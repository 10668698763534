import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import useChart from "../../../components/Chart/useChart";
import Chart from "../../../components/Chart/Chart";
import { ApexOptions } from "apexcharts";
import { Chip, Stack, Typography } from "@mui/material";
import NumberFunctions from "../../../core/functions/NumberFunctions";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TextFilterButton from "../../../components/Buttons/TextFielterButton";
import { TDailyCommissions } from "../../../core/types/Dashboard";
import {
    addDays,
    dateString,
    dayAndMonthString,
    dayString,
    daysOfWeek,
    getDayOfWeek,
    monthOfYear,
    stringToDate,
} from "../../../core/functions/dateTime";
import InvisibleDashboardCard from "../../../components/Cards/InvisibleDashboardCard";
import NotFoundChart from "../../../components/Chart/NotFoundChart";

export interface IAppWidgetVisitsChartProps {
    labels: Array<string>;
    colors?: Array<string>;
    series: Array<any>;
    options?: Array<any>;
    dates?: Array<Date>;
}

export interface IAppWidgetVisitsProps {
    chart: IAppWidgetVisitsChartProps;
    title: string;
    total: number;
    percentage: number;
    subtype: "positive" | "negative";
    isVisibleValue: boolean;
    selected: TDailyCommissions;
    setSelected: (value: TDailyCommissions) => void;
}

export default function AppDailyCommissions({
    title,
    total,
    percentage,
    subtype,
    chart,
    isVisibleValue,
    selected,
    setSelected,
    ...other
}: IAppWidgetVisitsProps) {
    const { labels, colors, series, options } = chart;

    const chartOptions = useChart({
        colors: colors ?? ["#ededff", "#637eef"],
        states: {
            hover: {
                filter: {
                    type: "none",
                },
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "16px",
                borderRadius: 7,
                borderRadiusApplication: "around",
                borderRadiusWhenStacked: "all",
            },
        },
        fill: {
            type: series.map((i) => i.fill),
        },
        labels,
        xaxis: {
            //type: "datetime",
            labels: {
                /*format:
                    selected === TDailyCommissions.Daily
                        ? "dd"
                        : selected === TDailyCommissions.Weekly
                        ? "dd/MM"
                        : "MM/yy",*/
                formatter: (value, timestamp, opts) => {
                    const date = new Date(value);
                    if (selected === TDailyCommissions.Daily) {
                        return dayString(date);
                    }
                    if (selected === TDailyCommissions.Weekly) {
                        let index = 1;
                        labels.map((label, i) => {
                            if (label === value) {
                                index = i + 1;
                            }
                        });
                        return `${index} sem`;
                    }
                    if (selected === TDailyCommissions.Monthly) {
                        return monthOfYear[date.getMonth()]?.substring(0, 3);
                    }
                    return dateString(date);
                },
                hideOverlappingLabels: true,
            },
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            axisBorder: {
                show: true,
            },
        },
        tooltip: {
            shared: false,
            intersect: false,
            y: {
                formatter: (value: any) => {
                    if (typeof value !== "undefined") {
                        return `${value.toFixed(0)}`;
                    }
                    return value;
                },
            },
            fixed: {
                enabled: false,
                position: "top",
            },
            custom: function ({ seriesIndex, dataPointIndex, ...opts }) {
                const barSerie = series[seriesIndex];
                const barData = barSerie.data[dataPointIndex];
                const dateTime = new Date(barSerie.dates[dataPointIndex]);

                if (!dateTime) {
                    return `<div><b>${NumberFunctions.formatMoneyDefault(barData)}</b></div>`;
                }

                if (selected === TDailyCommissions.Weekly) {
                    const until = addDays(6, dateTime);
                    return `<div><b>${NumberFunctions.formatMoneyDefault(barData)}</b></div>
                            <div>${dayString(dateTime)} de ${monthOfYear[dateTime.getMonth()]?.substring(
                        0,
                        3
                    )} - ${dayString(until)} de ${monthOfYear[until.getMonth()]?.substring(
                        0,
                        3
                    )} ${until.getFullYear()}</div>`;
                }

                if (selected === TDailyCommissions.Monthly) {
                    return `<div><b>${NumberFunctions.formatMoneyDefault(barData)}</b></div>
                            <div>${monthOfYear[dateTime.getMonth()]} de ${dateTime.getFullYear()}</div>`;
                }

                return `<div><b>${NumberFunctions.formatMoneyDefault(barData)}</b></div>
                        <div>${getDayOfWeek(dateTime)}, ${dayString(dateTime)} de ${monthOfYear[
                    dateTime.getMonth()
                ]?.substring(0, 3)}, ${dateTime.getFullYear()}</div>`;
            },
        },
        legend: {
            show: false,
        },
        ...options,
    } as ApexOptions);

    return (
        <Card
            elevation={0}
            sx={{
                borderRadius: "8px",
                height: "320px",
            }}
            {...other}
        >
            <Stack
                direction="row"
                spacing={1}
                alignContent="center"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 3, pt: 3, mb: 1 }}
            >
                <Typography fontWeight={600} fontSize="16px" lineHeight="20px" color="#38393B">
                    {title}
                </Typography>
                <Stack direction="row" spacing={1} alignContent="center" alignItems="center">
                    <TextFilterButton
                        text="Diário"
                        backgroundColor={selected === TDailyCommissions.Daily ? "#F2F2FF" : "#F3F3F4"}
                        borderColor={selected === TDailyCommissions.Daily ? "#BCC1FF" : "#DCDDDE"}
                        textColor={selected === TDailyCommissions.Daily ? "#1C52BD" : "#707275"}
                        onClick={() => setSelected(TDailyCommissions.Daily)}
                    />
                    <TextFilterButton
                        text="Semanal"
                        backgroundColor={selected === TDailyCommissions.Weekly ? "#F2F2FF" : "#F3F3F4"}
                        borderColor={selected === TDailyCommissions.Weekly ? "#BCC1FF" : "#DCDDDE"}
                        textColor={selected === TDailyCommissions.Weekly ? "#1C52BD" : "#707275"}
                        onClick={() => setSelected(TDailyCommissions.Weekly)}
                    />
                    <TextFilterButton
                        text="Mensal"
                        backgroundColor={selected === TDailyCommissions.Monthly ? "#F2F2FF" : "#F3F3F4"}
                        borderColor={selected === TDailyCommissions.Monthly ? "#BCC1FF" : "#DCDDDE"}
                        textColor={selected === TDailyCommissions.Monthly ? "#1C52BD" : "#707275"}
                        onClick={() => setSelected(TDailyCommissions.Monthly)}
                    />
                </Stack>
            </Stack>

            {isVisibleValue && series[0].data.length > 0 && (
                <Box>
                    <Chart
                        dir="ltr"
                        type="line"
                        series={series}
                        options={chartOptions}
                        width="100%"
                        height="250px"
                        sx={{
                            "& .apexcharts-tooltip, .apexcharts-tooltip-series-group.active": {
                                background: "#F2F2FF !important",
                                p: "8px",
                                m: "0px",
                                fontSize: "12px",
                                color: "#707275",
                            },
                        }}
                    />
                </Box>
            )}
            <NotFoundChart isVisible={isVisibleValue && series[0].data.length <= 0} />
            <InvisibleDashboardCard visible={!isVisibleValue} variant="chart" />
        </Card>
    );
}
