export enum TProductVoucherValue {
    value = 1,
    percentage = 2,
}

export enum TProductVoucherExpired {
    quantity = 1,
    date = 2,
}

export type TProductVoucher = {
    id?: string;
    productId: string;
    voucherIdentifier: string;
    valueType: TProductVoucherValue;
    value: number | string;
    startDurationDate: Date;
    expiredType: TProductVoucherExpired;
    maxQuantity?: number | null;
    endDurationDate?: Date | null;
    isOfferAll: boolean;
    productPrices: string[];
}