import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./core/context/AuthContext";
import Routes from "./routes";
import { ThemeProvider } from "@mui/material";
import theme from "./layouts/Theme";
import './App.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
