import React, { useState } from 'react'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import Hosts from '../../../core/functions/hosts'
import { TUpdateIndicatedTenant } from '../../../core/types/IndicatedTenant'
import { useCurrentTenant } from '../../../core/hooks/useCurrentTenant'
import { TTenantSettings } from '../../../core/types/Tenant'
import CopiedSnackbar from '../../../components/Snackbar/CopiedSnackbar'

export interface LinkIndicatedProps {
  tenantSettings: TTenantSettings | null
}

export default function LinkIndicated({ tenantSettings }: LinkIndicatedProps) {
  const [copiedLink, setCopiedLink] = useState(false)
  const [copied, setCopied] = useState(false)

  //user token
  const { data } = useCurrentTenant()
  const recruitingUrl = `${process.env.REACT_APP_PUBLIC_HOST}/signup?inid=${data?.token}`

  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={() => Hosts.openNewBlank(recruitingUrl)}
        sx={{ color: 'white' }}
      >
        ABRIR LINK
      </Button>
    </React.Fragment>
  )

  const handleChangeCopyURL = async () => {
    await Hosts.copyTextToClipboard(recruitingUrl)
    setCopiedLink(true)
  }

  return (
    <Box display="inline">
      <Typography gutterBottom variant="h5" textAlign="start" fontWeight={700}>
        Link de divulgação
      </Typography>
      <Typography gutterBottom>
        Ganhe {tenantSettings?.indicatedComissionsRateValue}% da comissão das
        pessoas que você indicar, durante 12 meses.
      </Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        spacing={2}
      >
        <TextField
          variant="outlined"
          size="small"
          value={recruitingUrl}
          aria-readonly
          sx={{ width: { xs: '100%', sm: '50%' } }}
        />
        <Button
          variant="contained"
          onClick={handleChangeCopyURL}
          sx={{ width: { xs: '100%', sm: '200px' } }}
        >
          Copiar link
        </Button>
      </Stack>
      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />
      <CopiedSnackbar
        open={copiedLink}
        onClose={() => setCopiedLink(false)}
        action={action}
      />
    </Box>
  )
}
