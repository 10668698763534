export enum TActiveCampaignItemType {
  CartAbandonment = 1,
  AwaitingPayment = 2,
  TicketIssued = 3,
  PurchaseApproved = 4,
  DelayedPurchase = 5,
  PurchaseCanceled = 6,
  CompletePurchase = 7,
  PurchaseInReversal = 8,
  ExpiredPurchase = 9,
  ClaimedPurchase = 10,
  PurchaseRefunded = 11,
  GeneratedPix = 12,

  MembershipRequestReceived = 13,
  AffiliationApproved = 14,
  MembershipCanceled = 15,
  CommissionUpdate = 16,
}

export enum TActiveCampaignItemCustomFieldType {
  textarea = 1,
  text = 2,
  date = 3,
  dropdown = 4,
  multiselect = 5,
  radio = 6,
  checkbox = 7,
  hidden = 8,
  datetime = 9,
}

export enum TActiveCampaignItemTagType {
  template = 1,
  contact = 2,
}

export enum TActiveCampaignItemAction {
  Include = 1,
  Delete = 2,
}

export type TActiveCampaignCustomField = {
  id: string
  name: string
  type: TActiveCampaignItemCustomFieldType
  defaultValue?: string | null
  defaultCurrency?: number | null
}

export type TActiveCampaignList = {
  id: string
  name: string
  stringId: string
  sender_url: string
  sender_reminder: string
  send_last_broadcast?: boolean | null
  carboncopy?: string | null
  subscription_notify?: string | null
  unsubscription_notify?: string | null
}

export type TActiveCampaignTag = {
  id: string
  name: string
}

export type TActiveCampaign = {
  id: string
  active: boolean
  url: string
  token: string
}

export type TActiveCampaignItemCustomField = {
  id?: string | null
  activeCampaignItemId?: string | null
  customFieldId: string
  name: string
  type: TActiveCampaignItemCustomFieldType
  defaultValue?: string | null
  defaultCurrency?: number | null
  isFormVisible: boolean
  isRequired: boolean
  displayOrder: number
}

export type TActiveCampaignItemList = {
  id?: string | null
  activeCampaignItemId?: string | null
  listId: string
  name: string
  stringId: string
  sender_url: string
  sender_reminder: string
  send_last_broadcast?: boolean | null
  carboncopy?: string | null
  subscription_notify?: string | null
  unsubscription_notify?: string | null
}

export type TActiveCampaignItemTag = {
  id?: string | null
  activeCampaignItemId?: string | null
  tagId: string
  tag: string
  tagType: TActiveCampaignItemTagType
  description: string
}

export type TActiveCampaignItem = {
  id: string
  campaignAccountId: string
  productId: string
  productName: string
  productPriceIds: string[]
  event: TActiveCampaignItemType | null
  action: TActiveCampaignItemAction | null

  customFields: TActiveCampaignItemCustomField[]
  lists: TActiveCampaignItemList[]
  tags: TActiveCampaignItemTag[]
}

export type TActiveCampaignPagination = {
  items: TActiveCampaignItem[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
}
