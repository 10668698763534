import {
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  TProduct,
  TProductAssurance,
  TProductDelivery,
  TProductStatus,
  TProductTypePayment,
} from '../../../../core/types/Product'
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useContext, useEffect, useState } from 'react'
import Convertions from '../../../../core/functions/convertions'
import CopiedSnackbar from '../../../../components/Snackbar/CopiedSnackbar'
import { TMemberService } from '../../../../core/types/MemberService'
import SwitchRound from '../../../../components/Switch/SwitchRound'
import ImagePlaceholder from '../../../../components/Images/ImagePlaceholder'
import { useResponsive } from '../../../../core/hooks/useResponsive'
import SimpleCard from '../../../../components/Cards/SimpleCard'
import { red } from '@mui/material/colors'
import InfoInputAdornment from '../../../../components/Inputs/InfoInputAdornment'
import { HtmlTooltip } from '../../../../components/Tooltip/HtmlTooltip'
import { AuthContext } from '../../../../core/context/AuthContext'
import { TUserType } from '../../../../core/types/Auth'

export interface IProductGeneralProp {
  control: Control<TProduct, any>
  errors: FieldErrors<TProduct>
  memberService: TMemberService[]
  buttonSave: boolean
  typePayment?: TProductTypePayment
  watch: UseFormWatch<TProduct>
  setValue: UseFormSetValue<TProduct>
  setError: (value: React.SetStateAction<string>) => void
  selectedOption: string
  onChangeSelectedOption: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ProductGeneral = ({
  control,
  errors,
  memberService,
  buttonSave,
  watch,
  setValue,
  typePayment,
  setError,
  selectedOption,
  onChangeSelectedOption,
}: IProductGeneralProp) => {
  const [copied, setCopied] = useState(false)
  const [thankyouPages, setThankyouPages] = useState(false)
  const { user } = useContext(AuthContext)
  const { register } = control

  const isSmall = useResponsive('down', 'sm')

  const approvedPageLink = watch('approvedPageLink')
  const awaitingPaymentPageLink = watch('awaitingPaymentPageLink')
  const awaitingCreditAnalysisPageLink = watch('awaitingCreditAnalysisPageLink')

  const externalLink = watch('externalLink')
  const accessUser = watch('accessUser')
  const accessPassword = watch('accessPassword')
  const deliveryValue = watch('delivery')

  const ALPHA_NUMERIC_DASH_REGEX = /^[0-9-]+$/

  const isAdmin = user?.UserType === TUserType.SystemAdmin

  useEffect(() => {
    const test =
      (approvedPageLink?.trim() ?? '') +
      (awaitingPaymentPageLink?.trim() ?? '') +
      (awaitingCreditAnalysisPageLink?.trim() ?? '')

    if (test !== '') {
      setThankyouPages(true)
    }
  }, [
    approvedPageLink,
    awaitingPaymentPageLink,
    awaitingCreditAnalysisPageLink,
  ])

  return (
    <Stack direction={'column'} spacing={3} sx={{ gap: '24px' }}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          backgroundColor: '#FFFF',
          flexDirection: 'row',
          gap: '32px',
          padding: '32px',
          borderRadius: '8px',
        }}
      >
        <Stack spacing={2}>
          <ImagePlaceholder
            imageUrl={watch('imageProductUrl')}
            alt="img-top"
            style={{
              borderRadius: 10,
              objectFit: 'cover',
              objectPosition: 'center',
              width: isSmall ? '90px' : '200px',
              height: isSmall ? '90px' : '200px',
              backgroundColor: 'white',
            }}
            optionsStyle={{
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
            onChange={(file) => {
              setValue('imageProduct', file)
              if (!file) {
                setValue('imageProductUrl', '')
              }
            }}
            onError={(error) => setError(error)}
          />
        </Stack>

        <Stack gap="32px" spacing={2}>
          <Stack spacing={0.2} sx={{ gap: '4px' }}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '20px',
                color: '#343948',
              }}
            >
              Informações básicas do produto
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '18px',
                color: '#707275',
              }}
            >
              Você pode cadastrar o produto e já começar a vender. A imagem do
              produto será exibida na área de membros e no seu programa de
              afiliados.
            </Typography>
          </Stack>
          <Stack spacing={3}>
            <Box>
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                type="text"
                label="Nome"
                {...register('name', { required: true })}
                InputLabelProps={{
                  shrink: (watch('name') || '') !== '',
                }}
                error={errors.name?.type === 'required'}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: '400px',
                  color: '#707070',
                  mt: '5px',
                }}
              >
                Esse nome irá aparecer em todos os locais da plataforma
              </Typography>
              {errors.name?.type === 'required' && (
                <Typography variant="overline" color={'red'}>
                  Nome é um campo obrigatório
                </Typography>
              )}
            </Box>

            <Box>
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                multiline
                rows={2}
                type="text"
                label="Descrição"
                {...register('description', { required: true })}
                InputLabelProps={{
                  shrink: (watch('description') || '') !== '',
                }}
                error={errors.description?.type === 'required'}
              />
              {errors.description?.type === 'required' && (
                <Typography variant="overline" color={'red'}>
                  Descrição é um campo obrigatório
                </Typography>
              )}
            </Box>

            <Box width="100%">
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                type="text"
                label="Identificação na fatura"
                {...register('statementDescription')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      sx={{
                        backgroundColor: (theme) => theme.palette.divider,
                        padding: '20px 14px',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                      }}
                      position="start"
                    >
                      <Typography
                        fontSize="14px"
                        lineHeight="18px"
                        fontWeight={500}
                        sx={{ color: '#343948' }}
                      >
                        CLTUS
                      </Typography>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InfoInputAdornment title="Identificador do produto que aparecerá na fatura do cartão de crédito." />
                  ),
                }}
                inputProps={{ maxLength: 8 }}
                InputLabelProps={{
                  shrink: (watch('statementDescription') || '') !== '',
                  sx: { ml: 9 },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    paddingLeft: 0,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    px: 10,
                  },
                }}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                sx={{ px: 1 }}
              >
                <Typography variant="caption" sx={{ color: '#707275' }}>
                  {(watch('statementDescription') ?? '').length}/8
                </Typography>
              </Stack>
            </Box>

            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
              <FormControl
                fullWidth
                size="small"
                error={errors.assurance?.type === 'required'}
              >
                <InputLabel id="assurance-select-label">Garantia</InputLabel>
                <Select
                  variant="outlined"
                  disabled={isAdmin}
                  labelId="assurance-select-label"
                  label="Garantia"
                  value={
                    watch('assurance')
                      ? Number(watch('assurance'))
                      : TProductAssurance.Seven
                  }
                  {...register('assurance')}
                  renderValue={(value?: number) =>
                    Convertions.productAssuranceToString(value)
                  }
                >
                  <MenuItem value={Number(TProductAssurance.Seven)}>
                    {Convertions.productAssuranceToString(
                      TProductAssurance.Seven
                    )}
                  </MenuItem>
                  <MenuItem value={Number(TProductAssurance.Fifteen)}>
                    {Convertions.productAssuranceToString(
                      TProductAssurance.Fifteen
                    )}
                  </MenuItem>
                  <MenuItem value={Number(TProductAssurance.TwentyOne)}>
                    {Convertions.productAssuranceToString(
                      TProductAssurance.TwentyOne
                    )}
                  </MenuItem>
                  <MenuItem value={Number(TProductAssurance.Thirty)}>
                    {Convertions.productAssuranceToString(
                      TProductAssurance.Thirty
                    )}
                  </MenuItem>
                </Select>
              </FormControl>

              <Stack width="100%" direction="row" sx={{ pr: 1.5 }}>
                <FormControl
                  fullWidth
                  size="small"
                  error={errors.status?.type === 'required'}
                >
                  <InputLabel id="status-select-label">
                    Status da venda
                  </InputLabel>

                  <Select
                    disabled={isAdmin}
                    variant="outlined"
                    labelId="status-select-label"
                    label="Status da venda"
                    value={Number(watch('status'))}
                    {...register('status', { required: true })}
                  >
                    <MenuItem value={Number(TProductStatus.Active)}>
                      {Convertions.productStatusToString(TProductStatus.Active)}
                    </MenuItem>
                    <MenuItem value={Number(TProductStatus.Inactive)}>
                      {Convertions.productStatusToString(
                        TProductStatus.Inactive
                      )}
                    </MenuItem>
                  </Select>
                  {errors.status?.type === 'required' && (
                    <Typography variant="overline" color={'red'}>
                      Campo obrigatório
                    </Typography>
                  )}
                </FormControl>
                <Box sx={{ alignContent: 'center' }}>
                  <HtmlTooltip title="Caso o status esteja como inativo todas os checkouts serão desativados e as vendas interrompidas">
                    <img
                      src="/assets/icons/infos/info.svg"
                      alt="tooltip-info-pixel"
                      style={{ marginLeft: 5, width: '25px', height: '25px' }}
                    />
                  </HtmlTooltip>
                </Box>
              </Stack>
            </Stack>

            {deliveryValue === TProductDelivery.eBook && (
              <Stack direction="column">
                <TextField
                  disabled={isAdmin}
                  fullWidth
                  size="small"
                  type="text"
                  label="URL do Ebook"
                  {...register('deliveryUrl', { required: true })}
                  InputLabelProps={{
                    shrink: (watch('deliveryUrl') || '') !== '',
                  }}
                  error={
                    errors.deliveryUrl?.type === 'required' ||
                    errors.deliveryUrl?.type === 'invalidURL'
                  }
                  InputProps={{
                    endAdornment: (
                      <InfoInputAdornment title="Link enviado por e-mail de compra aprovada para o aluno poder realizar o download do PDF." />
                    ),
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '16px',
                    fontWeight: '400px',
                    color: '#707070',
                    mt: '5px',
                  }}
                >
                  O PDF não pode possuir senha
                </Typography>
                {errors.deliveryUrl && (
                  <Typography variant="caption" color={red[700]}>
                    {errors.deliveryUrl.type === 'required'
                      ? 'URL do Ebook é um campo obrigatório'
                      : ''}
                    {errors.deliveryUrl.type === 'invalidURL'
                      ? 'URL inválida'
                      : ''}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          backgroundColor: '#FFFF',
          gap: '32px',
          padding: '32px',
          borderRadius: '8px',
        }}
      >
        <Stack spacing={0.2} sx={{ gap: '4px' }}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '20px',
              color: '#343948',
            }}
          >
            Área de Membros
          </Typography>
          <Typography
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight={400}
            lineHeight="18px"
            color="#707275"
          >
            Informe uma área de membros válida onde seus alunos receberão o
            acesso e o nosso time possa validar a entrega.
          </Typography>

          <Stack spacing={2}>
            {deliveryValue === TProductDelivery.Course && (
              <Stack width="100%" spacing={2}>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column' }}
                  alignItems={'left'}
                  justifyContent={'left'}
                >
                  <RadioGroup
                    row
                    sx={{ m: 0 }}
                    value={selectedOption}
                    onChange={onChangeSelectedOption}
                  >
                    <FormControlLabel
                      value="integration"
                      control={<Radio />}
                      label="API pré-cadastrada"
                    />
                    <FormControlLabel
                      value="external"
                      control={<Radio />}
                      label="Validação Manual"
                    />
                  </RadioGroup>
                </Box>

                {selectedOption === 'integration' && (
                  <Collapse in={selectedOption === 'integration'}>
                    <Stack direction={'column'} spacing={2}>
                      <Typography
                        fontFamily="Montserrat"
                        fontSize="14px"
                        fontWeight={400}
                        lineHeight="18px"
                        color="#707275"
                      >
                        Selecione uma área de membros que já foi pré-cadastrada
                        via API na área de aplicativos da Celetus.
                      </Typography>
                      <FormControl
                        fullWidth
                        size="small"
                        error={
                          errors.memberServiceSettingId?.type === 'required'
                        }
                      >
                        <InputLabel id="member-select-label">
                          Área de Membros
                        </InputLabel>
                        <Select
                          disabled={isAdmin}
                          required
                          variant="outlined"
                          labelId="member-select-label"
                          label="Área de Membros"
                          value={watch('memberServiceSettingId') ?? ''}
                          {...register('memberServiceSettingId')}
                          renderValue={(value?: string) =>
                            memberService.find((ms) => ms.id === value)
                              ?.identifier
                          }
                        >
                          {memberService.map((ms) => (
                            <MenuItem key={ms.id} value={ms.id}>
                              {ms.identifier} -{' '}
                              {Convertions.memberServiceTypeToString(ms.type)}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.memberServiceSettingId?.type === 'required' && (
                          <Typography variant="overline" color={'red'}>
                            Campo obrigatório
                          </Typography>
                        )}
                      </FormControl>
                    </Stack>
                  </Collapse>
                )}

                {selectedOption === 'external' && (
                  <Collapse in={selectedOption === 'external'}>
                    <Stack direction={'column'} spacing={2}>
                      <Typography
                        fontFamily="Montserrat"
                        fontSize="14px"
                        fontWeight={400}
                        lineHeight="18px"
                        color="#707275"
                      >
                        Forneça ao nosso time de validação, credenciais, para
                        avaliar a entrega do produto.
                      </Typography>
                      <Box>
                        <TextField
                          fullWidth
                          disabled={isAdmin}
                          size="small"
                          type="text"
                          label="Link da área de membros"
                          placeholder="Digite a URL"
                          {...register('externalLink', {
                            required: true,
                          })}
                          InputProps={{
                            endAdornment: (
                              <InfoInputAdornment title="Digite o link para a área de membros" />
                            ),
                          }}
                          InputLabelProps={{
                            shrink:
                              (watch('externalLink') || '') !== ''
                                ? true
                                : undefined,
                          }}
                          error={
                            errors.externalLink?.type === 'required' ||
                            errors.externalLink?.type === 'invalidURL'
                          }
                        />
                        {errors.externalLink && (
                          <Typography variant="overline" color={'red'}>
                            {errors.externalLink.type === 'required'
                              ? 'Campo obrigatório'
                              : ''}
                            {errors.externalLink.type === 'invalidURL'
                              ? 'URL inválida'
                              : ''}
                          </Typography>
                        )}
                      </Box>

                      <Box>
                        <TextField
                          fullWidth
                          disabled={isAdmin}
                          size="small"
                          type="email"
                          label=" E-mail "
                          placeholder="Digite o e-mail"
                          {...register('accessUser', {
                            required: 'true',
                          })}
                          InputProps={{
                            endAdornment: (
                              <InfoInputAdornment title="Digite o email para acessar o link da área de membros" />
                            ),
                          }}
                          InputLabelProps={{
                            shrink:
                              (watch('accessUser') || '') !== ''
                                ? true
                                : undefined,
                          }}
                          error={
                            errors.accessUser?.type === 'required' ||
                            errors.accessUser?.type === 'invalidEmail'
                          }
                        />
                        {errors.accessUser && (
                          <Typography variant="overline" color={'red'}>
                            {errors.accessUser.type === 'required'
                              ? 'Campo obrigatório'
                              : ''}
                            {errors.accessUser.type === 'invalidEmail'
                              ? 'E-mail inválido'
                              : ''}
                          </Typography>
                        )}
                      </Box>

                      <Box>
                        <TextField
                          fullWidth
                          disabled={isAdmin}
                          size="small"
                          type="text"
                          label="Senha de acesso"
                          placeholder="Digite a senha"
                          {...register('accessPassword', {
                            required: true,
                          })}
                          InputProps={{
                            endAdornment: (
                              <InfoInputAdornment title="Digite a senha para acessar o link da área de membros" />
                            ),
                          }}
                          InputLabelProps={{
                            shrink:
                              (accessPassword || '') !== '' ? true : undefined,
                          }}
                          error={errors.accessPassword?.type === 'required'}
                        />
                        {errors.accessPassword && (
                          <Typography variant="overline" color={'red'}>
                            {errors.accessPassword.type === 'required'
                              ? 'Campo obrigatório'
                              : ''}
                          </Typography>
                        )}
                      </Box>
                    </Stack>
                  </Collapse>
                )}
              </Stack>
            )}
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              sx={{ pt: 1 }}
            >
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                type="text"
                label="Voxuy - Id do Plano"
                {...register('voxuyPlanId')}
                InputLabelProps={{
                  shrink: (watch('voxuyPlanId') || '') !== '',
                }}
              />
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                type="text"
                label="Voxuy - Id do Evento"
                {...register('voxuyEventId')}
                InputLabelProps={{
                  shrink: (watch('voxuyEventId') || '') !== '',
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          backgroundColor: '#FFFF',
          flexDirection: 'column',
          alignContent: 'center',
          gap: '32px',
          padding: '32px',
          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            gap: '4px',
          }}
        >
          <Stack>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '20px',
                color: '#343948',
              }}
            >
              Página de obrigado
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '18px',
                color: '#707275',
              }}
            >
              Explicação o que é um página de obrigado
            </Typography>
          </Stack>
          <Stack>
            <SwitchRound
              sx={{ m: 0 }}
              checked={thankyouPages}
              disabled={isAdmin}
              onChange={(e) => {
                setThankyouPages(e.target.checked)
                if (!e.target.checked) {
                  setValue('approvedPageLink', '')
                  setValue('awaitingPaymentPageLink', '')
                  setValue('awaitingCreditAnalysisPageLink', '')
                }
              }}
            />
          </Stack>
        </Box>

        <Stack>
          <Box
            sx={{ display: 'flex', flexDirection: 'column' }}
            alignItems={'left'}
            justifyContent={'left'}
          >
            {thankyouPages && <Divider />}
          </Box>
          <Collapse in={thankyouPages}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              sx={{ pt: 2 }}
            >
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                type="text"
                label="URL Compras Aprovadas"
                placeholder="Digite a URL"
                {...register('approvedPageLink')}
                InputProps={{
                  endAdornment: (
                    <InfoInputAdornment title="Se a compra for feita com cartão de crédito, por exemplo, e ela já estiver aprovada, seu comprador é direcionado para esta URL." />
                  ),
                }}
                InputLabelProps={{
                  shrink: (approvedPageLink || '') !== '' ? true : undefined,
                }}
              />
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                type="text"
                label="URL Pagamento"
                placeholder="Digite a URL"
                {...register('awaitingPaymentPageLink')}
                InputProps={{
                  endAdornment: (
                    <InfoInputAdornment title="Se a compra for feita por boleto bancário, por exemplo, como ela precisa da confirmação do pagamento, seu comprador é direcionado para esta URL." />
                  ),
                }}
                InputLabelProps={{
                  shrink:
                    (awaitingPaymentPageLink || '') !== '' ? true : undefined,
                }}
              />
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                type="text"
                label="URL Análise de Crédito"
                placeholder="Digite a URL"
                {...register('awaitingCreditAnalysisPageLink')}
                InputProps={{
                  endAdornment: (
                    <InfoInputAdornment title="Se a compra for feita por PayPal, por exemplo, como ela leva até 24 horas para ser processada, seu comprador é direcionado para esta URL." />
                  ),
                }}
                InputLabelProps={{
                  shrink:
                    (awaitingCreditAnalysisPageLink || '') !== ''
                      ? true
                      : undefined,
                }}
              />
            </Stack>
          </Collapse>
        </Stack>
      </Stack>

      <SimpleCard sx={{ p: '32px' }}>
        <Stack sx={{ mb: 2 }}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '20px',
              color: '#343948',
            }}
          >
            Suporte
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '18px',
              color: '#707275',
            }}
          >
            Informe as configurações para suporte do produto
          </Typography>
        </Stack>

        <TextField
          fullWidth
          disabled={isAdmin}
          size="small"
          type="text"
          label="E-mail para suporte"
          placeholder="Digite o e-mail"
          {...register('supportEmail')}
          InputProps={{
            endAdornment: (
              <InfoInputAdornment title="Esse e-mail será apresentado na página de obrigado padrão e enviado por e-mail de compra aprovada. Caso não informe esse campo será apresentado o e-mail padrão da conta." />
            ),
          }}
          InputLabelProps={{
            shrink: (watch('supportEmail') || '') !== '' ? true : undefined,
          }}
        />
      </SimpleCard>

      {!isAdmin && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="end"
        >
          {buttonSave && (
            <Button
              color="primary"
              type="submit"
              variant="contained"
              size="large"
              sx={{ textTransform: 'none' }}
            >
              Salvar
            </Button>
          )}
        </Stack>
      )}

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />
    </Stack>
  )
}

export default ProductGeneral
