import { Button, Paper, Typography } from "@mui/material";

export interface ITextFilterButtonProps {
    text: React.ReactNode;
    backgroundColor?: string | undefined;
    borderColor?: string | undefined;
    textColor?: string | undefined;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const TextFilterButton = ({
    text,
    backgroundColor,
    borderColor = "#DCDDDE",
    textColor = "#707275",
    onClick,
}: ITextFilterButtonProps) => {
    return (
        <Button
            sx={{ p: 0, m: 0, minWidth: 0, borderRadius: "32px", textTransform: "none", textDecoration: "none" }}
            onClick={onClick}
        >
            <Paper
                elevation={0}
                sx={{
                    borderRadius: "32px",
                    border: `1px solid ${borderColor}`,
                    px: "8px",
                    py: "4px",
                    fontSize: "0rem",
                    backgroundColor,
                }}
            >
                <Typography fontWeight={500} fontSize="12px" lineHeight="16px" sx={{ color: textColor }}>
                    {text}
                </Typography>
            </Paper>
        </Button>
    );
};

export default TextFilterButton;
