import { CreditCardAmerican } from "../models/CreditCardAmerican";
import { CreditCardDiners } from "../models/CreditCardDiners";
import { CreditCardDiscover } from "../models/CreditCardDiscover";
import { CreditCardElo } from "../models/CreditCardElo";
import { CreditCardJCB } from "../models/CreditCardJCB";
import { CreditCardMaster } from "../models/CreditCardMaster";
import { CreditCardVisa } from "../models/CreditCardVisa";
import { IOrderChargeStatus } from "../types/Order";
import { TTenantAnticipationType } from "../types/Tenant";

export const methodTypeCreditCard = 1;
export const methodTypeDebitCard = 2;
export const methodTypePix = 3;
export const methodTypeBoleto = 4;

export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const paymentsPaid = [IOrderChargeStatus.Paid];

export const paymentsPendent = [IOrderChargeStatus.Overpaid, IOrderChargeStatus.Underpaid];

export const paymentsFailed = [IOrderChargeStatus.Failed, IOrderChargeStatus.Canceled, IOrderChargeStatus.Chargedback];

export const startNumberVisa = ['4'];

export const startNumberMaster = ['51', '52', '53', '54', '55'];

export const startNumberElo = ['50', '56', '57', '58', '59'];

export const startNumberDiners = ['30', '36', '38'];

export const startNumberDiscover = ['6011', '65'];

export const startNumberJCB = ['35'];

export const startNumberAmerican = ['34', '37'];

export const creditsCards = [
    new CreditCardVisa(),
    new CreditCardMaster(),
    new CreditCardElo(),
    new CreditCardDiners(),
    new CreditCardDiscover(),
    new CreditCardJCB(),
    new CreditCardAmerican(),
];

export const tenantAnticipationTypes = [
    TTenantAnticipationType.None,
    TTenantAnticipationType.D2,
    TTenantAnticipationType.D15,
    TTenantAnticipationType.D30,
];

export const creditCardInstallmentFee = 2.99;