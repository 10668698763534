import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { teal } from '@mui/material/colors'
import { Link as RouterLink } from 'react-router-dom'
import {
  ExportFileTypeEnum,
  ReportDownloadController,
} from '../../../core/controllers/ReportDownloadController'
import { useDownloadFile } from '../../../core/hooks/useDownloadFile'
import React, { useContext } from 'react'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import Hosts from '../../../core/functions/hosts'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { lastYears, monthOfYear } from '../../../core/functions/dateTime'
import { AuthContext } from '../../../core/context/AuthContext'
import { TUserType } from '../../../core/types/Auth'

const NotazzPage = () => {
  const { user } = useContext(AuthContext)

  const [error, setError] = React.useState('')

  const [month, setMonth] = React.useState(new Date().getMonth())
  const [year, setYear] = React.useState(new Date().getFullYear())

  const handleDownloadFile = (typeFile: ExportFileTypeEnum) => {
    return ReportDownloadController.notazzFile({
      fileType: typeFile,
    })
  }

  const handleDownloadInvoicesFile = (typeFile: ExportFileTypeEnum) => {
    return ReportDownloadController.invoicesFile({ year, month })
  }

  const handlerDownloadingError = (e: string) => setError(e)
  const getFileName = (type: ExportFileTypeEnum) =>
    `report_${new Date().toISOString().replace(':', '').replace('.', '_')}.${type === ExportFileTypeEnum.XLS ? 'xlsx' : 'csv'}`

  const { download, downloading } = useDownloadFile({
    apiDefinition: handleDownloadFile,
    onError: handlerDownloadingError,
    getFileName,
  })

  const { download: downloadInvoices, downloading: downloadingInvoices } =
    useDownloadFile({
      apiDefinition: handleDownloadInvoicesFile,
      onError: handlerDownloadingError,
      getFileName,
    })

  const downloadCallback = React.useCallback(
    (type: ExportFileTypeEnum) => download(type),
    [download]
  )

  const downloadInvoicesCallback = React.useCallback(
    () => downloadInvoices(ExportFileTypeEnum.XLS),
    [downloadInvoices]
  )

  return (
    <Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        Notazz
      </Typography>

      <Stack direction="column" spacing={2} sx={{ width: '100%', p: 3 }}>
        <Typography>
          Para emissão de notas das suas vendas, nós temos integração com a
          Notazz.
        </Typography>

        <Box>
          <Typography variant="subtitle2">
            Para se cadastrar na Notazz clique no botão abaixo:
          </Typography>
          <Paper
            sx={{
              width: '250px',
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              ':hover': {
                boxShadow: 10,
              },
            }}
          >
            <Button
              fullWidth
              sx={{ height: '100%', color: 'black', textTransform: 'none' }}
              onClick={() =>
                Hosts.openNewBlank(
                  'https://app.notazz.com/parceiros/077e29b11be80ab57e1a2ecabb7da330'
                )
              }
            >
              <Stack
                direction="row"
                justifyContent="center"
                sx={{ p: 1, width: '100%' }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/apps/notazz-bg.png`}
                  style={{ width: '150px' }}
                />
              </Stack>
            </Button>
          </Paper>
        </Box>

        <Typography>
          Siga as etapas abaixo para copiar a chave de acesso para a Celetus:
        </Typography>

        <Stack
          direction="row"
          spacing={1}
          sx={{ width: '100%' }}
          alignItems="end"
          alignContent="end"
        >
          <Avatar sx={{ bgcolor: teal[500] }}>1</Avatar>
          <Typography>
            Em sua conta{' '}
            <Link component={RouterLink} to="/" sx={{ textDecoration: 'none' }}>
              Celetus
            </Link>
            , no menu lateral esquerdo acesse "<strong>Configurações</strong>" e
            clique em "<strong>API de Integração</strong>":
          </Typography>
        </Stack>
        <Box sx={{ my: 1 }}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/examples/celetus-api-integracao.png`}
            style={{ width: '100%', maxWidth: '1024px', borderRadius: 10 }}
          />
        </Box>

        <Stack
          direction="row"
          spacing={1}
          sx={{ width: '100%' }}
          alignItems="end"
          alignContent="end"
        >
          <Avatar sx={{ bgcolor: teal[500] }}>2</Avatar>
          <Typography>
            Copie a "<strong>Chave de acesso</strong>":
          </Typography>
        </Stack>
        <Box sx={{ my: 1 }}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/examples/celetus-apikey.png`}
            style={{ width: '100%', maxWidth: '1024px', borderRadius: 10 }}
          />
        </Box>

        <Stack
          direction="row"
          spacing={1}
          sx={{ width: '100%' }}
          alignItems="end"
          alignContent="end"
        >
          <Avatar sx={{ bgcolor: teal[500] }}>3</Avatar>
          <Typography>
            Clique para baixar os dados dos seus produtos:
          </Typography>
        </Stack>

        <Box>
          <Button
            variant="contained"
            onClick={() => downloadCallback(ExportFileTypeEnum.XLS)}
          >
            Baixar agora
          </Button>
        </Box>

        {user?.UserType === TUserType.SystemAdmin && (
          <Box sx={{ pt: 2, maxWidth: '1024px' }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="subtitle2">
                  Baixe também a planilha com as vendas por período
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="row" spacing={2}>
                  <FormControl size="small">
                    <InputLabel
                      id="mes-label"
                      shrink
                      sx={{ backgroundColor: 'white' }}
                    >
                      Mês
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="mes-label"
                      label="Mês"
                      value={month}
                      onChange={(e) => setMonth(Number(e.target.value))}
                    >
                      {monthOfYear.map((month, index) => (
                        <MenuItem key={month.toLowerCase()} value={index}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl size="small">
                    <InputLabel
                      id="mes-label"
                      shrink
                      sx={{ backgroundColor: 'white' }}
                    >
                      Ano
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="mes-label"
                      label="Ano"
                      value={year}
                      onChange={(e) => setYear(Number(e.target.value))}
                    >
                      {lastYears().map((value) => (
                        <MenuItem key={value.toString()} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Button
                    variant="contained"
                    onClick={() => downloadInvoicesCallback()}
                    sx={{ textTransform: 'none' }}
                  >
                    Baixar vendas do período
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Stack>

      <LoadingBackdrop open={downloading || downloadingInvoices} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>
    </Box>
  )
}

export default NotazzPage
