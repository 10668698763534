import {
  Box,
  FormControl,
  InputBase,
  InputBaseComponentProps,
  InputLabel,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useState } from 'react'
import { Control, Controller, UseFormClearErrors } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid grey',
    borderRadius: '4px',
    padding: '10px',
  },
  invalid: {
    border: '2px solid red',
    borderRadius: '4px',
    padding: '10px !important',
  },
  valid: {
    border: '2px solid green',
    borderRadius: '4px',
    padding: '10px !important',
  },
}))

export interface IValidationTextFieldProp {
  control: Control<any, any>
  fieldName: string
  label: string
  placeholder?: string
  inputProps?: InputBaseComponentProps
  required?: boolean
  endAdornment?: React.ReactNode
  onKeyDown?: React.KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement | HTMLDivElement
  >
  onFieldExit?: () => void
  clearErrors: UseFormClearErrors<any>
  validate?: (value?: any) => boolean
  validateLabel?: string
  sx?: any
  type?: string
}

function TextFieldRequired({
  control,
  fieldName,
  label,
  placeholder,
  inputProps,
  required,
  endAdornment,
  onKeyDown,
  onFieldExit,
  clearErrors,
  validate,
  validateLabel,
  sx,
  type,
}: IValidationTextFieldProp) {
  const classes = useStyles()
  const { register } = control

  const [isValid, setIsValid] = useState(true)

  if (required === undefined || required === null) {
    required = true
  }

  register(fieldName, { required })

  const validateField = (value?: string) => {
    if (!validate) {
      setIsValid(true)
    }

    const valid = validate!(value)
    setIsValid(valid)
  }

  return (
    <FormControl fullWidth sx={{ ...sx }}>
      <InputLabel
        id={fieldName}
        htmlFor={fieldName}
        sx={{ backgroundColor: 'white', px: 1 }}
      >
        {label}
      </InputLabel>
      <Controller
        control={control}
        name={fieldName}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <Box>
            <InputBase
              fullWidth
              required={required}
              id={fieldName}
              type={type || 'text'}
              value={value}
              defaultValue={''}
              //placeholder={placeholder}
              error={error?.type === 'required' || !isValid}
              className={
                error?.type === 'required' || !isValid
                  ? classes.invalid
                  : value !== ''
                    ? classes.valid
                    : classes.root
              }
              onBlur={(e) => {
                try {
                  const text = e.target.value
                  validateField(text)
                } catch (err) {
                  console.log(err)
                }
                onBlur()
                if (onFieldExit) {
                  onFieldExit()
                }
              }}
              onChange={(e) => {
                try {
                  const text = e.target.value
                  if (text !== '') {
                    clearErrors(fieldName)
                    setIsValid(true)
                  }
                } catch (err) {
                  console.log(err)
                }
                onChange(e)
              }}
              inputRef={ref}
              inputProps={inputProps}
              endAdornment={endAdornment}
              onKeyDown={onKeyDown}
            />
            {invalid && (
              <Typography variant="overline" color={'red'}>
                Campo {`"${label}"`} obrigatório
              </Typography>
            )}
            {!isValid && (
              <Typography variant="overline" color={'red'}>
                {validateLabel || `${label} inválido`}
              </Typography>
            )}
          </Box>
        )}
      />
    </FormControl>
  )
}

export default TextFieldRequired
