import { SxProps, Theme, Typography } from '@mui/material'

interface IDataTableCellTypographyProps {
  children: React.ReactNode
  noWrap?: boolean
  sx?: SxProps<Theme>
}

const DataTableCellTypography = ({
  children,
  noWrap,
  sx,
}: IDataTableCellTypographyProps) => {
  return (
    <Typography
      fontWeight={400}
      fontSize="14px"
      lineHeight="18px"
      color="#38393B"
      noWrap={noWrap}
      sx={sx}
    >
      {children}
    </Typography>
  )
}

export default DataTableCellTypography
