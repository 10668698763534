import { Link, Stack, Typography, useMediaQuery } from '@mui/material'

interface ITypePaymentChoose {
  onOneTimePayment: () => void
  onRecurringSubscription: () => void
}

const PaymentChooseMobile = ({
  onOneTimePayment,
  onRecurringSubscription,
}: any) => (
  <Stack direction="column" spacing={2} sx={{ gap: '16px' }}>
    <Link
      onClick={onOneTimePayment}
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid rgba(220, 221, 222, 1)',
        borderRadius: '8px',
        width: '100%',
        padding: '16px',
        textDecoration: 'none',
      }}
    >
      <img
        alt="unique_payment"
        src="/assets/icons/payments/smartNFCring.svg"
        style={{ width: '80.12px', height: '80px', marginRight: '16px' }}
      />
      <Typography
        sx={{
          fontFamily: 'Montserrat',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '20px',
          color: 'rgba(52, 57, 72, 1)',
        }}
      >
        Pagamento único
      </Typography>
    </Link>
    <Link
      onClick={onRecurringSubscription}
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid rgba(220, 221, 222, 1)',
        borderRadius: '8px',
        width: '100%',
        padding: '16px',
        textDecoration: 'none',
      }}
    >
      <img
        alt="recurring_payment"
        src="/assets/icons/payments/pendingpayment.svg"
        style={{ width: '80.12px', height: '80px', marginRight: '16px' }}
      />
      <Typography
        sx={{
          fontFamily: 'Montserrat',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '20px',
          color: 'rgba(52, 57, 72, 1)',
          whiteSpace: 'nowrap',
        }}
      >
        Assinatura recorrente
      </Typography>
    </Link>
  </Stack>
)

const TypePaymentChoose = ({
  onOneTimePayment,
  onRecurringSubscription,
}: ITypePaymentChoose) => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  return (
    <>
      {isSmallScreen ? (
        <PaymentChooseMobile
          onOneTimePayment={onOneTimePayment}
          onRecurringSubscription={onRecurringSubscription}
        />
      ) : (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'center',
            height: '286px',
            gap: '16px',
          }}
        >
          <Link
            onClick={onOneTimePayment}
            sx={{
              border: '1px solid rgba(220, 221, 222, 1)',
              borderRadius: '8px',
              width: '240px',
              height: '286px',
              padding: '24px',
              gap: '32px',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            <Stack
              direction="column"
              spacing={1}
              alignContent="center"
              alignItems="center"
              sx={{ p: 2 }}
            >
              <img
                alt="unique_payment"
                src="/assets/icons/payments/smartNFCring.svg"
                style={{ width: '100%', height: '200px' }}
              />

              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: 'rgba(52, 57, 72, 1)',
                }}
              >
                Pagamento único
              </Typography>
            </Stack>
          </Link>

          <Link
            onClick={onRecurringSubscription}
            sx={{
              border: '1px solid rgba(220, 221, 222, 1)',
              borderRadius: '8px',
              width: '240px',
              height: '286px',
              padding: '24px',
              gap: '32px',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            <Stack
              direction="column"
              spacing={1}
              alignContent="center"
              alignItems="center"
              sx={{ p: 2 }}
            >
              <img
                alt="unique_payment"
                src="/assets/icons/payments/pendingpayment.svg"
                style={{ width: '100%', height: '200px' }}
              />

              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: 'rgba(52, 57, 72, 1)',
                  whiteSpace: 'nowrap',
                }}
              >
                Assinatura recorrente
              </Typography>
            </Stack>
          </Link>
        </Stack>
      )}
    </>
  )
}

export default TypePaymentChoose
