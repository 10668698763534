import { InputLabel, Stack, useMediaQuery, useTheme } from '@mui/material'
import NumberFunctions from '../../core/functions/NumberFunctions'
import InputSearch from '../Inputs/InputSearch'
import SelectInput from '../SelectInput/SelectInput'
import { statusOptions, ItensOptions, situationStatusOptions } from './constans'
import { DataTableHeaderProps } from './DataTableHeader'

const DataTableHeaderWeb: React.FC<DataTableHeaderProps> = ({
  labelItems = 'registros',
  totalItems,
  status,
  rowsPerPage,
  setSearch,
  executeSearch,
  setStatus,
  handleChangeRowsPerPage,
  situationStatus,
  setSituationStatus,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      sx={{ mb: 2 }}
    >
      {!isMobile && (
        <Stack direction="row" alignItems="center" spacing={2}>
          <InputLabel id="itens-total">
            {totalItems} {labelItems} encontrados
          </InputLabel>
        </Stack>
      )}

      <Stack direction="row" spacing={1} alignItems="center">
        <SelectInput
          value={rowsPerPage}
          onChange={(event) =>
            handleChangeRowsPerPage(
              NumberFunctions.toNumber(event.target.value)
            )
          }
          options={ItensOptions}
          minWidth={107}
          maxWidth="50%"
        />

        <InputSearch
          id="WebSearchMyProducts"
          key="WebSearchMyProducts"
          name="Nome do produto"
          size="small"
          setSearch={setSearch}
          execute={executeSearch}
          adornmentToStart
          removeLabel
          sx={{ flexGrow: 1 }}
        />

        {status && setStatus && (
          <SelectInput
            value={status}
            onChange={(e) =>
              setStatus(e.target.value as 'active' | 'inactive' | 'deleted')
            }
            options={statusOptions}
            minWidth={160}
          />
        )}

        {situationStatus && setSituationStatus && (
          <SelectInput
            value={situationStatus}
            onChange={(e) => setSituationStatus(Number(e.target.value))}
            options={situationStatusOptions}
            minWidth={160}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default DataTableHeaderWeb
