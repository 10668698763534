import { Alert, Box, Chip, Paper, Stack, Typography, styled } from "@mui/material"

export const RecipientStatus = ({ status }: { status: string }) => {
	const handleStatusLabel = () => {
		switch (status) {
			case 'registration': return 'Em registro';
			case 'affiliation': return 'Em afiliação';
			case 'active': return 'Ativo';
			case 'refused': return 'Recusado';
			case 'suspended': return 'Suspenso';
			case 'blocked': return 'Bloqueado';
			case 'inactive': return 'Inativo';
			default: return '';
		}
	}
	const handleStatusColor = () => {
		switch (status) {
			case 'active': return 'success';
			case 'registration': return 'info';
			case 'affiliation': return 'info';
			case 'refused': return 'secondary';
			case 'suspended': return 'warning';
			case 'blocked': return 'error';
			case 'inactive': return 'secondary';
			default: return 'info';
		}
	}

	return (
		<Stack spacing={1} alignItems="left">
			<Stack direction="row" spacing={1} alignContent="center">
				<Chip label={`Situação ${handleStatusLabel()}`} color={handleStatusColor()} />
				{status !== "active" && <Alert severity="warning" elevation={1} >Por favor procure o suporte para verificar a situação do recebedor da conta, para habilitar o saque!</Alert>}
			</Stack>
		</Stack>
	);
}
