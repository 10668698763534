import * as React from 'react'
import {
  Box,
  Typography,
  InputAdornment,
  Button,
  Stack,
  Chip,
  AlertTitle,
  Avatar,
  TextField,
  Paper,
  Tabs,
  Tab,
} from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useNavigate } from 'react-router-dom'
import {
  addDays,
  dateDescription,
  dateTimeString,
  diffDays,
  lastTime,
  resetTime,
} from '../../../core/functions/dateTime'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import Convertions from '../../../core/functions/convertions'
import { TCheckoutOrder } from '../../../core/types/CheckoutOrder'
import CheckoutOrderDetails from '../Orders/CheckoutOrderDetails'
import { useCheckoutOrders } from '../../../core/hooks/useCheckoutOrders'
import { ExportToFile } from '../ExportToFile'
import {
  ExportFileTypeEnum,
  ReportDownloadController,
} from '../../../core/controllers/ReportDownloadController'
import { useDownloadFile } from '../../../core/hooks/useDownloadFile'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import CopiedSnackbar from '../../../components/Snackbar/CopiedSnackbar'
import MethodTypeSmallIcon from '../../../components/Checkout/Icons/MethodTypeSmallIcon'
import LockIcon from '@mui/icons-material/Lock'
import { AuthContext } from '../../../core/context/AuthContext'
import { decodeToken } from 'react-jwt'
import { TAuthTenant } from '../../../core/types/AuthTenant'
import { useCurrentTenantBank } from '../../../core/hooks/useCurrentTenantBank'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'
import { AdvancedFilters } from '../AdvancedFilters'
import { useProductsSimple } from '../../../core/hooks/useProductsSimple'
import { useAffiliatedsSimple } from '../../../core/hooks/useAffiliatedsSimple'
import SimpleCard from '../../../components/Cards/SimpleCard'
import TitlePageTypography from '../../../components/Typographys/TitlePageTypography'
import SubtitlePageTypography from '../../../components/Typographys/SubtitlePageTypography'
import DataTable, {
  IDataTableColumn,
} from '../../../components/Tables/DataTable'
import DataTableCellTypography from '../../../components/Typographys/DataTableCellTypography'
import DataTableSubcellTypography from '../../../components/Typographys/DataTableSubcellTypography'
import { TCheckoutOrderReportTab } from '../../../core/types/Order'
import { TLostSale } from '../../../core/types/LostSale'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }
)

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: { textTransform: 'none' },
  }
}

export default function CheckoutSubscriptionPage() {
  const { user } = React.useContext(AuthContext)
  const json = decodeToken(user?.token ?? '')
  const authTenant = json as TAuthTenant

  const [tabIndex, setTabIndex] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [copied, setCopied] = React.useState(false)
  const [type, setType] = React.useState<TCheckoutOrderReportTab>('approved')
  const [current, setCurrent] = React.useState<TCheckoutOrder | undefined>(
    undefined
  )
  const [open, setOpen] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [errorSending, setErrorSending] = React.useState('')
  const [startDate, setStartdate] = React.useState<Date | null | undefined>(
    resetTime(addDays(-29))
  )
  const [endDate, setEndDate] = React.useState<Date | null | undefined>(
    lastTime()
  )

  const [searchType, setSearchType] = React.useState(0)
  const [openFilter, setOpenFilter] = React.useState(false)
  const [prodSearchList, setProdSearchLists] = React.useState<string[]>([])
  const [affilatedsSearchList, setAffilatedsSearchLists] = React.useState<
    string[]
  >([])
  const [paymentSearchList, setpaymentSearchLists] = React.useState<string[]>(
    []
  )
  const [statusSearchList, setStatusSearchLists] = React.useState<string[]>([])

  const { data: tenantBank } = useCurrentTenantBank({
    tenantId: authTenant.TenantId,
  })
  const { data: products, loading: loadingProducts } = useProductsSimple({
    initialExecute: true,
  })
  const { data: affiliateds, loading: loadingAffiliateds } =
    useAffiliatedsSimple({ initialExecute: true })

  const { loading, data, error, execute, clear } = useCheckoutOrders({
    page,
    rows: rowsPerPage,
    search,
    type,
    report: 'subscription',
    startDate,
    endDate,
    affiliateds: affilatedsSearchList,
    payments: paymentSearchList,
    products: prodSearchList,
    sellerType: searchType,
    status: statusSearchList,
  })

  const columns: readonly IDataTableColumn<TCheckoutOrder | TLostSale>[] = [
    {
      id: 'createdDate',
      label: 'Criado',
      minWidth: 80,
      format: (value) => dateTimeString(new Date(value)),
    },
    {
      id: 'productName',
      label: 'Produto',
      minWidth: 100,
      render(id, value, row) {
        return (
          <Stack direction="column">
            <DataTableCellTypography>{row.productName}</DataTableCellTypography>
            <DataTableSubcellTypography>
              {Convertions.valueProductTypeToString(
                (row as TCheckoutOrder).valueProductType
              )}
            </DataTableSubcellTypography>
          </Stack>
        )
      },
    },
    { id: 'offerName', label: 'Nome da oferta', minWidth: 100 },
    {
      id: 'sellerName',
      label: 'Vendedor',
      minWidth: 100,
      render(id, value, row) {
        return (
          <Stack direction="column">
            <DataTableCellTypography>{row.sellerName}</DataTableCellTypography>
            <DataTableSubcellTypography>
              {Convertions.valueTypeToString(row.valueType)}
            </DataTableSubcellTypography>
          </Stack>
        )
      },
    },
    {
      id: 'name',
      label: 'Cliente',
      minWidth: 100,
      render(id, value, row) {
        return (
          <Stack direction="column">
            <DataTableCellTypography>{row.name}</DataTableCellTypography>
            <DataTableSubcellTypography>{row.email}</DataTableSubcellTypography>
          </Stack>
        )
      },
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      render(id, value, row) {
        return (
          <Stack direction="row" spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              {(row as TCheckoutOrder).inAssurance === true && (
                <LockIcon fontSize="small" color="warning" />
              )}
              <Chip
                label={Convertions.checkoutOrderStatusToString(
                  (row as TCheckoutOrder).status
                )}
                size="small"
                color={
                  (row as TCheckoutOrder).status === 'pending'
                    ? 'default'
                    : (row as TCheckoutOrder).status === 'failed'
                      ? 'error'
                      : (row as TCheckoutOrder).status === 'paid'
                        ? 'success'
                        : 'warning'
                }
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <MethodTypeSmallIcon
                methodType={(row as TCheckoutOrder).paymentyMethod}
              />
              <Typography variant="overline">
                {Convertions.paymentMethodToString(
                  (row as TCheckoutOrder).paymentyMethod
                )}
              </Typography>
            </Stack>
          </Stack>
        )
      },
    },
    {
      id: 'frequency',
      label: 'Frequência',
      minWidth: 100,
      format: (value) => Convertions.productFrequencyToString(value),
    },
    {
      id: 'value',
      label: 'Comissão',
      minWidth: 60,
      align: 'right',
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
    {
      id: 'monthlyFeesPaid',
      label: 'Assinaturas',
      minWidth: 60,
      align: 'right',
      format: (value) => (value || 0).toString(),
    },
  ]

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }
  }, [data, rowsPerPage])

  const navigate = useNavigate()

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        setType('approved')
        break
      case 1:
        setType('all')
        break
      case 2:
        navigate('/checkout/open?type=subscription')
        break
      case 3:
        navigate('/checkout/abandoned?type=subscription')
        break
    }

    setTabIndex(newValue)
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleChangeItem = (checkoutOrder: TCheckoutOrder | TLostSale) => {
    setCurrent(checkoutOrder as TCheckoutOrder)
    setOpen(true)
  }

  const handleClose = () => {
    setCurrent(undefined)
    setOpen(false)
  }

  const handleDownloadFile = (typeFile: ExportFileTypeEnum) => {
    return ReportDownloadController.ordersGetAll({
      fileType: typeFile,
      search,
      type,
      report: 'subscription',
      startDate,
      endDate,
    })
  }

  const handlerDownloadingError = (e: string) => setErrorSending(e)
  const getFileName = (type: ExportFileTypeEnum) =>
    `report_${new Date().toISOString().replace(':', '_').replace('.', '_')}.${
      type === ExportFileTypeEnum.XLS ? 'xlsx' : 'csv'
    }`

  const { download, downloading } = useDownloadFile({
    apiDefinition: handleDownloadFile,
    onError: handlerDownloadingError,
    getFileName,
  })

  const downloadCallback = React.useCallback(
    (type: ExportFileTypeEnum) => download(type),
    [download]
  )

  const handleConvertToXLS = async () => {
    if (isValidDownloadReport()) downloadCallback(ExportFileTypeEnum.XLS)
  }

  const handleConvertToCSV = async () => {
    if (isValidDownloadReport()) downloadCallback(ExportFileTypeEnum.CSV)
  }

  const isValidDownloadReport = (): boolean => {
    if (startDate === null || startDate === undefined) {
      setErrorSending('Necessário informar o período inicial.')
      return false
    }

    if (endDate === null || endDate === undefined) {
      setErrorSending('Necessário informar um período final.')
      return false
    }

    if (diffDays(endDate, startDate) > 30) {
      setErrorSending('Não informar um período maior que 30 dias')
      return false
    }

    return true
  }

  const isTenantBank = () => {
    return (
      tenantBank &&
      tenantBank !== null &&
      tenantBank.accountNumber &&
      tenantBank.accountNumber !== null &&
      tenantBank.bankCode &&
      tenantBank.bankCode !== null
    )
  }

  const handleDateFilter = (value: any) => {
    setStartdate(resetTime(value.startDate))
    setEndDate(lastTime(value.endDate))
  }

  const handleOpenFilter = () => setOpenFilter(true)

  const handleCloseFilter = () => setOpenFilter(false)

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        alignContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <TitlePageTypography>Assinaturas</TitlePageTypography>
          <SubtitlePageTypography>
            {`Filtro do período de ${dateDescription(startDate)} à ${dateDescription(endDate)}`}
          </SubtitlePageTypography>
        </Box>
        <TextField
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              execute()
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img alt="Pesquisar" src="/assets/icons/search/search.svg" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            sx: {
              ml: 4.5,
              color: 'black',
            },
            shrink: search !== '',
            required: false,
          }}
          sx={(theme) => ({
            backgroundColor: 'white',
            '& .Mui-focused .MuiInputAdornment-root': {
              color: theme.palette.primary.main,
            },
            '& .Mui-error .MuiInputAdornment-root': {
              color: theme.palette.error.main,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              px: 5.5,
            },
          })}
          label="Pesquisar"
        />
        <Button
          sx={{
            p: 0,
            m: 0,
            minWidth: 0,
            borderRadius: '8px',
          }}
          onClick={handleOpenFilter}
        >
          <Paper
            elevation={0}
            sx={{
              border: '1px solid #C5C6C9',
              borderRadius: '8px',
              p: '8px',
              fontSize: '0rem',
            }}
          >
            <img src="/assets/icons/menu/funnel/funnel.svg" alt="Filtro" />
          </Paper>
        </Button>
      </Stack>

      {isTenantBank() && error !== '' && (
        <Box sx={{ mb: 2 }}>
          <Alert onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Box>
      )}

      {!isTenantBank() && error !== '' && (
        <Alert severity="warning" variant="standard" sx={{ mb: 2 }}>
          <AlertTitle>Dados bancário não fornecido</AlertTitle>
          Para vender é necessário informar uma conta bancária em{' '}
          <i>Menu - Configurações - Dados Bancário</i>
        </Alert>
      )}

      <Stack direction="row" spacing={3} sx={{ mb: 2 }}>
        <SimpleCard sx={{ px: 4 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar sx={{ width: 40, height: 40, bgcolor: '#F2F2FF' }}>
              <img alt="Vendas" src="/assets/icons/payments/pay.svg" />
            </Avatar>
            <Stack direction="column">
              <Typography
                variant="body2"
                color="#707275"
                fontWeight={500}
                fontSize="14px"
                lineHeight="14px"
              >
                Vendas encontradas
              </Typography>
              <Typography
                variant="h4"
                color="#38393B"
                fontWeight={600}
                fontSize="24px"
                lineHeight="32px"
              >
                {data.total}
              </Typography>
            </Stack>
          </Stack>
        </SimpleCard>
        <SimpleCard sx={{ px: 4 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar sx={{ width: 40, height: 40, bgcolor: '#F2F2FF' }}>
              <img alt="Vendas" src="/assets/icons/payments/pay.svg" />
            </Avatar>
            <Stack direction="column">
              <Typography
                variant="body2"
                color="#707275"
                fontWeight={500}
                fontSize="14px"
                lineHeight="14px"
              >
                Valor líquido
              </Typography>
              <Typography
                variant="h4"
                color="#38393B"
                fontWeight={600}
                fontSize="24px"
                lineHeight="32px"
              >
                {NumberFunctions.formatMoneyDefault(
                  NumberFunctions.toNumber(data.info)
                )}
              </Typography>
            </Stack>
          </Stack>
        </SimpleCard>
      </Stack>

      <Stack
        direction="row"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        alignItems="center"
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          sx={{ flexGrow: 1 }}
        >
          <Tab label="Aprovadas" {...a11yProps(0)} />
          <Tab label="Todas" {...a11yProps(1)} />
          <Tab label="Abertas" {...a11yProps(2)} />
          <Tab label="Abandonadas" {...a11yProps(3)} />
        </Tabs>
        <ExportToFile
          convertToCSV={handleConvertToCSV}
          convertToXLS={handleConvertToXLS}
        />
      </Stack>

      <DataTable<TCheckoutOrder | TLostSale>
        columns={columns}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        onItemClick={handleChangeItem}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Box sx={{ height: 80 }} />

      <CheckoutOrderDetails
        checkoutOrder={current}
        open={open}
        isOpened={tabIndex === 2}
        handleClose={handleClose}
        setOpen={setOpen}
        setSending={setSending}
        setSuccess={setSuccess}
        setError={setErrorSending}
        execute={execute}
        setCopied={setCopied}
      />

      <AdvancedFilters
        open={openFilter}
        setOpen={setOpenFilter}
        affiliateds={affiliateds}
        products={products}
        handleCloseFilter={handleCloseFilter}
        handleDateFilter={handleDateFilter}
        typeFilterValue={searchType}
        startDate={startDate}
        endDate={endDate}
        startDateDefault={addDays(-29)}
        endDateDefault={new Date()}
        onlyRangeDate={false}
        handleTypeFilter={(e: number) => setSearchType(e)}
        handleProductFilter={(e: string[]) => setProdSearchLists(e)}
        handleAffiliatedFilter={(e: string[]) => setAffilatedsSearchLists(e)}
        handlePaymentMethodFilter={(e: string[]) => setpaymentSearchLists(e)}
        handlStatusFilter={(e: string[]) => setStatusSearchLists(e)}
      />

      <LoadingBackdrop open={loading || sending} />

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Alterado com sucesso
      </SuccessSnackbar>

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />
    </Box>
  )
}
