import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LogoutIcon from '@mui/icons-material/Logout'
import { Outlet, useNavigate } from 'react-router-dom'
import AccountCircle from '@mui/icons-material/AccountCircle'
import {
  Avatar,
  Button,
  Divider,
  InputAdornment,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import MuiDrawer from '@mui/material/Drawer'
import { AuthContext } from '../../core/context/AuthContext'
import { grey } from '@mui/material/colors'
import DrawerHeader from '../DrawerHeader'
import SearchIcon from '@mui/icons-material/Search'
import { HtmlTooltip } from '../../components/Tooltip/HtmlTooltip'
import { ITenant, TTenantSettings } from '../../core/types/Tenant'
import Hosts from '../../core/functions/hosts'
import CopiedSnackbar from '../../components/Snackbar/CopiedSnackbar'
import { RWebShare } from 'react-web-share'
import IconFilterButton from '../../components/Buttons/IconFilterButton'

const drawerWidth = 240

interface Props {
  window?: () => Window
  drawer: JSX.Element
  open: boolean
  setOpen: (newOpen: boolean) => void
  tenant?: ITenant | null
  tenantSettings?: TTenantSettings | null
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: grey[50],
  color: '#707275',
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function DesktopLayout(props: Props) {
  const { window, drawer, open, tenant, tenantSettings, setOpen } = props
  const { user, setUser } = React.useContext(AuthContext)

  const theme = useTheme()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  //const [search, setSearch] = React.useState("");
  const [copiedLink, setCopiedLink] = React.useState(false)

  const recruitingUrl = `${process.env.REACT_APP_PUBLIC_HOST}/signup?inid=${tenant?.token}`

  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={() => Hosts.openNewBlank(recruitingUrl)}
        sx={{ color: 'white' }}
      >
        ABRIR LINK
      </Button>
    </React.Fragment>
  )

  const handleChangeCopyURL = async () => {
    await Hosts.copyTextToClipboard(recruitingUrl)
    setCopiedLink(true)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const toogleDrawer = () => {
    setOpen(!open)
  }

  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    setUser(null)
    navigate('/')
    handleClose()
  }

  const handleSettings = (event: React.MouseEvent<HTMLElement>) => {
    navigate('/settings')
    handleClose()
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  //search bar with no function yet
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }))
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }))
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      //vertical effect with padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '42%',
        '&:focus': {
          width: '50%',
        },
      },
    },
  }))

  //Style of the icon button for close and open the drawer
  const CustomIcon = styled('div')({
    width: 32,
    height: 32,
    border: '1px solid #BCC1FF',
    borderRadius: '50%',
    backgroundColor: '#ffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    m: 0,
  })
  //Style arrow insite of the icon button
  const ArrowIconOpen = styled(ArrowForwardIcon)({
    color: '#707275',
  })
  const ArrowIconClose = styled(ArrowBackIcon)({
    color: '#707275',
  })
  //Personalization icon button
  const CustomIconButton = styled(IconButton)({
    p: '0px',
    padding: '0px',
    minWidth: 0,
    fontSize: '0px',
  })

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        open={open}
        /*sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: grey[50],
                    color: "#707275",
                }}*/
      >
        <Toolbar>
          {/* <Stack sx={{ position: "fixed", right: "0" }}>
                        <CustomIconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginLeft: 5,
                                marginRight: 5,
                                ...(open && { display: "none" }),
                            }}
                        >
                            <CustomIcon>
                                <ArrowIconOpen />
                            </CustomIcon>
                             <MenuIcon />  
                        </CustomIconButton>
                    </Stack> */}

          <Box paddingBottom="2" paddingTop="2" sx={{ flexGrow: 1 }}>
            <Stack direction="row" justifyContent="end" alignItems="center">
              {/*<Search sx={{ marginRight: "auto" }}>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Pesquisar…"
                                    inputProps={{ "aria-label": "search" }}
                                    sx={{
                                        fontStyle: "#707275",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        lineHeight: "18px",
                                    }}
                                />
                                </Search>*/}

              {tenant &&
                tenantSettings?.acceptIndicationUseTerms &&
                tenantSettings.disableIndicatedComissions === false && (
                  <Box
                    width="310px"
                    sx={{
                      borderRadius: '8px',
                      backgroundColor: 'white',
                      px: '12px',
                      py: '6px',
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="standard"
                      size="small"
                      value={recruitingUrl}
                      aria-readonly
                      sx={{
                        gap: '12px',
                        fontStyle: '#707275',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '18px',
                      }}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <HtmlTooltip
                              title={<Typography>Copiar URL</Typography>}
                            >
                              <IconButton
                                size="small"
                                onClick={handleChangeCopyURL}
                              >
                                <img src="/assets/icons/copy/copy.svg" alt="" />
                              </IconButton>
                            </HtmlTooltip>
                            <HtmlTooltip
                              title={<Typography>Compartilhar URL</Typography>}
                            >
                              <RWebShare
                                data={{
                                  text: 'Convido você a fazer parte da maior plataforma para afiliados',
                                  url: recruitingUrl,
                                  title: 'Celetus',
                                }}
                                onClick={() =>
                                  console.log('shared successfully!')
                                }
                              >
                                <IconButton
                                  size="small"
                                  aria-label="account of current user"
                                  aria-controls="menu-appbar"
                                  aria-haspopup="true"
                                  sx={{ ml: 0.5 }}
                                >
                                  <img
                                    src="/assets/icons/share/share.svg"
                                    alt=""
                                  />
                                </IconButton>
                              </RWebShare>
                            </HtmlTooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                )}
              {/* <Stack direction="row" alignItems="center" marginLeft="5px">
                                <IconFilterButton
                                    icon={<img src="/assets/icons/bell/bell.svg" alt="Noticações" />}
                                    backgroundColor="white"
                                    onClick={handleChangeCopyURL} //provisório
                                />
                            </Stack> */}
            </Stack>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center">
            <Tooltip title="Configurações da conta">
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{
                  ml: 1,
                  p: '0px',
                  fontSize: '0px',
                  width: '48px',
                  height: '48px',
                }}
              >
                <AccountCircle sx={{ fontSize: '40px', p: '0px' }} />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={handleSettings}>
                <Avatar /> Configurações
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Sair
              </MenuItem>
            </Menu>
            <Typography fontSize="16px" fontWeight="600" lineHeight="20px">
              {user?.unique_name}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          //width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            //width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#FFFFFF',
            //color: "#9A9B9E",
            borderColor: '#BCC1FF',
          },
        }}
      >
        <DrawerHeader>
          <Box width="100%" height="100%" sx={{ pt: 1.5, pl: !open ? 0.6 : 0 }}>
            {open && (
              <img
                src="/assets/logo/loginLogo.svg"
                alt="Logo"
                style={{
                  width: '100%',
                  height: '32px',
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
              />
            )}
            {!open && (
              <img
                src="/assets/logo/icon.svg"
                alt="Icone"
                style={{
                  height: '32px',
                  objectFit: 'contain',
                }}
              />
            )}
          </Box>
          <Stack
            sx={{
              position: 'fixed',
              left: open ? drawerWidth - 16 : 48,
              transition: 'all .15s',
            }}
          >
            <CustomIconButton onClick={toogleDrawer}>
              <CustomIcon>
                {!open ? <ArrowIconOpen /> : <ArrowIconClose />}
              </CustomIcon>
            </CustomIconButton>
          </Stack>
        </DrawerHeader>
        <Divider sx={{ borderColor: '#BCC1FF' }} />
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {drawer}
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>

      <CopiedSnackbar
        open={copiedLink}
        onClose={() => setCopiedLink(false)}
        action={action}
      />
    </Box>
  )
}
