import api, { getResponseError } from "../../services/api";
import { TMemberService, TMemberServicePagination, TMemberServiceType } from "../types/MemberService";
import { IErrorResponse, IResponse } from "../types/ErrorResponse";

interface IGetCademisProp {
    page: number;
    rows: number;
    search?: string;
    type: TMemberServiceType;

}

const getAll = async ({ page, rows, search, type }: IGetCademisProp): Promise<TMemberServicePagination | IErrorResponse> => {
    try {
        var path = `/MemberService/settings?PageNumber=${page}&RowsPerPage=${rows}&type=${type}`;

        if (search && search !== '') {
            path += `&Search=${search}`;
        }

        const { data } = await api.get<TMemberServicePagination>(path);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const get = async ({ id }: { id: string }): Promise<TMemberService | IErrorResponse> => {
    try {
        const { data } = await api.get<TMemberService>(`/MemberService/settings/${id}`);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getList = async (): Promise<TMemberService[] | IErrorResponse> => {
    try {
        const { data } = await api.get<TMemberService[]>(`/MemberService/settings/list`);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const insert = async ({ data }: { data: TMemberService }): Promise<IResponse> => {
    try {
        const { data: response } = await api.post('/MemberService/settings', data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
                data: response,
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error: any) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const update = async ({ data }: { data: TMemberService }): Promise<IResponse> => {
    try {
        const { data: response } = await api.put('/MemberService/settings', data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const remove = async ({ id }: { id: string }): Promise<IResponse> => {
    try {
        await api.delete(`/MemberService/settings/${id}`);
        return {
            success: true,
            code: 0,
            error: "",
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
};

const MemberServiceController = {
    getAll,
    get,
    getList,
    insert,
    update,
    remove,
}

export default MemberServiceController;