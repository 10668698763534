import React, { useRef } from 'react'
import { IErrorResponse } from '../types/ErrorResponse'
import UtmfyController from '../controllers/UtmfyController'
import { TUtmfyPagination } from '../types/Utmfy'

export interface IUtmfyProps {
  page: number
  rows: number
}

const valueDefault = {
  items: [],
  page: 0,
  rowsPerPage: 0,
  totalPages: 0,
  total: 0,
  info: 'string',
  hasBackPage: false,
  hasNextPage: false,
  isLastPage: false,
}

export const useUtmfyService = ({ page, rows }: IUtmfyProps) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<TUtmfyPagination>(valueDefault)
  const [error, setError] = React.useState<string>('')

  const executing = useRef(false)

  const execute = async () => {
    if (!loading && !executing.current) {
      executing.current = true
      try {
        setLoading(true)

        if (error !== '') {
          setError('')
        }

        const response = await UtmfyController.getAll({
          page,
          rows,
        })
        const responseError = response as IErrorResponse
        const responseData = response as TUtmfyPagination

        if (responseError.code) {
          setError(responseError.error)
          setData(valueDefault)
        } else {
          setData(responseData)
        }
      } catch (e) {
        var message = 'Erro não identificado'
        if (typeof e === 'string') {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        setError(message)
      } finally {
        setLoading(false)
        executing.current = false
      }
    }
  }

  React.useEffect(() => {
    execute()
  }, [page, rows])

  const clear = () => {
    setError('')
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  }
}
