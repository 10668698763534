import { Breakpoint } from '@mui/material'
import { useResponsive } from '../../core/hooks/useResponsive'
import { TConfirmDialogButton } from './DialogActions/DialogActionButton'
import SwipeableDrawerRightDialogMobile from './SwipeableDrawerRightDialogMobile'
import SwipeableDrawerRightDialogWeb from './SwipeableDrawerRightDialogWeb'

export interface ISwipeableDrawerRightDialogProps {
  open: boolean
  title: string | React.ReactNode
  subtitle?: string
  setOpen: (value: boolean) => void
  onClose: () => void
  children?: JSX.Element
  backgroundColor?: string
  buttons?: TConfirmDialogButton[] | undefined
  buttonsDisabled?: boolean
  noValidate?: boolean | undefined
  component?: 'form'
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined
  maxWidth?: false | Breakpoint | undefined
  hideButtons?: boolean
}

const SwipeableDrawerRightDialog = (
  props: ISwipeableDrawerRightDialogProps
) => {
  const mdUp = useResponsive('up', 'md')

  if (mdUp) return <SwipeableDrawerRightDialogWeb {...props} />

  return <SwipeableDrawerRightDialogMobile {...props} />
}

export default SwipeableDrawerRightDialog
