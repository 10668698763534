import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import GridOnIcon from '@mui/icons-material/GridOn'
import DescriptionIcon from '@mui/icons-material/Description'
import GetAppIcon from '@mui/icons-material/GetApp'
import { useEffect, useRef, useState } from 'react'

export interface ExportToFileProps {
  convertToXLS: () => void
  convertToCSV: () => void
}

export const ExportToFile = ({
  convertToCSV,
  convertToXLS,
}: ExportToFileProps) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <Box style={{ marginLeft: 'auto' }}>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={
          <img
            src="/assets/icons/downloads/download.svg"
            alt="download-icon-sells"
          />
        }
        sx={{ textTransform: 'none' }}
      >
        <Typography
          color="rgba(99, 126, 239, 1)"
          fontFamily={'Montserrat'}
          fontSize={'14px'}
          lineHeight={'18px'}
          fontWeight={500}
        >
          Exportar
        </Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="left-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={(e) => {
                      convertToXLS()
                      handleClose(e)
                    }}
                  >
                    <ListItemIcon>
                      <GridOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="Excel" />
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      convertToCSV()
                      handleClose(e)
                    }}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="CSV" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}
