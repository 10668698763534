export enum TWebhookEventEnum {
  BoletoGenerated = 1,
  PixGenerated,
  PurchaseDeclined,
  ApprovedPurchase,
  Chargeback,
  ExpiredPurchase,
  LatePurchase,
  WaitingPayment,
  AbandonedCheckout,
  Protested,
  Refunded,
}

export enum TWebhookGenerateEnum {
  BoletoGenerated = 1,
  PixGenerated,
  PurchaseDeclined,
  ApprovedPurchase,
  Chargeback,
  ApprovedPurchaseComplete,
  PurchaseDeclinedBlocked,
  PurchaseDeclinedNoFunds,
  ExpiredPurchaseExpired,
  LatePurchaseOverdue,
  WaitingPayment,
  ProcessingTransaction,
  AbandonedCheckout,
  Protested,
  Refunded,
}

export enum TWebhookEventStatusEnum {
  Approved = 1,
  Complete,
  Blocked,
  PurchaseDeclined,
  NoFunds,
  Expired,
  Overdue,
  WaitingPayment,
  ProcessingTransaction,
  Abandoned,
  Protested,
  Refunded,
  Chargeback,
  UnderAnalisys,
}

export type TWebhookSend = {
  id: string
  identifier: string
  url: string
  productName?: string
  customerName?: string
  customerEmail?: string
  event?: TWebhookGenerateEnum
  returnCode?: string
  createdDate: Date
  jsonSend: string
  jsonReturn: string
}

export type TWebhookSendsPagination = {
  items: TWebhookSend[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
}

export type TWebhook = {
  id?: string
  identifier: string
  url: string
  numberAttempts?: number
  allProducts: boolean
  productId?: string
  productName?: string
  events?: number[]
  productIds?: string[]
  isSendToOrderbumps: boolean
}

export type TWebhooksPagination = {
  items: TWebhook[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
}
