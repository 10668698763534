import React from "react";
import { TCustomerPagination } from "../types/Customer";
import CustomerController from "../controllers/CustomerController";
import { IErrorResponse } from "../types/ErrorResponse";

export interface ICustomersProp {
    page: number;
    rows: number;
    name?: string;
}

export const useCustomers = ({ page, rows, name }: ICustomersProp) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<TCustomerPagination>({
        items: [],
        page: 0,
        rowsPerPage: 0,
        totalPages: 0,
        total: 0,
    });
    const [error, setError] = React.useState<string>('');

    const execute = async () => {
        if (!loading) {
            try {
                setLoading(true);

                if (error !== '') {
                    setError('');
                }

                const response = await CustomerController.getAll({ page, rows, name });
                const responseError = response as IErrorResponse;
                const responseData = response as TCustomerPagination;

                if (responseError.code) {
                    setError(responseError.error);
                } else {
                    setData(responseData);
                }
            } catch (e) {
                var message = 'Erro não identificado';
                if (typeof e === "string") {
                    message = e.toUpperCase();
                } else if (e instanceof Error) {
                    const err = e as Error;
                    message = err.message;
                }
                setError(message);
            } finally {
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        execute();
    }, [page, rows, name]);

    const clear = () => {
        setError('');
    }

    return {
        loading,
        data,
        error,
        execute,
        clear,
    };
}