import React, { useRef } from 'react'
import { IErrorResponse } from '../types/ErrorResponse'
import { TSituation } from '../types/Situation'
import ProductSituationController from '../controllers/ProductSituationController'

export interface ISituationProps {
  productId: string
}

export const useSituationService = ({ productId }: ISituationProps) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<TSituation[]>([])
  const [error, setError] = React.useState<string>('')

  const executing = useRef(false)

  const execute = async () => {
    if (!loading && !executing.current) {
      executing.current = true
      try {
        setLoading(true)

        if (error !== '') {
          setError('')
        }

        const response = await ProductSituationController.getAll({
          productId,
        })
        const responseError = response as IErrorResponse
        const responseData = response as TSituation[]

        if (responseError.code) {
          setError(responseError.error)
          setData([])
        } else {
          setData(responseData)
        }
      } catch (e) {
        var message = 'Erro não identificado'
        if (typeof e === 'string') {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        setError(message)
      } finally {
        setLoading(false)
        executing.current = false
      }
    }
  }

  React.useEffect(() => {
    execute()
  }, [])

  const clear = () => {
    setError('')
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  }
}
