import { Stack, SxProps, Theme } from '@mui/material'

interface ListItemDataProps {
  key?: React.Key | null | undefined
  sx?: SxProps<Theme> | undefined
  children?: React.ReactNode
  spacing?: number | undefined
}

const ListItemData = ({
  key,
  sx,
  children,
  spacing = 1,
}: ListItemDataProps) => {
  const sxDefault = {
    border: '1px solid #DCDDDE',
    borderRadius: '4px',
    px: 2,
    py: 1,
    width: '100%',
  }
  const style = sx ? { ...sxDefault, ...sx } : sxDefault

  return (
    <Stack
      key={key}
      direction="row"
      spacing={spacing}
      alignContent="center"
      alignItems="center"
      sx={style}
    >
      {children}
    </Stack>
  )
}

export default ListItemData
