import { TActiveCampaignItemCustomFieldType, TActiveCampaignItemTagType, TActiveCampaignItemType } from "../types/ActiveCampaign";

const salesTypes = [
    TActiveCampaignItemType.CartAbandonment,
    TActiveCampaignItemType.AwaitingPayment,
    TActiveCampaignItemType.TicketIssued,
    TActiveCampaignItemType.PurchaseApproved,
    TActiveCampaignItemType.DelayedPurchase,
    TActiveCampaignItemType.PurchaseCanceled,
    TActiveCampaignItemType.CompletePurchase,
    TActiveCampaignItemType.PurchaseInReversal,
    TActiveCampaignItemType.ExpiredPurchase,
    TActiveCampaignItemType.ClaimedPurchase,
    TActiveCampaignItemType.PurchaseRefunded,
    TActiveCampaignItemType.GeneratedPix,
];

const affiliateTypes = [
    TActiveCampaignItemType.MembershipRequestReceived,
    TActiveCampaignItemType.AffiliationApproved,
    TActiveCampaignItemType.MembershipCanceled,
    TActiveCampaignItemType.CommissionUpdate,
]

const types = [
    ...salesTypes,
    ...affiliateTypes,
];

const customFieldTypes = [
    TActiveCampaignItemCustomFieldType.textarea,
    TActiveCampaignItemCustomFieldType.text,
    TActiveCampaignItemCustomFieldType.date,
    TActiveCampaignItemCustomFieldType.dropdown,
    TActiveCampaignItemCustomFieldType.multiselect,
    TActiveCampaignItemCustomFieldType.radio,
    TActiveCampaignItemCustomFieldType.checkbox,
    TActiveCampaignItemCustomFieldType.hidden,
    TActiveCampaignItemCustomFieldType.datetime,
];

const tagTypes = [
    TActiveCampaignItemTagType.template,
    TActiveCampaignItemTagType.contact,
];

const typeToString = (type?: TActiveCampaignItemType | null) => {
    switch (type) {
        case TActiveCampaignItemType.CartAbandonment: return "Abandono de carrinho";
        case TActiveCampaignItemType.AwaitingPayment: return "Aguardando pagamento";
        case TActiveCampaignItemType.TicketIssued: return "Boleto emitido";
        case TActiveCampaignItemType.PurchaseApproved: return "Compra aprovada";
        case TActiveCampaignItemType.DelayedPurchase: return "Compra atrasada";
        case TActiveCampaignItemType.PurchaseCanceled: return "Compra cancelada";
        case TActiveCampaignItemType.CompletePurchase: return "Compra completa";
        case TActiveCampaignItemType.PurchaseInReversal: return "Compra em chargeback";
        case TActiveCampaignItemType.ExpiredPurchase: return "Compra expirada";
        case TActiveCampaignItemType.ClaimedPurchase: return "Compra reclamada";
        case TActiveCampaignItemType.PurchaseRefunded: return "Compra reembolsada";
        case TActiveCampaignItemType.GeneratedPix: return "Pix Gerado";

        case TActiveCampaignItemType.MembershipRequestReceived: return "Solicitação de afiliação recebida";
        case TActiveCampaignItemType.AffiliationApproved: return "Afiliação aprovada";
        case TActiveCampaignItemType.MembershipCanceled: return "Afiliação cancelada ou bloqueada";
        case TActiveCampaignItemType.CommissionUpdate: return "Comissão atualização";

        default: return "";
    }
}

const customFieldTypeToString = (type?: TActiveCampaignItemCustomFieldType) => {
    switch (type) {
        case TActiveCampaignItemCustomFieldType.textarea: return "textarea";
        case TActiveCampaignItemCustomFieldType.text: return "text";
        case TActiveCampaignItemCustomFieldType.date: return "date";
        case TActiveCampaignItemCustomFieldType.dropdown: return "dropdown";
        case TActiveCampaignItemCustomFieldType.multiselect: return "multiselect";
        case TActiveCampaignItemCustomFieldType.radio: return "radio";
        case TActiveCampaignItemCustomFieldType.checkbox: return "checkbox";
        case TActiveCampaignItemCustomFieldType.hidden: return "hidden";
        case TActiveCampaignItemCustomFieldType.datetime: return "datetime";
        default: return "";
    }
}

const tagTypeToString = (type?: TActiveCampaignItemTagType) => {
    switch (type) {
        case TActiveCampaignItemTagType.template: return "Template";
        case TActiveCampaignItemTagType.contact: return "Contato";
        default: return "";
    }
}

const ActiveCampaignFunctions = {
    salesTypes,
    affiliateTypes,
    types,
    customFieldTypes,
    tagTypes,
    typeToString,
    customFieldTypeToString,
    tagTypeToString,
}

export default ActiveCampaignFunctions;