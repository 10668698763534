import { Box, Button, Container, Stack, Typography } from "@mui/material";
import AppBlockingIcon from '@mui/icons-material/AppBlocking';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';

const UnauthorizedPage = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="sm">
            <Stack
                direction={"column"}
                width={"100%"}
                height="90vh"
                sx={{ p: 3 }}
                spacing={3}
                alignItems="center"
                justifyContent="center"
            >
                <Stack direction="row" spacing={3} alignContent="center" alignItems="center">
                    <AppBlockingIcon color="primary" sx={{ fontSize: "5rem" }} />
                    <Stack direction="column">
                        <Typography variant="h4" fontWeight={500}>Não autorizado!</Typography>
                        <Typography variant="body1">Desculpa, você não está autorizado a acessar.</Typography>
                        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                            <Button
                                variant="text"
                                size="small"
                                startIcon={<ArrowBackIcon />}
                                onClick={() => navigate(-1)}
                            >
                                Página anterior
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    );
}

export default UnauthorizedPage;