import React, { useCallback, useRef } from "react";
import { IErrorResponse } from "../types/ErrorResponse";
import OrderController from "../controllers/OrderController";
import { TCheckoutOrderAnticipationPagination, TCheckoutOrderPagination } from "../types/CheckoutOrder";

export interface ICheckoutOrderAnticipationsProp {
    page: number;
    rows: number;
    search?: string;
    startDate?: Date | null | undefined;
    endDate?: Date | null | undefined;
    status?: string[] | null;
}

const valueDefault = {
    items: [],
    page: 0,
    rowsPerPage: 0,
    totalPages: 0,
    total: 0,
};

export const useCheckoutOrderAnticipations = ({
    page,
    rows,
    search,
    startDate,
    endDate,
    status,
}: ICheckoutOrderAnticipationsProp) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<TCheckoutOrderAnticipationPagination>(valueDefault);
    const [error, setError] = React.useState<string>("");

    const executing = useRef(false);

    const execute = useCallback(async () => {
        if (!loading && !executing.current) {
            executing.current = true;
            try {
                setLoading(true);

                if (error !== "") {
                    setError("");
                }

                const response = await OrderController.getAnticipations({
                    page,
                    rows,
                    search,
                    startDate,
                    endDate,
                    status,
                });

                const responseError = response as IErrorResponse;
                const responseData = response as TCheckoutOrderAnticipationPagination;

                if (responseError.code) {
                    setError(responseError.error);
                    setData(valueDefault);
                } else {
                    setData(responseData);
                }
            } catch (e) {
                var message = "Erro não identificado";
                if (typeof e === "string") {
                    message = e.toUpperCase();
                } else if (e instanceof Error) {
                    const err = e as Error;
                    message = err.message;
                }
                setError(message);
            } finally {
                setLoading(false);
                executing.current = false;
            }
        }
    }, [error, loading, page, rows, search, startDate, endDate, status]);

    React.useEffect(() => {
        execute();
    }, [page, rows, startDate, endDate, status]);

    const clear = () => {
        setError("");
    };

    return {
        loading,
        data,
        error,
        execute,
        clear,
    };
};
