export const maskPhoneNumber = (value: String | undefined) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})(\d+?)/, '$1')
}

export const maskCpfNumber = (v: String | undefined): string => {
    if (!v) return '';

    v = v.replace(/\D/g, '')
    v = v.replace(/^(\d{3})(\d)/g, '$1.$2')
    v = v.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3')
    v = v.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4')
    v = v.replace(/^(\d{3})\.(\d{3})\.(\d{3})\/(\d{2})(\d)/, '$1.$2.$3-$4')
    return v.substring(0, 14);
}

export const maskCnpjNumber = (value: String | undefined) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

export const maskCpfCnpjNumber = (v: String | undefined): string => {
    if (!v) return '';
    if (v.replace(/\D/g, '').length <= 11)
        return maskCpfNumber(v)
    else
        return maskCnpjNumber(v);
}


export const maskCepNumber = (value: String | undefined) => {
    if (!value) return '';
    return value.replace(/\D/g, "")
        .replace(/^(\d{5})(\d{3})+?$/, "$1-$2")
        .replace(/(-\d{3})(\d+?)/, '$1');
}

export const maskMonthYear = (value: String | undefined) => {
    if (!value) return '';
    return value.replace(/\D/g, "")
        .replace(/^(\d{2})(\d)/g, '$1/$2')
        .replace(/^(\d{2})\/(\d{2})(\d)/, '$1');
}

export const maskCardNumber = (v: String | undefined): string => {
    if (!v) return '';

    v = v.replace(/\D/g, '')
    v = v.replace(/^(\d{4})(\d)/g, '$1 $2')
    v = v.replace(/^(\d{4}) (\d{4})(\d)/, '$1 $2 $3')
    v = v.replace(/^(\d{4}) (\d{4}) (\d{4})(\d)/, '$1 $2 $3 $4')
    v = v.replace(/^(\d{4}) (\d{4}) (\d{4}) (\d{4})(\d)/, '$1 $2 $3 $4')
    return v.substring(0, 19);
}

export const maskPercentage = (v?: string | number): string => {
    if (!v) return '00,00';

    if ((typeof v) === 'number') {
        v = Number(v).toFixed(2);
    }

    v = numberOnly(v.toString());
    v = Number(v).toString();
    v = v.padStart(4, '0');

    if (v.length > 4) {
        v = v.substring(0, 4);
    }

    v = v.replace(/^(\d{2})(\d{2})/g, '$1,$2');
    return v.substring(0, 5);
}

export const maskPercentage100 = (v?: string | number): string => {
    const old = v;
    if (!v) return '000,00';

    if ((typeof v) === 'number') {
        v = Number(v).toFixed(2);
    }

    v = numberOnly(v.toString());
    v = Number(v).toString();
    v = v.padStart(5, '0');

    if (v.length > 5) {
        v = v.substring(0, 5);
    }

    v = v.replace(/^(\d{3})(\d{2})/g, '$1,$2');
    v = v.substring(0, 6);

    const value = Number(v.replaceAll('.', '').replaceAll(',', '.'));
    if (value > 100) {
        return `0${maskPercentage(old)}`;
    }

    return v;
}

export const maskMoney = (v?: string | number): string => {
    const old = v;
    if (!v) return 'R$ 0,00';

    if ((typeof v) === 'number') {
        v = Number(v).toFixed(2);
    }

    v = numberOnly(v.toString());
    v = Number(v).toString();
    v = v.padStart(3, '0');

    if (v.length > 8) {
        v = v.replace(/^(\d{1})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3,$4');
    } else if (v.length > 5) {
        v = v.replace(/^(\d{1,3})(\d{3})(\d{2})/g, '$1.$2,$3');
    } else {
        v = v.replace(/^(\d{1,3})(\d{2})/g, '$1,$2');
    }

    v = v.substring(0, 12);
    return `R$ ${v}`;
}

export const numberOnly = (value: String | undefined) => {
    if (!value) return '';

    const numberPattern = /\d+/g;
    const numbers = value.match(numberPattern)?.join('');
    return numbers || '';
}