export enum TChartType {
    Brazil,
    World,
}

export enum TChartRange {
    last180,
    last90,
    last30,
    last7,
    custom,
}

export enum TDailyCommissions {
    Daily,
    Weekly,
    Monthly,
}

export type TDateRange = {
    startDate: Date | null;
    endDate: Date | null;
}

export type TComissionCard = {
    totalComissions: number;
    totalUniqueSales: number;
    indicationComissions: number;
    totalComissionsVariations: number;
    totalUniqueSalesVariations: number;
    indicationComissionsVariations: number;
    totalComissionsDiference: number;
    totalUniqueSalesDiference: number;
    indicationComissionsDiference: number;
}

export type TConversionsCard = {
    creditCardConversions: number;
    pixConversions: number;
    boletoConversions: number;
    refundedRate: number;
    creditCardConversionsVariations: number;
    pixConversionsVariations: number;
    boletoConversionsVariations: number;
    refundedRateVariations: number;
}

export type TSalesPaymentMethod = {
    paymentMethod: string;
    totalOrders: number;
    totalValue: number;
}

export type TSalesForGroupKey = {
    name: string;
    value: number;
    count: number;
}

export type TSalesGroupKey = {
    name: string;
    value: number;
    count: number;
    percentage: number;
}

export type TDailySales = {
    date: Date;
    value: number;
}

export type TDashboardDefaultProps = {
    tenantId: string;
    startPeriod: Date;
    endPeriod: Date;
}

export type TDailySalesProps = {
    tenantId: string;
    startPeriod: Date;
    endPeriod: Date;
    periodType: TDailyCommissions;
}