import { Box, CircularProgress, Grid, IconButton, Stack, Typography } from "@mui/material";
import NumberFunctions from "../../../../../core/functions/NumberFunctions";
import Hosts, { payUrl } from "../../../../../core/functions/hosts";
import { TAffiliated, TAffiliatedStatus } from "../../../../../core/types/Affiliated";
import { TProductPrice } from "../../../../../core/types/Product";
import CheckoutIcon from "@mui/icons-material/ContentCopy";
import { useAffiliationsCommissions } from "../../../../../core/hooks/useAffiliationsCommissions";
import { dateString } from "../../../../../core/functions/dateTime";

export interface IMyAffiliationsLinksProp {
    affiliated?: TAffiliated;
    setError: (error: string) => void;
}

const MyAffiliationsCommissions = ({ affiliated, setError }: IMyAffiliationsLinksProp) => {
    const { loading, data, error, execute, clear } = useAffiliationsCommissions({
        id: affiliated?.id || "",
    });

    return (
        <Box>
            {loading && (
                <Stack direction="row" alignContent="center" alignItems="center" sx={{ p: 5 }}>
                    <CircularProgress />
                </Stack>
            )}
            {!loading && (
                <Grid container columns={16}>
                    <Grid item xs={3} sx={{ backgroundColor: 'divider' }}>
                        <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>Início</Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ backgroundColor: 'divider' }}>
                        <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>Fim</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ backgroundColor: 'divider' }}>
                        <Typography variant="subtitle2" textAlign="end" sx={{ px: 2, py: 1 }}>Comissão</Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ backgroundColor: 'divider' }}>
                        <Typography variant="subtitle2" textAlign="end" sx={{ px: 2, py: 1 }}>Geral</Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ backgroundColor: 'divider' }}>
                        <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>Tipo</Typography>
                    </Grid>
                    {data?.map((commission) => (
                        <Stack
                            direction="row"
                            alignContent="center"
                            alignItems="center"
                            sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }}
                        >
                            <Grid item xs={3}>
                                <Typography variant="body2" sx={{ px: 2, py: 1 }}>{dateString(commission.startDate)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" sx={{ px: 2, py: 1 }}>{commission.endDate === null ? "Eterno" : dateString(commission.endDate)}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body2" textAlign="end" sx={{ px: 2, py: 1 }}>{NumberFunctions.toPercentage(commission.commissionPercentage)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" textAlign="end" sx={{ px: 2, py: 1 }}>{NumberFunctions.toPercentage(commission.globalCommissionPercentage)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" sx={{ px: 2, py: 1 }}>{commission.commissionTypeDescription}</Typography>
                            </Grid>
                        </Stack>
                    ))}
                </Grid>
            )}
        </Box>
    );
}

export default MyAffiliationsCommissions;