import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { IMultiSelectedValueItem } from '../../../../../components/Dialogs/MultiSelectedValueDialog'
import React from 'react'

export interface IProductPricePaymentsProps {
  selectds: IMultiSelectedValueItem[]
  disabled?: boolean
  setSelectds: (value: IMultiSelectedValueItem[]) => void
  onPayments: () => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const ProductPricePayments = ({
  selectds,
  disabled,
  setSelectds,
  onPayments,
}: IProductPricePaymentsProps) => {
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    onPayments()
    setOpen(true)
  }

  const handleChange = (event: SelectChangeEvent<typeof selectds>) => {
    const {
      target: { value },
    } = event

    const items = value as IMultiSelectedValueItem[]

    let id = ''
    items.map((v) => {
      if (typeof v === 'string' && id === '') {
        id = v
      }
    })

    const newItems = items.filter((x) => x.id !== id && typeof x !== 'string')
    const newItem = items.find((x) => x.id === id)

    setSelectds([...newItems, { ...newItem!, selected: !newItem!.selected }])
  }

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="pay-multiple-checkbox-label">
        Quais formas de pagamento serão aceitas?
      </InputLabel>
      <Select
        labelId="pay-multiple-checkbox-label"
        id="pay-multiple-checkbox"
        disabled={disabled}
        multiple
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={selectds}
        onChange={handleChange}
        input={
          <OutlinedInput label="Quais formas de pagamento serão aceitas?" />
        }
        renderValue={(selected) =>
          selected
            .filter((s) => s.selected)
            .map((s) => s.name)
            .join(', ')
        }
        MenuProps={MenuProps}
      >
        {selectds
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <Checkbox
                checked={selectds.filter((s) => s.selected).indexOf(item) > -1}
              />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
