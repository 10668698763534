import React, { useRef } from "react";
import { IErrorResponse } from "../types/ErrorResponse";
import { TCommissionRulePagination } from "../types/CommissionRule";
import CommissionRulesController from "../controllers/CommissionRuleController";

export interface ICommissionRulesProp {
    page: number;
    rows: number;
    search?: string;
}

const valueDefault = {
    items: [],
    page: 0,
    rowsPerPage: 0,
    totalPages: 0,
    total: 0,
};

export const useCommissionRules = ({ page, rows, search }: ICommissionRulesProp) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<TCommissionRulePagination>(valueDefault);
    const [error, setError] = React.useState<string>('');

    const executing = useRef(false);

    const execute = async () => {
        if (!loading && !executing.current) {
            executing.current = true;
            try {
                setLoading(true);

                if (error !== '') {
                    setError('');
                }

                const response = await CommissionRulesController.getAll({ page, rows, search });
                const responseError = response as IErrorResponse;
                const responseData = response as TCommissionRulePagination;

                if (responseError.code) {
                    setError(responseError.error);
                    setData(valueDefault);
                } else {
                    setData(responseData);
                }
            } catch (e) {
                var message = 'Erro não identificado';
                if (typeof e === "string") {
                    message = e.toUpperCase();
                } else if (e instanceof Error) {
                    const err = e as Error;
                    message = err.message;
                }
                setError(message);
            } finally {
                setLoading(false);
                executing.current = false;
            }
        }
    }

    React.useEffect(() => {
        execute();
    }, [page, rows]);

    const clear = () => {
        setError('');
    }

    return {
        loading,
        data,
        error,
        execute,
        clear,
    };
}