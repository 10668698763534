import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  Control,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormWatch,
} from 'react-hook-form'
import { ITenantBankAccount } from '../../../../core/types/Tenant'
import Banks from '../../../../core/data/banks'
import Validations from '../../../../core/functions/validations'
import { red } from '@mui/material/colors'
import { useState } from 'react'
import { CELETUS_CONTACT_SUPPORT } from '../../../../core/constants/CeletusConstants'

export interface ITenantBankAccountData {
  control: Control<ITenantBankAccount, any>
  errors: FieldErrors<ITenantBankAccount>
  embedded: boolean
  watch: UseFormWatch<ITenantBankAccount>
  handleSubmit: UseFormHandleSubmit<ITenantBankAccount>
  submit: (data: ITenantBankAccount) => void
  onClose?: () => void
  disableFields: boolean
  hasEdit: boolean
}

const accountTypes = [
  { value: 1, label: 'Corrente' },
  { value: 2, label: 'Poupança' },
]

const TenantBankAccountData = ({
  control,
  errors,
  embedded,
  watch,
  handleSubmit,
  submit,
  onClose,
  disableFields,
  hasEdit,
}: ITenantBankAccountData) => {
  const [showAlert, setShowAlert] = useState(false)
  const { register } = control

  const handleMaxLength = (e: any, limit: number) => {
    if (e.target.value.length > limit) {
      e.target.value = e.target.value.slice(0, e.target.maxLength)
    }
  }

  const handleValidateName = (e: any, limit: number) => {
    var value = e.target.value.replace(/\d+/g, '')
    if (value.length > limit) {
      value = value.slice(0, e.target.maxLength)
    }

    e.target.value = value
  }

  return (
    <Stack
      component={'form'}
      noValidate
      direction={'column'}
      pt={2}
      spacing={3}
      sx={{ width: embedded ? '100%' : '100%' }}
      onSubmit={handleSubmit(submit)}
    >
      <Box>
        <TextField
          disabled={disableFields && !hasEdit}
          onInput={(e) => handleMaxLength(e, 30)}
          onFocus={(e) => setShowAlert(true)}
          variant="outlined"
          size="small"
          fullWidth
          type="text"
          label={'CPF / CNPJ'}
          {...register('recipientDocument', {
            required: {
              value: true,
              message: 'Este campo é obrigatório',
            },
            validate: (value, formValues) =>
              Validations.CPF_CNPJ(value) || 'CPF / CNPJ inválido',
          })}
          error={(errors.recipientDocument?.message || '') !== ''}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 18, inputMode: 'numeric' }}
        />
        {errors.recipientDocument?.message && (
          <Typography variant="caption" color={red[700]}>
            {errors.recipientDocument?.message}
          </Typography>
        )}
        <Collapse in={showAlert}>
          <Alert severity="warning" sx={{ mt: 1 }}>
            <AlertTitle>Atenção</AlertTitle>O CPF / CNPJ informado deve ser o
            mesmo da <strong>conta bancária</strong> que será utilizada para
            realizar os saques. <br />A alteração de CPF / CNPJ só poderá ser
            realizada através da equipe de suporte.
          </Alert>
        </Collapse>
      </Box>
      <Box>
        <TextField
          onInput={(e) => handleValidateName(e, 100)}
          variant="outlined"
          size="small"
          fullWidth
          type="text"
          label="Nome Completo / Razão Social"
          {...register('accountName', { required: true })}
          error={errors.accountName?.type === 'required'}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 30 }}
          disabled={!hasEdit}
        />
        {errors.accountName?.type === 'required' && (
          <Typography variant="caption" color={red[700]}>
            Este campo é obrigatório
          </Typography>
        )}
      </Box>
      <Box sx={{ width: '100%' }}>
        <FormControl fullWidth size="small" disabled={!hasEdit}>
          <InputLabel id="bankCode-label">Banco</InputLabel>
          <Select
            variant="outlined"
            labelId="bankCode-label"
            label="Banco"
            value={watch('bankCode')}
            {...register('bankCode', { required: true })}
            error={errors.bankCode?.type === 'required'}
          >
            {Banks.map((bank) => (
              <MenuItem
                key={bank.value}
                value={bank.value}
              >{`${bank.value} - ${bank.label}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {errors.bankCode?.type === 'required' && (
          <Typography variant="caption" color={red[700]}>
            Este campo é obrigatório
          </Typography>
        )}
      </Box>
      <Stack direction={'row'} spacing={2}>
        <FormControl fullWidth>
          <TextField
            onInput={(e) => handleMaxLength(e, 4)}
            variant="outlined"
            size="small"
            type="number"
            label="Agência"
            {...register('agencyNumber', { required: true })}
            error={errors.agencyNumber?.type === 'required'}
            InputLabelProps={{ shrink: true }}
            inputProps={{ max: 9999 }}
            disabled={!hasEdit}
          />
          {errors.agencyNumber?.type === 'required' && (
            <Typography variant="caption" color={red[700]}>
              Este campo é obrigatório
            </Typography>
          )}
        </FormControl>
        <TextField
          onInput={(e) => handleMaxLength(e, 1)}
          variant="outlined"
          size="small"
          type="text"
          label="Dígito"
          {...register('agencyNumberCheck')}
          InputLabelProps={{ shrink: true }}
          inputProps={{ max: '9' }}
          sx={{ width: '30%' }}
          disabled={!hasEdit}
        />
      </Stack>
      <Stack direction={'row'} spacing={2}>
        <FormControl fullWidth>
          <TextField
            onInput={(e) => handleMaxLength(e, 13)}
            variant="outlined"
            size="small"
            type="number"
            label="Conta"
            {...register('accountNumber', { required: true })}
            error={errors.accountNumber?.type === 'required'}
            InputLabelProps={{ shrink: true }}
            disabled={!hasEdit}
          />
          {errors.accountNumber?.type === 'required' && (
            <Typography variant="caption" color={red[700]}>
              Este campo é obrigatório
            </Typography>
          )}
        </FormControl>
        <Stack direction="column" sx={{ width: '30%' }}>
          <TextField
            onInput={(e) => handleMaxLength(e, 2)}
            variant="outlined"
            size="small"
            type="text"
            label="Dígito"
            {...register('accountNumberDigit', { required: true })}
            error={errors.accountNumberDigit?.type === 'required'}
            InputLabelProps={{ shrink: true }}
            disabled={!hasEdit}
          />
          {errors.accountNumberDigit?.type === 'required' && (
            <Typography variant="caption" color={red[700]}>
              Obrigatório
            </Typography>
          )}
        </Stack>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <FormControl fullWidth size="small" disabled={!hasEdit}>
          <InputLabel id="accountType-label">Tipo da conta</InputLabel>
          <Select
            variant="outlined"
            labelId="accountType-label"
            label="Tipo da conta"
            value={watch('accountType')}
            {...register('accountType', { required: true })}
            error={errors.accountType?.type === 'required'}
          >
            {accountTypes.map((types) => (
              <MenuItem key={'ty_' + types.value} value={types.value}>
                {types.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {errors.accountType?.type === 'required' && (
          <Typography variant="caption" color={red[700]}>
            Este campo é obrigatório
          </Typography>
        )}
      </Box>
      {!hasEdit && (
        <Alert severity="warning">
          <AlertTitle>Não é possível alterar os dados bancários</AlertTitle>
          Caso precise alterar os dados clique{' '}
          <a href={CELETUS_CONTACT_SUPPORT} target="_blank">
            AQUI
          </a>{' '}
          para entrar em contato com o suporte.
        </Alert>
      )}
      {/*<Button
                color="primary"
                type="submit"
                variant="contained"
                fullWidth
            >
                Salvar
            </Button>*/}
    </Stack>
  )
}

export default TenantBankAccountData
