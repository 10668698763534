import { Box, Button, Stack } from '@mui/material'
import { TProductSimple } from '../../../core/types/Product'
import { SaleDateFilter } from './filters/SaleDateFilter'
import { SearchTypeFilter } from './filters/SearchTypeFilter'
import { ProductFilter } from './filters/ProductFilter'
import { AffiliatedFilter } from './filters/AffiliatedFilter'
import { TAffiliatedSimple } from '../../../core/types/Affiliated'
import { PaymentMethodsFilter } from './filters/PaymentMethodsFilter'
import { StatusFilter } from './filters/StatusFilter'
import { useState } from 'react'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'
import { addDays } from '../../../core/functions/dateTime'

export interface IAdvancedFiltersProps {
  open: boolean
  products: TProductSimple[]
  typeFilterValue: number
  affiliateds: TAffiliatedSimple[]
  startDateDefault: Date | null
  endDateDefault: Date | null
  onlyRangeDate: boolean
  startDate: Date | null | undefined
  endDate: Date | null | undefined
  setOpen: (value: boolean) => void
  handleCloseFilter: () => void
  handleDateFilter: (value: any) => void
  handleTypeFilter?: (value: number) => void
  handleProductFilter: (values: string[]) => void
  handleAffiliatedFilter: (values: string[]) => void
  handlePaymentMethodFilter: (values: string[]) => void
  handlStatusFilter: (values: string[]) => void
}

export const AdvancedFilters = ({
  open,
  affiliateds,
  products,
  typeFilterValue,
  startDateDefault,
  endDateDefault,
  onlyRangeDate,
  startDate,
  endDate,
  setOpen,
  handleCloseFilter,
  handleDateFilter,
  handleTypeFilter,
  handleProductFilter,
  handleAffiliatedFilter,
  handlePaymentMethodFilter,
  handlStatusFilter,
}: IAdvancedFiltersProps) => {
  const [productsList, setProductLists] = useState<string[]>([])
  const [selectdAffiliateds, setSelectdAffiliateds] = useState<string[]>([])
  const [selectdPaymentsMethods, setSelectdPaymentsMethods] = useState<
    string[]
  >([])
  const [selectdStatus, setSelectdStatus] = useState<string[]>([])

  const handleCleanFilters = () => {
    if (handleTypeFilter) {
      handleTypeFilter(0)
    }

    handleDateFilter({ startDate: new Date(1900, 0, 1), endDate: new Date() })

    handleProductFilter([])
    setProductLists([])

    handleAffiliatedFilter([])
    setSelectdAffiliateds([])

    handlePaymentMethodFilter([])
    setSelectdPaymentsMethods([])

    handlStatusFilter([])
    setSelectdStatus([])
  }

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={handleCloseFilter}
      setOpen={setOpen}
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <Box>Filtros avançados</Box>
          <Button
            variant="text"
            size="small"
            onClick={handleCleanFilters}
            sx={{ textTransform: 'none' }}
          >
            Limpar Filtros
          </Button>
        </Stack>
      }
    >
      <Stack direction="column" spacing={2} sx={{ width: '100%', pt: 1 }}>
        <SaleDateFilter
          startDate={startDate}
          endDate={endDate}
          handleDateFilter={handleDateFilter}
        />

        {!onlyRangeDate && (
          <>
            {handleTypeFilter && (
              <SearchTypeFilter
                typeFilterValue={typeFilterValue}
                handleTypeFilter={handleTypeFilter}
              />
            )}

            <ProductFilter
              products={products}
              handleProductFilter={handleProductFilter}
              productsList={productsList}
              setProductLists={setProductLists}
            />

            <AffiliatedFilter
              affiliateds={affiliateds}
              handleAffiliatedFilter={handleAffiliatedFilter}
              affiatedList={selectdAffiliateds}
              setAffiliatedList={setSelectdAffiliateds}
            />

            <PaymentMethodsFilter
              handlePaymentMethodFilter={handlePaymentMethodFilter}
              selectds={selectdPaymentsMethods}
              setSelectds={setSelectdPaymentsMethods}
            />

            <StatusFilter
              handleStatusFilter={handlStatusFilter}
              selectds={selectdStatus}
              setSelectds={setSelectdStatus}
            />
          </>
        )}
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}
