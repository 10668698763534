import { Box, FormControlLabel, Typography } from "@mui/material";
import BpCheckbox from "../../../components/Checkout/BpCheckbox";
import React from "react";
import TermsOfUseText from "./TermsOfUseText";

export interface TermsOfUseProps {
    terms: boolean;
    errorTerms: boolean;
    setTerms: (value: boolean) => void;
}

export default function TermsOfUse({ terms, errorTerms, setTerms }: TermsOfUseProps) {
    return (
        <Box display="flex" flexDirection="column">
            <Typography
                variant="h5"
                alignItems="center"
                textAlign="center"
                fontWeight={700}
                sx={{ marginBottom: "1%" }}
            >
                Programa de indicação
            </Typography>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    overflowY: "scroll",
                    maxHeight: "500px",
                    borderRadius: "8px",
                    border: "1px solid #e0e0e0",
                    marginTop: "20px",
                }}
            >
                <TermsOfUseText />
            </Box>
            <Box alignItems="flex-start">
                <FormControlLabel
                    sx={{
                        marginTop: "8px",
                    }}
                    control={
                        <BpCheckbox
                            value="allowExtraEmails"
                            color="primary"
                            checked={terms}
                            onChange={(e) => setTerms(e.target.checked)}
                        />
                    }
                    label={
                        <Typography
                            lineHeight="18px"
                            fontSize={{ xs: "13px", sm: "14px" }}
                            variant="body2"
                            sx={{
                                color: "#5D5E61",
                            }}
                        >
                            Eu li e aceito os termos de uso.
                        </Typography>
                    }
                />
            </Box>
            {errorTerms && (
                <Typography variant="caption" color="error">
                    É necessário aceitar os termos
                </Typography>
            )}
        </Box>
    );
}
