import React, { useState } from 'react'
import {
  Box,
  Checkbox,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import NumberFunctions from '../../../../core/functions/NumberFunctions'

interface DottedBoxWithBadgeProps {
  description?: string
  hasUnmissableTag?: boolean
  imgURL?: string
  title?: string
  productOfferValue?: number
  descriptionPrice?: string
  anchoringPrice?: number | null
  productName?: string
}

const DottedBoxWithBadge: React.FC<DottedBoxWithBadgeProps> = ({
  description,
  title,
  hasUnmissableTag,
  imgURL,
  productOfferValue,
  descriptionPrice,
  anchoringPrice,
  productName,
}) => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  const [selected, setSelected] = useState(false)

  return (
    <>
      <Stack
        direction={'column'}
        width="100%"
        sx={{
          mb: 2,
          backgroundColor: selected ? '#F2F2FF' : 'white',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(236, 80, 64, 1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            borderRadius: '8px 8px 0px 0px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          padding={1}
        >
          <Typography
            variant="h5"
            fontWeight={700}
            fontFamily={'Montserrat'}
            fontSize={'14px'}
            lineHeight={'32px'}
            color={'rgba(243, 243, 244, 1)'}
          >
            {title}
          </Typography>
        </Box>

        <Stack
          sx={{
            border: '2px dashed rgba(236, 80, 64, 1)',
            borderTop: '0px',
            backgroundColor: 'rgba(251, 243, 226, 1)',
            borderRadius: '0px 0px 8px 8px',
          }}
        >
          <Box>
            <Typography
              variant="h5"
              fontFamily={'Montserrat'}
              fontSize={'12px'}
              lineHeight={'20px'}
              fontWeight={400}
              color={'rgba(56, 57, 59, 1)'}
              p={1}
            >
              {description}
            </Typography>
          </Box>

          <Box p={1}>
            <Box
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 1)',
                gap: '8px',
                borderRadius: '8px',
              }}
            >
              <Box width="100%" sx={{ flexGrow: 1, display: 'flex' }}>
                <Box width="100%">
                  <Stack
                    direction={'row'}
                    sx={{ width: '100%' }}
                    alignItems="center"
                    gap="12px"
                  >
                    <Box>
                      <Stack direction="row" alignItems="center" gap="6px">
                        <img
                          alt="vector"
                          src="/assets/icons/actions/vector.svg"
                          style={{
                            height: isSmallScreen ? 24 : 35,
                            width: isSmallScreen ? 24 : 35,
                          }}
                        />
                        <Box>
                          <Checkbox
                            checked={selected}
                            onChange={(e) => setSelected(e.target.checked)}
                          />
                        </Box>
                        <Box
                          sx={{
                            borderRadius: '8px',
                            mt: 1,
                          }}
                        >
                          <img
                            src={
                              imgURL ? imgURL : `/assets/logo/logoCeletus.svg`
                            }
                            alt={'orderbump-icon-preview'}
                            style={{
                              height: isSmallScreen ? 24 : 80,
                              width: isSmallScreen ? 24 : 80,
                              objectFit: 'cover',
                              borderRadius: '8px',
                            }}
                          />
                        </Box>
                      </Stack>
                    </Box>

                    <Stack
                      direction={'column'}
                      spacing={1}
                      sx={{ flexGrow: 1, width: '100%' }}
                    >
                      {anchoringPrice?.toString() === '0' && (
                        <Typography
                          variant="body1"
                          color="#85868A"
                          fontSize="14px"
                          lineHeight="18px"
                          sx={{ flexGrow: 1 }}
                        >
                          {descriptionPrice}{' '}
                          <span
                            style={{
                              color: 'rgba(46, 178, 66, 1)',
                              fontFamily: 'Montserrat',
                              fontSize: '16px',
                              fontWeight: 500,
                              lineHeight: '20px',
                            }}
                          >
                            <b>
                              {NumberFunctions.formatMoneyDefault(
                                productOfferValue
                              )}
                            </b>
                          </span>
                        </Typography>
                      )}

                      {anchoringPrice?.toString() !== '0' && (
                        <Typography
                          variant="body1"
                          color="#85868A"
                          fontSize="14px"
                          lineHeight="18px"
                          sx={{ flexGrow: 1 }}
                        >
                          Sim, desejo adicionar <b>{productName}</b> de {''}
                          <span
                            style={{
                              color: 'rgba(236, 80, 64, 1)',
                              fontFamily: 'Montserrat',
                              fontSize: '16px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              textDecoration: 'line-through',
                            }}
                          >
                            <b>
                              {NumberFunctions.formatMoneyDefault(
                                anchoringPrice
                              )}
                            </b>
                          </span>{' '}
                          por apenas mais{' '}
                          <span
                            style={{
                              color: 'rgba(46, 178, 66, 1)',
                              fontFamily: 'Montserrat',
                              fontSize: '16px',
                              fontWeight: 500,
                              lineHeight: '20px',
                            }}
                          >
                            <b>
                              {NumberFunctions.formatMoneyDefault(
                                productOfferValue
                              )}
                              .
                            </b>
                          </span>{' '}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </>
  )
}

export default DottedBoxWithBadge
