import * as React from 'react';
import {
  Box,
  Typography,
  Tab,
  Tabs
} from '@mui/material';
import { useState } from 'react';
import { ActivAffiliateds } from './ActiveAffiliateds';
import { PendingAffiliateds } from './PendingAffiliateds';
import { BlockedAffiliateds } from './BlockedAffiliateds';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`affiliated-tabpanel-${index}`}
      aria-labelledby={`affiliated-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `affiliated-tab-${index}`,
    'aria-controls': `affiliated-tabpanel-${index}`,
  };
}


export const AffiliatedsPage = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

	return (
		<Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>Meus Afiliados</Typography>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabIndex} onChange={handleChange} aria-label="affiliated tabs">
            <Tab label="Ativos" {...a11yProps(0)} />
            <Tab label="Solicitações pendentes" {...a11yProps(1)} />
            <Tab label="Recusados, bloqueados ou cancelados" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={0}>
          <ActivAffiliateds />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <PendingAffiliateds />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <BlockedAffiliateds />
        </TabPanel>
      </Box>
    </Box >
	);
}
 