import { Stack, Typography } from '@mui/material'

export interface ITopLogoProp {
  margimBottom?: string | number | undefined
}

export function TopLogo({ margimBottom }: ITopLogoProp) {
  return (
    <Stack
      alignContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        height: { xs: '32px', md: '42px' },
        mb: margimBottom ?? '16px',
      }}
    >
      <img
        src="/assets/logo/loginLogo.svg"
        alt=""
        style={{ objectFit: 'contain', height: '100%' }}
      />
    </Stack>
  )
}
