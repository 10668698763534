import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  FormControl,
  InputLabel,
  Backdrop,
  CircularProgress,
  Chip,
  Select,
  MenuItem,
  Stack,
} from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useCoproducers } from '../../../core/hooks/useCoproducers'
import {
  addDays,
  dateString,
  dateTimeString,
} from '../../../core/functions/dateTime'
import Convertions from '../../../core/functions/convertions'
import { TCoproducerStatus } from '../../../core/types/Coproducer'
import TablePaginationDefault from '../../../components/Tables/TablePaginationDefault'
import InputSearch from '../../../components/Inputs/InputSearch'
import { useResponsive } from '../../../core/hooks/useResponsive'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }
)

interface Column {
  id:
    | 'createdDate'
    | 'productName'
    | 'commissionPercentage'
    | 'contractDuration'
    | 'status'
    | 'productActive'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'createdDate', label: 'Data', minWidth: 60 },
  { id: 'productActive', label: 'Ativo', minWidth: 60 },
  { id: 'productName', label: 'Produto', minWidth: 120 },
  {
    id: 'commissionPercentage',
    label: 'Comissão',
    minWidth: 80,
  },
  {
    id: 'contractDuration',
    label: 'Vencimento',
    minWidth: 60,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 60,
  },
]

export default function MyCoproducers() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [status, setStatus] = React.useState<TCoproducerStatus>(
    TCoproducerStatus.All
  )

  const [windowSize, setWindowSize] = React.useState([
    window.outerWidth,
    window.outerHeight,
  ])

  const oldSearch = React.useRef('')

  const smUp = useResponsive('up', 'sm')

  const { loading, data, error, execute, clear } = useCoproducers({
    page,
    rows: rowsPerPage,
    search,
    status,
  })

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  React.useEffect(() => {
    window.addEventListener('resize', (ev) => {
      setWindowSize([window.outerWidth, window.outerHeight])
    })
  }, [])

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  return (
    <Box>
      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <Alert onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Box>
      )}

      <Stack direction="row" spacing={2}>
        <InputSearch
          id="searchMyCoproducers"
          name="Filtrar por nome"
          setSearch={setSearch}
          execute={execute}
        />

        <FormControl sx={{ width: '20%' }}>
          <InputLabel id="days-select-label">Status</InputLabel>
          <Select
            variant="outlined"
            labelId="days-select-label"
            label="Status"
            value={status}
            onChange={(e) => setStatus(Number(e.target.value))}
          >
            <MenuItem value={TCoproducerStatus.All}>Todos</MenuItem>
            <MenuItem value={TCoproducerStatus.Pending}>
              {Convertions.coproducerStatusToString(TCoproducerStatus.Pending)}
            </MenuItem>
            <MenuItem value={TCoproducerStatus.Confirmed}>
              {Convertions.coproducerStatusToString(
                TCoproducerStatus.Confirmed
              )}
            </MenuItem>
            <MenuItem value={TCoproducerStatus.Canceled}>
              {Convertions.coproducerStatusToString(TCoproducerStatus.Canceled)}
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxWidth: smUp ? '100%' : windowSize[0] - 68 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id]
                      if (column.id === 'createdDate') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {dateTimeString(value)}
                          </TableCell>
                        )
                      }
                      if (column.id === 'status') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Chip
                              label={Convertions.coproducerStatusToString(
                                row.status
                              )}
                              color={
                                row.status === TCoproducerStatus.Confirmed
                                  ? 'success'
                                  : row.status === TCoproducerStatus.Canceled
                                    ? 'error'
                                    : undefined
                              }
                            />
                          </TableCell>
                        )
                      }
                      if (column.id === 'contractDuration') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row.contractDuration === 999
                              ? 'Eterno'
                              : dateString(
                                  addDays(
                                    row.contractDuration || 0,
                                    row.createdDate
                                  )
                                )}
                          </TableCell>
                        )
                      }
                      if (column.id === 'commissionPercentage') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}%
                          </TableCell>
                        )
                      }
                      if (column.id === 'productActive') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row.productActive === true ? 'Sim' : 'Não'}
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}
