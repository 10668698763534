import api, { getResponseError } from "../../services/api";
import { TActiveCampaign, TActiveCampaignCustomField, TActiveCampaignItem, TActiveCampaignItemCustomFieldType, TActiveCampaignItemTagType, TActiveCampaignList, TActiveCampaignPagination, TActiveCampaignTag } from "../types/ActiveCampaign";
import { IErrorResponse, IResponse } from "../types/ErrorResponse";

interface IGetActiveCampaignProp {
    page: number;
    rows: number;
    search?: string;
}

const insert = async ({ data }: { data: TActiveCampaign }): Promise<IResponse> => {
    try {
        const { data: response } = await api.post("/ActiveCampaign", data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: "",
                data: response,
            };
        }

        return {
            success: false,
            code: 1,
            error: "Erro inesperado do servidor",
        };
    } catch (error: any) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
};

const update = async ({ data }: { data: TActiveCampaign }): Promise<IResponse> => {
    try {
        const { data: response } = await api.put("/ActiveCampaign", data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: "",
            };
        }

        return {
            success: false,
            code: 1,
            error: "Erro inesperado do servidor",
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
};

const get = async (): Promise<TActiveCampaign | IErrorResponse> => {
    try {
        const { data } = await api.get<TActiveCampaign>(`/ActiveCampaign`);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getItems = async ({ page, rows, search }: IGetActiveCampaignProp): Promise<TActiveCampaignPagination | IErrorResponse> => {
    try {
        var path = `/ActiveCampaign/items?PageNumber=${page}&RowsPerPage=${rows}`;

        if (search && search !== '') {
            path += `&Search=${search}`;
        }

        const { data } = await api.get<TActiveCampaignPagination>(path);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getItem = async ({ id }: { id?: string }): Promise<TActiveCampaignItem | IErrorResponse> => {
    try {
        var path = `/ActiveCampaign/items/${id}`;
        const { data } = await api.get<TActiveCampaignItem>(path);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const remove = async ({ id }: { id: string }): Promise<IResponse> => {
    try {
        await api.delete(`/ActiveCampaign/${id}`);
        return {
            success: true,
            code: 0,
            error: "",
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
};

const insertItem = async ({ data }: { data: TActiveCampaignItem }): Promise<IResponse> => {
    try {
        const { data: response } = await api.post("/ActiveCampaign/items", data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: "",
                data: response,
            };
        }

        return {
            success: false,
            code: 1,
            error: "Erro inesperado do servidor",
        };
    } catch (error: any) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
};

const updateItem = async ({ data }: { data: TActiveCampaignItem }): Promise<IResponse> => {
    try {
        const { data: response } = await api.put("/ActiveCampaign/items", data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: "",
            };
        }

        return {
            success: false,
            code: 1,
            error: "Erro inesperado do servidor",
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
};

const removeItem = async ({ id }: { id: string }): Promise<IResponse> => {
    try {
        await api.delete(`/ActiveCampaign/items/${id}`);
        return {
            success: true,
            code: 0,
            error: "",
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
};

const getCustomFields = async ({ activeCampaignId }: { activeCampaignId?: string }): Promise<TActiveCampaignCustomField[] | IErrorResponse> => {
    try {
        var path = `/ActiveCampaign/fields/${activeCampaignId}`;
        const { data } = await api.get<TActiveCampaignCustomField[]>(path);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getLists = async ({ activeCampaignId }: { activeCampaignId?: string }): Promise<TActiveCampaignList[] | IErrorResponse> => {
    try {
        var path = `/ActiveCampaign/lists/${activeCampaignId}`;
        const { data } = await api.get<TActiveCampaignList[]>(path);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getTags = async ({ activeCampaignId, search }: { activeCampaignId?: string, search?: string }): Promise<TActiveCampaignTag[] | IErrorResponse> => {
    try {
        var path = `/ActiveCampaign/tags/${activeCampaignId}`;

        if (search && search !== "") {
            path += `?search=${search}`;
        }

        const { data } = await api.get<TActiveCampaignTag[]>(path);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const ActiveCampaignController = {
    insert,
    update,
    get,
    getItems,
    remove,
    insertItem,
    updateItem,
    removeItem,
    getCustomFields,
    getLists,
    getTags,
    getItem,
}

export default ActiveCampaignController;