import { TCoproducer } from './Coproducer'
import { TCustomCheckout } from './CustomCheckout'
import { IOrderBump } from './OrderBump'
import {
  TProductAffiliates,
  TProdutAffiliatesCategory,
} from './ProductAffiliates'
import { ProductSituationEnum } from './Situation'

export enum TProductDelivery {
  Course = 1,
  eBook = 2,
}

export enum TProductTypePayment {
  OneTimePayment = 1,
  RecurringSubscription = 2,
}

export enum TProductFrequency {
  Weekly = 1,
  Monthly = 2,
  Bimonthly = 3,
  Quarterly = 4,
  Semester = 5,
  Yearly = 6,
}

export enum TProductAssurance {
  Seven = 7,
  Fifteen = 15,
  TwentyOne = 21,
  Thirty = 30,
}

export enum TProductStatus {
  Active = 1,
  Inactive = 2,
}

export enum TProductLinkDuration {
  Unknown = 0,
  SevenDays = 7,
  ThirtyDays = 30,
  SixtyDays = 60,
  NinetyDays = 90,
  OneHundredAndEightyDays = 180,
  ThreeHundredSixtyFiveDays = 365,
  Eternal = 999,
}

export const productLinkDurations: TProductLinkDuration[] = [
  TProductLinkDuration.Unknown,
  TProductLinkDuration.SevenDays,
  TProductLinkDuration.ThirtyDays,
  TProductLinkDuration.SixtyDays,
  TProductLinkDuration.NinetyDays,
  TProductLinkDuration.OneHundredAndEightyDays,
  TProductLinkDuration.ThreeHundredSixtyFiveDays,
  TProductLinkDuration.Eternal,
]

export enum TProductLinkCategory {
  SalesPage = 1,
  Leads = 2,
  SocialMedia = 3,
  Others = 4,
}

export const productLinkCategories: TProductLinkCategory[] = [
  TProductLinkCategory.SalesPage,
  TProductLinkCategory.Leads,
  TProductLinkCategory.SocialMedia,
  TProductLinkCategory.Others,
]

export enum TProductPixelType {
  Facebook = 1,
  GoogleAds = 2,
}

export enum TProductPixelRegisterPage {
  Pixel = 1,
  Domain = 2,
}

export type TProductTypeChoose = {
  delivery: TProductDelivery
  typePayment: TProductTypePayment
}

export type TProductPricePayment = {
  id?: string
  methodType: number
  description?: string
  installmentsNumber: number
  installments: TProductPricePaymentInstallment[]
}

export type TProductPricePaymentInstallment = {
  installmentNumber: number
  value: number
  total: number
}

export type TProductPrice = {
  id: string
  productId?: string
  productName?: string
  productCoverUrl?: string
  productDescription?: string
  facebookPixel?: string
  value?: number
  firstPurchasePrice?: number
  frequency?: TProductFrequency
  paymentMethods: TProductPricePayment[]
  orderBumps?: IOrderBump[]
  codeId?: string
  hideCheckoutAddress: boolean
  disabled: boolean
  disableDate: Date | null
  redirectProductPriceId: string | null
  isDisabled: boolean
  offerName?: string
  cycleNumbers?: number | null
}

export type TProduct = {
  id: string
  name: string
  description: string
  approvedPageLink: string
  awaitingPaymentPageLink?: string
  awaitingCreditAnalysisPageLink?: string
  memberServiceId?: string
  externalLink: string
  accessUser: string
  accessPassword: string

  imageCover?: File | undefined
  imageProduct?: File | undefined

  coverUrl?: string
  imageProductUrl?: string

  typePayment?: TProductTypePayment

  enableAffiliateProgram: boolean
  affiliate?: TProductAffiliates | null

  prices?: TProductPrice[]
  coproducers?: TCoproducer[]
  checkouts?: TCustomCheckout[]

  assurance?: TProductAssurance | null
  status: TProductStatus

  memberServiceSettingId?: string | null

  voxuyPlanId?: string
  voxuyEventId?: string
  supportEmail?: string

  delivery: TProductDelivery
  deliveryUrl?: string | null

  statementDescription?: string | null
  situation?: ProductSituationEnum
  situationDescription?: string
}

export type TProductPagination = {
  items: TProduct[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
}

export type TProductSimple = {
  id: string
  name: string
  coverUrl?: string
  imageUrl?: string
}

export type TProductPixel = {
  id: string
  productId: string
  pixel: string
  pixelToken?: string
  pixelDomain?: string
  affiliatedId?: string

  type: TProductPixelType
  enabledDomain: boolean
  cnameVerified: boolean

  selectivePurchase: boolean
  selectiveImmediatePurchase: boolean
  selectiveNonImmediatePurchase: boolean
  visitSalesPage: boolean
}

export type TProductLink = {
  id: string
  productId: string
  identifier: string
  codeId: string
  url: string
  otherProducts: boolean
  duration?: TProductLinkDuration | null
  category: TProductLinkCategory
  createdDate?: Date
}

export type TProductAffiliation = {
  id: string
  productId: string
  tenantId: string
  serviceCode: string
  productName: string
  productDescription: string
  author: string
  imageProductUrl?: string | null
  imageCoverUrl?: string | null
  maxValue: number
  approveRequestManually?: boolean | null
  receiveEmailNotification?: boolean | null
  viewBuyerContact?: boolean | null
  exposeOnMarketplace?: boolean | null
  allowRegistrationThanksPages?: boolean | null
  supportPhone?: string | null
  description: string
  commissionPercentage: number
  globalCommissionPercentage: number
  category: TProdutAffiliatesCategory
  categoryDescription?: string | null
  productSaleLink?: string | null
  prices?: TProductPrice[]

  enableRecoverySale?: boolean
  enableIndividualRecoverySale?: boolean
}
