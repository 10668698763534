import { styled, useTheme } from '@mui/material/styles'
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material'

// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined'

const CardWrapper = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.light,
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    //background: `linear-gradient(210.04deg, ${theme.palette.primary.light} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: '50%',
    top: -30,
    right: -180,
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    //background: `linear-gradient(140.9deg, ${theme.palette.primary.light} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
    borderRadius: '50%',
    top: -160,
    right: -130,
  },
}))

export const BalanceDarkCard = ({ balance }: { balance: number }) => {
  const theme = useTheme()

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  return (
    <Box
      display="flex"
      width={{ xs: '100%', sm: '50%' }}
      justifyContent="center"
      alignItems="center"
      sx={{ p: 2 }}
    >
      <List sx={{ py: 0 }}>
        <ListItem disableGutters sx={{ py: 0 }}>
          <ListItemText
            sx={{
              py: 0,
              mt: 0.45,
              mb: 0.45,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
            primary={
              <Typography
                variant="subtitle2"
                sx={{
                  size: '12px',
                  lineHeight: '16px',
                  alignContent: 'center',
                  color: theme.palette.grey[900],
                  mt: 0.25,
                }}
              >
                Saldo disponível
              </Typography>
            }
            secondary={
              <Typography variant="h5" sx={{ color: theme.palette.grey[900] }}>
                {formatter.format(balance)}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Box>
  )
}
