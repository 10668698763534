import { Alert, Snackbar } from "@mui/material";

export interface IInfoSnackbarProps {
    open: boolean;
    children: string | string[] | JSX.Element;
    onClose: () => void;
    autoHideDuration?: number;
    anchorHorizontal?: "center" | "right" | "left";
    action?: React.ReactNode;
}

const InfoSnackbar = ({ 
    open, 
    onClose, 
    children, 
    autoHideDuration, 
    anchorHorizontal,
    action,
}: IInfoSnackbarProps) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration || 4000}
            onClose={onClose}
            anchorOrigin={{ 
                vertical: 'top', 
                horizontal: anchorHorizontal || 'right' 
            }}
            action={action}
        >
            <Alert
                variant="filled"
                severity="info"
                onClose={onClose}
                sx={{ width: '100%' }}
                action={action}
            >
                {children}
            </Alert>
        </Snackbar>
    );
}

export default InfoSnackbar;