import {
  Button,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useContext, useState } from 'react'
import { UseFormWatch } from 'react-hook-form'
import { TProduct } from '../../../../core/types/Product'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/EditOutlined'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import {
  TProductVoucher,
  TProductVoucherExpired,
  TProductVoucherValue,
} from '../../../../core/types/Voucher'
import ProductVoucherRegister from '../../../../components/Product/Voucher/ProductVoucherRegister'
import { useProductVouchers } from '../../../../core/hooks/useProductVouchers'
import NumberFunctions from '../../../../core/functions/NumberFunctions'
import { dateString } from '../../../../core/functions/dateTime'
import VoucherController from '../../../../core/controllers/VoucherController'
import { AuthContext } from '../../../../core/context/AuthContext'
import { TUserType } from '../../../../core/types/Auth'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
export interface IProductVouchersProps {
  watch: UseFormWatch<TProduct>
  setError: (error: string) => void
}

const productVoucherDefault: TProductVoucher = {
  id: '',
  productId: '',
  voucherIdentifier: '',
  valueType: TProductVoucherValue.percentage,
  value: 0,
  startDurationDate: new Date(),
  expiredType: TProductVoucherExpired.quantity,
  isOfferAll: true,
  productPrices: [],
}

const ProductVouchers = ({
  watch,
  setError,
}: IProductVouchersProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState<TProductVoucher>({
    ...productVoucherDefault,
  })
  const [openConfirmDelete, setOpenConfirmeDelete] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorIdentifier, setErrorIdentifier] = useState('')
  const [errorValue, setErrorValue] = useState('')
  const [errorPrices, setErrorPrices] = useState('')
  const [errorEndDate, setErrorEndDate] = useState('')

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const productId = watch('id')
  const prices = watch('prices')

  const { loading, data, error, execute, clear } = useProductVouchers({
    productId,
  })

  const setCurrentDefault = () => {
    clearErrors()
    setCurrent({ ...productVoucherDefault })
  }

  const handleChangeEditVoucher = (productCoucher: TProductVoucher) => {
    clearErrors()
    setCurrent(productCoucher)
    setOpen(true)
  }

  const handleChangeVoucher = () => {
    clearErrors()
    const productVoucher = { ...productVoucherDefault }
    setCurrent(productVoucher)
    setOpen(true)
  }

  const handleChangeClose = () => {
    setCurrentDefault()
    setOpen(false)
  }

  const validationVoucherErrors = () => {
    let isError = false

    if ((current.voucherIdentifier ?? '') === '') {
      setErrorIdentifier('Campo obrigatório')
      isError = true
    }

    if (!current.value) {
      setErrorValue('Campo obrigatório')
      isError = true
    } else if (NumberFunctions.toNumber(current.value) <= 0) {
      setErrorValue('É necessário o desconto ser maior que 0.')
      isError = true
    }

    if (!current.isOfferAll && current.productPrices.length <= 0) {
      setErrorPrices('É necessário selecionar no mínimo uma oferta')
      isError = true
    }

    if (current.valueType === TProductVoucherValue.value) {
      const priceFilter = prices?.filter(
        (x) => current.productPrices.includes(x.id) && !x.isDisabled
      )
      if (priceFilter) {
        const price = priceFilter.find(
          (x) => (x.value ?? 0) <= NumberFunctions.toNumber(current.value)
        )
        if (price) {
          setErrorValue(
            'É necessário fornecer um valor menor que as ofertas selecionadas'
          )
          isError = true
        }
      }
    } else if (current.valueType === TProductVoucherValue.percentage) {
      if (NumberFunctions.toNumber(current.value) > 90) {
        setErrorValue('O percentual não pode ser maior que 90%.')
        isError = true
      }
    }

    if (current.expiredType === TProductVoucherExpired.date) {
      if ((current.endDurationDate ?? new Date()) < current.startDurationDate) {
        setErrorEndDate(
          'A data de validade deve ser maior que a data de início'
        )
        isError = true
      }
    }

    return isError
  }

  const validationWithErrors = () => {
    let isError = false

    if (validationVoucherErrors()) {
      isError = true
    }

    return isError
  }

  const clearErrors = () => {
    if (errorIdentifier !== '') {
      setErrorIdentifier('')
    }
    if (errorValue !== '') {
      setErrorValue('')
    }
    if (errorPrices !== '') {
      setErrorPrices('')
    }
  }

  const handleChangeSave = async () => {
    current.productId = productId
    current.value = NumberFunctions.toNumber(current.value)

    if (validationWithErrors()) {
      return
    }

    clearErrors()

    if (
      current.expiredType === TProductVoucherExpired.quantity &&
      !current.maxQuantity
    ) {
      current.maxQuantity = 1
    }

    setSending(true)
    try {
      var response
      if (current.id === '') {
        response = await VoucherController.insertVoucher({ data: current })
      } else {
        response = await VoucherController.updateVoucher({ data: current })
      }

      if (!response.success) {
        setError(response.error)
        return
      }

      setSuccess(true)
      handleChangeClose()
      execute()
    } finally {
      setSending(false)
    }
  }

  const handleChangeDelete = (productVoucher: TProductVoucher) => {
    productVoucher ??= { ...productVoucherDefault }
    setCurrent(productVoucher)
    setOpenConfirmeDelete(true)
  }

  const handleDeleteClose = () => {
    setCurrentDefault()
    setOpenConfirmeDelete(false)
  }

  const deleteVoucher = async () => {
    if (!current || current.id === '') {
      return
    }

    setSending(true)
    setSuccess(false)
    setError('')

    try {
      const response = await VoucherController.removeVoucher({
        id: current.id ?? '',
      })

      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        handleDeleteClose()
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  return (
    <Box>
      <Stack spacing={2}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            color="#343948"
            fontWeight={700}
            fontSize="16px"
            lineHeight="20px"
          >
            Meus Cupons de Desconto
          </Typography>

          {!isAdmin && (
            <Button
              variant="outlined"
              size="large"
              sx={{ textTransform: 'none' }}
              onClick={() => handleChangeVoucher()}
            >
              + Novo Cupom de Desconto
            </Button>
          )}
        </Stack>

        <Stack direction="column" spacing={1} sx={{ pt: 2 }}>
          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            alignItems="center"
            sx={{ px: 2 }}
          >
            <Box sx={{ width: '20%' }}>
              <Typography
                color="#707275"
                fontWeight={600}
                fontSize="14px"
                lineHeight="18px"
              >
                Código
              </Typography>
            </Box>
            <Box sx={{ width: '20%' }}>
              <Typography
                color="#707275"
                fontWeight={600}
                fontSize="14px"
                lineHeight="18px"
              >
                Valor
              </Typography>
            </Box>
            <Box sx={{ width: '20%' }}>
              <Typography
                color="#707275"
                fontWeight={600}
                fontSize="14px"
                lineHeight="18px"
              >
                Válido a partir de
              </Typography>
            </Box>
            <Box sx={{ width: '20%' }}>
              <Typography
                color="#707275"
                fontWeight={600}
                fontSize="14px"
                lineHeight="18px"
              >
                Válido até
              </Typography>
            </Box>
            <Box sx={{ width: '10%' }}>
              <Typography
                color="#707275"
                fontWeight={600}
                fontSize="14px"
                lineHeight="18px"
              >
                Todas ofertas
              </Typography>
            </Box>
            <Box sx={{ width: '10%' }}>
              <Typography
                color="#707275"
                fontWeight={600}
                fontSize="14px"
                lineHeight="18px"
              >
                Ações
              </Typography>
            </Box>
          </Stack>
          {data.map((voucher, index) => (
            <Paper
              elevation={0}
              sx={{
                mb: 2,
                px: 2,
                py: 1,
                border: '1px solid #C5C6C9',
                borderRadius: '4px',
              }}
            >
              <Stack
                key={index}
                direction="row"
                spacing={1}
                alignContent="center"
                alignItems="center"
              >
                <Box sx={{ width: '20%' }}>
                  <Typography
                    variant="body2"
                    color="#38393B"
                    fontSize="14px"
                    lineHeight="18px"
                  >
                    {voucher.voucherIdentifier}
                  </Typography>
                </Box>
                <Stack direction="column" sx={{ width: '20%' }}>
                  <Typography
                    variant="body2"
                    color="#38393B"
                    fontSize="14px"
                    lineHeight="18px"
                  >
                    {voucher.valueType === TProductVoucherValue.percentage
                      ? NumberFunctions.toPercentage(voucher.value)
                      : NumberFunctions.formatMoneyDefault(voucher.value)}
                  </Typography>
                </Stack>
                <Box sx={{ width: '20%' }}>
                  <Typography
                    variant="body2"
                    color="#38393B"
                    fontSize="14px"
                    lineHeight="18px"
                  >
                    {dateString(voucher.startDurationDate)}
                  </Typography>
                </Box>
                <Box sx={{ width: '20%' }}>
                  <Typography
                    variant="body2"
                    color="#38393B"
                    fontSize="14px"
                    lineHeight="18px"
                  >
                    {voucher.expiredType === TProductVoucherExpired.date
                      ? dateString(voucher.endDurationDate)
                      : voucher.maxQuantity?.toString()}
                  </Typography>
                </Box>
                <Box sx={{ width: '10%' }}>
                  <Checkbox disabled checked={voucher.isOfferAll} />
                </Box>
                {!isAdmin && (
                  <Stack direction="row" sx={{ width: '10%' }}>
                    <IconButton
                      onClick={() => handleChangeEditVoucher(voucher)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleChangeDelete(voucher)}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                )}

                {isAdmin && (
                  <Stack direction="row" sx={{ width: '10%' }}>
                    <IconButton
                      onClick={() => handleChangeEditVoucher(voucher)}
                    >
                      <RemoveRedEyeIcon />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
            </Paper>
          ))}
        </Stack>
      </Stack>

      <ProductVoucherRegister
        open={open}
        sending={sending}
        productVoucher={current}
        productPrices={prices ?? []}
        errorIdentifier={errorIdentifier}
        errorValue={errorValue}
        errorPrices={errorPrices}
        errorEndDate={errorEndDate}
        setOpen={setOpen}
        setProductVoucher={setCurrent}
        onSave={handleChangeSave}
      />

      <ConfirmDialog
        open={openConfirmDelete}
        title="Confirmar exclusão"
        message="Deseja realmente deletar o cupom de desconto?"
        onClose={handleDeleteClose}
        onYes={deleteVoucher}
      />

      <LoadingBackdrop open={loading || sending} />

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Concluído com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

export default ProductVouchers
