import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import ListItemData from '../../../../../components/ListItem/ListItemData'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import { grey } from '@mui/material/colors'
import Convertions from '../../../../../core/functions/convertions'
import { IProductPriceItemProps } from './ProductPriceItem'
import ProductPriceItemStatus from './ProductPriceItemStatus'
import { AuthContext } from '../../../../../core/context/AuthContext'
import { TUserType } from '../../../../../core/types/Auth'
import { useContext } from 'react'

const ProductPriceItemWeb = ({
  price,
  index,
  isTenantBank,
  fields,
  watch,
  onCopyURL,
  onOpenPrice,
  onOpenDisable,
  onDelete,
}: IProductPriceItemProps) => {
  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  return (
    <ListItemData key={`ProductPriceItemWeb${index}`}>
      <Box sx={{ width: '20%' }}>
        <Typography
          color="#38393B"
          fontWeight={500}
          fontSize="14px"
          lineHeight="18px"
        >
          {price.offerName}
        </Typography>
      </Box>
      <Box sx={{ width: '12%' }}>
        <Typography
          color="#38393B"
          fontWeight={500}
          fontSize="14px"
          lineHeight="18px"
          textAlign="right"
        >
          {NumberFunctions.formatMoneyDefault(price.value)}
        </Typography>
      </Box>
      <Stack
        sx={{
          width: '12%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ProductPriceItemStatus price={price} />
      </Stack>
      <Stack direction="column" alignItems="center" sx={{ width: '14%' }}>
        {price.hideCheckoutAddress && (
          <img src="/assets/icons/check/check.svg" alt="check-offer" />
        )}
      </Stack>
      <Stack
        direction="column"
        sx={{ width: '14%' }}
        alignContent="start"
        alignItems="start"
      >
        <AvatarGroup max={4} color="primary">
          {watch(`prices.${index}.paymentMethods`)
            .sort((a, b) =>
              Convertions.methodTypeToString(a.methodType) >
              Convertions.methodTypeToString(b.methodType)
                ? 1
                : -1
            )
            .map((pay) => (
              <Avatar
                key={`pay${pay.methodType}`}
                alt={pay.methodType.toString()}
                sx={{
                  width: 32,
                  height: 32,
                  bgcolor: price.isDisabled
                    ? grey[400]
                    : price.disableDate
                      ? '#887100'
                      : '#1C52BD',
                }}
              >
                <Typography
                  color="white"
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="18px"
                  textAlign="right"
                >
                  {Convertions.methodTypeToString(pay.methodType).substring(
                    0,
                    2
                  )}
                </Typography>
              </Avatar>
            ))}
        </AvatarGroup>
      </Stack>
      <Box sx={{ width: '14%' }}>
        {isTenantBank &&
          watch(`prices.${index}.id`) !== '' &&
          !price.isDisabled && (
            <Stack direction="row" alignItems="center">
              <Tooltip title="Copiar URL">
                <IconButton
                  size="small"
                  onClick={() => onCopyURL(false, index)}
                >
                  <img src="/assets/icons/copy/copy.svg" alt="copy" />
                </IconButton>
              </Tooltip>
              <Typography
                variant="body1"
                color="#38393B"
                fontWeight={500}
                fontSize="14px"
                lineHeight="18px"
              >
                {price.codeId}
              </Typography>
            </Stack>
          )}
        {price.isDisabled && (
          <Typography
            color="#38393B"
            fontWeight={500}
            fontSize="14px"
            lineHeight="18px"
          >
            Redirecionamento para{' '}
            {NumberFunctions.formatMoneyDefault(
              fields.find(
                (f, i) =>
                  watch(`prices.${i}.id`) === price.redirectProductPriceId
              )?.value
            )}
          </Typography>
        )}
      </Box>

      {!isAdmin && (
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ width: '14%' }}
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title="Editar oferta">
            <IconButton
              size="small"
              disabled={price.isDisabled}
              onClick={() => onOpenPrice(price, index)}
            >
              <img src="/assets/icons/actions/edit.svg" alt="edit" />
            </IconButton>
          </Tooltip>
          {price.id !== '' && !price.isDisabled && (
            <Tooltip title="Desabilitar oferta">
              <IconButton
                size="small"
                onClick={() => onOpenDisable(price, index)}
              >
                <img src="/assets/icons/actions/offerActive.svg" alt="offer" />
              </IconButton>
            </Tooltip>
          )}
          {price.id !== '' && price.isDisabled && (
            <Tooltip title="Habilitar oferta">
              <IconButton
                size="small"
                onClick={() => onOpenDisable(price, index)}
              >
                <img
                  src="/assets/icons/actions/offerInactive.svg"
                  alt="offer"
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Excluir oferta">
            <IconButton size="small" onClick={() => onDelete(price, index)}>
              <img src="/assets/icons/actions/delete.svg" alt="delete" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      {isAdmin && (
        <Tooltip title="Ver Oferta">
          <IconButton size="small" onClick={() => onOpenPrice(price, index)}>
            <img src="/assets/icons/eyes/eyeButtonBlack.svg" alt="edit" />
          </IconButton>
        </Tooltip>
      )}
    </ListItemData>
  )
}

export default ProductPriceItemWeb
