import api, { getResponseError } from '../../services/api'
import { IErrorResponse, IResponse } from '../types/ErrorResponse'
import {
  TSituation,
  TSituationRegister,
  TSituationUpdate,
} from '../types/Situation'

interface IGetSituationProp {
  productId: string
}

const getAll = async ({
  productId,
}: IGetSituationProp): Promise<TSituation[] | IErrorResponse> => {
  try {
    var path = `/Product/situation/${productId}/actions`

    const { data } = await api.get<TSituation[]>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const insert = async ({
  productId,
  data,
}: {
  productId: string
  data: TSituationRegister
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.post(
      `/Product/situation/${productId}`,
      data
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const update = async ({
  productSituationActionId,
  data,
}: {
  productSituationActionId: string
  data: TSituationUpdate
}): Promise<IResponse> => {
  try {
    const { status } = await api.put(
      `/Product/situation/${productSituationActionId}`,
      data
    )
    if (status === 200) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const remove = async ({
  productSituationActionId,
}: {
  productSituationActionId: string
}): Promise<IResponse> => {
  try {
    await api.delete(`/Product/situation/${productSituationActionId}`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}
const resolved = async ({
  productSituationActionId,
}: {
  productSituationActionId: string
}): Promise<IResponse> => {
  try {
    await api.put(`/Product/situation/${productSituationActionId}/resolved`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const unresolved = async ({
  productSituationActionId,
}: {
  productSituationActionId: string
}): Promise<IResponse> => {
  try {
    await api.put(`/Product/situation/${productSituationActionId}/unresolved`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const ProductSituationControler = {
  getAll,
  insert,
  update,
  remove,
  resolved,
  unresolved,
}

export default ProductSituationControler
