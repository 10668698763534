import { Box, Stack } from '@mui/material'
import { ISwipeableDrawerRightDialogProps } from './SwipeableDrawerRightDialog'
import ContentDialog from './ContentDialog'

const SwipeableDrawerRightDialogWeb = ({
  open,
  title,
  subtitle,
  setOpen,
  onClose,
  children,
  backgroundColor,
  buttons,
  buttonsDisabled,
  noValidate,
  component,
  onSubmit,
  maxWidth,
  hideButtons,
}: ISwipeableDrawerRightDialogProps) => {
  return (
    <ContentDialog
      open={open}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      maxWidth={maxWidth}
      buttons={buttons}
      buttonsDisabled={buttonsDisabled}
      noValidate={noValidate}
      component={component}
      hideButtons={hideButtons}
      onSubmit={onSubmit}
    >
      <Stack
        direction="column"
        sx={{
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            overflowY: 'auto',
            height: '100%',
          }}
        >
          {children}
        </Box>
      </Stack>
    </ContentDialog>
  )
}

export default SwipeableDrawerRightDialogWeb
