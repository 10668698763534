import React, { useContext, useState } from 'react'
import SimpleCard from '../../../../components/Cards/SimpleCard'
import {
  Stack,
  Typography,
  Button,
  Box,
  Modal,
  TextField,
  Grid,
  Chip,
} from '@mui/material'
import { useSituationService } from '../../../../core/hooks/useSituationActions'

import { useParams } from 'react-router-dom'
import { ProductSituationEnum } from '../../../../core/types/Situation'
import ProductSituationController from '../../../../core/controllers/ProductSituationController'
import { dateTimeString } from '../../../../core/functions/dateTime'

import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { TUserType } from '../../../../core/types/Auth'
import { AuthContext } from '../../../../core/context/AuthContext'

const getStylesBySituation = (situation: ProductSituationEnum) => {
  switch (situation) {
    case ProductSituationEnum.Approved:
      return {
        color: 'rgba(0, 157, 35, 1)',
        backgroundColor: 'rgba(28, 189, 63, 0.16)',
      }
    case ProductSituationEnum.Pending:
      return {
        color: 'rgba(136, 113, 0, 1)',
        backgroundColor: 'rgba(251, 243, 226, 1)',
      }
    case ProductSituationEnum.Rejected:
      return {
        color: 'rgba(136, 113, 0, 1)',
        backgroundColor: 'rgba(251, 243, 226, 1)',
      }
    case ProductSituationEnum.Blocked:
      return {
        color: 'rgba(212, 54, 44, 1)',
        backgroundColor: 'rgba(189, 28, 28, 0.16)',
      }
    default:
      return {}
  }
}

const ListItem = ({
  item,
  setErrorSending,
  setSuccess,
  setApprovedMessage,
  execute,
  setSending,
  isAdmin,
}: any) => {
  const isBlockedOrApprovedOrPending =
    item.situation === ProductSituationEnum.Blocked ||
    item.situation === ProductSituationEnum.Approved ||
    item.situation === ProductSituationEnum.Pending

  const shouldShowResolveButton =
    !item.isResolved && !isAdmin && !isBlockedOrApprovedOrPending

  const handleResolve = async () => {
    setSending(true)
    try {
      const response = await ProductSituationController.resolved({
        productSituationActionId: item.id || '',
      })

      if (!response.success) {
        setErrorSending(response.error)
        return
      }
      setSuccess(true)
      setApprovedMessage('Resolvido')
      execute()
    } finally {
      setSending(false)
    }
  }

  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '8px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography>{dateTimeString(item.createdDate)}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{item.userName}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Chip
            size="small"
            sx={{ ...getStylesBySituation(item.situation) }}
            label={<Typography>{item.situationDescription}</Typography>}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>{item.reason}</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {item.isResolved ? (
            <img src="/assets/icons/check/check.svg" alt="check-offer" />
          ) : (
            shouldShowResolveButton && (
              <Stack direction="row" spacing={1} justifyContent="center">
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleResolve}
                >
                  Resolver
                </Button>
              </Stack>
            )
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

const RejectionModal = ({ open, onClose, onSubmit }: any) => {
  const [reason, setReason] = useState('')

  const handleSubmit = () => {
    onSubmit(reason)
    setReason('')
    handleClose()
  }

  const handleClose = () => {
    setReason('')
    onClose()
  }

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={handleClose}
      setOpen={open}
      title="Motivo da Rejeição"
      subtitle="Escreva um motivo pra rejeitar o produto"
      buttons={[
        {
          title: 'Cancelar',
          onClick: handleClose,
          type: 'negative',
        },
        {
          title: 'Rejeitar',
          onClick: handleSubmit,
          type: 'positive',
          isDisabled: reason.length < 10,
        },
      ]}
    >
      <Box>
        <TextField
          fullWidth
          multiline
          rows={4}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Escreva o motivo da rejeição com no minimo 10 caracteres..."
        />
      </Box>
    </SwipeableDrawerRightDialog>
  )
}

const BlockedModal = ({ open, onClose, onSubmit }: any) => {
  const [reason, setReason] = useState('')

  const handleSubmit = () => {
    onSubmit(reason)
    setReason('')
    handleClose()
  }

  const handleClose = () => {
    setReason('')
    onClose()
  }

  return (
    <>
      <SwipeableDrawerRightDialog
        open={open}
        onClose={handleClose}
        setOpen={open}
        title="Motivo do Bloqueio"
        subtitle="Escreva um motivo pra bloquear o produto"
        buttons={[
          {
            title: 'Cancelar',
            onClick: handleClose,
            type: 'negative',
          },
          {
            title: 'Bloquear',
            onClick: handleSubmit,
            type: 'positive',
            isDisabled: reason.length < 10,
          },
        ]}
      >
        <Box>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Escreva o motivo do bloqueio com no minimo 10 caracteres..."
          />
        </Box>
      </SwipeableDrawerRightDialog>
    </>
  )
}

const Situation: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalBlockedOpen, setIsBlokedModalOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorSending, setErrorSending] = useState('')
  const [sending, setSending] = useState(false)
  const [ApprovedMessage, setApprovedMessage] = useState('')
  const { id } = useParams()

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const { loading, data, error, execute, clear } = useSituationService({
    productId: id || '',
  })

  const handleRejectClick = () => {
    setIsModalOpen(true)
  }

  const handleBlockedClick = () => {
    setIsBlokedModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }
  const handleModalBlockedClose = () => {
    setIsBlokedModalOpen(false)
  }

  const handleApproved = async () => {
    setSending(true)
    try {
      const response = await ProductSituationController.insert({
        productId: id || '',
        data: { situation: ProductSituationEnum.Approved, reason: '' },
      })

      if (!response.success) {
        setErrorSending(response.error)
        return
      }
      setSuccess(true)
      setApprovedMessage('Produto Aprovado')
      execute()
    } finally {
      setSending(false)
    }
  }

  const handleReject = async (reason: string) => {
    setSending(true)
    try {
      const response = await ProductSituationController.insert({
        productId: id || '',
        data: { situation: ProductSituationEnum.Rejected, reason: reason },
      })

      if (!response.success) {
        setErrorSending(response.error)
        return
      }

      setSuccess(true)
      setApprovedMessage('Produto Rejeitado')
      execute()
    } finally {
      setSending(false)
    }
  }

  const handleBlocked = async (reason: string) => {
    setSending(true)
    try {
      const response = await ProductSituationController.insert({
        productId: id || '',
        data: { situation: ProductSituationEnum.Blocked, reason: reason },
      })
      if (!response.success) {
        setErrorSending(response.error)
        return
      }
      setSuccess(true)
      setApprovedMessage('Produto Bloqueado')
      execute()
    } finally {
      setSending(false)
    }
  }

  return (
    <SimpleCard sx={{ p: '32px' }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          color="#343948"
          fontWeight={700}
          fontSize="16px"
          lineHeight="20px"
        >
          Minhas Situações
        </Typography>

        {isAdmin && (
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="success"
              onClick={handleApproved}
            >
              Aprovar
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={handleRejectClick}
            >
              Rejeitar
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleBlockedClick}
            >
              Bloquear
            </Button>
          </Stack>
        )}
      </Stack>

      <Box sx={{ padding: '16px' }}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={2}>
            <Typography fontWeight={700}>Data</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={700}>Nome</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={700}>Situação</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography fontWeight={700}>Motivo</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={700}>Resolvido</Typography>
          </Grid>
        </Grid>
        {data.map((item) => (
          <ListItem
            key={item.id}
            item={item}
            setErrorSending={setErrorSending}
            setSuccess={setSuccess}
            setApprovedMessage={setApprovedMessage}
            execute={execute}
            setSending={setSending}
            isAdmin={isAdmin}
          />
        ))}
      </Box>

      <LoadingBackdrop open={loading || sending} />

      <RejectionModal
        open={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleReject}
      />

      <BlockedModal
        open={isModalBlockedOpen}
        onClose={handleModalBlockedClose}
        onSubmit={handleBlocked}
      />

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        {ApprovedMessage}
      </SuccessSnackbar>
    </SimpleCard>
  )
}

export default Situation
