import React from "react";
import { Box, Card, LinearProgressProps, Stack, Typography } from "@mui/material";
import ValueOrPercentageGroupButtons, {
    ValueOrPercentageGroupButtonsEnum,
} from "../../../../components/Buttons/ValueOrPercentageGroupButtons";
import NumberFunctions from "../../../../core/functions/NumberFunctions";
import InvisibleDashboardCard from "../../../../components/Cards/InvisibleDashboardCard";
import NotFoundChart from "../../../../components/Chart/NotFoundChart";
import BorderLinearProgress from "../../../../components/Progress/BorderLinearProgress";
import BoxScroll from "../../../../components/Divs/BoxScroll";

function LinearProgressWithLabelStyled(
    props: LinearProgressProps & {
        value: number;
        total: number;
        label: string;
        selected: ValueOrPercentageGroupButtonsEnum;
    }
) {
    const percentage = (props.value * 100) / props.total;

    const processValue = props.selected === ValueOrPercentageGroupButtonsEnum.Percentage ? percentage : props.value;

    const processLabel =
        props.selected === ValueOrPercentageGroupButtonsEnum.Percentage
            ? `${Math.round(processValue)}%`
            : NumberFunctions.formatMoneyDefault(processValue);

    return (
        <Box display="flex" flexDirection="column">
            <Stack direction="row" justifyContent="space-between" gap="4px" sx={{ minWidth: 35, mr: 1, mt: 2 }}>
                <Typography fontWeight={500} fontSize="14px" lineHeight="18px" variant="body2" color="#707275">
                    {props.label}
                </Typography>

                <Typography variant="body2" color="#707275">
                    {processLabel}
                </Typography>
            </Stack>
            <Box sx={{ width: "100%", mr: 1 }}>
                <BorderLinearProgress variant="determinate" {...props} value={percentage} />
            </Box>
        </Box>
    );
}

export interface ILinearProgressWithLabelProps {
    labels?: Array<string>;
    series: Array<{ label: string; value: number; total: number }>;
    options?: Array<any>;
}
export interface ISaleByCityProps {
    chartLinearProgres: ILinearProgressWithLabelProps;
    title: string;
    isVisibleValue: boolean;
    selected: ValueOrPercentageGroupButtonsEnum;
    setSelected: (value: ValueOrPercentageGroupButtonsEnum) => void;
}

export default function SaleByCityPage({
    chartLinearProgres,
    isVisibleValue,
    title,
    selected,
    setSelected,
}: ISaleByCityProps) {
    const [progress, setProgress] = React.useState(10);
    const { series, options } = chartLinearProgres;

    return (
        <Card
            elevation={0}
            sx={{
                borderRadius: "8px",
                height: "100%",
            }}
        >
            <Stack
                direction="row"
                spacing={2}
                alignContent="center"
                alignItems="center"
                justifyContent="space-between"
                sx={{ p: 3 }}
            >
                <Typography fontWeight={600} fontSize="16px" lineHeight="20px" color="#343948">
                    {title}
                </Typography>
                <ValueOrPercentageGroupButtons selected={selected} setSelected={setSelected} />
            </Stack>

            {isVisibleValue && (
                <Stack sx={{ px: 0, mb: 3, height: "17rem", width: "100%" }}>
                    {series.length > 0 && (
                        <BoxScroll>
                            {series.map((serie, index) => (
                                <LinearProgressWithLabelStyled
                                    key={index}
                                    value={serie.value}
                                    total={serie.total}
                                    label={serie.label}
                                    selected={selected}
                                />
                            ))}
                        </BoxScroll>
                    )}
                    <NotFoundChart isVisible={isVisibleValue && series.length <= 0} />
                </Stack>
            )}
            <InvisibleDashboardCard visible={!isVisibleValue} variant="chart" />
        </Card>
    );
}
