import React, { useState } from 'react'
import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import SimpleCard from '../../components/Cards/SimpleCard'
import { GridSearchIcon } from '@mui/x-data-grid'
import FilterIcon from '@mui/icons-material/Tune'
import InfoIcon from '@mui/icons-material/InfoRounded'
import TermsOfUse from './TermsOfUse/termsOfUse'
import { AuthContext } from '../../core/context/AuthContext'
import {
  addDays,
  dateDescription,
  dateTimeString,
} from '../../core/functions/dateTime'
import NumberFunctions from '../../core/functions/NumberFunctions'
import { useIndicatedTenantIndicates } from '../../core/hooks/useIndicatedTenantIndicates'
import { useNavigate } from 'react-router-dom'
import { TIndicatedTenant } from '../../core/types/IndicatedTenant'
import TablePaginationDefault from '../../components/Tables/TablePaginationDefault'
import { grey } from '@mui/material/colors'
import SwipeableDrawerRightDialog from '../../components/Dialogs/SwipeableDrawerRightDialog'
import { AdvancedFilters } from '../Checkouts/AdvancedFilters'
import { CalendarMonth } from '@mui/icons-material'
import LinkIndicated from './LinkIndicated/linkIndicated'
import { useTenantSettingsByTenantId } from '../../core/hooks/useTenantSettingsByTenantId'
import IndicatedController from '../../core/controllers/IndicatedController'
import LoadingBackdrop from '../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../components/Snackbar/SuccessSnackbar'
import ContentDialog from '../../components/Dialogs/ContentDialog'
import TermsOfUseText from './TermsOfUse/TermsOfUseText'

//colums
interface Column {
  id: 'name' | 'receiveComissionUntilDateOf' | 'totalSales' | 'totalComissions'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const IndicatedPage: React.FC = () => {
  const { user } = React.useContext(AuthContext)
  const navigate = useNavigate()
  const [searchType, setSearchType] = useState(0)
  //search
  const [search, setSearch] = useState('')
  //filter
  const [openFilter, setOpenFilter] = useState(false)
  const [startDate, setStartdate] = useState<Date | null | undefined>(
    addDays(-29)
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(new Date())
  const [prodSearchList, setProdSearchLists] = useState<string[]>([])
  const [affilatedsSearchList, setAffilatedsSearchLists] = useState<string[]>(
    []
  )
  const [paymentSearchList, setpaymentSearchLists] = useState<string[]>([])
  const [statusSearchList, setStatusSearchLists] = useState<string[]>([])
  const [current, setCurrent] = useState<TIndicatedTenant | undefined>(
    undefined
  )
  //pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  //error notification
  const [errorSending, setErrorSending] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = React.useState('')

  const [terms, setTerms] = React.useState(false)
  const [errorTerms, setErrorTerms] = React.useState(false)
  const [openTerms, setOpenTerms] = React.useState(false)

  //user token
  const {
    data: tenantSettings,
    execute: executeSettings,
    loading: loadingSettings,
  } = useTenantSettingsByTenantId({
    id: user?.TenantId ?? '',
  })
  // accept use of terms
  const handleAcceptUseTerms = async (): Promise<boolean> => {
    if (!terms) {
      setError('É necessário aceitar os termos de uso')
      setErrorTerms(true)
      return false
    }

    setSuccess(true)
    setError('')
    try {
      var response = await IndicatedController.acceptUseTerms()
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        executeSettings()
      }
    } finally {
      return true
    }
  }

  const handleOpenFilter = () => setOpenFilter(true)
  const handleDateFilter = (value: any) => {
    setStartdate(value.startDate)
    setEndDate(value.endDate)
  }
  const handleCloseFilter = () => setOpenFilter(false)

  //colums
  const columns: readonly Column[] = [
    {
      id: 'name',
      label: 'Nome do indicado',
      minWidth: 100,
    },
    {
      id: 'receiveComissionUntilDateOf',
      label: 'Expira em',
      minWidth: 100,
    },
    {
      id: 'totalSales',
      label: 'Qtde venda',
      minWidth: 100,
    },
    {
      id: 'totalComissions',
      label: 'Comissão (R$)',
      minWidth: 100,
      align: 'right',
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
  ]

  const { data, execute } = useIndicatedTenantIndicates({
    page,
    rows: rowsPerPage,
    search,
    startDate,
    endDate,
  })

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleOpenTerms = () => {
    setOpenTerms(true)
  }

  const handleCloseTerms = () => {
    setOpenTerms(false)
  }

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }
  }, [data, rowsPerPage])

  if (loadingSettings) {
    return <LoadingBackdrop open={loadingSettings} />
  }

  if (!tenantSettings?.acceptIndicationUseTerms) {
    return (
      <Container maxWidth="md">
        <Paper sx={{ p: 2 }}>
          <Box>
            <TermsOfUse
              terms={terms}
              errorTerms={errorTerms}
              setTerms={setTerms}
            />

            <Stack
              direction="row"
              alignContent="end"
              alignItems="end"
              justifyContent="end"
            >
              <Button
                type="submit"
                variant="contained"
                size="small"
                onClick={handleAcceptUseTerms}
              >
                Aceitar Termos
              </Button>
            </Stack>
          </Box>
        </Paper>

        <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
          {error}
        </ErrorSnackbar>

        <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
          Termos aceitos com sucesso
        </SuccessSnackbar>
      </Container>
    )
  }
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        Indicação
      </Typography>

      <SimpleCard sx={{ px: 4, mb: 2 }}>
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Stack>
            <LinkIndicated tenantSettings={tenantSettings} />
          </Stack>
        </Stack>
      </SimpleCard>

      <SimpleCard sx={{ mb: 2 }}>
        <Stack direction="column" spacing={1}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-filter">Filtrar</InputLabel>
            <OutlinedInput
              id="outlined-adornment-filter"
              onChange={(e) => setSearch(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  execute()
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <GridSearchIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Filtros avançados">
                    <IconButton
                      aria-label="toggle search"
                      onClick={handleOpenFilter}
                      edge="end"
                    >
                      <FilterIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              label="Filtrar"
            />
          </FormControl>
          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            alignItems="center"
          >
            <Chip
              variant="filled"
              size="small"
              icon={<CalendarMonth />}
              label={`Filtro do período de ${dateDescription(startDate)} à ${dateDescription(endDate)}`}
            />
          </Stack>
        </Stack>
      </SimpleCard>

      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <SimpleCard sx={{ px: 4 }}>
          <Stack direction="row" spacing={2}>
            <Stack direction="column">
              <Typography variant="body2" color={grey[700]}>
                Total de indicados
              </Typography>
              <Typography variant="h4">{data.total}</Typography>
            </Stack>
          </Stack>
        </SimpleCard>
        <SimpleCard sx={{ px: 4 }}>
          <Stack direction="row" spacing={2}>
            <Stack direction="column">
              <Stack direction="row" spacing={1}>
                <Typography variant="body2" color={grey[700]}>
                  Somatório de comissões
                </Typography>
                <IconButton
                  size="small"
                  onClick={handleOpenTerms}
                  sx={{ p: '0px', fontSize: '0px' }}
                >
                  <InfoIcon fontSize="small" sx={{ color: 'grey' }} />
                </IconButton>
              </Stack>
              <Typography variant="h4">
                {NumberFunctions.formatMoneyDefault(
                  NumberFunctions.toNumber(data.info)
                )}
              </Typography>
            </Stack>
          </Stack>
        </SimpleCard>
      </Stack>
      <SimpleCard sx={{ p: 0 }}>
        <TableContainer
          sx={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.items.map((row) => {
                return (
                  <TableRow>
                    {columns.map((column) => {
                      const value = row[column.id] || ''
                      if (column.id === 'receiveComissionUntilDateOf') {
                        return (
                          //data
                          <TableCell key={column.id} align={column.align}>
                            {dateTimeString(new Date(value))}
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value.toString()}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </SimpleCard>

      <AdvancedFilters
        open={openFilter}
        setOpen={setOpenFilter}
        affiliateds={[]}
        products={[]}
        handleCloseFilter={handleCloseFilter}
        handleDateFilter={handleDateFilter}
        typeFilterValue={searchType}
        startDate={startDate}
        endDate={endDate}
        startDateDefault={addDays(-29)}
        endDateDefault={new Date()}
        onlyRangeDate={true}
        handleTypeFilter={(e: number) => setSearchType(e)}
        handleProductFilter={(e: string[]) => setProdSearchLists(e)}
        handleAffiliatedFilter={(e: string[]) => setAffilatedsSearchLists(e)}
        handlePaymentMethodFilter={(e: string[]) => setpaymentSearchLists(e)}
        handlStatusFilter={(e: string[]) => setStatusSearchLists(e)}
      />

      <ContentDialog
        open={openTerms}
        onClose={handleCloseTerms}
        title="REGRAS DO PROGRAMA DE INDICAÇÕES"
        hasCancel={false}
      >
        <TermsOfUseText />
      </ContentDialog>

      <LoadingBackdrop open={loadingSettings} />

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Termos aceito com sucesso
      </SuccessSnackbar>
    </Box>
  )
}
export default IndicatedPage
