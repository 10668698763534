import { useCallback, useEffect, useState } from "react";
import { ITenantBankAccount } from "../types/Tenant";
import { TenantController } from "../controllers/TenantController";
import { IErrorResponse } from "../types/ErrorResponse";

export const useCurrentTenantBank = ({ tenantId }: { tenantId: string }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ITenantBankAccount | null>(null);
  const [error, setError] = useState('');

  const execute = useCallback(async () => {
    if (!loading) {
      try {
        setLoading(true);

        if (error !== '') {
          setError('');
        }

        const response = await TenantController.getByBankInfoByTenantId(tenantId);
        const responseError = response as IErrorResponse;
        const responseData = response as ITenantBankAccount;

        if (responseError.code) {
          setError(responseError.error);
        } else {
          setData(responseData);
        }
      } catch (e) {
        var message = 'Erro não identificado';
        if (typeof e === "string") {
          message = e.toUpperCase();
        } else if (e instanceof Error) {
          const err = e as Error;
          message = err.message;
        }
        setError(message);
      } finally {
        setLoading(false);
      }
    }
  }, [])

  useEffect(() => {
    execute();
  }, [execute]);

  const clear = () => {
    setError('');
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  };

}