import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

export type TRowRadioButtonsGroupItem<T> = {
  value: T
  label: string
}

export type IRowRadioButtonsGroupProps<T> = {
  name?: string
  value: T
  onChange: (value: T) => void
  items: TRowRadioButtonsGroupItem<T>[]
  sx?: any
}

export default function RowRadioButtonsGroup<T>({
  name,
  value,
  items,
  sx,
  onChange,
}: IRowRadioButtonsGroupProps<T>) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputElementValue = (event.target as HTMLInputElement).value
    const inputValue = inputElementValue as T
    onChange(inputValue)
  }

  return (
    <FormControl>
      {name && (
        <FormLabel id={`row-radio-group-${name.replaceAll(' ', '')}`}>
          {name}
        </FormLabel>
      )}
      <RadioGroup
        row
        aria-labelledby={`row-radio-group-${(name ?? '').replaceAll(' ', '')}`}
        name={`row-radio-buttons-group-${(name ?? '').replaceAll(' ', '')}`}
        value={value}
        onChange={handleChange}
        sx={sx}
      >
        {items.map((item) => (
          <FormControlLabel
            key={`rowRadioButons${(name ?? '').replaceAll(' ', '')}${item.label.replaceAll(' ', '-')}`}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
