import { LinearProgress, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#D8D9FF",
    },
    [`& .${linearProgressClasses.bar}`]: {
        //borderRadius: 8,
        backgroundColor: "#476ADA",
    },
}));

export default BorderLinearProgress;
