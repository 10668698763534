import React, { useCallback } from "react";
import { IErrorResponse } from "../types/ErrorResponse";
import IndicatedController from "../controllers/IndicatedController";
import { TIndicatedTenantPagination } from "../types/IndicatedTenant";

export interface IIndicatedTenantIndicatesProp {
    page: number;
    rows: number;
    search?: string;
    startDate: Date | null | undefined;
    endDate: Date | null | undefined;
}

const valueDefault = {
    items: [],
    page: 0,
    rowsPerPage: 0,
    totalPages: 0,
    total: 0,
};

export const useIndicatedTenantIndicates = ({
    page,
    rows,
    search,
    startDate,
    endDate,
}: IIndicatedTenantIndicatesProp) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<TIndicatedTenantPagination>(valueDefault);
    const [error, setError] = React.useState<string>("");

    const execute = useCallback(async () => {
        if (!loading) {
            try {
                setLoading(true);

                if (error !== "") {
                    setError("");
                }

                var response = await IndicatedController.getIndicatesAll({ page, rows, search, startDate, endDate });
                const responseError = response as IErrorResponse;
                const responseData = response as TIndicatedTenantPagination;

                if (responseError.code) {
                    setError(responseError.error);
                    setData(valueDefault);
                } else {
                    setData(responseData);
                }
            } catch (e) {
                var message = "Erro não identificado";
                if (typeof e === "string") {
                    message = e.toUpperCase();
                } else if (e instanceof Error) {
                    const err = e as Error;
                    message = err.message;
                }
                setError(message);
            } finally {
                setLoading(false);
            }
        }
    }, [error, loading, page, rows, search, startDate, endDate]);

    React.useEffect(() => {
        execute();
    }, [page, rows, startDate, endDate]);

    const clear = () => {
        setError("");
    };

    return {
        loading,
        data,
        error,
        execute,
        clear,
    };
};
