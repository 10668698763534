import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Avatar, Chip, Paper, SxProps, Theme } from "@mui/material";
import NumberFunctions from "../../../core/functions/NumberFunctions";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { grey } from "@mui/material/colors";
import InvisibleDashboardCard from "../../../components/Cards/InvisibleDashboardCard";

export interface IAppWidgetSummaryProps {
    src: string;
    sx?: SxProps<Theme>;
    title: string;
    total: number;
    subtotal: number;
    increasing: number;
    type: "money" | "percentage" | "int";
    subtype: "positive" | "negative";
    isSubtype: boolean;
    isVisibleValue: boolean;
}

export default function AppWidgetSummary({
    title,
    total,
    increasing,
    src,
    isSubtype = true,
    subtotal,
    subtype,
    type = "money",
    isVisibleValue = true,
    sx,
    ...other
}: IAppWidgetSummaryProps) {
    return (
        <Card
            component={Stack}
            spacing={3}
            direction="row"
            elevation={0}
            alignItems="center"
            sx={{
                p: "16px",
                borderRadius: "8px",
                ...sx,
            }}
            {...other}
        >
            {src && (
                <Avatar sx={{ width: 40, height: 40, bgcolor: "#F2F2FF" }}>
                    <img alt={title} src={src} />
                </Avatar>
            )}

            <Stack spacing={0.5}>
                <Typography fontWeight={500} fontSize="14px" lineHeight="14px" sx={{ color: "#707275" }}>
                    {title}
                </Typography>
                <Stack direction="row" spacing={2} alignContent="center" alignItems="center">
                    {isVisibleValue && (
                        <Typography fontWeight={600} fontSize="24px" lineHeight="32px" sx={{ color: "#38393B" }}>
                            {type === "money" ? NumberFunctions.formatMoneyDefault(total) : total}
                            {type === "percentage" ? "%" : ""}
                        </Typography>
                    )}
                    <InvisibleDashboardCard visible={!isVisibleValue} />
                    {isVisibleValue && (
                        <Chip
                            size="small"
                            color="success"
                            icon={subtype === "positive" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                            label={`${increasing?.toFixed(0)}%`}
                            sx={{ backgroundColor: subtype === "positive" ? "#1CBD3F" : "#D4362C" }}
                        />
                    )}
                    <InvisibleDashboardCard visible={!isVisibleValue} variant="chip" />
                </Stack>
                {isSubtype && (
                    <Stack direction="row" spacing={0.6} alignContent="center" alignItems="center">
                        {isVisibleValue && (
                            <Typography
                                fontWeight={600}
                                fontSize="12px"
                                lineHeight="16px"
                                sx={{ color: subtype === "positive" ? "#1CBD3F" : "#D4362C" }}
                            >
                                {subtype === "positive" ? "+ " : "- "}
                                {type === "money"
                                    ? NumberFunctions.formatMoneyDefault(Math.abs(subtotal))
                                    : Math.abs(subtotal)}
                                {type === "percentage" ? "%" : ""}
                            </Typography>
                        )}
                        <InvisibleDashboardCard visible={!isVisibleValue} size="small" />
                        <Typography fontWeight={400} fontSize="12px" lineHeight="16px" sx={{ color: "#707275" }}>
                            no período selecionado
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </Card>
    );
}
