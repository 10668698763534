import * as React from 'react'
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
  numberInputClasses,
} from '@mui/base/Unstable_NumberInput'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'

const CustomNumberInput = React.forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInputElement,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: '▴',
        },
        decrementButton: {
          children: '▾',
        },
      }}
      {...props}
      ref={ref}
    />
  )
})

export default function NumberInput({
  placeholder,
  min,
  max,
  value,
  onChange,
}: {
  placeholder?: string
  value: number
  min?: number
  max?: number
  onChange: (
    event:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.PointerEvent<Element>
      | React.KeyboardEvent<Element>,
    value: number | null
  ) => void
}) {
  const theme = useTheme()
  return (
    <CustomNumberInput
      aria-label="Demo number input"
      placeholder={placeholder}
      min={min}
      max={max}
      onChange={onChange}
      value={value}
    />
  )
}

const StyledInputRoot = styled('div')(
  ({ theme }) => `
  font-family: Montserrat, "Open Sans", Roboto;
  font-weight: 400;
  border-radius: 10px;  // Ajuste o valor aqui
  color: ${theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[900]};
  background: ${theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  };
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 4px;
  padding: 2px;

  &.${numberInputClasses.focused} {
    border-width: 1px;
    border-color: ${theme.palette.primary.main};
    box-shadow: 0 0 0 2px ${
      theme.palette.mode === 'dark'
        ? theme.palette.primary.dark
        : theme.palette.primary.light
    };
  }

  &:hover {
    border-color: ${theme.palette.primary.main};
  }

  &:focus-visible {
    outline: 0;
  }
`
)

const StyledInputElement = styled('input')(
  ({ theme }) => `
  font-size: 0.875rem; // Mantendo o tamanho da fonte original
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  color: ${theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[900]};
  background: inherit;
  border: none;
  border-radius: 8px; // Ajustando para uma borda mais arredondada
  padding: 2px 4px; // Reduzindo o padding
  outline: 0;
  width: 100%; // Certifique-se de que o input ocupe o espaço disponível
`
)

const StyledButton = styled('button')(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 16px; // Reduzindo a largura do botão
  height: 16px; // Reduzindo a altura do botão
  font-family: system-ui, sans-serif;
  font-size: 0.75rem; // Mantendo o tamanho da fonte original
  line-height: 1;
  box-sizing: border-box;
  background: ${theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#fff'};
  border: 0;
  color: ${theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[300]};
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    border-top-left-radius: 8px; // Ajustando para uma borda mais arredondada
    border-top-right-radius: 8px; // Ajustando para uma borda mais arredondada
    border: 1px solid;
    border-bottom: 0;
    border-color: ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200]};
    background: ${theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50]};
    color: ${theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[900]};

    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main};
      border-color: ${theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark};
    }
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    border-bottom-left-radius: 8px; // Ajustando para uma borda mais arredondada
    border-bottom-right-radius: 8px; // Ajustando para uma borda mais arredondada
    border: 1px solid;
    border-color: ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200]};
    background: ${theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50]};
    color: ${theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[900]};
  }

  &:hover {
    cursor: pointer;
    color: #FFF;
    background: ${theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main};
    border-color: ${theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark};
  }

  & .arrow {
    transform: translateY(-1px);
  }

  & .arrow {
    transform: translateY(-1px);
  }
`
)
