import {
  Select,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
} from '@mui/material'

export interface IStatusFilterProps {
  handleStatusFilter: (values: string[]) => void
  selectds: string[]
  setSelectds: (value: React.SetStateAction<string[]>) => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const options = [
  'Pendente',
  'Pago',
  'Cancelado',
  'Processando',
  'Falha',
  'Estorno',
]

export const StatusFilter = ({
  handleStatusFilter,
  selectds,
  setSelectds,
}: IStatusFilterProps) => {
  const handleChange = (event: SelectChangeEvent<typeof selectds>) => {
    const {
      target: { value },
    } = event

    const values = typeof value === 'string' ? value.split(',') : value
    setSelectds(values)
    if (values.length === 0) handleStatusFilter([])
    else handleStatusFilter(values.map((x) => convertToType(x)))
  }

  const convertToType = (value: string): string => {
    if (value === 'Pendente') return 'pending'
    if (value === 'Pago') return 'paid'
    if (value === 'Cancelado') return 'canceled'
    if (value === 'Processando') return 'processing'
    if (value === 'Falha') return 'failed'
    if (value === 'Estorno') return 'chargedback'
    return value
  }

  return (
    <Stack direction="row" alignContent="center" alignItems="center">
      <FormControl fullWidth>
        <InputLabel id="status-multiple-checkbox-label">Status</InputLabel>
        <Select
          labelId="status-multiple-checkbox-label"
          id="status-multiple-checkbox"
          multiple
          value={selectds}
          onChange={handleChange}
          input={<OutlinedInput label="Status" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {options
            .sort((a, b) => (a > b ? 1 : -1))
            .map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox checked={selectds.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Stack>
  )
}
