import {
  Range,
  InputRange,
  StaticRange,
  Preview,
  DefinedRangeProps,
} from 'react-date-range'

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns'

const defineds = {
  now: new Date(),
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  last7Days: addDays(new Date(), -6),
  last30Days: addDays(new Date(), -29),
  last90Days: addDays(new Date(), -89),
  last180Days: addDays(new Date(), -179),
}

const staticRangeHandler = {
  range: (props?: DefinedRangeProps) => {
    const p: Preview = {}
    return p
  },
  isSelected(range: Range) {
    const definedRange = this.range()
    if (
      !range.startDate ||
      !range.endDate ||
      !definedRange.startDate ||
      !definedRange.endDate
    ) {
      return false
    }
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    )
  },
}

export function createStaticRanges(ranges: any) {
  return ranges.map((range: any) => ({ ...staticRangeHandler, ...range }))
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Hoje',
    range: (props?: DefinedRangeProps) => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Ontem',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },
  /*{
    label: 'Essa semana',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: 'Semana passada',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: 'Este mês',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },*/
  {
    label: 'Últimos 7 dias',
    range: () => ({
      startDate: defineds.last7Days,
      endDate: defineds.now,
    }),
  },
  {
    label: 'Últimos 30 dias',
    range: () => ({
      startDate: defineds.last30Days,
      endDate: defineds.now,
    }),
  },
  {
    label: 'Últimos 90 dias',
    range: () => ({
      startDate: defineds.last90Days,
      endDate: defineds.now,
    }),
  },
  {
    label: 'Últimos 180 dias',
    range: () => ({
      startDate: defineds.last180Days,
      endDate: defineds.now,
    }),
  },
])

export const defaultInputRanges: InputRange[] = [
  {
    label: 'dias até hoje',
    range(value: number) {
      return {
        startDate: addDays(
          defineds.startOfToday,
          (Math.max(Number(value), 1) - 1) * -1
        ),
        endDate: defineds.endOfToday,
      }
    },
    getCurrentValue(range: Range) {
      if (!range.endDate) return '~'
      if (!isSameDay(range.endDate, defineds.endOfToday)) return '-'
      if (!range.startDate) return '∞'
      return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1
    },
  },
  {
    label: 'dias a partir de hoje',
    range(value: number) {
      const today = new Date()
      return {
        startDate: today,
        endDate: addDays(today, Math.max(Number(value), 1) - 1),
      }
    },
    getCurrentValue(range: Range) {
      if (!range.startDate) return '~'
      if (!isSameDay(range.startDate, defineds.startOfToday)) return '-'
      if (!range.endDate) return '∞'
      return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1
    },
  },
]
