import { Box, ListItem } from "@mui/material";
import { useRef, useState } from "react";
import { useResponsive } from "../../core/hooks/useResponsive";

interface IBoxScrollProps {
    children?: React.ReactNode;
}

const BoxScroll = ({ children }: IBoxScrollProps) => {
    const [active, setActive] = useState(false);

    const mdUp = useResponsive("up", "md");

    const handleMouseEnter = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        setActive(true);
    };

    const handleMouseLeave = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        setActive(false);
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
            }}
        >
            <ListItem
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{ width: "100%", height: "100%" }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        overflowY: !mdUp ? "auto" : active ? "scroll" : "hidden",
                        pl: 1,
                        pb: 1,
                        pr: active ? (mdUp ? "0.3em" : "0.2em") : mdUp ? "0.55em" : "0.35em",
                        "&::-webkit-scrollbar": {
                            width: mdUp ? "0.3em" : "0.2em",
                        },
                        "&::-webkit-scrollbar-track": {
                            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#476ADA",
                        },
                    }}
                >
                    {children}
                </Box>
            </ListItem>
        </Box>
    );
};

export default BoxScroll;
