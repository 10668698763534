import { SxProps, Theme } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

export interface DatePickerFilterProps {
  label: string
  sx?: SxProps<Theme> | undefined
  value?: Date | null | undefined
  disabled?: boolean
  setDate: (newValue: any) => void
}

export const TimePickerFilter = ({
  label,
  sx,
  value,
  disabled,
  setDate,
}: DatePickerFilterProps) => {
  const oldValue = value
    ? dayjs(value).locale('pt-br')
    : dayjs(Date.now()).locale('pt-br')
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <TimePicker
        label={label}
        disabled={disabled}
        value={oldValue}
        onChange={(newValue) => setDate(newValue)}
        sx={sx}
        slotProps={{ textField: { size: 'small' } }}
      />
    </LocalizationProvider>
  )
}
