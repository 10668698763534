import { Typography } from '@mui/material'

interface SubtitlePageTypographyProps {
  children: React.ReactNode
}

const SubtitlePageTypography = ({ children }: SubtitlePageTypographyProps) => {
  return (
    <Typography
      variant="body2"
      fontWeight={400}
      fontSize="14px"
      lineHeight="18px"
      color="rgba(112, 114, 117, 1)"
    >
      {children}
    </Typography>
  )
}

export default SubtitlePageTypography
