import { Box, Divider, Stack, Typography } from '@mui/material'

interface IListItemDetailProp {
  title: string | React.ReactNode
  value?: string | JSX.Element
  widthTitlePercentage?: number
  viewDivider?: boolean | undefined
  py?: number
  px?: number
  direction?: 'row' | 'column' | undefined
}

const ListItemDetail = ({
  title,
  value,
  widthTitlePercentage,
  viewDivider,
  py,
  px,
  direction,
}: IListItemDetailProp) => {
  const direct = direction || 'row'
  const align = direct === 'row' ? 'center' : 'start'
  const widthTitle =
    direct === 'row' ? `${widthTitlePercentage || 30}%` : '100%'
  const widthValue =
    direct === 'row' ? `${100 - (widthTitlePercentage || 30)}%` : '100%'
  const spacing = direct === 'row' ? 2 : 0

  return (
    <Box>
      <Stack
        direction={direct}
        spacing={spacing}
        alignContent={align}
        alignItems={align}
        sx={{ py: py ?? 2, px: px ?? 2 }}
      >
        <Typography variant="subtitle2" sx={{ width: widthTitle }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ width: widthValue }}>
          {value}
        </Typography>
      </Stack>
      {(viewDivider === undefined || viewDivider === true) && <Divider />}
    </Box>
  )
}

export default ListItemDetail
