import { Box, Stack, TextField, Typography } from '@mui/material'
import {
  TMemberService,
  TMemberServiceType,
} from '../../../core/types/MemberService'
import { red } from '@mui/material/colors'
import { FieldErrors, UseFormRegister } from 'react-hook-form'

export interface ITenantMemberService {
  memberService: TMemberService
  errors: FieldErrors<TMemberService>
  register: UseFormRegister<TMemberService>
}

export const TenantMemberService = ({
  memberService,
  errors,
  register,
}: ITenantMemberService) => {
  return (
    <Box sx={{ width: '100%', margin: '0 auto' }}>
      <Stack direction="column" p={2} spacing={3}>
        <Box>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="text"
            label="Nome"
            {...register('identifier', { required: true })}
            error={errors.identifier?.type === 'required'}
            InputLabelProps={{ shrink: true }}
          />
          {errors.identifier?.type === 'required' && (
            <Typography variant="caption" color={red[700]}>
              Campo obrigatório
            </Typography>
          )}
        </Box>
        {memberService.type === TMemberServiceType.Cademi && (
          <Box>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              label="Subdomínimo"
              {...register('domain', { required: true })}
              error={errors.domain?.type === 'required'}
              InputLabelProps={{ shrink: true }}
            />
            {errors.domain?.type === 'required' && (
              <Typography variant="caption" color={red[700]}>
                Campo obrigatório
              </Typography>
            )}
          </Box>
        )}
        {memberService.type !== TMemberServiceType.AstronMembers && (
          <Box>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              label="Token"
              {...register('token', { required: true })}
              error={errors.token?.type === 'required'}
              InputLabelProps={{ shrink: true }}
            />
            {errors.token?.type === 'required' && (
              <Typography variant="caption" color={red[700]}>
                Campo obrigatório
              </Typography>
            )}
          </Box>
        )}
        {[TMemberServiceType.Cademi, TMemberServiceType.AstronMembers].includes(
          memberService.type
        ) && (
          <Box>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              label="Chave da API"
              {...register('apiKey', { required: true })}
              error={errors.apiKey?.type === 'required'}
              InputLabelProps={{ shrink: true }}
              helperText={
                memberService.type === TMemberServiceType.AstronMembers
                  ? 'Chave da Integração configurada na central AstronMembers'
                  : undefined
              }
            />
            {errors.apiKey?.type === 'required' && (
              <Typography variant="caption" color={red[700]}>
                Campo obrigatório
              </Typography>
            )}
          </Box>
        )}
        {memberService.type === TMemberServiceType.AstronMembers && (
          <Box>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="password"
              label="Senha"
              {...register('token', { required: true })}
              error={errors.token?.type === 'required'}
              InputLabelProps={{ shrink: true }}
            />
            {errors.token?.type === 'required' && (
              <Typography variant="caption" color={red[700]}>
                Campo obrigatório
              </Typography>
            )}
          </Box>
        )}
        {memberService.type === TMemberServiceType.AstronMembers && (
          <Box>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              label="Código do Club (club_id)"
              {...register('domain', { required: true })}
              error={errors.domain?.type === 'required'}
              InputLabelProps={{ shrink: true }}
            />
            {errors.domain?.type === 'required' && (
              <Typography variant="caption" color={red[700]}>
                Campo obrigatório
              </Typography>
            )}
          </Box>
        )}
        {[TMemberServiceType.TheMembers].includes(memberService.type) && (
          <Box>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              label="Senha Padrão da Plataforma"
              {...register('platformDefaultPassword', { required: true })}
              error={errors.platformDefaultPassword?.type === 'required'}
              InputLabelProps={{ shrink: true }}
            />
            {errors.platformDefaultPassword?.type === 'required' && (
              <Typography variant="caption" color={red[700]}>
                Campo obrigatório
              </Typography>
            )}
          </Box>
        )}
        {[TMemberServiceType.AstronMembers].includes(memberService.type) && (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="text"
            label="Código do Webhook"
            {...register('webhookCode')}
            InputLabelProps={{ shrink: true }}
            helperText="Encontrado no final da URL do webhook na aba integrações do clube"
          />
        )}
      </Stack>
    </Box>
  )
}
