import { Autocomplete, Box, Button, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { TCommissionRule, TCommissionRuleProduct } from "../../../../core/types/CommissionRule";
import { TProductSimple } from "../../../../core/types/Product";
import AddIcon from "@mui/icons-material/AddCircle";
import RemoveIcon from "@mui/icons-material/DeleteOutline";
import ConfirmDialog from "../../../../components/Dialogs/ConfirmDialog";
import { red } from "@mui/material/colors";

export interface ICommissionRuleDetailsProps {
    commission: TCommissionRule;
    products: TCommissionRuleProduct[];
    saveCommissionRuleProducts: (productAffiliationIds: string[]) => void;
}

const CommissionRuleProducts = ({
    commission,
    products,
    saveCommissionRuleProducts,
}: ICommissionRuleDetailsProps) => {
    const [options, setOptions] = useState<TCommissionRuleProduct[]>([]);
    const [product, setProduct] = useState<TCommissionRuleProduct>({ productAffiliationId: "", productName: "" });
    const [errorProduct, setErrorProduct] = useState("");
    const [deleteId, setDeleteId] = useState("");

    const handleChangeAdd = () => {
        if (product.productAffiliationId === "") {
            setErrorProduct("É necessário selecionar um produto");
            return;
        }

        const exists = commission.products?.find((p) => p.productAffiliationId === product.productAffiliationId);
        if (exists) {
            return;
        }

        commission.products?.push({ ...product });
        const ids = commission.products?.map((p) => p.productAffiliationId);
        saveCommissionRuleProducts(ids ?? []);
        setProduct({ productAffiliationId: "", productName: "" });
    }

    const handleChangeDelete = (productAffiliationId: string) => {
        setDeleteId(productAffiliationId);
    }

    const deleteProductAffiliation = () => {
        if (deleteId === "") {
            return;
        }

        const prods = commission.products?.filter((p) => p.productAffiliationId !== deleteId);
        const ids = prods?.map((p) => p.productAffiliationId);
        saveCommissionRuleProducts(ids || []);
        setDeleteId("");
    }

    const handleChangeAddProductsAll = () => {
        const ids = commission.products?.map((p) => p.productAffiliationId);
        const newIds = options.map((o) => o.productAffiliationId);
        ids?.push(...newIds);
        saveCommissionRuleProducts(ids ?? []);
        setProduct({ productAffiliationId: "", productName: "" });
    }

    useEffect(() => {
        const ops: TCommissionRuleProduct[] = [];
        products.map((p) => {
            const exists = commission.products?.find((prod) => prod.productAffiliationId === p.productAffiliationId);
            if (!exists) {
                ops.push({ ...p });
            }
        });
        setOptions(ops);
    }, [commission]);

    return (
        <Stack direction="column" spacing={2} width="100%">
            <Box>
                <Stack
                    direction="row"
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ pr: 2 }}
                >
                    <Typography
                        variant="h6"
                        sx={{ m: 1 }}
                    >
                        Adicionar produto a regra
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={handleChangeAddProductsAll}
                    >
                        Adicionar todos os produtos
                    </Button>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ px: 2 }} alignContent="start" alignItems="start">
                    <Box width="100%">
                        <Autocomplete
                            disablePortal
                            fullWidth
                            size="small"
                            options={options}
                            getOptionLabel={(option) => option.productName}
                            onChange={(event, option) => {
                                setErrorProduct("");
                                if (option && option !== null) {
                                    setProduct({ ...option });
                                }
                            }}
                            value={{ ...product }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Produto"
                                    value={product}
                                />
                            )}
                        />
                        {errorProduct !== "" && <Typography variant="caption" color={red[700]}>{errorProduct}</Typography>}
                    </Box>
                    <IconButton onClick={handleChangeAdd}>
                        <AddIcon color="primary" />
                    </IconButton>
                </Stack>
            </Box>
            <Stack direction="column" width="100%">
                <Typography variant="h6" sx={{ m: 1 }}>Produtos adicionados</Typography>
                <Grid container columns={18} width="100%">
                    <Grid item xs={14}>
                        <Typography variant="body2" fontWeight={600} sx={{ pl: 2 }}>Descrição</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body2" fontWeight={600} textAlign="center">Remover</Typography>
                    </Grid>
                    {commission.products?.map((p) => (
                        <Stack
                            direction="row"
                            alignContent="center"
                            alignItems="center"
                            sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }}
                        >
                            <Grid item xs={14}>
                                <Typography variant="body2" sx={{ px: 2, py: 1 }}>{p.productName}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack alignContent="center" alignItems="center">
                                    <IconButton size="small" onClick={() => handleChangeDelete(p.productAffiliationId)}>
                                        <RemoveIcon color="error" sx={{ fontSize: "1.2rem" }} />
                                    </IconButton>
                                </Stack>
                            </Grid>
                        </Stack>
                    ))}
                </Grid>
            </Stack>

            <ConfirmDialog
                open={deleteId !== ""}
                onClose={() => setDeleteId("")}
                message="Deseja realmente deletar o produto ?"
                onYes={deleteProductAffiliation}
            />
        </Stack>
    );
}

export default CommissionRuleProducts;