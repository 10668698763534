import { FieldArrayWithId, UseFormWatch } from 'react-hook-form'
import { TProduct, TProductPrice } from '../../../../../core/types/Product'
import ProductPriceItemWeb from './ProductPriceItemWeb'
import { useResponsive } from '../../../../../core/hooks/useResponsive'
import ProductPriceItemMobile from './ProductPriceItemMobile'

export interface IProductPriceItemProps {
  price: TProductPrice
  index: number
  isTenantBank: boolean
  fields: FieldArrayWithId<TProduct, 'prices', 'id'>[]
  watch: UseFormWatch<TProduct>
  onCopyURL: (producerLink: boolean, index?: number) => void
  onOpenPrice: (productPrice: TProductPrice, index: number) => void
  onOpenDisable: (productPrice: TProductPrice, index: number) => void
  onDelete: (productPrice: TProductPrice, index: number) => void
}

const ProductPriceItem = (data: IProductPriceItemProps) => {
  const mdUp = useResponsive('up', 'md')

  if (mdUp) return <ProductPriceItemWeb {...data} />

  return <ProductPriceItemMobile {...data} />
}

export default ProductPriceItem
