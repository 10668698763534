import {
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'
import { RecipientStatus } from '../../../../settings/TenantBalanceWithdraw/RecipientStatus'
import SwitchRoundSmall from '../../../../../components/Switch/SwitchRoundSmall'
import { TTenantSettings } from '../../../../../core/types/Tenant'
import { HtmlTooltip } from '../../../../../components/Tooltip/HtmlTooltip'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

export interface IUserSettingGeneralProps {
  tenantSettings: TTenantSettings
  loadingSettings: boolean
  settings: TTenantSettings | null
  handleChangeCopyRecipientId: () => void
  handleOpenRecipient: () => void
  handleChangeSalesEnabled: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>
  handleChangeWithdrawlsEnabled: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>
}

const UserSettingGeneral = ({
  tenantSettings,
  loadingSettings,
  settings,
  handleChangeCopyRecipientId,
  handleOpenRecipient,
  handleChangeSalesEnabled,
  handleChangeWithdrawlsEnabled,
}: IUserSettingGeneralProps) => {
  return (
    <Stack direction="column" spacing={0} sx={{ py: 1 }}>
      <ListItemDefault
        title="Código do Recebedor"
        widthTitlePercentage={40}
        variant="compact"
        value={
          <Box>
            {(tenantSettings.recipientId ?? '') !== '' && (
              <Stack direction="row" spacing={0.5} alignItems="center">
                <ListItemButton onClick={handleChangeCopyRecipientId}>
                  <Typography variant="subtitle2">
                    {tenantSettings.recipientId}
                  </Typography>
                </ListItemButton>
                <HtmlTooltip title="Copiar código do recebedor">
                  <IconButton
                    size="small"
                    onClick={handleChangeCopyRecipientId}
                  >
                    <img src="/assets/icons/copy/copy.svg" alt="copy" />
                  </IconButton>
                </HtmlTooltip>
                <HtmlTooltip title="Abrir recebedor na Pagar.Me">
                  <IconButton size="small" onClick={handleOpenRecipient}>
                    <OpenInNewIcon color="primary" />
                  </IconButton>
                </HtmlTooltip>
              </Stack>
            )}
          </Box>
        }
      />
      {(tenantSettings.recipientId ?? '') !== '' && (
        <ListItemDefault
          title="Status do Recebedor"
          widthTitlePercentage={40}
          variant="compact"
          value={
            !loadingSettings ? (
              <RecipientStatus status={settings?.recipientStatus ?? ''} />
            ) : (
              <CircularProgress size={27} />
            )
          }
        />
      )}
      <ListItemDefault
        title={
          <FormControlLabel
            control={
              <SwitchRoundSmall
                sx={{ m: 1 }}
                checked={tenantSettings.salesEnabled ?? false}
                onChange={handleChangeSalesEnabled}
              />
            }
            label={<Typography variant="subtitle2">Permite vender</Typography>}
            sx={{ width: '100%' }}
          />
        }
        widthTitlePercentage={50}
        variant="compact"
        value={
          <FormControlLabel
            control={
              <SwitchRoundSmall
                sx={{ m: 1 }}
                checked={tenantSettings.withdrawlsEnabled ?? false}
                onChange={handleChangeWithdrawlsEnabled}
              />
            }
            label={
              <Typography variant="subtitle2">
                Permite realizar saques
              </Typography>
            }
            sx={{ width: '100%' }}
          />
        }
      />
    </Stack>
  )
}

export default UserSettingGeneral
