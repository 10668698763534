import { Paper, SxProps, Theme } from '@mui/material'

export interface ISimpleCardProps {
  children?: JSX.Element | JSX.Element[] | undefined
  sx?: SxProps<Theme> | undefined
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}

const SimpleCard = ({ children, sx, onClick }: ISimpleCardProps) => {
  return (
    <Paper
      elevation={0}
      onClick={onClick}
      sx={{
        width: '100%',
        p: 2,
        borderRadius: '8px',
        ...sx,
      }}
    >
      {children}
    </Paper>
  )
}

export default SimpleCard
