import { CreditCard, CreditCardType } from "../types/CreditCard";
import { startNumberVisa } from "../constants";
import CreditCards from "../functions/creditCards";

export class CreditCardVisa implements CreditCard {
    getType(): CreditCardType {
        return CreditCardType.Visa;
    }
    compare(number?: string): boolean {
        return CreditCards.compare(startNumberVisa, number);
    }
    getImageUrl() {
        return `${process.env.PUBLIC_URL}/assets/creditcards/visa.png`;
    }
}