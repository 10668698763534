export const statusOptions = [
  { label: 'Ativos', value: 'active' },
  { label: 'Inativos', value: 'inactive' },
  { label: 'Excluídos/Bloqueados', value: 'deleted' },
]

export const situationStatusOptions = [
  { label: 'Pendente', value: 1 },
  { label: 'Aprovado', value: 2 },
  { label: 'Bloqueado', value: 3 },
  { label: 'Rejeitado', value: 4 },
]

export const ItensOptions = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
]
