import { Box, Stack, Tab, Tabs } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'
import CommissionRuleData from './components/CommissionRuleData'
import CommissionRuleProducts from './components/CommissionRuleProducts'
import CommissionRulesController from '../../../core/controllers/CommissionRuleController'
import { IErrorResponse } from '../../../core/types/ErrorResponse'
import {
  TCommissionRule,
  TCommissionRuleProduct,
} from '../../../core/types/CommissionRule'

export interface ICommissionRuleDetailsProps {
  commission: TCommissionRule
  products: TCommissionRuleProduct[]
  open: boolean
  handleClose: () => void
  setOpen: (value: boolean) => void
  setCommissionRule: (value: TCommissionRule) => void
  saveCommissionRule: () => void
  saveCommissionRuleProducts: (productAffiliationIds: string[]) => Promise<void>
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const CommissionRuleDetails = ({
  commission,
  products,
  open,
  handleClose,
  setOpen,
  setCommissionRule,
  saveCommissionRule,
  saveCommissionRuleProducts,
}: ICommissionRuleDetailsProps) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<TCommissionRule | undefined>(undefined)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const getCommissionRuleProducts = useCallback(
    async (code?: string) => {
      try {
        if (!code || code === '') {
          return
        }

        setLoading(true)
        const response = await CommissionRulesController.get({ code })
        const responseError = response as IErrorResponse
        const responseData = response as TCommissionRule
        if (responseError.code) {
          console.log(responseError.error)
        } else {
          setData(responseData)
          commission.products = responseData.products
        }
      } catch (e) {
        var message = 'Erro não identificado'
        if (typeof e === 'string') {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        console.log(message)
      } finally {
        setLoading(false)
      }
    },
    [commission, setLoading, setCommissionRule]
  )

  const saveMyCommissionRuleProducts = async (
    productAffiliationIds: string[]
  ) => {
    await saveCommissionRuleProducts(productAffiliationIds)
    getCommissionRuleProducts(commission.codeId)
  }

  useEffect(() => {
    getCommissionRuleProducts(commission.codeId)
    setTabIndex(0)
  }, [commission])

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={handleClose}
      setOpen={setOpen}
      title="Regra de Comissão"
      subtitle="Defina regra de comissão automática"
      buttons={[
        { title: 'Cancelar', onClick: handleClose, type: 'negative' },
        { title: 'Salvar', onClick: saveCommissionRule, type: 'positive' },
      ]}
    >
      <Box>
        <Stack direction="row" sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ flexGrow: 1 }}
          >
            <Tab label="Geral" {...a11yProps(0)} />
            {commission.codeId !== '' && (
              <Tab label="Produtos" {...a11yProps(1)} />
            )}
          </Tabs>
        </Stack>
        <TabPanel value={tabIndex} index={0}>
          <CommissionRuleData
            handleClose={handleClose}
            setCommissionRule={setCommissionRule}
            saveCommissionRule={saveCommissionRule}
            commission={commission}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <CommissionRuleProducts
            commission={data || commission}
            products={products}
            saveCommissionRuleProducts={saveMyCommissionRuleProducts}
          />
        </TabPanel>
      </Box>
    </SwipeableDrawerRightDialog>
  )
}

export default CommissionRuleDetails
