import api, { getResponseError } from "../../services/api";
import DashboardFunctions from "../functions/DashboardFunctions";
import { addDays } from "../functions/dateTime";
import { TSalesGroupKey, TComissionCard, TDashboardDefaultProps, TConversionsCard, TSalesPaymentMethod, TSalesForGroupKey, TDailySales, TDailySalesProps } from "../types/Dashboard";

const getComissionCard = async ({ tenantId, startPeriod, endPeriod }: TDashboardDefaultProps) => {
    try {
        let path = `/Dashboard/ComissionCard?TenantId=${tenantId}&StartPeriod=${startPeriod.toISOString()}&EndPeriod=${endPeriod.toISOString()}`;
        const { data: response } = await api.get<TComissionCard>(path);
        if (response) {
            return response;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getConversionsCard = async ({ tenantId, startPeriod, endPeriod }: TDashboardDefaultProps) => {
    try {
        let path = `/Dashboard/ConversionsCard?TenantId=${tenantId}&StartPeriod=${startPeriod.toISOString()}&EndPeriod=${endPeriod.toISOString()}`;
        const { data: response } = await api.get<TConversionsCard>(path);
        if (response) {
            return response;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getDailySales = async ({ tenantId, startPeriod, endPeriod, periodType }: TDailySalesProps) => {
    try {
        let path = `/Dashboard/DailySalesGraph?TenantId=${tenantId}&PeriodType=${DashboardFunctions.getDailyCommissions(periodType)}&StartPeriod=${startPeriod.toISOString()}&EndPeriod=${endPeriod.toISOString()}`;
        const { data: response } = await api.get(path);
        if (response) {
            const items = response.items.map((item: any) => {
                if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
                    item.date = addDays(1, new Date(item.date));
                }
                return item;
            }) as TDailySales[];

            return items;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        console.error({ error });
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getSalesForStates = async ({ tenantId, startPeriod, endPeriod }: TDashboardDefaultProps) => {
    try {
        let path = `/Dashboard/SalesForStates?TenantId=${tenantId}&StartPeriod=${startPeriod.toISOString()}&EndPeriod=${endPeriod.toISOString()}`;
        const { data: response } = await api.get<TSalesForGroupKey[]>(path);
        if (response) {
            return response.sort((a, b) => b.value - a.value);
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getSalesForCities = async ({ tenantId, startPeriod, endPeriod }: TDashboardDefaultProps) => {
    try {
        let path = `/Dashboard/SalesForCities?TenantId=${tenantId}&StartPeriod=${startPeriod.toISOString()}&EndPeriod=${endPeriod.toISOString()}`;
        const { data: response } = await api.get<TSalesForGroupKey[]>(path);
        if (response) {
            return response.sort((a, b) => b.value - a.value);
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getSalesPaymentMethod = async ({ tenantId, startPeriod, endPeriod }: TDashboardDefaultProps) => {
    try {
        let path = `/Dashboard/SalesPaymentMethod?TenantId=${tenantId}&StartPeriod=${startPeriod.toISOString()}&EndPeriod=${endPeriod.toISOString()}`;
        const { data: response } = await api.get<TSalesPaymentMethod[]>(path);
        if (response) {
            return response;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getBestSellingProducts = async ({ tenantId, startPeriod, endPeriod }: TDashboardDefaultProps) => {
    try {
        let path = `/Dashboard/BestSellingProducts?TenantId=${tenantId}&StartPeriod=${startPeriod.toISOString()}&EndPeriod=${endPeriod.toISOString()}`;
        const { data: response } = await api.get<TSalesGroupKey[]>(path);
        if (response) {
            return response;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getBestSellingProductsByType = async ({ tenantId, startPeriod, endPeriod }: TDashboardDefaultProps) => {
    try {
        let path = `/Dashboard/BestSellingProductsByType?TenantId=${tenantId}&StartPeriod=${startPeriod.toISOString()}&EndPeriod=${endPeriod.toISOString()}`;
        const { data: response } = await api.get<TSalesGroupKey[]>(path);
        if (response) {
            return response;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const DashboardController = {
    getComissionCard,
    getConversionsCard,
    getDailySales,
    getSalesForStates,
    getSalesForCities,
    getSalesPaymentMethod,
    getBestSellingProducts,
    getBestSellingProductsByType,
}

export default DashboardController;