import { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  Stack,
  TextField,
  Typography,
  Checkbox,
  Fade,
  FormControlLabel,
  InputAdornment,
} from '@mui/material'
import SwipeableDrawerRightDialog from '../../Dialogs/SwipeableDrawerRightDialog'
import { HtmlTooltip } from '../../Tooltip/HtmlTooltip'

import {
  TProductPixel,
  TProductPixelRegisterPage,
  TProductPixelType,
} from '../../../core/types/Product'
import { red } from '@mui/material/colors'
import SwitchRound from '../../Switch/SwitchRound'
import RowRadioButtonsGroup from '../../RadioGroup/RowRadioButtonsGroup'
import UtilFunctions from '../../../core/functions/UtilFunctions'
import { TUserType } from '../../../core/types/Auth'
import { AuthContext } from '../../../core/context/AuthContext'

export interface IProductPixelRegisterProps {
  open: boolean
  sending: boolean
  productPixel: TProductPixel
  errorPixel: string
  errorDomain: string
  errorToken: string
  pageIndex: TProductPixelRegisterPage
  setOpen: (value: boolean) => void
  setProductPixel: (value: TProductPixel) => void
  onSave: () => Promise<void>
  onVerifyDomain: () => Promise<void>
  onValidationPixelErrors: () => boolean
  onValidationDomainErrors: () => boolean
  setPageIndex: React.Dispatch<React.SetStateAction<TProductPixelRegisterPage>>
  isEditing?: boolean
}

const ProductPixelRegister = ({
  open,
  sending,
  productPixel,
  errorPixel,
  errorDomain,
  errorToken,
  pageIndex,
  isEditing,
  setOpen,
  setProductPixel,
  onSave,
  onVerifyDomain,
  onValidationPixelErrors,
  onValidationDomainErrors,
  setPageIndex,
}: IProductPixelRegisterProps) => {
  const [selectedType, setSelectedType] = useState(TProductPixelType.Facebook)

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const handleTypeChange = (type: TProductPixelType) => {
    setSelectedType(type)
    setProductPixel({
      ...productPixel,
      type: type,
      pixel: '',
      pixelToken: '',
      pixelDomain: '',
    })
  }

  useEffect(() => {
    if (!isEditing) {
      setSelectedType(TProductPixelType.Facebook)
      setProductPixel({
        ...productPixel,
        type: TProductPixelType.Facebook,
        pixel: '',
        pixelToken: '',
        pixelDomain: '',
      })
    }
  }, [open])

  useEffect(() => {
    if (open && isEditing) {
      setSelectedType(productPixel.type)
    }
  }, [open, isEditing, productPixel.type])

  const lastPageIndex =
    productPixel.type === TProductPixelType.Facebook
      ? TProductPixelRegisterPage.Domain
      : TProductPixelRegisterPage.Pixel

  const pixelName =
    productPixel.type === TProductPixelType.Facebook ? 'Facebook' : 'Google Ads'

  const labelPixel = `ID do Pixel do ${pixelName}`

  const labelToken =
    productPixel.type === TProductPixelType.Facebook
      ? 'Facebook Pixel Token'
      : 'Label de conversão do Google Ads'

  const placeholderPixel =
    productPixel.type === TProductPixelType.Facebook
      ? 'Ex.: 0123456789'
      : 'Ex.: AW-0000000000'

  const placeholderToken =
    productPixel.type === TProductPixelType.Facebook
      ? 'Inserir token de acesso'
      : 'Cole o label de conversão aqui...'

  const onSaveOrNext = () => {
    if (pageIndex === lastPageIndex) {
      onSave()
      return
    }

    switch (pageIndex) {
      case TProductPixelRegisterPage.Pixel:
        if (onValidationPixelErrors()) {
          return
        }
        break
      case TProductPixelRegisterPage.Domain:
        if (onValidationDomainErrors()) {
          return
        }
        break
    }

    setPageIndex((value) => value + 1)
  }

  const onBack = () => {
    if (pageIndex === TProductPixelRegisterPage.Pixel) {
      setOpen(false)
      return
    }
    setPageIndex((value) => value - 1)
  }

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={() => setOpen(false)}
      setOpen={(value) => setOpen(value)}
      title="Pixel de conversão"
      buttons={
        isAdmin
          ? []
          : [
              { title: 'Voltar', onClick: onBack, type: 'negative' },
              {
                title: pageIndex === lastPageIndex ? 'Salvar' : 'Próximo',
                onClick: onSaveOrNext,
                type: 'positive',
              },
            ]
      }
      buttonsDisabled={sending}
    >
      <Box>
        {pageIndex === TProductPixelRegisterPage.Pixel && (
          <Stack direction="column" spacing={3} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                sx={{
                  color: '#38393B',
                  borderColor:
                    selectedType === TProductPixelType.Facebook
                      ? '#637EEF'
                      : '#DCDDDE',
                  borderRadius: '8px',
                  gap: '8px',
                  textTransform: 'none',
                }}
                startIcon={
                  <img
                    src="/assets/icons/social/logoFace.svg"
                    alt="google-icon-pixel"
                  />
                }
                onClick={() => handleTypeChange(TProductPixelType.Facebook)}
                disabled={
                  (isEditing &&
                    productPixel.type !== TProductPixelType.Facebook) ||
                  isAdmin
                }
              >
                Facebook
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: '#38393B',
                  borderColor:
                    selectedType === TProductPixelType.GoogleAds
                      ? '#637EEF'
                      : '#DCDDDE',
                  borderRadius: '8px',
                  gap: '8px',
                  textTransform: 'none',
                }}
                startIcon={
                  <img
                    src="/assets/icons/social/logoGoogle.svg"
                    alt="google-icon-pixel"
                  />
                }
                onClick={() => handleTypeChange(TProductPixelType.GoogleAds)}
                disabled={
                  (isEditing &&
                    productPixel.type !== TProductPixelType.GoogleAds) ||
                  isAdmin
                }
              >
                Google
              </Button>
            </Stack>

            {TProductPixelType.Facebook && (
              <Box>
                <TextField
                  fullWidth
                  disabled={isAdmin}
                  required
                  size="small"
                  type="text"
                  label={labelPixel}
                  variant="outlined"
                  inputProps={{ maxLength: 50, inputMode: 'numeric' }}
                  InputLabelProps={{ shrink: true }}
                  value={productPixel.pixel}
                  onChange={(e) =>
                    setProductPixel({
                      ...productPixel,
                      pixel: e.target.value,
                    })
                  }
                  placeholder={placeholderPixel}
                  error={errorPixel !== ''}
                />
                {errorPixel !== '' && (
                  <Typography variant="caption" color={red[700]}>
                    {errorPixel}
                  </Typography>
                )}

                {productPixel.type === TProductPixelType.GoogleAds && (
                  <Box>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignContent="center"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    ></Stack>
                    <TextField
                      fullWidth
                      disabled={isAdmin}
                      multiline
                      size="small"
                      type="text"
                      variant="outlined"
                      label={labelToken}
                      sx={{ mt: 1 }}
                      InputLabelProps={{ shrink: true }}
                      value={productPixel.pixelToken}
                      onChange={(e) =>
                        setProductPixel({
                          ...productPixel,
                          pixelToken: e.target.value,
                        })
                      }
                      placeholder={placeholderToken}
                      error={errorToken !== ''}
                    />
                    {errorToken !== '' && (
                      <Typography variant="caption" color={red[700]}>
                        {errorToken}
                      </Typography>
                    )}
                  </Box>
                )}

                <Stack direction="column" spacing={1} alignItems="flex-start">
                  <Stack
                    direction="row"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Checkbox
                      checked={productPixel.selectivePurchase}
                      disabled={isAdmin}
                      sx={{
                        pl: 0,
                        marginLeft: 0,
                        color: '#DDDDDD',
                      }}
                      onChange={(e) =>
                        setProductPixel({
                          ...productPixel,
                          selectivePurchase: e.target.checked,
                        })
                      }
                    />
                    <Typography variant="body1" color={'#707275'}>
                      Personalizar disparos
                    </Typography>
                    <HtmlTooltip
                      title={
                        <Typography>
                          Selecione como você quer que os eventos do{' '}
                          {productPixel.type === 1 ? 'Facebook' : 'Google'}{' '}
                          sejam disparados
                        </Typography>
                      }
                    >
                      <img
                        src="/assets/icons/infos/info.svg"
                        alt="tooltip-info-pixel"
                        style={{ marginLeft: 5 }}
                      />
                    </HtmlTooltip>
                  </Stack>

                  <Fade
                    in={productPixel.selectivePurchase}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Stack direction="column">
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{ width: '100%' }}
                      >
                        <FormControlLabel
                          control={
                            <SwitchRound
                              disabled={isAdmin}
                              sx={{ m: 1, pl: 0, ml: 0 }}
                              checked={productPixel.selectiveImmediatePurchase}
                              onChange={(e) =>
                                setProductPixel({
                                  ...productPixel,
                                  selectiveImmediatePurchase: e.target.checked,
                                })
                              }
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={{ color: '#707275' }}
                            >
                              Vendas imediatas
                            </Typography>
                          }
                        />
                        <HtmlTooltip
                          title={
                            <Typography>
                              {productPixel.type === 1
                                ? 'Fazemos um disparo de compra toda vez que um pagamento é aprovado no cartão de crédito.'
                                : 'O evento vai acontecer sempre que um usuário acessar a página de checkout.'}
                            </Typography>
                          }
                        >
                          <img
                            src="/assets/icons/infos/info.svg"
                            alt="tooltip-info-pixel"
                            style={{ marginLeft: 5 }}
                          />
                        </HtmlTooltip>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{ width: '100%' }}
                      >
                        <FormControlLabel
                          control={
                            <SwitchRound
                              disabled={isAdmin}
                              sx={{ m: 1, pl: 0, ml: 0 }}
                              checked={
                                productPixel.selectiveNonImmediatePurchase
                              }
                              onChange={(e) =>
                                setProductPixel({
                                  ...productPixel,
                                  selectiveNonImmediatePurchase:
                                    e.target.checked,
                                })
                              }
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={{ color: '#707275' }}
                            >
                              Vendas não imediatas
                            </Typography>
                          }
                        />
                        <HtmlTooltip
                          title={
                            <Typography>
                              {productPixel.type === 1
                                ? 'Fazemos um disparo de compra toda vez que um pix e boleto são gerados. Mesmo que eles ainda não tenham sido pagos.'
                                : 'O evento vai acontecer sempre que um usuário finalizar sua compra com cartão de crédito.'}
                            </Typography>
                          }
                        >
                          <img
                            src="/assets/icons/infos/info.svg"
                            alt="tooltip-info-pixel"
                            style={{ marginLeft: 5 }}
                          />
                        </HtmlTooltip>
                      </Stack>

                      <Stack
                        direction="row"
                        alignContent="center"
                        alignItems="center"
                      >
                        <Checkbox
                          disabled={isAdmin}
                          checked={productPixel.visitSalesPage}
                          sx={{ pl: 0, marginLeft: 0, color: '#DDDDDD' }}
                          onChange={(e) =>
                            setProductPixel({
                              ...productPixel,
                              visitSalesPage: e.target.checked,
                            })
                          }
                        />
                        <Typography variant="body1" color={'#707275'}>
                          Visitas no checkout
                        </Typography>
                        <HtmlTooltip
                          title={
                            <Typography>
                              {productPixel.type === 1
                                ? 'Ativamos o pixel de InitiateCheckout toda vez que um usuário acessa a página de checkout'
                                : 'O evento vai acontecer sempre que um usuário finalizar sua compra pix e boleto, mesmo que não tenha pago ainda.'}
                            </Typography>
                          }
                        >
                          <img
                            src="/assets/icons/infos/info.svg"
                            alt="tooltip-info-pixel"
                            style={{ marginLeft: 5 }}
                          />
                        </HtmlTooltip>
                      </Stack>
                    </Stack>
                  </Fade>
                </Stack>
              </Box>
            )}
          </Stack>
        )}

        {pageIndex === TProductPixelRegisterPage.Domain && (
          <Stack direction="column" spacing={3} sx={{ px: 2 }}>
            {productPixel.type === TProductPixelType.Facebook && (
              <Box>
                <Typography
                  gutterBottom
                  variant="body1"
                  fontWeight={600}
                  fontSize="14px"
                  lineHeight="18px"
                  fontFamily="Montserrat"
                >
                  Deseja que os eventos sejam enviados a partir do seu domínio,
                  e não da celetus.com?
                </Typography>

                <RowRadioButtonsGroup
                  value={productPixel.enabledDomain}
                  onChange={(value) => {
                    if (typeof value === 'string') {
                      value = UtilFunctions.booleanify(value)
                    }
                    setProductPixel({ ...productPixel, enabledDomain: value })
                  }}
                  items={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                  sx={{ color: '#707275' }}
                />
              </Box>
            )}
            <Fade in={productPixel.enabledDomain} mountOnEnter unmountOnExit>
              <Stack direction="column" spacing={3}>
                {productPixel.type === TProductPixelType.Facebook && (
                  <Box>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Domínio do gerenciador de negócios"
                      InputLabelProps={{ shrink: true }}
                      value={productPixel.pixelDomain}
                      onChange={(e) =>
                        setProductPixel({
                          ...productPixel,
                          pixelDomain: e.target.value,
                        })
                      }
                      placeholder="Ex.: meudominio.com.br"
                      error={errorDomain !== ''}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <HtmlTooltip
                              title={
                                <Typography>
                                  {`O DNS do domínio informado deve ser configurado com o CNAME apontando para ${process.env.REACT_APP_PIXEL_HOST}.\n
                                        Não concluir essa configuração pode resultar em perda de eventos.`}
                                </Typography>
                              }
                            >
                              <img
                                src="/assets/icons/infos/infoBlack.svg"
                                alt="tooltip-info-pixel"
                                style={{ marginLeft: 5 }}
                              />
                            </HtmlTooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errorDomain !== '' && (
                      <Typography variant="caption" color={red[700]}>
                        {errorDomain}
                      </Typography>
                    )}
                  </Box>
                )}
                {productPixel.type === TProductPixelType.Facebook && (
                  <Stack direction="column" spacing={2}>
                    <Typography>
                      Não sabe como fazer? Siga o passo a passo
                    </Typography>

                    <Stack direction="row" spacing={1}>
                      <img
                        src="/assets/icons/arrow/curveArrow.svg"
                        alt="curve-arrow-domain"
                        style={{ width: '20px', height: '20px' }}
                      />

                      <Typography
                        fontFamily="Montserrat"
                        fontSize="14px"
                        fontWeight={500}
                        lineHeight="18px"
                        color="#707275"
                      >
                        No seu provedor de domínio vá em configurações de DNS
                      </Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                      <img
                        src="/assets/icons/arrow/curveArrow.svg"
                        alt="curve-arrow-domain"
                        style={{ width: '20px', height: '20px' }}
                      />

                      <Typography
                        fontFamily="Montserrat"
                        fontSize="14px"
                        fontWeight={500}
                        lineHeight="18px"
                        color="#707275"
                      >
                        Configure o CNAME pixel, apontando para
                        pixel.celetus.com
                      </Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                      <img
                        src="/assets/icons/arrow/curveArrow.svg"
                        alt="curve-arrow-domain"
                        style={{ width: '20px', height: '20px' }}
                      />

                      <Typography
                        fontFamily="Montserrat"
                        fontSize="14px"
                        fontWeight={500}
                        lineHeight="18px"
                        color="#707275"
                      >
                        Faça a verificação do CNAME para validar a configuração
                      </Typography>
                    </Stack>

                    <Stack
                      direction="row"
                      spacing={2}
                      alignContent="center"
                      alignItems="center"
                      sx={{ mt: 2 }}
                    >
                      <Button
                        variant="outlined"
                        disabled={sending}
                        sx={{ textTransform: 'none' }}
                        onClick={onVerifyDomain}
                      >
                        <Typography
                          variant="button"
                          sx={{
                            fontFamily: 'Montserrat',
                            fontWeight: 700,
                            fontSize: '14px',
                            lineHeight: '24px',
                          }}
                        >
                          Verificar CNAME
                        </Typography>
                      </Button>
                      <Chip
                        size="small"
                        variant="outlined"
                        color={
                          productPixel.cnameVerified ? 'success' : 'warning'
                        }
                        label={
                          productPixel.cnameVerified
                            ? 'CNAME verificado'
                            : 'CNAME não validado'
                        }
                        sx={{
                          borderColor: productPixel.cnameVerified
                            ? '#1CBD3F'
                            : '#FBF3E2',
                          color: productPixel.cnameVerified
                            ? '#009D23'
                            : '#887100',
                        }}
                      />
                    </Stack>
                  </Stack>
                )}
                <Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignContent="center"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  ></Stack>
                  <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    label={labelToken}
                    size="small"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    value={productPixel.pixelToken}
                    onChange={(e) =>
                      setProductPixel({
                        ...productPixel,
                        pixelToken: e.target.value,
                      })
                    }
                    placeholder={placeholderToken}
                    error={errorToken !== ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip
                            title={
                              <Typography>
                                Para disparos via API informe o token do pixel
                                da API de conversão do Facebook.
                              </Typography>
                            }
                          >
                            <img
                              src="/assets/icons/infos/infoBlack.svg"
                              alt="tooltip-info-pixel"
                              style={{ marginLeft: 5 }}
                            />
                          </HtmlTooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errorToken !== '' && (
                    <Typography variant="caption" color={red[700]}>
                      {errorToken}
                    </Typography>
                  )}
                </Box>
              </Stack>
            </Fade>
          </Stack>
        )}
      </Box>
    </SwipeableDrawerRightDialog>
  )
}

export default ProductPixelRegister
