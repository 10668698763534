import { Box, IconButton, Stack, Typography } from '@mui/material'
import AccordionDefault from '../../../../../components/Accordions/AccordionDefault'
import { IProductPriceItemProps } from './ProductPriceItem'
import AccordionSummaryDefault from '../../../../../components/Accordions/AccordionSummaryDefault'
import AccordionDetailsDefault from '../../../../../components/Accordions/AccordionDetailsDefault'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import ProductPriceItemStatus from './ProductPriceItemStatus'
import BlackButton from '../../../../../components/Buttons/BlackButton'
import TextBlackButton from '../../../../../components/Buttons/BlackButton/TextBlackButton'
import Convertions from '../../../../../core/functions/convertions'
import { useContext } from 'react'
import { AuthContext } from '../../../../../core/context/AuthContext'
import { TUserType } from '../../../../../core/types/Auth'

const ProductPriceItemMobile = ({
  price,
  index,
  isTenantBank,
  fields,
  watch,
  onCopyURL,
  onOpenPrice,
  onOpenDisable,
  onDelete,
}: IProductPriceItemProps) => {
  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  return (
    <AccordionDefault>
      <AccordionSummaryDefault>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="column" spacing={1} width="100%">
            <Typography
              fontWeight={500}
              fontSize="14px"
              lineHeight="18px"
              color="#38393B"
              maxWidth="80%"
            >
              {price.offerName}
            </Typography>
            <Typography
              fontWeight={600}
              fontSize="12px"
              lineHeight="16px"
              color="#637EEF"
            >
              {NumberFunctions.formatMoneyDefault(price.value)}
            </Typography>
          </Stack>
          <ProductPriceItemStatus price={price} />
        </Stack>
      </AccordionSummaryDefault>
      <AccordionDetailsDefault>
        <Stack direction="column" spacing={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontWeight={500}
              fontSize="12px"
              lineHeight="16px"
              color="#38393B"
            >
              Pagamentos
            </Typography>
            <Typography
              fontWeight={500}
              fontSize="12px"
              lineHeight="16px"
              color="#38393B"
            >
              {watch(`prices.${index}.paymentMethods`)
                .map((pay) => Convertions.methodTypeToString(pay.methodType))
                .join(', ')}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontWeight={500}
              fontSize="12px"
              lineHeight="16px"
              color="#38393B"
            >
              URL
            </Typography>
            {isTenantBank && (
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography
                  fontWeight={500}
                  fontSize="12px"
                  lineHeight="16px"
                  color="#38393B"
                >
                  {price.codeId}
                </Typography>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    onCopyURL(false, index)
                  }}
                >
                  <img src="/assets/icons/copy/copy.svg" alt="copy" />
                </IconButton>
              </Stack>
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontWeight={500}
              fontSize="12px"
              lineHeight="16px"
              color="#38393B"
            >
              Ocultar Endereço
            </Typography>
            <Typography
              fontWeight={500}
              fontSize="12px"
              lineHeight="16px"
              color="#38393B"
            >
              {price.hideCheckoutAddress ? 'Sim' : 'Não'}
            </Typography>
          </Stack>
          <Box>
            <BlackButton
              startIcon={
                <img
                  src={
                    isAdmin
                      ? '/assets/icons/eyes/eyeButtonWhite.svg'
                      : '/assets/icons/actions/editWhite.svg'
                  }
                  alt="edit-w"
                />
              }
              onClick={(e) => {
                e.stopPropagation()
                onOpenPrice(price, index)
              }}
            >
              <TextBlackButton>{isAdmin ? 'Ver' : 'Editar'}</TextBlackButton>
            </BlackButton>
          </Box>
        </Stack>
      </AccordionDetailsDefault>
    </AccordionDefault>
  )
}

export default ProductPriceItemMobile
