import { Stack, Typography } from '@mui/material'
import { IDataTableColumn } from '../../../../components/Tables/DataTable'
import {
  dayAndMonthString,
  timeString,
} from '../../../../core/functions/dateTime'
import DataTableCellTypography from '../../../../components/Typographys/DataTableCellTypography'
import Convertions from '../../../../core/functions/convertions'
import { HtmlTooltip } from '../../../../components/Tooltip/HtmlTooltip'
import DataTableSubcellTypography from '../../../../components/Typographys/DataTableSubcellTypography'
import OrderFunctions from '../../../../core/functions/OrderFunctions'
import { TCheckoutOrder } from '../../../../core/types/CheckoutOrder'
import { TLostSale } from '../../../../core/types/LostSale'

export const checkoutOrderColumnsDefault: readonly IDataTableColumn<
  TCheckoutOrder | TLostSale
>[] = [
  {
    id: 'productName',
    label: 'Produto',
    render(id, value, row) {
      return (
        <Stack direction="row">
          <DataTableCellTypography>{value}</DataTableCellTypography>
        </Stack>
      )
    },
  },
  {
    id: 'productType',
    label: 'Tipo de Produto',
    render(id, value, row) {
      return (
        <Stack direction="row" spacing={1}>
          <DataTableCellTypography>
            {Convertions.valueProductTypeToString(
              (row as TCheckoutOrder).valueProductType
            )}
          </DataTableCellTypography>
          {OrderFunctions.isSubscription(row as TCheckoutOrder) && (
            <HtmlTooltip title={<Typography>Recorrência</Typography>}>
              <img
                src="/assets/icons/arrow/roundArrow.svg"
                alt="icon-sell-type"
              />
            </HtmlTooltip>
          )}
        </Stack>
      )
    },
  },
  {
    id: 'sellerName',
    label: 'Vendedor',
    render(id, value, row) {
      return (
        <Stack direction="column">
          <DataTableCellTypography>{value}</DataTableCellTypography>
          <DataTableSubcellTypography>
            {Convertions.valueTypeToString(row.valueType)}
          </DataTableSubcellTypography>
        </Stack>
      )
    },
  },
  {
    id: 'name',
    label: 'Cliente',
    render(id, value, row) {
      return (
        <Stack direction="column" width="120px">
          <DataTableCellTypography>{value}</DataTableCellTypography>
          <DataTableSubcellTypography noWrap={true}>
            {row.email}
          </DataTableSubcellTypography>
        </Stack>
      )
    },
  },
  {
    id: 'utmCampaign',
    label: 'UTM Campaign',
  },
]

export const checkoutOrderCreatedDateColumn: IDataTableColumn<
  TCheckoutOrder | TLostSale
> = {
  id: 'createdDate',
  label: 'Criado em',
  render(id, value, row) {
    return (
      <Stack direction="column">
        <Typography
          fontFamily="Montserrat"
          fontWeight={400}
          fontSize="14px"
          lineHeight="18px"
        >
          {dayAndMonthString(value)}
        </Typography>
        <Typography
          fontFamily="Montserrat"
          fontWeight={400}
          fontSize="12px"
          lineHeight="16px"
          color="#707275"
        >
          {timeString(value)}
        </Typography>
      </Stack>
    )
  },
}
