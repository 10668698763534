import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  CssBaseline,
  Divider,
  Link,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material'
import theme from '../../../layouts/Theme'
import { TopLogo } from '../../../components/Logo/TopLogo'
import SignInIcon from '@mui/icons-material/Login'
import { useNavigate, useParams } from 'react-router-dom'
import { useProductAffiliation } from '../../../core/hooks/useProductAffiliation'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import { useState } from 'react'
import { maskPhoneNumber } from '../../../masks/masks'
import Validations from '../../../core/functions/validations'

const ProductAffiliateRecruiting = () => {
  const { codeId } = useParams<{ codeId: string }>()

  const [showPhone, setShowPhone] = useState(false)

  const { data, loading, error } = useProductAffiliation({ code: codeId })

  const navigate = useNavigate()

  const handleChangeViewPhone = (
    e:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.preventDefault()
    setShowPhone(true)
  }

  const handleChangeAffiliated = () => {
    navigate(`/product/affiliation/${data?.id}`)
  }

  const getDescription = () => {
    const text = data?.description ?? ''
    const lines = text.split('\n')
    return lines.map((description) => {
      if (description.includes('http')) {
        const chars = description.split(' ')
        const link = chars.find((c) => Validations.URL(c))
        if (link) {
          return (
            <Typography>
              <Link href={link} underline="hover">
                {description}
              </Link>
            </Typography>
          )
        }

        const linkWhats = chars.find((c) => c.startsWith('(https://t.me/'))
        if (linkWhats) {
          const url = linkWhats.replaceAll('(', '').replaceAll(')', '')
          if (Validations.URL(url)) {
            const newDescription = chars
              .map((c) =>
                c.startsWith('(https://t.me/') ? '(Link do WhatsApp)' : c
              )
              .join(' ')
            return (
              <Typography>
                <Link href={url} underline="hover">
                  {newDescription}
                </Link>
              </Typography>
            )
          }
        }
      }

      return <Typography>{description}</Typography>
    })
  }

  if (error !== '') {
    return (
      <Container>
        <Stack
          direction="row"
          spacing={2}
          alignItems="cemter"
          justifyContent="center"
          sx={{ p: '16px' }}
        >
          <Stack spacing={3}>
            <TopLogo margimBottom={0} />
            <Box>
              <Typography fontWeight={700} fontSize="24px" lineHeight="32px">
                Ops, recrutamento não encontrado!
              </Typography>
              <Typography fontSize="18px" lineHeight="24px">
                Entre em contato com o administrador do link.
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Container>
    )
  }

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Container maxWidth="xs" sx={{ margin: 0, padding: 0 }}>
              <TopLogo margimBottom={0} />
            </Container>
            {loading && <CircularProgress color="inherit" />}
            {data && (
              <Box>
                <Typography
                  gutterBottom
                  textAlign="center"
                  fontSize={26}
                  sx={{ letterSpacing: 1 }}
                >
                  Afilie-se e lucre!
                </Typography>
                <Typography variant="body1" textAlign="center">
                  Você foi convidado por <strong>{data?.author}</strong> para se
                  tornar afiliado ao produto{' '}
                  <strong>{data?.productName}</strong>.
                </Typography>
                <Typography variant="body1" textAlign="center">
                  Após se afiliar, você receberá um link de afiliado e poderá
                  ganhar dinheiro vendendo este produto pela internet!
                </Typography>
                <Card
                  elevation={0}
                  sx={{
                    width: '100%',
                    marginTop: 4,
                    border: 1,
                    borderColor: 'silver',
                  }}
                >
                  <CardHeader
                    title={data?.productName}
                    sx={{
                      borderBottom: 1,
                      borderColor: 'silver',
                      letterSpacing: 1,
                    }}
                  />
                  <CardContent>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      sx={{ mb: 2 }}
                    >
                      {data?.imageProductUrl && (
                        <>
                          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <img
                              src={data.imageProductUrl}
                              alt="img-top"
                              style={{
                                borderRadius: 4,
                                objectFit: 'cover',
                                objectPosition: 'center',
                                width: '200px',
                                height: '200px',
                              }}
                            />
                          </Box>
                          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <img
                              src={data.imageProductUrl}
                              alt="img-top"
                              style={{
                                borderRadius: 4,
                                objectFit: 'cover',
                                objectPosition: 'center',
                                width: '100%',
                                height: '100%',
                              }}
                            />
                          </Box>
                        </>
                      )}
                      <Stack direction="column" spacing={3} width="100%">
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={2}
                          alignItems="start"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Stack>
                            <Typography
                              sx={{ fontSize: 28, display: 'inline-block' }}
                            >
                              Receba até{' '}
                              <Typography
                                fontWeight={500}
                                color={theme.palette.primary.main}
                                sx={{ fontSize: 28, display: 'inline-block' }}
                              >
                                {NumberFunctions.formatMoneyDefault(
                                  ((data?.commissionPercentage ?? 0) / 100) *
                                    (data?.maxValue ?? 0)
                                )}
                              </Typography>
                            </Typography>
                            <Typography variant="caption" color="grey">
                              por cada venda
                            </Typography>
                          </Stack>
                          <Button
                            variant="contained"
                            size="medium"
                            startIcon={<SignInIcon />}
                            onClick={handleChangeAffiliated}
                          >
                            Entre para se afiliar
                          </Button>
                        </Stack>

                        <Stack>
                          <Typography variant="body1">
                            O preço máximo desse produto é de{' '}
                            <strong>
                              {NumberFunctions.formatMoneyDefault(
                                data?.maxValue
                              )}
                            </strong>
                            .
                          </Typography>
                          <Typography variant="caption" color="grey">
                            Produto comissionado pelo{' '}
                            <strong>último clique</strong>, cookies com duração{' '}
                            <strong>infinita</strong>.
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Divider sx={{ borderColor: 'silver' }} />

                    <Stack direction="column" spacing={2} sx={{ my: 2 }}>
                      {getDescription()}
                    </Stack>

                    <Divider sx={{ borderColor: 'silver' }} />

                    <Stack direction="column" spacing={3} sx={{ mt: 2 }}>
                      <Typography variant="body1" fontWeight={500}>
                        Descrição do produto
                      </Typography>
                      <Typography variant="body1">
                        {data?.productDescription}
                      </Typography>
                    </Stack>

                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      sx={{ mt: 3 }}
                    >
                      <Box width="100%">
                        <Typography variant="body2" gutterBottom>
                          <strong>Idioma: </strong>Português (Brasil)
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <strong>Telefone de suporte: </strong>
                          {!showPhone
                            ? (data?.supportPhone ?? '') !== '' && (
                                <Link
                                  href="#"
                                  underline="hover"
                                  onClick={handleChangeViewPhone}
                                >
                                  Ver telefone
                                </Link>
                              )
                            : maskPhoneNumber(data?.supportPhone ?? '')}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <strong>Página de Vendas: </strong>
                          {data?.productSaleLink}
                        </Typography>
                      </Box>
                      <Box width="100%">
                        <Typography variant="body2" gutterBottom>
                          <strong>Forma de Acesso: </strong>Membership (Site de
                          Membros)
                        </Typography>
                        {/* <Typography variant="body2" gutterBottom>
                                            <strong>Formato: </strong>Cursos Online, Área de Membros, Serviços de Assinatura
                                        </Typography> */}
                        <Typography variant="body2" gutterBottom>
                          <strong>Assunto: </strong>
                          {data?.categoryDescription}
                        </Typography>
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
              </Box>
            )}
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  )
}

export default ProductAffiliateRecruiting
