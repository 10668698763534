import { Box } from '@mui/material'

interface IAccordionSummaryDefaultProps {
  children: React.ReactNode
}

const AccordionSummaryDefault = ({
  children,
}: IAccordionSummaryDefaultProps) => {
  return <Box sx={{ p: 2, width: '100%' }}>{children}</Box>
}

export default AccordionSummaryDefault
