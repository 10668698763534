import axios from "axios";
import { ICEP } from "../types/CEP";
import { numberOnly } from "../../masks/masks";

const search = async (zipCode: string | undefined): Promise<ICEP | null> => {
    if (!zipCode) {
        return null;
    }

    const code = numberOnly(zipCode);
    if (code.length < 8) {
        return null;
    }

    const response = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`);
    return response.data as ICEP;
}

const CepController = {
    search,
}

export default CepController;