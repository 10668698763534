export const testWebhookJSON = {
  id: '00000000-0000-0000-0000-000000000000',
  eventName: 'Nome do Webhook',
  eventType: 'Pix',
  paymentMethod: 'Pix',
  eventTypeIdentifier: 'PixGenerated.WaitingPayment',
  orderId: '91d97938-9c75-4a43-8471-1fa754aa9c03',
  orderCode: 'V93ET2DCKV',
  status: 'WaitingPayment',
  createdDate: '2024-07-19T17:06:59.25843+00:00',
  approvedDate: null,
  refundedDate: null,
  customer: {
    id: '2b719ad3-3a3d-44ca-bc7e-4118a7772a0c',
    name: 'Nome do Cliente',
    phone: '99999999999',
    email: 'nome@cliente.com',
    document: '00000000000',
    address: {
      id: '347145a7-f9cb-4be8-b748-10746c2e7a60',
      street: 'Rua',
      number: '0',
      complement: '',
      zipCode: '00000000',
      neighborhood: 'Bairro',
      city: 'Cidade',
      state: 'SP',
      country: 'BR',
      reference: '',
    },
  },
  items: [
    {
      id: '1de472a9-c127-4577-93e1-c8ddb94ee468',
      code: '4VSISAIQ',
      name: 'Produto 1',
      amount: 5.05,
      quantity: 1,
      itemType: 'Principal',
    },
    {
      id: '342d49dd-ae02-4c1f-bfb0-756f485a8b7d',
      code: '74ZKA8WK',
      name: 'Produto 2',
      amount: 55.0,
      quantity: 1,
      itemType: 'Orderbump',
    },
    {
      id: 'c01c5257-a3be-4879-8e95-c7973890cd83',
      code: 'DK9MRK3H',
      name: 'Produto 3',
      amount: 5.01,
      quantity: 1,
      itemType: 'Orderbump',
    },
  ],
  charge: {
    id: '91d97938-9c75-4a43-8471-1fa754aa9c03',
    code: '9a3b422f-52b2-4f25-aede-193c29a7721a',
    status: 'pending',
    amount: '65.06',
    gatewayResponse: 'null',
  },
  lostSaleData: {
    LostSaleId: null,
    Product: null,
    Producer: null,
    CoProducers: null,
    Affialted: null,
    CreatedDate: null,
    CheckoutModelId: null,
    CheckoutModelName: '',
    TenantId: '00000000-0000-0000-0000-000000000000',
    TenantName: '',
  },
  trackingParameters: {
    sck: '',
    src: '',
    utmCampaign: '',
    utmContent: '',
    utmMedium: '',
    utmSource: '',
    utmTerm: '',
  },
  commission: {
    totalPrice: 65.06,
    gatewayFee: 7.68,
    userCommission: 57.38,
    currency: 'BRL',
  },
}
