import * as React from 'react'
import { useState } from 'react'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { Box, InputAdornment, Stack, Tab, Tabs, TextField } from '@mui/material'
import { AuthContext } from '../../../../core/context/AuthContext'
import { decodeToken } from 'react-jwt'
import { TAuthTenant } from '../../../../core/types/AuthTenant'
import TitlePageTypography from '../../../../components/Typographys/TitlePageTypography'
import DataTable, {
  IDataTableColumn,
} from '../../../../components/Tables/DataTable'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import { TSalesAnalysisByProducer } from '../../../../core/types/Tenant'
import { useSalesAnalysisByProducer } from '../../../../core/hooks/useSalesAnalysisByProducer'
import DataTableCellTypography from '../../../../components/Typographys/DataTableCellTypography'
import DataTableSubcellTypography from '../../../../components/Typographys/DataTableSubcellTypography'
import {
  ExportFileTypeEnum,
  ReportDownloadController,
} from '../../../../core/controllers/ReportDownloadController'
import { useDownloadFile } from '../../../../core/hooks/useDownloadFile'
import { ExportToFile } from '../../../Checkouts/ExportToFile'
import NumberFunctions from '../../../../core/functions/NumberFunctions'
import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'
import { a11yProps } from '../../../../components/Tabs'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }
)

export default function SalesAnalysis() {
  const [tabIndex, setTabIndex] = React.useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [type, setType] = useState('allOccurences')

  const [sending, setSending] = useState(false)
  const [errorSending, setErrorSending] = useState('')

  const { loading, data, error, execute, clear } = useSalesAnalysisByProducer({
    page,
    rows: rowsPerPage,
    search,
  })

  const columns: readonly IDataTableColumn<TSalesAnalysisByProducer>[] = [
    {
      id: 'name',
      label: 'Nome do produtor',
      minWidth: 100,
      render(id, value, row) {
        return (
          <Stack direction="column">
            <DataTableCellTypography>{value}</DataTableCellTypography>
            <DataTableSubcellTypography>{row.email}</DataTableSubcellTypography>
          </Stack>
        )
      },
    },

    {
      id: 'allTransactionsLast30Days',
      label: 'Total de transações nos últimos 30 dias',
      minWidth: 100,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
    {
      id: 'allTransactionsLastGeneral',
      label: 'Total de transações gerais',
      minWidth: 100,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },

    {
      id: 'allCommissionsReceived',
      label: 'Comissões totais recebidas',
      minWidth: 100,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
    {
      id: 'allCommissionsReceivedLast30Days',
      label: 'Comissões totais últimos 30 dias',
      minWidth: 80,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },

    {
      id: 'allWithdraws',
      label: 'Saques totais feitos',
      minWidth: 100,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
    {
      id: 'allAccountBalance',
      label: 'Saldo total em conta',
      minWidth: 100,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
    {
      id: 'allBalance',
      label: 'Balanço (comissões totais)',
      minWidth: 100,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },

    {
      id: 'celetusGeneralIncame',
      label: 'Receita geral Celetus',
      minWidth: 100,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
    {
      id: 'celetusLast30DaysIncame',
      label: 'Receita últimos 30 dias Celetus',
      minWidth: 100,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
    {
      id: 'allGatewayFees',
      label: 'Taxas gateway totais',
      minWidth: 100,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
    {
      id: 'allGatewayFeesLast30Days',
      label: 'Taxas gateway últimos 30 dias',
      minWidth: 100,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
    {
      id: 'allProfit',
      label: 'Lucro total',
      minWidth: 100,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
    {
      id: 'allProfitLast30Days',
      label: 'Lucro últimos 30 dias',
      minWidth: 100,
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
  ]

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }
  }, [data, rowsPerPage])

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        setType('allSalesAnalysis')
        break
    }
    setTabIndex(newValue)
  }

  const handleDownloadFile = (typeFile: ExportFileTypeEnum) => {
    return ReportDownloadController.getSalesAnalysis({
      fileType: typeFile,
      search,
      type: '',
    })
  }

  const handlerDownloadingError = (e: string) => setErrorSending(e)
  const getFileName = (type: ExportFileTypeEnum) =>
    `report_${new Date().toISOString().replace(':', '_').replace('.', '_')}.${
      type === ExportFileTypeEnum.XLS ? 'xlsx' : 'csv'
    }`

  const { download, downloading } = useDownloadFile({
    apiDefinition: handleDownloadFile,
    onError: handlerDownloadingError,
    getFileName,
  })

  const downloadCallback = React.useCallback(
    (type: ExportFileTypeEnum) => download(type),
    [download]
  )

  const handleConvertToXLS = async () => {
    downloadCallback(ExportFileTypeEnum.XLS)
  }

  const handleConvertToCSV = async () => {
    downloadCallback(ExportFileTypeEnum.CSV)
  }

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        alignContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Stack flexGrow="1">
          <TitlePageTypography>Análise por produtor</TitlePageTypography>
        </Stack>

        <Stack>
          <TextField
            size="small"
            onChange={(e) => setSearch(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                execute()
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img alt="Pesquisar" src="/assets/icons/search/search.svg" />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: {
                ml: 4.5,
                color: 'black',
              },
              shrink: search !== '',
              required: false,
            }}
            sx={(theme) => ({
              backgroundColor: 'white',
              '& .Mui-focused .MuiInputAdornment-root': {
                color: theme.palette.primary.main,
              },
              '& .Mui-error .MuiInputAdornment-root': {
                color: theme.palette.error.main,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                px: 5.5,
              },
            })}
            label="Pesquisar"
          />
        </Stack>
      </Stack>

      <Stack
        direction="row"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        alignItems="center"
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          sx={{ flexGrow: 1 }}
        >
          <Tab label="Análise de vendas por produtor" {...a11yProps(0)} />
        </Tabs>
        <Stack direction="row">
          <ExportToFile
            convertToCSV={handleConvertToCSV}
            convertToXLS={handleConvertToXLS}
          />
        </Stack>
      </Stack>

      <DataTable
        columns={columns}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>
      <LoadingBackdrop open={loading || sending} />
    </Box>
  )
}
