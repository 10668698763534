import React from "react";
import { IErrorResponse } from "../types/ErrorResponse";
import { TTenantSettings } from "../types/Tenant";
import { TenantController } from "../controllers/TenantController";

export interface ITenantSettingsByTenantIdProp {
    id: string;
    requestRecipient?: boolean;
}

export const useTenantSettingsByTenantId = ({ id, requestRecipient = false }: ITenantSettingsByTenantIdProp) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<TTenantSettings | null>(null);
    const [error, setError] = React.useState<string>("");

    const execute = async () => {
        if (!loading) {
            try {
                setLoading(true);

                if (error !== "") {
                    setError("");
                }

                if (id === "") {
                    setData(null);
                    return;
                }

                //await new Promise((f) => setTimeout(f, 1000));
                const response = !requestRecipient
                    ? await TenantController.getSettings({ id })
                    : await TenantController.getRecipientSettings({ id });

                const responseError = response as IErrorResponse;
                const responseData = response as TTenantSettings;

                if (responseError.code) {
                    setError(responseError.error);
                    setData(null);
                } else {
                    setData(responseData);
                }
            } catch (e) {
                var message = "Erro não identificado";
                if (typeof e === "string") {
                    message = e.toUpperCase();
                } else if (e instanceof Error) {
                    const err = e as Error;
                    message = err.message;
                }
                setError(message);
            } finally {
                setLoading(false);
            }
        }
    };

    React.useEffect(() => {
        execute();
    }, [id]);

    const clear = () => {
        setError("");
    };

    return {
        loading,
        data,
        error,
        execute,
        clear,
    };
};
