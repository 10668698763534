import { Box } from '@mui/material'

interface IAccordionDetailsDefaultProps {
  children: React.ReactNode
}

const AccordionDetailsDefault = ({
  children,
}: IAccordionDetailsDefaultProps) => {
  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: '#F3F3F7',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      }}
    >
      {children}
    </Box>
  )
}

export default AccordionDetailsDefault
