const ImageSignUpForm = () => {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/assets/signup/forms.svg`}
      style={{ width: '100%', height: '92px', marginBottom: '16px' }}
    />
  )
}

export default ImageSignUpForm
