import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PaymentIcon from '@mui/icons-material/Payment';
import { blue, grey } from '@mui/material/colors';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { IOrderBump } from '../../core/types/OrderBump';
import { useEffect, useRef } from 'react';

export interface MultiSelectedItem {
  id: string;
  name: string;
  selected: boolean;
  object: any;
  order?: number;
}

export interface MultiSelectedDialogProps {
  open: boolean;
  title?: string;
  description?: string;
  icon?: JSX.Element;
  list: MultiSelectedItem[];
  setList: (items: MultiSelectedItem[]) => void;
  onClose: () => void;
  onSave: (items: MultiSelectedItem[]) => void;
}

const MultiSelectedDialog = (props: MultiSelectedDialogProps): JSX.Element => {
  const { open, title, description, icon, list, setList, onClose, onSave } = props;

  const dragItem = useRef(-1);
  const dragOverItem = useRef(-1);

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (item: MultiSelectedItem) => {
    var newList: MultiSelectedItem[] = [];
    list.map((l) => {
      newList.push({
        id: l.id,
        name: l.name,
        selected: l.id !== item.id ? l.selected : !l.selected,
        object: l.object,
        order: l.selected ? l.order : undefined,
      });
      return true;
    });

    var order = 0;
    newList.map((item) => {
      if (item.selected) {
        order += 1;
        item.order = order;
      } else {
        item.order = undefined;
      }
    });

    newList = newList.sort((a, b) => (a.order || 0) - (b.order || 0));
    newList = newList.sort((a, b) => (((a.order || 0) === 0) || ((b.order || 0) === 0)) ? -1 : 0);

    setList(newList);
  };

  const handleSave = () => {
    onSave(list);
    onClose();
  }

  const disabledItem = (item: MultiSelectedItem): boolean => {
    if (!item.object || item.object === null) {
      return false;
    }

    if (item.selected) {
      return false;
    }

    const otherSelected = list.filter((l) => {
      if (!l.object || l.object === null) {
        return true;
      }
      return l.selected && (l.object! as IOrderBump).productPrice!.product!.id === (item.object! as IOrderBump).productPrice!.product!.id;
    });

    return otherSelected !== undefined && otherSelected.length > 0;
  }

  const dragStart = (e: React.DragEvent<HTMLLIElement>, position: number) => {
    dragItem.current = position;
  };

  const dragEnter = (e: React.DragEvent<HTMLLIElement>, position: number) => {
    dragOverItem.current = position;
  };

  const drop = (e: React.DragEvent<HTMLLIElement>) => {
    var copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = -1;
    dragOverItem.current = -1;

    var order = 0;
    copyListItems.map((item) => {
      if (item.selected) {
        order += 1;
        item.order = order;
      }
    });

    copyListItems = copyListItems.sort((a, b) => (a.order || 0) - (b.order || 0));
    copyListItems = copyListItems.sort((a, b) => (((a.order || 0) === 0) || ((b.order || 0) === 0)) ? -1 : 0);

    setList(copyListItems);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title || 'Selecione uma opção'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        <List sx={{ pt: 0 }}>
          {list.map((item, index) => (
            <ListItem
              disableGutters
              key={`item${index}`}
              draggable={item.selected}
              onDragStart={(e) => dragStart(e, index)}
              onDragEnter={(e) => dragEnter(e, index)}
              onDragEnd={drop}
            >
              <ListItemButton
                key={item.id}
                onClick={() => handleListItemClick(item)}
                disabled={disabledItem(item)}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      bgcolor: item.selected ? blue[100] : grey[100],
                      color: item.selected ? blue[600] : grey[600]
                    }}
                  >
                    {icon ? icon : <PaymentIcon />}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${item.order ? `${item.order} - ` : ""}${item.name}`} sx={{ width: "100%" }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={handleClose}>Cancelar</Button>
        <Button onClick={handleSave} autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MultiSelectedDialog;