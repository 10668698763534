import { useContext } from "react";
import { setAuthorization } from "../../services/api";
import { AuthContext } from "../../core/context/AuthContext";
import { useJwt } from "react-jwt";
import { Navigate, Outlet } from "react-router-dom";
import { TUserType } from "../../core/types/Auth";

const SysAdminProtected = () => {
    const { user, setUser } = useContext(AuthContext);
    const { isExpired } = useJwt(user?.token ?? '');

    if (isExpired) {
        setUser(null);
    }

    if (user === null || user.UserType !== TUserType.SystemAdmin) {
        return <Navigate to="/unauthorized" replace />;
    }

    setAuthorization(user.token);

    return <Outlet />;
};

export default SysAdminProtected;