import { Person } from "@mui/icons-material";
import ApprovedIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
    Box,
    Button,
    Paper,
    Stack,
    TextField,
    Typography,
    Slide,
    Backdrop,
    CircularProgress,
    Snackbar,
    Alert,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { TCustomer, TCustomerProduct, TCustomerProductStatus } from "../../../core/types/Customer";
import { useCustomer } from "../../../core/hooks/useCustomer";
import { useCustomerProducts } from "../../../core/hooks/useCustomerProducts";
import CustomerController from "../../../core/controllers/CustomerController";
import Convertions from "../../../core/functions/convertions";
import { maskCpfCnpjNumber, maskPhoneNumber } from "../../../masks/masks";
import SuccessSnackbar from "../../../components/Snackbar/SuccessSnackbar";
import LoadingBackdrop from "../../../components/Loading/LoadingBackdrop";
import ErrorSnackbar from "../../../components/Snackbar/ErrorSnackbar";

interface Column {
    id: 'name' | 'description' | 'status' | 'action';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'name', label: 'Nome', minWidth: 120 },
    { id: 'description', label: 'Descrição', minWidth: 120 },
    { id: 'status', label: 'Status', minWidth: 100 },
    {
        id: 'action',
        label: 'Ações',
        minWidth: 100,
    },
];

const CustomerPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const [animationState, setAnimationState] = React.useState(false);
    const [sending, setSending] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState('');

    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm<TCustomer>({
        defaultValues: {
            id: id,
            name: " ",
            document: " ",
            phone: " ",
            email: " ",
            address: {
                city: " ",
                state: " ",
                country: " ",
                street: " ",
                number: " ",
            }
        }
    });

    const { data, loading } = useCustomer({ id: id || '' });
    const { data: products, loading: loadingProducts, execute } = useCustomerProducts({ id: id || '' });

    React.useEffect(() => {
        setAnimationState(true);
        return () => setAnimationState(false);
    }, []);

    React.useEffect(() => {
        if (data !== null) {
            reset(data);

            setValue("document", maskCpfCnpjNumber(data.document));
            setValue("phone", maskPhoneNumber(data.phone));
        }
        return () => {
            if (data !== null) {
                reset(data);
            }
        };
    }, [data, reset]);

    const Submit = async (data: any) => {
        setSending(true);
        setSuccess(false);
        setError('');
        try {
            const response = await CustomerController.update({ data });
            if (!response.success) {
                setError(response.error);
            } else {
                setSuccess(true);
            }
        } finally {
            setSending(false);
        }
    };

    const updateProductStatus = async (customerProduct: TCustomerProduct, canceled: boolean) => {
        setSending(true);
        setSuccess(false);
        setError('');
        try {
            var response;
            if (canceled) {
                response = await CustomerController.updateProductCanceled({
                    id: customerProduct.id,
                    customerId: id || '',
                    productId: customerProduct.product.id,
                });
            } else {
                response = await CustomerController.updateProductApproved({
                    id: customerProduct.id,
                    customerId: id || '',
                    productId: customerProduct.product.id,
                });
            }
            if (!response.success) {
                setError(response.error);
            } else {
                setSuccess(true);
                execute();
            }
        } finally {
            setSending(false);
        }
    };

    return (
        <Box>
            <Box sx={{ width: "100%", margin: "0 auto" }}>
                <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
                    <Box>
                        <Box
                            component={Paper}
                            pt={2}
                            pb={5}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                flexBasis: 1,
                                alignItems: "center",
                                margin: "0 auto",
                            }}
                            width={{ xs: "100%", sm: "90%", md: "80%", lg: "70%", xl: "60%" }}
                        >
                            <Box
                                sx={{ display: "flex", flexDirection: "column" }}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <Person sx={{ fontSize: "3.5em" }} color="primary" />
                                <Typography variant="h5">Cliente</Typography>
                            </Box>
                            <Stack
                                component={"form"}
                                noValidate
                                direction={"column"}
                                pt={2}
                                spacing={3}
                                sx={{ width: "80%" }}
                                onSubmit={handleSubmit(Submit)}
                            >
                                <Box>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Nome"
                                        {...register("name", { required: true })}
                                        error={errors.name?.type === 'required'}
                                    />
                                    {errors.name?.type === 'required' && <Typography variant="overline" color={"red"}>Nome é um campo obrigatório</Typography>}
                                </Box>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Documento"
                                        {...register("document")}
                                    />
                                    <TextField
                                        type="text"
                                        label="Celular"
                                        {...register("phone")}
                                        fullWidth
                                    />
                                </Stack>
                                <TextField
                                    type="text"
                                    label="E-mail"
                                    fullWidth
                                    {...register("email")}
                                />
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        disabled
                                        variant="filled"
                                        type="text"
                                        label="País"
                                        {...register("address.country")}
                                        fullWidth
                                    />
                                    <TextField
                                        disabled
                                        variant="filled"
                                        type="text"
                                        label="Cidade"
                                        {...register("address.city")}
                                        fullWidth
                                    />
                                    <TextField
                                        disabled
                                        variant="filled"
                                        type="text"
                                        label="Estado"
                                        {...register("address.state")}
                                        fullWidth
                                    />
                                </Stack>
                                <Stack direction={"row"} spacing={2}>
                                    <TextField
                                        disabled
                                        variant="filled"
                                        type="text"
                                        label="Endereço"
                                        {...register("address.street")}
                                        fullWidth
                                    />
                                    <TextField
                                        disabled
                                        variant="filled"
                                        type="text"
                                        label="Número"
                                        {...register("address.number")}
                                        sx={{ width: "30%" }}
                                    />
                                </Stack>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                >
                                    Salvar
                                </Button>
                            </Stack>
                        </Box>

                        <Box
                            my={3}
                            sx={{ display: "flex", flexDirection: "column" }}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <Typography variant="h5">Produtos Adquiridos</Typography>
                        </Box>

                        <Box
                            component={Paper}
                            pt={2}
                            pb={5}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                flexBasis: 1,
                                alignItems: "center",
                                //width: "80%",
                                margin: "0 auto",
                            }}
                            width={{ xs: "100%", sm: "90%", md: "80%", lg: "70%", xl: "60%" }}
                        >
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {products !== null && products
                                            .map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                        {columns.map((column) => {
                                                            if (column.id === 'action') {
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {(row.status === TCustomerProductStatus.Cancelado || row.status === TCustomerProductStatus.Disputa) &&
                                                                            <Button
                                                                                variant="text"
                                                                                size="small"
                                                                                startIcon={<ApprovedIcon />}
                                                                                onClick={() => updateProductStatus(row, false)}
                                                                            >
                                                                                Aprovar
                                                                            </Button>
                                                                        }
                                                                        {(row.status === TCustomerProductStatus.Aprovado || row.status === TCustomerProductStatus.Disputa) &&
                                                                            <Button
                                                                                variant="text"
                                                                                size="small"
                                                                                color="error"
                                                                                startIcon={<CancelIcon />}
                                                                                onClick={() => updateProductStatus(row, true)}
                                                                            >
                                                                                Cancelar
                                                                            </Button>
                                                                        }
                                                                    </TableCell>
                                                                );
                                                            }
                                                            if (column.id === 'status') {
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {Convertions.customerProductStatusToString(row.status)}
                                                                    </TableCell>
                                                                );
                                                            }
                                                            const value = row.product[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number'
                                                                        ? column.format(value)
                                                                        : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Slide>
            </Box>
            
            <LoadingBackdrop open={loading || sending || loadingProducts} />

            <ErrorSnackbar
                open={error !== ''}
                onClose={() => setError('')}
            >
                {error}
            </ErrorSnackbar>

            <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
                Alterado com sucesso
            </SuccessSnackbar>
        </Box >
    );
};

export default CustomerPage;