import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { TTenantRegister } from '../../../core/types/Tenant'
import BasicDatePicker from '../../../components/DateTime/BasicDatePicker'
import { TenantController } from '../../../core/controllers/TenantController'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import {
  maskCpfCnpjNumber,
  maskPhoneNumber,
  maskCepNumber,
  numberOnly,
} from '../../../masks/masks'
import CurrencyField from '../../../components/Inputs/CurrencyField'
import CepController from '../../../core/controllers/CepController'
import states from '../../../core/data/states'
import Validations from '../../../core/functions/validations'
import { isValidDateTime } from '../../../core/functions/dateTime'

interface TenantCorporationProps {
  data: TTenantRegister
  loading: boolean
  execute: () => void
}

const TenantCorporation: React.FC<TenantCorporationProps> = ({
  data,
  loading,
  execute,
}) => {
  const {
    register: registerCorporation,
    watch,
    setValue,
    formState: { errors },
    setError: setErrorForm,
    clearErrors,
    getValues,
  } = useForm({ defaultValues: data })

  const corporationDocument = watch('document')
  const corporationPhone = watch('phone')
  const corporationZipcode = watch('mainAddress.zipCode')
  const representantPhone = watch('managingPartner.phone')
  const representantDocument = watch('managingPartner.document')
  const representatZipcode = watch('managingPartner.address.zipCode')

  const [isRepresentantBlock, setIsRepresentantBlock] = useState(true)
  const [loadingZipCode, setLoadingZipCode] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [tabIndex, setTabIndex] = useState(0)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex)
  }

  const searchZipCode = useCallback(
    async (
      load: boolean,
      addressName: 'mainAddress' | 'managingPartner.address',
      zipcode?: string
    ) => {
      if (!zipcode || load) {
        return
      }
      const code = numberOnly(zipcode)
      if (code.length < 8) {
        return
      }
      setLoadingZipCode(true)
      try {
        const cep = await CepController.search(code)
        if (cep) {
          setValue(`${addressName}.street`, cep.logradouro)
          setValue(`${addressName}.complement`, cep.complemento)
          setValue(`${addressName}.neighborhood`, cep.bairro)
          setValue(`${addressName}.city`, cep.localidade)
          setValue(`${addressName}.state`, cep.uf)
        } else {
        }
      } finally {
        setLoadingZipCode(false)
      }
    },
    [numberOnly, setLoadingZipCode, setValue]
  )

  const isCorporationValid = () => {
    let isValid = true

    const data = getValues()

    if (data.name === '') {
      isValid = false
      setErrorForm('name', { message: 'Campo obrigatório', type: 'required' })
    }

    if (data.phone === '') {
      isValid = false
      setErrorForm('phone', { message: 'Campo obrigatório', type: 'required' })
    }

    if (data.tradingName === '') {
      isValid = false
      setErrorForm('tradingName', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (data.document === '') {
      isValid = false
      setErrorForm('document', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    } else if (!Validations.CNPJ(data.document)) {
      isValid = false
      setErrorForm('document', { message: 'CPF inválido', type: 'invalid' })
    }

    if (!data.revenue || data.revenue === 0) {
      isValid = false
      setErrorForm('revenue', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    } else if (data.revenue > 9999999.99) {
      isValid = false
      setErrorForm('revenue', {
        message: 'O valor não pode ser maior que R$ 9.999.999,99',
        type: 'required',
      })
    }

    if (data.mainAddress?.zipCode === '') {
      isValid = false
      setErrorForm('mainAddress.zipCode', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.mainAddress?.street || data.mainAddress?.street === '') {
      isValid = false
      setErrorForm('mainAddress.street', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.mainAddress?.number || data.mainAddress?.number === '') {
      isValid = false
      setErrorForm('mainAddress.number', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.mainAddress?.complement || data.mainAddress?.complement === '') {
      isValid = false
      setErrorForm('mainAddress.complement', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (
      !data.mainAddress?.neighborhood ||
      data.mainAddress?.neighborhood === ''
    ) {
      isValid = false
      setErrorForm('mainAddress.neighborhood', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.mainAddress?.city || data.mainAddress?.city === '') {
      isValid = false
      setErrorForm('mainAddress.city', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.mainAddress?.state || data.mainAddress?.state === '') {
      isValid = false
      setErrorForm('mainAddress.state', {
        message: 'Campo obrigatório',
        type: 'required',
      })

      if (
        !data.mainAddress?.referencePoint ||
        data.mainAddress?.referencePoint === ''
      ) {
        isValid = false
        setErrorForm('mainAddress.referencePoint', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }
    }
    return isValid
  }

  const isRepresentatValid = () => {
    let isValid = true
    const data = getValues()

    if ((data.managingPartner?.name ?? '') === '') {
      isValid = false
      setErrorForm('managingPartner.name', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if ((data.managingPartner?.email ?? '') === '') {
      isValid = false
      setErrorForm('managingPartner.email', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (data.managingPartner?.document === '') {
      isValid = false
      setErrorForm('managingPartner.document', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    } else if (!Validations.CPF(data.managingPartner?.document)) {
      isValid = false
      setErrorForm('managingPartner.document', {
        message: 'CPF inválido',
        type: 'invalid',
      })
    }

    if ((data.managingPartner?.phone ?? '') === '') {
      isValid = false
      setErrorForm('managingPartner.phone', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.managingPartner?.birthDate) {
      isValid = false
      setErrorForm('managingPartner.birthDate', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    } else if (!isValidDateTime(data.managingPartner?.birthDate)) {
      isValid = false
      setErrorForm('managingPartner.birthDate', {
        message: 'Data inválida',
        type: 'invalid',
      })
    }

    if (
      !data.managingPartner?.monthyIncome ||
      data.managingPartner?.monthyIncome === 0
    ) {
      isValid = false
      setErrorForm('managingPartner.monthyIncome', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    } else if (data.managingPartner?.monthyIncome > 9999999.99) {
      isValid = false
      setErrorForm('managingPartner.monthyIncome', {
        message: 'O valor não pode ser maior que R$ 9.999.999,99',
        type: 'required',
      })
    }

    if ((data.managingPartner?.professionalOccupation ?? '') === '') {
      isValid = false
      setErrorForm('managingPartner.professionalOccupation', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if ((data.managingPartner?.address?.zipCode ?? '') === '') {
      isValid = false
      setErrorForm('managingPartner.address.zipCode', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (
      !data.managingPartner?.address?.street ||
      data.managingPartner?.address?.street === ''
    ) {
      isValid = false
      setErrorForm('managingPartner.address.street', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (
      !data.managingPartner?.address?.number ||
      data.managingPartner?.address?.number === ''
    ) {
      isValid = false
      setErrorForm('managingPartner.address.number', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (
      !data.managingPartner?.address?.complement ||
      data.managingPartner?.address?.complement === ''
    ) {
      isValid = false
      setErrorForm('managingPartner.address.complement', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (
      !data.managingPartner?.address?.neighborhood ||
      data.managingPartner?.address?.neighborhood === ''
    ) {
      isValid = false
      setErrorForm('managingPartner.address.neighborhood', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (
      !data.managingPartner?.address?.city ||
      data.managingPartner?.address?.city === ''
    ) {
      isValid = false
      setErrorForm('managingPartner.address.city', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (
      !data.managingPartner?.address?.state ||
      data.managingPartner?.address?.state === ''
    ) {
      isValid = false
      setErrorForm('managingPartner.address.state', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (
      !data.managingPartner?.address?.referencePoint ||
      data.managingPartner?.address?.referencePoint === ''
    ) {
      isValid = false
      setErrorForm('managingPartner.address.referencePoint', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    return isValid
  }

  const onSubmit = async (formData: any) => {
    clearErrors()
    setSending(true)
    setSuccess(false)
    setError('')

    let indexTab = -1

    if (!isRepresentatValid()) {
      indexTab = 1
    }

    if (!isCorporationValid()) {
      indexTab = 0
    }

    if (indexTab === -1) {
      await TenantController.update({ data: formData })
        .then((data) => {
          if (data.success) {
            setSuccess(true)
            execute()
          } else {
            setError('Erro ao alterar dados')
          }
        })
        .finally(() => setSending(false))
    } else {
      setError('Corrija os erros antes de prosseguir')
      setSending(false)
      setTabIndex(indexTab)
    }
  }

  useEffect(
    () => setValue('document', maskCpfCnpjNumber(corporationDocument)),
    [corporationDocument, setValue]
  )

  useEffect(
    () => setValue('phone', maskPhoneNumber(corporationPhone)),
    [corporationPhone, setValue]
  )

  useEffect(
    () => setValue('mainAddress.zipCode', maskCepNumber(corporationZipcode)),
    [corporationZipcode, setValue]
  )

  useEffect(
    () => setValue('managingPartner.phone', maskPhoneNumber(representantPhone)),
    [representantPhone, setValue]
  )

  useEffect(
    () =>
      setValue(
        'managingPartner.document',
        maskCpfCnpjNumber(representantDocument)
      ),
    [representantDocument, setValue]
  )

  useEffect(
    () =>
      setValue(
        'managingPartner.address.zipCode',
        maskCepNumber(representatZipcode)
      ),
    [representatZipcode, setValue]
  )

  useEffect(() => {
    if (data.managingPartner === null) {
      setIsRepresentantBlock(false)
    }
  }, [data])

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Empresa" />
        <Tab label="Representante" />
      </Tabs>

      <Box pt={2} sx={{ width: '100%' }}>
        {tabIndex === 0 && (
          <Stack spacing={3}>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Nome Fantasia"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('name', {
                  required: 'Nome Fantasia é obrigatório',
                })}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Razão Social"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('tradingName', {
                  required: 'Razão Social é obrigatória',
                })}
                error={!!errors.tradingName}
                helperText={errors.tradingName?.message}
              />
            </Stack>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="E-mail"
                type="text"
                disabled
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('email')}
              />

              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Telefone"
                type="text"
                placeholder="(99) 99999-9999"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('phone', {
                  required: 'Telefone é obrigatório',
                })}
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            </Stack>

            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="CNPJ"
                type="text"
                InputLabelProps={{ shrink: true }}
                disabled
                {...registerCorporation('document')}
              />

              <CurrencyField
                label="Receita Anual"
                value={watch('revenue') ?? 0}
                onChange={(value) => {
                  setValue('revenue', value)
                  clearErrors('revenue')
                }}
                error={
                  (errors.revenue?.type ?? '') !== '' &&
                  (errors.revenue?.message ?? '') !== ''
                }
                helperText={
                  errors.revenue?.type &&
                  errors.revenue.message && (
                    <Typography
                      sx={{ fontSize: 11 }}
                      variant="inherit"
                      color={'red'}
                    >
                      {errors.revenue?.message}
                    </Typography>
                  )
                }
              />
            </Stack>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="CEP"
                type="text"
                placeholder="00000-000"
                inputProps={{
                  inputMode: 'numeric',
                  maxLength: 9,
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="small"
                      onClick={() =>
                        searchZipCode(
                          loadingZipCode,
                          'mainAddress',
                          corporationZipcode
                        )
                      }
                    >
                      <img alt="Search" src="/assets/icons/search/search.svg" />
                    </IconButton>
                  ),
                }}
                {...registerCorporation('mainAddress.zipCode', {
                  required: 'CEP é obrigatório',
                  pattern: {
                    value: /^\d{5}-?\d{3}$/,
                    message: 'CEP inválido',
                  },
                })}
                error={!!errors.mainAddress?.zipCode}
                helperText={
                  errors.mainAddress?.zipCode
                    ? errors.mainAddress.zipCode.message
                    : ''
                }
              />
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Endereço"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('mainAddress.street', {
                  required: 'Endereço é obrigatório',
                })}
                error={!!errors.mainAddress?.street}
                helperText={errors.mainAddress?.street?.message}
              />
            </Stack>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Número"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('mainAddress.number', {
                  required: 'Número é obrigatório',
                })}
                error={!!errors.mainAddress?.number}
                helperText={errors.mainAddress?.number?.message}
              />
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Complemento"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('mainAddress.complement', {
                  required: 'Complemento é obrigatório',
                })}
                error={!!errors.mainAddress?.complement}
                helperText={errors.mainAddress?.complement?.message}
              />
            </Stack>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Bairro"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('mainAddress.neighborhood', {
                  required: 'Bairro é obrigatório',
                })}
                error={!!errors.mainAddress?.neighborhood}
                helperText={errors.mainAddress?.neighborhood?.message}
              />
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Cidade"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('mainAddress.city', {
                  required: 'Cidade é obrigatória',
                })}
                error={!!errors.mainAddress?.city}
                helperText={errors.mainAddress?.city?.message}
              />
            </Stack>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <FormControl fullWidth size="small" variant="outlined">
                <InputLabel id="state-label">Estado</InputLabel>
                <Select
                  labelId="state-label"
                  label="Estado"
                  value={watch('mainAddress.state') ?? ''}
                  {...registerCorporation('mainAddress.state', {
                    required: true,
                  })}
                  error={!!errors.mainAddress?.state}
                >
                  {states.map((state, index) => (
                    <MenuItem key={`state_${index}`} value={state.sigla}>
                      {`${state.sigla} - ${state.nome}`}
                    </MenuItem>
                  ))}
                </Select>
                {errors.mainAddress?.state ? (
                  <Typography variant="overline" color={'red'}>
                    {errors.mainAddress.state.message}
                  </Typography>
                ) : (
                  ''
                )}
              </FormControl>

              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Ponto de Referência"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('mainAddress.referencePoint', {
                  required: 'Ponto de Referência é obrigatório',
                })}
                error={!!errors.mainAddress?.referencePoint}
                helperText={errors.mainAddress?.referencePoint?.message}
              />
            </Stack>
          </Stack>
        )}
        {tabIndex === 1 && (
          <Stack spacing={3}>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Nome"
                type="text"
                disabled={isRepresentantBlock}
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('managingPartner.name')}
                error={!!errors.managingPartner?.name}
                helperText={errors.managingPartner?.name?.message}
              />
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Email"
                type="email"
                disabled={isRepresentantBlock}
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('managingPartner.email')}
                error={!!errors.managingPartner?.email}
                helperText={errors.managingPartner?.email?.message}
              />
            </Stack>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="CPF"
                type="text"
                disabled={isRepresentantBlock}
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('managingPartner.document')}
                error={!!errors.managingPartner?.document}
                helperText={errors.managingPartner?.document?.message}
              />
              <Box width="100%">
                <BasicDatePicker
                  fullWidth
                  label="Data de Nascimento"
                  value={watch('managingPartner.birthDate')}
                  disabled={isRepresentantBlock}
                  setValue={(e) => {
                    if (e) {
                      setValue('managingPartner.birthDate', e)
                    }
                  }}
                />
                {errors.managingPartner?.birthDate?.type &&
                  errors.managingPartner?.birthDate?.message && (
                    <Typography variant="caption" color={'red'}>
                      {errors.managingPartner?.birthDate?.message}
                    </Typography>
                  )}
              </Box>
            </Stack>

            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              size="small"
              label="Ocupação Profissional"
              type="text"
              InputLabelProps={{ shrink: true }}
              {...registerCorporation('managingPartner.professionalOccupation')}
              error={!!errors.managingPartner?.professionalOccupation}
              helperText={
                errors.managingPartner?.professionalOccupation?.message
              }
            />

            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Telefone"
                type="text"
                placeholder="(99) 99999-9999"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('managingPartner.phone', {
                  required: 'Telefone é obrigatório',
                })}
                error={!!errors.managingPartner?.phone}
                helperText={errors.managingPartner?.phone?.message}
              />
              <CurrencyField
                label="Renda Mensal"
                value={watch('managingPartner.monthyIncome') ?? 0}
                onChange={(value) => {
                  setValue('managingPartner.monthyIncome', value)
                  clearErrors('managingPartner.monthyIncome')
                }}
                error={
                  (errors.managingPartner?.monthyIncome?.type ?? '') !== '' &&
                  (errors.managingPartner?.monthyIncome?.message ?? '') !== ''
                }
                helperText={
                  errors.managingPartner?.monthyIncome?.type &&
                  errors.managingPartner?.monthyIncome.message && (
                    <Typography
                      sx={{ fontSize: 11 }}
                      variant="inherit"
                      color={'red'}
                    >
                      {errors.managingPartner?.monthyIncome?.message}
                    </Typography>
                  )
                }
              />
            </Stack>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="CEP"
                type="text"
                placeholder="00000-000"
                inputProps={{
                  inputMode: 'numeric',
                  maxLength: 9,
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="small"
                      onClick={() =>
                        searchZipCode(
                          loadingZipCode,
                          'managingPartner.address',
                          representatZipcode
                        )
                      }
                    >
                      <img alt="Search" src="/assets/icons/search/search.svg" />
                    </IconButton>
                  ),
                }}
                {...registerCorporation('managingPartner.address.zipCode', {
                  required: 'CEP é obrigatório',
                  pattern: {
                    value: /^\d{5}-?\d{3}$/,
                    message: 'CEP inválido',
                  },
                })}
                error={!!errors.managingPartner?.address?.zipCode}
                helperText={
                  errors.managingPartner?.address?.zipCode
                    ? errors.managingPartner?.address.zipCode.message
                    : ''
                }
              />
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Endereço"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('managingPartner.address.street', {
                  required: 'Endereço é obrigatório',
                })}
                error={!!errors.managingPartner?.address?.street}
                helperText={errors.managingPartner?.address?.street?.message}
              />
            </Stack>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Número"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('managingPartner.address.number', {
                  required: 'Número é obrigatório',
                })}
                error={!!errors.managingPartner?.address?.number}
                helperText={errors.managingPartner?.address?.number?.message}
              />
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Complemento"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('managingPartner.address.complement', {
                  required: 'Complemento é obrigatório',
                })}
                error={!!errors.managingPartner?.address?.complement}
                helperText={
                  errors.managingPartner?.address?.complement?.message
                }
              />
            </Stack>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Bairro"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation(
                  'managingPartner.address.neighborhood',
                  { required: 'Bairro é obrigatório' }
                )}
                error={!!errors.managingPartner?.address?.neighborhood}
                helperText={
                  errors.managingPartner?.address?.neighborhood?.message
                }
              />
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Cidade"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation('managingPartner.address.city', {
                  required: 'Cidade é obrigatória',
                })}
                error={!!errors.managingPartner?.address?.city}
                helperText={errors.managingPartner?.address?.city?.message}
              />
            </Stack>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
              <FormControl fullWidth size="small" variant="outlined">
                <InputLabel id="state-label">Estado</InputLabel>
                <Select
                  labelId="state-label"
                  label="Estado"
                  value={watch('managingPartner.address.state') ?? ''}
                  {...registerCorporation('managingPartner.address.state', {
                    required: true,
                  })}
                  error={!!errors.managingPartner?.address?.state}
                >
                  {states.map((state, index) => (
                    <MenuItem
                      key={`state_representant_${index}`}
                      value={state.sigla}
                    >
                      {`${state.sigla} - ${state.nome}`}
                    </MenuItem>
                  ))}
                </Select>
                {errors.managingPartner?.address?.state ? (
                  <Typography variant="overline" color={'red'}>
                    {errors.managingPartner?.address.message}
                  </Typography>
                ) : (
                  ''
                )}
              </FormControl>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                label="Ponto de Referência"
                type="text"
                InputLabelProps={{ shrink: true }}
                {...registerCorporation(
                  'managingPartner.address.referencePoint',
                  { required: 'Ponto de Referência é obrigatório' }
                )}
                error={!!errors.managingPartner?.address?.referencePoint}
                helperText={
                  errors.managingPartner?.address?.referencePoint?.message
                }
              />
            </Stack>
          </Stack>
        )}
        <Stack sx={{ mt: 2, alignItems: 'start', justifyContent: 'start' }}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={() => onSubmit(watch())}
          >
            Salvar
          </Button>
        </Stack>
      </Box>
      <LoadingBackdrop open={loading || sending} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Alterado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

export default TenantCorporation
