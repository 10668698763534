import {
  Box,
  Button,
  Fade,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import SwipeableDrawerRightDialog from '../../Dialogs/SwipeableDrawerRightDialog'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveIcon from '@mui/icons-material/DoneAll'
import { red } from '@mui/material/colors'
import {
  TProductVoucher,
  TProductVoucherExpired,
  TProductVoucherValue,
} from '../../../core/types/Voucher'
import BasicDatePicker from '../../DateTime/BasicDatePicker'
import { TimePickerFilter } from '../../DatePickerFilter/TimePickerFilter'
import { isValidDateTime } from '../../../core/functions/dateTime'
import SwitchRoundSmall from '../../Switch/SwitchRoundSmall'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import { PriceMultiselect } from '../PriceMultiselect'
import { TProductPrice } from '../../../core/types/Product'
import InfoIcon from '@mui/icons-material/InfoRounded'
import { HtmlTooltip } from '../../Tooltip/HtmlTooltip'
import { useContext } from 'react'
import { AuthContext } from '../../../core/context/AuthContext'
import { TUserType } from '../../../core/types/Auth'

export interface IProductVoucherRegisterProps {
  open: boolean
  sending: boolean
  productVoucher: TProductVoucher
  productPrices: TProductPrice[]
  errorIdentifier: string
  errorValue: string
  errorPrices: string
  errorEndDate: string
  setOpen: (value: boolean) => void
  setProductVoucher: (value: TProductVoucher) => void
  onSave: () => Promise<void>
}

const ProductVoucherRegister = ({
  open,
  sending,
  productVoucher,
  productPrices,
  errorIdentifier,
  errorValue,
  errorPrices,
  errorEndDate,
  setOpen,
  setProductVoucher,
  onSave,
}: IProductVoucherRegisterProps) => {
  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={() => setOpen(false)}
      setOpen={(value) => setOpen(value)}
      title="Cupom de Desconto"
      /*actions={
                <Stack direction="row" spacing={1} sx={{ p: 1 }} justifyContent="end">
                    <Button startIcon={<ArrowBackIcon />} disabled={sending} onClick={() => setOpen(false)}>
                        Voltar
                    </Button>
                    <Button variant="contained" endIcon={<SaveIcon />} disabled={sending} onClick={onSave}>
                        Salvar
                    </Button>
                </Stack>
            }*/
      buttons={
        isAdmin
          ? []
          : [
              {
                title: 'Voltar',
                onClick: () => setOpen(false),
                type: 'negative',
                startIcon: <ArrowBackIcon />,
              },
              {
                title: 'Salvar',
                onClick: onSave,
                type: 'positive',
                endIcon: <SaveIcon />,
              },
            ]
      }
      buttonsDisabled={sending}
    >
      <Box>
        <Stack direction="column" spacing={3} sx={{ px: 2 }}>
          <Box>
            <Stack
              direction="row"
              spacing={1}
              alignContent="center"
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Typography variant="body2" fontWeight={500}>
                Código do Cupom de Desconto
              </Typography>
            </Stack>
            <TextField
              fullWidth
              disabled={isAdmin}
              multiline
              size="small"
              type="text"
              InputLabelProps={{ shrink: true }}
              value={productVoucher.voucherIdentifier}
              onChange={(e) =>
                setProductVoucher({
                  ...productVoucher,
                  voucherIdentifier: e.target.value,
                })
              }
              placeholder="Digite o código"
              error={errorIdentifier !== ''}
              helperText="Este é o código que ser comprador tem que digitar para receber o desconto."
            />
            {errorIdentifier !== '' && (
              <Typography variant="caption" color={red[700]}>
                {errorIdentifier}
              </Typography>
            )}
          </Box>
          <Box>
            <Stack
              direction="row"
              spacing={1}
              alignContent="center"
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Typography variant="body2" fontWeight={500}>
                Desconto
              </Typography>
            </Stack>
            <TextField
              fullWidth
              disabled={isAdmin}
              size="small"
              placeholder="0,00"
              value={productVoucher.value}
              onChange={(e) =>
                setProductVoucher({
                  ...productVoucher,
                  value: e.target.value,
                })
              }
              InputProps={{
                startAdornment: (
                  <>
                    <Select
                      variant="standard"
                      size="small"
                      autoWidth={false}
                      disableUnderline={true}
                      value={productVoucher.valueType}
                      onChange={(event) => {
                        const valueType = event.target
                          .value as TProductVoucherValue
                        const isOfferAll =
                          valueType === TProductVoucherValue.value
                            ? false
                            : productVoucher.isOfferAll

                        setProductVoucher({
                          ...productVoucher,
                          valueType,
                          isOfferAll,
                        })
                      }}
                      renderValue={(selected) => (
                        <Typography>
                          {selected === TProductVoucherValue.value
                            ? 'Por Valor'
                            : 'Por Percentual'}
                        </Typography>
                      )}
                      inputProps={{ sx: { padding: '0 !important' } }}
                      style={{
                        borderRadius: '4px',
                        paddingRight: '0px',
                        width: '200px',
                      }}
                    >
                      <MenuItem
                        key={'valor'}
                        value={TProductVoucherValue.value}
                      >
                        Por Valor
                      </MenuItem>
                      <MenuItem
                        key={'percentual'}
                        value={TProductVoucherValue.percentage}
                      >
                        Por Percentual
                      </MenuItem>
                    </Select>
                    <Box
                      sx={{
                        backgroundColor: '#DCDDDE',
                        height: '22.5px',
                        width: '2px',
                        ml: 1,
                        mr: 1,
                      }}
                    />
                  </>
                ),
                endAdornment: (
                  <HtmlTooltip
                    title={
                      productVoucher.valueType ===
                      TProductVoucherValue.value ? (
                        <Typography variant="caption">
                          Por valor é necessário selecionar as ofertas. O valor
                          do desconto tem que ser menor que o valor da oferta.
                        </Typography>
                      ) : (
                        <Typography variant="caption">
                          Será calculado o percentual sobre o valor total da
                          venda, incluindo os valores dos orderbumps
                          selecionados no checkout.
                        </Typography>
                      )
                    }
                  >
                    <InfoIcon fontSize="small" sx={{ color: 'grey' }} />
                  </HtmlTooltip>
                ),
              }}
              error={errorValue !== ''}
              helperText={errorValue}
            />
          </Box>
          <Box>
            <Stack
              direction="row"
              spacing={1}
              alignContent="center"
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Typography variant="body2" fontWeight={500}>
                Válido a partir de
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignContent="center"
              alignItems="center"
            >
              <BasicDatePicker
                fullWidth={false}
                disabled={isAdmin}
                value={productVoucher.startDurationDate}
                setValue={(e) => {
                  if (e) {
                    setProductVoucher({
                      ...productVoucher,
                      startDurationDate: e,
                    })
                  }
                }}
              />
              <TimePickerFilter
                label=""
                disabled={isAdmin}
                value={productVoucher.startDurationDate}
                setDate={(e) => {
                  const newValue = new Date(e)
                  if (isValidDateTime(newValue)) {
                    setProductVoucher({
                      ...productVoucher,
                      startDurationDate: newValue,
                    })
                  }
                }}
              />
            </Stack>
          </Box>
          <Box>
            <Stack
              direction="row"
              spacing={1}
              alignContent="center"
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Typography variant="body2" fontWeight={500}>
                Válido até
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignContent="center"
              alignItems="center"
            >
              <Select
                variant="outlined"
                disabled={isAdmin}
                size="small"
                autoWidth={false}
                value={productVoucher.expiredType}
                onChange={(event) => {
                  const expiredType = event.target
                    .value as TProductVoucherExpired
                  let endDurationDate = productVoucher.endDurationDate

                  if (expiredType === TProductVoucherExpired.date) {
                    if (!endDurationDate) {
                      endDurationDate = new Date()
                    }
                  } else {
                    endDurationDate = null
                  }

                  setProductVoucher({
                    ...productVoucher,
                    expiredType,
                    endDurationDate,
                  })
                }}
                sx={{ width: '230px' }}
              >
                <MenuItem key={'valor'} value={TProductVoucherExpired.quantity}>
                  Quantidade Máxima
                </MenuItem>
                <MenuItem
                  key={'percentual'}
                  value={TProductVoucherExpired.date}
                >
                  Data Final
                </MenuItem>
              </Select>
              {productVoucher.expiredType === TProductVoucherExpired.date && (
                <Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignContent="center"
                    alignItems="center"
                  >
                    <BasicDatePicker
                      fullWidth={false}
                      value={productVoucher.endDurationDate}
                      setValue={(e) => {
                        if (e) {
                          setProductVoucher({
                            ...productVoucher,
                            endDurationDate: e,
                          })
                        }
                      }}
                    />
                    <TimePickerFilter
                      label=""
                      value={productVoucher.endDurationDate}
                      setDate={(e) => {
                        const newValue = new Date(e)
                        if (isValidDateTime(newValue)) {
                          setProductVoucher({
                            ...productVoucher,
                            endDurationDate: newValue,
                          })
                        }
                      }}
                    />
                  </Stack>
                  {errorEndDate && (
                    <Typography variant="caption" color="error">
                      {errorEndDate}
                    </Typography>
                  )}
                </Box>
              )}
              {productVoucher.expiredType ===
                TProductVoucherExpired.quantity && (
                <TextField
                  disabled={isAdmin}
                  size="small"
                  type="text"
                  value={productVoucher.maxQuantity ?? ''}
                  onChange={(e) =>
                    setProductVoucher({
                      ...productVoucher,
                      maxQuantity: NumberFunctions.toNumber(e.target.value),
                    })
                  }
                  placeholder="1"
                />
              )}
            </Stack>
          </Box>
          {productVoucher.valueType === TProductVoucherValue.percentage && (
            <FormControlLabel
              control={
                <SwitchRoundSmall
                  disabled={isAdmin}
                  sx={{ m: 1 }}
                  checked={productVoucher.isOfferAll}
                  onChange={(e) => {
                    if (
                      productVoucher.valueType ===
                      TProductVoucherValue.percentage
                    ) {
                      setProductVoucher({
                        ...productVoucher,
                        isOfferAll: e.target.checked,
                      })
                    }
                  }}
                />
              }
              label="Aplicar em todas as ofertas"
            />
          )}
          {!productVoucher.isOfferAll && (
            <PriceMultiselect
              prices={productPrices}
              selecteds={productVoucher.productPrices}
              error={errorPrices}
              setSelecteds={(values) =>
                setProductVoucher({ ...productVoucher, productPrices: values })
              }
            />
          )}
        </Stack>
      </Box>
    </SwipeableDrawerRightDialog>
  )
}

export default ProductVoucherRegister
