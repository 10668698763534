import { Box, Button, Chip, Stack, Typography } from '@mui/material'
import AccordionDefault from '../../../components/Accordions/AccordionDefault'
import AccordionSummaryDefault from '../../../components/Accordions/AccordionSummaryDefault'
import { dateTimeString } from '../../../core/functions/dateTime'
import OrderFunctions from '../../../core/functions/OrderFunctions'
import AccordionDetailsDefault from '../../../components/Accordions/AccordionDetailsDefault'
import Convertions from '../../../core/functions/convertions'
import { maskPhoneNumber } from '../../../masks/masks'
import Hosts from '../../../core/functions/hosts'
import { IWithdraw } from '../../../core/types/Tenant'
import { withdrawStatusComponent } from './WithdrawConsts'
import NumberFunctions from '../../../core/functions/NumberFunctions'

interface WithdrawGridItemMobileProps {
  item: IWithdraw
  index: number
  handleShowDetails: (value: IWithdraw) => void
}

const WithdrawGridItemMobile = ({
  item,
  index,
  handleShowDetails,
}: WithdrawGridItemMobileProps) => {
  const getWithdrawStatus = () => {
    const statusConfig = withdrawStatusComponent[item.status]
    const { label, ...sx } = statusConfig
    return <Chip label={label} size="small" sx={sx} />
  }

  return (
    <Box key={`abandoned${index}`} width="100%" sx={{ mb: 1, px: 2 }}>
      <AccordionDefault>
        <AccordionSummaryDefault>
          <Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight={500}
                    fontSize="14px"
                    lineHeight="18px"
                    color="rgba(56, 57, 59, 1)"
                  >
                    {NumberFunctions.formatMoneyDefault(item.amount)}
                  </Typography>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight={500}
                    fontSize="12px"
                    lineHeight="16px"
                    color="rgba(99, 126, 239, 1)"
                  >
                    {dateTimeString(item.createdAt)}
                  </Typography>
                </Box>
              </Box>

              {getWithdrawStatus()}
            </Stack>
          </Box>
        </AccordionSummaryDefault>

        <AccordionDetailsDefault>
          <Box>
            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Taxa:
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontWeight={600}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                {NumberFunctions.formatMoneyDefault(3.67)}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Valor líquido:
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontWeight={600}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                {NumberFunctions.formatMoneyDefault(item.amount - 3.67)}
              </Typography>
            </Box>

            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              sx={{ mt: 1 }}
            >
              <Button
                size="small"
                onClick={(event) => {
                  event.stopPropagation()
                  handleShowDetails(item)
                }}
                startIcon={
                  <img
                    src="/assets/icons/eyes/eyeButtonWhite.svg"
                    alt="whats"
                  />
                }
                sx={{
                  backgroundColor: 'rgba(56, 57, 59, 1)',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(56, 57, 59, 0.9)',
                  },
                  width: '120px',
                  height: '24px',

                  borderRadius: '4px',
                  padding: '2px 8px 2px 4px',
                  gap: '8px',
                  textTransform: 'none',
                }}
              >
                <Typography
                  sx={{
                    width: '61px',
                    height: '16px',
                    fontFamily: 'Montserrat',
                    fontWeight: 700,
                    fontSize: '12px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Visualizar
                </Typography>
              </Button>
            </Stack>
          </Box>
        </AccordionDetailsDefault>
      </AccordionDefault>
    </Box>
  )
}

export default WithdrawGridItemMobile
