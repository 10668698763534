import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { Handle, Position } from 'reactflow'
import IconFilterButton from '../../../Buttons/IconFilterButton'

const handleStylePositive = { top: 125 }
const handleStyleNegative = { top: 165 }

function TextUpdaterNode({
  data,
  isConnectable,
}: {
  data: any
  isConnectable?: boolean
}) {
  return (
    <div className="text-updater-node">
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <div>
        <Box sx={{ p: '16px' }}>
          <Typography
            gutterBottom
            variant="body2"
            fontWeight={500}
            height="40px"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {data.title}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            height="40px"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {data.subtitle}
          </Typography>
          {!data.isThankYouPage && (
            <>
              <Button
                fullWidth
                variant="contained"
                color="success"
                size="small"
                disabled={data.positiveNextStepId !== null}
                sx={{ mb: 1, textTransform: 'none' }}
                onClick={() => data.onPositive(data)}
              >
                {data.positiveButtonLabel}
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="error"
                size="small"
                disabled={data.negativeNextStepId !== null}
                sx={{ mb: 1, textTransform: 'none' }}
                onClick={() => data.onNegative(data)}
              >
                {data.negativeButtonLabel}
              </Button>
            </>
          )}
        </Box>
        <Divider />
        <Stack direction="row" spacing={1.5} sx={{ px: '16px', py: '8px' }}>
          <IconFilterButton
            icon={<img src="/assets/icons/actions/code.svg" alt="code" />}
            backgroundColor="#F2F2FF"
            onClick={() => data.onCopyCode(data.id)}
          />
          <IconFilterButton
            icon={<img src="/assets/icons/actions/editBlue.svg" alt="edit" />}
            backgroundColor="#F2F2FF"
            onClick={() => data.onEdit(data.id)}
          />

          {!data.isInitial && (
            <IconFilterButton
              icon={
                <img
                  src="/assets/icons/actions/delete.svg"
                  alt="delete"
                  style={{ width: '20px', height: '20px' }}
                />
              }
              backgroundColor="#FFEEE9"
              onClick={() => data.onDelete(data.id)}
            />
          )}
        </Stack>
      </div>
      {!data.isThankYouPage && (
        <Handle
          type="source"
          position={Position.Right}
          id="a"
          style={handleStylePositive}
          isConnectable={isConnectable}
        />
      )}
      {!data.isThankYouPage && (
        <Handle
          type="source"
          position={Position.Right}
          id="b"
          style={handleStyleNegative}
          isConnectable={isConnectable}
        />
      )}
    </div>
  )
}

export default TextUpdaterNode
