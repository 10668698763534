import { useEffect, useRef, useState } from 'react'
import { TUtmfyListId } from '../../../../core/types/Utmfy'
import { useForm } from 'react-hook-form'
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Select,
  Typography,
} from '@mui/material'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import { useProductsSimple } from '../../../../core/hooks/useProductsSimple'
import UtmfyController from '../../../../core/controllers/UtmfyController'

export interface IUtmfyRegisterProps {
  open: boolean
  utmfyItem: any
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  execute: () => Promise<void>
}

const UtmfyEdit = ({
  open,
  utmfyItem,
  setOpen,
  execute,
}: IUtmfyRegisterProps) => {
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [settings, setSettings] = useState<TUtmfyListId>(utmfyItem)
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])
  const [selectedIds, setSelectedIds] = useState(utmfyItem.productIds)
  const [errorName, setErrorName] = useState('')
  const [apiToken, setApiToken] = useState('')
  const [isAllProductsFalse, setAllProductsFalse] = useState(false)
  const [isAllProductsError, setAllProductsError] = useState('')
  const { handleSubmit } = useForm<TUtmfyListId>()

  const { data: products, loading: loadingProducts } = useProductsSimple({
    initialExecute: true,
  })

  useEffect(() => {
    if (products && utmfyItem.productIds) {
      const initalSelectedOptions = products.filter((p) =>
        utmfyItem.productIds.includes(p.id)
      )
      setSelectedOptions(initalSelectedOptions)
    }
  }, [products, utmfyItem.productIds])

  useEffect(() => {
    if (utmfyItem) {
      setSettings(utmfyItem)
      setSelectedIds(utmfyItem.productIds || [])
    }
  }, [utmfyItem])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setSettings((prevSettings) => ({ ...prevSettings, [name]: value }))
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setSettings((prevSettings) => ({ ...prevSettings, [name]: checked }))
  }

  const handleSelectChange = (event: any) => {
    const newSelectedOptions = event.target.value

    const newSelectedIds = newSelectedOptions.map((option: any) => option.id)

    setSelectedOptions(newSelectedOptions)
    setSelectedIds(newSelectedIds)

    setSettings((prevSettings) => ({
      ...prevSettings,
      productIds: newSelectedIds,
    }))
  }

  const Submit = async () => {
    var isError = false
    setSending(true)
    const dataToSend = {
      settingsName: settings.settingsName,
      apiToken: settings.apiToken,
      allProducts: settings.allProducts,
      products: settings.allProducts ? [] : selectedIds,
    }

    if (!settings.settingsName || settings.settingsName.trim() === '') {
      setErrorName('Campo obrigatório')
      isError = true
    }

    if (!settings.apiToken || settings.apiToken.trim() === '') {
      setApiToken('Campo obrigatório')
      isError = true
    }

    if (!settings.allProducts && settings.productIds.length <= 0) {
      isError = true
      setAllProductsFalse(true)
      setAllProductsError('Seleciona pelo menos 1 produto')
    }

    try {
      if (isError) {
        return false
      } else {
        const response = await UtmfyController.update({
          id: utmfyItem.id,
          data: dataToSend,
        })

        if (!response.success) {
          setError(response.error)
        } else {
          setSuccess(true)
          execute()
          handleOnClose()
        }
      }
    } finally {
      setSending(false)
    }
  }

  const handleOnClose = () => {
    setOpen(false)
    setError('')
    setErrorName('')
    setApiToken('')
    setAllProductsError('')
  }

  return (
    <Box>
      <SwipeableDrawerRightDialog
        open={open}
        onClose={handleOnClose}
        setOpen={setOpen}
        title="UTMIfy"
        subtitle={'Edite o UTMIfy'}
        buttons={[
          {
            title: 'Cancelar',
            onClick: handleOnClose,
            type: 'negative',
          },
          {
            title: 'Salvar',
            type: 'positive',
            event: 'submit',
          },
        ]}
        noValidate
        component="form"
        onSubmit={handleSubmit(Submit)}
      >
        <Stack spacing={2} p={1}>
          <Box>
            <TextField
              label="Name"
              name="settingsName"
              value={settings.settingsName}
              onChange={handleInputChange}
              fullWidth
              error={errorName !== ''}
              InputLabelProps={{ shrink: true }}
            />
            {errorName !== '' && (
              <Typography variant="overline" color={'red'}>
                {errorName}
              </Typography>
            )}
          </Box>
          <Box>
            <TextField
              label="API Token"
              name="apiToken"
              value={settings.apiToken}
              onChange={handleInputChange}
              fullWidth
              error={apiToken !== ''}
              InputLabelProps={{ shrink: true }}
            />
            {apiToken !== '' && (
              <Typography variant="overline" color={'red'}>
                {apiToken}
              </Typography>
            )}
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={settings.allProducts || false}
                onChange={handleCheckboxChange}
                name="allProducts"
              />
            }
            label="Todos os produtos"
          />
          {!settings.allProducts && (
            <>
              <InputLabel id="select-label">Produtos</InputLabel>
              <Select
                id="select-multiple"
                multiple
                value={selectedOptions}
                onChange={handleSelectChange}
                labelId="select-label"
                renderValue={(selected: any) => (
                  <Stack direction={'row'} spacing={1}>
                    {selected.map((option: any) => (
                      <Typography>{option.name}</Typography>
                    ))}
                  </Stack>
                )}
              >
                {products.map((option: any) => (
                  <MenuItem key={option.id} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {isAllProductsFalse && (
                <Typography variant="overline" color={'red'}>
                  {isAllProductsError}
                </Typography>
              )}
            </>
          )}
        </Stack>
      </SwipeableDrawerRightDialog>

      <LoadingBackdrop open={sending} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Atualizado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

export default UtmfyEdit
