import { Box } from '@mui/material'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { useState } from 'react'
import { TConfirmDialogButton } from '../../../../components/Dialogs/DialogActions/DialogActionButton'
import TypePaymentChoose from '../TypePaymentChoose'
import TypeDeliveryChoose from '../TypeDeliveryChoose'
import {
  TProductDelivery,
  TProductTypeChoose,
  TProductTypePayment,
} from '../../../../core/types/Product'
import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'

interface ITypePaymentChoose {
  open: boolean
  onClose: () => void
  onChange: (value: TProductTypeChoose) => void
  setOpen: (value: boolean) => void
}

const ProductTypeChoose = ({
  open,
  onClose,
  setOpen,
  onChange,
}: ITypePaymentChoose) => {
  const [activeStep, setActiveStep] = useState(0)
  const [productDelivery, setProductDelivery] = useState<TProductDelivery>(
    TProductDelivery.Course
  )
  const [error, setError] = useState('')

  const handleChangePreview = () => {
    setActiveStep((as) => as - 1)
  }

  const handleChangeNext = () => {
    setActiveStep((as) => as + 1)
  }

  const handleChangeSave = () => {
    setError('Selecione uma opção')
  }

  const handleChangeDelivery = (value: TProductDelivery) => {
    setProductDelivery(value)
    handleChangeNext()
  }

  const handleChangeTypePayment = (value: TProductTypePayment) => {
    onChange({ delivery: productDelivery, typePayment: value })
    setActiveStep(0)
  }

  const handleChangeClose = () => {
    setActiveStep(0)
    onClose()
  }

  const getTitle = () => {
    const titles = ['tipo da entrega', 'tipo de pagamento']
    return `Selecione o ${titles[activeStep]} do produto`
  }

  const getButtons = (): TConfirmDialogButton[] => {
    const buttons: TConfirmDialogButton[][] = [
      [
        {
          title: 'Cancelar',
          type: 'negative',
          event: 'button',
          onClick: handleChangeClose,
        },
        {
          title: 'Próximo',
          type: 'positive',
          event: 'button',
          onClick: handleChangeSave,
        },
      ],
      [
        {
          title: 'Voltar',
          type: 'negative',
          event: 'button',
          onClick: handleChangePreview,
        },
        {
          title: 'Próximo',
          type: 'positive',
          event: 'button',
          onClick: handleChangeSave,
        },
      ],
    ]
    return buttons[activeStep]
  }

  return (
    <>
      <SwipeableDrawerRightDialog
        open={open}
        setOpen={setOpen}
        onClose={handleChangeClose}
        title={getTitle()}
        subtitle="Selecione abaixo uma opção"
        buttons={getButtons()}
      >
        <Box>
          <Box sx={{ display: activeStep !== 0 ? 'none' : undefined }}>
            <TypeDeliveryChoose onChange={handleChangeDelivery} />
          </Box>
          <Box sx={{ display: activeStep !== 1 ? 'none' : undefined }}>
            <TypePaymentChoose
              onOneTimePayment={() =>
                handleChangeTypePayment(TProductTypePayment.OneTimePayment)
              }
              onRecurringSubscription={() =>
                handleChangeTypePayment(
                  TProductTypePayment.RecurringSubscription
                )
              }
            />
          </Box>
        </Box>
      </SwipeableDrawerRightDialog>

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>
    </>
  )
}

export default ProductTypeChoose
