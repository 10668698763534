import React from "react";
import { IErrorResponse } from "../types/ErrorResponse";
import { TCustomCheckout } from "../types/CustomCheckout";
import CustomCheckoutController from "../controllers/CustomCheckoutController";

export interface TCustomCheckoutProp {
    productId: string;
}

export const useCustomCheckoutsByProduct = ({ productId }: TCustomCheckoutProp) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<TCustomCheckout[]>([]);
    const [error, setError] = React.useState<string>('');

    const execute = async () => {
        if (!loading) {
            try {
                setLoading(true);

                if (error !== '') {
                    setError('');
                }

                const response = await CustomCheckoutController.getByProduct({ productId });
                const responseError = response as IErrorResponse;
                const responseData = response as TCustomCheckout[];

                if (responseError.code) {
                    setError(responseError.error);
                    setData([]);
                } else {
                    setData(responseData);
                }
            } catch (e) {
                var message = 'Erro não identificado';
                if (typeof e === "string") {
                    message = e.toUpperCase();
                } else if (e instanceof Error) {
                    const err = e as Error;
                    message = err.message;
                }
                setError(message);
            } finally {
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        execute();
    }, [productId]);

    const clear = () => {
        setError('');
    }

    return {
        loading,
        data,
        error,
        execute,
        clear,
    };
}