import { Typography } from "@mui/material";

interface TitlePageTypographyProps {
    children: React.ReactNode;
}

const TitlePageTypography = ({ children }: TitlePageTypographyProps) => {
    return (
        <Typography gutterBottom variant="h6" fontWeight={700} fontSize="20px" lineHeight="24px">
            {children}
        </Typography>
    );
};

export default TitlePageTypography;
