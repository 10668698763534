import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material'
import InputSearch from '../../../components/Inputs/InputSearch'
import { useState } from 'react'
import { useFunnelSteps } from '../../../core/hooks/useFunnelSteps'

export interface IFunnelStepsChooseProp {
  onChoose: (value: string) => void
}

const FunnelStepsChoose = ({ onChoose }: IFunnelStepsChooseProp) => {
  const [search, setSearch] = useState('')

  const { data, loading, execute } = useFunnelSteps({ search })

  return (
    <Box>
      <InputSearch
        name="Pesquisar..."
        setSearch={setSearch}
        execute={execute}
      />
      {data?.items.map((funnelStep) => (
        <Card sx={{ width: '100%', mb: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={{ p: 1 }}
          >
            <Stack direction="column">
              <Typography
                variant="caption"
                fontWeight={500}
              >{`${funnelStep.name} - ${funnelStep.productName}`}</Typography>
              <Typography variant="caption">{funnelStep.pageUrl}</Typography>
            </Stack>
            <Button size="small" onClick={() => onChoose(funnelStep.id)}>
              Selecionar
            </Button>
          </Stack>
        </Card>
      ))}
    </Box>
  )
}

export default FunnelStepsChoose
