export enum TCommissionRuleType {
    Sales = 1,
    Commissions = 2,
}

export const commissionRuleTypes = [
    TCommissionRuleType.Sales,
    TCommissionRuleType.Commissions
];

export type TCommissionRuleProduct = {
    productAffiliationId: string;
    productName: string;
}

export type TCommissionRule = {
    codeId: string;
    identifier: string;
    ruleType: TCommissionRuleType;
    ruleMeta: number;
    commissionPercentage: number;
    globalCommissionPercentage: number;
    deadline?: number;
    createdDate: Date;
    closingDate?: Date | null;

    products?: TCommissionRuleProduct[];
}

export type TCommissionRulePagination = {
    items: TCommissionRule[];
    page: number;
    rowsPerPage: number;
    totalPages: number;
    total: number;
}