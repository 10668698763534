import { Box, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import {
  TCheckoutOrder,
  TCheckoutOrderDetails,
} from '../../../../../core/types/CheckoutOrder'
import Convertions from '../../../../../core/functions/convertions'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import { dateTimeString } from '../../../../../core/functions/dateTime'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'

export interface IOrderDetailPaymentsProp {
  checkoutOrder?: TCheckoutOrder
  details?: TCheckoutOrderDetails
}

const OrderDetailPayments = ({
  checkoutOrder,
  details,
}: IOrderDetailPaymentsProp) => {
  return (
    <Box width="100%" sx={{ pt: 2 }}>
      <Box
        sx={{
          mx: 2,
          px: 2,
          py: 1,
          backgroundColor: 'divider',
          borderRadius: '4px',
        }}
      >
        <Grid container columns={18} width="100%">
          <Grid xs={10}>
            <Typography variant="subtitle2">Data e Hora</Typography>
          </Grid>
          <Grid xs={8}>
            <Typography variant="subtitle2" textAlign="end">
              Valor
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {details?.subscriptionsChargesPaid?.items.map((paid, index) => (
        <ListItemDefault key={`paid-${index}`} title="">
          <Grid container columns={18} width="100%">
            <Grid xs={10}>
              <Typography variant="body2">
                {dateTimeString(paid.chargeDate)}
              </Typography>
            </Grid>
            <Grid xs={8}>
              <Typography
                variant="body2"
                textAlign="end"
              >{`${NumberFunctions.formatMoneyDefault(paid.value)} / ${Convertions.productFrequencyToType(checkoutOrder?.frequency)}`}</Typography>
            </Grid>
          </Grid>
        </ListItemDefault>
      ))}
    </Box>
  )
}

export default OrderDetailPayments
