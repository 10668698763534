export enum TProdutAffiliatesCategory {
    All = 0,
    HealthAndSports = 1,
    FinanceAndInvestments = 2,
    Relationships = 3,
    BusinessAndCareer = 4,
    Spirituality = 5,
    Sexuality = 6,
    Entertainment = 7,
    CulinaryAndGastronomy = 8,
    Languages = 9,
    Law = 10,
    AppsSoftware = 11,
    Literature = 12,
    HomeAndConstruction = 13,
    PersonalDevelopment = 14,
    FashionAndBeauty = 15,
    AnimalsAndPlants = 16,
    Educational = 17,
    hobbies = 18,
    Internet = 19,
    EcologyAndEnvironment = 20,
    MusicAndArts = 21,
    InformationTechnology = 22,
    DigitalEntrepreneurship = 23,
    Others = 24
}

export type TProductAffiliates = {
    id?: string;
    productId?: string;
    approveRequestManually: boolean;
    receiveEmailNotification: boolean;
    viewBuyerContact: boolean;
    exposeOnMarketplace: boolean;
    supportPhone: string;
    description: string;
    commissionPercentage: number;
    globalCommissionPercentage: number;
    category?: TProdutAffiliatesCategory;
    tenantName?: string;
    tenantDocument?: string;

    allowRegistrationThanksPages: boolean;
    enableRecoverySale: boolean;
    enableIndividualRecoverySale: boolean;
}
