import { TextField } from '@mui/material'
import React from 'react'

export interface ICurrencyFieldProps {
  label?: string
  error?: boolean
  helperText?: React.ReactNode
  value: number
  onChange: (value: number) => void
}

const CurrencyField = ({
  label,
  error,
  helperText,
  value,
  onChange,
}: ICurrencyFieldProps) => {
  const valueRef = React.useRef<HTMLInputElement>(null)

  const formatValue = (num: number) =>
    new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(num)

  const [stringValue, dispatchValue] = React.useReducer(
    (state: string, newValue: string) => {
      const [formattedWholeValue, decimalValue = '0'] = newValue.split(',')
      const significantValue = formattedWholeValue.replaceAll('.', '')
      const floatValue = parseFloat(
        significantValue + '.' + decimalValue.slice(0, 2)
      )
      if (isNaN(floatValue) === false) {
        onChange(floatValue)
        let n = formatValue(floatValue)
        if (newValue.includes(',') && !n.includes(',')) {
          return n + ','
        }
        return n
      }
      onChange(0)
      return '0'
    },
    formatValue(value)
  )

  return (
    <TextField
      fullWidth
      type="text"
      size="small"
      label={label}
      inputProps={{
        pattern: 'd+(,d{2})?',
        style: { textAlign: 'right' },
      }}
      InputProps={{ startAdornment: 'R$' }}
      value={stringValue}
      onChange={(e) => dispatchValue(e.target.value)}
      ref={valueRef}
      error={error}
      helperText={helperText}
    />
  )
}

export default CurrencyField
