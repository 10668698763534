import { Box, Stack } from '@mui/material'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { TenantMemberService } from '../../../settings/TenantMemberService'
import { TMemberService } from '../../../../core/types/MemberService'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import { useEffect, useState } from 'react'
import MemberServiceController from '../../../../core/controllers/MemberServiceController'
import { useForm } from 'react-hook-form'

export interface ITheMembersRegisterProps {
  open: boolean
  memberService: TMemberService
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  execute: () => Promise<void>
}

const TheMembersRegister = ({
  open,
  memberService,
  setOpen,
  execute,
}: ITheMembersRegisterProps) => {
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const isNew =
    !memberService.id || memberService.id === null || memberService.id === ''

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<TMemberService>({
    defaultValues: memberService,
  })

  const Submit = async (data: TMemberService) => {
    setSending(true)
    setSuccess(false)
    setError('')

    try {
      let response
      if (isNew) response = await MemberServiceController.insert({ data })
      else response = await MemberServiceController.update({ data })

      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        setOpen(false)
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  useEffect(() => {
    reset(memberService)
  }, [memberService])

  return (
    <Box>
      <SwipeableDrawerRightDialog
        open={open}
        onClose={() => setOpen(false)}
        setOpen={setOpen}
        title="The Members"
        subtitle={
          memberService.id === ''
            ? 'Adicione uma nova configuração para a The Members'
            : memberService.identifier
        }
        buttons={[
          {
            title: 'Cancelar',
            onClick: () => setOpen(false),
            type: 'negative',
          },
          {
            title: 'Salvar',
            type: 'positive',
            event: 'submit',
          },
        ]}
        noValidate
        component="form"
        onSubmit={handleSubmit(Submit)}
      >
        <Stack direction="column" spacing={2} sx={{ py: 1 }}>
          <TenantMemberService
            memberService={memberService}
            errors={errors}
            register={register}
          />
        </Stack>
      </SwipeableDrawerRightDialog>

      <LoadingBackdrop open={sending} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Alterado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

export default TheMembersRegister
