import { useEffect, useState } from 'react'
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import { useCurrentTenantBank } from '../../../core/hooks/useCurrentTenantBank'
import { useForm } from 'react-hook-form'
import {
  ITenant,
  TPersonType,
  TTenantRegister,
} from '../../../core/types/Tenant'
import { TenantController } from '../../../core/controllers/TenantController'
import { maskCpfCnpjNumber, maskPhoneNumber } from '../../../masks/masks'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ImageSignUpForm from '../../../components/Images/ImageSignUpForm'
import RowRadioButtonsGroup from '../../../components/RadioGroup/RowRadioButtonsGroup'
import BasicDatePicker from '../../../components/DateTime/BasicDatePicker'
import TenantBankAccountAddress from './TenantBankAccountAddress'
import Validations from '../../../core/functions/validations'
import { red } from '@mui/material/colors'
import Banks from '../../../core/data/banks'
import CurrencyField from '../../../components/Inputs/CurrencyField'
import { isValidDateTime } from '../../../core/functions/dateTime'

export interface ITenantBankAccountWidgetProps {
  tenantId: string
  tenant: ITenant | null
  reload: () => void
  onClose: () => void
}

const inidividualSteps = [
  'Cadastro',
  'Complemento do Cadastro',
  'Dados de Endereço',
  'Dados Bancário para Recebimento',
]
const corporationSteps = [
  'Cadastro',
  'Dados da Empresa',
  'Endereço da Empresa',
  'Dados do Representante Legal',
  'Endereço do Representante Legal',
  'Dados Bancário para Recebimento',
]

const accountTypes = [
  { value: 1, label: 'Corrente' },
  { value: 2, label: 'Poupança' },
]

export const TenantBankAccountWidget = ({
  tenantId,
  tenant,
  reload,
  onClose,
}: ITenantBankAccountWidgetProps) => {
  const { data, loading } = useCurrentTenantBank({ tenantId: tenantId })

  const [showAlert, setShowAlert] = useState(false)
  const [isNew, setIsNew] = useState(true)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const [activeStep, setActiveStep] = useState(0)
  const [steps, setSteps] = useState([...inidividualSteps])

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError: setErrorForm,
    clearErrors,
  } = useForm<TTenantRegister>({
    defaultValues: {
      personType: TPersonType.Individual,
      email: '',
      document: '',
      phone: '',
      address: {
        city: '',
        complement: '',
        neighborhood: '',
        number: '',
        referencePoint: '',
        state: '',
        street: '',
        zipCode: '',
      },
      name: '',
      bankAccount: {
        tenantId: tenantId,
        accountName: '',
        accountNumber: '',
        accountNumberDigit: '',
        accountType: 0,
        agencyNumber: '',
        agencyNumberCheck: null,
        bankCode: '',
      },
    },
  })

  const personTypeValue = watch('personType')
  const phoneValue = watch('phone')
  const documentValue = watch('document')
  const managingPartnerPhoneValue = watch('managingPartner.phone')
  const managingPartnerDocumentValue = watch('managingPartner.document')
  const bankDocumentValue = watch('bankAccount.recipientDocument')

  const handleChangePersonType = (value: any) => {
    const type =
      value === '1'
        ? TPersonType.Individual
        : value === '2'
          ? TPersonType.Corporation
          : value
    setValue('personType', type)
  }

  const handleChangePrevier = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    } else {
      onClose()
    }
  }

  const handleChangeNext = () => {
    clearErrors()

    switch (activeStep) {
      case 0:
        if (!isRegisterValid()) {
          setError('Corrija os erros antes de prosseguir')
          return
        }
        break
      case 1:
        if (!isRegisterComplementValid()) {
          setError('Corrija os erros antes de prosseguir')
          return
        }
        break
      case 2:
        if (!isAddressValid()) {
          setError('Corrija os erros antes de prosseguir')
          return
        }
        break
      case 3:
        if (!isManagingPartnerValid()) {
          setError('Corrija os erros antes de prosseguir')
          return
        }
        break
      case 4:
        if (!isManagingPartnerAddressValid()) {
          setError('Corrija os erros antes de prosseguir')
          return
        }
        break
    }

    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1)
    }
  }

  const isRegisterValid = () => {
    let isValid = true

    const data = getValues()
    if (data.email === '') {
      isValid = false
      setErrorForm('email', { message: 'Campo obrigatório', type: 'required' })
    } else if (!Validations.email(data.email)) {
      isValid = false
      setErrorForm('email', { message: 'E-mail inválido', type: 'email' })
    }

    if (data.phone === '') {
      isValid = false
      setErrorForm('phone', { message: 'Campo obrigatório', type: 'required' })
    }

    return isValid
  }

  const isRegisterComplementValid = () => {
    let isValid = true
    const data = getValues()

    if (data.personType === TPersonType.Individual) {
      if (data.name === '') {
        isValid = false
        setErrorForm('name', { message: 'Campo obrigatório', type: 'required' })
      }

      if (data.document === '') {
        isValid = false
        setErrorForm('document', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      } else if (!Validations.CPF(data.document)) {
        isValid = false
        setErrorForm('document', { message: 'CPF inválido', type: 'invalid' })
      }

      if (!data.birthDate) {
        isValid = false
        setErrorForm('birthDate', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      } else if (!isValidDateTime(data.birthDate)) {
        isValid = false
        setErrorForm('birthDate', {
          message: 'Data inválida',
          type: 'invalid',
        })
      }

      if (data.professionalOccupation === '') {
        isValid = false
        setErrorForm('professionalOccupation', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }

      if (!data.revenue || data.revenue === 0) {
        isValid = false
        setErrorForm('revenue', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      } else if (data.revenue > 9999999.99) {
        isValid = false
        setErrorForm('revenue', {
          message: 'O valor não pode ser maior que R$ 9.999.999,99',
          type: 'required',
        })
      }
    }

    if (data.personType === TPersonType.Corporation) {
      if (data.name === '') {
        isValid = false
        setErrorForm('name', { message: 'Campo obrigatório', type: 'required' })
      }

      if (!data.tradingName || data.tradingName === '') {
        isValid = false
        setErrorForm('tradingName', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }

      if (data.document === '') {
        isValid = false
        setErrorForm('document', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      } else if (!Validations.CNPJ(data.document)) {
        isValid = false
        setErrorForm('document', { message: 'CNPJ inválido', type: 'invalid' })
      }

      if (!data.revenue || data.revenue === 0) {
        isValid = false
        setErrorForm('revenue', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      } else if (data.revenue > 9999999.99) {
        isValid = false
        setErrorForm('revenue', {
          message: 'O valor não pode ser maior que R$ 9.999.999,99',
          type: 'required',
        })
      }
    }

    return isValid
  }

  const isAddressValid = () => {
    let isValid = true
    const data = getValues()

    if (data.address.zipCode === '') {
      isValid = false
      setErrorForm('address.zipCode', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.address.street || data.address.street === '') {
      isValid = false
      setErrorForm('address.street', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.address.number || data.address.number === '') {
      isValid = false
      setErrorForm('address.number', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.address.complement || data.address.complement === '') {
      isValid = false
      setErrorForm('address.complement', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.address.neighborhood || data.address.neighborhood === '') {
      isValid = false
      setErrorForm('address.neighborhood', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.address.city || data.address.city === '') {
      isValid = false
      setErrorForm('address.city', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.address.state || data.address.state === '') {
      isValid = false
      setErrorForm('address.state', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    if (!data.address.referencePoint || data.address.referencePoint === '') {
      isValid = false
      setErrorForm('address.referencePoint', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    }

    return isValid
  }

  const isManagingPartnerValid = () => {
    let isValid = true
    const data = getValues()

    if (data.personType === TPersonType.Corporation) {
      if ((data.managingPartner?.name ?? '') === '') {
        isValid = false
        setErrorForm('managingPartner.name', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }

      if ((data.managingPartner?.email ?? '') === '') {
        isValid = false
        setErrorForm('managingPartner.email', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }

      if (data.managingPartner?.document === '') {
        isValid = false
        setErrorForm('managingPartner.document', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      } else if (!Validations.CPF(data.managingPartner?.document)) {
        isValid = false
        setErrorForm('managingPartner.document', {
          message: 'CPF inválido',
          type: 'invalid',
        })
      }

      if ((data.managingPartner?.phone ?? '') === '') {
        isValid = false
        setErrorForm('managingPartner.phone', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }

      if (!data.managingPartner?.birthDate) {
        isValid = false
        setErrorForm('managingPartner.birthDate', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      } else if (!isValidDateTime(data.managingPartner?.birthDate)) {
        isValid = false
        setErrorForm('managingPartner.birthDate', {
          message: 'Data inválida',
          type: 'invalid',
        })
      }

      if (
        !data.managingPartner?.monthyIncome ||
        data.managingPartner?.monthyIncome === 0
      ) {
        isValid = false
        setErrorForm('managingPartner.monthyIncome', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      } else if (data.managingPartner?.monthyIncome > 9999999.99) {
        isValid = false
        setErrorForm('managingPartner.monthyIncome', {
          message: 'O valor não pode ser maior que R$ 9.999.999,99',
          type: 'required',
        })
      }

      if ((data.managingPartner?.professionalOccupation ?? '') === '') {
        isValid = false
        setErrorForm('managingPartner.professionalOccupation', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }
    }

    return isValid
  }

  const isManagingPartnerAddressValid = () => {
    let isValid = true
    const data = getValues()

    if (personTypeValue === TPersonType.Corporation) {
      if ((data.managingPartner?.address?.zipCode ?? '') === '') {
        isValid = false
        setErrorForm('managingPartner.address.zipCode', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }

      if (
        !data.managingPartner?.address?.street ||
        data.managingPartner?.address?.street === ''
      ) {
        isValid = false
        setErrorForm('managingPartner.address.street', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }

      if (
        !data.managingPartner?.address?.number ||
        data.managingPartner?.address?.number === ''
      ) {
        isValid = false
        setErrorForm('managingPartner.address.number', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }

      if (
        !data.managingPartner?.address?.complement ||
        data.managingPartner?.address?.complement === ''
      ) {
        isValid = false
        setErrorForm('managingPartner.address.complement', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }

      if (
        !data.managingPartner?.address?.neighborhood ||
        data.managingPartner?.address?.neighborhood === ''
      ) {
        isValid = false
        setErrorForm('managingPartner.address.neighborhood', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }

      if (
        !data.managingPartner?.address?.city ||
        data.managingPartner?.address?.city === ''
      ) {
        isValid = false
        setErrorForm('managingPartner.address.city', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }

      if (
        !data.managingPartner?.address?.state ||
        data.managingPartner?.address?.state === ''
      ) {
        isValid = false
        setErrorForm('managingPartner.address.state', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }

      if (
        !data.managingPartner?.address?.referencePoint ||
        data.managingPartner?.address?.referencePoint === ''
      ) {
        isValid = false
        setErrorForm('managingPartner.address.referencePoint', {
          message: 'Campo obrigatório',
          type: 'required',
        })
      }
    }

    return isValid
  }

  useEffect(() => {
    if (data !== null) {
      setValue('bankAccount', data)
      setIsNew(false)
    }

    return () => {
      if (data !== null) {
        setValue('bankAccount', data)
      }
    }
  }, [data, setValue, setIsNew])

  useEffect(() => {
    if (personTypeValue === TPersonType.Individual) {
      setSteps(inidividualSteps)
    } else {
      setSteps(corporationSteps)
    }
  }, [personTypeValue])

  useEffect(() => {
    if (tenant) {
      setValue('name', tenant?.name ?? '')
      setValue('email', tenant?.email ?? '')
    }
  }, [tenant])

  useEffect(
    () => setValue('document', maskCpfCnpjNumber(documentValue)),
    [documentValue, setValue]
  )

  useEffect(
    () => setValue('phone', maskPhoneNumber(phoneValue)),
    [phoneValue, setValue]
  )

  useEffect(
    () =>
      setValue(
        'managingPartner.document',
        maskCpfCnpjNumber(managingPartnerDocumentValue)
      ),
    [managingPartnerDocumentValue, setValue]
  )

  useEffect(
    () =>
      setValue(
        'managingPartner.phone',
        maskPhoneNumber(managingPartnerPhoneValue)
      ),
    [managingPartnerPhoneValue, setValue]
  )

  useEffect(
    () =>
      setValue(
        'bankAccount.recipientDocument',
        maskCpfCnpjNumber(bankDocumentValue)
      ),
    [bankDocumentValue, setValue]
  )

  const Submit = async (data: TTenantRegister) => {
    setSending(true)
    setSuccess(false)
    setError('')

    data.bankAccount.recipientDocument =
      data.bankAccount.recipientDocument.replace(/[^0-9]/g, '')
    if (!data.bankAccount.agencyNumberCheck)
      data.bankAccount.agencyNumberCheck = -1

    try {
      if (tenant) {
        tenant.name = data.bankAccount.accountName
        // await TenantController.update({ data: tenant })
      }

      let response
      if (isNew) {
        response = await TenantController.insertBank({
          data,
        })
      } else {
        response = await TenantController.updateBank({
          data,
        })
      }

      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        reload()
        onClose()
      }
    } finally {
      setSending(false)
    }
  }

  return (
    <Box>
      <Box sx={{ width: '100%', margin: '0 auto' }}>
        <Box>
          <ImageSignUpForm />
          <Typography variant="h5" textAlign="center" fontWeight={500}>
            Conclua o seu cadastro
          </Typography>
          <Typography variant="body1" textAlign="center" gutterBottom>
            Informe os dados da sua conta para começar a vender.
          </Typography>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            sx={{ mt: 4 }}
            width="100%"
          >
            <Box width="100%">
              <Stepper orientation="vertical" activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      optional={
                        index === steps.length - 1 ? (
                          <Typography variant="caption">
                            Está quase lá
                          </Typography>
                        ) : null
                      }
                    >
                      {label}
                    </StepLabel>
                    <StepContent>
                      <Stack
                        component="form"
                        noValidate
                        direction="column"
                        sx={{ width: '100%' }}
                        onSubmit={handleSubmit(Submit)}
                      >
                        {activeStep === 0 && (
                          <Box width="100%">
                            <Typography gutterBottom variant="body1">
                              Qual o seu tipo de cadastro?
                            </Typography>

                            <RowRadioButtonsGroup
                              value={Number(personTypeValue)}
                              onChange={handleChangePersonType}
                              items={[
                                {
                                  label: 'Pessoa Física',
                                  value: Number(TPersonType.Individual),
                                },
                                {
                                  label: 'Pessoa Jurídica',
                                  value: Number(TPersonType.Corporation),
                                },
                              ]}
                            />

                            <TextField
                              size="small"
                              fullWidth
                              margin="normal"
                              id="email"
                              label="E-mail"
                              type="email"
                              {...register('email')}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                style: { textTransform: 'lowercase' },
                              }}
                              aria-readonly
                              error={
                                (errors.email?.type ?? '') !== '' &&
                                (errors.email?.message ?? '') !== ''
                              }
                              helperText={
                                errors.email?.type &&
                                errors.email.message && (
                                  <Typography variant="overline" color={'red'}>
                                    {errors.email?.message}
                                  </Typography>
                                )
                              }
                            />

                            <TextField
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              size="small"
                              label="Telefone"
                              type="text"
                              {...register('phone')}
                              placeholder="(99) 99999-9999"
                              inputProps={{
                                inputMode: 'numeric',
                                maxLength: 15,
                              }}
                              error={
                                (errors.phone?.type ?? '') !== '' &&
                                (errors.phone?.message ?? '') !== ''
                              }
                              helperText={
                                errors.phone?.type &&
                                errors.phone.message && (
                                  <Typography variant="overline" color={'red'}>
                                    {errors.phone?.message}
                                  </Typography>
                                )
                              }
                            />
                          </Box>
                        )}

                        {activeStep === 1 &&
                          personTypeValue === TPersonType.Individual && (
                            <Box width="100%">
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                size="small"
                                label="Nome"
                                type="text"
                                {...register('name')}
                                InputLabelProps={{ shrink: true }}
                                error={
                                  (errors.name?.type ?? '') !== '' &&
                                  (errors.name?.message ?? '') !== ''
                                }
                                helperText={
                                  errors.name?.type &&
                                  errors.name.message && (
                                    <Typography
                                      variant="overline"
                                      color={'red'}
                                    >
                                      {errors.name?.message}
                                    </Typography>
                                  )
                                }
                              />

                              <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={2}
                                sx={{ pt: 2, pb: 1 }}
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  label="CPF"
                                  type="text"
                                  {...register('document')}
                                  error={
                                    (errors.document?.type ?? '') !== '' &&
                                    (errors.document?.message ?? '') !== ''
                                  }
                                  helperText={
                                    errors.document?.type &&
                                    errors.document.message && (
                                      <Typography
                                        variant="overline"
                                        color={'red'}
                                      >
                                        {errors.document?.message}
                                      </Typography>
                                    )
                                  }
                                />

                                <Box width="100%">
                                  <BasicDatePicker
                                    fullWidth
                                    label="Data de Nascimento"
                                    value={watch('birthDate')}
                                    setValue={(e) => {
                                      if (e) {
                                        setValue('birthDate', e)
                                      }
                                    }}
                                  />
                                  {errors.birthDate?.type &&
                                    errors.birthDate.message && (
                                      <Typography
                                        variant="overline"
                                        color={'red'}
                                      >
                                        {errors.birthDate?.message}
                                      </Typography>
                                    )}
                                </Box>
                              </Stack>

                              <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={2}
                                sx={{ pt: 2, pb: 1 }}
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  label="Ocupação profissional"
                                  type="text"
                                  {...register('professionalOccupation')}
                                  error={
                                    (errors.professionalOccupation?.type ??
                                      '') !== '' &&
                                    (errors.professionalOccupation?.message ??
                                      '') !== ''
                                  }
                                  helperText={
                                    errors.professionalOccupation?.type &&
                                    errors.professionalOccupation.message && (
                                      <Typography
                                        variant="overline"
                                        color={'red'}
                                      >
                                        {errors.professionalOccupation?.message}
                                      </Typography>
                                    )
                                  }
                                />

                                <CurrencyField
                                  label="Renda Mensal"
                                  value={watch('revenue') ?? 0}
                                  onChange={(value) =>
                                    setValue('revenue', value)
                                  }
                                  error={
                                    (errors.revenue?.type ?? '') !== '' &&
                                    (errors.revenue?.message ?? '') !== ''
                                  }
                                  helperText={
                                    errors.revenue?.type &&
                                    errors.revenue.message && (
                                      <Typography
                                        variant="overline"
                                        color={'red'}
                                      >
                                        {errors.revenue?.message}
                                      </Typography>
                                    )
                                  }
                                />
                              </Stack>
                            </Box>
                          )}

                        {activeStep === 1 &&
                          personTypeValue === TPersonType.Corporation && (
                            <Box width="100%">
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                size="small"
                                label="Nome Fantasia"
                                type="text"
                                {...register('name')}
                                InputLabelProps={{ shrink: true }}
                                error={
                                  (errors.name?.type ?? '') !== '' &&
                                  (errors.name?.message ?? '') !== ''
                                }
                                helperText={
                                  errors.name?.type &&
                                  errors.name.message && (
                                    <Typography
                                      variant="overline"
                                      color={'red'}
                                    >
                                      {errors.name?.message}
                                    </Typography>
                                  )
                                }
                              />

                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                size="small"
                                label="Razão Social"
                                type="text"
                                {...register('tradingName')}
                                error={
                                  (errors.tradingName?.type ?? '') !== '' &&
                                  (errors.tradingName?.message ?? '') !== ''
                                }
                                helperText={
                                  errors.tradingName?.type &&
                                  errors.tradingName.message && (
                                    <Typography
                                      variant="overline"
                                      color={'red'}
                                    >
                                      {errors.tradingName?.message}
                                    </Typography>
                                  )
                                }
                              />

                              <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={2}
                                sx={{ pt: 2, pb: 1 }}
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  margin="normal"
                                  size="small"
                                  label="CNPJ"
                                  type="text"
                                  {...register('document')}
                                  error={
                                    (errors.document?.type ?? '') !== '' &&
                                    (errors.document?.message ?? '') !== ''
                                  }
                                  helperText={
                                    errors.document?.type &&
                                    errors.document.message && (
                                      <Typography
                                        variant="overline"
                                        color={'red'}
                                      >
                                        {errors.document?.message}
                                      </Typography>
                                    )
                                  }
                                />

                                <CurrencyField
                                  label="Receita Anual da Empresa"
                                  value={watch('revenue') ?? 0}
                                  onChange={(value) =>
                                    setValue('revenue', value)
                                  }
                                  error={
                                    (errors.revenue?.type ?? '') !== '' &&
                                    (errors.revenue?.message ?? '') !== ''
                                  }
                                  helperText={
                                    errors.revenue?.type &&
                                    errors.revenue.message && (
                                      <Typography
                                        variant="overline"
                                        color={'red'}
                                      >
                                        {errors.revenue?.message}
                                      </Typography>
                                    )
                                  }
                                />
                              </Stack>
                            </Box>
                          )}

                        {activeStep === 2 && (
                          <TenantBankAccountAddress
                            control={control}
                            addressName="address"
                            errors={errors}
                            setValue={setValue}
                            watch={watch}
                          />
                        )}

                        {activeStep === 3 &&
                          personTypeValue === TPersonType.Corporation && (
                            <Box>
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                size="small"
                                label="Nome"
                                type="text"
                                {...register('managingPartner.name')}
                                error={
                                  (errors.managingPartner?.name?.type ?? '') !==
                                    '' &&
                                  (errors.managingPartner?.name?.message ??
                                    '') !== ''
                                }
                                helperText={
                                  errors.managingPartner?.name?.type &&
                                  errors.managingPartner?.name.message && (
                                    <Typography
                                      variant="overline"
                                      color={'red'}
                                    >
                                      {errors.managingPartner?.name?.message}
                                    </Typography>
                                  )
                                }
                              />
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                size="small"
                                label="Email"
                                type="text"
                                {...register('managingPartner.email')}
                                error={
                                  (errors.managingPartner?.email?.type ??
                                    '') !== '' &&
                                  (errors.managingPartner?.email?.message ??
                                    '') !== ''
                                }
                                helperText={
                                  errors.managingPartner?.email?.type &&
                                  errors.managingPartner?.email.message && (
                                    <Typography
                                      variant="overline"
                                      color={'red'}
                                    >
                                      {errors.managingPartner?.email?.message}
                                    </Typography>
                                  )
                                }
                              />

                              <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={2}
                                sx={{ pt: 2, pb: 1 }}
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  label="CPF"
                                  type="text"
                                  {...register('managingPartner.document')}
                                  error={
                                    (errors.managingPartner?.document?.type ??
                                      '') !== '' &&
                                    (errors.managingPartner?.document
                                      ?.message ?? '') !== ''
                                  }
                                  helperText={
                                    errors.managingPartner?.document?.type &&
                                    errors.managingPartner?.document
                                      .message && (
                                      <Typography
                                        variant="overline"
                                        color={'red'}
                                      >
                                        {
                                          errors.managingPartner?.document
                                            ?.message
                                        }
                                      </Typography>
                                    )
                                  }
                                />

                                <Box width="100%">
                                  <BasicDatePicker
                                    fullWidth
                                    label="Data de Nascimento"
                                    value={watch('managingPartner.birthDate')}
                                    setValue={(e) => {
                                      if (e) {
                                        setValue('managingPartner.birthDate', e)
                                      }
                                    }}
                                  />
                                  {errors.managingPartner?.birthDate?.type &&
                                    errors.managingPartner?.birthDate
                                      ?.message && (
                                      <Typography
                                        variant="overline"
                                        color={'red'}
                                      >
                                        {
                                          errors.managingPartner?.birthDate
                                            ?.message
                                        }
                                      </Typography>
                                    )}
                                </Box>
                              </Stack>

                              <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={2}
                                sx={{ pt: 2, pb: 1 }}
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  label="Telefone"
                                  type="text"
                                  placeholder="(99) 99999-9999"
                                  inputProps={{
                                    inputMode: 'numeric',
                                    maxLength: 15,
                                  }}
                                  {...register('managingPartner.phone')}
                                  error={
                                    (errors.managingPartner?.phone?.type ??
                                      '') !== '' &&
                                    (errors.managingPartner?.phone?.message ??
                                      '') !== ''
                                  }
                                  helperText={
                                    errors.managingPartner?.phone?.type &&
                                    errors.managingPartner?.phone.message && (
                                      <Typography
                                        variant="overline"
                                        color={'red'}
                                      >
                                        {errors.managingPartner?.phone?.message}
                                      </Typography>
                                    )
                                  }
                                />

                                <CurrencyField
                                  label="Renda Mensal"
                                  value={
                                    watch('managingPartner.monthyIncome') ?? 0
                                  }
                                  onChange={(value) =>
                                    setValue(
                                      'managingPartner.monthyIncome',
                                      value
                                    )
                                  }
                                  error={
                                    (errors.managingPartner?.monthyIncome
                                      ?.type ?? '') !== '' &&
                                    (errors.managingPartner?.monthyIncome
                                      ?.message ?? '') !== ''
                                  }
                                  helperText={
                                    errors.managingPartner?.monthyIncome
                                      ?.type &&
                                    errors.managingPartner?.monthyIncome
                                      .message && (
                                      <Typography
                                        variant="overline"
                                        color={'red'}
                                      >
                                        {
                                          errors.managingPartner?.monthyIncome
                                            ?.message
                                        }
                                      </Typography>
                                    )
                                  }
                                />
                              </Stack>

                              <TextField
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                size="small"
                                label="Ocupação profissional"
                                type="text"
                                {...register(
                                  'managingPartner.professionalOccupation'
                                )}
                                error={
                                  (errors.managingPartner
                                    ?.professionalOccupation?.type ?? '') !==
                                    '' &&
                                  (errors.managingPartner
                                    ?.professionalOccupation?.message ?? '') !==
                                    ''
                                }
                                helperText={
                                  errors.managingPartner?.professionalOccupation
                                    ?.type &&
                                  errors.managingPartner?.professionalOccupation
                                    .message && (
                                    <Typography
                                      variant="overline"
                                      color={'red'}
                                    >
                                      {
                                        errors.managingPartner
                                          ?.professionalOccupation?.message
                                      }
                                    </Typography>
                                  )
                                }
                              />
                            </Box>
                          )}

                        {activeStep === 4 &&
                          personTypeValue === TPersonType.Corporation && (
                            <TenantBankAccountAddress
                              control={control}
                              addressName="managingPartner.address"
                              errors={errors}
                              setValue={setValue}
                              watch={watch}
                            />
                          )}

                        {activeStep === steps.length - 1 && (
                          <Stack direction="column" spacing={2}>
                            <Box>
                              <TextField
                                disabled={sending}
                                onFocus={(e) => setShowAlert(true)}
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="text"
                                label={'CPF / CNPJ'}
                                {...register('bankAccount.recipientDocument', {
                                  required: {
                                    value: true,
                                    message: 'Este campo é obrigatório',
                                  },
                                  validate: (value, formValues) =>
                                    Validations.CPF_CNPJ(value) ||
                                    'CPF / CNPJ inválido',
                                })}
                                error={
                                  (errors.bankAccount?.recipientDocument
                                    ?.message ?? '') !== ''
                                }
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  maxLength: 18,
                                  inputMode: 'numeric',
                                }}
                              />
                              {errors.bankAccount?.recipientDocument
                                ?.message && (
                                <Typography variant="caption" color={red[700]}>
                                  {
                                    errors.bankAccount?.recipientDocument
                                      ?.message
                                  }
                                </Typography>
                              )}
                              <Collapse in={showAlert}>
                                <Alert severity="warning" sx={{ mt: 1 }}>
                                  <AlertTitle>Atenção</AlertTitle>O CPF / CNPJ
                                  informado deve ser o mesmo da{' '}
                                  <strong>conta bancária</strong> que será
                                  utilizada para realizar os saques. <br />A
                                  alteração de CPF / CNPJ só poderá ser
                                  realizada através da equipe de suporte.
                                </Alert>
                              </Collapse>
                            </Box>
                            <Box>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="text"
                                label="Nome Completo / Razão Social"
                                {...register('bankAccount.accountName', {
                                  required: true,
                                })}
                                error={
                                  errors.bankAccount?.accountName?.type ===
                                  'required'
                                }
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 30 }}
                              />
                              {errors.bankAccount?.accountName?.type ===
                                'required' && (
                                <Typography variant="caption" color={red[700]}>
                                  Este campo é obrigatório
                                </Typography>
                              )}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                              <FormControl fullWidth size="small">
                                <Autocomplete
                                  disablePortal
                                  size="small"
                                  options={Banks}
                                  getOptionLabel={(option) =>
                                    `${option.value} - ${option.label}`
                                  }
                                  onChange={(event, option) => {
                                    if (option && option !== null) {
                                      setValue(
                                        'bankAccount.bankCode',
                                        option.value
                                      )
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Banco" />
                                  )}
                                />
                              </FormControl>
                              {errors.bankAccount?.bankCode?.type ===
                                'required' && (
                                <Typography variant="caption" color={red[700]}>
                                  Este campo é obrigatório
                                </Typography>
                              )}
                            </Box>
                            <Stack direction={'row'} spacing={2}>
                              <FormControl fullWidth>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  label="Agência"
                                  {...register('bankAccount.agencyNumber', {
                                    required: true,
                                  })}
                                  error={
                                    errors.bankAccount?.agencyNumber?.type ===
                                    'required'
                                  }
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{
                                    max: 9999,
                                    maxLength: 4,
                                    inputMode: 'numeric',
                                  }}
                                />
                                {errors.bankAccount?.agencyNumber?.type ===
                                  'required' && (
                                  <Typography
                                    variant="caption"
                                    color={red[700]}
                                  >
                                    Este campo é obrigatório
                                  </Typography>
                                )}
                              </FormControl>
                              <TextField
                                variant="outlined"
                                size="small"
                                type="text"
                                label="Dígito"
                                {...register('bankAccount.agencyNumberCheck')}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  max: 9,
                                  maxLength: 1,
                                  inputMode: 'numeric',
                                }}
                                sx={{ width: '30%' }}
                              />
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                              <FormControl fullWidth>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  label="Conta"
                                  {...register('bankAccount.accountNumber', {
                                    required: true,
                                  })}
                                  error={
                                    errors.bankAccount?.accountNumber?.type ===
                                    'required'
                                  }
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{
                                    maxLength: 13,
                                    inputMode: 'numeric',
                                  }}
                                />
                                {errors.bankAccount?.accountNumber?.type ===
                                  'required' && (
                                  <Typography
                                    variant="caption"
                                    color={red[700]}
                                  >
                                    Este campo é obrigatório
                                  </Typography>
                                )}
                              </FormControl>
                              <Stack direction="column" sx={{ width: '30%' }}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  label="Dígito"
                                  {...register(
                                    'bankAccount.accountNumberDigit',
                                    {
                                      required: true,
                                    }
                                  )}
                                  error={
                                    errors.bankAccount?.accountNumberDigit
                                      ?.type === 'required'
                                  }
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{
                                    max: 99,
                                    maxLength: 2,
                                    inputMode: 'numeric',
                                  }}
                                />
                                {errors.bankAccount?.accountNumberDigit
                                  ?.type === 'required' && (
                                  <Typography
                                    variant="caption"
                                    color={red[700]}
                                  >
                                    Obrigatório
                                  </Typography>
                                )}
                              </Stack>
                            </Stack>
                            <Box sx={{ width: '100%' }}>
                              <FormControl fullWidth size="small">
                                <InputLabel id="accountType-label">
                                  Tipo da conta
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  labelId="accountType-label"
                                  label="Tipo da conta"
                                  value={watch('bankAccount.accountType')}
                                  {...register('bankAccount.accountType', {
                                    required: true,
                                  })}
                                  error={
                                    errors.bankAccount?.accountType?.type ===
                                    'required'
                                  }
                                >
                                  {accountTypes.map((types) => (
                                    <MenuItem
                                      key={'ty_' + types.value}
                                      value={types.value}
                                    >
                                      {types.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {errors.bankAccount?.accountType?.type ===
                                'required' && (
                                <Typography variant="caption" color={red[700]}>
                                  Este campo é obrigatório
                                </Typography>
                              )}
                            </Box>
                          </Stack>
                        )}

                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={2}
                          sx={{ mt: 2, width: '100%' }}
                        >
                          <Button
                            onClick={handleChangePrevier}
                            variant="outlined"
                            color="primary"
                          >
                            {activeStep === 0 ? 'Cancelar' : 'Voltar'}
                          </Button>
                          {activeStep < steps.length - 1 && (
                            <Button
                              type="button"
                              onClick={handleChangeNext}
                              variant="contained"
                              color="primary"
                            >
                              Próximo
                            </Button>
                          )}
                          {activeStep === steps.length - 1 && (
                            <Button
                              type="submit"
                              onClick={handleChangeNext}
                              variant="contained"
                              color="primary"
                            >
                              Finalizar
                            </Button>
                          )}
                        </Stack>
                      </Stack>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Stack>
        </Box>
      </Box>

      <LoadingBackdrop open={loading || sending} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Alterado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}
