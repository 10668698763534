import { SxProps, Theme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

export interface DatePickerFilterProps {
    label: string;
    value?: Date | null | undefined;
    sx?: SxProps<Theme> | undefined;
    setDate: (newValue: any) => void;
}

export const DatePickerFilter = ({ label, value, sx, setDate }: DatePickerFilterProps) => {
    const oldValue = value ? dayjs(value).locale("pt-br") : dayjs(Date.now()).locale("pt-br");
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <DatePicker
                label={label}
                value={oldValue}
                onChange={(newValue) => setDate(newValue)}
                sx={sx}
                slotProps={{ textField: { size: "small" } }}
            />
        </LocalizationProvider>
    );
};
