import { useContext } from "react";
import MainLayout from "../../layouts/main.layout";
import { setAuthorization } from "../../services/api";
import { AuthContext } from "../../core/context/AuthContext";
import { useJwt } from "react-jwt";
import { Navigate } from "react-router-dom";

const AdminProtected = () => {
    const { user, setUser } = useContext(AuthContext);
    const { isExpired } = useJwt(user?.token ?? '');

    if (isExpired) {
        setUser(null);
    }

    if (user === null) {
        return <Navigate to={`/?redirect=${window.location.pathname}`} replace />;
    }

    setAuthorization(user.token);

    return <MainLayout />;
};

export default AdminProtected;