import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";

export interface IMarketPlaceCardProp {
    title: string;
    subtitle?: string;
    text?: string;
    imageUrl?: string;
    onClick?: () => void;
}

export default function MarketplaceCard({ title, subtitle, text, imageUrl, onClick }: IMarketPlaceCardProp) {
    return (
        <Card
            elevation={0}
            sx={{
                maxWidth: 365,
                borderRadius: 4,
                boxShadow: "16px 16px 16px #EEEEEE",
            }}
        >
            <CardActionArea sx={{ height: 460 }} onClick={onClick}>
                <CardMedia
                    component="img"
                    image={!imageUrl || imageUrl === "" ? `${process.env.PUBLIC_URL}/assets/logo/icon.svg` : undefined}
                    src={imageUrl}
                    alt={title}
                    sx={{ height: 330 }}
                />
                <CardContent sx={{ height: 130 }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            height: 100,
                        }}
                    >
                        <Typography gutterBottom variant="subtitle2" component="div" sx={{ flexGrow: 0 }}>
                            {title}
                        </Typography>
                        {subtitle && (
                            <Typography
                                gutterBottom
                                variant="body2"
                                color="text.secondary"
                                component="div"
                                sx={{ flexGrow: 1 }}
                            >
                                {subtitle}
                            </Typography>
                        )}
                        {text && (
                            <Typography variant="body2" sx={{ flexGrow: 0 }}>
                                {text}
                            </Typography>
                        )}
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
