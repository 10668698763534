import {
  Box,
  DialogActions,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material'
import SwipeableDrawerTitle from './SwipeableDrawerTitle'
import { ISwipeableDrawerRightDialogProps } from './SwipeableDrawerRightDialog'
import DialogActionButton from './DialogActions/DialogActionButton'
import StackOrForm from '../Divs/StackOrForm'

const SwipeableDrawerRightDialogMobile = ({
  open,
  title,
  subtitle,
  setOpen,
  onClose,
  children,
  backgroundColor,
  buttons,
  buttonsDisabled,
  component,
  noValidate,
  onSubmit,
}: ISwipeableDrawerRightDialogProps) => {
  return (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      onClose={onClose}
      onOpen={() => setOpen(true)}
      PaperProps={{
        sx: {
          backgroundColor: backgroundColor ?? 'auto',
          p: 1,
        },
      }}
    >
      <StackOrForm
        component={component}
        noValidate={noValidate}
        onSubmit={onSubmit}
      >
        <Stack
          direction="column"
          sx={{
            height: '10%',
          }}
        >
          <SwipeableDrawerTitle
            setOpen={setOpen}
            title={title}
            subtitle={
              subtitle ? (
                <Typography variant="caption" sx={{ flexGrow: 1 }}>
                  {subtitle}
                </Typography>
              ) : undefined
            }
          />
          <Box
            sx={{
              width: '100%',
              overflowY: 'scroll',

              height: '100%',
            }}
          >
            <Stack>
              {children}
              <Box sx={{ height: 20 }} />
            </Stack>
          </Box>
          {buttons && (
            <DialogActions>
              {buttons.map((button) => (
                <DialogActionButton
                  fullWidth
                  button={button}
                  disabled={buttonsDisabled}
                />
              ))}
            </DialogActions>
          )}
        </Stack>
      </StackOrForm>
    </SwipeableDrawer>
  )
}

export default SwipeableDrawerRightDialogMobile
