import { NavigateFunction } from "react-router-dom";

export const baseUrl = `${window.location.protocol}//${window.location.host}`;

export const payUrl = process.env.REACT_APP_PAY_HOST;

export const goUrl = process.env.REACT_APP_GO_HOST;

const getUrlRedirect = (url: string): string => {
    if (!url.trim().toLowerCase().startsWith('http')) {
        url = `https://${url}`;
    }
    return url;
}

const redirect = (urlRedirect?: string, urlDefault?: string, navigateDefault?: NavigateFunction) => {
    if (!urlRedirect || urlRedirect.trim() === '') {
        if (urlDefault && urlDefault.trim() !== '') {
            if (navigateDefault) {
                navigateDefault(urlDefault);
            } else {
                window.location.href = getUrlRedirect(urlDefault);
            }
        }
    } else {
        window.location.href = getUrlRedirect(urlRedirect);
    }
}

const openNewBlank = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
}

const openNewBlankCustomCheckout = (id: string) => {
    openNewBlank(`${process.env.REACT_APP_BUILDER_HOST}/customcheckout/${id}`);
}

const returnCodeString = (code?: string) => {
    if (!code) {
        return "";
    }

    if(code === "404") {
        return '404 Servidor ou método não encontrado';
    }
    if (code === "0") {
        return `Sem Resposta`;
    } else if (code.startsWith("1")) {
        return `${code} Informação`;
    }
    if (code.startsWith("2")) {
        return `${code} Sucesso`;
    }
    if (code.startsWith("3")) {
        return `${code} Redirecionamento`;
    }
    if (code.startsWith("4")) {
        return `${code} Erro do Cliente`;
    }
    if (code.startsWith("5")) {
        return `${code} Erro do Servidor`;
    }

    return code;
}

const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
        //throw 'not clipboard';
    }
}

const refreshPage = () => {
    window.location.reload();
}

const Hosts = {
    redirect,
    openNewBlank,
    returnCodeString,
    refreshPage,
    copyTextToClipboard,
    openNewBlankCustomCheckout,
}

export default Hosts;