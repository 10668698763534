export enum TIndicatedComissionLevelEnum {
    None = 0,       // Nenhuma indicação 
    Basic = 1,      // 5%
    Gold = 2,       // 10%
    Platinum = 3    // 15%
}

export type TIndicatedTenant = {
    id: string;
    tenantId: string;
    indicationOfId: string;
    receiveComissionDate: Date;
    indicatedComissionsPercentage: number;
}

export type TUpdateIndicatedTenant = {
    tenantId: string;
    indicatedComissionLevelEnum: TIndicatedComissionLevelEnum;
    comissionsPercentage: number;
    receiveComissionUntilDateOf: Date;
}

export type TEnableIndicatedTenant = {
    tenantId: string;
    indicationOfId: string;
}

export type TEnableOrDisableIndicatedTenant = {
    tenantId: string;
    indicationOfId: string;
    value: boolean;
}

export type TIndicatedTenantResult = {
    indicatedTenantId: string;
    name: string;
    receiveComissionUntilDateOf: Date;
    comissionsPercentage: number;
    comissionLevel: TIndicatedComissionLevelEnum;
    totalComissions: number;
    totalSales: number;

}

export type TIndicatedTenantPagination = {
    items: TIndicatedTenantResult[];
    page: number;
    rowsPerPage: number;
    totalPages: number;
    total: number;
    info?: number;
}

export interface IIndicatedTenantPaginationProp {
    page: number;
    rows: number;
    search?: string;
    startDate?: Date | null | undefined;
    endDate?: Date | null | undefined;
}

export type TIndicatedTenantItem = {
    id: string;
    tenantId: string;
    indicationOfId: string;
    name: string;
    email: string;
    indicatedComissionLevelEnum: TIndicatedComissionLevelEnum;
    receiveComissionUntilDateOf: Date;
    indicatedComissionsPercentage: number;
    isEnableCommissions: boolean;
}