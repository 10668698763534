import { Box, Button, Slide, Stack, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import SalesFunnelStepNewEdit from './SalesFunnelStepNewEdit'
import {
  TFunnelStepError,
  TSalesFunnelStep,
} from '../../../core/types/SalesFunnel'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import { useSaleFunnel } from '../../../core/hooks/useSaleFunnel'
import SalesFunnelController from '../../../core/controllers/SalesFunnelController'
import Validations from '../../../core/functions/validations'
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import ContentDialog from '../../../components/Dialogs/ContentDialog'
import { CopyBlock, solarizedDark } from 'react-code-blocks'
import CopiedSnackbar from '../../../components/Snackbar/CopiedSnackbar'
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  Node,
  MarkerType,
} from 'reactflow'
import TextUpdaterNode from '../../../components/Sales/Funnel/Steps/SalesFunnelStepFlowItem'
import SalesFunnelStepItem from '../../../components/Sales/Funnel/Steps/SalesFunnelStepItem'

import './SalesFunnelSteps.css'
import '../../../components/Sales/Funnel/Steps/SalesFunnelStepFlowItem.css'

const nodeTypes = { textUpdater: TextUpdaterNode }

const markerEndDefault = {
  markerEnd: {
    type: MarkerType.Arrow,
    width: 20,
    height: 20,
    color: '#476ADA',
  },
  style: {
    strokeWidth: 2,
    stroke: '#476ADA',
    paddingRight: '20px',
  },
}

const SalesFunnelStepsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const salesFunnelStepDefault: TSalesFunnelStep = {
    id: '',
    salesFunnelId: id ?? '',
    funnelStepId: '',
    isInitial: true,
    positiveNextStepId: null,
    negativeNextStepId: null,
    funnelStep: {
      id: '',
      name: '',
      isThankYouPage: false,
      productId: null,
      productName: '',
      productPriceId: null,
      pageUrl: '',
      positiveButtonLabel: '',
      negativeButtonLabel: '',
      hasCountdown: false,
      countdownSeconds: 0,
      countdownTitle: '',
    },
  }

  const [animationState, setAnimationState] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openCode, setOpenCode] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [currentPositiveButton, setCurrentPositiveButton] = useState(true)
  const [copied, setCopied] = useState(false)

  const [error, setError] = useState('')

  const [current, setCurrent] = useState<TSalesFunnelStep>({
    ...salesFunnelStepDefault,
  })
  const [currentParentId, setCurrentParentId] = useState<string | undefined>(
    undefined
  )
  const [errors, setErrors] = useState<TFunnelStepError>({})

  const { data, loading, execute } = useSaleFunnel({ id: id ?? '' })

  const currentNode = useRef<Node<any, string | undefined>[]>([])

  const handleChangeItem = (
    newStep: TSalesFunnelStep,
    positiveButton: boolean,
    parentStepId?: string
  ) => {
    setErrors({})
    setCurrentParentId(parentStepId)
    setCurrentPositiveButton(positiveButton)
    setCurrent(newStep)
    setOpen(true)
  }
  const handleChangeEditItem = (salesFunnelStepId: string) => {
    const step = data?.steps.find((step) => step.id === salesFunnelStepId)
    if (step) {
      const positive = data?.steps.find((s) => s.positiveNextStepId === step.id)
      if (positive) {
        handleChangeItem(step, true, positive.id)
      } else {
        const negative = data?.steps.find(
          (s) => s.negativeNextStepId === step.id
        )
        handleChangeItem(step, false, negative?.id)
      }
    }
  }

  const handleDeleteItem = (salesFunnelStepId: string) => {
    const step = data?.steps.find((step) => step.id === salesFunnelStepId)
    if (step) {
      if (step.isInitial) {
        setError('Impossível remover a etapa inicial de um Funil de Vendas.')
        return
      }
      setErrors({})
      setCurrent(step)
      setOpenDelete(true)
    }
  }

  const handleCopyCodeItem = (salesFunnelStepId: string) => {
    const step = data?.steps.find((step) => step.id === salesFunnelStepId)
    if (step) {
      setErrors({})
      setCurrent(step)
      setOpenCode(true)
    }
  }

  const save = async (salesFunnelId: string): Promise<boolean> => {
    let currentId = salesFunnelId
    const newItem = currentId === ''
    var response

    try {
      setSending(true)
      if (currentId === '') {
        response = await SalesFunnelController.insertSalesFunnelStep({
          data: current,
        })
        currentId = response.data
      } else {
        response = await SalesFunnelController.updateSalesFunnelStep({
          data: current,
        })
      }

      if (!response.success) {
        setError(response.error)
        return false
      }

      if (currentParentId) {
        const currentParent = data?.steps.find((s) => s.id === currentParentId)
        if (currentParent) {
          if (currentPositiveButton) {
            currentParent.positiveNextStepId = currentId
          } else {
            currentParent.negativeNextStepId = currentId
          }
          response = await SalesFunnelController.updateSalesFunnelStep({
            data: currentParent,
          })

          if (!response.success) {
            setError(response.error)
            return false
          }

          if (newItem) {
            const sourceHandle = currentPositiveButton ? 'a' : 'b'
            const name = current.funnelStep?.name ?? ''
            const productName = current.funnelStep?.productName ?? ''
            const moreY = sourceHandle === 'b' ? 250 : 0
            const parentNode = nodes.find((n) => n.id === currentParent.id)

            const node = newNode(
              currentId,
              currentId,
              name,
              productName,
              parentNode,
              moreY,
              current.funnelStep?.positiveButtonLabel ?? '',
              current.funnelStep?.negativeButtonLabel ?? '',
              current.positiveNextStepId,
              current.negativeNextStepId
            )
            nodes.push(node)

            const newEdge = {
              id: `e_${currentParent.id}_${currentId}`,
              source: currentParent.id,
              target: currentId,
              sourceHandle,
            }
            edges.push(newEdge)
          }

          response = await SalesFunnelController.updateSalesFunnelData({
            data: {
              id: id ?? '',
              dataNode: JSON.stringify(nodes),
              dataEdge: JSON.stringify(edges),
            },
          })

          if (!response.success) {
            setError(response.error)
            return false
          }
        }
      }

      setSuccess(true)
      execute()
      setOpen(false)
      return true
    } catch (e) {
      return false
    } finally {
      setSending(false)
    }
  }

  const handleChangeSave = async (): Promise<boolean> => {
    return await save(current.id)
  }

  const handleChangeChoose = async (funnelStepId: string): Promise<boolean> => {
    current.funnelStepId = funnelStepId
    return await save(current.id)
  }

  const handleChangeSaveData = async (): Promise<boolean> => {
    try {
      setSending(true)

      const response = await SalesFunnelController.updateSalesFunnelData({
        data: {
          id: id ?? '',
          dataNode: JSON.stringify(nodes),
          dataEdge: JSON.stringify(edges),
        },
      })

      if (!response.success) {
        setError(response.error)
        return false
      }

      setSuccess(true)
      execute()
      setOpen(false)
      return true
    } catch (e) {
      return false
    } finally {
      setSending(false)
    }
  }

  const handleChangeDelete = async () => {
    try {
      setSending(true)
      let response = await SalesFunnelController.removeSalesFunnelStep({
        id: current.id,
      })

      if (!response.success) {
        setError(response.error)
        return false
      }

      const edgeIds = edges
        .filter((e) => e.source === current.id || e.target === current.id)
        .map((e) => e.id)
      const dataEdge = edges.filter((e) => !edgeIds.includes(e.id))

      const targetIds = dataEdge.map((de) => de.target)
      const sourceIds = dataEdge.map((de) => de.source)

      let dataNode = nodes.filter((n) => n.id !== current.id)
      dataNode = dataNode.filter(
        (dn) => targetIds.includes(dn.id) || sourceIds.includes(dn.id)
      )

      response = await SalesFunnelController.updateSalesFunnelData({
        data: {
          id: id ?? '',
          dataNode: JSON.stringify(dataNode),
          dataEdge: JSON.stringify(dataEdge),
        },
      })

      if (!response.success) {
        setError(response.error)
        return false
      }

      execute()
      setOpenDelete(false)
    } finally {
      setSending(false)
    }
  }

  const funnelStepValidation = (): boolean => {
    let isError = false
    let err: TFunnelStepError = {}

    if (current.funnelStep) {
      if (current.funnelStep.name.trim() === '') {
        err.name = 'Campo obrigatório'
        isError = true
      }

      if (!current.funnelStep.isThankYouPage) {
        if (current.funnelStep.productId === '') {
          err.productId = 'Campo obrigatório'
          isError = true
        }

        if (current.funnelStep.productPriceId === '') {
          err.productPriceId = 'Campo obrigatório'
          isError = true
        }
      }

      if (
        current.funnelStep.pageUrl.trim() === '' ||
        !Validations.URL(current.funnelStep.pageUrl.trim())
      ) {
        err.pageUrl = 'URL inválida'
        isError = true
      }
    }

    setErrors(err)
    return !isError
  }

  const funnelStepValidationButtons = (): boolean => {
    let isError = false
    let err: TFunnelStepError = {}

    if (current.funnelStep) {
      if (current.funnelStep.positiveButtonLabel.trim() === '') {
        err.positiveButtonLabel = 'Campo obrigatório'
        isError = true
      }

      if (current.funnelStep.negativeButtonLabel.trim() === '') {
        err.negativeButtonLabel = 'Campo obrigatório'
        isError = true
      }
    }

    setErrors(err)
    return !isError
  }

  const handleChangePositive = (parentData: any) => {
    handleChangeItem(
      { ...salesFunnelStepDefault, isInitial: false },
      true,
      parentData?.id
    )
  }

  const handleChangeNegative = (parentData: any) => {
    handleChangeItem(
      { ...salesFunnelStepDefault, isInitial: false },
      false,
      parentData?.id
    )
  }

  const addNode = (node: any) => {
    setNodes((nds) => nds.concat(node))
    currentNode.current = [...currentNode.current, { ...node }]
  }

  const newNode = (
    id: string,
    dataId: string,
    name: string,
    subtitle: string,
    parentNode: Node<any, string | undefined> | undefined,
    moreY: number,
    positiveButtonLabel: string,
    negativeButtonLabel: string,
    positiveNextStepId: string | null | undefined,
    negativeNextStepId: string | null | undefined
  ) => {
    return {
      id,
      type: 'textUpdater',
      position: {
        x: (parentNode?.position.x ?? 0) + 250,
        y: (parentNode?.position.y ?? 0) + moreY,
      },
      data: {
        id: dataId,
        title: name,
        subtitle,
        positiveButtonLabel,
        negativeButtonLabel,
        positiveNextStepId,
        negativeNextStepId,
        onPositive: handleChangePositive,
        onNegative: handleChangeNegative,
        onDelete: handleDeleteItem,
        onCopyCode: handleCopyCodeItem,
        onEdit: handleChangeEditItem,
      },
    }
  }

  const addSaleStepPositive = (
    positive?: TSalesFunnelStep,
    parentNode?: Node<any, string | undefined>
  ) => {
    if (positive) {
      addSaleStep(positive, parentNode, 'a')
    }
  }

  const addSaleStepNegative = (
    negative?: TSalesFunnelStep,
    parentNode?: Node<any, string | undefined>
  ) => {
    if (negative) {
      addSaleStep(negative, parentNode, 'b')
    }
  }

  const addSaleStep = (
    salesFunnelStep?: TSalesFunnelStep,
    parentNode?: Node<any, string | undefined>,
    sourceHandle?: 'a' | 'b' | undefined
  ) => {
    if (salesFunnelStep) {
      const name = salesFunnelStep.funnelStep?.name ?? ''
      const productName = salesFunnelStep.funnelStep?.productName ?? ''
      const moreY = sourceHandle === 'b' ? 250 : 0
      const node = newNode(
        salesFunnelStep.id,
        salesFunnelStep.id,
        name,
        productName,
        parentNode,
        moreY,
        salesFunnelStep.funnelStep?.positiveButtonLabel ?? '',
        salesFunnelStep.funnelStep?.negativeButtonLabel ?? '',
        salesFunnelStep.positiveNextStepId,
        salesFunnelStep.negativeNextStepId
      )
      addNode(node)

      if (parentNode) {
        setEdges((eds) =>
          eds.concat({
            id: `e_${parentNode.id}_${node.id}`,
            source: parentNode.id,
            target: node.id,
            sourceHandle,
            ...markerEndDefault,
          })
        )
      }

      if (salesFunnelStep.positiveNextStepId) {
        const positive = data!.steps.find(
          (s) => s.id === salesFunnelStep.positiveNextStepId
        )
        addSaleStepPositive(positive, node)
      }

      if (salesFunnelStep.negativeNextStepId) {
        const negative = data!.steps.find(
          (s) => s.id === salesFunnelStep.negativeNextStepId
        )
        addSaleStepNegative(negative, node)
      }
    }
  }

  useEffect(() => {
    setAnimationState(true)
    return () => setAnimationState(false)
  }, [])

  useEffect(() => {
    currentNode.current = nodes
  }, [nodes])

  useEffect(() => {
    if (data) {
      if (data.dataNode && data.dataEdge) {
        let objects = JSON.parse(data.dataNode ?? '[]')
        objects = objects.map((object: any) => {
          const step = data.steps.find((s) => s.id === object.id)
          return {
            ...object,
            data: {
              ...object.data,
              title: step?.funnelStep?.name ?? '',
              subtitle: step?.funnelStep?.productName ?? '',
              positiveButtonLabel: step?.funnelStep?.positiveButtonLabel,
              negativeButtonLabel: step?.funnelStep?.negativeButtonLabel,
              isThankYouPage: step?.funnelStep?.isThankYouPage,
              positiveNextStepId: step?.positiveNextStepId,
              negativeNextStepId: step?.negativeNextStepId,
              onPositive: handleChangePositive,
              onNegative: handleChangeNegative,
              onDelete: handleDeleteItem,
              onCopyCode: handleCopyCodeItem,
              onEdit: handleChangeEditItem,
            },
          }
        })

        setNodes(objects)
        setEdges(
          JSON.parse(data.dataEdge ?? '[]').map((item: any) => ({
            ...item,
            ...markerEndDefault,
          }))
        )
      } else {
        const initial = data.steps.find((s) => s.isInitial)
        addSaleStep(initial)
      }
    }
  }, [data])

  return (
    <Box sx={{ width: '100%', margin: '0 auto' }}>
      <Stack
        direction="row"
        alignContent="center"
        justifyContent="space-between"
      >
        <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
          Etapas do funil de vendas
        </Typography>
        <Box>
          <Button variant="contained" onClick={handleChangeSaveData}>
            Salvar Funil
          </Button>
        </Box>
      </Stack>

      <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexBasis: 1,
            alignItems: 'center',
            margin: '0 auto',
          }}
          width="100%"
          height="80vh"
        >
          {nodes.length <= 0 && (
            <SalesFunnelStepItem
              level={0}
              title={data?.name ?? '...'}
              subtitle={data?.productName}
              positiveButtonLabel="Iniciar"
              positiveNextStepId={
                data?.steps.find((s) => s.isInitial) ? '1' : undefined
              }
              initial={true}
              isInitial={true}
              isThankYouPage={false}
              onPositive={() =>
                handleChangeItem(
                  { ...salesFunnelStepDefault, isInitial: true },
                  true
                )
              }
              onEdit={() => {}}
              onDelete={() => {}}
              onCopyCode={() => {}}
            />
          )}

          {nodes.length > 0 && (
            <ReactFlowProvider>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                fitView
              >
                <MiniMap zoomable pannable position="top-right" />
                <Controls position="top-left" />
                <Background color="#aaa" gap={16} />
              </ReactFlow>
            </ReactFlowProvider>
          )}

          <Box
            position="fixed"
            bottom={0}
            left={0}
            sx={{
              backgroundColor: (theme) => theme.palette.background.default,
              height: '4.3rem',
              width: '100%',
            }}
          />
        </Box>
      </Slide>

      <SalesFunnelStepNewEdit
        open={open}
        sending={false}
        step={current}
        errors={errors}
        setOpen={setOpen}
        setStep={setCurrent}
        onSave={handleChangeSave}
        onChooseSave={handleChangeChoose}
        setSending={setSending}
        setError={setError}
        onValidate={funnelStepValidation}
        onValidateButtons={funnelStepValidationButtons}
      />

      <LoadingBackdrop open={sending || loading} />

      <ConfirmDialog
        open={openDelete}
        title="Confirmar exclusão"
        message="Deseja realmente deletar a etapa ?"
        onClose={() => setOpenDelete(false)}
        onYes={handleChangeDelete}
      />

      <ContentDialog
        open={openCode}
        onClose={() => setOpenCode(false)}
        maxWidth="md"
      >
        <Stack direction="column" spacing={1} sx={{ p: 1 }}>
          <Typography variant="subtitle2">
            Código do widget para a página{' '}
            <a href={current.funnelStep?.pageUrl}>
              {current.funnelStep?.pageUrl}
            </a>
          </Typography>
          <CopyBlock
            text={`<!-- AFFILIATESPAY - Upsell Widget -->
<div id="box_affiliatespay"></div>
<script src="${
              process.env.REACT_APP_PAY_HOST
            }/assets/js/affiliatespay.upsell.js"></script>
<script>
    opts = {
        key: '${current.id}',
        width: '650' ,
        height: '420'${
          process.env.REACT_APP_ENVIRONMENT !== 'prod'
            ? `,\n        environment: '${process.env.REACT_APP_ENVIRONMENT}'`
            : ''
        }
    };
    Upsell.Widget.show(opts);
</script>
<!-- AFFILIATESPAY - Upsell Widget -->`}
            codeBlock={true}
            language="javascript"
            //showLineNumbers={12}
            showLineNumbers
            startingLineNumber={1}
            //wrapLines
            wrapLongLines
            copied
            theme={solarizedDark}
            onCopy={() => setCopied(true)}
          />
          <Typography variant="caption">
            Incorpore um atalho facilitado do upsell em seu site por meio do
            código
          </Typography>
        </Stack>
      </ContentDialog>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Concluído com sucesso
      </SuccessSnackbar>

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>
    </Box>
  )
}

export default SalesFunnelStepsPage
