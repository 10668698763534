const contractDurations = [
    {
        value: 30,
        label: "30 Dias",
    },
    {
        value: 60,
        label: "60 Dias",
    },
    {
        value: 90,
        label: "90 Dias",
    },
    {
        value: 180,
        label: "180 Dias",
    },
    {
        value: 999,
        label: "Eterno",
    },
];

export default contractDurations;