import { useCallback, useEffect, useRef, useState } from "react";
import { ITenantBankAccount, TTenantSettings } from "../types/Tenant";
import { TenantController } from "../controllers/TenantController";
import { IErrorResponse } from "../types/ErrorResponse";

export const useCurrentTenantSettings = ({ tenantId }: { tenantId: string }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<TTenantSettings | null>(null);
    const [error, setError] = useState("");

    const loadingRef = useRef(false);

    const execute = useCallback(async () => {
        if (!loadingRef.current) {
            loadingRef.current = true;
            try {
                setLoading(true);

                if (error !== "") {
                    setError("");
                }

                const response = await TenantController.getSettingsByTenantId(tenantId);
                const responseError = response as IErrorResponse;
                const responseData = response as TTenantSettings;

                if (responseError.code) {
                    setError(responseError.error);
                } else {
                    setData(responseData);
                }
            } catch (e) {
                var message = "Erro não identificado";
                if (typeof e === "string") {
                    message = e.toUpperCase();
                } else if (e instanceof Error) {
                    const err = e as Error;
                    message = err.message;
                }
                setError(message);
            } finally {
                setLoading(false);
                loadingRef.current = false;
            }
        }
    }, []);

    useEffect(() => {
        execute();
    }, [execute]);

    const clear = () => {
        setError("");
    };

    return {
        loading,
        data,
        error,
        execute,
        clear,
    };
};
