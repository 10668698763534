import { useResponsive } from '../../core/hooks/useResponsive'
import DataTableHeaderWeb from './DataTableHeaderWeb'
import DataTableHeaderMobile from './DataTableHeaderMobile'

export interface DataTableHeaderProps {
  labelItems?: string
  totalItems: number
  setSearch: (value: string) => void
  executeSearch: () => void
  status?: 'active' | 'inactive' | 'deleted'
  setStatus?: (value: 'active' | 'inactive' | 'deleted') => void
  rowsPerPage: number
  handleChangeRowsPerPage: (value: number) => void
  situationStatus?: number | null
  setSituationStatus?: (value: number) => void
}

const DataTableHeader = (props: DataTableHeaderProps) => {
  const mdUp = useResponsive('up', 'md')

  if (mdUp) return <DataTableHeaderWeb {...props} />

  return <DataTableHeaderMobile {...props} />
}

export default DataTableHeader
