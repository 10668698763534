import { Box, InputAdornment, Popover, Stack, TextField } from '@mui/material'
import { DateRangePicker, Range } from 'react-date-range'
import { useState } from 'react'
import ptBR from 'date-fns/locale/pt-BR'
import { defaultStaticRanges } from '../../../../components/DateTime/ReactDateRange'
import 'react-date-range/dist/styles.css'
//import 'react-date-range/dist/theme/default.css'
import './theme/default.css'
import { addDays, dateString } from '../../../../core/functions/dateTime'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import BasicDatePicker from '../../../../components/DateTime/BasicDatePicker'

export const SaleDateFilter = ({
  startDate,
  endDate,
  handleDateFilter,
}: {
  startDate: Date | null | undefined
  endDate: Date | null | undefined
  handleDateFilter: (value: any) => void
}) => {
  const initialStartDate = addDays(-29)
  const initialEndDate = new Date()

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const [state, setState] = useState<Range[]>([
    {
      startDate: startDate ?? initialStartDate,
      endDate: endDate ?? initialEndDate,
      color: '#D8D9FF',
      key: 'sections',
      autoFocus: true,
      disabled: false,
      showDateDisplay: true,
    },
  ])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleChangeClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleChangeClose = () => {
    setAnchorEl(null)
  }

  const filter = (startDate?: Date, endDate?: Date) => {
    const filter = {
      startDate,
      endDate,
    }
    handleDateFilter(filter)
  }

  return (
    <Stack
      direction="row"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      sx={{ pt: 1, width: '100%' }}
    >
      <TextField
        fullWidth
        aria-describedby={id}
        value={`${dateString(startDate)} à ${dateString(endDate)}`}
        label="Período"
        onClick={handleChangeClick}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ mr: -1, cursor: 'pointer' }}>
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </InputAdornment>
          ),
        }}
        sx={{ input: { cursor: 'pointer' } }}
      />
      <Popover
        id={id}
        open={open}
        onClose={handleChangeClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack direction="column">
          <Stack
            direction="row"
            spacing={1}
            width="100%"
            sx={{ pt: 1.5, pr: 0 }}
          >
            <div className="rdrDefinedRangesWrapper"></div>
            <Box maxWidth="153px">
              <BasicDatePicker
                fullWidth={false}
                label="Data inicial"
                value={startDate}
                setValue={(e) => {
                  if (e) {
                    setState((s) => [{ ...s[0], startDate: e }])
                    filter(e, state[0].endDate)
                  }
                }}
              />
            </Box>
            <Box maxWidth="153px">
              <BasicDatePicker
                fullWidth={false}
                label="Data final"
                value={endDate}
                setValue={(e) => {
                  if (e) {
                    setState((s) => [{ ...s[0], endDate: e }])
                    filter(state[0].startDate, e)
                  }
                }}
              />
            </Box>
          </Stack>
          <DateRangePicker
            onChange={(item) => {
              setState([item.sections])
              const { startDate, endDate } = item.sections
              filter(startDate, endDate)
            }}
            showPreview
            showDateDisplay={false}
            showMonthAndYearPickers={false}
            showMonthArrow
            color="#1C52BD"
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="vertical"
            locale={ptBR}
            dateDisplayFormat="d MMM yyyy"
            inputRanges={[]}
            staticRanges={defaultStaticRanges}
            weekdayDisplayFormat="EEEEEE"
          />
        </Stack>
      </Popover>
    </Stack>
  )
}
