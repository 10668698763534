import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import CheckIcon from '@mui/icons-material/Check'
import { TTenantAnticipationType } from '../../../core/types/Tenant'
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog'
import ContentDialog from '../../../components/Dialogs/ContentDialog'
import { useState } from 'react'
import NumberFunctions from '../../../core/functions/NumberFunctions'

export interface ITenantAnticipationItemProp {
  anticipationType: TTenantAnticipationType
  tax: number
  isCurrent: boolean
  isRecomended: boolean
  currentFee: number
  sending: boolean
  onChangeAnticipationType: (value: TTenantAnticipationType) => Promise<boolean>
}

const TenantAnticipationItem = ({
  anticipationType,
  tax,
  isCurrent,
  isRecomended,
  currentFee,
  sending,
  onChangeAnticipationType,
}: ITenantAnticipationItemProp) => {
  const [anticipationChoose, setAnticipationChoose] = useState<
    TTenantAnticipationType | undefined
  >(undefined)

  let anticipationNumber = Number(anticipationType)
  const hasRecommended = isRecomended && anticipationNumber > 0

  if (anticipationNumber === 0) {
    anticipationNumber = 30
  }

  const handleChangePositive = async (): Promise<boolean> => {
    return await onChangeAnticipationType(anticipationType)
  }

  return (
    <>
      <Paper>
        <Stack direction="column" spacing={1} sx={{ p: 2 }}>
          <Stack direction="column" spacing={3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="overline" fontWeight={500}>
                {isCurrent ? 'Prazo atual' : 'Prazo de recebimento'}
              </Typography>
              {hasRecommended && (
                <Chip
                  variant="filled"
                  size="small"
                  color="primary"
                  avatar={
                    <Avatar>
                      <StarBorderIcon sx={{ fontSize: 16 }} />
                    </Avatar>
                  }
                  label="Recomendado"
                />
              )}
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            {hasRecommended && (
              <AutoAwesomeIcon
                color="primary"
                sx={{ fontSize: '1.2rem', mt: 1 }}
              />
            )}
            <Typography variant="h4" fontWeight={500} sx={{ m: 0, p: 0 }}>
              {anticipationNumber} dias
            </Typography>
          </Stack>
          {/* {anticipationType !== TTenantAnticipationType.None && (
                        <Stack direction="row" spacing={1}>
                            <CheckIcon sx={{ fontSize: 20, color: 'gray' }} />
                            <Typography variant='body2' color="gray">
                                Repasse a taxa para seus clientes e <strong>não pague diretamente por esse prazo de recebimento.</strong>
                            </Typography>
                        </Stack>
                    )}
                    {anticipationType !== TTenantAnticipationType.None && currentFee !== tax && (
                        <Stack direction="row" spacing={1} sx={{ pb: 1 }}>
                            <CheckIcon sx={{ fontSize: 20, color: 'gray' }} />
                            <Typography variant='body2' color="gray">
                                Aproveite os benefícios de receber em {anticipationNumber} dias com apenas um reajuste na taxa: <strong>de {NumberFunctions.toPercentage(currentFee)} para {NumberFunctions.toPercentage(tax)}.</strong>
                            </Typography>
                        </Stack>
                    )}
                    <Divider /> */}
          {anticipationNumber !== 30 && (
            <Stack
              direction="row"
              spacing={1}
              sx={{ py: 1 }}
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="body2" textAlign="center">
                Receba em {anticipationNumber} dias acrescentando nas suas
                transações apenas:{' '}
                <strong>{NumberFunctions.toPercentage(tax)}</strong>
              </Typography>
            </Stack>
          )}
          {!isCurrent && (
            <Button
              fullWidth
              variant="contained"
              disabled={sending}
              onClick={() => setAnticipationChoose(anticipationType)}
            >
              Selecionar prazo
            </Button>
          )}
          {isCurrent && (
            <Button variant="outlined" endIcon={<CheckIcon />}>
              Prazo atual
            </Button>
          )}
        </Stack>
      </Paper>

      <ContentDialog
        open={anticipationChoose !== undefined}
        onClose={() => setAnticipationChoose(undefined)}
        title={`Quer confirmar o prazo de ${anticipationNumber} dias?`}
        labelPrositive="Sim, confirmar"
        onPositive={handleChangePositive}
      >
        <Box>
          {/* <Typography gutterBottom>Você só poderá alterar este prazo de recebimento após 30 dias corridos, contados a partir desta confirmação.</Typography> */}
          <Alert severity="info" sx={{ my: 2 }}>
            Ao confirmar, os preços parcelados dos seus produtos serão
            atualizados automaticamente em até 30 minutos. Lembre-se de
            alterá-los em seus canais de divulgação.
          </Alert>
        </Box>
      </ContentDialog>
    </>
  )
}

export default TenantAnticipationItem
