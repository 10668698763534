const booleanify = (value: string): boolean => {
    const truthy: string[] = [
        'true',
        'True',
        '1'
    ];

    return truthy.includes(value)
}

const UtilFunctions = {
    booleanify,
}

export default UtilFunctions;