import CreditCardIcon from '@mui/icons-material/CreditCard'
import DebitCardIcon from '@mui/icons-material/CreditCardTwoTone'
import PixIcon from '@mui/icons-material/Pix'
import BoletoIcon from '@mui/icons-material/Receipt'
import PaymentIcon from '@mui/icons-material/Paid'

const MethodTypeSmallIcon = ({
  methodType,
  widthCustom,
  heightCustom,
}: {
  methodType?: string
  widthCustom?: string
  heightCustom?: string
}) => {
  switch (methodType) {
    case 'credit_card':
      return (
        <img
          src="/assets/icons/payments/pay.svg"
          alt="credit-payment-sell"
          style={{ width: widthCustom, height: heightCustom }}
        />
      )
    case 'debit_card':
      return (
        <img
          src="/assets/icons/payments/pay.svg"
          alt="debit-payment-sell"
          style={{ width: widthCustom, height: heightCustom }}
        />
      )
    case 'pix':
      return (
        <img
          src="/assets/icons/payments/pix.svg"
          alt="pix-payment-sell"
          style={{ width: widthCustom, height: heightCustom }}
        />
      )
    case 'boleto':
      return (
        <img
          src="/assets/icons/payments/barcode.svg"
          alt="invoice-payment-sell"
          style={{ width: widthCustom, height: heightCustom }}
        />
      )
  }

  return <PaymentIcon fontSize="small" sx={{ color: '#1C52BD' }} />
}

export default MethodTypeSmallIcon
