import { Box, Button, Stack, Tab, Tabs } from '@mui/material'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'
import {
  TFunnelStep,
  TFunnelStepError,
  TSalesFunnelStep,
} from '../../../core/types/SalesFunnel'
import { useState } from 'react'
import FunnelStepNewEdit, { TFunnelStepPage } from './FunnelStepNewEdit'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SaveIcon from '@mui/icons-material/DoneAll'
import FunnelStepsChoose from './FunnelSteps'

export interface ISalesFunnelStepNewEditProp {
  open: boolean
  step: TSalesFunnelStep
  sending: boolean
  errors: TFunnelStepError
  setOpen: (value: boolean) => void
  setStep: (value: TSalesFunnelStep) => void
  onSave: () => Promise<boolean>
  onChooseSave: (value: string) => Promise<boolean>
  setSending: (value: boolean) => void
  setError: (value: string) => void
  onValidate: () => boolean
  onValidateButtons: () => boolean
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const SalesFunnelStepNewEdit = ({
  open,
  step,
  sending,
  errors,
  setOpen,
  setStep,
  onSave,
  onChooseSave,
  setSending,
  setError,
  onValidate,
  onValidateButtons,
}: ISalesFunnelStepNewEditProp) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [pageIndex, setPageIndex] = useState<TFunnelStepPage>(
    TFunnelStepPage.Initial
  )

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const handleChangeFunnelStep = (value: TFunnelStep) => {
    setStep({ ...step, funnelStep: value })
  }

  const handleChangeFunnelStepId = (value: string) => {
    onChooseSave(value)
  }

  const handleChangeCancel = () => {
    if (pageIndex === TFunnelStepPage.Buttons) {
      setPageIndex(TFunnelStepPage.Initial)
    } else {
      setOpen(false)
    }
  }

  const handleChangeSave = async () => {
    if (pageIndex === TFunnelStepPage.Initial) {
      if (onValidate()) {
        await onSave()
      }
    } else {
      if (onValidateButtons()) {
        const isSave = await onSave()
        if (isSave) {
          setPageIndex(TFunnelStepPage.Initial)
        }
      }
    }
  }

  return (
    <SwipeableDrawerRightDialog
      open={open}
      setOpen={setOpen}
      onClose={() => setOpen(false)}
      title="Etapa do funil"
      subtitle="Edição dos dados da etapa"
      buttons={
        tabIndex === 0
          ? [
              {
                title: 'Cancelar',
                onClick: handleChangeCancel,
                type: 'negative',
                startIcon: <ArrowBackIcon />,
              },
              {
                title: 'Salvar',
                onClick: handleChangeSave,
                type: 'positive',
                endIcon: <SaveIcon />,
              },
            ]
          : undefined
      }
      buttonsDisabled={sending}
    >
      <>
        {step.id === '' && (
          <Box>
            <Stack
              direction="row"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <Tabs
                value={tabIndex}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{ flexGrow: 1 }}
              >
                <Tab label="Nova etapa" {...a11yProps(0)} />
                <Tab label="Etapa existente" {...a11yProps(1)} />
              </Tabs>
            </Stack>
            <TabPanel value={tabIndex} index={0}>
              <FunnelStepNewEdit
                step={step.funnelStep!}
                page={pageIndex}
                errors={errors}
                setStep={handleChangeFunnelStep}
                setSending={setSending}
                setError={setError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <FunnelStepsChoose onChoose={handleChangeFunnelStepId} />
            </TabPanel>
          </Box>
        )}
        {step.id !== '' && (
          <Box sx={{ p: 2 }}>
            <FunnelStepNewEdit
              step={step.funnelStep!}
              page={pageIndex}
              errors={errors}
              setStep={handleChangeFunnelStep}
              setSending={setSending}
              setError={setError}
            />
          </Box>
        )}
      </>
    </SwipeableDrawerRightDialog>
  )
}

export default SalesFunnelStepNewEdit
