import React, { useState } from 'react'
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

export interface ISelectInputProps<T> {
  width?: string
  minWidth?: number
  maxWidth?: string
  value: T
  onChange: (event: SelectChangeEvent<T>) => void
  options: Array<{ label: string; value: T }>
}

const SelectInput = <T extends string | number>({
  width,
  minWidth,
  maxWidth,
  value,
  onChange,
  options,
}: ISelectInputProps<T>) => {
  const [openSelectRange, setOpenSelectRange] = useState(false)

  const handleCloseSelectRange = () => {
    setOpenSelectRange(false)
  }

  const handleOpenSelectRange = () => {
    setOpenSelectRange(true)
  }

  return (
    <FormControl
      size="small"
      sx={{
        width: width,
        backgroundColor: 'white',
        borderRadius: '8px',
        minWidth,
        maxWidth,
      }}
    >
      <Select
        autoWidth={false}
        disableUnderline={true}
        variant="outlined"
        value={value}
        onChange={onChange}
        open={openSelectRange}
        onClose={handleCloseSelectRange}
        onOpen={handleOpenSelectRange}
        IconComponent={() => (
          <IconButton
            size="small"
            onClick={() => setOpenSelectRange(!openSelectRange)}
          >
            {openSelectRange ? (
              <KeyboardArrowUpIcon color="disabled" />
            ) : (
              <KeyboardArrowDownIcon color="disabled" />
            )}
          </IconButton>
        )}
        style={{ borderRadius: '8px' }}
      >
        {options.map((option) => (
          <MenuItem key={option.value.toString()} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectInput
