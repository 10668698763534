import api, { getResponseError } from "../../services/api";
import { IErrorResponse, IResponse } from "../types/ErrorResponse";
import { IIndicatedTenantPaginationProp, TEnableIndicatedTenant, TEnableOrDisableIndicatedTenant, TIndicatedComissionLevelEnum, TIndicatedTenant, TIndicatedTenantItem, TIndicatedTenantPagination, TUpdateIndicatedTenant } from "../types/IndicatedTenant";

const getIndicatedById = async ({ id }: { id: string }): Promise<TIndicatedTenant | IErrorResponse> => {
    try {
        var path = `/IndicatedTenant/${id}`;

        const { data } = await api.get<TIndicatedTenant>(path);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const listIndicatedById = async ({ id }: { id: string }): Promise<TIndicatedTenantItem[] | IErrorResponse> => {
    try {
        var path = `/IndicatedTenant/${id}/list`;

        const { data } = await api.get<TIndicatedTenantItem[]>(path);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const updateIndicatedTenant = async ({ data }: { data: TUpdateIndicatedTenant }): Promise<IResponse> => {
    try {
        const { data: response } = await api.put("/IndicatedTenant", data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const enableOrDisableIndicated = async ({ data }: { data: TEnableOrDisableIndicatedTenant }): Promise<IResponse> => {
    try {
        const { data: response } = await api.put("/IndicatedTenant/enable", data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const enableIndicated = async ({ data }: { data: TEnableIndicatedTenant }): Promise<IResponse> => {
    return enableOrDisableIndicated({ data: { ...data, value: true } });
}

const disableIndicated = async ({ data }: { data: TEnableIndicatedTenant }): Promise<IResponse> => {
    return enableOrDisableIndicated({ data: { ...data, value: false } });
}

const getIndicatesAll = async ({ page, rows, search, startDate, endDate }: IIndicatedTenantPaginationProp): Promise<TIndicatedTenantPagination | IErrorResponse> => {
    try {
        let path = `/IndicatedTenant/indicates?PageNumber=${page}&RowsPerPage=${rows}`;

        if (search && search !== '') {
            path += `&Search=${search}`;
        }

        if (startDate) {
            endDate ??= new Date();
            path += `&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate.toISOString()}`;
        }

        const { data } = await api.get<TIndicatedTenantPagination>(path);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const acceptUseTerms = async (): Promise<IResponse> => {
    try {
        await api.put("/IndicatedTenant/accept", { value: true });
        return {
            success: true,
            code: 0,
            error: '',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const IndicatedController = {
    getIndicatedById,
    listIndicatedById,
    updateIndicatedTenant,
    enableOrDisableIndicated,
    enableIndicated,
    disableIndicated,
    getIndicatesAll,
    acceptUseTerms,
}

export default IndicatedController;