import { InputAdornment, Typography } from '@mui/material'
import { HtmlTooltip } from '../Tooltip/HtmlTooltip'

interface IInfoInputAdornmentProps {
  title: string | React.ReactNode
}

const InfoInputAdornment = ({ title }: IInfoInputAdornmentProps) => {
  return (
    <InputAdornment position="end" sx={{ cursor: 'default' }}>
      <HtmlTooltip title={title}>
        <img src="/assets/icons/infos/info.svg" alt="info" />
      </HtmlTooltip>
    </InputAdornment>
  )
}

export default InfoInputAdornment
