import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TProductPrice } from "../../../core/types/Product";
import { useForm } from "react-hook-form";
import {
    TActiveCampaignItem,
    TActiveCampaignCustomField,
    TActiveCampaignList,
    TActiveCampaignTag,
    TActiveCampaignItemCustomField,
    TActiveCampaignItemList,
    TActiveCampaignItemTag,
    TActiveCampaignItemAction,
    TActiveCampaignItemTagType,
    TActiveCampaignItemType,
} from "../../../core/types/ActiveCampaign";
import { useOrderBump } from "../../../core/hooks/useOrderBump";
import { useProductsSimple } from "../../../core/hooks/useProductsSimple";
import ProductController from "../../../core/controllers/ProductController";
import { IErrorResponse } from "../../../core/types/ErrorResponse";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    FormControl,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    Slide,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import LoadingBackdrop from "../../../components/Loading/LoadingBackdrop";
import ErrorSnackbar from "../../../components/Snackbar/ErrorSnackbar";
import SuccessSnackbar from "../../../components/Snackbar/SuccessSnackbar";
import ActiveCampaignController from "../../../core/controllers/ActiveCampaignController";
import ActiveCampaignFunctions from "../../../core/functions/ActiveCampaignFunctions";
import { useActiveCampaign } from "../../../core/hooks/useActiveCampaign";
import { useActiveCampaignItems } from "../../../core/hooks/useActiveCampaignItems";
import { useActiveCampaignItem } from "../../../core/hooks/useActiveCampaignItem";
import { red } from "@mui/material/colors";
import { useAffiliationProducts } from "../../../core/hooks/useAffiliationProducts";
import React from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ActiveCampaignSmartPage = () => {
    const { id, activeCampaignId } = useParams<{ id: string; activeCampaignId: string }>();
    const newItem = id === "new";

    const [tabIndex, setTabIndex] = useState(0);
    const [animationState, setAnimationState] = useState(false);
    const [loadingTags, setLoadingTags] = useState(false);
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const [productPrices, setProductPrices] = useState<TProductPrice[]>([]);
    const [customFields, setCustomFields] = useState<TActiveCampaignCustomField[]>([]);
    const [lists, setLists] = useState<TActiveCampaignList[]>([]);
    const [tags, setTags] = useState<TActiveCampaignTag[]>([]);

    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        watch,
        setError: setErrorForm,
        clearErrors,
    } = useForm<TActiveCampaignItem>({
        defaultValues: {
            id: newItem ? undefined : id,
            campaignAccountId: activeCampaignId,
            productId: "",
            productName: "",
            event: null,
            action: null,
            productPriceIds: [],
            customFields: [],
            lists: [],
            tags: [],
        },
    });

    const navigate = useNavigate();
    const productId = watch("productId");

    const { data, loading } = useActiveCampaignItem({ id: id || "" });
    //const { data: products, loading: loadingProducts } = useProductsSimple({ initialExecute: true });
    const { data: products, loading: loadingProducts } = useAffiliationProducts();

    const getPricesByProduct = async () => {
        if (productId && productId !== null && productId !== "") {
            setSending(true);
            try {
                const response = await ProductController.getPricesByProduct({ productId });
                const err = response as IErrorResponse;
                if (err.code) {
                    setError(err.error);
                } else {
                    setProductPrices(response as TProductPrice[]);
                }
            } finally {
                setSending(false);
            }
        }
    };

    const getActiveCampaignSettings = async () => {
        setSending(true);
        try {
            const respCustomField = await ActiveCampaignController.getCustomFields({ activeCampaignId });
            const errCustomField = respCustomField as IErrorResponse;
            if (errCustomField.code) {
                setError(errCustomField.error);
            } else {
                setCustomFields(respCustomField as TActiveCampaignCustomField[]);
            }

            const respLists = await ActiveCampaignController.getLists({ activeCampaignId });
            const errLists = respLists as IErrorResponse;
            if (errLists.code) {
                setError(errLists.error);
            } else {
                setLists(respLists as TActiveCampaignList[]);
            }

            const respTags = await ActiveCampaignController.getTags({ activeCampaignId });
            const errTagss = respTags as IErrorResponse;
            if (errTagss.code) {
                setError(errTagss.error);
            } else {
                setTags(respTags as TActiveCampaignTag[]);
            }

            if (data !== null) {
                reset(data);
            }
        } finally {
            setSending(false);
        }
    };

    const getTags = async (search?: string) => {
        setLoadingTags(true);
        try {
            const respTags = await ActiveCampaignController.getTags({ activeCampaignId, search });
            const errTagss = respTags as IErrorResponse;
            if (errTagss.code) {
                setError(errTagss.error);
            } else {
                setTags(respTags as TActiveCampaignTag[]);
            }
        } finally {
            setLoadingTags(false);
        }
    };

    const handleChangePrice = (event: SelectChangeEvent<string[] | string>) => {
        const {
            target: { value },
        } = event;

        const ids = typeof value === "string" ? value.split(",") : value;

        setValue("productPriceIds", ids);
        clearErrors("productPriceIds");
    };

    const handleChangeCustomField = (event: SelectChangeEvent<string[] | string>) => {
        const {
            target: { value },
        } = event;

        const ids = typeof value === "string" ? value.split(",") : value;

        const activeCampaignCustomFields = watch("customFields");
        const newCustomFields: TActiveCampaignItemCustomField[] = [];
        ids.map((id) => {
            const accf = activeCampaignCustomFields.find((cf) => cf.customFieldId === id);
            if (accf) {
                newCustomFields.push({ ...accf });
            } else {
                const customField = customFields.find((cf) => cf.id === id);
                if (customField) {
                    newCustomFields.push({
                        id: null,
                        activeCampaignItemId: watch("id") ?? null,
                        customFieldId: customField.id,
                        name: customField.name,
                        type: customField.type,
                        displayOrder: 0,
                        isFormVisible: false,
                        isRequired: false,
                    });
                }
            }
        });

        setValue("customFields", newCustomFields);
        clearErrors("customFields");
    };

    const handleChangeList = (event: SelectChangeEvent<string[] | string>) => {
        const {
            target: { value },
        } = event;

        const ids = typeof value === "string" ? value.split(",") : value;

        const activeCampaignLists = watch("lists");
        const newLists: TActiveCampaignItemList[] = [];
        ids.map((id) => {
            const acl = activeCampaignLists.find((l) => l.listId === id);
            if (acl) {
                newLists.push({ ...acl });
            } else {
                const list = lists.find((cf) => cf.id === id);
                if (list) {
                    newLists.push({
                        id: null,
                        activeCampaignItemId: watch("id") ?? null,
                        listId: list.id,
                        name: list.name,
                        sender_reminder: list.sender_reminder,
                        sender_url: list.sender_url,
                        stringId: list.stringId,
                    });
                }
            }
        });

        setValue("lists", newLists);
        clearErrors("lists");
    };

    const handleChangeTag = (event: SelectChangeEvent<string[] | string>) => {
        const {
            target: { value },
        } = event;

        const ids = typeof value === "string" ? value.split(",") : value;

        const activeCampaignTags = watch("tags");
        const newTags: TActiveCampaignItemTag[] = [];
        ids.map((id) => {
            const act = activeCampaignTags.find((t) => t.tagId === id);
            if (act) {
                newTags.push({ ...act });
            } else {
                const tag = tags.find((t) => t.id === id);
                if (tag) {
                    newTags.push({
                        id: null,
                        activeCampaignItemId: watch("id") ?? null,
                        tagId: tag.id,
                        tag: tag.name,
                        tagType: TActiveCampaignItemTagType.contact,
                        description: "",
                    });
                }
            }
        });

        setValue("tags", newTags);
        clearErrors("tags");
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        setAnimationState(true);
        getActiveCampaignSettings();
        return () => setAnimationState(false);
    }, []);

    useEffect(() => {
        if (data !== null) {
            reset(data);
        }
        return () => {
            if (data !== null) {
                reset(data);
            }
        };
    }, [data, reset]);

    useEffect(() => {
        getPricesByProduct();
        return () => {};
    }, [productId]);

    const Submit = async (data: TActiveCampaignItem) => {
        let isErrors = false;

        if (!data.productId || data.productId === "") {
            setErrorForm("productId", { type: "required" });
            isErrors = true;
        }

        if (!data.productPriceIds || data.productPriceIds.length <= 0) {
            setErrorForm("productPriceIds", { type: "required" });
            isErrors = true;
        }

        if (!data.event) {
            setErrorForm("event", { type: "required" });
            isErrors = true;
        }

        if (!data.action) {
            setErrorForm("action", { type: "required" });
            isErrors = true;
        }

        if (!data.lists || data.lists.length <= 0) {
            setErrorForm("lists", { type: "required" });
            isErrors = true;
        }

        if (isErrors) {
            setError("Corrija os erros");
            return;
        }

        setSending(true);
        setSuccess(false);
        setError("");
        try {
            var response;
            if (newItem) {
                response = await ActiveCampaignController.insertItem({ data });
            } else {
                response = await ActiveCampaignController.updateItem({ data });
            }
            if (!response.success) {
                setError(response.error);
            } else {
                setSuccess(true);
            }
        } finally {
            setSending(false);
        }
    };

    return (
        <Box>
            <Box sx={{ width: "100%", margin: "0 auto" }}>
                <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
                    Disparo inteligente
                </Typography>

                <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
                    <Box>
                        <Box
                            component={Paper}
                            py={4}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                flexBasis: 1,
                                alignItems: "center",
                                margin: "0 auto",
                            }}
                            width="90%"
                        >
                            <Stack
                                component={"form"}
                                noValidate
                                direction={"column"}
                                spacing={3}
                                sx={{ width: "80%" }}
                                onSubmit={handleSubmit(Submit)}
                            >
                                <Stack direction="row" spacing={2}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            fullWidth
                                            disablePortal
                                            size="small"
                                            options={products}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(event, option) => {
                                                if (option && option !== null) {
                                                    setValue("productId", option.id);
                                                    setValue("productName", option.name);
                                                }
                                                clearErrors("productId");
                                            }}
                                            value={{ id: watch("productId"), name: watch("productName") }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Produto"
                                                    {...register("productName")}
                                                    error={errors.productId?.type === "required"}
                                                />
                                            )}
                                        />
                                        {errors.productId?.type === "required" && (
                                            <Typography variant="caption" color={red[700]}>
                                                Campo obrigatório
                                            </Typography>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth size="small" disabled={productPrices.length <= 0}>
                                        <InputLabel id="price-select-label">Ofertas</InputLabel>
                                        <Select
                                            labelId="price-select-label-label"
                                            id="price-select-label"
                                            multiple
                                            value={watch("productPriceIds")}
                                            onChange={handleChangePrice}
                                            input={<OutlinedInput label="Ofertas" />}
                                            renderValue={(selected) =>
                                                productPrices
                                                    .filter((price) => selected.includes(price.id))
                                                    .map((price) => price.value?.toFixed(2))
                                                    .join(", ")
                                            }
                                            error={errors.productPriceIds?.type === "required"}
                                        >
                                            {productPrices.map((price) => (
                                                <MenuItem key={price.id} value={price.id}>
                                                    <Checkbox
                                                        checked={watch("productPriceIds").indexOf(price.id) > -1}
                                                    />
                                                    <ListItemText primary={price.value?.toFixed(2)} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.productPriceIds?.type === "required" && (
                                            <Typography variant="caption" color={red[700]}>
                                                Campo obrigatório
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Stack>

                                <FormControl size="small" sx={{ m: 1 }}>
                                    <InputLabel htmlFor="grouped-select">Evento</InputLabel>
                                    <Select
                                        id="grouped-select"
                                        label="Evento"
                                        value={Number(watch("event"))}
                                        {...register("event")}
                                        error={errors.event?.type === "required"}
                                    >
                                        <ListSubheader>Status de Venda</ListSubheader>
                                        {ActiveCampaignFunctions.salesTypes.map((type) => (
                                            <MenuItem key={Number(type)} value={Number(type)}>
                                                {ActiveCampaignFunctions.typeToString(type)}
                                            </MenuItem>
                                        ))}
                                        <ListSubheader>Status de Afiliado</ListSubheader>
                                        {ActiveCampaignFunctions.affiliateTypes.map((type) => (
                                            <MenuItem key={Number(type)} value={Number(type)}>
                                                {ActiveCampaignFunctions.typeToString(type)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.event?.type === "required" && (
                                        <Typography variant="caption" color={red[700]}>
                                            Campo obrigatório
                                        </Typography>
                                    )}
                                </FormControl>

                                <FormControl size="small" sx={{ m: 1 }}>
                                    <InputLabel htmlFor="when-grouped-select">Ação</InputLabel>
                                    <Select
                                        id="when-grouped-select"
                                        label="Ação"
                                        value={Number(watch("action"))}
                                        {...register("action")}
                                        error={errors.action?.type === "required"}
                                    >
                                        <MenuItem
                                            key={Number(TActiveCampaignItemAction.Include)}
                                            value={Number(TActiveCampaignItemAction.Include)}
                                        >
                                            Incluir
                                        </MenuItem>
                                        <MenuItem
                                            key={Number(TActiveCampaignItemAction.Delete)}
                                            value={Number(TActiveCampaignItemAction.Delete)}
                                        >
                                            Remover
                                        </MenuItem>
                                    </Select>
                                    {errors.action?.type === "required" && (
                                        <Typography variant="caption" color={red[700]}>
                                            Campo obrigatório
                                        </Typography>
                                    )}
                                </FormControl>

                                <FormControl fullWidth size="small">
                                    <InputLabel id="lists-select-label">Listas</InputLabel>
                                    <Select
                                        labelId="lists-select-label-label"
                                        id="lists-select-label"
                                        multiple
                                        value={watch("lists").map((cf) => cf.listId)}
                                        onChange={handleChangeList}
                                        input={<OutlinedInput label="Listas" />}
                                        renderValue={(selected) => (
                                            <Stack direction="row" spacing={1}>
                                                {lists
                                                    .filter((list) => selected.includes(list.id))
                                                    .map((l) => (
                                                        <Chip label={l.name} size="small" />
                                                    ))}
                                            </Stack>
                                        )}
                                        error={errors.lists?.type === "required"}
                                    >
                                        {lists.map((list) => (
                                            <MenuItem key={list.id} value={list.id}>
                                                <Checkbox
                                                    checked={
                                                        watch("lists").find((cf) => cf.listId === list.id) !== undefined
                                                    }
                                                />
                                                <ListItemText primary={list.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.lists?.type === "required" && (
                                        <Typography variant="caption" color={red[700]}>
                                            Campo obrigatório
                                        </Typography>
                                    )}
                                </FormControl>

                                <Autocomplete
                                    multiple
                                    freeSolo
                                    size="small"
                                    id="tags-filled"
                                    loading={loadingTags}
                                    options={tags.map((option) => option.name)}
                                    value={watch("tags").map((tag) => tag.tag)}
                                    onChange={(e, value) => {
                                        const ts = watch("tags").filter((t) => value.includes(t.tag));
                                        value.map((v) => {
                                            const ex = ts.find((t) => t.tag === v);
                                            if (!ex) {
                                                const tag = tags.find((t) => t.name === v);
                                                ts.push({
                                                    id: null,
                                                    tag: tag?.name ?? v,
                                                    description: "",
                                                    tagId: tag?.id ?? "CREATETAG",
                                                    activeCampaignItemId: watch("id") ?? null,
                                                    tagType: TActiveCampaignItemTagType.contact,
                                                });
                                            }
                                        });
                                        setValue("tags", ts);
                                    }}
                                    renderTags={(value: readonly string[], getTagProps) =>
                                        value.map((option: string, index: number) => (
                                            <Chip label={option} size="small" {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="TAGS"
                                            placeholder="Pesquisar ou adicionar tags"
                                            onChange={(e) => getTags(e.target.value)}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loadingTags ? (
                                                            <CircularProgress color="inherit" size={20} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />

                                <FormControl fullWidth size="small">
                                    <InputLabel id="fields-select-label">Link do PIX / Boleto</InputLabel>
                                    <Select
                                        labelId="fields-select-label-label"
                                        id="fields-select-label"
                                        //multiple
                                        value={watch("customFields").map((cf) => cf.customFieldId)}
                                        onChange={handleChangeCustomField}
                                        input={<OutlinedInput label="Link do PIX / Boleto" />}
                                        renderValue={(selected) => (
                                            <Stack direction="row" spacing={1}>
                                                {customFields
                                                    .filter((cf) => selected.includes(cf.id))
                                                    .map((field) => (
                                                        <Chip label={field.name} size="small" />
                                                    ))}
                                            </Stack>
                                        )}
                                    >
                                        {customFields.map((field) => (
                                            <MenuItem key={field.id} value={field.id}>
                                                <Checkbox
                                                    checked={
                                                        watch("customFields").find(
                                                            (cf) => cf.customFieldId === field.id
                                                        ) !== undefined
                                                    }
                                                />
                                                <ListItemText primary={field.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* <FormControl fullWidth size="small">
                                    <InputLabel id="lists-select-label">Listas</InputLabel>
                                    <Select
                                        labelId="lists-select-label-label"
                                        id="lists-select-label"
                                        multiple
                                        value={watch("list").map(cf => cf.listId)}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Listas" />}
                                        renderValue={(selected) => <Stack direction="row" spacing={1}>{lists.filter((cf) => selected.includes(cf.id)).map((list) => <Chip label={list.name} size="small" />)}</Stack>}
                                    >
                                        {lists.map((list) => (
                                            <MenuItem key={list.id} value={list.id}>
                                                <Checkbox checked={watch("list").find(cf => cf.listId === list.id) !== undefined} />
                                                <ListItemText primary={list.name} />
                                            </MenuItem>)
                                        )}
                                    </Select>
                                </FormControl> */}

                                {/* <Stack direction="row" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="basic tabs example" sx={{ flexGrow: 1 }}>
                                        <Tab label="Campos personalizados" {...a11yProps(0)} />
                                        <Tab label="Listas" {...a11yProps(1)} />
                                        <Tab label="TAGS" {...a11yProps(2)} />
                                    </Tabs>
                                </Stack>

                                <TabPanel value={tabIndex} index={0}>
                                    <ActiveCampaignCustomFields
                                        control={control}
                                        watch={watch}
                                        getData={getPricesByProduct}
                                        errors={errors}
                                        setError={setError}
                                    />
                                </TabPanel>

                                <TabPanel value={tabIndex} index={1}>
                                    <ActiveCampaignList
                                        control={control}
                                        watch={watch}
                                        getData={getPricesByProduct}
                                        errors={errors}
                                        setError={setError}
                                    />
                                </TabPanel>

                                <TabPanel value={tabIndex} index={2}>
                                    <ActiveCampaignTag
                                        control={control}
                                        watch={watch}
                                        getData={getPricesByProduct}
                                        errors={errors}
                                        setError={setError}
                                    />
                                </TabPanel> */}

                                <Button color="primary" type="submit" variant="contained" fullWidth>
                                    Salvar
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Slide>
            </Box>

            <LoadingBackdrop open={loading || sending || loadingProducts} />

            <ErrorSnackbar open={error !== ""} onClose={() => setError("")}>
                {error}
            </ErrorSnackbar>

            <SuccessSnackbar
                open={success}
                onClose={() => {
                    setSuccess(false);
                    navigate("/apps/activecampaign");
                }}
            >
                Alterado com sucesso
            </SuccessSnackbar>
        </Box>
    );
};

export default ActiveCampaignSmartPage;
