import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import SwipeableDrawerRightDialog from '../../../../../components/Dialogs/SwipeableDrawerRightDialog'
import Convertions from '../../../../../core/functions/convertions'
import SwitchRound from '../../../../../components/Switch/SwitchRound'
import { HtmlTooltip } from '../../../../../components/Tooltip/HtmlTooltip'
import {
  TProductFrequency,
  TProductPrice,
  TProductTypePayment,
} from '../../../../../core/types/Product'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import { payUrl } from '../../../../../core/functions/hosts'
import { useContext, useEffect, useState } from 'react'
import { ProductPricePayments } from './ProductPricePayments'
import { IMultiSelectedValueItem } from '../../../../../components/Dialogs/MultiSelectedValueDialog'
import { methodTypeCreditCard } from '../../../../../core/constants'
import { AuthContext } from '../../../../../core/context/AuthContext'
import { TUserType } from '../../../../../core/types/Auth'

export interface IProductPriceDetailProps {
  open: boolean
  sending: boolean
  price: TProductPrice | null
  typePaymentValue: TProductTypePayment
  offerName: TProductPrice | null
  buttonPositiveTitle: string
  methodTypes: IMultiSelectedValueItem[]
  options: number[]
  setOpen: (value: boolean) => void
  handleClose: () => void
  setPrice: (value: TProductPrice) => void
  isTenantBank: () => boolean
  onSave: () => void
  onCopyURL: (producerLink: boolean, index?: number) => void
  onPayments: () => void
  onOrderBump: () => void
  setCopied: (value: boolean) => void
  setMethodTypes: (
    value: React.SetStateAction<IMultiSelectedValueItem[]>
  ) => void
  onSavePayments: (value: IMultiSelectedValueItem[]) => void
}

const ProductPriceDetail = ({
  open,
  sending,
  price,
  typePaymentValue,
  offerName,
  buttonPositiveTitle,
  methodTypes,
  options,
  setOpen,
  handleClose,
  setPrice,
  isTenantBank,
  onSave,
  onCopyURL,
  onPayments,
  onOrderBump,
  setCopied,
  setMethodTypes,
  onSavePayments,
}: IProductPriceDetailProps) => {
  const codeBlock = `<script>
(function () {
    localStorage.setItem("codeId", "${price?.codeId}");
})();
</script>
<script
    defer="defer"
    src="${process.env.REACT_APP_STATIC_HOST}/bundle.js"
></script>
<div id="checkout-affiliatespay"></div>`

  const [errorPrice, setErrorPrice] = useState('')
  const [errorPayments, setErrorPayments] = useState('')
  const [errorFrequency, setErrorFrequency] = useState('')
  const [errorFirstPurchase, setErrorFirstPurchase] = useState('')
  const [errorOfferName, setErrorOfferName] = useState('')
  const [errorCycleNumbers, setErrorCycleNumbers] = useState('')

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const clearErrors = () => {
    if (errorPrice !== '') {
      setErrorPrice('')
    }

    if (errorPayments !== '') {
      setErrorPayments('')
    }

    if (errorFrequency !== '') {
      setErrorFrequency('')
    }

    if (errorFirstPurchase !== '') {
      setErrorFirstPurchase('')
    }

    if (errorOfferName !== '') {
      setErrorOfferName('')
    }

    if (errorCycleNumbers !== '') {
      setErrorCycleNumbers('')
    }
  }

  const getMethodTypeCreditCard = () => {
    return methodTypes
      .filter((x) => x.selected)
      .find((x) => x.id === methodTypeCreditCard.toString())
  }

  const isMethodTypeCreditCard = () => {
    const creditCard = getMethodTypeCreditCard()
    if (creditCard) return true

    return false
  }

  const getMethodTypeCreditCardInstallment = () => {
    const creditCard = getMethodTypeCreditCard()
    if (creditCard) return creditCard.value
    return 1
  }

  const handleSave = () => {
    clearErrors()
    var isError = false

    if (!price?.value) {
      setErrorPrice('Valor obrigatório R$ 5,00 ou mais')
      isError = true
    } else if (price.value < 5) {
      setErrorPrice('Valor obrigatório R$ 5,00 ou mais')
      isError = true
    }

    if ((price?.paymentMethods.length ?? 0) <= 0) {
      setErrorPayments('Selecione no mínimo um método de pagamento')
      isError = true
    }

    if (typePaymentValue === TProductTypePayment.RecurringSubscription) {
      if (!price?.frequency) {
        setErrorFrequency('Campo obrigatório')
        isError = true
      }

      if ((price?.firstPurchasePrice ?? 5) < 5) {
        setErrorFirstPurchase('Valor obrigatório R$ 5,00 ou mais')
        isError = true
      }

      if ((price?.cycleNumbers ?? '') === '') {
        setErrorCycleNumbers('Nº de Ciclos é um campo obrigatório')
        isError = true
      }
    }
    if (!price?.offerName) {
      setErrorOfferName('Campo obrigatório')
      isError = true
    }

    if (isError) {
      return
    }

    onSave()

    setErrorPrice('')
    setErrorPayments('')
    setErrorOfferName('')
  }

  const handleChangeMethodTypes = (data: IMultiSelectedValueItem[]) => {
    onSavePayments(data)
    setMethodTypes(data)
  }

  if (price === null) {
    return <></>
  }

  const handleChangeClose = () => {
    clearErrors()
    handleClose()
  }

  useEffect(() => {
    if (!open) clearErrors()
  }, [open])

  return (
    <SwipeableDrawerRightDialog
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      title="Oferta do produto"
      subtitle="Defina as configurações da oferta"
      buttons={
        isAdmin
          ? []
          : [
              {
                title: 'Cancelar',
                onClick: () => setOpen(false),
                type: 'negative',
              },
              {
                title: buttonPositiveTitle,
                onClick: handleSave,
                type: 'positive',
              },
            ]
      }
      buttonsDisabled={sending}
    >
      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
        <FormControl fullWidth size="small">
          <TextField
            fullWidth
            disabled={isAdmin}
            size="small"
            variant="outlined"
            type="text"
            label="Nome da oferta"
            inputProps={{ maxLength: 150 }}
            error={errorOfferName !== ''}
            value={price.offerName ?? ''}
            onChange={(e) => {
              setPrice({ ...price, offerName: e.target.value })
              setErrorOfferName('')
            }}
          />
          {errorOfferName !== '' && (
            <Typography variant="overline" color={'red'}>
              {errorOfferName}
            </Typography>
          )}
        </FormControl>
        <FormControl fullWidth size="small">
          <TextField
            fullWidth
            disabled={isAdmin}
            size="small"
            variant="outlined"
            type="number"
            label="Preço"
            InputProps={{ inputProps: { min: 5 }, startAdornment: 'R$' }}
            error={errorPrice !== ''}
            value={price.value === undefined ? '' : price.value}
            onChange={(e) => {
              setPrice({
                ...price,
                value:
                  e.target.value === ''
                    ? undefined
                    : NumberFunctions.toNumber(e.target.value),
              })
              setErrorPrice('')
            }}
          />
          {errorPrice !== '' && (
            <Typography variant="overline" color={'red'}>
              {errorPrice}
            </Typography>
          )}
        </FormControl>
        {typePaymentValue === TProductTypePayment.RecurringSubscription && (
          <FormControl fullWidth size="small" error={errorFrequency !== ''}>
            <InputLabel id="days-select-label">Frequência cobrança</InputLabel>
            <Select
              variant="outlined"
              disabled={isAdmin}
              labelId="days-select-label"
              label="Frequência cobrança"
              value={Number(price.frequency)}
              onChange={(e) => {
                setPrice({
                  ...price,
                  frequency: e.target.value as TProductFrequency,
                })
                setErrorFrequency('')
              }}
            >
              <MenuItem value={Number(TProductFrequency.Weekly)}>
                {Convertions.productFrequencyToString(TProductFrequency.Weekly)}
              </MenuItem>
              <MenuItem value={Number(TProductFrequency.Monthly)}>
                {Convertions.productFrequencyToString(
                  TProductFrequency.Monthly
                )}
              </MenuItem>
              <MenuItem value={Number(TProductFrequency.Bimonthly)}>
                {Convertions.productFrequencyToString(
                  TProductFrequency.Bimonthly
                )}
              </MenuItem>
              <MenuItem value={Number(TProductFrequency.Quarterly)}>
                {Convertions.productFrequencyToString(
                  TProductFrequency.Quarterly
                )}
              </MenuItem>
              <MenuItem value={Number(TProductFrequency.Semester)}>
                {Convertions.productFrequencyToString(
                  TProductFrequency.Semester
                )}
              </MenuItem>
              <MenuItem value={Number(TProductFrequency.Yearly)}>
                {Convertions.productFrequencyToString(TProductFrequency.Yearly)}
              </MenuItem>
            </Select>
            {errorFrequency !== '' && (
              <Typography variant="overline" color={'red'}>
                {errorFrequency}
              </Typography>
            )}
          </FormControl>
        )}
        {typePaymentValue === TProductTypePayment.RecurringSubscription && (
          <FormControl fullWidth size="small">
            <TextField
              fullWidth
              disabled={isAdmin}
              size="small"
              variant="outlined"
              type="number"
              label="Preço primeira compra"
              InputProps={{ inputProps: { min: 5 }, startAdornment: 'R$' }}
              error={errorFirstPurchase !== ''}
              value={
                price.firstPurchasePrice === undefined
                  ? ''
                  : price.firstPurchasePrice
              }
              onChange={(e) => {
                setPrice({
                  ...price,
                  firstPurchasePrice:
                    e.target.value === ''
                      ? undefined
                      : NumberFunctions.toNumber(e.target.value),
                })
                setErrorFirstPurchase('')
              }}
            />
            {errorFirstPurchase !== '' && (
              <Typography variant="overline" color={'red'}>
                {errorFirstPurchase}
              </Typography>
            )}
          </FormControl>
        )}

        {typePaymentValue == TProductTypePayment.RecurringSubscription && (
          <Box>
            <TextField
              fullWidth
              disabled={isAdmin}
              size="small"
              type="number"
              label="Nº de Ciclos (Informar '0' para cobranças recorrentes sem limites de ciclos)"
              InputLabelProps={{
                shrink: (price.cycleNumbers ?? '') !== '',
              }}
              inputProps={{ inputMode: 'numeric' }}
              value={price.cycleNumbers === undefined ? '' : price.cycleNumbers}
              onChange={(e) => {
                setPrice({
                  ...price,
                  cycleNumbers:
                    e.target.value === ''
                      ? undefined
                      : NumberFunctions.toNumber(e.target.value),
                })
                setErrorCycleNumbers('')
              }}
            />
            {errorCycleNumbers !== '' && (
              <Typography variant="overline" color={'red'}>
                {errorCycleNumbers}
              </Typography>
            )}
          </Box>
        )}

        <FormControlLabel
          control={
            <SwitchRound
              sx={{ mr: 1 }}
              size="small"
              disabled={isAdmin}
              checked={price.hideCheckoutAddress}
              onChange={(e) =>
                setPrice({ ...price, hideCheckoutAddress: e.target.checked })
              }
            />
          }
          label={
            <Typography
              color="#707275"
              fontSize="14px"
              lineHeight="18px"
              fontWeight={500}
            >
              Ocultar dados de endereço no checkout
            </Typography>
          }
          sx={{ width: '100%' }}
        />

        {isTenantBank() && price.id !== '' && (
          <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <TextField
              fullWidth
              disabled={isAdmin}
              size="small"
              label="Link checkout"
              value={`${payUrl}/${price.codeId}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HtmlTooltip title={<Typography>Copiar URL</Typography>}>
                      <IconButton size="small" onClick={() => onCopyURL(false)}>
                        <img src="/assets/icons/copy/copyGrey.svg" alt="" />
                      </IconButton>
                    </HtmlTooltip>
                  </InputAdornment>
                ),
              }}
            />
            {/* <TextField
              fullWidth
              size="small"
              label="Link imune de afiliado"
              value={`${payUrl}/pro/${price.codeId}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HtmlTooltip title={<Typography>Copiar URL</Typography>}>
                      <IconButton size="small" onClick={() => onCopyURL(true)}>
                        <img src="/assets/icons/copy/copyGrey.svg" alt="" />
                      </IconButton>
                    </HtmlTooltip>
                  </InputAdornment>
                ),
              }}
            /> */}
          </Stack>
        )}

        <Typography
          color="#343948"
          fontSize="16px"
          lineHeight="20px"
          fontWeight={700}
        >
          Formas de Pagamento
        </Typography>
        <ProductPricePayments
          selectds={methodTypes}
          setSelectds={handleChangeMethodTypes}
          onPayments={onPayments}
          disabled={isAdmin}
        />
        {errorPayments !== '' && (
          <Typography variant="overline" color={'red'}>
            {errorPayments}
          </Typography>
        )}

        {isMethodTypeCreditCard() && (
          <FormControl fullWidth>
            <InputLabel id="select-label">
              Max. Parcelas Cartão de Crédito
            </InputLabel>
            <Select
              variant="outlined"
              disabled={isAdmin}
              size="small"
              labelId="select-label"
              label="Max. Parcelas Cartão de Crédito"
              value={getMethodTypeCreditCardInstallment()}
              onChange={(e) => {
                const creditCard = getMethodTypeCreditCard()
                if (creditCard) {
                  creditCard.value = Number(e.target.value)
                  const newItems = methodTypes.filter(
                    (x) => x.id !== methodTypeCreditCard.toString()
                  )
                  handleChangeMethodTypes([...newItems, { ...creditCard }])
                }
              }}
            >
              {options?.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {!isAdmin && (
          <Box>
            <Button
              variant="outlined"
              size="large"
              sx={{ textTransform: 'none' }}
              onClick={onOrderBump}
            >
              <Typography fontWeight={700} fontSize="14px" lineHeight="24px">
                + Adicionar Order Bump
              </Typography>
            </Button>
          </Box>
        )}

        {price.orderBumps && price.orderBumps.length > 0 && (
          <Grid
            container={true}
            direction={'row'}
            spacing={1}
            style={{ overflowY: 'hidden' }}
          >
            {price.orderBumps
              ?.sort((a, b) => (a.order || 0) - (b.order || 0))
              .map((orderBump, orderBumpIndex) => (
                <Grid item={true} key={`productOrderBump${orderBumpIndex}`}>
                  <Chip
                    disabled={isAdmin}
                    label={`${orderBump.order} - ${
                      orderBump.productPrice?.product?.name || ''
                    } - ${'R$'}${(orderBump.productPrice?.value || 0).toFixed(2)}`}
                    onDelete={() => {
                      price.orderBumps = price.orderBumps?.filter(
                        (ob) => ob.orderBumpId !== orderBump.orderBumpId
                      )
                      setPrice({ ...price })
                    }}
                    sx={{
                      backgroundColor: 'rgba(216, 217, 255, 1)',
                      color: 'rgba(28, 82, 189, 1)',
                      fontFamily: 'Montserrat',
                      fontWeight: 500,
                      fontSize: '12px',
                      lineHeight: '16px',
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        )}

        {/*isTenantBank() && price.id !== '' && (
                    <Stack direction="column" spacing={1} sx={{ p: 1 }}>
                        <Typography variant="subtitle2">Código do widget de página de pagamento</Typography>
                        <CopyBlock
                            text={codeBlock}
                            codeBlock={true}
                            language="javascript"
                            showLineNumbers={6}
                            startingLineNumber={1}
                            wrapLines
                            wrapLongLines
                            copied
                            theme={solarizedDark}
                            onCopy={() => setCopied(true)}
                        />
                        <Typography variant="caption">Incorpore um atalho facilitado da sua página de pagamento a qualquer endereço web por meio do código</Typography>
                    </Stack>
                )*/}
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}

export default ProductPriceDetail
