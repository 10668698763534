import { FieldArrayWithId, UseFormWatch } from 'react-hook-form'
import { TProduct, TProductPrice } from '../../../../../core/types/Product'
import { Box, Stack, Typography } from '@mui/material'
import ListItemTitleTypography from '../../../../../components/Typographys/ListItemTitleTypography'
import ProductPriceItem from './ProductPriceItem'
import { useResponsive } from '../../../../../core/hooks/useResponsive'

export interface IProductPriceListProps {
  isTenantBank: boolean
  fields: FieldArrayWithId<TProduct, 'prices', 'id'>[]
  watch: UseFormWatch<TProduct>
  onCopyURL: (producerLink: boolean, index?: number) => void
  onOpenPrice: (productPrice: TProductPrice, index: number) => void
  onOpenDisable: (productPrice: TProductPrice, index: number) => void
  onDelete: (productPrice: TProductPrice, index: number) => void
}

const ProductPriceList = (data: IProductPriceListProps) => {
  const mdUp = useResponsive('up', 'md')

  if (data.fields.length <= 0) {
    return (
      <Typography variant="body1" align="center" sx={{ p: 3 }}>
        Nenhum preço encontrado para este produto. Clique em NOVA OFERTA e
        cadastre os preços para o produto e obtenha links para o checkout.
      </Typography>
    )
  }

  return (
    <>
      {data.fields.length > 0 && mdUp && (
        <Box sx={{ pt: 2 }}>
          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            alignItems="center"
            sx={{ px: 2 }}
          >
            <Box sx={{ width: '20%' }}>
              <ListItemTitleTypography>Nome</ListItemTitleTypography>
            </Box>
            <Box sx={{ width: '12%' }}>
              <ListItemTitleTypography align="right">
                Preço
              </ListItemTitleTypography>
            </Box>
            <Box sx={{ width: '12%' }}>
              <ListItemTitleTypography align="center">
                Status
              </ListItemTitleTypography>
            </Box>
            <Box sx={{ width: '14%' }}>
              <ListItemTitleTypography align="center">
                Ocultar endereço
              </ListItemTitleTypography>
            </Box>
            <Box sx={{ width: '14%' }}>
              <ListItemTitleTypography>Pagamentos</ListItemTitleTypography>
            </Box>
            <Box sx={{ width: '14%', pl: 5 }}>
              <ListItemTitleTypography>URL</ListItemTitleTypography>
            </Box>
            <Box sx={{ width: '14%' }}>
              <ListItemTitleTypography align="center">
                Ações
              </ListItemTitleTypography>
            </Box>
          </Stack>
        </Box>
      )}
      {data.fields.map((price, index) => (
        <ProductPriceItem price={price} index={index} {...data} />
      ))}
    </>
  )
}

export default ProductPriceList
