import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

interface FilterAbandonedLastDaysProps {
  width?: number | string | undefined
  lastDays: number
  setLastDays: (value: number) => void
}

const FilterAbandonedLastDays = ({
  width,
  lastDays,
  setLastDays,
}: FilterAbandonedLastDaysProps) => {
  return (
    <FormControl sx={{ width: width }}>
      <InputLabel id="days-select-label">Últimos</InputLabel>
      <Select
        variant="outlined"
        labelId="days-select-label"
        label="Últimos"
        defaultValue={30}
        value={lastDays}
        onChange={(e) => setLastDays(Number(e.target.value))}
      >
        <MenuItem value={30}>30 dias</MenuItem>
        <MenuItem value={60}>60 dias</MenuItem>
        <MenuItem value={90}>90 dias</MenuItem>
        <MenuItem value={180}>180 dias</MenuItem>
      </Select>
    </FormControl>
  )
}

export default FilterAbandonedLastDays
