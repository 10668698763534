import { Button } from '@mui/material'

interface IBlackButtonProps {
  children: React.ReactNode
  startIcon?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const BlackButton = ({ children, startIcon, onClick }: IBlackButtonProps) => {
  return (
    <Button
      size="small"
      onClick={onClick}
      startIcon={startIcon}
      sx={{
        backgroundColor: 'rgba(56, 57, 59, 1)',
        color: 'white',
        '&:hover': {
          backgroundColor: 'rgba(56, 57, 59, 0.9)',
        },

        borderRadius: '4px',
        padding: '2px 8px 2px 4px',
        gap: '8px',
        textTransform: 'none',
      }}
    >
      {children}
    </Button>
  )
}

export default BlackButton
