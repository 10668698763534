import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import TablePaginationDefault from './TablePaginationDefault'
import React from 'react'
import DataTableCellTypography from '../Typographys/DataTableCellTypography'

export type DataTableRowRender<T> = (row: T, index: number) => React.ReactNode

export type DataTableColumnRender<T> = (
  id: string,
  value: any,
  row: T
) => React.ReactNode

export interface IDataTableColumn<T> {
  id: string
  label: string
  minWidth?: number
  width?: number | string | undefined
  align?: 'right' | 'left' | 'center'
  sx?: SxProps<Theme>
  format?: (value: any) => string
  render?: DataTableColumnRender<T>
}

export type TDataTablePagination<T> = {
  items: T[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
  info?: number
}

export interface IDataTableProps<T> {
  key?: string | undefined
  columns: readonly IDataTableColumn<T>[]
  data: TDataTablePagination<T>
  page: number
  rowsPerPage: number
  hasNewCustomHeader?: boolean
  headerComponent?: React.ReactNode
  headerMobileComponent?: React.ReactNode
  hasPagination?: boolean
  headerBackgroundColor?: string
  onItemClick?: (row: T) => void
  onPageChange: (page: number) => void
  onRowsPerPageChange: (rows: number) => void
  renderItemMobile?: DataTableRowRender<T>
}

const DataTable = <T,>({
  key,
  columns,
  data,
  page,
  rowsPerPage,
  hasNewCustomHeader,
  headerComponent,
  headerMobileComponent,
  hasPagination = true,
  headerBackgroundColor,
  onItemClick,
  onPageChange,
  onRowsPerPageChange,
  renderItemMobile,
}: IDataTableProps<T>) => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )
  const isRenderMobile = isSmallScreen && renderItemMobile

  return (
    <Box key={key}>
      {isSmallScreen ? headerMobileComponent : headerComponent}
      {!isRenderMobile && (
        <TableContainer sx={{ width: '100%', overflow: 'hidden' }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ borderSpacing: '0px 10px', overflow: 'hidden' }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={`${column.id}-${index}-${key ?? ''}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth, width: column.width }}
                    sx={{
                      borderColor: 'transparent',
                      py: '0px',
                      backgroundColor: headerBackgroundColor,
                    }}
                  >
                    <Typography
                      color="#707275"
                      fontWeight={600}
                      fontSize="14px"
                      lineHeight="18px"
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: 'transparent' }}>
              {data.items.map((row, rowIndex) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`${rowIndex.toString()}-${key ?? ''}`}
                    sx={{
                      cursor: onItemClick ? 'pointer' : undefined,
                      backgroundColor: 'white',
                    }}
                  >
                    {columns.map((column, index) => {
                      const value = (row as any)[column.id]
                      const isColumnFirst = index === 0
                      const isColumnLast = columns.length - 1
                      const borderFirstRadius = isColumnFirst ? '4px' : '0px'
                      const borderLastRadius = isColumnLast ? '4px' : '0px'
                      return (
                        <TableCell
                          key={`${rowIndex}-${column.id}-${index}-${key ?? ''}`}
                          align={column.align}
                          width={column.width}
                          onClick={() => {
                            if (onItemClick) {
                              onItemClick(row)
                            }
                          }}
                          sx={{
                            borderTopLeftRadius: borderFirstRadius,
                            borderBottomLeftRadius: borderFirstRadius,
                            borderTopRightRadius: borderLastRadius,
                            borderBottomRightRadius: borderLastRadius,
                            borderColor: 'transparent',
                          }}
                        >
                          {column.render &&
                            column.render(column.id, value, row)}
                          {!column.render && (
                            <DataTableCellTypography sx={column.sx}>
                              {column.format
                                ? column.format(value)
                                : value?.toString() ?? ''}
                            </DataTableCellTypography>
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isRenderMobile &&
        data.items.map((row, rowIndex) => {
          return renderItemMobile(row, rowIndex)
        })}

      {hasPagination && (
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          hasNewCustomHeader={hasNewCustomHeader}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </Box>
  )
}

export default DataTable
