import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import BlockIcon from '@mui/icons-material/Block'
import { TProduct } from '../../../core/types/Product'
import { useSituationService } from '../../../core/hooks/useSituationActions'
import { ProductSituationEnum } from '../../../core/types/Situation'
import { dateTimeString } from '../../../core/functions/dateTime'

interface ProductSituationBlockModalProps {
  row: TProduct
}

const ProductSituationBlockModal: React.FC<ProductSituationBlockModalProps> = ({
  row,
}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { data } = useSituationService({
    productId: row.id || '',
  })
  const situationBlockedItem = data.find(
    (item) => item.situation === ProductSituationEnum.Blocked
  )

  return (
    <>
      <BlockIcon onClick={handleOpen} style={{ cursor: 'pointer' }} />
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <Typography variant="h6" component="h2">
            {row.situationDescription} -{' '}
            {dateTimeString(situationBlockedItem?.createdDate)}
          </Typography>
          <Typography sx={{ mt: 2 }}>{situationBlockedItem?.reason}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={handleClose} variant="contained">
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default ProductSituationBlockModal
