export enum TypeButtonAppEnum {
  redirect = 1,
  sideBar = 2,
}

export enum TAppSideBar {
  voxuy = 1,
  gtm = 2,
}

export type TApp = {
  name: string
  to: string
  image: string
  typeButton: TypeButtonAppEnum
  sideBar?: TAppSideBar | undefined
}

export const apps: TApp[] = [
  {
    name: 'Voxuy',
    to: '',
    image: `${process.env.PUBLIC_URL}/assets/apps/voxuy.svg`,
    typeButton: TypeButtonAppEnum.sideBar,
    sideBar: TAppSideBar.voxuy,
  },
  {
    name: 'Astron Members',
    to: '/apps/astronmembers',
    image: `${process.env.PUBLIC_URL}/assets/apps/astronMembers.svg`,
    typeButton: TypeButtonAppEnum.redirect,
    sideBar: undefined,
  },
  {
    name: 'Notazz',
    to: '/apps/notazz',
    image: `${process.env.PUBLIC_URL}/assets/apps/notazzIcon.png`,
    typeButton: TypeButtonAppEnum.redirect,
    sideBar: undefined,
  },
  {
    name: 'Active Campaign',
    to: '/apps/activecampaign',
    image: `${process.env.PUBLIC_URL}/assets/apps/activeCampaing.svg`,
    typeButton: TypeButtonAppEnum.redirect,
    sideBar: undefined,
  },
  {
    name: 'Webhooks',
    to: '/apps/webhooks',
    image: `${process.env.PUBLIC_URL}/assets/apps/webhooks.svg`,
    typeButton: TypeButtonAppEnum.redirect,
    sideBar: undefined,
  },
  {
    name: 'Google Tag Manager',
    to: '',
    image: `${process.env.PUBLIC_URL}/assets/apps/gtm.png`,
    typeButton: TypeButtonAppEnum.sideBar,
    sideBar: TAppSideBar.gtm,
  },
  {
    name: 'The Members',
    to: '/apps/themembers',
    image: `${process.env.PUBLIC_URL}/assets/apps/theMembers.svg`,
    typeButton: TypeButtonAppEnum.redirect,
    sideBar: undefined,
  },
  {
    name: 'Cademí',
    to: '/apps/cademi',
    image: `${process.env.PUBLIC_URL}/assets/apps/cademi.svg`,
    typeButton: TypeButtonAppEnum.redirect,
    sideBar: undefined,
  },
  {
    name: 'UTMIfy',
    to: '/apps/utmfy',
    image: `${process.env.PUBLIC_URL}/assets/apps/utmfy.png`,
    typeButton: TypeButtonAppEnum.redirect,
    sideBar: undefined,
  },
]
