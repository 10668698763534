import React, { useEffect } from "react";
import { IErrorResponse } from "../types/ErrorResponse";
import { TProductAffiliation } from "../types/Product";
import AffiliationController from "../controllers/AffiliationController";

export interface IProductAffiliationProp {
    code?: string | null;
}

export const useProductAffiliation = ({ code }: IProductAffiliationProp) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<TProductAffiliation | null>(null);
    const [error, setError] = React.useState<string>('');

    const execute = async () => {
        if (!code) {
            if (data) {
                setData(null);
            }
            return;
        }

        if (!loading) {
            try {
                setLoading(true);

                if (error !== '') {
                    setError('');
                }
                if (data !== null) {
                    setData(null);
                }

                const response = await AffiliationController.getProductAffiliation({ code });
                const responseError = response as IErrorResponse;
                const responseData = response as TProductAffiliation;

                if (responseError.code) {
                    setError(responseError.error);
                } else {
                    setData(responseData);
                }
            } catch (e) {
                var message = 'Erro não identificado';
                if (typeof e === "string") {
                    message = e.toUpperCase();
                } else if (e instanceof Error) {
                    const err = e as Error;
                    message = err.message;
                }
                setError(message);
            } finally {
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        execute();
    }, []);

    const clear = () => {
        setError('');
    }

    return {
        loading,
        data,
        error,
        execute,
        clear,
    };
}