import { CreditCard, CreditCardType } from "../types/CreditCard";
import { startNumberDiscover } from "../constants";
import CreditCards from "../functions/creditCards";

export class CreditCardDiscover implements CreditCard {
    getType(): CreditCardType {
        return CreditCardType.Discover;
    }
    compare(number?: string): boolean {
        return CreditCards.compare(startNumberDiscover, number);
    }
    getImageUrl() {
        return `${process.env.PUBLIC_URL}/assets/creditcards/discover.png`;
    }
}