import { useEffect, useState } from 'react'
import { Box, Slide } from '@mui/material'
import { useCurrentTenantBank } from '../../../core/hooks/useCurrentTenantBank'
import { useForm } from 'react-hook-form'
import { ITenantBankAccount } from '../../../core/types/Tenant'
import { maskCpfCnpjNumber } from '../../../masks/masks'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import TenantBankAccountData from './TenantBankAccountData'

export const TenantBankAccount = ({ tenantId }: { tenantId: string }) => {
  const { data, loading } = useCurrentTenantBank({ tenantId: tenantId })
  const [isNew, setIsNew] = useState(true)
  const [animationState, setAnimationState] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<ITenantBankAccount>({
    defaultValues: {
      tenantId: tenantId,
      accountName: '',
      accountNumber: '',
      accountNumberDigit: '',
      accountType: 0,
      agencyNumber: '',
      agencyNumberCheck: null,
      bankCode: '',
    },
  })

  const documentValue = watch('recipientDocument')

  useEffect(() => {
    setAnimationState(true)
    return () => setAnimationState(false)
  }, [])

  useEffect(() => {
    const documentComplete = maskCpfCnpjNumber(documentValue)
    setValue('recipientDocument', documentComplete)
  }, [documentValue, setValue])

  useEffect(() => {
    if (data !== null) {
      reset(data)
      setIsNew(false)
    }

    return () => {
      if (data !== null) {
        reset(data)
      }
    }
  }, [data, reset])

  const Submit = async (data: any) => {
    /*setSending(true);
    setSuccess(false);
    setError('');
    data.recipientDocument = data.recipientDocument.replace(/[^0-9]/g, '');
    if (!data.agencyNumberCheck) data.agencyNumberCheck = -1;

    try {
      let response;
      if (isNew)
        response = await TenantController.insertBank({ data });
      else
        response = await TenantController.updateBank({ data });

      if (!response.success) {
        setError(response.error);
      } else {
        setSuccess(true);
      }
    } finally {
      setSending(false);
    }*/
  }

  return (
    <Box>
      <Box sx={{ width: '100%', margin: '0 auto' }}>
        <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
          <Box>
            <TenantBankAccountData
              control={control}
              errors={errors}
              embedded={false}
              watch={watch}
              handleSubmit={handleSubmit}
              submit={Submit}
              disableFields={true}
              hasEdit={false}
            />
          </Box>
        </Slide>
      </Box>

      <LoadingBackdrop open={loading || sending} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Alterado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}
