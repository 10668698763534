import { Box, Button, Chip, Stack, Typography } from '@mui/material'
import AccordionDefault from '../../../components/Accordions/AccordionDefault'
import AccordionSummaryDefault from '../../../components/Accordions/AccordionSummaryDefault'
import { dateTimeString } from '../../../core/functions/dateTime'
import OrderFunctions from '../../../core/functions/OrderFunctions'
import AccordionDetailsDefault from '../../../components/Accordions/AccordionDetailsDefault'
import Convertions from '../../../core/functions/convertions'
import { TLostSale } from '../../../core/types/LostSale'
import { maskPhoneNumber } from '../../../masks/masks'
import Hosts from '../../../core/functions/hosts'

interface AbandonedItemMobileProps {
  item: TLostSale
  index: number
  setCopied: (value: boolean) => void
}

const AbandonedItemMobile = ({
  item,
  index,
  setCopied,
}: AbandonedItemMobileProps) => {
  const url = OrderFunctions.getUrlCheckoutAbandoned(item)
  const whatsapp = OrderFunctions.getTextWhatsAppAbandoned(item)

  return (
    <Box key={`abandoned${index}`} width="100%" sx={{ mb: 1, px: 2 }}>
      <AccordionDefault>
        <AccordionSummaryDefault>
          <Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight={500}
                    fontSize="14px"
                    lineHeight="18px"
                    color="rgba(56, 57, 59, 1)"
                  >
                    {item.productName}
                  </Typography>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight={500}
                    fontSize="12px"
                    lineHeight="16px"
                    color="rgba(99, 126, 239, 1)"
                  >
                    {dateTimeString(item.createdDate)}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Chip
                  label={
                    <Typography fontSize={12} lineHeight={16} fontWeight={500}>
                      {OrderFunctions.getDiffDaysDescription(item.createdDate)}
                    </Typography>
                  }
                  size="small"
                  sx={{
                    backgroundColor: '#FBF3E2',
                    color: '#887100',
                  }}
                />
              </Box>
            </Stack>
          </Box>
        </AccordionSummaryDefault>

        <AccordionDetailsDefault>
          <Box>
            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Vendedor:
              </Typography>
              <Stack direction={'row'} spacing={1}>
                <Typography
                  fontFamily="Montserrat"
                  fontWeight={400}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(28, 82, 189, 1)"
                >
                  {Convertions.valueTypeToString(item.valueType)}
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontWeight={600}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(56, 57, 59, 1)"
                >
                  {item.sellerName}
                </Typography>
              </Stack>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Cliente:
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontWeight={600}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                {item.name}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Celular:
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontWeight={600}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                {maskPhoneNumber(item.phone)}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Checkout:
              </Typography>
              <Button
                variant="text"
                size="small"
                color="success"
                startIcon={
                  <img src="/assets/icons/actions/copy-green.svg" alt="whats" />
                }
                sx={{ textTransform: 'none' }}
                onClick={async (event) => {
                  event.stopPropagation()
                  await Hosts.copyTextToClipboard(url)
                  setCopied(true)
                }}
              >
                <Typography
                  fontFamily="Montserrat"
                  fontWeight={400}
                  fontSize="12px"
                  lineHeight="16px"
                >
                  Copiar Link
                </Typography>
              </Button>
            </Box>

            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              sx={{ mt: 1 }}
            >
              <Button
                size="small"
                onClick={(event) => {
                  event.stopPropagation()
                  Hosts.openNewBlank(
                    OrderFunctions.getTextWhatsAppAbandoned(item)
                  )
                }}
                startIcon={
                  <img
                    src="/assets/icons/actions/whatsapp-white.svg"
                    alt="whats"
                  />
                }
                sx={{
                  backgroundColor: 'rgba(56, 57, 59, 1)',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(56, 57, 59, 0.9)',
                  },
                  width: '120px',
                  height: '24px',

                  borderRadius: '4px',
                  padding: '2px 8px 2px 4px',
                  gap: '8px',
                  textTransform: 'none',
                }}
              >
                <Typography
                  sx={{
                    width: '61px',
                    height: '16px',
                    fontFamily: 'Montserrat',
                    fontWeight: 700,
                    fontSize: '12px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Recuperar
                </Typography>
              </Button>
            </Stack>
          </Box>
        </AccordionDetailsDefault>
      </AccordionDefault>
    </Box>
  )
}

export default AbandonedItemMobile
