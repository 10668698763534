import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slide,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useState } from 'react'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { TProductPrice } from '../../../../core/types/Product'
import { IOrderBump } from '../../../../core/types/OrderBump'
import { useForm } from 'react-hook-form'
import { useProductsSimple } from '../../../../core/hooks/useProductsSimple'
import { useOrderBump } from '../../../../core/hooks/useOrderBump'
import ProductController from '../../../../core/controllers/ProductController'
import { IErrorResponse } from '../../../../core/types/ErrorResponse'
import OrderBumpController from '../../../../core/controllers/OrderBumpController'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import DottedBoxWithBadge from '../OrderBumpsEdit/DottedBoxWithBadge'
import NumberFunctions from '../../../../core/functions/NumberFunctions'

export interface IOrderBumpRegisterProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  execute: () => Promise<void>
}

const OrderBumpsRegister: React.FC<IOrderBumpRegisterProps> = ({
  open,
  setOpen,
  execute,
}) => {
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [productPrices, setProductPrices] = React.useState<TProductPrice[]>([])

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<IOrderBump>({
    defaultValues: {
      id: undefined,
      productPriceId: '',
      title: '',
      description: '',
      descriptionPrice: '',
      productPrice: {
        id: '',
        name: '',
        value: undefined,
        product: {
          id: '',
          name: '',
        },
      },
      hasUnmissableTag: false,
      anchoringPrice: 0,
    },
  })

  const productId = watch('productPrice.product.id')
  const { data: products, loading: loadingProducts } = useProductsSimple({
    initialExecute: true,
  })

  const description = watch('description')
  const hasUnmissableTag = watch('hasUnmissableTag')
  const title = watch('title')
  const imgURL = watch('imageProductUrl')
  const productName = watch('productPrice.product.name')
  const productOfferValue = productPrices.find(
    (x) => x.id === watch('productPriceId')
  )?.value
  const anchoringPrice = watch('anchoringPrice')

  const getOrderBumpPricesByProduct = async () => {
    if (productId && productId !== null && productId !== '') {
      setSending(true)
      try {
        const response = await ProductController.getOrderBumpPricesByProduct({
          productId,
          orderBumpId: null,
        })
        const err = response as IErrorResponse
        if (err.code) {
          setError(err.error)
        } else {
          setProductPrices(response as TProductPrice[])
        }
      } finally {
        setSending(false)
      }
    }
  }

  React.useEffect(() => {
    getOrderBumpPricesByProduct()
    return () => {}
  }, [productId])

  const Submit = async (data: any) => {
    setSending(true)
    setSuccess(false)
    setError('')
    try {
      var response

      response = await OrderBumpController.insert({ data })

      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        setOpen(false)
        execute()
        reset()
      }
    } finally {
      setSending(false)
    }
  }

  return (
    <Box>
      <SwipeableDrawerRightDialog
        open={open}
        onClose={() => {
          setOpen(false)
          reset()
        }}
        setOpen={setOpen}
        title="Novo Order Bump"
        subtitle="Preencha os dados corretamente"
        buttons={[
          {
            title: 'Cancelar',
            onClick: () => {
              setOpen(false)
              reset()
            },
            type: 'negative',
          },
          {
            title: 'Salvar',
            type: 'positive',
            event: 'submit',
          },
        ]}
        maxWidth={'lg'}
        noValidate
        component="form"
        onSubmit={handleSubmit(Submit)}
      >
        <Box>
          <Box sx={{ width: '100%', margin: '0 auto' }}>
            <Box sx={{ alignItems: 'center' }}>
              <Stack
                component={'form'}
                noValidate
                direction={isSmallScreen ? 'column' : 'row'}
                pt={2}
                spacing={3}
                sx={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onSubmit={handleSubmit(Submit)}
                gap="32px"
              >
                <Stack spacing={3} width={isSmallScreen ? '100%' : '50%'}>
                  <Autocomplete
                    disablePortal
                    options={products}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, option) => {
                      if (option && option !== null) {
                        setValue('productPrice.product.id', option.id)
                        setValue('productPrice.product.name', option.name)
                        setValue('productPriceId', '')
                        setValue('imageProductUrl', option.imageUrl)
                      }
                    }}
                    value={{
                      id: watch('productPrice.product.id'),
                      name: watch('productPrice.product.name'),
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Produto"
                        {...register('productPrice.product.name')}
                      />
                    )}
                  />
                  <FormControl fullWidth disabled={productPrices.length <= 0}>
                    <InputLabel id="product-select-label">
                      Oferta do Produto
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="product-select-label"
                      label="Oferta do Produto"
                      value={watch('productPriceId')}
                      {...register('productPriceId', { required: true })}
                      error={errors.productPriceId?.type === 'required'}
                    >
                      {productPrices.map((price) => (
                        <MenuItem value={price.id}>
                          {price.value?.toFixed(2)}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.productPriceId?.type === 'required' && (
                      <Typography variant="overline" color={'red'}>
                        Obrigatório
                      </Typography>
                    )}
                  </FormControl>
                  <Box>
                    <TextField
                      fullWidth
                      multiline
                      type="text"
                      label="Título"
                      {...register('title', { required: true })}
                      inputProps={{ maxLength: 150 }}
                      error={errors.title?.type === 'required'}
                    />
                    {errors.title?.type === 'required' && (
                      <Typography variant="overline" color={'red'}>
                        Título é um campo obrigatório
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <TextField
                      fullWidth
                      type="number"
                      label="Preço de ancoragem"
                      {...register('anchoringPrice', { required: true })}
                      InputProps={{
                        inputProps: { min: 5 },
                        startAdornment: 'R$',
                      }}
                      error={errors.anchoringPrice?.type === 'required'}
                    />
                    {errors.anchoringPrice?.type === 'required' && (
                      <Typography variant="overline" color={'red'}>
                        Preço de ancoragem obrigatório
                      </Typography>
                    )}
                  </Box>

                  <Stack gap="8px">
                    <Box>
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        type="text"
                        label="Descrição"
                        {...register('description', { required: true })}
                        inputProps={{ maxLength: 350 }}
                        error={errors.description?.type === 'required'}
                      />
                      {errors.description?.type === 'required' && (
                        <Typography variant="overline" color={'red'}>
                          Descrição é um campo obrigatório
                        </Typography>
                      )}
                    </Box>
                    <Typography
                      color="#707070"
                      fontSize="12px"
                      fontWeight="400"
                    >
                      Descrição que aparece para seus compradores
                    </Typography>
                  </Stack>

                  {/* <Stack>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="medium"
                          checked={watch('hasUnmissableTag') ?? true}
                          onChange={(e) => {
                            setValue('hasUnmissableTag', e.target.checked)
                          }}
                        />
                      }
                      label={
                        <Typography
                          lineHeight="18px"
                          fontSize={{ xs: '13px', sm: '14px' }}
                          fontWeight={400}
                          sx={{
                            color: '#5D5E61',
                          }}
                        >
                          Adicionar tag “Imperdível”
                        </Typography>
                      }
                    />
                  </Stack> */}
                </Stack>

                <DottedBoxWithBadge
                  description={description}
                  hasUnmissableTag={hasUnmissableTag}
                  imgURL={imgURL}
                  title={title}
                  productOfferValue={productOfferValue}
                  anchoringPrice={anchoringPrice}
                  productName={productName}
                />
              </Stack>
            </Box>
          </Box>
        </Box>
      </SwipeableDrawerRightDialog>
      <LoadingBackdrop open={sending || loadingProducts} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar
        open={success}
        onClose={() => {
          setSuccess(false)
          setOpen(false)
        }}
      >
        Cadastrado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

export default OrderBumpsRegister
