import { Box, Chip, Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import Convertions from "../../../../../core/functions/convertions";
import { TProductLink, TProductLinkCategory } from "../../../../../core/types/Product";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ProductLinkRegister from "./ProductLinkRegister";

export interface IProductLinkDataProps {
    isAffiliate: boolean;
    links: TProductLink[];
    productLink: TProductLink;
    errorIdentifier?: string;
    errorUrl?: string;
    setProductLink: (value: TProductLink) => void;
    onOpenLink: (value: TProductLink) => void;
    onRemoveLink: (value: TProductLink) => void;
}

const ProductLinkData = ({
    isAffiliate,
    links,
    productLink,
    errorIdentifier,
    errorUrl,
    setProductLink,
    onOpenLink,
    onRemoveLink,
}: IProductLinkDataProps) => {
    if (!isAffiliate) {
        return <></>;
    }
    if (links.length <= 0) {
        return (
            <ProductLinkRegister
                embedded={true}
                link={productLink}
                errorIdentifier={errorIdentifier}
                errorUrl={errorUrl}
                setLink={setProductLink}
            />
        );
    }

    return (
        <Paper
            elevation={0}
            sx={{
                mb: 2,
                px: 2,
                py: 1,
                borderRadius: 4,
                backgroundColor: '#FAFAFA',
            }}
        >
            <Stack direction="column" spacing={1}>
                <Stack
                    direction="row"
                    spacing={1}
                    alignContent="center"
                    alignItems="center"
                >
                    <Box sx={{ width: "20%" }}>
                        <Typography variant="overline" fontWeight={500}>
                            Identificação
                        </Typography>
                    </Box>
                    <Box sx={{ width: "45%" }}>
                        <Typography variant="overline" fontWeight={500}>
                            URL
                        </Typography>
                    </Box>
                    <Box sx={{ width: "10%" }}>
                        <Typography variant="overline" fontWeight={500}>
                            Duração
                        </Typography>
                    </Box>
                    <Box sx={{ width: "15%" }}>
                        <Typography variant="overline" fontWeight={500}>
                            Categoria
                        </Typography>
                    </Box>
                    <Box sx={{ width: "10%" }}>
                        <Typography variant="overline" fontWeight={500}>
                            Ações
                        </Typography>
                    </Box>
                </Stack>
                <Divider />
                {links.map((link) => (
                    <Stack
                        key={link.id}
                        direction="row"
                        spacing={1}
                        alignContent="center"
                        alignItems="center"
                    >
                        <Stack direction="column" sx={{ width: "20%" }}>
                            <Typography variant="caption" fontWeight={500}>
                                {link.identifier}
                            </Typography>
                        </Stack>
                        <Stack direction="column" sx={{ width: "45%" }}>
                            <Typography noWrap variant="caption" fontWeight={500}>
                                {link.url}
                            </Typography>
                        </Stack>
                        <Box sx={{ width: "10%" }}>
                            <Typography variant="caption">
                                {Convertions.productLinkDurationToString(link.duration)}
                            </Typography>
                        </Box>
                        <Box sx={{ width: "15%" }}>
                            <Chip
                                size="small"
                                variant="outlined"
                                label={Convertions.productLinkCategoryToString(link.category)}
                                color={link.category === TProductLinkCategory.SalesPage
                                    ? "primary"
                                    : link.category === TProductLinkCategory.Leads
                                        ? "success"
                                        : link.category === TProductLinkCategory.SocialMedia
                                            ? "warning"
                                            : undefined}
                            />
                        </Box>
                        <Stack direction="row" sx={{ width: "10%" }}>
                            <IconButton
                                onClick={() => onOpenLink(link)}
                            >
                                <EditIcon color="primary" />
                            </IconButton>
                            {link.codeId !== "" && (
                                <IconButton
                                    onClick={() => onRemoveLink(link)}
                                >
                                    <DeleteIcon color="error" />
                                </IconButton>
                            )}
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </Paper>
    );
}

export default ProductLinkData;