import React, { useEffect } from "react";
import { TCustomer } from "../types/Customer";
import CustomerController from "../controllers/CustomerController";
import { IErrorResponse } from "../types/ErrorResponse";

export interface ICustomerProp {
    id: string;
}

export const useCustomer = ({ id }: ICustomerProp) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<TCustomer | null>(null);
    const [error, setError] = React.useState<string>('');

    const execute = async () => {
        if (!loading) {
            try {
                setLoading(true);

                if (error !== '') {
                    setError('');
                }
                if (data !== null) {
                    setData(null);
                }

                const response = await CustomerController.get({ id });
                const responseError = response as IErrorResponse;
                const responseData = response as TCustomer;

                if (responseError.code) {
                    setError(responseError.error);
                } else {
                    setData(responseData);
                }
            } catch (e) {
                var message = 'Erro não identificado';
                if (typeof e === "string") {
                    message = e.toUpperCase();
                } else if (e instanceof Error) {
                    const err = e as Error;
                    message = err.message;
                }
                setError(message);
            } finally {
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        execute();
    }, []);

    const clear = () => {
        setError('');
    }

    return {
        loading,
        data,
        error,
        execute,
        clear,
    };
}