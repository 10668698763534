import { Typography } from "@mui/material";

const TermsOfUseText = () => {
    return (
        <>
            <Typography
                variant="body2"
                textAlign="start"
                fontWeight={500}
                sx={{ marginTop: "2%", marginLeft: "1%", marginBottom: "2%" }}
            >
                REGRAS DO PROGRAMA DE INDICAÇÕES
            </Typography>
            <Typography
                variant="body2"
                textAlign="start"
                fontWeight={500}
                sx={{ marginLeft: "1%", marginBottom: "2%" }}
            >
                CÁLCULO DA COMISSÃO
            </Typography>
            <Typography variant="inherit" sx={{ marginLeft: "1%" }}>
                A comissão de 1.5% é calculada baseado no valor líquido (já com taxas descontadas) recebido pela conta
                que você indicou. Apenas as vendas aprovadas são consideradas. Por exemplo, se a conta que você indicou
                fez R$10.000 em vendas aprovadas, é considerado o valor de R$10.000 - taxas da Celetus, ou seja podemos
                estimar cerca de R$9250. Os 1.5% seriam calculados sobre R$9250 = R$138,75
            </Typography>
            <Typography variant="inherit" sx={{ marginLeft: "1%" }}>
                Você pode indicar tanto contas principais (dono do produto), como também co-produtores. O que vale é o
                valor líquido que cada conta recebe.
            </Typography>
            <Typography
                variant="body2"
                textAlign="start"
                fontWeight={500}
                sx={{ marginTop: "1%", marginLeft: "1%", marginBottom: "2%" }}
            >
                PERÍODO BASE DO CÁLCULO
            </Typography>
            <Typography variant="inherit" sx={{ marginLeft: "1%" }}>
                As comissões são consideradas no período de 12 meses após o cadastro da conta indicada por você. Depois
                desse período, você não recebe mais a comissão de 1.5%.
            </Typography>
            <Typography
                variant="body2"
                textAlign="start"
                fontWeight={500}
                sx={{ marginTop: "1%", marginLeft: "1%", marginBottom: "2%" }}
            >
                PRAZO DE PAGAMENTO
            </Typography>
            <Typography variant="inherit" sx={{ marginLeft: "1%" }}>
                As comissões são agrupadas por mês, e pagas 30 dias após o fechamento do mês. Por exemplo, se no mês de
                Janeiro (1-31) foi gerado R$500,00 de comissões, esse valor será pago na primeira semana de março.
            </Typography>
            <Typography
                variant="body2"
                textAlign="start"
                fontWeight={500}
                sx={{ marginTop: "1%", marginLeft: "1%", marginBottom: "2%" }}
            >
                BLOQUEIO DO PROGRAMA DE INDICAÇÕES
            </Typography>
            <Typography variant="inherit" sx={{ marginLeft: "1%" }}>
                A Celetus reserva o direito de bloquear o programa de indicações para a sua conta a qualquer momento,
                caso exista suspeita de fraude, indicações de baixa qualidade, anúncios prejudiciais a marca Celetus,
                tentativas de burlar o rastreio de indicações (cookie stuffing, etc) ou qualquer outro motivo, sem
                necessidade de prover uma explicação. No caso de cancelamento do seu programa de indicações, você não
                receberá nenhuma comissão pendente.
            </Typography>
            <Typography
                variant="body2"
                textAlign="start"
                fontWeight={500}
                sx={{ marginTop: "1%", marginLeft: "1%", marginBottom: "2%" }}
            >
                ALTERAÇÕES NAS REGRAS E VALORES DO PROGRAMA DE INDICAÇÕES
            </Typography>
            <Typography variant="inherit" sx={{ marginLeft: "1%" }}>
                O programa de indicações da Celetus pode sofrer alterações sem aviso prévio, por exemplo redução da
                porcentagem de comissão, período do cálculo das comissões, ou até mesmo o encerramento do programa.
            </Typography>
        </>
    );
};

export default TermsOfUseText;
