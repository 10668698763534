import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../core/context/AuthContext'
import {
  TOccurrenceLog,
  TOccurrenceInteration,
  TOccurrenceDetails,
} from '../../../../../core/types/OccurrenceLog'
import OccurrenceLogController from '../../../../../core/controllers/OccurrenceLogController'
import {
  Box,
  Button,
  FormControl,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'
import { dateTimeString } from '../../../../../core/functions/dateTime'
import SwipeableDrawerRightDialog from '../../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { To } from 'react-router-dom'
import LoadingBackdrop from '../../../../../components/Loading/LoadingBackdrop'

export interface IOccurrenceDetailPageProp {
  occurrence?: TOccurrenceLog
  details?: TOccurrenceInteration
  detailsInfo?: TOccurrenceDetails
  open: boolean
  handleClose: () => void
  setOpen: (value: boolean) => void
  setSending: (value: boolean) => void
  setSuccess: (value: boolean) => void
  setError: (value: string) => void
  execute: () => void
  getDetails: (id: string) => Promise<void>
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const OccurrenceDetailsPage = ({
  occurrence,
  details,
  detailsInfo,
  open,
  handleClose,
  setOpen,
  setSending,
  setSuccess,
  setError,
  execute,
  getDetails,
}: IOccurrenceDetailPageProp) => {
  const { user } = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [data, setData] = useState<TOccurrenceInteration>({
    actorName: details?.actorName || '',
    message: details?.message || '',
    isFixed: details?.isFixed || false,
  })
  const [isFixed, setIsFixed] = useState(details?.isFixed ?? false)
  const [tabIndex, setTabIndex] = useState(0)

  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = useState({ actorName: '', message: '' })

  const handleMyClose = () => {
    handleMenuClose()
    handleClose()
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFixed(event.target.checked)
    setData((prevData) => ({ ...prevData, isFixed: event.target.checked }))
  }

  const handleChangeInteration = async () => {
    const newErrors = { actorName: '', message: '' }
    let hasError = false

    if (!data.actorName) {
      newErrors.actorName = 'Nome da ocorrência é obrigatório'
      hasError = true
    }
    if (!data.message) {
      newErrors.message = 'Descrição é obrigatória'
      hasError = true
    }

    setErrors(newErrors)

    if (hasError) {
      return
    }

    if (!occurrence?.id) {
      setError('Nenhuma ocorrência selecionada')
      return
    }

    setSending(true)
    try {
      const response = await OccurrenceLogController.interation({
        occurrenceId: occurrence.id,
        data: { actorName: data.actorName, message: data.message, isFixed },
      })

      if (!response.success) {
        setError(response.error)
        return
      }

      setSuccess(true)
      await getDetails(occurrence.id)
    } finally {
      setSending(false)
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)

    if (newValue === 1 && occurrence?.id) {
      getDetails(occurrence.id)
    }
  }

  useEffect(() => {
    if (details) {
      setData({
        actorName: details.actorName,
        message: details.message,
        isFixed: details.isFixed,
      }),
        setIsFixed(details.isFixed)
    }
  }, [details])

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={handleMyClose}
      setOpen={setOpen}
      backgroundColor="#fafafa"
      title="Detalhes da Ocorrência"
      subtitle="Visualize os detalhes da ocorrência"
    >
      <Stack>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Detalhes" />
          <Tab label="Histórico" />
        </Tabs>

        {tabIndex === 0 && (
          <Box>
            <Stack>
              <ListItemDefault
                title="Nome da ocorrência"
                value={
                  <Box>
                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      rows={1}
                      type="text"
                      value={data.actorName}
                      onChange={(e) => {
                        setData({ ...data, actorName: e.target.value })
                        setErrors({ ...errors, actorName: '' })
                      }}
                      error={!!errors.actorName}
                    />
                    {errors.actorName && (
                      <Typography color="error">{errors.actorName}</Typography>
                    )}
                  </Box>
                }
              />

              <ListItemDefault
                title="Descrição"
                value={
                  <Box>
                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      rows={1}
                      type="text"
                      value={data.message}
                      onChange={(e) => {
                        setData({ ...data, message: e.target.value })
                        setErrors({ ...errors, message: '' })
                      }}
                      error={!!errors.message}
                    />
                    {errors.message && (
                      <Typography color="error">{errors.message}</Typography>
                    )}
                  </Box>
                }
              />

              <Stack sx={{ p: 5 }}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleChangeInteration}
                >
                  Salvar
                </Button>
              </Stack>
            </Stack>
          </Box>
        )}

        {tabIndex === 1 && (
          <Stack>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome da ocorrência</TableCell>
                  <TableCell>Descrição</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detailsInfo?.interactions.length ?? 0 > 0 ? (
                  detailsInfo?.interactions.map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{entry.actorName}</TableCell>
                      <TableCell>{entry.message}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      Nenhuma interação encontrada
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <LoadingBackdrop open={loading} />
          </Stack>
        )}
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}
export default OccurrenceDetailsPage
