import * as React from 'react'
import {
  Box,
  Typography,
  InputAdornment,
  Button,
  Stack,
  AlertTitle,
  Avatar,
  Paper,
  TextField,
  Tabs,
  Tab,
  MenuItem,
  Select,
  useMediaQuery,
} from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import {
  addDays,
  dateDescription,
  diffDays,
  lastTime,
  resetTime,
} from '../../../core/functions/dateTime'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import { useCheckoutOrders } from '../../../core/hooks/useCheckoutOrders'
import {
  TCheckoutOrder,
  TCheckoutOrderPagination,
} from '../../../core/types/CheckoutOrder'
import CheckoutOrderDetails from './CheckoutOrderDetails'
import { ExportToFile } from '../ExportToFile'
import {
  ExportFileTypeEnum,
  ReportDownloadController,
} from '../../../core/controllers/ReportDownloadController'
import { useDownloadFile } from '../../../core/hooks/useDownloadFile'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import { AuthContext } from '../../../core/context/AuthContext'
import { useCurrentTenantBank } from '../../../core/hooks/useCurrentTenantBank'
import 'dayjs/locale/pt-br'
import { AdvancedFilters } from '../AdvancedFilters'
import { useProductsSimple } from '../../../core/hooks/useProductsSimple'
import { useState } from 'react'
import { useAffiliatedsSimple } from '../../../core/hooks/useAffiliatedsSimple'
import SimpleCard from '../../../components/Cards/SimpleCard'
import TitlePageTypography from '../../../components/Typographys/TitlePageTypography'
import SubtitlePageTypography from '../../../components/Typographys/SubtitlePageTypography'
import DataTable, {
  IDataTableColumn,
} from '../../../components/Tables/DataTable'
import SelectInput from '../../../components/SelectInput/SelectInput'
import { a11yProps } from '../../../components/Tabs'
import {
  TCheckoutOrderReport,
  TCheckoutOrderReportTab,
} from '../../../core/types/Order'
import OrderFunctions from '../../../core/functions/OrderFunctions'
import { CheckoutOrderDataTableColumns } from './CheckoutOrderConsts'
import CheckoutOrdersMobile from './CheckoutOrdersMobile'
import { TLostSale } from '../../../core/types/LostSale'
import { AbandonedAdvancedFilters } from '../AdvancedFilters/AbandonedAdvancedFilters'
import AbandonedItemMobile from '../Abandoned/AbandonedItemMobile'
import OrderController from '../../../core/controllers/OrderController'
import Hosts from '../../../core/functions/hosts'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }
)

const TAB_INDEX_APPROVED = 0
const TAB_INDEX_ALL = 1
const TAB_INDEX_OPENED = 2
const TAB_INDEX_ABANDONED = 3

export default function CheckoutOrdersPage() {
  const { user } = React.useContext(AuthContext)

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ])

  const [tabIndex, setTabIndex] = React.useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [copied, setCopied] = useState(false)
  const [type, setType] = useState<TCheckoutOrderReportTab>('approved')
  const [report, setReport] = useState<TCheckoutOrderReport>('all')
  const [current, setCurrent] = useState<TCheckoutOrder | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorSending, setErrorSending] = useState('')

  const [startDate, setStartdate] = useState<Date | null | undefined>(
    resetTime(addDays(-29))
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(lastTime())
  const [searchType, setSearchType] = useState(0)
  const [prodSearchList, setProdSearchLists] = useState<string[]>([])
  const [affilatedsSearchList, setAffilatedsSearchLists] = useState<string[]>(
    []
  )
  const [paymentSearchList, setpaymentSearchLists] = useState<string[]>([])
  const [statusSearchList, setStatusSearchLists] = useState<string[]>([])
  const [openFilter, setOpenFilter] = useState(false)

  const [lastDays, setLastDays] = React.useState(30)

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  const handleDownloadBoleto = async (codeId: string) => {
    const boletoURL = await OrderController.getPaymentLink({ codeId })
    window.open(boletoURL.data, '_blank')
  }

  const handleCopyPixCode = async (codeId: string) => {
    const pixCode = await OrderController.getPaymentLink({ codeId })
    Hosts.copyTextToClipboard(pixCode.data)
    setCopied(true)
  }

  const { data: tenantBank } = useCurrentTenantBank({
    tenantId: user?.TenantId ?? '',
  })
  const { data: products, loading: loadingProducts } = useProductsSimple({
    initialExecute: true,
  })
  const { data: affiliateds, loading: loadingAffiliateds } =
    useAffiliatedsSimple({ initialExecute: true })

  const { loading, data, error, execute, clear } = useCheckoutOrders({
    page,
    rows: rowsPerPage,
    search,
    type,
    report,
    startDate,
    endDate,
    lastDays,
    affiliateds: affilatedsSearchList,
    payments: paymentSearchList,
    products: prodSearchList,
    sellerType: searchType,
    status: statusSearchList,
  })

  const dataTableColumns = CheckoutOrderDataTableColumns({
    setCopied,
    onDownloadBoleto: handleDownloadBoleto,
    onCopyPix: handleCopyPixCode,
    execute,
  })

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (page > 0) {
      setPage(0)
    }

    switch (newValue) {
      case TAB_INDEX_APPROVED:
        setStatusSearchLists([])
        setType('approved')
        break
      case TAB_INDEX_ALL:
        setStatusSearchLists([])
        setType('all')
        break
      case TAB_INDEX_OPENED:
        setStatusSearchLists(['pending'])
        setType('opened')
        break
      case TAB_INDEX_ABANDONED:
        setType('abandoned')
        break
    }

    setTabIndex(newValue)
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleChangeItem = (checkoutOrder: TCheckoutOrder | TLostSale) => {
    setCurrent(checkoutOrder as TCheckoutOrder)
    setOpen(true)
  }

  const handleClose = () => {
    setCurrent(undefined)
    setOpen(false)
  }

  const handleDownloadFile = (typeFile: ExportFileTypeEnum) => {
    if (type === 'abandoned') {
      return ReportDownloadController.getLostSales({
        fileType: typeFile,
        search,
        lastDays,
        type: '',
        report,
      })
    }
    return ReportDownloadController.ordersGetAll({
      fileType: typeFile,
      search,
      type,
      report,
      startDate,
      endDate,
      affiliateds: affilatedsSearchList,
      payments: paymentSearchList,
      products: prodSearchList,
      sellerType: searchType,
      status: statusSearchList,
    })
  }

  const handlerDownloadingError = (e: string) => setErrorSending(e)

  const getFileName = (type: ExportFileTypeEnum) =>
    `report_${new Date().toISOString().replace(':', '_').replace('.', '_')}.${
      type === ExportFileTypeEnum.XLS ? 'xlsx' : 'csv'
    }`

  const { download, downloading } = useDownloadFile({
    apiDefinition: handleDownloadFile,
    onError: handlerDownloadingError,
    getFileName,
  })

  const downloadCallback = React.useCallback(
    (type: ExportFileTypeEnum) => download(type),
    [download]
  )

  const handleConvertToXLS = async () => {
    if (isValidDownloadReport()) downloadCallback(ExportFileTypeEnum.XLS)
  }

  const handleConvertToCSV = async () => {
    if (isValidDownloadReport()) downloadCallback(ExportFileTypeEnum.CSV)
  }

  const isValidDownloadReport = (): boolean => {
    if (startDate === null || startDate === undefined) {
      setErrorSending('Necessário informar o período inicial.')
      return false
    }

    if (endDate === null || endDate === undefined) {
      setErrorSending('Necessário informar um período final.')
      return false
    }

    if (diffDays(endDate, startDate) > 30) {
      setErrorSending('Não informar um período maior que 30 dias')
      return false
    }

    return true
  }

  const isTenantBank = () => {
    return (
      tenantBank &&
      tenantBank !== null &&
      tenantBank.accountNumber &&
      tenantBank.accountNumber !== null &&
      tenantBank.bankCode &&
      tenantBank.bankCode !== null
    )
  }

  const handleDateFilter = (value: any) => {
    setStartdate(resetTime(value.startDate))
    setEndDate(lastTime(value.endDate))
  }

  const handleOpenFilter = () => setOpenFilter(true)

  const handleCloseFilter = () => setOpenFilter(false)

  const renderItemMobile = (
    item: TCheckoutOrder | TLostSale,
    index: number
  ) => {
    const lostSale = item as TLostSale
    return (
      <AbandonedItemMobile
        item={lostSale}
        index={index}
        setCopied={setCopied}
      />
    )
  }

  React.useEffect(() => {
    window.addEventListener('resize', (ev) => {
      setWindowSize([window.innerWidth, window.innerHeight])
    })
  }, [])

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }
  }, [data, rowsPerPage])

  return (
    <>
      {isSmallScreen && type !== 'abandoned' && (
        <CheckoutOrdersMobile
          startDate={startDate}
          endDate={endDate}
          tabIndex={tabIndex}
          rowsPerPage={rowsPerPage}
          page={page}
          data={data as TCheckoutOrderPagination}
          handleOpenFilter={handleOpenFilter}
          handleTabChange={handleTabChange}
          handleConvertToCSV={handleConvertToCSV}
          handleConvertToXLS={handleConvertToXLS}
          handleChangeItem={handleChangeItem}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      {(!isSmallScreen || type === 'abandoned') && (
        <Box>
          <Stack
            direction="row"
            spacing={1}
            alignContent="space-between"
            alignItems="center"
            sx={{ mb: 2, px: 2 }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <TitlePageTypography>Vendas</TitlePageTypography>
              <SubtitlePageTypography>
                {`Filtro do período de ${dateDescription(startDate)} à ${dateDescription(endDate)}`}
              </SubtitlePageTypography>
            </Box>

            {!isSmallScreen && (
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight={500}
                  lineHeight="18px"
                  color="rgba(152, 156, 155, 1)"
                >
                  Registros por página
                </Typography>
                <Select
                  variant="outlined"
                  value={rowsPerPage}
                  onChange={(event) =>
                    handleChangeRowsPerPage(
                      NumberFunctions.toNumber(event.target.value)
                    )
                  }
                  sx={{ height: 40, width: 85 }}
                >
                  {[10, 20, 50, 100].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            )}

            {!isSmallScreen && (
              <TextField
                size="small"
                onChange={(e) => setSearch(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    execute()
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        alt="Pesquisar"
                        src="/assets/icons/search/search.svg"
                      />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    ml: 4.5,
                    color: 'black',
                  },
                  shrink: search !== '',
                  required: false,
                }}
                sx={(theme) => ({
                  backgroundColor: 'white',
                  '& .Mui-focused .MuiInputAdornment-root': {
                    color: theme.palette.primary.main,
                  },
                  '& .Mui-error .MuiInputAdornment-root': {
                    color: theme.palette.error.main,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    px: 5.5,
                  },
                })}
                label="Pesquisar"
              />
            )}

            {!isSmallScreen && (
              <SelectInput
                width="180px"
                value={report}
                onChange={(e) =>
                  setReport(e.target.value as TCheckoutOrderReport)
                }
                options={OrderFunctions.STATUS_FILTER}
              />
            )}

            <Button
              sx={{
                p: 0,
                m: 0,
                minWidth: 0,
                borderRadius: '8px',
              }}
              onClick={handleOpenFilter}
            >
              <Paper
                elevation={0}
                sx={{
                  border: '1px solid #C5C6C9',
                  borderRadius: '8px',
                  p: '8px',
                  fontSize: '0rem',
                  height: 40,
                }}
              >
                <img
                  src="/assets/icons/menu/funnel/funnelBlack.svg"
                  alt="Filtro"
                />
              </Paper>
            </Button>
          </Stack>

          {isTenantBank() && error !== '' && (
            <Box sx={{ mb: 2 }}>
              <Alert onClose={clear} severity="error" sx={{ width: '100%' }}>
                {error}
              </Alert>
            </Box>
          )}

          {!isTenantBank() && error !== '' && (
            <Alert severity="warning" variant="standard" sx={{ mb: 2 }}>
              <AlertTitle>Dados bancário não fornecido</AlertTitle>
              Para vender é necessário informar uma conta bancária em{' '}
              <i>Menu - Configurações - Dados Bancário</i>
            </Alert>
          )}

          <Stack
            direction={{ xs: 'column', lg: 'row' }}
            spacing={3}
            sx={{ mb: 2, px: { xs: 2, lg: 0 } }}
          >
            <SimpleCard sx={{ px: 4 }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar sx={{ width: 40, height: 40, bgcolor: '#F2F2FF' }}>
                  <img alt="Vendas" src="/assets/icons/payments/pay.svg" />
                </Avatar>
                <Stack direction="column">
                  <Typography
                    variant="body2"
                    color="#707275"
                    fontWeight={500}
                    fontSize="14px"
                    lineHeight="14px"
                  >
                    Vendas encontradas
                  </Typography>
                  <Typography
                    variant="h4"
                    color="#38393B"
                    fontWeight={600}
                    fontSize="24px"
                    lineHeight="32px"
                  >
                    {data.total}
                  </Typography>
                </Stack>
              </Stack>
            </SimpleCard>
            {type !== 'abandoned' && (
              <SimpleCard sx={{ px: 4 }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar sx={{ width: 40, height: 40, bgcolor: '#F2F2FF' }}>
                    <img alt="Vendas" src="/assets/icons/payments/pay.svg" />
                  </Avatar>
                  <Stack direction="column">
                    <Typography
                      variant="body2"
                      color="#707275"
                      fontWeight={500}
                      fontSize="14px"
                      lineHeight="14px"
                    >
                      Valor líquido
                    </Typography>
                    <Typography
                      variant="h4"
                      color="#38393B"
                      fontWeight={600}
                      fontSize="24px"
                      lineHeight="32px"
                    >
                      {NumberFunctions.formatMoneyDefault(
                        NumberFunctions.toNumber(data.info)
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              </SimpleCard>
            )}
          </Stack>

          <Stack
            direction="row"
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              mb: isSmallScreen ? 2 : 0,
            }}
            alignItems="center"
          >
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                flexGrow: isSmallScreen ? 0 : 1,
                width: isSmallScreen ? windowSize[0] : '100%',
              }}
            >
              <Tab label="Aprovadas" {...a11yProps(0)} />
              <Tab label="Todas" {...a11yProps(1)} />
              <Tab label="Abertas" {...a11yProps(2)} />
              <Tab label="Abandonadas" {...a11yProps(3)} />
              {isSmallScreen && (
                <Stack
                  height="46px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ExportToFile
                    convertToCSV={handleConvertToCSV}
                    convertToXLS={handleConvertToXLS}
                  />
                </Stack>
              )}
            </Tabs>
            {!isSmallScreen && (
              <ExportToFile
                convertToCSV={handleConvertToCSV}
                convertToXLS={handleConvertToXLS}
              />
            )}
          </Stack>

          <DataTable<TCheckoutOrder | TLostSale>
            key={type}
            columns={
              dataTableColumns[type] as IDataTableColumn<
                TCheckoutOrder | TLostSale
              >[]
            }
            hasNewCustomHeader
            data={data}
            page={page}
            rowsPerPage={rowsPerPage}
            onItemClick={
              tabIndex <= TAB_INDEX_OPENED ? handleChangeItem : undefined
            }
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            renderItemMobile={renderItemMobile}
          />
        </Box>
      )}
      <CheckoutOrderDetails
        checkoutOrder={current}
        open={open}
        isOpened={tabIndex === TAB_INDEX_OPENED}
        handleClose={handleClose}
        setOpen={setOpen}
        setSending={setSending}
        setSuccess={setSuccess}
        setError={setErrorSending}
        execute={execute}
        setCopied={setCopied}
      />

      {type !== 'abandoned' && (
        <AdvancedFilters
          open={openFilter}
          setOpen={setOpenFilter}
          affiliateds={affiliateds}
          products={products}
          handleCloseFilter={handleCloseFilter}
          handleDateFilter={handleDateFilter}
          typeFilterValue={searchType}
          startDate={startDate}
          endDate={endDate}
          startDateDefault={addDays(-29)}
          endDateDefault={new Date()}
          onlyRangeDate={type === 'opened'}
          handleTypeFilter={(e: number) => setSearchType(e)}
          handleProductFilter={(e: string[]) => setProdSearchLists(e)}
          handleAffiliatedFilter={(e: string[]) => setAffilatedsSearchLists(e)}
          handlePaymentMethodFilter={(e: string[]) => setpaymentSearchLists(e)}
          handlStatusFilter={(e: string[]) => setStatusSearchLists(e)}
        />
      )}

      {type === 'abandoned' && (
        <AbandonedAdvancedFilters
          open={openFilter}
          lastDays={lastDays}
          setOpen={setOpenFilter}
          handleCloseFilter={handleCloseFilter}
          setLastDays={setLastDays}
        />
      )}

      <LoadingBackdrop
        open={
          loading ||
          sending ||
          downloading ||
          loadingProducts ||
          loadingAffiliateds
        }
      />

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Concluído com sucesso
      </SuccessSnackbar>

      <SuccessSnackbar open={copied} onClose={() => setCopied(false)}>
        Copiado com sucesso
      </SuccessSnackbar>
    </>
  )
}
