import * as React from 'react'
import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
  Stack,
  Tooltip,
  Chip,
} from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useOrderBumps } from '../../core/hooks/useOrderBumps'
import DataTable from '../../components/Tables/DataTable'
import DataTableHeaderWeb from '../../components/Tables/DataTableHeaderWeb'
import OrderBumpsRegister from './OrderBump/OrderBumpsRegister'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import OrderBumpController from '../../core/controllers/OrderBumpController'
import SuccessSnackbar from '../../components/Snackbar/SuccessSnackbar'
import OrderBumpsEdit from './OrderBump/OrderBumpsEdit'
import OrderBumpsCard from './OrderBump/OrderBumpsCard'
import { IOrderBump } from '../../core/types/OrderBump'
import DataTableHeaderMobile from '../../components/Tables/DataTableHeaderMobile'
import { AuthContext } from '../../core/context/AuthContext'
import { TUserType } from '../../core/types/Auth'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }
)

interface Column {
  id:
    | 'product'
    | 'productName'
    | 'title'
    | 'offerName'
    | 'selles'
    | 'active'
    | 'action'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
  render?: (id: string, value: any, row: any) => React.ReactNode
}

type StatusType = 'active' | 'inactive'

const getStatusChipStyles = (status: boolean) => {
  const statusStyles: Record<
    StatusType,
    { backgroundColor: string; color: string }
  > = {
    active: {
      backgroundColor: 'rgba(28, 189, 63, 0.16)',
      color: 'rgba(0, 157, 35, 1)',
    },
    inactive: {
      backgroundColor: 'rgba(189, 28, 28, 0.16)',
      color: 'rgba(212, 54, 44, 1)',
    },
  }

  const statusType: StatusType = status ? 'active' : 'inactive'
  return statusStyles[statusType]
}

export interface IOrderBumpRegisterProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function OrderBumpsPage({
  open,
  setOpen,
}: IOrderBumpRegisterProps) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [status, setStatus] = React.useState<'active' | 'inactive' | 'deleted'>(
    'active'
  )
  const [openRemove, setOpenRemove] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [idToDelete, setIdToDelete] = React.useState('')
  const oldSearch = React.useRef('')
  const [success, setSuccess] = React.useState(false)
  const [errorDelete, setErrorDelete] = React.useState('')
  const [openEdit, setOpenEdit] = React.useState(false)
  const [idToEdit, setIdToEdit] = React.useState('')

  const { user } = React.useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const { loading, data, error, execute, clear } = useOrderBumps({
    page,
    rows: rowsPerPage,
    search: search,
  })

  const columns: readonly Column[] = [
    {
      id: 'product',
      label: 'Produto',
      minWidth: 150,
      render(id, value, row) {
        return (
          <Stack direction="row" alignItems="center">
            <img
              src={
                row.imageProductUrl
                  ? row.imageProductUrl
                  : '/assets/logo/logoCeletus.svg'
              }
              style={{
                width: 40,
                height: 40,
                marginRight: 10,
                borderRadius: 6.67,
              }}
            />
            <Typography>{row.productName}</Typography>
          </Stack>
        )
      },
    },
    { id: 'title', label: 'Titulo', minWidth: 150 },
    {
      id: 'offerName',
      label: 'Oferta',
      minWidth: 150,
      render(id, value) {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              fontFamily={'Montserrat'}
              fontWeight={400}
              fontSize={'14px'}
              lineHeight={'18px'}
            >
              {value}
            </Typography>
          </Stack>
        )
      },
    },
    {
      id: 'active',
      label: 'Status',
      minWidth: 150,
      render(id, value) {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Chip
              label={value ? 'Ativo' : 'Inativo'}
              size="small"
              sx={getStatusChipStyles(value)}
            />
          </Stack>
        )
      },
    },
    {
      id: 'action',
      label: 'Ações',
      minWidth: 100,
      render(id, value, row) {
        return (
          <Box>
            {value !== 'deleted' && !isAdmin && (
              <Tooltip title="Editar o produto">
                <Button
                  onClick={() => {
                    setIdToEdit(row.id)
                    setOpenEdit(true)
                  }}
                  variant="text"
                  size="small"
                  sx={{
                    px: 1,
                    py: 0.5,
                    m: 0,
                    minWidth: 0,
                    borderRadius: '8px',
                  }}
                >
                  <img
                    src="/assets/icons/actions/edit.svg"
                    alt="action-eye"
                    style={{ fontSize: '1.2rem', color: 'black' }}
                  />
                </Button>
              </Tooltip>
            )}

            {isAdmin && (
              <Button
                onClick={() => {
                  setIdToEdit(row.id)
                  setOpenEdit(true)
                }}
                variant="text"
                size="small"
                sx={{
                  px: 1,
                  py: 0.5,
                  m: 0,
                  minWidth: 0,
                  borderRadius: '8px',
                }}
              >
                <img
                  src="/assets/icons/eyes/eyeButtonBlack.svg"
                  alt="action-eye"
                  style={{ fontSize: '1.2rem', color: 'black' }}
                />
              </Button>
            )}

            {!isAdmin && (
              <Tooltip title="Deletar o produto">
                <Button
                  onClick={() => {
                    setOpenRemove(true)
                    setIdToDelete(row.id)
                  }}
                  variant="text"
                  size="small"
                  sx={{
                    px: 1,
                    py: 0.5,
                    m: 0,
                    minWidth: 0,
                    borderRadius: '8px',
                  }}
                >
                  <img
                    src="/assets/icons/actions/delete.svg"
                    alt="action-eye"
                    style={{ fontSize: '1.2rem', color: 'black' }}
                  />
                </Button>
              </Tooltip>
            )}
          </Box>
        )
      },
    },
  ]

  const removeOrderBump = async () => {
    setSending(true)

    try {
      var response

      response = await OrderBumpController.remove({ id: idToDelete })

      if (!response.success) {
        setErrorDelete(response.error)
      } else {
        setSuccess(true)
        setOpen(false)
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const renderItemMobile = (item: IOrderBump, index: number) => {
    return (
      <OrderBumpsCard
        key={item.id}
        index={index}
        orderbump={item}
        onClick={() => {
          setIdToEdit(item.id)
          setOpenEdit(true)
        }}
        orderbumpImage={
          item.imageProductUrl
            ? item.imageProductUrl
            : '/assets/logo/logoCeletus.svg'
        }
      />
    )
  }

  return (
    <Box>
      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <Alert onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Box>
      )}

      <DataTable
        columns={columns}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        headerComponent={
          <DataTableHeaderWeb
            labelItems="orderbmps"
            totalItems={data.total}
            setSearch={setSearch}
            executeSearch={execute}
            status={status}
            setStatus={setStatus}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
        headerMobileComponent={
          <DataTableHeaderMobile
            totalItems={data.total}
            setSearch={setSearch}
            executeSearch={execute}
            status={status}
            setStatus={setStatus}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        hasNewCustomHeader
        renderItemMobile={renderItemMobile}
      />

      <Box sx={{ height: 80 }} />

      <OrderBumpsRegister open={open} setOpen={setOpen} execute={execute} />

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <OrderBumpsEdit
        open={openEdit}
        setOpen={setOpenEdit}
        id={idToEdit}
        execute={execute}
      />

      <ConfirmDialog
        open={openRemove}
        onClose={() => setOpenRemove(false)}
        message="Deseja realmente deletar?"
        onYes={removeOrderBump}
      />

      <SuccessSnackbar
        open={success}
        onClose={() => {
          setSuccess(false)
          setOpen(false)
        }}
      >
        Deletado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}
