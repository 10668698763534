import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import CodeIcon from '@mui/icons-material/Code'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

export interface ISalesFunnelStepItemProp {
  level?: number
  title?: string
  subtitle?: string
  initial?: boolean
  isInitial: boolean
  isThankYouPage?: boolean
  positiveButtonLabel?: string
  positiveNextStepId?: string | null
  negativeButtonLabel?: string
  negativeNextStepId?: string | null
  onPositive?: () => void
  onNegative?: () => void
  onEdit?: () => void
  onDelete?: () => void
  onCopyCode?: () => void
}

const SalesFunnelStepItem = ({
  level,
  title,
  subtitle,
  initial,
  isInitial,
  isThankYouPage,
  positiveButtonLabel,
  positiveNextStepId,
  negativeButtonLabel,
  negativeNextStepId,
  onPositive,
  onNegative,
  onEdit,
  onDelete,
  onCopyCode,
}: ISalesFunnelStepItemProp) => {
  const handleChangePositive = () => {
    if (onPositive) {
      onPositive()
    }
  }

  const handleChangeNegative = () => {
    if (onNegative) {
      onNegative()
    }
  }

  return (
    <Card
      elevation={1}
      sx={{
        width: 180,
        height: 260,
        ':hover': {
          boxShadow: 10,
        },
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            height: initial ? 140 : 200,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              gutterBottom
              variant="subtitle2"
              component="div"
              sx={{ flexGrow: 0 }}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                gutterBottom
                variant="body2"
                color="text.secondary"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
          {!isThankYouPage && (level ?? 0) < 5 && (
            <Box sx={{ flexGrow: 0, width: '100%' }}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                color="success"
                disabled={
                  positiveNextStepId !== undefined &&
                  positiveNextStepId !== null &&
                  positiveNextStepId !== ''
                }
                onClick={handleChangePositive}
                sx={{ mb: 1 }}
              >
                {positiveButtonLabel}
              </Button>
              {!initial && (
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  color="error"
                  disabled={
                    negativeNextStepId !== undefined &&
                    negativeNextStepId !== null &&
                    negativeNextStepId !== ''
                  }
                  onClick={handleChangeNegative}
                >
                  {negativeButtonLabel}
                </Button>
              )}
            </Box>
          )}
          {(level ?? 0) >= 5 && (
            <Alert
              severity="warning"
              variant="standard"
              sx={{ flexGrow: 0, width: '100%' }}
            >
              É permitido apenas 5 etapas
            </Alert>
          )}
        </Box>
      </CardContent>
      {!initial && <Divider />}
      {!initial && (
        <CardActions sx={{ flexGrow: 0 }}>
          <Divider />
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {!isThankYouPage && (
              <Button size="small" color="primary" onClick={onCopyCode}>
                <CodeIcon />
              </Button>
            )}
            <Button size="small" color="primary" onClick={onEdit}>
              <EditIcon />
            </Button>
          </ButtonGroup>
          {!isInitial && (
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button size="small" color="error" onClick={onDelete}>
                <DeleteIcon />
              </Button>
            </ButtonGroup>
          )}
        </CardActions>
      )}
    </Card>
  )
}

export default SalesFunnelStepItem
