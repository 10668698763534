import { Stack, Typography } from '@mui/material'
import SwipeableDrawerRightDialog from '../../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { CopyBlock, dracula } from 'react-code-blocks'

export interface IPricesDetailsProps {
  open: boolean
  setOpen: (value: boolean) => void
  handleCloseItem: () => void
  codeId: string
  setCopied: (value: boolean) => void
}

const PricesDetails = ({
  open,
  setOpen,
  handleCloseItem,
  codeId,
  setCopied,
}: IPricesDetailsProps) => {
  const codeBlock = `<script>
(function () {
    localStorage.setItem("codeId", "${codeId}");
})();
</script>
<script
    defer="defer"
    src="${process.env.REACT_APP_STATIC_HOST}/bundle.js"
></script>
<div id="checkout-affiliatespay"></div>`

  return (
    <SwipeableDrawerRightDialog
      title="Oferta"
      subtitle="Integração por widget"
      open={open}
      setOpen={setOpen}
      onClose={handleCloseItem}
    >
      <Stack direction="column" spacing={1} sx={{ p: 1 }}>
        <Typography variant="subtitle2">
          Código do widget de página de pagamento
        </Typography>
        <CopyBlock
          text={codeBlock}
          codeBlock={true}
          language="javascript"
          //showLineNumbers={6}
          showLineNumbers
          startingLineNumber={1}
          //wrapLines
          wrapLongLines
          copied
          theme={dracula}
          onCopy={() => setCopied(true)}
        />
        <Typography variant="caption">
          Incorpore um atalho facilitado da sua página de pagamento a qualquer
          endereço web por meio do código
        </Typography>
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}

export default PricesDetails
