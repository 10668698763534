import api, { getResponseError } from "../../services/api";
import { methodTypes } from "../data/methodTypes";
import { IErrorResponse } from "../types/ErrorResponse";
import { IPayment } from "../types/Payment";

const getAll = async (): Promise<IPayment[] | IErrorResponse> => {
    try {
        //var path = `/Product/payments/methods/list`;
        //const { data } = await api.get<IPayment[]>(path);
        const { data } = methodTypes;
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const PaymentController = {
    getAll,
}

export default PaymentController;