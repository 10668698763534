export enum TCoproducerStatus {
    All = 0,
    Pending = 1,
    Confirmed = 2,
    Canceled = 3,
}

export type TCoproducer = {
    id?: string;
    productId?: string;
    productActive?: boolean;
    productName?: string;
    tenantId?: string;
    tenantName?: string;
    email?: string;
    status?: TCoproducerStatus;
    contractDuration?: number;
    commissionPercentage?: any;
    issuanceInvoices?: boolean;
    createdDate?: Date;

    isBlockedAffiliateCommission: boolean;
    affiliatedCommissionPercentage: number;
}

export type TCoproducerPagination = {
    items: TCoproducer[];
    page: number;
    rowsPerPage: number;
    totalPages: number;
    total: number;
}