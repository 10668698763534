import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import NumberFunctions from "../../../../../core/functions/NumberFunctions";
import Hosts, { goUrl, payUrl } from "../../../../../core/functions/hosts";
import { TAffiliated, TAffiliatedStatus } from "../../../../../core/types/Affiliated";
import { TProductLink, TProductLinkCategory, TProductPrice } from "../../../../../core/types/Product";
import CheckoutIcon from "@mui/icons-material/ContentCopy";
import { useEffect, useState } from "react";
import Convertions from "../../../../../core/functions/convertions";

export interface IMyAffiliationsLinksProp {
    affiliated?: TAffiliated;
    prices?: TProductPrice[];
    links: TProductLink[];
    setCopied: (copied: boolean) => void;
    setError: (error: string) => void;
}

const MyAffiliatesLinks = ({ affiliated, prices, links, setError, setCopied }: IMyAffiliationsLinksProp) => {
    const [groups, setGroups] = useState<TProductLinkCategory[]>([]);

    useEffect(() => {
        const list: TProductLinkCategory[] = [];
        links.map((link) => {
            const item = list.find(l => l === link.category);
            if (!item) {
                list.push(link.category);
            }
            return true;
        });
        setGroups(list);
    }, [links]);

    return (
        <Box>
            <Grid container columns={18}>
                <Grid item xs={14} sx={{ backgroundColor: 'divider' }}>
                    {affiliated?.status === TAffiliatedStatus.Accepted && <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
                        Página de Checkout
                    </Typography>}
                </Grid>
                <Grid item xs={4} sx={{ backgroundColor: 'divider' }}>
                    <Typography variant="subtitle2" textAlign="end" sx={{ px: 2, py: 1 }}>Preço</Typography>
                </Grid>
                {prices?.filter(p => !p.isDisabled).map((price) => (
                    <Stack
                        direction="row"
                        alignContent="center"
                        alignItems="center"
                        sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }}
                    >
                        <Grid item xs={1}></Grid>
                        <Grid item xs={13}>
                            {affiliated?.status === TAffiliatedStatus.Accepted &&
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignContent="center"
                                    alignItems="center"
                                    sx={{ px: 2, py: 1 }}
                                >
                                    <Typography variant="caption" fontWeight={500} noWrap>{`${payUrl}/${price.codeId}?afid=${affiliated?.codeId}`}</Typography>
                                    <IconButton
                                        size="small"
                                        onClick={async () => {
                                            const url: string = `${payUrl}/${price.codeId}?afid=${affiliated?.codeId}`;
                                            Hosts.copyTextToClipboard(url)
                                                .then(() => setCopied(true))
                                                .catch(() => setError('Erro ao copiar'));
                                        }}
                                    >
                                        <CheckoutIcon sx={{ fontSize: "1.2rem" }} />
                                    </IconButton>
                                </Stack>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" textAlign="end" sx={{ px: 2, py: 1 }}>{NumberFunctions.formatMoneyDefault(price.value)}</Typography>
                        </Grid>
                    </Stack>
                ))}

                {groups.map((group) => (
                    <>
                        <Grid item xs={18} sx={{ backgroundColor: 'divider' }}>
                            <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>{Convertions.productLinkCategoryToString(group)}</Typography>
                        </Grid>
                        {links?.filter(link => link.category === group).map((link) => (
                            <Stack
                                direction="row"
                                alignContent="center"
                                alignItems="center"
                                sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }}
                            >
                                <Grid item xs={1}></Grid>
                                <Grid item xs={17}>
                                    {affiliated?.status === TAffiliatedStatus.Accepted &&
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignContent="center"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            sx={{ px: 2, py: 1 }}
                                        >
                                            <Stack direction="column">
                                                <Typography variant="caption">{link.identifier}</Typography>
                                                <Typography variant="caption" fontWeight={500} noWrap>
                                                    {`${goUrl}/${affiliated?.codeId}${link.codeId !== "" ? `/?ap=${link.codeId}` : ""}`}
                                                </Typography>
                                            </Stack>
                                            <IconButton
                                                size="small"
                                                onClick={async () => {
                                                    const url = `${goUrl}/${affiliated?.codeId}${link.codeId !== "" ? `/?ap=${link.codeId}` : ""}`;
                                                    Hosts.copyTextToClipboard(url)
                                                        .then(() => setCopied(true))
                                                        .catch(() => setError('Erro ao copiar'));
                                                }}
                                            >
                                                <CheckoutIcon sx={{ fontSize: "1.2rem" }} />
                                            </IconButton>
                                        </Stack>
                                    }
                                </Grid>
                            </Stack>
                        ))}
                    </>
                ))}
            </Grid>
            <Box sx={{ height: 80 }} />
        </Box>
    );
}

export default MyAffiliatesLinks;