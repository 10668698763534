import * as React from 'react'
import { useState } from 'react'
import Paper from '@mui/material/Paper'
import { IWithdraw, IWithdrawDetail } from '../../../core/types/Tenant'
import {
  Box,
  Typography,
  Slide,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Icon,
  Stack,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { WithdrawGridDetails } from './WithdrawGridDetails'
import DataTable, {
  TDataTablePagination,
} from '../../../components/Tables/DataTable'
import WithdrawColumns from './WithdrawConsts'
import WithdrawGridItemMobile from './WithdrawGridItemMobile'

export interface WithdrawGridProps {
  data: IWithdraw[]
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const WithdrawGrid = ({ data }: WithdrawGridProps) => {
  const dataPagination: TDataTablePagination<IWithdraw> = {
    items: data,
    page: 0,
    rowsPerPage: 0,
    total: 0,
    totalPages: 0,
  }

  const [open, setOpen] = useState(false)
  const [details, setDetails] = useState<IWithdrawDetail[]>([])

  const handleShowDetails = (value: IWithdraw) => {
    let res = value.details || []
    setDetails(res)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const columns = WithdrawColumns(handleShowDetails)

  const renderItemMobile = (item: IWithdraw, index: number) => {
    return (
      <WithdrawGridItemMobile
        item={item}
        index={index}
        handleShowDetails={handleShowDetails}
      />
    )
  }

  return (
    <Box display="flex" width="100%" gap="24px">
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          boxShadow: '0',
          p: 1,
        }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
          width="100%"
          marginBottom="24px"
        >
          <Typography
            sx={{
              display: 'flex',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '20px',
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <Icon sx={{ mr: 1 }}>
              <img src="assets/icons/history/historyIcon.svg" alt="history" />
            </Icon>
            Histórico de saques
            <Tooltip title="Histórico de saques">
              <Icon sx={{ ml: 1 }}>
                <img src="assets/icons/help/helpIcon.svg" alt="help" />
              </Icon>
            </Tooltip>
          </Typography>
        </Stack>

        <Stack>
          <DataTable
            headerBackgroundColor="transparent"
            columns={columns}
            data={dataPagination}
            page={0}
            rowsPerPage={0}
            hasPagination={false}
            onPageChange={() => {}}
            onRowsPerPageChange={() => {}}
            renderItemMobile={renderItemMobile}
          />
        </Stack>
      </Paper>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Histórico do processamento'}</DialogTitle>
        <DialogContent>
          <WithdrawGridDetails data={details} />
        </DialogContent>
      </Dialog>
    </Box>
  )
}
