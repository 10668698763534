export const webhookExamples = [
  {
    label: 'id',
    description: 'Identificação do evento do webhook',
  },
  {
    label: 'event_name',
    description: 'Nome do evento',
  },
  {
    label: 'event_type',
    description: 'Tipo do evento',
  },
  {
    label: 'order_id',
    description: 'Identificador da compra',
  },
  {
    label: 'order_code',
    description: 'Identificador curto da compra',
  },
  {
    label: 'order_status',
    description: 'Situação do pagamento da compra',
  },
  {
    label: 'payment_method',
    description:
      'Método de pagamento (Boleto, Pix, CreditCard, DebitCard ou BankTransfer)',
  },
]
