import api, { getResponseError, isErrorApi, setAuthorization } from "../../services/api";
import { TAuthUser } from "../types/Auth";
import { isExpired, decodeToken } from "react-jwt";
import { IErrorResponse, IResponse } from "../types/ErrorResponse";

const auth = async (user: string, password: string): Promise<TAuthUser | IErrorResponse> => {
    try {
        if (user.trim() === '') {
            return {
                code: 1,
                error: 'É necessário preencher o campo Usuário.',
            };
        }
        if (password === '') {
            return {
                code: 1,
                error: 'É necessário preencher o campo Senha.',
            };
        }

        const body = {
            email: user,
            password: password,
        };

        const { data: token } = await api.post<string>('/User/login', body);

        if (!token || token === null || token === '') {
            return {
                code: 1,
                error: 'Não foi possível obter a resposta. Tente novamente mais tarde, se o erro persistir contate um administrador.',
            };
        }

        const payload = decodeToken<TAuthUser>(token);
        const expired = isExpired(token);

        if (payload === null) {
            return {
                code: 1,
                error: 'Token inválido',
            };
        }

        if (expired) {
            return {
                code: 1,
                error: 'Token expirado',
            };
        }

        payload.token = token;
        setAuthorization(token);
        return payload;
    } catch (error) {
        if (isErrorApi(error)) {
            const err = `${error}`;
            if (err.includes('400')) {
                return {
                    code: 2,
                    error: 'Usuário e / ou senha inválidos',
                };
            }
            return {
                code: 1,
                error: 'Ops, houve um problema, estamos trabalhando para solucionar o quanto antes. Tente novamente mais tarde.',
            };
        } else {
            return {
                code: 3,
                error: 'Erro inesperado',
            }
        }
    }
}

const forgotPassword = async ({ data }: { data: { email: string } }): Promise<IResponse> => {
    try {
        data.email = data.email.toLowerCase();
        const { data: response } = await api.post("/User/forgotpassword", data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: "",
                data: response,
            };
        }

        return {
            success: false,
            code: 1,
            error: "Erro inesperado do servidor",
        };
    } catch (error: any) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
};

const validateForgotPassword = async ({ data }: { data: { codeId: string } }): Promise<IResponse> => {
    try {
        const { data: response } = await api.get(`/User/forgotpassword/validate/${data.codeId}`);
        if (response) {
            return {
                success: true,
                code: 0,
                error: "",
                data: response,
            };
        }

        return {
            success: false,
            code: 1,
            error: "Erro inesperado do servidor",
        };
    } catch (error: any) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
};

const changePassword = async ({ data }: { data: { codeId: string, password: string } }): Promise<IResponse> => {
    try {
        const { data: response } = await api.post("/User/changepassword", data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: "",
                data: response,
            };
        }

        return {
            success: false,
            code: 1,
            error: "Erro inesperado do servidor",
        };
    } catch (error: any) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
};

const UserController = {
    auth,
    forgotPassword,
    changePassword,
    validateForgotPassword,
}

export default UserController;