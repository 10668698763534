import api, { getResponseError } from '../../services/api'
import { IErrorResponse, IResponse } from '../types/ErrorResponse'
import {
  TOccurrenceDetails,
  TOccurrenceInteration,
  TOccurrenceLogPagination,
} from '../types/OccurrenceLog'

interface IGetOccurrenceLogProp {
  page: number
  rows: number
  name?: string
  startDate?: Date | null | undefined
  endDate?: Date | null | undefined
}

const getAll = async ({
  page,
  rows,
  name,
  startDate,
  endDate,
}: IGetOccurrenceLogProp): Promise<
  TOccurrenceLogPagination | IErrorResponse
> => {
  try {
    let path = `/Occurrence?PageNumber=${page}&RowsPerPage=${rows}`

    if (name && name !== '') {
      path += `&Name=${name}`
    }

    if (startDate) {
      endDate ??= new Date()
      path += `&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate.toISOString()}`
    }

    const { data } = await api.get<TOccurrenceLogPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getDetails = async ({
  id,
}: {
  id: string
}): Promise<TOccurrenceDetails | IErrorResponse> => {
  try {
    let path = `/Occurrence/${id}`

    const { data } = await api.get<TOccurrenceDetails>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const interation = async ({
  occurrenceId,
  data,
}: {
  occurrenceId: string
  data?: TOccurrenceInteration
}): Promise<IResponse> => {
  try {
    await api.post(`/Occurrence/${occurrenceId}/interation`, data)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const OccurrenceLogController = {
  getAll,
  getDetails,
  interation,
}

export default OccurrenceLogController
