import { Chip, IconButton } from '@mui/material'
import { IDataTableColumn } from '../../../components/Tables/DataTable'
import { dateTimeString } from '../../../core/functions/dateTime'
import { IWithdraw, WithdrawStatusEnum } from '../../../core/types/Tenant'
import { HtmlTooltip } from '../../../components/Tooltip/HtmlTooltip'

export const withdrawStatusComponent: Record<
  WithdrawStatusEnum,
  { label: string; backgroundColor: string; color: string }
> = {
  [WithdrawStatusEnum.pending]: {
    label: 'Pendente',
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
  [WithdrawStatusEnum.fail]: {
    label: 'Falhou',
    backgroundColor: '#BD1C1C29',
    color: '#D4362C',
  },
  [WithdrawStatusEnum.approved]: {
    label: 'Aprovado',
    backgroundColor: 'rgba(28, 189, 63, 0.16)',
    color: 'rgba(0, 157, 35, 1)',
  },
  [WithdrawStatusEnum.processing]: {
    label: 'Processando',
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
}

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})

const WithdrawColumns = (
  onChangeItem: (value: IWithdraw) => void
): readonly IDataTableColumn<IWithdraw>[] => [
  {
    id: 'createdAt',
    label: 'Data Solicitação',
    minWidth: 100,
    format: (value) => dateTimeString(value),
  },
  {
    id: 'amount',
    label: 'Valor do Saque',
    minWidth: 100,
    align: 'right',
    format: (value) => formatter.format(value),
  },
  {
    id: 'tax',
    label: 'Taxa',
    minWidth: 100,
    align: 'right',
    render(id, value, row) {
      return formatter.format(3.67)
    },
  },
  {
    id: 'value',
    label: 'Valor Líquido',
    minWidth: 100,
    align: 'right',
    render(id, value, row) {
      return formatter.format(row.amount - 3.67)
    },
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    align: 'center',
    render(id, value, row) {
      const statusConfig = withdrawStatusComponent[row.status]
      const { label, ...sx } = statusConfig
      return <Chip label={label} size="small" sx={sx} />
    },
  },
  {
    id: 'action',
    label: 'Ações',
    minWidth: 80,
    render(id, value, row) {
      return (
        <HtmlTooltip title="Mais detalhes">
          <IconButton size="small" onClick={() => onChangeItem(row)}>
            <img
              src="/assets/icons/eyes/eyeButtonBlack.svg"
              alt="action-eye"
              style={{ fontSize: '1.2rem', color: 'black' }}
            />
          </IconButton>
        </HtmlTooltip>
      )
    },
  },
]

export default WithdrawColumns
