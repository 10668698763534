import { Box, Stack, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'
import { TVoxuySettings } from '../../../core/types/TenantVoxuySettings'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import { useForm } from 'react-hook-form'
import { TenantController } from '../../../core/controllers/TenantController'
import { useCurrentTenant } from '../../../core/hooks/useCurrentTenant'
import Validations from '../../../core/functions/validations'

export interface IVoxuyProps {
  open: boolean
  voxuySettings: TVoxuySettings
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const VoxuySettings = ({
  open,
  voxuySettings,
  setOpen,
}: IVoxuyProps) => {
  const { data: tenant } = useCurrentTenant()
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [animationState, setAnimationState] = useState(false)
  const [error, setError] = useState('')
  const [errorUrl, setErrorUrl] = useState<string | undefined>()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<TVoxuySettings>({
    defaultValues: voxuySettings,
  })

  const Submit = async (data: any) => {
    setSending(true)
    setSuccess(false)
    setError('')

    if (!data.tenantId) data.tenantId = tenant?.id

    try {
      let response = await TenantController.registerVoxuySettings({ data })

      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        setOpen(false)
      }
    } finally {
      setSending(false)
    }
  }

  useEffect(() => {
    setAnimationState(true)
    return () => setAnimationState(false)
  }, [])

  useEffect(() => {
    reset(voxuySettings)
  }, [voxuySettings])

  const validateLink = (value: string) => {
    if (!Validations.URL(value)) {
      setErrorUrl('URL inválida')
      return
    } else {
      setErrorUrl(undefined)
    }
  }

  return (
    <Box>
      <SwipeableDrawerRightDialog
        open={open}
        onClose={() => setOpen(false)}
        setOpen={setOpen}
        title="Voxuy"
        subtitle="Configuração para a Voxuy"
        buttons={[
          {
            title: 'Cancelar',
            onClick: () => setOpen(false),
            type: 'negative',
          },
          {
            title: 'Salvar',
            type: 'positive',
            event: 'submit',
          },
        ]}
        buttonsDisabled={sending}
        noValidate
        component="form"
        onSubmit={handleSubmit(Submit)}
      >
        <Stack direction="column" spacing={2} sx={{ py: 1 }}>
          <Box sx={{ width: '100%', margin: '0 auto' }}>
            <Stack direction="column">
              <Stack
                direction="column"
                p={2}
                spacing={3}
                onSubmit={handleSubmit(Submit)}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label="Token"
                  {...register('voxuyToken')}
                  error={errors.voxuyToken?.type === 'required'}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label="URL para Webhook"
                  {...register('voxuyApiUrl')}
                  onChange={(e) => validateLink(e.target.value)}
                  error={errorUrl !== undefined && errorUrl !== ''}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label="Id do plano (Padrão)"
                  {...register('voxuyPlanId')}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label="Id do Evento"
                  {...register('voxuyEventId')}
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </SwipeableDrawerRightDialog>

      <LoadingBackdrop open={sending} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Alterado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}
