import {
  Chip,
  Select,
  Box,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Typography,
  InputLabel,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { TProductPrice } from "../../../core/types/Product";
import NumberFunctions from "../../../core/functions/NumberFunctions";
import { red } from "@mui/material/colors";

export interface IPriceMultiselectProps {
  prices: TProductPrice[];
  selecteds: string[];
  error?: string;
  setSelecteds: (values: string[]) => void;
  hasTitle?: boolean;
  disabled?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const PriceMultiselect = ({
  prices,
  setSelecteds,
  selecteds,
  error,
  hasTitle = true,
  disabled = false,
}: IPriceMultiselectProps) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<typeof selecteds>) => {
    const {
      target: { value },
    } = event;

    const values = typeof value === "string" ? value.split(",") : value;
    setSelecteds(values);
  };

  function getStyles(
    name: string,
    personName: readonly string[] | undefined,
    theme: Theme
  ) {
    return {
      fontWeight:
        (personName?.indexOf(name) ?? -1) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <FormControl fullWidth size="small">
      {hasTitle ? (
        <Typography variant="subtitle2">Oferta</Typography>
      ) : (
        <InputLabel id="prices-multiple-chip-label">Oferta</InputLabel>
      )}
      <Select
        labelId="prices-multiple-chip-label"
        id="prices-multiple-chip"
        label="Oferta"
        multiple
        disabled={disabled}
        value={selecteds}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                size="small"
                key={value}
                label={NumberFunctions.formatMoneyDefault(
                  prices.find((p) => p.id === value)?.value ?? 0
                )}
                color="primary"
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        error={error !== ""}
      >
        {prices
          .filter((x) => !x.isDisabled)
          .map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              style={getStyles(
                NumberFunctions.formatMoneyDefault(item.value),
                selecteds,
                theme
              )}
            >
              {NumberFunctions.formatMoneyDefault(item.value)}
            </MenuItem>
          ))}
      </Select>
      {error !== "" && (
        <Typography variant="caption" color={red[700]}>
          {error}
        </Typography>
      )}
    </FormControl>
  );
};
