import { Box, Button, Slide, Stack, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import { useCurrentTenant } from '../../../core/hooks/useCurrentTenant'
import { ITenant } from '../../../core/types/Tenant'
import { TenantController } from '../../../core/controllers/TenantController'

export interface IVoxuyProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const GoogleTagManagerSettings = ({ open, setOpen }: IVoxuyProps) => {
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [animationState, setAnimationState] = useState(false)
  const [error, setError] = useState('')
  const [currentTenant, setCurrentTenant] = useState<ITenant | undefined>(
    undefined
  )

  const { data: tenant } = useCurrentTenant()

  const handleSave = async () => {
    if (!currentTenant) {
      return
    }

    try {
      setSending(true)

      let response = await TenantController.registerGoogleTagManagerSettings({
        data: {
          tenantId: currentTenant.id,
          googleTagManagerId: currentTenant.googleTagManagerId ?? '',
        },
      })

      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        setOpen(false)
      }
    } finally {
      setSending(false)
    }
  }

  useEffect(() => {
    setAnimationState(true)
    return () => setAnimationState(false)
  }, [])

  useEffect(() => {
    if (tenant) {
      setCurrentTenant(tenant)
    }
  }, [tenant])

  return (
    <Box>
      <SwipeableDrawerRightDialog
        open={open}
        onClose={() => setOpen(false)}
        setOpen={setOpen}
        title="Google Tag Manager"
        subtitle="Configuração para o GTM"
        /*actions={
                    <Stack direction="row" spacing={1} sx={{ p: 1 }} justifyContent="end">
                        <Button
                            variant="outlined"
                            color="error"
                            disabled={sending}
                            onClick={() => setOpen(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            disabled={sending}
                            onClick={handleSave}
                        >
                            Salvar
                        </Button>
                    </Stack>
                }*/
        buttons={[
          {
            title: 'Cancelar',
            onClick: () => setOpen(false),
            type: 'negative',
          },
          { title: 'Salvar', onClick: handleSave, type: 'positive' },
        ]}
        buttonsDisabled={sending}
      >
        <Stack direction="column" spacing={2} sx={{ py: 1 }}>
          <Box>
            <Box sx={{ width: '100%', margin: '0 auto' }}>
              <img
                alt="Google Tag Manager"
                src={`${process.env.PUBLIC_URL}/assets/apps/gtm-bg.jpg`}
                style={{ maxWidth: '250px' }}
              />

              <Stack direction="column">
                <Stack direction="column" p={2} spacing={3}>
                  <TextField
                    fullWidth
                    id="gtm-id-container"
                    variant="outlined"
                    size="small"
                    type="text"
                    label="ID do contêiner"
                    placeholder="GTM-XXXX"
                    helperText="Utilize o formato GTM-XXXX"
                    value={currentTenant?.googleTagManagerId ?? ''}
                    onChange={(e) =>
                      setCurrentTenant({
                        ...currentTenant!,
                        googleTagManagerId: e.target.value,
                      })
                    }
                    inputProps={{ maxLength: 40 }}
                  />
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </SwipeableDrawerRightDialog>

      <LoadingBackdrop open={sending} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Alterado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}
