import {
  Avatar,
  Box,
  Button,
  Chip,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import TitlePageTypography from '../../../components/Typographys/TitlePageTypography'
import SubtitlePageTypography from '../../../components/Typographys/SubtitlePageTypography'
import {
  dateDescription,
  dateTimeString,
  diffDays,
} from '../../../core/functions/dateTime'
import SimpleCard from '../../../components/Cards/SimpleCard'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import { ExportToFile } from '../ExportToFile'
import { a11yProps } from '../../../components/Tabs'
import { checkoutOrderStatusColorMap } from './CheckoutOrderConsts'
import AccordionDefault from '../../../components/Accordions/AccordionDefault'
import AccordionSummaryDefault from '../../../components/Accordions/AccordionSummaryDefault'
import Convertions from '../../../core/functions/convertions'
import AccordionDetailsDefault from '../../../components/Accordions/AccordionDetailsDefault'
import OrderFunctions from '../../../core/functions/OrderFunctions'
import MethodTypeSmallIcon from '../../../components/Checkout/Icons/MethodTypeSmallIcon'
import TablePaginationDefault from '../../../components/Tables/TablePaginationDefault'
import {
  TCheckoutOrder,
  TCheckoutOrderPagination,
} from '../../../core/types/CheckoutOrder'
import { useEffect, useState } from 'react'

interface ICheckoutOrdersMobileProps {
  startDate: Date | null | undefined
  endDate: Date | null | undefined
  tabIndex: number
  rowsPerPage: number
  page: number
  data: TCheckoutOrderPagination
  handleOpenFilter: () => void
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void
  handleConvertToCSV: () => Promise<void>
  handleConvertToXLS: () => Promise<void>
  handleChangeItem: (checkoutOrder: TCheckoutOrder) => void
  handleChangePage: (newPage: number) => void
  handleChangeRowsPerPage: (rows: number) => void
}

const CheckoutOrdersMobile = ({
  startDate,
  endDate,
  tabIndex,
  rowsPerPage,
  page,
  data,
  handleOpenFilter,
  handleTabChange,
  handleConvertToCSV,
  handleConvertToXLS,
  handleChangeItem,
  handleChangePage,
  handleChangeRowsPerPage,
}: ICheckoutOrdersMobileProps) => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ])

  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  useEffect(() => {
    window.addEventListener('resize', (ev) => {
      setWindowSize([window.innerWidth, window.innerHeight])
    })
  }, [])

  return (
    <>
      <Box width="100%">
        <Stack
          direction="row"
          spacing={1}
          alignContent="space-between"
          justifyContent={'space-between'}
          alignItems="center"
          width="100%"
          sx={{ mb: 2, px: 2 }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <TitlePageTypography>Vendas</TitlePageTypography>
            <SubtitlePageTypography>
              {`Filtro do período de ${dateDescription(startDate)} à ${dateDescription(endDate)}`}
            </SubtitlePageTypography>
          </Box>

          <Button
            sx={{
              p: 0,
              m: 0,
              minWidth: 0,
              borderRadius: '8px',
            }}
            onClick={handleOpenFilter}
          >
            <Paper
              elevation={0}
              sx={{
                border: '1px solid #C5C6C9',
                borderRadius: '8px',
                p: '8px',
                fontSize: '0rem',
                height: 40,
              }}
            >
              <img
                src="/assets/icons/menu/funnel/funnelBlack.svg"
                alt="Filtro"
              />
            </Paper>
          </Button>
        </Stack>

        <Stack
          direction="column"
          spacing={3}
          sx={{ mb: 2, px: { xs: 2, lg: 0 } }}
        >
          <SimpleCard sx={{ px: 4 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar sx={{ width: 40, height: 40, bgcolor: '#F2F2FF' }}>
                <img alt="Vendas" src="/assets/icons/payments/pay.svg" />
              </Avatar>
              <Stack direction="column">
                <Typography
                  variant="body2"
                  color="#707275"
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="14px"
                >
                  Vendas encontradas
                </Typography>
                <Typography
                  variant="h4"
                  color="#38393B"
                  fontWeight={600}
                  fontSize="24px"
                  lineHeight="32px"
                >
                  {data.total}
                </Typography>
              </Stack>
            </Stack>
          </SimpleCard>
          <SimpleCard sx={{ px: 4 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar sx={{ width: 40, height: 40, bgcolor: '#F2F2FF' }}>
                <img alt="Vendas" src="/assets/icons/payments/pay.svg" />
              </Avatar>
              <Stack direction="column">
                <Typography
                  variant="body2"
                  color="#707275"
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="14px"
                >
                  Valor líquido
                </Typography>
                <Typography
                  variant="h4"
                  color="#38393B"
                  fontWeight={600}
                  fontSize="24px"
                  lineHeight="32px"
                >
                  {NumberFunctions.formatMoneyDefault(
                    NumberFunctions.toNumber(data.info)
                  )}
                </Typography>
              </Stack>
            </Stack>
          </SimpleCard>
        </Stack>

        <Stack
          direction="row"
          width="100%"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          alignItems="center"
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{ width: windowSize[0] }}
          >
            <Tab label="Aprovadas" {...a11yProps(0)} />
            <Tab label="Todas" {...a11yProps(1)} />
            <Tab label="Abertas" {...a11yProps(2)} />
            <Tab label="Abandonadas" {...a11yProps(3)} />
            <Stack height="46px" alignItems="center" justifyContent="center">
              <ExportToFile
                convertToCSV={handleConvertToCSV}
                convertToXLS={handleConvertToXLS}
              />
            </Stack>
          </Tabs>
        </Stack>

        <Stack width="100%" sx={{ mt: 2 }}>
          {data.items.map((orders: any) => {
            const colorConfig =
              checkoutOrderStatusColorMap[orders.status] ||
              checkoutOrderStatusColorMap.default
            return (
              <>
                <Box width="100%" sx={{ mb: 1, px: 2 }}>
                  <AccordionDefault>
                    <AccordionSummaryDefault>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                              <Typography
                                fontFamily="Montserrat"
                                fontWeight={500}
                                fontSize="14px"
                                lineHeight="18px"
                                color="rgba(56, 57, 59, 1)"
                              >
                                {truncateString(orders.productName, 20)}
                              </Typography>
                              <Typography
                                fontFamily="Montserrat"
                                fontWeight={500}
                                fontSize="12px"
                                lineHeight="16px"
                                color="rgba(99, 126, 239, 1)"
                              >
                                {dateTimeString(orders.createdDate)}
                              </Typography>
                            </Box>
                          </Box>

                          {tabIndex > 1 && (
                            <Box>
                              <Chip
                                label={
                                  <Typography
                                    fontSize={12}
                                    lineHeight={16}
                                    fontWeight={500}
                                  >
                                    {OrderFunctions.getDiffDaysDescription(
                                      orders.createdDate
                                    )}
                                  </Typography>
                                }
                                size="small"
                                sx={{
                                  backgroundColor: '#FBF3E2',
                                  color: '#887100',
                                }}
                              />
                            </Box>
                          )}
                          {tabIndex === 1 && (
                            <Box>
                              <Chip
                                size="small"
                                label={Convertions.checkoutOrderStatusToString(
                                  orders.status
                                )}
                                sx={{
                                  backgroundColor: colorConfig.backgroundColor,
                                  color: colorConfig.color,
                                }}
                              />
                            </Box>
                          )}
                          {tabIndex === 0 && (
                            <Typography
                              variant="body2"
                              fontFamily="Montserrat"
                              fontWeight={600}
                              fontSize="12px"
                              lineHeight="16px"
                              color="rgba(56, 57, 59, 1)"
                            >
                              {NumberFunctions.formatMoneyDefault(orders.value)}
                            </Typography>
                          )}
                        </Stack>
                      </Box>
                    </AccordionSummaryDefault>

                    <AccordionDetailsDefault>
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontFamily="Montserrat"
                            fontWeight={500}
                            fontSize="12px"
                            lineHeight="16px"
                            color="rgba(56, 57, 59, 1)"
                          >
                            Tipo de venda
                          </Typography>

                          <Stack direction={'row'} spacing={1}>
                            <Typography
                              fontFamily="Montserrat"
                              fontWeight={400}
                              fontSize="12px"
                              lineHeight="16px"
                              color="rgba(28, 82, 189, 1)"
                            >
                              {OrderFunctions.isSubscription(orders) && (
                                <img
                                  src={'/assets/icons/arrow/roundArrow.svg'}
                                  alt="icon-eyes"
                                  style={{
                                    width: '10px',
                                    height: '10px',
                                  }}
                                />
                              )}
                              {OrderFunctions.isSubscription(orders)
                                ? 'Assinatura'
                                : 'Venda'}
                            </Typography>
                            <Typography
                              fontFamily="Montserrat"
                              fontWeight={600}
                              fontSize="12px"
                              lineHeight="16px"
                              color="rgba(56, 57, 59, 1)"
                            >
                              {Convertions.valueProductTypeToString(
                                orders.valueProductType
                              )}
                            </Typography>
                          </Stack>
                        </Box>

                        <Box
                          sx={{
                            mt: 0.5,
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontFamily="Montserrat"
                            fontWeight={500}
                            fontSize="12px"
                            lineHeight="16px"
                            color="rgba(56, 57, 59, 1)"
                          >
                            Vendedor:
                          </Typography>
                          <Stack direction={'row'} spacing={1}>
                            <Typography
                              fontFamily="Montserrat"
                              fontWeight={400}
                              fontSize="12px"
                              lineHeight="16px"
                              color="rgba(28, 82, 189, 1)"
                            >
                              {Convertions.valueTypeToString(orders.valueType)}
                            </Typography>
                            <Typography
                              fontFamily="Montserrat"
                              fontWeight={600}
                              fontSize="12px"
                              lineHeight="16px"
                              color="rgba(56, 57, 59, 1)"
                            >
                              {orders.sellerName}
                            </Typography>
                          </Stack>
                        </Box>

                        <Box
                          sx={{
                            mt: 0.5,
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontFamily="Montserrat"
                            fontWeight={500}
                            fontSize="12px"
                            lineHeight="16px"
                            color="rgba(56, 57, 59, 1)"
                          >
                            Cliente:
                          </Typography>
                          <Typography
                            fontFamily="Montserrat"
                            fontWeight={600}
                            fontSize="12px"
                            lineHeight="16px"
                            color="rgba(56, 57, 59, 1)"
                          >
                            {orders.name}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            mt: 0.5,
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontFamily="Montserrat"
                            fontWeight={500}
                            fontSize="12px"
                            lineHeight="16px"
                            color="rgba(56, 57, 59, 1)"
                          >
                            Forma de Pagamento:
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent={'center'}
                          >
                            <MethodTypeSmallIcon
                              widthCustom="16px"
                              heightCustom="16px"
                              methodType={orders.paymentyMethod}
                            />
                            <Typography
                              fontFamily="Montserrat"
                              fontWeight={400}
                              fontSize="12px"
                              lineHeight="16px"
                              color="rgba(28, 82, 189, 1)"
                            >
                              {Convertions.paymentMethodToString(
                                orders.paymentyMethod
                              )}
                            </Typography>
                          </Stack>
                        </Box>
                        {tabIndex !== 0 && (
                          <Box
                            sx={{
                              mt: 0.5,
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <Typography
                              variant="body2"
                              fontFamily="Montserrat"
                              fontWeight={500}
                              fontSize="12px"
                              lineHeight="16px"
                              color="rgba(56, 57, 59, 1)"
                            >
                              Comissão:
                            </Typography>
                            <Typography
                              variant="body2"
                              fontFamily="Montserrat"
                              fontWeight={600}
                              fontSize="12px"
                              lineHeight="16px"
                              color="rgba(56, 57, 59, 1)"
                            >
                              {NumberFunctions.formatMoneyDefault(orders.value)}
                            </Typography>
                          </Box>
                        )}

                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="space-between"
                          sx={{ mt: 1 }}
                        >
                          <Button
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation()
                              handleChangeItem(orders)
                            }}
                            startIcon={
                              <img
                                src={'/assets/icons/eyes/eyeButtonWhite.svg'}
                                alt="icon-eyes"
                                style={{ width: '20px', height: '20px' }}
                              />
                            }
                            sx={{
                              backgroundColor: 'rgba(56, 57, 59, 1)',
                              color: 'white',
                              '&:hover': {
                                backgroundColor: 'rgba(56, 57, 59, 0.9)',
                              },
                              width: '120px',
                              height: '24px',

                              borderRadius: '4px',
                              padding: '2px 8px 2px 4px',
                              gap: '8px',
                            }}
                          >
                            <Typography
                              sx={{
                                width: '61px',
                                height: '16px',
                                fontFamily: 'Montserrat',
                                fontWeight: 700,
                                fontSize: '12px',
                                lineHeight: '16px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              Visualizar
                            </Typography>
                          </Button>

                          {tabIndex === 0 && (
                            <Box>
                              <Chip
                                size="small"
                                label={Convertions.checkoutOrderStatusToString(
                                  orders.status
                                )}
                                sx={{
                                  backgroundColor: colorConfig.backgroundColor,
                                  color: colorConfig.color,
                                }}
                              />
                            </Box>
                          )}
                        </Stack>
                      </Box>
                    </AccordionDetailsDefault>
                  </AccordionDefault>
                </Box>
              </>
            )
          })}
        </Stack>
      </Box>
      <TablePaginationDefault
        count={data.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        hasNewCustomHeader
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

export default CheckoutOrdersMobile
