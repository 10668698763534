import React, { useCallback } from "react";
import { IErrorResponse } from "../types/ErrorResponse";
import { TCheckoutAbandonedPagination } from "../types/CheckoutAbandoned";
import OrderController from "../controllers/OrderController";
import LostSaleController from "../controllers/LostSaleController";
import { TLostSalePagination } from "../types/LostSale";

export interface ICheckoutAbandonedsProp {
    page: number;
    rows: number;
    search?: string;
    lastDays: number;
    report: "order" | "subscription";
}

const valueDefault = {
    items: [],
    page: 1,
    rowsPerPage: 0,
    totalPages: 0,
    total: 0,
};

export const useLostSales = ({ page, rows, search, lastDays, report }: ICheckoutAbandonedsProp) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<TLostSalePagination>(valueDefault);
    const [error, setError] = React.useState<string>('');

    const execute = useCallback(
        async () => {
            if (!loading) {
                try {
                    setLoading(true);

                    if (error !== '') {
                        setError('');
                    }

                    const response = await LostSaleController.getAll({ page, rows, search, lastDays, report });
                    const responseError = response as IErrorResponse;
                    const responseData = response as TLostSalePagination;

                    if (responseError.code) {
                        setError(responseError.error);
                        setData(valueDefault);
                    } else {
                        setData(responseData);
                    }
                } catch (e) {
                    var message = 'Erro não identificado';
                    if (typeof e === "string") {
                        message = e.toUpperCase();
                    } else if (e instanceof Error) {
                        const err = e as Error;
                        message = err.message;
                    }
                    setError(message);
                } finally {
                    setLoading(false);
                }
            }
        },
        [error, lastDays, loading, page, rows, search]
    );

    React.useEffect(() => {
        execute();
    }, [lastDays, page, rows, search]);

    const clear = () => {
        setError('');
    }

    return {
        loading,
        data,
        error,
        execute,
        clear,
    };
}