import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../../layouts/Theme';
import CopyrightCeletus from '../../../components/Copyright/CopyrightCeletus';
import { TopLogo } from '../../../components/Logo/TopLogo';
import { Alert, AlertTitle, Card, CircularProgress, IconButton, InputAdornment, Stack } from '@mui/material';
import { TUserChangePassword } from '../../../core/types/User';
import { useForm } from 'react-hook-form';
import UserController from '../../../core/controllers/UserController';
import TextFieldRequired from '../../../components/Inputs/TextFieldRequired';
import Validations from '../../../core/functions/validations';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

export default function ChangePasswordPage() {
    var { codeId } = useParams<{ codeId?: string; }>();

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = React.useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [expired, setExpired] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [sending, setSending] = React.useState(false);

    const [error, setError] = React.useState('');
    const [minError, setMinError] = React.useState<'error' | 'green'>('error');
    const [maxError, setMaxError] = React.useState<'error' | 'green'>('error');
    const [lowError, setLowError] = React.useState<'error' | 'green'>('error');
    const [uppError, setUppError] = React.useState<'error' | 'green'>('error');
    const [speError, setSpeError] = React.useState<'error' | 'green'>('error');
    const [hasPasswordError, setHasPasswordError] = React.useState(false);

    const {
        control,
        handleSubmit,
        watch,
        setFocus,
        clearErrors,
    } = useForm<TUserChangePassword>({
        defaultValues: {
            codeId: codeId,
            password: "",
            passwordConfirm: "",
        },
    });

    const passwordValue = watch("password");

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

    const handleMouseDownPasswordConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const Submit = async (data: TUserChangePassword) => {
        if (data.password === "") {
            setError("É necessário preencher o campo Senha");
            setFocus("password");
            return;
        }
        if (data.passwordConfirm === "") {
            setError("É necessário preencher o campo Canfirmação da Senha");
            setFocus("passwordConfirm");
            return;
        }
        if (data.password !== data.passwordConfirm) {
            setError("A senha digitada não confere com a confirmação da senha");
            setFocus("password");
            return;
        }

        setSending(true);
        setSuccess(false);
        setError("");
        try {
            const response = await UserController.changePassword({ data });
            if (!response.success) {
                setError(`Não foi possível redefinir a senha. ${response.error}`);
            } else {
                setSuccess(true);
            }
        } finally {
            setSending(false);
        }
    };

    const validatePassword = (value: string) => {
        if ((!value) || (value === '')) {
            return true;
        }
        
        const result = Validations.password(value);
        setMinError(result.includes('min') ? 'error' : 'green' );
        setMaxError(result.includes('max') ? 'error' : 'green' );
        setLowError(result.includes('lower') ? 'error' : 'green' );
        setUppError(result.includes('upper') ? 'error' : 'green' );
        setSpeError(result.includes('special') ? 'error' : 'green' );
        setHasPasswordError(!result.includes("success"));

        return result.includes("success");
    }

    const validatePasswordLabel = () => {
        const result = Validations.password(passwordValue);

        if (result.includes('min'))
            return "Precisa ser igual ou maior que 6 caracteres";
        if (result.includes('max'))
            return "Precisa ser igual ou menor que 20 caracteres";
        if (result.includes('lower'))
            return "Precisa ter no mínimo uma letra minúscula";
        if (result.includes('upper'))
            return "Precisa ter no mínimo uma letra maiúscula";
        if (result.includes('special'))
            return "Precisa ter no mínimo um caracter especial";
        
        return "";
    }

    const isExpired = React.useCallback(
        async () => {
            setLoading(true);
            try {
                const response = await UserController.validateForgotPassword({ data: { codeId: codeId || "" } });
                if (!response.success) {
                    setExpired(true);
                }
                else if (response.data && response.data.expired === true) {
                    setExpired(true);
                }
            } finally {
                setLoading(false);
            }
        },
        []
    );

    const handleChangePassword = (value: string) => {
        if (value.trim().length < 1) return;

        const result = Validations.password(value);
        setMinError(result.includes('min') ? 'error' : 'green' );
        setMaxError(result.includes('max') ? 'error' : 'green' );
        setLowError(result.includes('lower') ? 'error' : 'green' );
        setUppError(result.includes('upper') ? 'error' : 'green' );
        setSpeError(result.includes('special') ? 'error' : 'green' );

        setHasPasswordError(!result.includes("success"));
    }

    React.useEffect(() => {
        isExpired();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <TopLogo />
                    <Typography component="h1" variant="h5" textAlign="center" fontWeight={900}>
                        Redefinir a senha
                    </Typography>
                    <Typography variant="subtitle1" textAlign="center">
                        Crie uma nova senha
                    </Typography>
                    <Card sx={{ width: "100%", mt: 3, mb: 2 }}>
                        {loading && (
                            <Stack
                                direction={"column"}
                                width={"100%"}
                                sx={{ p: 3 }}
                                spacing={3}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CircularProgress size="2rem" />
                            </Stack>
                        )}
                        {!loading && expired && (
                            <Box sx={{ p: 5 }}>
                                <Stack alignContent="center" alignItems="center" sx={{ marginBottom: 3 }}>
                                    <ErrorIcon color='disabled' sx={{ fontSize: "4.5rem" }} />
                                </Stack>
                                <Typography variant='h6' textAlign="center">
                                    Renovação de senha expirada!
                                </Typography>
                            </Box>
                        )}
                        {!loading && !expired && (
                            <Box component="form" noValidate onSubmit={handleSubmit(Submit)} sx={{ p: 5 }}>
                                <TextFieldRequired
                                    control={control}
                                    fieldName="password"
                                    label="Senha"
                                    placeholder="Digite a senha"
                                    clearErrors={clearErrors}
                                    validate={validatePassword}
                                    validateLabel={validatePasswordLabel()}
                                    type={showPassword ? 'text' : 'password'}
                                    onKeyDown={(e) => handleChangePassword((e.target as any).value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    sx={{ mb: 3 }}
                                />
                                {hasPasswordError && (
                                    <Alert
                                        severity={hasPasswordError ? 'error' : 'success'}
                                        sx={{ width: '100%', mt: 1 }}
                                    >
                                        <AlertTitle>A senha precisa respeitar as seguintes condições</AlertTitle>
                                        <Typography variant="caption" color={minError} >Precisa ser igual ou maior que 6 caracteres</Typography><br />
                                        <Typography variant="caption" color={maxError}>Precisa ser igual ou menor que 20 caracteres</Typography><br />
                                        <Typography variant="caption" color={lowError}>Precisa ter no mínimo uma letra minúscula</Typography><br />
                                        <Typography variant="caption" color={uppError}>Precisa ter no mínimo uma letra maiúscula</Typography><br />
                                        <Typography variant="caption" color={speError}>Precisa ter no mínimo um caracter especial</Typography>
                                    </Alert>
                                )}
                                <TextFieldRequired
                                    control={control}
                                    fieldName="passwordConfirm"
                                    label="Confirmação da Senha"
                                    placeholder="Digite a confirmação da senha"
                                    clearErrors={clearErrors}
                                    validate={validatePassword}
                                    type={showPasswordConfirm ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPasswordConfirm}
                                                onMouseDown={handleMouseDownPasswordConfirm}
                                                edge="end"
                                            >
                                                {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3 }}
                                    startIcon={sending ? <CircularProgress size="1rem" /> : undefined}
                                    disabled={sending}
                                >
                                    Redefinir senha
                                </Button>
                            </Box>
                        )}
                    </Card>
                </Box>
                <CopyrightCeletus />
            </Container>

            <ErrorSnackbar
                open={error !== ''}
                onClose={() => setError('')}
            >
                {error}
            </ErrorSnackbar>

            <SuccessSnackbar
                open={success}
                autoHideDuration={1000}
                onClose={() => {
                    setSuccess(false);
                    navigate("/");
                }}
            >
                Alterado com sucesso
            </SuccessSnackbar>

        </ThemeProvider>
    );
}