import { Box } from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  p?: number
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, p = 3, ...other } = props

  return (
    <Box
      sx={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box display="flex" sx={{ p: p }}>
          {children}
        </Box>
      )}
    </Box>
  )
}
