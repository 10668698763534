import {
  Chip,
  Select,
  Stack,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Autocomplete,
  TextField,
} from '@mui/material'
import { TAffiliatedSimple } from '../../../../core/types/Affiliated'
import { Theme, useTheme } from '@mui/material/styles'

export interface IAffiliatedFilterProps {
  affiliateds: TAffiliatedSimple[]
  handleAffiliatedFilter: (values: string[]) => void
  affiatedList: string[]
  setAffiliatedList: (value: React.SetStateAction<string[]>) => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const AffiliatedFilter = ({
  affiliateds,
  handleAffiliatedFilter,
  affiatedList,
  setAffiliatedList,
}: IAffiliatedFilterProps) => {
  const theme = useTheme()

  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    values: string[]
  ) => {
    setAffiliatedList(values)

    var ids = affiliateds
      .filter((x) => values.includes(x.name))
      .map((i) => i.id)
    if (ids?.length > 0) handleAffiliatedFilter(ids)
    else handleAffiliatedFilter([])
  }

  function getStyles(name: string, list: readonly string[], theme: Theme) {
    return {
      fontWeight:
        list.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    }
  }

  return (
    <Stack direction="row" alignContent="center" alignItems="center">
      <FormControl fullWidth>
        <Autocomplete
          id="affiliated-multiple-chip"
          multiple
          options={affiliateds.map((p) => p.name)}
          value={affiatedList}
          onChange={handleChange}
          renderTags={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip size="small" key={value} label={value} />
              ))}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Afiliados"
              placeholder="Pesquisar"
            />
          )}
        />
      </FormControl>
    </Stack>
  )
}
