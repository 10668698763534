import { CreditCard, CreditCardType } from "../types/CreditCard";
import { startNumberMaster } from "../constants";
import CreditCards from "../functions/creditCards";

export class CreditCardMaster implements CreditCard {
    getType(): CreditCardType {
        return CreditCardType.Master;
    }
    compare(number?: string): boolean {
        return CreditCards.compare(startNumberMaster, number);
    }
    getImageUrl() {
        return `${process.env.PUBLIC_URL}/assets/creditcards/mastercard.png`;
    }
}