import { Button, Paper } from "@mui/material";

export interface IIconFilterButtonProps {
    icon: React.ReactNode;
    backgroundColor?: string | undefined;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const IconFilterButton = ({ icon, backgroundColor, onClick }: IIconFilterButtonProps) => {
    return (
        <Button sx={{ p: 0, m: 0, minWidth: 0, borderRadius: "8px" }} onClick={onClick}>
            <Paper
                elevation={0}
                sx={{
                    borderRadius: "8px",
                    p: "4px",
                    fontSize: "0rem",
                    backgroundColor,
                }}
            >
                {icon}
            </Paper>
        </Button>
    );
};

export default IconFilterButton;
