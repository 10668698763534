import { createTheme } from '@mui/material'
import { grey } from '@mui/material/colors'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    main: true
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1C52BD',
      900: '#1A3579',
      800: '#21469D',
      700: '#3057BE',
    },
    secondary: {
      main: '#F6D240',
      900: '#473800',
      800: '#5C4A00',
      700: '#715D00',
    },
    background: {
      default: grey[50],
    },
  },
  typography: {
    fontFamily: 'Montserrat, "Open Sans", Roboto',
    h1: { fontSize: '3.5rem' },
    h2: { fontSize: '3rem' },
    h3: { fontSize: '2.5rem' },
    h4: { fontSize: '2rem' },
    h5: { fontSize: '1.5rem' },
    h6: { fontSize: '1.25rem' },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '::-ms-reveal': {
            display: 'none',
          },
          '::-ms-clear': {
            display: 'none',
          },
          borderRadius: '10px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '::-ms-reveal': {
            display: 'none',
          },
          '::-ms-clear': {
            display: 'none',
          },
          borderRadius: '10px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiListItemButton-root': {
            paddingLeft: 8,
            paddingRight: 8,
            marginLeft: 8,
            marginRight: 8,
          },
          '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: 16,
          },
          '& .MuiSvgIcon-root': {
            fontSize: 24,
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'main' },
          style: {
            textTransform: 'none',
            border: '0px solid black',
            color: 'white',
            backgroundColor: '#1C52BD',
            '&:hover': {
              backgroundColor: '#1A3579',
              boxShadow: '2px 2px 6px grey',
              top: '-0.5px',
            },
          },
        },
      ],
    },
  },
})

export const themeWithBackgroundWhite = createTheme({
  ...theme,
  palette: { background: { default: 'white' } },
})

export default theme
