import { IDataTableColumn } from '../../../../components/Tables/DataTable'
import { checkoutOrderColumnsDefault } from './CheckoutOrderColumnsDefault'
import {
  CheckoutOrderLinkColumn,
  CheckoutOrderCustomerWhatsAppColumn,
  checkoutOrderPaymentAndCommissionColumns,
  checkoutOrderStatusTimeColumn,
} from '../CheckoutOrderConsts'
import { TCheckoutOrder } from '../../../../core/types/CheckoutOrder'
import { Box, Stack, Typography } from '@mui/material'
import {
  dayAndMonthString,
  timeString,
} from '../../../../core/functions/dateTime'

export function checkoutOrderColumnsOpened(
  setCopied: (value: boolean) => void,
  onDownloadBoleto: (value: string) => Promise<void>,
  onCopyPix: (value: string) => Promise<void>,
  execute: () => void
) {
  const columns: IDataTableColumn<TCheckoutOrder>[] = [
    {
      id: 'createdDate',
      label: 'Criado em',
      render(id, value, row) {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Box width="24px" height="24px">
              <img
                src={`/assets/icons/check/${!row.reachedOutForAbandonedOrder ? 'un' : ''}checked.svg`}
                alt="check"
              />
            </Box>
            <Stack direction="column">
              <Typography
                fontFamily="Montserrat"
                fontWeight={400}
                fontSize="14px"
                lineHeight="18px"
              >
                {dayAndMonthString(value)}
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontWeight={400}
                fontSize="12px"
                lineHeight="16px"
                color="#707275"
              >
                {timeString(value)}
              </Typography>
            </Stack>
          </Stack>
        )
      },
    },
    ...checkoutOrderColumnsDefault,
    checkoutOrderStatusTimeColumn,
    ...checkoutOrderPaymentAndCommissionColumns,
    CheckoutOrderLinkColumn({
      setCopied,
      onDownloadBoleto,
      onCopyPix,
      execute,
    }),
    CheckoutOrderCustomerWhatsAppColumn({ execute }),
  ]
  return columns
}
