import api, { getResponseError } from "../../services/api";
import { TCustomer, TCustomerPagination, TCustomerProduct, TCustomerProductStatus } from "../types/Customer";
import { IErrorResponse, IResponse } from "../types/ErrorResponse";

interface IGetCustomerProp {
    page: number;
    rows: number;
    name?: string;
}

const getAll = async ({ page, rows, name }: IGetCustomerProp): Promise<TCustomerPagination | IErrorResponse> => {
    try {
        var url = `/Customer?PageNumber=${page}&RowsPerPage=${rows}`;

        if (name && name !== '') {
            url += `&Name=${name}`;
        }

        const { data } = await api.get<TCustomerPagination>(url);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const get = async ({ id }: { id: string }): Promise<TCustomer | IErrorResponse> => {
    try {
        const { data } = await api.get<TCustomer>(`/Customer/${id}`);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getProducts = async ({ customerId }: { customerId: string }): Promise<TCustomerProduct[] | IErrorResponse> => {
    try {
        const { data } = await api.get<TCustomerProduct[]>(`/Customer/orders/customer/${customerId}`);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const update = async ({ data }: { data: TCustomer }): Promise<IResponse> => {
    try {
        const { data: response } = await api.put('/Customer', data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const updateProductStatus = async (data: { id: string, customerId: string, productId: string, status: TCustomerProductStatus }): Promise<IResponse> => {
    try {
        const { data: response } = await api.put('/MemberService/update/status', data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const updateProductApproved = async (data: { id: string, customerId: string, productId: string }): Promise<IResponse> => {
    return await updateProductStatus({ ...data, status: TCustomerProductStatus.Aprovado });
}

const updateProductCanceled = async (data: { id: string, customerId: string, productId: string }): Promise<IResponse> => {
    return await updateProductStatus({ ...data, status: TCustomerProductStatus.Cancelado });
}

const CustomerController = {
    getAll,
    get,
    getProducts,
    update,
    updateProductApproved,
    updateProductCanceled,
}

export default CustomerController;