import { TCommissionRuleType } from "./CommissionRule";

export enum TAffiliatedStatus {
    All = 0,
    Accepted = 1,
    Pending = 2,
    Refused = 3,
    Blocked = 4,
}

export type TAffiliated = {
    id?: string;
    tenantId: string;
    tenantName?: string;
    productAffiliationId: string;
    productId: string;
    productActive: boolean;
    productName?: string;
    codeId?: string;
    tenantCodeId?: string;
    status: TAffiliatedStatus;
    createdDate?: Date;
    email?: string;
    commissionPercentage?: any;
    globalCommissionPercentage?: any;

    productCommissionPercentage?: number;
    productGlobalCommissionPercentage?: number;
    affiliatedMonthlyFeesPaid?: number;
    affiliatedCommissionValue?: number;

    enableTargetCommission?: boolean;

    productAllowRegistratioinThanksPages: boolean;
    approvedPageLink?: string | null;
    awaitingPaymentPageLink?: string | null;
    awaitingCreditAnalysisPageLink?: string | null;

    activedRecoverySale: boolean;
}

export type TAffiliatedPagination = {
    items: TAffiliated[];
    page: number;
    rowsPerPage: number;
    totalPages: number;
    total: number;
}

export enum TAffiliatedCommissionType {
    Manual = 1,
    Targes = 2,
}

export type TAffiliatedCommission = {
    id: string;
    startDate: Date;
    endDate: Date;
    commissionPercentage: number;
    globalCommissionPercentage: number;
    commissionType: TAffiliatedCommissionType,
    commissionTypeDescription: string;
    ruleType: TCommissionRuleType;
    ruleTypeDescription: string;
}

export type TAffiliatedSimple = {
    id: string;
    name: string;
}