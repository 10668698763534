import api, { getResponseError } from '../../services/api'
import { IErrorResponse, IResponse } from '../types/ErrorResponse'
import { IOrderBump, IOrderBumpPagination } from '../types/OrderBump'
import { TProductTypePayment } from '../types/Product'

interface IGetOrderBumpProp {
  page: number
  rows: number
  search?: string
}

const getAll = async ({
  page,
  rows,
  search,
}: IGetOrderBumpProp): Promise<IOrderBumpPagination | IErrorResponse> => {
  try {
    var path = `/Product/orderBumps?PageNumber=${page}&RowsPerPage=${rows}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    const { data } = await api.get<IOrderBumpPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getProducts = async ({
  typePayment,
}: {
  typePayment?: TProductTypePayment
}): Promise<IOrderBump[] | IErrorResponse> => {
  try {
    var url = '/Product/orderBumps/list'

    if (typePayment && typePayment === TProductTypePayment.OneTimePayment) {
      url += `?TypePayment=${typePayment}`
    }

    const { data } = await api.get<IOrderBump[]>(url)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const get = async ({
  id,
}: {
  id: string
}): Promise<IOrderBump | IErrorResponse> => {
  try {
    const { data } = await api.get<IOrderBump>(`/Product/orderBump/${id}`)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getByProductPriceId = async ({
  productPriceId,
}: {
  productPriceId: string
}): Promise<IOrderBump[] | IErrorResponse> => {
  try {
    var path = `/Product/price/${productPriceId}/orderBumps`

    const { data } = await api.get<IOrderBump[]>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const insert = async ({ data }: { data: IOrderBump }): Promise<IResponse> => {
  try {
    const { data: response } = await api.post('/Product/orderBump', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const update = async ({ data }: { data: IOrderBump }): Promise<IResponse> => {
  try {
    const { data: response } = await api.put('/Product/orderBump', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const remove = async ({ id }: { id: string }): Promise<IResponse> => {
  try {
    const { data: response } = await api.delete(`/Product/orderBump/${id}`)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const OrderBumpController = {
  getAll,
  getProducts,
  get,
  getByProductPriceId,
  insert,
  update,
  remove,
}

export default OrderBumpController
