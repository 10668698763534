import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { TWebhookEventEnum } from '../../../../core/types/Webhook'

interface WebhookEventsProps {
  events?: number[] | undefined
  setEvents: (events: number[] | undefined) => void
}

const WebhookEvents = ({ events, setEvents }: WebhookEventsProps) => {
  const isChecked = (event: TWebhookEventEnum) => {
    return events?.find((e) => e === event) !== undefined
  }

  const handleChecked = (checked: boolean, event: TWebhookEventEnum) => {
    if (checked) {
      if (!events) {
        events = []
      }
      events?.push(event)
    } else {
      events = events?.filter((x) => x !== event)
    }
    if (!events) {
      events = []
    }
    setEvents([...events])
  }

  const handleSelectedAll = () => {
    events = [
      TWebhookEventEnum.BoletoGenerated,
      TWebhookEventEnum.PixGenerated,
      TWebhookEventEnum.PurchaseDeclined,
      TWebhookEventEnum.ApprovedPurchase,
      TWebhookEventEnum.Chargeback,
      TWebhookEventEnum.ExpiredPurchase,
      TWebhookEventEnum.LatePurchase,
      TWebhookEventEnum.WaitingPayment,
      TWebhookEventEnum.AbandonedCheckout,
      TWebhookEventEnum.Protested,
      TWebhookEventEnum.Refunded,
    ]
    setEvents(events)
  }

  return (
    <Box width="100%">
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          color="#38393B"
          fontSize="14px"
          lineHeight="24px"
          fontWeight={600}
        >
          Evento
        </Typography>
        <Button
          variant="text"
          size="small"
          onClick={handleSelectedAll}
          sx={{ textTransform: 'none' }}
        >
          {'[Selecionar todos os eventos]'}
        </Button>
      </Stack>
      <FormGroup>
        <option aria-label="None" value="" />
        <optgroup label="Eventos únicos"></optgroup>

        <Tooltip title="Evento gerado quando um compra é aprovada com meio de pagamento do tipo 'Boleto'">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isChecked(TWebhookEventEnum.BoletoGenerated)}
                onChange={(e) =>
                  handleChecked(
                    e.target.checked,
                    TWebhookEventEnum.BoletoGenerated
                  )
                }
              />
            }
            label={
              <Typography
                color="#4A4B4E"
                fontSize="14px"
                lineHeight="18px"
                fontWeight={500}
              >
                Boleto gerado
              </Typography>
            }
          />
        </Tooltip>

        <Tooltip title="Evento gerado quando um compra é aprovada com meio de pagamento do tipo 'PIX'">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isChecked(TWebhookEventEnum.PixGenerated)}
                onChange={(e) =>
                  handleChecked(
                    e.target.checked,
                    TWebhookEventEnum.PixGenerated
                  )
                }
              />
            }
            label={
              <Typography
                color="#4A4B4E"
                fontSize="14px"
                lineHeight="18px"
                fontWeight={500}
              >
                Pix gerado
              </Typography>
            }
          />
        </Tooltip>

        <optgroup label="Compras"></optgroup>

        <Tooltip title="Compra com cartão de crédito/débito recusada pelo 'gateway de pagamento', pelo próprio cartão ou pelo banco.">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isChecked(TWebhookEventEnum.PurchaseDeclined)}
                onChange={(e) =>
                  handleChecked(
                    e.target.checked,
                    TWebhookEventEnum.PurchaseDeclined
                  )
                }
              />
            }
            label={
              <Typography
                color="#4A4B4E"
                fontSize="14px"
                lineHeight="18px"
                fontWeight={500}
              >
                Compra recusada
              </Typography>
            }
          />
        </Tooltip>

        {/* <Tooltip title="Compra com cartão de crédito/débito recusada pelo 'gateway de pagamento' devido ao bloqueio pelo sistema de antifraude.">
							<FormControlLabel
								control={
									<Checkbox
										size="small"
										checked={ isChecked(TWebhookEventEnum.PurchaseDeclinedBlocked) }
										onChange={(e) => handleChecked(e.target.checked, TWebhookEventEnum.PurchaseDeclinedBlocked) }
									/>
								}
								label="Compra recusada - Bloqueada"
							/>
						</Tooltip> */}

        {/* <Tooltip title="Compra com cartão de crédito/débito recusada pelo 'gateway de pagamento' por falta de saldo no cartão.">
							<FormControlLabel
								control={
									<Checkbox
										size="small"
										checked={ isChecked(TWebhookEventEnum.PurchaseDeclinedNoFunds) }
										onChange={(e) => handleChecked(e.target.checked, TWebhookEventEnum.PurchaseDeclinedNoFunds) }
									/>
								}
								label="Compra recusada - Sem fundos"
							/>
						</Tooltip> */}

        <Tooltip title="Compra aprovada pelo 'gateway de pagamento'">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isChecked(TWebhookEventEnum.ApprovedPurchase)}
                onChange={(e) =>
                  handleChecked(
                    e.target.checked,
                    TWebhookEventEnum.ApprovedPurchase
                  )
                }
              />
            }
            label={
              <Typography
                color="#4A4B4E"
                fontSize="14px"
                lineHeight="18px"
                fontWeight={500}
              >
                Compra aprovada
              </Typography>
            }
          />
        </Tooltip>

        {/* <Tooltip title="Compra aprovada em PIX ou boleto que saíram do statud de pendente para pago">
							<FormControlLabel
								control={
									<Checkbox
										size="small"
										checked={ isChecked(TWebhookEventEnum.ApprovedPurchaseComplete) }
										onChange={(e) => handleChecked(e.target.checked, TWebhookEventEnum.ApprovedPurchaseComplete) }
									/>
								}
								label="Compra aprovada - completa"
							/>
						</Tooltip> */}

        <Tooltip title="Compras que iniciaram por PIX ou boleto e não foram pagos.">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isChecked(TWebhookEventEnum.ExpiredPurchase)}
                onChange={(e) =>
                  handleChecked(
                    e.target.checked,
                    TWebhookEventEnum.ExpiredPurchase
                  )
                }
              />
            }
            label={
              <Typography
                color="#4A4B4E"
                fontSize="14px"
                lineHeight="18px"
                fontWeight={500}
              >
                Compra expirada
              </Typography>
            }
          />
        </Tooltip>

        <Tooltip title="Para compras não imediatas que precisam de uma ação de pagamento do usuário.">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isChecked(TWebhookEventEnum.WaitingPayment)}
                onChange={(e) =>
                  handleChecked(
                    e.target.checked,
                    TWebhookEventEnum.WaitingPayment
                  )
                }
              />
            }
            label={
              <Typography
                color="#4A4B4E"
                fontSize="14px"
                lineHeight="18px"
                fontWeight={500}
              >
                Aguardando pagamento
              </Typography>
            }
          />
        </Tooltip>

        {/* <Tooltip title="Pagamentos que dependem de um processamento para serem concluídos.">
							<FormControlLabel
								control={
									<Checkbox
										size="small"
										checked={ isChecked(TWebhookEventEnum.ProcessingTransaction) }
										onChange={(e) => handleChecked(e.target.checked, TWebhookEventEnum.ProcessingTransaction) }
									/>
								}
								label="Aguardando processamento"
							/>
						</Tooltip> */}

        <Tooltip title="O usuário faz uma solicitação de reembolso da compra pelo emissor do cartão.">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isChecked(TWebhookEventEnum.Chargeback)}
                onChange={(e) =>
                  handleChecked(e.target.checked, TWebhookEventEnum.Chargeback)
                }
              />
            }
            label={
              <Typography
                color="#4A4B4E"
                fontSize="14px"
                lineHeight="18px"
                fontWeight={500}
              >
                Estorno - (ChargeBack)
              </Typography>
            }
          />
        </Tooltip>

        <Tooltip title="Solicitação de reembolso pelo usuário.">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isChecked(TWebhookEventEnum.Protested)}
                onChange={(e) =>
                  handleChecked(e.target.checked, TWebhookEventEnum.Protested)
                }
              />
            }
            label={
              <Typography
                color="#4A4B4E"
                fontSize="14px"
                lineHeight="18px"
                fontWeight={500}
              >
                Solicitação reembolso
              </Typography>
            }
          />
        </Tooltip>

        <Tooltip title="Reembolso realizado para o usuário.">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isChecked(TWebhookEventEnum.Refunded)}
                onChange={(e) =>
                  handleChecked(e.target.checked, TWebhookEventEnum.Refunded)
                }
              />
            }
            label={
              <Typography
                color="#4A4B4E"
                fontSize="14px"
                lineHeight="18px"
                fontWeight={500}
              >
                Reembolso
              </Typography>
            }
          />
        </Tooltip>

        <optgroup label="Assinaturas"></optgroup>

        <Tooltip title="Atraso no pagamento de recorrência no boleto ou PIX.">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isChecked(TWebhookEventEnum.LatePurchase)}
                onChange={(e) =>
                  handleChecked(
                    e.target.checked,
                    TWebhookEventEnum.LatePurchase
                  )
                }
              />
            }
            label={
              <Typography
                color="#4A4B4E"
                fontSize="14px"
                lineHeight="18px"
                fontWeight={500}
              >
                Recorrência em atraso
              </Typography>
            }
          />
        </Tooltip>

        <optgroup label="Outros"></optgroup>

        <Tooltip title="Usuários que chegaram no carrinho e não finalizaram a compra.">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isChecked(TWebhookEventEnum.AbandonedCheckout)}
                onChange={(e) =>
                  handleChecked(
                    e.target.checked,
                    TWebhookEventEnum.AbandonedCheckout
                  )
                }
              />
            }
            label={
              <Typography
                color="#4A4B4E"
                fontSize="14px"
                lineHeight="18px"
                fontWeight={500}
              >
                Compras abandonadas.
              </Typography>
            }
          />
        </Tooltip>
      </FormGroup>
    </Box>
  )
}

export default WebhookEvents
