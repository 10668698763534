import { Stack, FormControl, MenuItem, Select, InputLabel } from '@mui/material'

export interface ISearchTypeFilterProps {
  typeFilterValue: number
  handleTypeFilter: (value: number) => void
}

const options = [
  { value: 0, label: 'Todos' },
  { value: 1, label: 'Sou produtor' },
  { value: 2, label: 'Sou co-produtor' },
  { value: 3, label: 'Sou afiliado' },
]

export const SearchTypeFilter = ({
  typeFilterValue,
  handleTypeFilter,
}: ISearchTypeFilterProps) => {
  return (
    <Stack direction="row" alignContent="center" alignItems="center">
      <FormControl fullWidth>
        <InputLabel id="type-select-label">Tipo</InputLabel>
        <Select
          variant="outlined"
          labelId="type-select-label"
          label="Tipo"
          defaultValue={0}
          value={typeFilterValue}
          onChange={(e) => handleTypeFilter(Number(e.target.value))}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  )
}
