import { Stack, Typography } from '@mui/material'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'
import {
  TTenantAnticipationType,
  TTenantSettings,
} from '../../../core/types/Tenant'
import TenantAnticipationItem from './TenantAnticipationItem'
import { TenantController } from '../../../core/controllers/TenantController'
import { creditCardInstallmentFee } from '../../../core/constants'

export interface IProductPixelRegisterProps {
  open: boolean
  sending: boolean
  tenantSettings?: TTenantSettings | null
  anticipationType?: TTenantAnticipationType | null
  setOpen: (value: boolean) => void
  setSending: (value: boolean) => void
  refresh: () => void
  setError: (value: string) => void
}

const TenantAnticipationDrawerRight = ({
  open,
  sending,
  tenantSettings,
  anticipationType,
  setOpen,
  setSending,
  refresh,
  setError,
}: IProductPixelRegisterProps) => {
  const anticipationNumber =
    tenantSettings?.enableAnticipationD2 ?? false
      ? tenantSettings?.anticipationD2Fee
      : tenantSettings?.enableAnticipationD15 ?? false
        ? tenantSettings?.anticipationD15Fee
        : creditCardInstallmentFee

  const minAnticipationType =
    tenantSettings?.enableAnticipationD2 ?? false
      ? TTenantAnticipationType.D2
      : tenantSettings?.enableAnticipationD15 ?? false
        ? TTenantAnticipationType.D15
        : TTenantAnticipationType.D30

  const currentFee =
    anticipationType === TTenantAnticipationType.D2
      ? tenantSettings?.anticipationD2Fee ?? 4
      : anticipationType === TTenantAnticipationType.D15
        ? tenantSettings?.anticipationD15Fee ?? 2
        : creditCardInstallmentFee

  const handleChangeAnticipationType = async (
    value: TTenantAnticipationType
  ) => {
    setSending(true)
    try {
      const response = await TenantController.setAnticipation({
        data: {
          tenantId: tenantSettings?.tenantId ?? '',
          anticipationType: value,
        },
      })

      if (response.error) {
        setError(response.error)
        return true
      }

      setOpen(false)
      refresh()
    } finally {
      setSending(false)
    }
    return true
  }

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={() => setOpen(false)}
      setOpen={(value) => setOpen(value)}
      title="Quando você quer receber suas vendas de cartão de crédito?"
    >
      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
        {tenantSettings?.enableAnticipationD2 && (
          <TenantAnticipationItem
            anticipationType={TTenantAnticipationType.D2}
            tax={tenantSettings.anticipationD2Fee ?? 0}
            isCurrent={anticipationType === TTenantAnticipationType.D2}
            isRecomended={true}
            currentFee={currentFee}
            sending={sending}
            onChangeAnticipationType={handleChangeAnticipationType}
          />
        )}
        {tenantSettings?.enableAnticipationD15 && (
          <TenantAnticipationItem
            anticipationType={TTenantAnticipationType.D15}
            tax={tenantSettings.anticipationD15Fee ?? 0}
            isCurrent={anticipationType === TTenantAnticipationType.D15}
            isRecomended={
              (tenantSettings?.enableAnticipationD2 ?? false) === false
            }
            currentFee={currentFee}
            sending={sending}
            onChangeAnticipationType={handleChangeAnticipationType}
          />
        )}
        <TenantAnticipationItem
          anticipationType={TTenantAnticipationType.D30}
          tax={creditCardInstallmentFee}
          isCurrent={
            (anticipationType ?? TTenantAnticipationType.D30) ===
            TTenantAnticipationType.D30
          }
          isRecomended={false}
          currentFee={currentFee}
          sending={sending}
          onChangeAnticipationType={handleChangeAnticipationType}
        />
        {/* <Stack sx={{ pt: 2 }}>
                    <Typography variant="body1" fontWeight={500} gutterBottom>Informações adicionais</Typography>
                    <Typography variant="body2">Ao optar pelo prazo de {Number(minAnticipationType)} dias, até 10% do valor das vendas de cartão de crédito é reservado para garantir possíveis reembolsos, e fica disponível para saque em 30 dias.</Typography>
                </Stack> */}
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}

export default TenantAnticipationDrawerRight
