export enum TOccurrenceTypeEnum {
  Info = 1,
  Warnning = 2,
  Error = 3,
}

export enum TOccurrenceEventEnum {
  Withdraws = 1,
  MemberService = 2,
}

export enum TOccurrenceStatusEnum {
  Active = 1,
  Inactive = 2,
  Resolved = 3,
  Unresolved = 4,
}

export type TOccurrenceLog = {
  id: string
  codeId?: string
  description?: string
  status?: TOccurrenceStatusEnum
  type?: TOccurrenceTypeEnum
  event?: TOccurrenceEventEnum
  createdDate: Date
  updatedDate: Date
}

export type TOccurrenceLogPagination = {
  items: TOccurrenceLog[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
  info?: number
}

export type TOccurrenceDetails = {
  id: string
  codeId?: string
  description?: string
  status?: TOccurrenceStatusEnum
  type?: TOccurrenceTypeEnum
  event?: TOccurrenceEventEnum
  createdDate: Date
  updatedDate: Date

  interactions: TOccurrenceInteration[]
}

export type TOccurrenceInteration = {
  actorName: string
  message: string
  isFixed: boolean
}
