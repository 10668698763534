import { Box, Stack, Typography } from "@mui/material";

export interface INotFoundChartProps {
    isVisible: boolean;
}

const NotFoundChart = ({ isVisible }: INotFoundChartProps) => {
    if (!isVisible) {
        return <></>;
    }

    return (
        <Stack height="200px" width="100%" alignItems="center" justifyContent="center">
            <Typography textAlign="center">Não existem informações neste período.</Typography>
        </Stack>
    );
};

export default NotFoundChart;
