import moment from 'moment'

export const monthOfYear = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

export const daysOfWeek = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
]

export const yearsExpires = () => {
  const now = new Date()
  const year = now.getFullYear()
  const years: number[] = []

  for (var i = year; i < year + 10; i++) {
    years.push(Number(i.toString().substring(2, 4)))
  }

  return years
}

export const lastYears = (n?: number | null) => {
  n ??= 5

  const now = new Date()
  const year = now.getFullYear()
  const years: number[] = []

  for (var i = year; i >= year - n; i--) {
    years.push(i)
  }

  return years
}

export const dateTimeString = (dateTime?: Date) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('DD/MM/yyyy HH:mm')
}

export const dateString = (dateTime?: Date | null) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('DD/MM/yyyy')
}

export const dayString = (dateTime?: Date | null) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('DD')
}

export const dayAndMonthString = (dateTime?: Date | null) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('DD/MM')
}

export const timeString = (dateTime?: Date) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('HH:mm')
}

export const stringToDate = (value?: string | null | undefined) => {
  if (!value) {
    return null
  }

  const dates = value.split('/')
  if (dates.length !== 3) {
    return null
  }

  try {
    const day = Number(dates[0])
    const month = Number(dates[0])
    const year = Number(dates[0])
    const date = new Date(year, month, day)
    return date
  } catch (e) {
    console.error(e)
  }
}

export const dateDescription = (dateTime?: Date | null) => {
  if (!dateTime) {
    return ''
  }

  const date = new Date(dateTime)
  date.setHours(3)

  if (diffDays(date, new Date()) === -1) {
    return 'Ontem'
  }
  if (diffDays(date, new Date()) === 0) {
    return 'Hoje'
  }
  if (diffDays(date, new Date()) === 1) {
    return 'Amanhã'
  }
  return moment(date).format('DD/MM/yyyy')
}

export const addDays = (days: number, dateTime?: Date) => {
  dateTime ??= new Date()
  const current = new Date(dateTime)
  current.setDate(current.getDate() + days)
  return current
}

export const resetTime = (dateTime?: Date) => {
  const value = new Date(dateTime ?? new Date())
  value.setHours(0)
  value.setMinutes(0)
  value.setSeconds(0)
  value.setMilliseconds(0)
  return value
}

export const lastTime = (dateTime?: Date) => {
  const value = new Date(dateTime ?? new Date())
  value.setHours(23)
  value.setMinutes(59)
  value.setSeconds(59)
  value.setMilliseconds(59)
  return value
}

export const diffDays = (dateA: Date, dateB: Date) => {
  const timeA = dateA.getTime()
  const timeB = dateB.getTime()

  const diff = timeA - timeB
  const diffInDays = Math.ceil(diff / (1000 * 3600 * 24))

  return diffInDays
}

export const isValidDateTime = (dateTime?: string | Date) => {
  if (!dateTime) {
    return false
  }

  let date: Date

  if (typeof dateTime === 'string') {
    date = new Date(dateTime)
  } else {
    date = dateTime
  }

  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (!isNaN(date.getTime())) {
      return true
    }
  }

  return false
}

export const getHours = (
  dateTime: Date | null | undefined,
  valueDefault?: number
) => {
  if (!dateTime || !isValidDateTime(dateTime)) {
    return valueDefault
  }

  try {
    return dateTime.getHours()
  } catch (e) {
    console.error(e)
  }

  return valueDefault
}

export const getMinutes = (
  dateTime: Date | null | undefined,
  valueDefault?: number
) => {
  if (!dateTime || !isValidDateTime(dateTime)) {
    return valueDefault
  }

  try {
    return dateTime.getMinutes()
  } catch (e) {
    console.error(e)
  }

  return valueDefault
}

export const getFullYear = (
  dateTime: Date | null | undefined,
  valueDefault?: number | undefined
) => {
  if (!dateTime || !isValidDateTime(dateTime)) {
    return valueDefault
  }

  try {
    return dateTime.getFullYear()
  } catch (e) {
    console.error(e)
  }

  return valueDefault
}

export const getMonth = (
  dateTime: Date | null | undefined,
  valueDefault?: number
) => {
  if (!dateTime || !isValidDateTime(dateTime)) {
    return valueDefault
  }

  try {
    return dateTime.getMonth()
  } catch (e) {
    console.error(e)
  }

  return valueDefault
}

export const getDate = (
  dateTime: Date | null | undefined,
  valueDefault?: number
) => {
  if (!dateTime || !isValidDateTime(dateTime)) {
    return valueDefault
  }

  try {
    return dateTime.getDate()
  } catch (e) {
    console.error(e)
  }

  return valueDefault
}

export const getDayOfWeek = (dateTime?: Date | null) => {
  const date = new Date(dateTime ?? new Date())
  const day = date.getDay()
  const week = daysOfWeek[day]
  return week
}

export const compareDate = (a: Date, b: Date) => {
  return (
    a.getDate() === b.getDate() &&
    a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear()
  )
}

export const betweenDate = (date: Date, startDate: Date, endDate: Date) => {
  return (
    date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()
  )
}

export const startDateMonth = (dateTime?: Date | null) => {
  const date = new Date(dateTime ?? new Date())
  date.setDate(1)
  return date
}

export const endDateMonth = (dateTime?: Date | null) => {
  let date = new Date(dateTime ?? new Date())
  date = new Date(date.setMonth(date.getMonth() + 1))
  date = startDateMonth(date)
  date.setSeconds(-1)
  return date
}

export const startDateWeekend = (dateTime?: Date | null) => {
  const date = new Date(dateTime ?? new Date())
  const end = date.getDate() - date.getDay() - 1
  let startDate = new Date(date.setDate(end))
  startDate = resetTime(startDate)
  return startDate
}

export const endDateWeekend = (dateTime?: Date | null) => {
  const date = new Date(dateTime ?? new Date())
  const end = date.getDate() - date.getDay() + 6
  let endDate = new Date(date.setDate(end))
  endDate = lastTime(endDate)
  return endDate
}
