import {
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useContext, useEffect, useRef, useState } from 'react'
import {
  Control,
  FieldErrors,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form'
import { TProduct } from '../../../../core/types/Product'
import SearchIcon from '@mui/icons-material/Search'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  TCoproducer,
  TCoproducerStatus,
} from '../../../../core/types/Coproducer'
import contractDurations from '../../../../core/data/contractDurations'
import {
  addDays,
  dateString,
  dateTimeString,
  diffDays,
} from '../../../../core/functions/dateTime'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import Validations from '../../../../core/functions/validations'
import { maskPercentage } from '../../../../masks/masks'
import NumberFunctions from '../../../../core/functions/NumberFunctions'
import ProductController from '../../../../core/controllers/ProductController'
import Convertions from '../../../../core/functions/convertions'
import SwitchRound from '../../../../components/Switch/SwitchRound'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { TUserType } from '../../../../core/types/Auth'
import { AuthContext } from '../../../../core/context/AuthContext'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
export interface IProductCoproducersProp {
  control: Control<TProduct, any>
  watch: UseFormWatch<TProduct>
  errors: FieldErrors<TProduct>
  setError: (error: string) => void
  getCoproducer: () => void
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const coproducerDefault: TCoproducer = {
  id: '',
  productId: '',
  tenantId: '',
  status: TCoproducerStatus.Pending,
  email: '',
  commissionPercentage: 0,
  contractDuration: 30,
  issuanceInvoices: false,
  createdDate: new Date(),
  productActive: true,
  productName: '',
  tenantName: '',
  isBlockedAffiliateCommission: false,
  affiliatedCommissionPercentage: 0,
}

const ProductCoproducers = ({
  control,
  watch,
  errors,
  setError,
  getCoproducer,
}: IProductCoproducersProp): JSX.Element => {
  const { fields, remove } = useFieldArray({
    control,
    name: `coproducers`,
  })

  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState<TCoproducer>({ ...coproducerDefault })
  const [openConfirmDelete, setOpenConfirmeDelete] = useState(false)
  const [openConfirmCancel, setOpenConfirmeCancel] = useState(false)
  const [search, setSearch] = useState('')
  const [coproducers, setCoproducers] = useState<TCoproducer[]>([])
  const [sending, setSending] = useState(false)
  const [contractDuration, setContractDuration] = useState(30)
  const [tabIndex, setTabIndex] = useState(0)

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const productId = watch('id')
  const coproducerIndex = useRef(-1)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const setCurrentDefault = () => {
    setCurrent({ ...coproducerDefault, productId })
    setContractDuration(30)
  }

  const handleNewCoproducer = () => {
    setCurrentDefault()
    setOpen(true)
    setTabIndex(0)
  }

  const handleCloseCoproducer = () => {
    setCurrent({ ...coproducerDefault, productId })
    setContractDuration(30)
    coproducerIndex.current = -1
    setOpen(false)
  }

  const handleSaveCoproducer = async () => {
    if (current) {
      current.contractDuration = contractDuration

      if (!current.email || current.email.trim() === '') {
        setError('É necessário preencher o Email.')
        return
      }

      if (!Validations.email(current.email)) {
        setError('Informe um email.')
        return
      }

      if (!current.contractDuration || current.contractDuration <= 0) {
        setError('É necessário escolher a duração do contrato.')
        return
      }

      current.commissionPercentage = NumberFunctions.toNumber(
        current.commissionPercentage
      )

      setSending(true)
      try {
        if (current.id === undefined || current.id === '') {
          const field = fields.find(
            (f) =>
              f.email === current.email &&
              f.status !== TCoproducerStatus.Canceled
          )
          if (field) {
            setError(
              'Já existe o mesmo co-produtor cadastrado para esse produto.'
            )
            return
          }

          const response = await ProductController.insertCoproducer({
            data: current,
          })
          if (!response.success) {
            setError(response.error)
            return
          }
        } else {
          const response = await ProductController.updateCoproducer({
            data: current,
          })
          if (!response.success) {
            setError(response.error)
            return
          }
        }
      } finally {
        setSending(false)
      }

      getCoproducer()
      handleCloseCoproducer()
    }
  }

  const handleEdit = (coproducer: TCoproducer, index: number) => {
    coproducerIndex.current = index
    const id = watch(`coproducers.${coproducerIndex.current}.id`)
    const tenantId = watch(`coproducers.${coproducerIndex.current}.tenantId`)
    coproducer.id = id
    coproducer.tenantId = tenantId
    setCurrent({ ...coproducer })
    setContractDuration(coproducer.contractDuration || 30)
    setOpen(true)
    setTabIndex(0)
  }

  const handleCancel = (coproducer: TCoproducer, index: number) => {
    coproducerIndex.current = index
    const id = watch(`coproducers.${coproducerIndex.current}.id`)
    const tenantId = watch(`coproducers.${coproducerIndex.current}.tenantId`)
    coproducer.id = id
    coproducer.tenantId = tenantId
    setCurrent({ ...coproducer })
    setContractDuration(coproducer.contractDuration || 30)
    setOpenConfirmeCancel(true)
  }

  const closeCancel = () => {
    setCurrent({ ...coproducerDefault, productId })
    coproducerIndex.current = -1
    setOpenConfirmeCancel(false)
  }

  const cancelCoproducer = async () => {
    if (!current || coproducerIndex.current <= -1) {
      return
    }

    setSending(true)
    try {
      await ProductController.cancelCoproducer({ coproducerId: current.id! })
      getCoproducer()
      setCurrent({ ...coproducerDefault, productId })
      setContractDuration(30)
      coproducerIndex.current = -1
    } finally {
      setSending(false)
    }
  }

  const handleDelete = (coproducer: TCoproducer) => {
    fields.map((f, index) => {
      if (f.email === coproducer.email) {
        coproducerIndex.current = index
        return true
      }
      return false
    })

    const id = watch(`coproducers.${coproducerIndex.current}.id`)
    const tenantId = watch(`coproducers.${coproducerIndex.current}.tenantId`)
    coproducer.id = id
    coproducer.tenantId = tenantId
    setCurrent({ ...coproducer })
    setContractDuration(coproducer.contractDuration || 30)
    setOpenConfirmeDelete(true)
  }

  const closeDelete = () => {
    setCurrent({ ...coproducerDefault, productId })
    setContractDuration(30)
    coproducerIndex.current = -1
    setOpenConfirmeDelete(false)
  }

  const deleteCoproducer = () => {
    if (!current || coproducerIndex.current <= -1) {
      return
    }
    remove(coproducerIndex.current)
    setCurrent({ ...coproducerDefault, productId })
    setContractDuration(30)
    coproducerIndex.current = -1
  }

  useEffect(() => {
    if (search !== '') {
      const filters = fields.filter(
        (f) =>
          f.tenantName?.toLowerCase().includes(search) ||
          f.email?.toLowerCase().includes(search)
      )
      setCoproducers(filters)
    } else {
      setCoproducers(fields)
    }
  }, [fields, search])

  return (
    <Box>
      <Stack spacing={2}>
        <Stack
          direction="row"
          spacing={3}
          alignContent="center"
          alignItems="center"
        >
          <FormControl fullWidth size="small">
            <InputLabel id="search-label">Pesquisar</InputLabel>
            <OutlinedInput
              fullWidth
              id="search-label"
              type="text"
              label="Pesquisar"
              onChange={(e) => setSearch(e.target.value)}
              endAdornment={<SearchIcon color="primary" />}
            />
          </FormControl>
          {!isAdmin && (
            <Button
              color="secondary"
              variant="contained"
              onClick={handleNewCoproducer}
              sx={{ width: 350 }}
            >
              Novo coprodutor
            </Button>
          )}
        </Stack>

        <Stack direction="column" spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            alignItems="center"
          >
            <Box sx={{ width: '19%' }}>
              <Typography variant="overline" fontWeight={500}>
                Criado
              </Typography>
            </Box>
            <Box sx={{ width: '19%' }}>
              <Typography variant="overline" fontWeight={500}>
                Nome
              </Typography>
            </Box>
            <Box sx={{ width: '14%' }}>
              <Typography variant="overline" fontWeight={500}>
                Comissão
              </Typography>
            </Box>
            <Box sx={{ width: '14%' }}>
              <Typography variant="overline" fontWeight={500}>
                Validade
              </Typography>
            </Box>
            <Box sx={{ width: '14%' }}>
              <Typography variant="overline" fontWeight={500}>
                Status
              </Typography>
            </Box>
            <Box sx={{ width: '14%' }}>
              <Typography variant="overline" fontWeight={500}>
                Ações
              </Typography>
            </Box>
          </Stack>
          <Divider />
          {coproducers.map((coproducer, index) => (
            <Box>
              <Stack
                key={coproducer.id}
                direction="row"
                spacing={1}
                alignContent="center"
                alignItems="center"
                sx={{ width: '100%', pb: 1 }}
              >
                <Box sx={{ width: '19%' }}>
                  <Typography variant="caption">
                    {dateTimeString(coproducer.createdDate)}
                  </Typography>
                </Box>
                <Stack direction="column" sx={{ width: '19%' }}>
                  <Typography variant="caption" fontWeight={500}>
                    {coproducer.tenantName}
                  </Typography>
                  <Typography variant="caption" noWrap>
                    {coproducer.email}
                  </Typography>
                </Stack>
                <Stack direction="column" sx={{ width: '14%' }}>
                  <Typography variant="caption" fontWeight={500}>
                    {coproducer.commissionPercentage}%
                  </Typography>
                  <Typography variant="caption">De todas as vendas</Typography>
                </Stack>
                <Box sx={{ width: '14%' }}>
                  <Typography variant="caption">
                    {coproducer.contractDuration === 999
                      ? 'Eterno'
                      : dateString(
                          addDays(
                            coproducer.contractDuration || 0,
                            coproducer.createdDate
                          )
                        )}
                  </Typography>
                </Box>
                <Box sx={{ width: '14%' }}>
                  {coproducer.status !== TCoproducerStatus.Canceled &&
                    coproducer.contractDuration !== 999 &&
                    diffDays(
                      new Date(),
                      addDays(
                        coproducer.contractDuration ?? 0,
                        coproducer.createdDate
                      )
                    ) > 0 && (
                      <Chip size="small" label="Expirado" color="warning" />
                    )}
                  {(coproducer.status === TCoproducerStatus.Canceled ||
                    coproducer.contractDuration === 999 ||
                    diffDays(
                      new Date(),
                      addDays(
                        coproducer.contractDuration ?? 0,
                        coproducer.createdDate
                      )
                    ) <= 0) && (
                    <Chip
                      size="small"
                      label={Convertions.coproducerStatusToString(
                        coproducer.status
                      )}
                      color={
                        coproducer.status === TCoproducerStatus.Confirmed
                          ? 'success'
                          : coproducer.status === TCoproducerStatus.Canceled
                            ? 'error'
                            : undefined
                      }
                    />
                  )}
                </Box>
                {coproducer.status !== TCoproducerStatus.Canceled && (
                  <Stack direction="row" sx={{ width: '14%' }}>
                    <ButtonGroup
                      variant="outlined"
                      aria-label="outlined button group"
                      size="small"
                    >
                      {!isAdmin && (
                        <Tooltip title="Editar">
                          <Button
                            size="small"
                            color="primary"
                            onClick={() => handleEdit(coproducer, index)}
                          >
                            <EditIcon />
                          </Button>
                        </Tooltip>
                      )}

                      {isAdmin && (
                        <Tooltip title="Editar">
                          <Button
                            size="small"
                            color="primary"
                            onClick={() => handleEdit(coproducer, index)}
                          >
                            <RemoveRedEyeIcon />
                          </Button>
                        </Tooltip>
                      )}

                      {!isAdmin && (
                        <Tooltip title="Cancelar">
                          <Button
                            size="small"
                            color="error"
                            onClick={() => handleCancel(coproducer, index)}
                          >
                            <CancelIcon />
                          </Button>
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </Stack>
                )}
                {coproducer.status === TCoproducerStatus.Canceled && (
                  <Stack direction="row" sx={{ width: '14%' }} />
                )}
              </Stack>
              <Divider />
            </Box>
          ))}
        </Stack>
      </Stack>

      <SwipeableDrawerRightDialog
        open={open}
        onClose={handleCloseCoproducer}
        setOpen={setOpen}
        title="Co-produção"
        subtitle="Preencha as informações do seu co-produtor."
        /*actions={
                    <Stack direction="row" spacing={1} sx={{ p: 1 }} justifyContent="end">
                        <Button variant="outlined" color="error" disabled={sending} onClick={handleCloseCoproducer}>
                            Cancelar
                        </Button>
                        <Button variant="contained" disabled={sending} onClick={handleSaveCoproducer}>
                            Salvar
                        </Button>
                    </Stack>
                }*/
        buttons={
          isAdmin
            ? []
            : [
                {
                  title: 'Cancelar',
                  onClick: handleCloseCoproducer,
                  type: 'negative',
                },
                {
                  title: 'Salvar',
                  onClick: handleSaveCoproducer,
                  type: 'positive',
                },
              ]
        }
        buttonsDisabled={sending}
      >
        <Stack direction="column" sx={{ width: '100%' }}>
          <Stack
            direction="row"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ flexGrow: 1 }}
            >
              <Tab label="Vendas do Produtor" {...a11yProps(0)} />
              <Tab label="Vendas dos Afiliados" {...a11yProps(1)} />
            </Tabs>
          </Stack>

          <TabPanel value={tabIndex} index={0}>
            <TextField
              fullWidth
              disabled={isAdmin}
              margin="normal"
              size="small"
              type="text"
              label="Email do co-produtor"
              value={current?.email}
              onChange={(e) =>
                setCurrent({ ...current, email: e.target.value })
              }
              error={errors.name?.type === 'required'}
              InputLabelProps={{
                shrink: current?.email !== '' ? true : undefined,
              }}
            />
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel
                id="contrato-label"
                shrink
                sx={{ backgroundColor: 'white' }}
              >
                Duração do contrato
              </InputLabel>
              <Select
                displayEmpty
                disabled={isAdmin}
                variant="outlined"
                labelId="contrato-label"
                label="Duração do contrato"
                value={contractDuration}
                defaultValue={30}
                onChange={(e) => {
                  const value = Number(e.target.value)
                  setCurrent({ ...current, contractDuration: value })
                  setContractDuration(value)
                }}
                renderValue={(value?: number) =>
                  contractDurations.find((cd) => cd.value === (value || 30))!
                    .label
                }
              >
                {contractDurations.map((contractDuration) => (
                  <MenuItem
                    key={contractDuration.value}
                    value={contractDuration.value}
                  >
                    {contractDuration.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel
                id="comissao-label"
                sx={{
                  backgroundColor:
                    (current?.commissionPercentage || 0) > 0
                      ? 'white'
                      : undefined,
                }}
                shrink={
                  (current?.commissionPercentage || 0) > 0 ? true : undefined
                }
              >
                Comissão
              </InputLabel>
              <OutlinedInput
                fullWidth
                disabled={isAdmin}
                id="comissao-label"
                label="Comissão"
                value={maskPercentage(current?.commissionPercentage)}
                onChange={(e) => {
                  const commissionPercentage = maskPercentage(e.target.value)
                  setCurrent({ ...current, commissionPercentage })
                }}
                endAdornment={'%'}
              />
            </FormControl>
            <Stack direction="row" alignContent="center" alignItems="center">
              <FormControlLabel
                control={
                  <SwitchRound
                    size="small"
                    disabled={isAdmin}
                    checked={current.issuanceInvoices ?? false}
                    onChange={(e) =>
                      setCurrent({
                        ...current,
                        issuanceInvoices: e.target.checked,
                      })
                    }
                    sx={{ m: 1 }}
                  />
                }
                label="Dividir responsabilidade de emissão das notas fiscais com esse co-produtor?"
              />
            </Stack>
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <FormControlLabel
              control={
                <SwitchRound
                  disabled={isAdmin}
                  size="small"
                  checked={current.isBlockedAffiliateCommission === true}
                  onChange={(e) =>
                    setCurrent({
                      ...current,
                      isBlockedAffiliateCommission: e.target.checked,
                    })
                  }
                  sx={{ m: 1 }}
                />
              }
              label="Bloquear recebimento de comissões de vendas de afiliados"
            />
            <Fade
              in={(current.isBlockedAffiliateCommission ?? false) === false}
              mountOnEnter
              unmountOnExit
            >
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                margin="normal"
                label="Comissão sobre venda dos afiliados"
                value={maskPercentage(current.affiliatedCommissionPercentage)}
                onChange={(e) => {
                  const affiliatedCommissionPercentage =
                    NumberFunctions.toNumber(maskPercentage(e.target.value))
                  setCurrent({ ...current, affiliatedCommissionPercentage })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography>%</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Fade>
          </TabPanel>
        </Stack>
      </SwipeableDrawerRightDialog>

      <ConfirmDialog
        open={openConfirmDelete}
        title="Confirmar exclusão"
        message="Deseja realmente deletar o co-produtor?"
        onClose={closeDelete}
        onYes={deleteCoproducer}
      />

      <ConfirmDialog
        open={openConfirmCancel}
        title="Confirmar cancelamento"
        message="Deseja realmente cancelar o co-produtor?"
        onClose={closeCancel}
        onYes={cancelCoproducer}
      />
    </Box>
  )
}

export default ProductCoproducers
