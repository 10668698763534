import { Typography } from '@mui/material'

interface IListItemTitleTypographyProps {
  children: React.ReactNode
  noWrap?: boolean
  align?: 'left' | 'center' | 'right' | undefined
}

const ListItemTitleTypography = ({
  children,
  noWrap,
  align = 'left',
}: IListItemTitleTypographyProps) => {
  return (
    <Typography
      color="#707275"
      fontWeight={600}
      fontSize="14px"
      lineHeight="18px"
      noWrap={noWrap}
      textAlign={align}
    >
      {children}
    </Typography>
  )
}

export default ListItemTitleTypography
