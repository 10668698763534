import { Button, Stack } from '@mui/material'
import SwipeableDrawerRightDialog from '../../../../../components/Dialogs/SwipeableDrawerRightDialog'
import ProductLinkRegister from './ProductLinkRegister'
import { TProductLink } from '../../../../../core/types/Product'
import { TUserType } from '../../../../../core/types/Auth'
import { AuthContext } from '../../../../../core/context/AuthContext'
import { useContext } from 'react'

export interface IProductLinkDialogProps {
  link: TProductLink
  open: boolean
  sending: boolean
  errorIdentifier?: string
  errorUrl?: string
  setLink: (value: TProductLink) => void
  setOpen: (value: boolean) => void
  onSave: () => Promise<void>
}

const ProductLinkDialog = ({
  link,
  open,
  sending,
  errorIdentifier,
  errorUrl,
  setLink,
  setOpen,
  onSave,
}: IProductLinkDialogProps) => {
  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={() => setOpen(false)}
      setOpen={(value) => setOpen(value)}
      title="Link de divulgação"
      subtitle="Links para divulgação"
      /*actions={
                <Stack direction="row" spacing={1} sx={{ p: 1 }} justifyContent="end">
                    <Button
                        variant="outlined"
                        color="error"
                        disabled={sending}
                        onClick={() => setOpen(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        disabled={sending}
                        onClick={onSave}
                    >
                        Salvar
                    </Button>
                </Stack>
            }*/
      buttons={
        isAdmin
          ? []
          : [
              {
                title: 'Cancelar',
                onClick: () => setOpen(false),
                type: 'negative',
              },
              { title: 'Salvar', onClick: onSave, type: 'positive' },
            ]
      }
      buttonsDisabled={sending}
    >
      <ProductLinkRegister
        key={link.id}
        embedded={false}
        link={link}
        errorIdentifier={errorIdentifier}
        errorUrl={errorUrl}
        setLink={setLink}
      />
    </SwipeableDrawerRightDialog>
  )
}

export default ProductLinkDialog
