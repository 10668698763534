import { IDataTableColumn } from '../../../../components/Tables/DataTable'
import { TCheckoutOrder } from '../../../../core/types/CheckoutOrder'
import {
  CheckoutOrderCustomerWhatsAppColumn,
  checkoutOrderPaymentAndCommissionColumns,
  checkoutOrderStatusColumn,
} from '../CheckoutOrderConsts'
import {
  checkoutOrderColumnsDefault,
  checkoutOrderCreatedDateColumn,
} from './CheckoutOrderColumnsDefault'

export function checkoutOrderColumnsApproved({
  execute,
}: {
  execute: () => void
}) {
  const columns: IDataTableColumn<TCheckoutOrder>[] = [
    { ...checkoutOrderCreatedDateColumn },
    ...checkoutOrderColumnsDefault,
    checkoutOrderStatusColumn,
    ...checkoutOrderPaymentAndCommissionColumns,
    CheckoutOrderCustomerWhatsAppColumn({ execute }),
  ]

  return columns
}
