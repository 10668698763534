import {
  Select,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
} from '@mui/material'

export interface IPMFilterProps {
  handlePaymentMethodFilter: (values: string[]) => void
  selectds: string[]
  setSelectds: (value: React.SetStateAction<string[]>) => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const options = ['Cartão de Crédito', 'PIX', 'Boleto']

export const PaymentMethodsFilter = ({
  handlePaymentMethodFilter,
  selectds,
  setSelectds,
}: IPMFilterProps) => {
  const handleChange = (event: SelectChangeEvent<typeof selectds>) => {
    const {
      target: { value },
    } = event

    const values = typeof value === 'string' ? value.split(',') : value
    setSelectds(values)
    if (values.length === 0) handlePaymentMethodFilter([])
    else handlePaymentMethodFilter(values.map((x) => convertToPaymentType(x)))
  }

  const convertToPaymentType = (value: string): string => {
    if (value === 'Cartão de Crédito') return 'credit_card'
    return value.toLowerCase()
  }

  return (
    <Stack direction="row" alignContent="center" alignItems="center">
      <FormControl fullWidth>
        <InputLabel id="pm-multiple-checkbox-label">
          Forma de Pagamento
        </InputLabel>
        <Select
          labelId="pm-multiple-checkbox-label"
          id="pm-multiple-checkbox"
          multiple
          value={selectds}
          onChange={handleChange}
          input={<OutlinedInput label="Forma de Pagamento" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {options
            .sort((a, b) => (a > b ? 1 : -1))
            .map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={selectds.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Stack>
  )
}
