import {
  Box,
  Button,
  CircularProgress,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import React, { Fragment, useContext } from 'react'
import { TUserType } from '../../core/types/Auth'
import { AuthContext } from '../../core/context/AuthContext'

export interface IImagePlaceholderProps {
  imageUrl?: string
  alt: string
  sugestion?: string
  style?: React.CSSProperties
  optionsStyle?: SxProps<Theme>
  onChange: (file: File | undefined) => void
  onError: (error: string) => void
}

const ImagePlaceholder = ({
  imageUrl,
  alt,
  style,
  optionsStyle,
  onChange,
  onError,
}: IImagePlaceholderProps) => {
  const [loaded, setLoaded] = React.useState(false)
  const [selectedFile, setSelectedFile] = React.useState<File | undefined>()
  const [preview, setPreview] = React.useState<string | undefined>()
  const [viewOptions, setViewOptions] = React.useState(false)

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const handleImageLoaded = () => {
    setLoaded(true)
  }

  const imageStyle = !loaded ? { display: 'none' } : { ...style }

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      onChange(undefined)
      return
    }

    const file = e.target.files[0]

    if (file.size >= 1056895) {
      onError('A imagem precisa ter um tamanho menor que 1 MB')
      return
    }

    setSelectedFile(file)
    onChange(file)
  }

  const onRemoveFile = () => {
    setSelectedFile(undefined)
    onChange(undefined)
  }

  React.useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  if ((imageUrl ?? '') === '' && !preview) {
    return (
      <Stack display="flex" direction={{ xs: 'row', md: 'column' }} gap="12px">
        {selectedFile && (
          <img
            src={preview}
            alt={alt}
            style={imageStyle}
            onLoad={handleImageLoaded}
          />
        )}
        {!selectedFile && <div style={{ ...style }}></div>}
        <Stack
          alignContent={{ xs: 'start', md: 'center' }}
          alignItems={{ xs: 'start', md: 'center' }}
          direction={{ xs: 'column-reverse', md: 'column' }}
          justifyContent={{ xs: 'flex-end', md: 'center' }}
          gap="10px"
          sx={{
            width: '100%',
            maxWidth: '200px',
            ...optionsStyle,
          }}
          onMouseEnter={() => setViewOptions(true)}
          onMouseLeave={() => setViewOptions(false)}
        >
          <Box>
            {!isAdmin && (
              <Button
                variant="text"
                component="label"
                sx={{
                  textTransform: 'none',
                  color: '#EC5040',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: '500',
                }}
              >
                Selecione uma imagem
                <input
                  type="file"
                  onChange={onSelectFile}
                  hidden
                  accept="image/*"
                />
              </Button>
            )}
          </Box>
          <Stack
            sx={{
              backgroundColor: '#FBF3E2',
              borderRadius: '8px',
              padding: '8px',
            }}
          >
            <Typography
              sx={{
                color: '#887100',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '16px',
                alignItems: 'center',
              }}
            >
              Recomendado: 1 MB de tamanho. Dimensões ideais: 600x600 pixels.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack display="flex" direction={{ xs: 'row', md: 'column' }} gap="12px">
      {!loaded && (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ height: { xs: 200, md: 400 } }}
        >
          <CircularProgress />
        </Stack>
      )}
      {selectedFile && (
        <img
          src={preview}
          alt={alt}
          style={imageStyle}
          onLoad={handleImageLoaded}
          onMouseEnter={() => setViewOptions(true)}
          onMouseLeave={() => setViewOptions(false)}
        />
      )}
      {!selectedFile && (
        <img
          src={imageUrl}
          alt={alt}
          style={imageStyle}
          onLoad={handleImageLoaded}
          onMouseEnter={() => setViewOptions(true)}
          onMouseLeave={() => setViewOptions(false)}
        />
      )}

      <Stack
        direction={{ xs: 'column-reverse', md: 'column' }}
        alignContent={{ xs: 'start', md: 'center' }}
        alignItems={{ xs: 'start', md: 'center' }}
        justifyContent={{ xs: 'flex-end', md: 'center' }}
        gap="10px"
        sx={{
          width: '100%',
          maxWidth: '200px',
          ...optionsStyle,
        }}
        onMouseEnter={() => setViewOptions(true)}
        onMouseLeave={() => setViewOptions(false)}
      >
        <Box>
          {!isAdmin && (
            <Button
              variant="text"
              component="label"
              sx={{
                textTransform: 'none',
                color: '#EC5040',
                fontSize: '12px',
                lineHeight: '16px',
                fontWeight: '500',
              }}
            >
              Substituir imagem
              <input
                type="file"
                onChange={onSelectFile}
                hidden
                accept="image/*"
              />
            </Button>
          )}
          {/* <Button
            variant="text"
            component="label"
            sx={{
              textTransform: 'none',
              color: '#EC5040',
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: '500',
            }}
            onClick={onRemoveFile}
          >
            Remover imagem
          </Button> */}
        </Box>
        <Stack
          sx={{
            backgroundColor: '#FBF3E2',
            borderRadius: '8px',
            padding: '8px',
            maxWidth: '200px',
          }}
        >
          <Typography
            sx={{
              color: '#887100',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '16px',
              alignItems: 'center',
            }}
          >
            Recomendado: 1 MB de tamanho. Dimensões ideais: 600x600 pixels.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ImagePlaceholder
