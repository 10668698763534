import { useEffect, useState } from "react";
import { IErrorResponse } from "../types/ErrorResponse";
import { TAffiliatedSimple } from "../types/Affiliated";
import AffiliationController from "../controllers/AffiliationController";

export interface IAffiliatedsSimpleProp {
	initialExecute: boolean;
}

export const useAffiliatedsSimple = ({ initialExecute }: IAffiliatedsSimpleProp) => {    
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<TAffiliatedSimple[]>([]);
	const [error, setError] = useState<string>('');

	const execute = async () => {
		if (!loading) {
			try {
				setLoading(true);

				if (error !== '') {
					setError('');
				}

				const response = await AffiliationController.getSimpleAffiliatedsList();
				const responseError = response as IErrorResponse;
				const responseData = response as TAffiliatedSimple[];

				if (responseError.code) {
					setError(responseError.error);
					setData([]);
				} else {
					setData(responseData);
				}
			} catch (e) {
				var message = 'Erro não identificado';
				if (typeof e === "string") {
					message = e.toUpperCase();
				} else if (e instanceof Error) {
					const err = e as Error;
					message = err.message;
				}
				setError(message);
			} finally {
				setLoading(false);
			}
		}
	}

	useEffect(() => {
		execute();
	}, [initialExecute]);

	const clear = () => {
		setError('');
	}

	return {
		loading,
		data,
		error,
		execute,
		clear,
	};
}