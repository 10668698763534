import { numberOnly } from '../../masks/masks'
import api, { getResponseError } from '../../services/api'
import { IErrorResponse, IResponse } from '../types/ErrorResponse'
import {
  ITenant,
  ITenantBalance,
  ITenantBankAccount,
  TPersonType,
  TSalesAnalysisByProducer,
  TSalesAnalysisByProducerPagination,
  TSignUpTenant,
  TTenantAnticipationType,
  TTenantRegister,
  TTenantSettings,
  TTenantSettingsPagination,
} from '../types/Tenant'
import { TVoxuySettings } from '../types/TenantVoxuySettings'

interface ITTenantSettings {
  page: number
  rows: number
  search?: string
}

const getSettings = async ({
  id,
}: {
  id: string
}): Promise<TTenantSettings | IErrorResponse> => {
  try {
    var path = `/Tenant/${id}/settings`

    const { data } = await api.get<TTenantSettings>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getRecipientSettings = async ({
  id,
}: {
  id: string
}): Promise<TTenantSettings | IErrorResponse> => {
  try {
    var path = `/Tenant/${id}/recipient/settings`

    const { data } = await api.get<TTenantSettings>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getSettingsAll = async ({
  page,
  rows,
  search,
}: ITTenantSettings): Promise<TTenantSettingsPagination | IErrorResponse> => {
  try {
    var path = `/Tenant/settings?PageNumber=${page}&RowsPerPage=${rows}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    const { data } = await api.get<TTenantSettingsPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getByCurrentUser = async (): Promise<ITenant | IErrorResponse> => {
  try {
    const { data } = await api.get<ITenant>('/Tenant/currentUser')
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getByTenantRecipient = async (): Promise<
  TTenantRegister | IErrorResponse
> => {
  try {
    const { data } = await api.get<TTenantRegister>('/Tenant/recipient')
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getAll = async (): Promise<ITenant[] | IErrorResponse> => {
  try {
    const { data } = await api.get<ITenant[]>('/Tenant')
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

interface ISalesAnalysisPagProp {
  page: number
  rows: number
  search?: string
}

const getSalesAnalysisPag = async ({
  page,
  rows,
  search,
}: ISalesAnalysisPagProp): Promise<
  TSalesAnalysisByProducerPagination | IErrorResponse
> => {
  try {
    let path = `/Report/SalesAnalysis?PageNumber=${page}&RowsPerPage=${rows}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    const { data } = await api.get<TSalesAnalysisByProducerPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const update = async ({
  data,
}: {
  data: TTenantRegister
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.put(
      '/Tenant',
      getDataTenantRecipient(data)
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    console.log('error', error)
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const insert = async ({ data }: { data: ITenant }): Promise<IResponse> => {
  try {
    const { data: response } = await api.post('/Tenant', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const insertUser = async ({
  data,
}: {
  data: TSignUpTenant
}): Promise<IResponse> => {
  try {
    const json = {
      name: data.name,
      memberServiceToken: ' ',
      memberServiceApiKey: ' ',
      memberServiceDomain: ' ',
      urlRedirectSuccess: process.env.REACT_APP_SITE_HOST,
      email: data.email,
      userAdmin: {
        name: data.name,
        email: data.email.toLowerCase(),
        password: data.password,
      },
      indicatedOf: data.indicatedOf,
    }
    const { data: response } = await api.post('/Tenant', json)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const updateSettings = async ({
  data,
}: {
  data: TTenantSettings
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.put('/Tenant/settings', data)
    if (response) {
      await updateAnticipation({ data })
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const setAnticipation = async ({
  data,
}: {
  data: { tenantId: string; anticipationType: TTenantAnticipationType }
}): Promise<IResponse> => {
  try {
    await api.put('/Tenant/settings/anticipation/active', data)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const insertSettings = async ({
  data,
}: {
  data: TTenantSettings
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.post('/Tenant/settings', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const getByBankInfoByTenantId = async (
  tenantId: string
): Promise<ITenantBankAccount | IErrorResponse> => {
  try {
    const { data } = await api.get<ITenantBankAccount>(
      '/Tenant/bank/' + tenantId
    )
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getSettingsByTenantId = async (
  tenantId: string
): Promise<TTenantSettings | IErrorResponse> => {
  try {
    const { data } = await api.get<TTenantSettings>(
      `/Tenant/settings/${tenantId}/anticipation`
    )
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const updateAnticipation = async ({
  data,
}: {
  data: TTenantSettings
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.put(
      '/Tenant/settings/anticipation',
      data
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const getDataTenantRecipient = (data: TTenantRegister) => {
  data.document = numberOnly(data.document)
  data.phone = numberOnly(data.phone)
  if (data.address) {
    data.address.zipCode = numberOnly(data.address.zipCode)
  }

  if (data.personType === TPersonType.Corporation) {
    if (data.mainAddress) {
      data.mainAddress.zipCode = numberOnly(data.mainAddress.zipCode)

      if (!data.address || data.address.street === '') {
        data.address = data.mainAddress
      }
    } else {
      data.mainAddress = data.address
    }
  } else {
    data.mainAddress = undefined
    data.managingPartner = undefined
  }

  if (data.managingPartner) {
    data.managingPartner.document = numberOnly(data.managingPartner.document)
    data.managingPartner.phone = numberOnly(data.managingPartner.phone)

    if (data.managingPartner.address) {
      data.managingPartner.address.zipCode = numberOnly(
        data.managingPartner.address.zipCode
      )
    }
  }

  return data
}

const updateBank = async ({
  data,
}: {
  data: TTenantRegister
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.put('/Tenant/bank', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const insertBank = async ({
  data,
}: {
  data: TTenantRegister
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.post(
      '/Tenant/bank',
      getDataTenantRecipient(data)
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const getCurrentBalance = async (): Promise<
  ITenantBalance | IErrorResponse
> => {
  try {
    const { data } = await api.get<ITenantBalance>('/Tenant/withdraws')
    if (data) {
      if (
        (data.anticipationType ?? TTenantAnticipationType.None) ===
        TTenantAnticipationType.None
      ) {
        data.anticipationType = TTenantAnticipationType.D30
      }
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const withdrawRequest = async (amount: number): Promise<IResponse> => {
  try {
    const { data: response } = await api.post('/Tenant/withdraws', { amount })
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const registerVoxuySettings = async ({
  data,
}: {
  data: TVoxuySettings
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.put('/Tenant/settings/voxuy', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const registerGoogleTagManagerSettings = async ({
  data,
}: {
  data: { tenantId: string; googleTagManagerId: string }
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.put('/Tenant/settings/gtm', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const getVoxuySettings = async (): Promise<TVoxuySettings | IErrorResponse> => {
  try {
    const { data } = await api.get<TVoxuySettings>('/Tenant/settings/voxuy')
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const salesEnabled = async ({
  tenantId,
  enabled,
}: {
  tenantId: string
  enabled: boolean
}): Promise<IResponse> => {
  try {
    const { status } = await api.put(
      `/Tenant/settings/${tenantId}/salesEnabled`,
      { enabled }
    )
    if (status >= 200 && status <= 299) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    console.log('error', error)
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const withdrawlsEnabled = async ({
  tenantId,
  enabled,
}: {
  tenantId: string
  enabled: boolean
}): Promise<IResponse> => {
  try {
    const { status } = await api.put(
      `/Tenant/settings/${tenantId}/withdrawlsEnabled`,
      { enabled }
    )
    if (status >= 200 && status <= 299) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    console.log('error', error)
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

export const TenantController = {
  getSettings,
  getRecipientSettings,
  getSettingsAll,
  getByCurrentUser,
  getByTenantRecipient,
  getAll,
  insert,
  update,
  getByBankInfoByTenantId,
  insertBank,
  updateBank,
  getCurrentBalance,
  withdrawRequest,
  insertUser,
  insertSettings,
  updateSettings,
  getVoxuySettings,
  registerVoxuySettings,
  getSettingsByTenantId,
  setAnticipation,
  registerGoogleTagManagerSettings,
  //insertTenantRecipientBank,
  getSalesAnalysisPag,
  salesEnabled,
  withdrawlsEnabled,
}
