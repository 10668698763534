import React from 'react'
import { IErrorResponse } from '../types/ErrorResponse'
import { TProductSimple } from '../types/Product'
import ProductController from '../controllers/ProductController'

export interface IProductsSimpleProp {
  initialExecute: boolean
  includeAffiliatedProducts?: boolean
}

export const useProductsSimple = ({
  initialExecute,
  includeAffiliatedProducts,
}: IProductsSimpleProp) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<TProductSimple[]>([])
  const [error, setError] = React.useState<string>('')

  const execute = async () => {
    if (!loading) {
      try {
        setLoading(true)

        if (error !== '') {
          setError('')
        }

        const response = await ProductController.getList({
          includeAffiliatedProducts,
        })
        const responseError = response as IErrorResponse
        const responseData = response as TProductSimple[]

        if (responseError.code) {
          setError(responseError.error)
          setData([])
        } else {
          setData(responseData)
        }
      } catch (e) {
        var message = 'Erro não identificado'
        if (typeof e === 'string') {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        setError(message)
      } finally {
        setLoading(false)
      }
    }
  }

  React.useEffect(() => {
    execute()
  }, [initialExecute])

  const clear = () => {
    setError('')
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  }
}
