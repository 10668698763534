import { TProductFrequency, TProductTypePayment } from "../types/Product";

const maxPaymentInstallment = (typePayment?: TProductTypePayment, frequency?: TProductFrequency, productValue?: number) => {
    var total = 1;
    if (typePayment === TProductTypePayment.RecurringSubscription) {
        switch (frequency) {
            case TProductFrequency.Bimonthly:
                total = 2;
                break;
            case TProductFrequency.Quarterly:
                total = 3;
                break;
            case TProductFrequency.Semester:
                total = 6;
                break;
            case TProductFrequency.Yearly:
                total = 12;
        }
    } else {
        const quantity = (productValue || 0) / 5;
        total = quantity > 12 ? 12 : quantity;
    }

    return total;
}

const ProductFunctions = {
    maxPaymentInstallment,
}

export default ProductFunctions;