import { Box, Button, Stack } from '@mui/material'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'
import FilterAbandonedLastDays from '../Abandoned/FilterAbandonedLastDays'

export interface IAbandonedAdvancedFiltersProps {
  open: boolean
  lastDays: number
  setOpen: (value: boolean) => void
  handleCloseFilter: () => void
  setLastDays: (value: number) => void
}

export const AbandonedAdvancedFilters = ({
  open,
  lastDays,
  setOpen,
  handleCloseFilter,
  setLastDays,
}: IAbandonedAdvancedFiltersProps) => {
  const handleCleanFilters = () => {
    setLastDays(30)
  }

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={handleCloseFilter}
      setOpen={setOpen}
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <Box>Filtros avançados</Box>
          <Button
            variant="text"
            size="small"
            onClick={handleCleanFilters}
            sx={{ textTransform: 'none' }}
          >
            Limpar Filtros
          </Button>
        </Stack>
      }
    >
      <Stack direction="column" spacing={2} sx={{ width: '100%', pt: 1 }}>
        <FilterAbandonedLastDays
          lastDays={lastDays}
          setLastDays={setLastDays}
        />
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}
