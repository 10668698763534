import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  Slide,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useProductsSimple } from '../../../core/hooks/useProductsSimple'
import { TProductPrice } from '../../../core/types/Product'
import ProductController from '../../../core/controllers/ProductController'
import { IErrorResponse } from '../../../core/types/ErrorResponse'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import { TSalesFunnel } from '../../../core/types/SalesFunnel'
import SalesFunnelController from '../../../core/controllers/SalesFunnelController'
import { useSaleFunnel } from '../../../core/hooks/useSaleFunnel'
import { PriceMultiselect } from '../../../components/Product/PriceMultiselect'

const SalesFunnelNewEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const newItem = id === 'new'

  const [animationState, setAnimationState] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState('')
  const [productPrices, setProductPrices] = React.useState<TProductPrice[]>([])
  const [multSelectError, setMultSelectError] = React.useState('')

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<TSalesFunnel>({
    defaultValues: {
      id: newItem ? undefined : id,
      productId: '',
      productPriceIds: [],
      name: '',
      productName: '',
      isAllOffers: false,
      paymentMethod: 1,
    },
  })

  const navigate = useNavigate()
  const productId = watch('productId')
  const isAllOffers = watch('isAllOffers')

  const { data, loading } = useSaleFunnel({ id: id || '' })
  const { data: products, loading: loadingProducts } = useProductsSimple({
    initialExecute: true,
  })

  const getPricesByProduct = async () => {
    if (productId && productId !== null && productId !== '') {
      setSending(true)
      try {
        const response = await ProductController.getPricesByProduct({
          productId,
        })
        const err = response as IErrorResponse

        if (err.code) {
          setError(err.error)
        } else {
          setProductPrices(response as TProductPrice[])
        }
      } finally {
        setSending(false)
      }
    }
  }

  React.useEffect(() => {
    setAnimationState(true)
    return () => setAnimationState(false)
  }, [])

  React.useEffect(() => {
    if (data !== null) {
      reset(data)
    }
    return () => {
      if (data !== null) {
        reset(data)
      }
    }
  }, [data, reset])

  React.useEffect(() => {
    getPricesByProduct()
    return () => {}
  }, [productId])

  const handleSwitchChange = async (event: any) => {
    const isChecked = event.target.checked
    const resp =
      await SalesFunnelController.checkOtherSalesFunnelPriceConfigured({
        id: productId,
        salesFunneId: id,
      })
    if (resp === false) {
      setValue('isAllOffers', false)
      setError('Oferta usada em outro funil')
    } else {
      setValue('isAllOffers', isChecked)
    }
  }

  const Submit = async (data: any) => {
    setSending(true)
    setSuccess(false)
    setError('')

    if (isAllOffers) {
      data.productPriceIds = []
    }

    if (!isAllOffers && data.productPriceIds.length === 0) {
      setMultSelectError('Selecione ao menos uma oferta')
      setSending(false)
      return
    } else {
      setMultSelectError('')
    }

    try {
      var response
      if (newItem) {
        response = await SalesFunnelController.insert({ data })
      } else {
        response = await SalesFunnelController.update({ data })
      }
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
      }
    } finally {
      setSending(false)
    }
  }

  return (
    <Box>
      <Box sx={{ width: '100%', margin: '0 auto' }}>
        <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
          <Box>
            <Box
              component={Paper}
              pt={2}
              pb={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flexBasis: 1,
                alignItems: 'center',
                margin: '0 auto',
                width: { xs: '90%', sm: '70%' },
              }}
              width="90%"
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'column' }}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Typography variant="h5">
                  {newItem ? 'Novo ' : 'Editar '}funil de venda
                </Typography>
              </Box>
              <Stack
                component={'form'}
                noValidate
                direction={'column'}
                pt={2}
                spacing={3}
                sx={{ width: '80%' }}
                onSubmit={handleSubmit(Submit)}
              >
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    size="small"
                    options={products}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, option) => {
                      if (option && option !== null) {
                        setValue('productId', option.id)
                        setValue('productName', option.name)
                        setValue('productPriceIds', [])
                      }
                    }}
                    value={{
                      id: watch('productId'),
                      name: watch('productName'),
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Produto"
                        {...register('productName')}
                      />
                    )}
                  />

                  <FormControl fullWidth size="small">
                    <PriceMultiselect
                      hasTitle={false}
                      prices={productPrices}
                      disabled={productPrices.length <= 0 || isAllOffers}
                      selecteds={watch('productPriceIds')}
                      error={multSelectError}
                      setSelecteds={(values) =>
                        setValue('productPriceIds', values)
                      }
                    />
                  </FormControl>
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ width: { xs: '100%', sm: '49%' } }}
                  >
                    <InputLabel id="payment-method-label">
                      Método de Pagamento
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="payment-method-label"
                      label="Método de Pagamento"
                      value={watch('paymentMethod')}
                      {...register('paymentMethod')}
                      error={errors.paymentMethod?.type === 'required'}
                    >
                      <MenuItem value={1}>C. Crédito</MenuItem>
                      <MenuItem value={2}>PIX</MenuItem>
                      <MenuItem value={3}>AMBOS</MenuItem>
                    </Select>
                    {errors.paymentMethod?.type === 'required' && (
                      <Typography variant="overline" color={'red'}>
                        Selecione um método de pagamento
                      </Typography>
                    )}
                  </FormControl>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={watch('isAllOffers')}
                        // onChange={(e) => setAllOffers(e.target.checked)}
                        onChange={(e) => handleSwitchChange(e)}
                        color="primary"
                      />
                    }
                    {...register('isAllOffers')}
                    label="Todas as Ofertas"
                  />
                </Stack>

                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    label="Nome"
                    InputLabelProps={{
                      shrink: watch('name') !== '',
                    }}
                    {...register('name', { required: true })}
                    error={errors.name?.type === 'required'}
                  />
                  {errors.name?.type === 'required' && (
                    <Typography variant="overline" color={'red'}>
                      Título é um campo obrigatório
                    </Typography>
                  )}
                </Box>

                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  Salvar
                </Button>
              </Stack>
            </Box>
          </Box>
        </Slide>
      </Box>

      <LoadingBackdrop open={loading || sending || loadingProducts} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar
        open={success}
        onClose={() => {
          setSuccess(false)
          navigate('/sales/funnel')
        }}
      >
        Alterado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

export default SalesFunnelNewEditPage
