import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { TTenantRegister } from '../../../core/types/Tenant'
import BasicDatePicker from '../../../components/DateTime/BasicDatePicker'
import { TenantController } from '../../../core/controllers/TenantController'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import {
  maskCepNumber,
  maskCpfCnpjNumber,
  maskPhoneNumber,
  numberOnly,
} from '../../../masks/masks'
import CurrencyField from '../../../components/Inputs/CurrencyField'
import CepController from '../../../core/controllers/CepController'
import states from '../../../core/data/states'
import { isValidDateTime } from '../../../core/functions/dateTime'

interface TenantIndividualProps {
  data: TTenantRegister
  loading: boolean
  execute: () => void
}

const TenantIndividual: React.FC<TenantIndividualProps> = ({
  data,
  loading,
  execute,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError: setErrorForm,
    clearErrors,
  } = useForm({
    defaultValues: data,
  })
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [loadingZipCode, setLoadingZipCode] = useState(false)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const documentValue = watch('document')
  const zipcodeValue = watch('address.zipCode')
  const phoneValue = watch('phone')

  const isValidMoney = (money: any) => {
    var isValid = true
    if (!money || money === 0) {
      isValid = false
      setErrorForm('revenue', {
        message: 'Não pode ser 0',
        type: 'required',
      })
      setSending(false)
    } else if (money > 9999999.99) {
      isValid = false
      setErrorForm('revenue', {
        message: 'O valor não pode ser maior que R$ 9.999.999,99',
        type: 'required',
      })
      setSending(false)
    }

    return isValid
  }

  const isValidRegister = (data: TTenantRegister) => {
    let isValid = true

    if (!data.birthDate) {
      isValid = false
      setErrorForm('birthDate', {
        message: 'Campo obrigatório',
        type: 'required',
      })
    } else if (!isValidDateTime(data.birthDate)) {
      isValid = false
      setErrorForm('birthDate', {
        message: 'Data inválida',
        type: 'invalid',
      })
    }

    return isValid
  }

  const searchZipCode = useCallback(
    async (load: boolean, zipcode?: string) => {
      if (!zipcode || load) {
        return
      }
      const code = numberOnly(zipcode)
      if (code.length < 8) {
        return
      }
      setLoadingZipCode(true)
      try {
        const cep = await CepController.search(code)
        if (cep) {
          setValue('address.street', cep.logradouro)
          setValue('address.complement', cep.complemento)
          setValue('address.neighborhood', cep.bairro)
          setValue('address.city', cep.localidade)
          setValue('address.state', cep.uf)
        } else {
        }
      } finally {
        setLoadingZipCode(false)
      }
    },
    [numberOnly, setLoadingZipCode, setValue]
  )

  const onSubmit = async (formData: any) => {
    if (!isValidRegister(formData)) {
      return
    }

    setSending(true)
    setSuccess(false)
    setError('')

    if (isValidMoney(formData.revenue)) {
      await TenantController.update({ data: formData })
        .then((data) => {
          if (data.success) {
            setSuccess(true)
            execute()
          } else {
            setError('Erro ao alterar dados')
          }
        })
        .finally(() => setSending(false))
    } else {
      setSending(false)
    }
  }

  useEffect(
    () => setValue('document', maskCpfCnpjNumber(documentValue)),
    [documentValue, setValue]
  )

  useEffect(
    () => setValue('address.zipCode', maskCepNumber(zipcodeValue)),
    [zipcodeValue, setValue]
  )
  useEffect(
    () => setValue('phone', maskPhoneNumber(phoneValue)),
    [phoneValue, setValue]
  )

  return (
    <Box
      component="form"
      noValidate
      pt={2}
      sx={{ width: '100%' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3}>
        <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            label="Nome"
            type="text"
            InputLabelProps={{ shrink: true }}
            {...register('name')}
            disabled
          />
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            label="Documento"
            type="text"
            disabled
            InputLabelProps={{ shrink: true }}
            {...register('document')}
          />
        </Stack>

        <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            label="Email"
            type="email"
            disabled
            InputLabelProps={{ shrink: true }}
            {...register('email')}
          />

          <BasicDatePicker
            fullWidth
            label="Data de Nascimento"
            value={watch('birthDate')}
            disabled
            setValue={(e) => {
              if (e) {
                setValue('birthDate', e)
              }
            }}
          />

          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            label="Telefone"
            type="text"
            InputLabelProps={{ shrink: true }}
            {...register('phone', {
              required: 'Telefone é obrigatório',
              pattern: {
                value: /^\(?\d{2}\)?[\s-]?\d{4,5}-?\d{4}$/,
                message: 'Telefone inválido',
              },
            })}
            error={!!errors.phone}
            helperText={errors.phone ? errors.phone.message : ''}
          />
        </Stack>

        <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            label="Ocupação Profissional"
            type="text"
            InputLabelProps={{ shrink: true }}
            {...register('professionalOccupation', {
              required: 'Ocupação Profissional é obrigatória',
            })}
            error={!!errors.professionalOccupation}
            helperText={
              errors.professionalOccupation
                ? errors.professionalOccupation.message
                : ''
            }
          />
          <CurrencyField
            label="Renda Mensal"
            value={watch('revenue') ?? 0}
            onChange={(value) => {
              setValue('revenue', value)
              clearErrors('revenue')
            }}
            error={
              (errors.revenue?.type ?? '') !== '' &&
              (errors.revenue?.message ?? '') !== ''
            }
            helperText={
              errors.revenue?.type &&
              errors.revenue.message && (
                <Typography
                  sx={{ fontSize: 11 }}
                  variant="inherit"
                  color={'red'}
                >
                  {errors.revenue?.message}
                </Typography>
              )
            }
          />
        </Stack>

        <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            label="CEP"
            type="text"
            placeholder="00000-000"
            inputProps={{
              inputMode: 'numeric',
              maxLength: 9,
            }}
            InputProps={{
              endAdornment: (
                <IconButton
                  size="small"
                  onClick={() => searchZipCode(loadingZipCode, zipcodeValue)}
                >
                  <img alt="Search" src="/assets/icons/search/search.svg" />
                </IconButton>
              ),
            }}
            {...register('address.zipCode', {
              required: 'CEP é obrigatório',
              pattern: {
                value: /^\d{5}-?\d{3}$/,
                message: 'CEP inválido',
              },
            })}
            error={!!errors.address?.zipCode}
            helperText={
              errors.address?.zipCode ? errors.address.zipCode.message : ''
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            label="Endereço"
            type="text"
            InputLabelProps={{ shrink: true }}
            {...register('address.street', {
              required: 'Endereço é obrigatório',
            })}
            error={!!errors.address?.street}
            helperText={
              errors.address?.street ? errors.address.street.message : ''
            }
          />
        </Stack>
        <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            label="Número"
            type="text"
            InputLabelProps={{ shrink: true }}
            {...register('address.number', {
              required: 'Número é obrigatório',
            })}
            error={!!errors.address?.number}
            helperText={
              errors.address?.number ? errors.address.number.message : ''
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            label="Complemento"
            type="text"
            InputLabelProps={{ shrink: true }}
            {...register('address.complement', {
              required: 'Complemento é obrigatório',
            })}
            error={!!errors.address?.complement}
            helperText={
              errors.address?.complement
                ? errors.address.complement.message
                : ''
            }
          />
        </Stack>
        <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            label="Bairro"
            type="text"
            InputLabelProps={{ shrink: true }}
            {...register('address.neighborhood', {
              required: 'Bairro é obrigatório',
            })}
            error={!!errors.address?.neighborhood}
            helperText={
              errors.address?.neighborhood
                ? errors.address.neighborhood.message
                : ''
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            label="Cidade"
            type="text"
            InputLabelProps={{ shrink: true }}
            {...register('address.city', {
              required: 'Cidade é obrigatória',
            })}
            error={!!errors.address?.city}
            helperText={errors.address?.city ? errors.address.city.message : ''}
          />
        </Stack>
        <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel id="state-label">Estado</InputLabel>
            <Select
              labelId="state-label"
              label="Estado"
              value={watch('address.state') ?? ''}
              {...register('address.state', {
                required: true,
              })}
              error={!!errors.address?.state}
            >
              {states.map((state, index) => (
                <MenuItem key={`state_${index}`} value={state.sigla}>
                  {`${state.sigla} - ${state.nome}`}
                </MenuItem>
              ))}
            </Select>
            {errors.address?.state ? (
              <Typography variant="overline" color={'red'}>
                {errors.address.state.message}
              </Typography>
            ) : (
              ''
            )}
          </FormControl>

          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            label="Ponto de Referência"
            type="text"
            InputLabelProps={{ shrink: true }}
            {...register('address.referencePoint', {
              required: 'Ponto de Referência é obrigatório',
            })}
            error={!!errors.address?.referencePoint}
            helperText={
              errors.address?.referencePoint
                ? errors.address.referencePoint.message
                : ''
            }
          />
        </Stack>

        <Stack sx={{ mt: 2, alignItems: 'start', justifyContent: 'start' }}>
          <Button fullWidth color="primary" type="submit" variant="contained">
            Salvar
          </Button>
        </Stack>
      </Stack>

      <LoadingBackdrop open={loading || sending} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Alterado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

export default TenantIndividual
