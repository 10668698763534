import InfoSnackbar from "./InfoSnackbar"

export interface ICopiedSnackbarProps {
    open: boolean;
    onClose: () => void;
    message?: string;
    action?: React.ReactNode;
}

const CopiedSnackbar = ({ open, onClose, message, action, }: ICopiedSnackbarProps) => {
    return (
        <InfoSnackbar
            open={open}
            onClose={onClose}
            autoHideDuration={2000}
            anchorHorizontal="center"
            action={action}
        >
            {message || "Copiado com sucesso"}
        </InfoSnackbar>
    );
}

export default CopiedSnackbar;