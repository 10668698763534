import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface IConfirmDialogProps {
    open: boolean;
    title?: string;
    message: string;
    onClose: () => void;
    onYes?: () => void;
}

export default function ConfirmDialog(props: IConfirmDialogProps) {
    const { open, title, message, onClose, onYes } = props;

    const handleYes = () => {
        if (onYes && onYes !== null) {
            onYes();
        }
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title || "Confirme"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Não</Button>
                <Button onClick={handleYes} autoFocus>
                    Sim
                </Button>
            </DialogActions>
        </Dialog>
    );
}