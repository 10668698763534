import { numberOnly } from "../../masks/masks";
import { TValidatePasswordReturn } from "../types/User";

const CPF = (cpf?: string) => {
  if (!cpf) {
    return false;
  }

  cpf = numberOnly(cpf);

  if (cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999") {
    return false;
  }

  var sum = 0;
  var rest;

  for (var i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(cpf.substring(9, 10))) {
    return false;
  }

  sum = 0;
  for (i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(cpf.substring(10, 11))) {
    return false;
  }
  return true;
};

const email = (email?: string) => {
  if (!email) {
    return false;
  }

  const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return expression.test(email);
};

const URL = (value: string) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(value);
};

const testChar = (value: string, reg: RegExp) => {
  for (var i = 0; i < value.length; i++) {
    if (reg.test(value[i])) {
      return true;
    }
  }
  return false;
}

const password = (value: string): Array<TValidatePasswordReturn> => {
  var charUpper = /[A-Z]/;
  var charLower = /[a-z]/;
  var charSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

  var result = new Array<TValidatePasswordReturn>();

  if (value.length < 6) 
    result.push("min");

  if (value.length > 20) 
    result.push("max");

  if (!testChar(value, charUpper)) 
    result.push("upper");

  if (!testChar(value, charLower)) 
    result.push("lower");

  if (!testChar(value, charSpecial))
    result.push("special");

  if (result.length === 0)
    result.push("success");

  return result;
}

// Regex para validação de string no formato CNPJ
const regexCNPJ = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/

// Método de validação
// Referência: https://pt.wikipedia.org/wiki/Cadastro_Nacional_da_Pessoa_Jur%C3%ADdica
function CNPJ(value: string | number | number[] = '') {
  if (!value) return false

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof value === 'string'
  const validTypes = isString || Number.isInteger(value) || Array.isArray(value)

  // Elimina valor de tipo inválido
  if (!validTypes) return false

  // Filtro inicial para entradas do tipo string
  if (isString) {
    const cnpj = numberOnly(value);
    if (cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999") {
      return false;
    }
    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{14}$/.test(value)
    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = regexCNPJ.test(value)
    // Verifica se o valor passou em ao menos 1 dos testes
    const isValid = digitsOnly || validFormat

    // Se o formato não é válido, retorna inválido
    if (!isValid) return false
  }

  // Elimina tudo que não é dígito
  const numbers = matchNumbers(value)

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false

  // Elimina inválidos com todos os dígitos iguais
  const items = [...numbers]
  if (items.length === 1) return false

  // Separa os 2 últimos dígitos verificadores
  const digits = numbers.slice(12)

  // Valida 1o. dígito verificador
  const digit0 = validCalc(12, numbers)
  if (digit0 !== digits[0]) return false

  // Valida 2o. dígito verificador
  const digit1 = validCalc(13, numbers)
  return digit1 === digits[1]
}

// Método de formatação
function formatCNPJ(value: string | number | number[] = '') {
  // Verifica se o valor é válido
  const valid = CNPJ(value)

  // Se o valor não for válido, retorna vazio
  if (!valid) return ''

  // Elimina tudo que não é dígito
  const numbers = matchNumbers(value)
  const text = numbers.join('')

  // Formatação do CNPJ: 99.999.999/9999-99
  const format = text.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5',
  )

  // Retorna o valor formatado
  return format
}

// Cálculo validador
function validCalc(x: number, numbers: number[]) {
  const slice = numbers.slice(0, x)
  let factor = x - 7
  let sum = 0

  for (let i = x; i >= 1; i--) {
    const n = slice[x - i]
    sum += n * factor--
    if (factor < 2) factor = 9
  }

  const result = 11 - (sum % 11)

  return result > 9 ? 0 : result
}

// Elimina tudo que não é dígito
function matchNumbers(value: string | number | number[] = '') {
  const match = value.toString().match(/\d/g)
  return Array.isArray(match) ? match.map(Number) : []
}

const CPF_CNPJ = (value?: string) => {
  if (!value) {
    return false;
  }

  if (numberOnly(value).length > 12) {
    return CNPJ(value);
  }

  return CPF(value);
}

const Validations = {
  CPF,
  CNPJ,
  CPF_CNPJ,
  email,
  URL,
  testChar,
  password,
};

export default Validations;
