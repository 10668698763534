import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
} from '@mui/material'

export interface IInputSearchProp {
  id?: string
  name: string
  mb?: number
  size?: 'small' | 'medium' | undefined
  width?: string
  adornmentToStart?: boolean
  removeLabel?: boolean
  sx?: object
  setSearch: (value: string) => void
  execute: () => void
}

const InputSearch = ({
  id,
  name,
  mb,
  size,
  adornmentToStart,
  width,
  removeLabel,
  sx,
  setSearch,
  execute,
}: IInputSearchProp) => {
  const nameId = (id ?? name).replaceAll(' ', '')

  const SearchAdornment = () => (
    <InputAdornment position={adornmentToStart ? 'start' : 'end'}>
      <IconButton
        aria-label="toggle search"
        onClick={execute}
        edge={adornmentToStart ? 'start' : 'end'}
      >
        <img src="/assets/icons/search/search.svg" alt="Search" />
      </IconButton>
    </InputAdornment>
  )

  return (
    <FormControl
      sx={{
        width: width,
        backgroundColor: 'white',
        borderRadius: '8px',
        mb: mb === undefined ? 2 : mb,
      }}
      variant="outlined"
      fullWidth
      size={size}
    >
      {!removeLabel && (
        <InputLabel htmlFor={`${nameId}-adornment-filter`}>{name}</InputLabel>
      )}
      <OutlinedInput
        id={`${nameId}-adornment-filter`}
        label={!removeLabel ? name : null}
        onChange={(e) => setSearch(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            execute()
          }
        }}
        startAdornment={adornmentToStart ? <SearchAdornment /> : undefined}
        endAdornment={!adornmentToStart ? <SearchAdornment /> : undefined}
        {...sx}
      />
    </FormControl>
  )
}

export default InputSearch
