import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import DesktopLayout from './partials/desktop.layout'
import MobileLayout from './partials/mobile.layout'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import React from 'react'
import { AuthContext } from '../core/context/AuthContext'
import { useCurrentTenant } from '../core/hooks/useCurrentTenant'
import {
  TUserType,
  userTypeAll,
  userTypeNotSysAdmin,
  userTypeSysAdmin,
} from '../core/types/Auth'
import HotjarFunctions from '../core/functions/HotjarFunctions'
import TagFunctions from '../core/functions/TagFunctions'
import { useTenantSettingsByTenantId } from '../core/hooks/useTenantSettingsByTenantId'
import { TTenantSettings } from '../core/types/Tenant'
import { useResponsive } from '../core/hooks/useResponsive'

const menus = [
  {
    name: 'Dashboard',
    to: '/dashboard',
    icon: { path: '/assets/icons/menu/dash/dash', extension: '.svg' },
    permissions: userTypeNotSysAdmin,
    isVersionBeta: true,
  },
  {
    name: 'Produtos',
    to: '/products',
    icon: { path: '/assets/icons/menu/product/product', extension: '.svg' },
    permissions: userTypeAll,
  },
  {
    name: 'Afiliados',
    to: '/affiliateds',
    icon: { path: '/assets/icons/menu/user/user', extension: '.svg' },
    permissions: userTypeNotSysAdmin,
  },
  {
    name: 'Regras de Comissões',
    to: '/commissionrules',
    icon: {
      path: '/assets/icons/menu/commission/commission',
      extension: '.svg',
    },
    permissions: userTypeNotSysAdmin,
  },
  {
    name: 'Apps',
    to: '/apps',
    icon: { path: '/assets/icons/menu/app/app', extension: '.svg' },
    permissions: userTypeNotSysAdmin,
  },
  {
    name: 'Funil de Vendas',
    to: '/sales/funnel',
    icon: { path: '/assets/icons/menu/funnel/funnel', extension: '.svg' },
    permissions: userTypeNotSysAdmin,
  },
  {
    name: 'Marketplace',
    to: '/marketplace',
    icon: {
      path: '/assets/icons/menu/marketplace/marketplace',
      extension: '.svg',
    },
    permissions: userTypeNotSysAdmin,
  },
  {
    name: 'Indicações',
    to: '/indicated',
    icon: { path: '/assets/icons/menu/indicated/indicated', extension: '.svg' },
    permissions: userTypeNotSysAdmin,
    isValidBySettings: (settings: TTenantSettings | null) => {
      return settings && !settings.disableIndicatedComissions
    },
  },
]

const reports = [
  {
    name: 'Vendas',
    to: '/checkout/order',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutOrder',
      extension: '.svg',
    },
    permissions: userTypeAll,
  },
  /*{
    name: 'Assinaturas',
    to: '/checkout/subscription',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutSubscription',
      extension: '.svg',
    },
    permissions: userTypeAll,
  },*/

  {
    name: 'Análise Vendas',
    to: '/checkout/analytics',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutOrder',
      extension: '.svg',
    },
    permissions: userTypeSysAdmin,
  },
  {
    name: 'Acessos Checkout',
    to: '/checkout/access',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutOrder',
      extension: '.svg',
    },
    permissions: userTypeSysAdmin,
  },
  {
    name: 'Taxa de Antecipação',
    to: '/checkout/anticipations',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutOrder',
      extension: '.svg',
    },
    permissions: userTypeSysAdmin,
  },
  {
    name: 'Registro de Ocorrências',
    to: '/admin/occurrences',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutOrder',
      extension: '.svg',
    },
    permissions: userTypeSysAdmin,
  },
  {
    name: 'Análise por produtor',
    to: '/admin/sales/analysis',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutOrder',
      extension: '.svg',
    },
    permissions: userTypeSysAdmin,
  },
  {
    name: 'Webhooks Enviados',
    to: '/webhooks/sends',
    icon: { path: '/assets/icons/menu/webhook/webhook', extension: '.svg' },
    permissions: userTypeNotSysAdmin,
  },
]

const configs = [
  {
    name: 'Configurações',
    to: '/settings',
    icon: {
      path: '/assets/icons/menu/settingsIcon/settingIcon',
      extension: '.svg',
    },
    permissions: userTypeAll,
  },
]

const system = [
  {
    name: 'Usuários',
    to: '/settings/users',
    icon: { path: '/assets/icons/menu/user/user', extension: '.svg' },
    permissions: userTypeSysAdmin,
  },
]

export default function MainLayout() {
  const { user } = React.useContext(AuthContext)

  const [open, setOpen] = React.useState(false)
  const [selected, setSelected] = React.useState('')

  const { data } = useCurrentTenant()
  const { data: settings } = useTenantSettingsByTenantId({
    id: user?.TenantId ?? '',
  })

  const isXS = useResponsive('down', 'sm')
  const isOpenOrXS = open || isXS

  const userMenus = menus.filter(
    (m) =>
      m.permissions.includes(user?.UserType ?? TUserType.Member) &&
      (!m.isValidBySettings || m.isValidBySettings(settings))
  )
  const userReports = reports.filter((m) =>
    m.permissions.includes(user?.UserType ?? TUserType.Member)
  )
  const userConfigs = configs.filter((m) =>
    m.permissions.includes(user?.UserType ?? TUserType.Member)
  )
  const userSystem = system.filter((m) =>
    m.permissions.includes(user?.UserType ?? TUserType.Member)
  )

  const drawer = (
    <div>
      {userMenus.length > 0 && (
        <List dense={true}>
          {userMenus.map((menu) => (
            <ListItem
              disablePadding
              key={menu.name}
              sx={{ borderRadius: 1, py: 0.2 }}
            >
              <ListItemButton
                component={Link}
                to={menu.to}
                selected={selected === menu.name}
                sx={{
                  borderRadius: 1,
                  minHeight: 48,
                  justifyContent: isOpenOrXS ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => setSelected(menu.name)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    ml: isOpenOrXS ? '0px' : '16px',
                    mr: '0px',
                    justifyContent: 'center',
                  }}
                >
                  <Stack alignItems="center" justifyContent="center">
                    <img
                      src={`${menu.icon.path}${selected === menu.name ? '-selected' : ''}${
                        menu.icon.extension
                      }`}
                      alt={menu.name}
                    />
                    {(menu.isVersionBeta ?? false) && (
                      <Stack
                        sx={{
                          position: 'absolute',
                          left: '9px',
                          bottom: '-2px',
                        }}
                      >
                        <Typography variant="caption" sx={{ color: '#1C52BD' }}>
                          Beta
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </ListItemIcon>
                <ListItemText
                  primary={menu.name}
                  sx={{
                    ml: 0,
                    opacity: isOpenOrXS ? 1 : 0,
                    color: selected === menu.name ? '#1C52BD' : undefined,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      {userMenus.length > 0 && <Divider sx={{ backgroundColor: '#DADCE7' }} />}
      {userReports.length > 0 && (
        <List dense={true}>
          {userReports.map((menu, index) => (
            <ListItem
              disablePadding
              key={menu.name}
              sx={{ borderRadius: 1, py: 0.2 }}
            >
              <ListItemButton
                component={Link}
                to={menu.to}
                selected={selected === menu.name}
                sx={{
                  borderRadius: 1,
                  minHeight: 48,
                  justifyContent: isOpenOrXS ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => setSelected(menu.name)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    ml: isOpenOrXS ? '0px' : '16px',
                    mr: '0px',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={`${menu.icon.path}${selected === menu.name ? '-selected' : ''}${
                      menu.icon.extension
                    }`}
                    alt={menu.name}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={menu.name}
                  sx={{
                    ml: 0,
                    opacity: isOpenOrXS ? 1 : 0,
                    color: selected === menu.name ? '#1C52BD' : undefined,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      {userReports.length > 0 && (
        <Divider sx={{ backgroundColor: '#DADCE7' }} />
      )}
      {userSystem.length > 0 && (
        <List dense={true}>
          {userSystem.map((menu, index) => (
            <ListItem
              disablePadding
              key={menu.name}
              sx={{ borderRadius: 1, py: 0.2 }}
            >
              <ListItemButton
                component={Link}
                to={menu.to}
                selected={selected === menu.name}
                sx={{
                  borderRadius: 1,
                  minHeight: 48,
                  justifyContent: isOpenOrXS ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => setSelected(menu.name)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    ml: isOpenOrXS ? '0px' : '16px',
                    mr: '0px',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={`${menu.icon.path}${selected === menu.name ? '-selected' : ''}${
                      menu.icon.extension
                    }`}
                    alt={menu.name}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={menu.name}
                  sx={{
                    ml: 0,
                    opacity: isOpenOrXS ? 1 : 0,
                    color: selected === menu.name ? '#1C52BD' : undefined,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      {userSystem.length > 0 && <Divider sx={{ backgroundColor: '#DADCE7' }} />}
      {userConfigs.length > 0 && (
        <List dense={true}>
          {userConfigs.map((menu, index) => (
            <ListItem
              disablePadding
              key={menu.name}
              sx={{ borderRadius: 1, py: 0.2 }}
            >
              <ListItemButton
                component={Link}
                to={menu.to}
                selected={selected === menu.name}
                sx={{
                  borderRadius: 1,
                  minHeight: 48,
                  justifyContent: isOpenOrXS ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => setSelected(menu.name)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    ml: isOpenOrXS ? '0px' : '16px',
                    mr: '0px',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={`${menu.icon.path}${selected === menu.name ? '-selected' : ''}${
                      menu.icon.extension
                    }`}
                    alt={menu.name}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={menu.name}
                  sx={{
                    ml: 0,
                    opacity: isOpenOrXS ? 1 : 0,
                    color: selected === menu.name ? '#1C52BD' : undefined,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )

  useEffect(() => {
    const tagHotjarDash = HotjarFunctions.addTagDash()

    return () => TagFunctions.removeTags({ script: tagHotjarDash })
  }, [])

  return (
    <Box>
      {isXS && <MobileLayout drawer={drawer} />}
      {!isXS && (
        <DesktopLayout
          drawer={drawer}
          open={open}
          setOpen={setOpen}
          tenant={data}
          tenantSettings={settings}
        />
      )}
    </Box>
  )
}
