import api, { getResponseError } from '../../services/api'
import { IErrorResponse, IResponse } from '../types/ErrorResponse'
import {
  TWebhook,
  TWebhookEventEnum,
  TWebhookSendsPagination,
  TWebhooksPagination,
} from '../types/Webhook'

interface IGetProp {
  page: number
  rows: number
  search?: string
}

interface IGetSendsProp {
  page: number
  rows: number
  search?: string
  event?: TWebhookEventEnum
  status?: string
}

const getAll = async ({
  page,
  rows,
  search,
}: IGetProp): Promise<TWebhooksPagination | IErrorResponse> => {
  try {
    var path = `/Webhook/pagination?PageNumber=${page}&RowsPerPage=${rows}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    const { data } = await api.get<TWebhooksPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getSends = async ({
  page,
  rows,
  search,
  event,
  status,
}: IGetSendsProp): Promise<TWebhookSendsPagination | IErrorResponse> => {
  try {
    var path = `/Webhook/send?PageNumber=${page}&RowsPerPage=${rows}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (event) {
      path += `&Event=${event}`
    }

    if (status && status !== '') {
      path += `&Status=${status}`
    }

    const { data } = await api.get<TWebhookSendsPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const resendWebhookSend = async ({
  webhookSendId,
}: {
  webhookSendId: string
}): Promise<string | IErrorResponse> => {
  try {
    var path = `/Webhook/send/${webhookSendId}/resend`

    const { data } = await api.get<string>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const insert = async ({ data }: { data: TWebhook }): Promise<IResponse> => {
  try {
    const json = {
      identifier: data.identifier,
      url: data.url,
      allProducts: data.allProducts || data.productId === '',
      numberAttempts: data.numberAttempts,
      events: data.events,
      productIds:
        data.productId && data.productId !== '' ? [data.productId] : [],
      isSendToOrderbumps: data.isSendToOrderbumps,
    }

    const { data: response } = await api.post('/Webhook', json)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const update = async ({ data }: { data: TWebhook }): Promise<IResponse> => {
  try {
    const json = {
      id: data.id,
      identifier: data.identifier,
      url: data.url,
      allProducts: data.allProducts || data.productId === '',
      numberAttempts: data.numberAttempts,
      events: data.events,
      productIds:
        data.productId && data.productId !== '' ? [data.productId] : [],
      isSendToOrderbumps: data.isSendToOrderbumps,
    }

    const { data: response } = await api.put('/Webhook', json)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const remove = async ({ id }: { id: string }): Promise<IResponse> => {
  try {
    const { data: response } = await api.delete(`/Webhook/${id}`)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const test = async ({
  data,
  url,
  token,
}: {
  data: any
  url: string
  token: string
}): Promise<IResponse> => {
  try {
    const json = {
      data: data,
      url: url,
      token,
    }

    const { data: response } = await api.post('/Webhook/test', json)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
      data: '404',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
      data: '404',
    }
  }
}

const WebhookController = {
  getSends,
  resendWebhookSend,
  getAll,
  insert,
  update,
  remove,
  test,
}

export default WebhookController
