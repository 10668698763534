import { Box, Container } from '@mui/material'
import { useResponsive } from '../../../core/hooks/useResponsive'

interface IContainerBoxProps {
  children: React.ReactNode
}

const ContainerBox = ({ children }: IContainerBoxProps) => {
  const mdDown = useResponsive('down', 'md')

  return (
    <Container maxWidth="lg" disableGutters={mdDown}>
      {children}
    </Container>
  )
}

export default ContainerBox
