import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import MarketplaceCard from '../../components/Cards/MarketplaceCard'
import NumberFunctions from '../../core/functions/NumberFunctions'
import { useMarketplace } from '../../core/hooks/useMarketplace'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import FilterIcon from '@mui/icons-material/Tune'
import { TMarketplace } from '../../core/types/Marketplace'
import MarketplaceDetails from './MarketplaceDetails'
import { useCurrentTenant } from '../../core/hooks/useCurrentTenant'
import AffiliatesFunction from '../../core/functions/affiliates'
import { TProdutAffiliatesCategory } from '../../core/types/ProductAffiliates'
import TablePaginationDefault from '../../components/Tables/TablePaginationDefault'
import SuccessSnackbar from '../../components/Snackbar/SuccessSnackbar'
import SwipeableDrawerRightDialog from '../../components/Dialogs/SwipeableDrawerRightDialog'
import CopiedSnackbar from '../../components/Snackbar/CopiedSnackbar'
import { AuthContext } from '../../core/context/AuthContext'
import { useCurrentTenantBank } from '../../core/hooks/useCurrentTenantBank'
import ContentDialog from '../../components/Dialogs/ContentDialog'
import { TenantBankAccountWidget } from '../settings/TenantBankAccountWidget'

function valueTextMoney(value: number, index: number) {
  return NumberFunctions.formatMoneyDefault(value)
}

function valueTextPercentage(value: number, index: number) {
  return `${value}%`
}

const minDistance = 10

const marksPrice = [
  {
    value: 0,
    label: NumberFunctions.formatMoneyDefault(0),
  },
  {
    value: 20000,
    label: NumberFunctions.formatMoneyDefault(20000),
  },
]

const marksCommission = [
  {
    value: 1,
    label: '1%',
  },
  {
    value: 99,
    label: '99%',
  },
]

const MarketplacePage: React.FC = () => {
  const { user } = React.useContext(AuthContext)

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [openDetails, setOpenDetails] = React.useState(false)
  const [showNotTenantBank, setShowNotTenantBank] = React.useState(false)
  const [current, setCurrent] = React.useState<TMarketplace>()
  const [category, setCategory] = React.useState<TProdutAffiliatesCategory>(
    TProdutAffiliatesCategory.All
  )
  const [openFilter, setOpenFilter] = React.useState(false)
  const [priceRange, setPriceRange] = React.useState<number[]>([0, 20000])
  const [commissionRange, setCommissionRange] = React.useState<number[]>([
    1, 99,
  ])
  const [success, setSuccess] = React.useState(false)
  const [copied, setCopied] = React.useState(false)

  const { loading, data, error, clear, execute } = useMarketplace({
    page,
    rows: rowsPerPage,
    search,
    category,
    priceRange,
    commissionRange,
  })

  const {
    data: tenant,
    loading: loadingTenant,
    error: errorTenant,
  } = useCurrentTenant()

  const { data: tenantBank, execute: tenantBankExecute } = useCurrentTenantBank(
    { tenantId: user?.TenantId ?? '' }
  )

  const isNotTenantBank =
    !tenantBank ||
    !tenantBank?.accountName ||
    tenantBank?.accountName.trim() === '' ||
    !tenantBank?.recipientDocument ||
    tenantBank?.recipientDocument.trim() === ''

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleOpenDetails = (marketplace: TMarketplace) => {
    if (isNotTenantBank) {
      handleChangeShowNotTenantBank()
      return
    }
    setCurrent(marketplace)
    setOpenDetails(true)
  }

  const handleCloseDetails = () => {
    setCurrent(undefined)
    setOpenDetails(false)
  }

  const handleOpenFilter = () => {
    setOpenFilter(true)
  }

  const handleCloseFilter = () => {
    setOpenFilter(false)
  }

  const handleChangePriceRange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance)
        setPriceRange([clamped, clamped + minDistance])
      } else {
        const clamped = Math.max(newValue[1], minDistance)
        setPriceRange([clamped - minDistance, clamped])
      }
    } else {
      setPriceRange(newValue as number[])
    }
  }

  const handleChangeCommissionRange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance)
        setCommissionRange([clamped, clamped + minDistance])
      } else {
        const clamped = Math.max(newValue[1], minDistance)
        setCommissionRange([clamped - minDistance, clamped])
      }
    } else {
      setCommissionRange(newValue as number[])
    }
  }

  const handleChangeShowNotTenantBank = () => {
    setShowNotTenantBank(true)
  }

  const handleCloseNotTenantBank = () => {
    setShowNotTenantBank(false)
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        Marketplace
      </Typography>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <Alert onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Box>
      )}

      <FormControl sx={{ mb: 2 }} variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-filter">
          Filtrar por nome
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-filter"
          onChange={(e) => setSearch(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle search"
                onClick={handleOpenFilter}
                edge="end"
              >
                <FilterIcon />
              </IconButton>
            </InputAdornment>
          }
          label="Filtrar por nome"
        />
      </FormControl>

      <Grid container spacing={2} columns={18} sx={{ pt: 2, pb: 2 }}>
        {data.items.map((product) => (
          <Grid item xs={18} sm={18} md={9} lg={6} xl={4.5}>
            <MarketplaceCard
              title={product.productName}
              subtitle={product.author}
              text={`Preço máximo do produto: ${NumberFunctions.formatMoneyDefault(product.maxValue)}`}
              imageUrl={product.imageProductUrl}
              onClick={() => handleOpenDetails(product)}
            />
          </Grid>
        ))}
      </Grid>
      <TablePaginationDefault
        count={data.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <MarketplaceDetails
        marketplace={current}
        tenant={tenant}
        open={openDetails}
        handleClose={handleCloseDetails}
        setOpen={setOpenDetails}
        setSuccess={setSuccess}
        setCopied={setCopied}
      />

      <SwipeableDrawerRightDialog
        open={openFilter}
        onClose={handleCloseFilter}
        setOpen={setOpenFilter}
        title="Filtros"
      >
        <Stack direction="column" sx={{ width: '100%' }}>
          <Stack direction="row" justifyContent="end" sx={{ px: 3 }}>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setCategory(TProdutAffiliatesCategory.All)
                setPriceRange([0, 20000])
                setCommissionRange([1, 99])
              }}
            >
              Limpar filtros
            </Button>
          </Stack>
          <Stack
            direction="row"
            alignContent="center"
            alignItems="center"
            sx={{ pl: 3, pt: 3, pr: 3, pb: 1 }}
          >
            <Autocomplete
              fullWidth
              disablePortal
              size="small"
              options={AffiliatesFunction.productAffiliatesCategoriesFilter}
              getOptionLabel={(option) =>
                AffiliatesFunction.descriptionProductAffiliatesCategory(option)
              }
              onChange={(event, option) => {
                if (option && option !== null) {
                  setCategory(option)
                } else {
                  setCategory(TProdutAffiliatesCategory.All)
                }
              }}
              value={category}
              renderInput={(params) => (
                <TextField {...params} label="Categoria" />
              )}
            />
          </Stack>
          <Box>
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              sx={{ pl: 4, pt: 2, pr: 3, pb: 0 }}
            >
              <Typography variant="caption" fontWeight={500}>
                Preço
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              sx={{ pl: 6, pt: 0, pr: 6, pb: 1 }}
            >
              <Slider
                getAriaLabel={() => 'Preço'}
                value={priceRange}
                onChange={handleChangePriceRange}
                valueLabelDisplay="auto"
                getAriaValueText={valueTextMoney}
                valueLabelFormat={valueTextMoney}
                disableSwap
                min={0}
                max={20000}
                marks={marksPrice}
              />
            </Stack>
          </Box>
          <Box>
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              sx={{ pl: 4, pt: 2, pr: 3, pb: 0 }}
            >
              <Typography variant="caption" fontWeight={500}>
                Comissão %
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              sx={{ pl: 6, pt: 0, pr: 6, pb: 1 }}
            >
              <Slider
                getAriaLabel={() => 'Comissão'}
                value={commissionRange}
                onChange={handleChangeCommissionRange}
                valueLabelDisplay="auto"
                getAriaValueText={valueTextPercentage}
                valueLabelFormat={valueTextPercentage}
                disableSwap
                min={1}
                max={99}
                marks={marksCommission}
              />
            </Stack>
          </Box>
        </Stack>
      </SwipeableDrawerRightDialog>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Afiliação solicitada
      </SuccessSnackbar>

      <Backdrop
        sx={{
          bgColor: '#3333',
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading || loadingTenant}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />

      {tenant && (
        <ContentDialog
          open={showNotTenantBank}
          onClose={() => {}}
          hasCancel={false}
          isTitle={false}
          hideButtons
        >
          <TenantBankAccountWidget
            tenantId={user?.TenantId ?? ''}
            tenant={tenant}
            reload={tenantBankExecute}
            onClose={handleCloseNotTenantBank}
          />
        </ContentDialog>
      )}
    </Container>
  )
}

export default MarketplacePage
