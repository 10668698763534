import React, { useCallback, useRef } from 'react'
import { IErrorResponse } from '../types/ErrorResponse'
import OrderController from '../controllers/OrderController'
import { TCheckoutOpenPagination } from '../types/CheckoutOpen'

export interface ICheckoutOpenProp {
  page: number
  rows: number
  search?: string
  lastDays: number
  report?: 'order' | 'subscription'
}

const valueDefault = {
  items: [],
  page: 0,
  rowsPerPage: 0,
  totalPages: 0,
  total: 0,
}

export const useCheckoutOpen = ({
  page,
  rows,
  search,
  lastDays,
  report,
}: ICheckoutOpenProp) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<TCheckoutOpenPagination>(valueDefault)
  const [error, setError] = React.useState<string>('')

  const executing = useRef(false)

  const execute = useCallback(async () => {
    if (!loading && !executing.current) {
      executing.current = true
      try {
        setLoading(true)

        if (error !== '') {
          setError('')
        }

        const response = await OrderController.getOpen({
          page,
          rows,
          search,
          lastDays,
          report,
        })
        const responseError = response as IErrorResponse
        const responseData = response as TCheckoutOpenPagination

        if (responseError.code) {
          setError(responseError.error)
          setData(valueDefault)
        } else {
          setData(responseData)
        }
      } catch (e) {
        var message = 'Erro não identificado'
        if (typeof e === 'string') {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        setError(message)
      } finally {
        setLoading(false)
        executing.current = false
      }
    }
  }, [error, lastDays, loading, page, rows, search])

  React.useEffect(() => {
    execute()
  }, [lastDays, page, rows])

  const clear = () => {
    setError('')
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  }
}
