import * as React from 'react'
import { useState } from 'react'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import {
  addDays,
  dateDescription,
  dateTimeString,
} from '../../../../core/functions/dateTime'
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material'
import { useProductsSimple } from '../../../../core/hooks/useProductsSimple'
import { useOrderAnalitycs } from '../../../../core/hooks/useOrdersAnalitycs'
import { AuthContext } from '../../../../core/context/AuthContext'
import { decodeToken } from 'react-jwt'
import { TAuthTenant } from '../../../../core/types/AuthTenant'
import { useNavigate } from 'react-router-dom'
import TitlePageTypography from '../../../../components/Typographys/TitlePageTypography'
import SubtitlePageTypography from '../../../../components/Typographys/SubtitlePageTypography'
import DataTable, {
  IDataTableColumn,
} from '../../../../components/Tables/DataTable'
import {
  TOccurrenceDetails,
  TOccurrenceInteration,
  TOccurrenceLog,
  TOccurrenceStatusEnum,
} from '../../../../core/types/OccurrenceLog'
import { useOccurrenceLog } from '../../../../core/hooks/useOccurrenceLogs'
import OccurrenceDetailsPage from './OccurrenceLogDetails/OccurrenceDetailPage'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import OccurrenceLogController from '../../../../core/controllers/OccurrenceLogController'
import { IErrorResponse } from '../../../../core/types/ErrorResponse'
import OccurrenceLogFunction from '../../../../core/functions/occurrenceLog'
import DataTableSubcellTypography from '../../../../components/Typographys/DataTableSubcellTypography'
import DataTableCellTypography from '../../../../components/Typographys/DataTableCellTypography'
import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }
)

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: { textTransform: 'none' },
  }
}

export default function OccurrenceLogPage() {
  const { user } = React.useContext(AuthContext)
  const json = decodeToken(user?.token ?? '')
  const authTenant = json as TAuthTenant

  const [tabIndex, setTabIndex] = React.useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [type, setType] = useState('allOccurences')
  const [current, setCurrent] = useState<TOccurrenceLog | undefined>(undefined)
  const [details, setDetails] = useState<TOccurrenceInteration | undefined>(
    undefined
  )
  const [detailsInfo, setDetailsInfo] = useState<
    TOccurrenceDetails | undefined
  >(undefined)
  const [open, setOpen] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorSending, setErrorSending] = useState('')
  const [startDate, setStartdate] = useState<Date | null | undefined>(
    addDays(-29)
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(new Date())
  const [searchType, setSearchType] = useState(0)
  const [prodSearchList, setProdSearchLists] = useState<string[]>([])
  const [affilatedsSearchList, setAffilatedsSearchLists] = useState<string[]>(
    []
  )
  const [paymentSearchList, setpaymentSearchLists] = useState<string[]>([])
  const [statusSearchList, setStatusSearchLists] = useState<string[]>([])
  const [openFilter, setOpenFilter] = useState(false)

  const { loading, data, error, execute, clear } = useOccurrenceLog({
    page,
    rows: rowsPerPage,
    startDate,
    endDate,
  })

  const columns: readonly IDataTableColumn<TOccurrenceLog>[] = [
    {
      id: 'createdDate',
      label: 'Criado em',
      minWidth: 80,
      format: (value: any) => {
        const date = new Date(value)
        return isNaN(date.getTime())
          ? 'Data não encontrada'
          : dateTimeString(date)
      },
    },
    { id: 'codeId', label: 'Código', minWidth: 100 },
    { id: 'description', label: 'Descrição', minWidth: 80 },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      render(id, value, row) {
        return (
          <Stack direction="column">
            <DataTableSubcellTypography>
              <Chip
                label={OccurrenceLogFunction.statusString(value)}
                size="small"
                color={
                  value === TOccurrenceStatusEnum.Active
                    ? 'warning'
                    : value === TOccurrenceStatusEnum.Inactive
                      ? 'secondary'
                      : value === TOccurrenceStatusEnum.Resolved
                        ? 'success'
                        : 'error'
                }
              />
            </DataTableSubcellTypography>
          </Stack>
        )
      },
    },
    {
      id: 'type',
      label: 'Tipo',
      minWidth: 100,
      render(id, value, row) {
        return (
          <Stack direction="column">
            <DataTableSubcellTypography>
              {OccurrenceLogFunction.statusType(value)}
            </DataTableSubcellTypography>
          </Stack>
        )
      },
    },
    {
      id: 'event',
      label: 'Evento',
      minWidth: 80,
      render(id, value, row) {
        return (
          <Stack direction="column">
            <DataTableSubcellTypography>
              {OccurrenceLogFunction.statusEvent(value)}
            </DataTableSubcellTypography>
          </Stack>
        )
      },
    },
  ]

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }
  }, [data, rowsPerPage])

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleClose = () => {
    setCurrent(undefined)
    setOpen(false)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleDateFilter = (value: any) => {
    setStartdate(value.startDate)
    setEndDate(value.endDate)
  }

  const handleOpenFilter = () => setOpenFilter(true)

  const handleChangeItem = (occurenceLog: TOccurrenceLog) => {
    setCurrent(occurenceLog)
    setOpen(true)
  }

  const navigate = useNavigate()

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        setType('allOccurrences')
        break
    }
    setTabIndex(newValue)
  }

  const getDetails = React.useCallback(async (id: string) => {
    try {
      setSending(true)
      setErrorSending('')
      if (detailsInfo) {
        setDetailsInfo(undefined)
      }

      const response = await OccurrenceLogController.getDetails({
        id,
      })
      var responseError = response as IErrorResponse

      if (responseError.code) {
        setErrorSending(responseError.error)
      } else {
        const responseData = response as TOccurrenceDetails
        setDetailsInfo(responseData)
      }
    } catch (e) {
      var message = 'Erro não identificado'
      if (typeof e === 'string') {
        message = e.toUpperCase()
      } else if (e instanceof Error) {
        const err = e as Error
        message = err.message
      }
      setErrorSending(message)
    } finally {
      setSending(false)
    }
  }, [])

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        alignContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Stack flexGrow="1">
          <TitlePageTypography>Registro de Ocorrências</TitlePageTypography>
          <SubtitlePageTypography>
            {`Filtro do período de ${dateDescription(startDate)} à ${dateDescription(endDate)}`}
          </SubtitlePageTypography>
        </Stack>

        <TextField
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              execute()
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img alt="Pesquisar" src="/assets/icons/search/search.svg" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            sx: {
              ml: 4.5,
              color: 'black',
            },
            shrink: search !== '',
            required: false,
          }}
          sx={(theme) => ({
            backgroundColor: 'white',
            '& .Mui-focused .MuiInputAdornment-root': {
              color: theme.palette.primary.main,
            },
            '& .Mui-error .MuiInputAdornment-root': {
              color: theme.palette.error.main,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              px: 5.5,
            },
          })}
          label="Pesquisar"
        />

        <Button
          sx={{
            p: 0,
            m: 0,
            minWidth: 0,
            borderRadius: '8px',
          }}
          onClick={handleOpenFilter}
        ></Button>
      </Stack>

      <Stack
        direction="row"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        alignItems="center"
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          sx={{ flexGrow: 1 }}
        >
          <Tab label="Ocorrências" {...a11yProps(0)} />
        </Tabs>
      </Stack>

      <DataTable
        columns={columns}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        onItemClick={handleChangeItem}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <OccurrenceDetailsPage
        occurrence={current}
        details={details}
        detailsInfo={detailsInfo}
        open={open}
        handleClose={handleClose}
        setOpen={setOpen}
        setSending={setSending}
        setSuccess={setSuccess}
        setError={setErrorSending}
        execute={execute}
        getDetails={getDetails}
      />
      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>
      <LoadingBackdrop open={loading || sending} />
    </Box>
  )
}
