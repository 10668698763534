import { AxiosResponse } from 'axios'
import api, { getResponseError } from '../../services/api'
import { IErrorResponse, IResponse } from '../types/ErrorResponse'
import {
  TFunnelStepsPagination,
  TSalesFunnel,
  TSalesFunnelPagination,
  TSalesFunnelStep,
} from '../types/SalesFunnel'

interface IGetSalesFunnelProp {
  page: number
  rows: number
  search?: string
}

const getAll = async ({
  page,
  rows,
  search,
}: IGetSalesFunnelProp): Promise<TSalesFunnelPagination | IErrorResponse> => {
  try {
    var path = `/SalesFunnel?PageNumber=${page}&RowsPerPage=${rows}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    const { data } = await api.get<TSalesFunnelPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const get = async ({
  id,
}: {
  id: string
}): Promise<TSalesFunnel | IErrorResponse> => {
  try {
    const { data } = await api.get<TSalesFunnel>(`/SalesFunnel/${id}`)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getFunnelSteps = async ({
  page,
  rows,
  search,
}: IGetSalesFunnelProp): Promise<TFunnelStepsPagination | IErrorResponse> => {
  try {
    var path = `/SalesFunnel/funnelStep?PageNumber=${page}&RowsPerPage=${rows}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    const { data } = await api.get<TFunnelStepsPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const insert = async ({ data }: { data: TSalesFunnel }): Promise<IResponse> => {
  try {
    const { data: response } = await api.post('/SalesFunnel', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const update = async ({ data }: { data: TSalesFunnel }): Promise<IResponse> => {
  try {
    const { data: response } = await api.put('/SalesFunnel', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const remove = async ({ id }: { id: string }): Promise<IResponse> => {
  try {
    await api.delete(`/SalesFunnel/${id}`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const disable = async ({ id }: { id: string }): Promise<IResponse> => {
  try {
    await api.patch(`/SalesFunnel/${id}/disable`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const enable = async ({ id }: { id: string }): Promise<IResponse> => {
  try {
    await api.patch(`/SalesFunnel/${id}/enable`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const insertSalesFunnelStep = async ({
  data,
}: {
  data: TSalesFunnelStep
}): Promise<IResponse> => {
  try {
    const { funnelStep, ...salesFunnelStep } = data
    let funnelStepId = salesFunnelStep.funnelStepId
    if (!funnelStepId || funnelStepId === '') {
      const { data: resp } = await api.post(
        '/SalesFunnel/funnelStep',
        funnelStep
      )
      funnelStepId = resp
    }
    const { data: response } = await api.post('/SalesFunnel/saleFunnelStep', {
      ...salesFunnelStep,
      funnelStepId,
    })
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const updateSalesFunnelStep = async ({
  data,
}: {
  data: TSalesFunnelStep
}): Promise<IResponse> => {
  try {
    const { funnelStep, ...salesFunnelStep } = data
    const { data: resp } = await api.put('/SalesFunnel/funnelStep', funnelStep)
    if (!resp) {
      return {
        success: true,
        code: 0,
        error: '',
        data: resp,
      }
    }

    const { data: response } = await api.put(
      '/SalesFunnel/saleFunnelStep',
      salesFunnelStep
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const removeSalesFunnelStep = async ({
  id,
}: {
  id: string
}): Promise<IResponse> => {
  try {
    await api.delete(`/SalesFunnel/saleFunnelStep/${id}`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const updateSalesFunnelData = async ({
  data,
}: {
  data: { id: string; dataNode?: string | null; dataEdge?: string | null }
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.put('/SalesFunnel/data', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const checkOtherSalesFunnelPriceConfigured = async ({ id, salesFunneId }: { id: string, salesFunneId?: string | null }) => {
  try {
    let url = `/SalesFunnel/product/${id}/validate`;

    if (salesFunneId && salesFunneId !== "" && salesFunneId !== 'new') {
      url += `?salesFunnelId=${salesFunneId}`;
    }

    const resp = await api.get(url);
    const typedResp = resp as AxiosResponse<{ isValid: boolean }>
    return typedResp.data.isValid
  } catch (error) {
    return false
  }
}

const SalesFunnelController = {
  getAll,
  get,
  getFunnelSteps,
  insert,
  update,
  remove,
  insertSalesFunnelStep,
  updateSalesFunnelStep,
  removeSalesFunnelStep,
  disable,
  enable,
  updateSalesFunnelData,
  checkOtherSalesFunnelPriceConfigured,
}

export default SalesFunnelController
