import { useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Collapse,
    FormControlLabel,
    Paper,
    Slide,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { TActiveCampaign, TActiveCampaignItem, TActiveCampaignItemAction } from "../../../core/types/ActiveCampaign";
import { useActiveCampaign } from "../../../core/hooks/useActiveCampaign";
import SwitchRound from "../../../components/Switch/SwitchRound";
import ActiveCampaignController from "../../../core/controllers/ActiveCampaignController";
import SuccessSnackbar from "../../../components/Snackbar/SuccessSnackbar";
import ErrorSnackbar from "../../../components/Snackbar/ErrorSnackbar";
import InputSearch from "../../../components/Inputs/InputSearch";
import TablePaginationDefault from "../../../components/Tables/TablePaginationDefault";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useActiveCampaignItems } from "../../../core/hooks/useActiveCampaignItems";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";
import ActiveCampaignFunctions from "../../../core/functions/ActiveCampaignFunctions";
import Validations from "../../../core/functions/validations";

interface Column {
    id: "productName" | "event" | "action" | "actions";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: "productName", label: "Produto", minWidth: 150 },
    { id: "event", label: "Evento", minWidth: 100 },
    { id: "action", label: "Ação", minWidth: 100 },
    {
        id: "actions",
        label: "Ações",
        minWidth: 100,
    },
];

export const ActiveCampaignPage = () => {
    const [animationState, setAnimationState] = useState(false);
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showSettings, setShowSettings] = useState(true);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [inactive, setInactive] = useState(false);

    const [error, setError] = useState("");
    const [search, setSearch] = useState("");

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [current, setCurrent] = useState<TActiveCampaignItem>();

    const oldSearch = useRef("");

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        watch,
        setValue,
    } = useForm<TActiveCampaign>({
        defaultValues: {
            id: "",
            active: true,
            url: "",
            token: "",
        },
    });

    const { data, loading, error: errorData, execute, clear } = useActiveCampaign();

    const {
        data: items,
        loading: loadItems,
        error: errorItems,
        execute: getItems,
    } = useActiveCampaignItems({ page, rows: rowsPerPage, search });

    const Submit = async (data: any) => {
        if (!data.url || data.url === "" || !Validations.URL(data.url)) {
            setError("É necessário informar uma URL válida");
            return;
        }

        if (!data.token || data.token === "") {
            setError("É necessário informar um token");
            return;
        }

        setSending(true);
        setSuccess(false);
        setError("");

        try {
            let response;
            if (data.id === "") response = await ActiveCampaignController.insert({ data });
            else response = await ActiveCampaignController.update({ data });

            if (!response.success) {
                setError(response.error);
            } else {
                setSuccess(true);
                execute();
            }
        } finally {
            setSending(false);
        }
    };

    const navigate = useNavigate();

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (rows: number) => {
        setRowsPerPage(rows);
        setPage(0);
    };

    const handleChangeDelete = (item: TActiveCampaignItem) => {
        setCurrent(item);
        setOpenDelete(true);
    };

    const deleteItem = async () => {
        if (!current) {
            return;
        }

        setSending(true);
        try {
            const response = await ActiveCampaignController.removeItem({ id: current?.id ?? "" });
            if (!response.success) {
                setError(response.error);
            } else {
                setDeleted(true);
                getItems();
            }

            setCurrent(undefined);
            setOpenDelete(false);
        } finally {
            setSending(false);
        }
    };

    const inactiveCampaign = async () => {
        setSending(true);
        try {
            const response = await ActiveCampaignController.remove({ id: data?.id ?? "" });
            if (!response.success) {
                setError(response.error);
            } else {
                execute();
            }

            setInactive(false);
        } finally {
            setSending(false);
        }
    };

    useEffect(() => {
        setAnimationState(true);
        return () => setAnimationState(false);
    }, []);

    useEffect(() => {
        if (data !== null) {
            reset(data);
            setShowSettings(false);
        }
    }, [data]);

    useEffect(() => {
        if (items.rowsPerPage !== rowsPerPage) {
            setRowsPerPage(items.rowsPerPage);
        }

        if (oldSearch.current !== search) {
            oldSearch.current = search;
            setPage(0);
        }
    }, [items]);

    return (
        <Box>
            <Box sx={{ width: "100%", margin: "0 auto" }}>
                <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
                    ActiveCampaign
                </Typography>

                <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
                    <Box>
                        <Box
                            component={Paper}
                            py={3}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                flexBasis: 1,
                                alignItems: "center",
                                margin: "0 auto",
                            }}
                            width="90%"
                        >
                            <Stack
                                noValidate
                                component="form"
                                direction="column"
                                spacing={showSettings ? 2 : 0}
                                sx={{ width: "80%" }}
                                onSubmit={handleSubmit(Submit)}
                            >
                                {(data?.id ?? "") !== "" && (
                                    <Button sx={{ width: 200 }} onClick={() => setShowSettings(!showSettings)}>
                                        {showSettings ? "Ocultar " : "Editar "}configurações
                                    </Button>
                                )}

                                <Collapse in={showSettings}>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={2}
                                        onSubmit={handleSubmit(Submit)}
                                    >
                                        {data && (
                                            <FormControlLabel
                                                control={
                                                    <SwitchRound
                                                        sx={{ m: 1 }}
                                                        {...register("active")}
                                                        checked={watch("active")}
                                                        onChange={(e) => {
                                                            if (!e.target.checked) {
                                                                setInactive(true);
                                                                setValue("active", true);
                                                                return;
                                                            }
                                                            setValue("active", e.target.checked);
                                                        }}
                                                    />
                                                }
                                                label="Ativo"
                                            />
                                        )}
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            type="text"
                                            label="URL"
                                            InputLabelProps={{ shrink: data ? true : undefined }}
                                            {...register("url")}
                                            error={errors.url?.type === "required"}
                                            helperText="No ActiveCampaign em Configurações - Desenvolvedor copie a URL e cole aqui. Exemplo https://account.api-us1.com"
                                        />
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            type="text"
                                            label="Token"
                                            InputLabelProps={{ shrink: data ? true : undefined }}
                                            {...register("token")}
                                            error={errors.token?.type === "required"}
                                        />
                                    </Stack>
                                </Collapse>

                                <Collapse in={showSettings}>
                                    <Button fullWidth variant="contained" disabled={sending} type="submit">
                                        Salvar
                                    </Button>
                                </Collapse>
                            </Stack>
                        </Box>

                        {(data?.id ?? "") !== "" && (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    flexBasis: 1,
                                    alignItems: "start",
                                    margin: "0 auto",
                                }}
                                width="90%"
                            >
                                <Typography variant="h5" fontWeight={500} sx={{ my: 2 }}>
                                    Disparos inteligentes
                                </Typography>

                                <Stack
                                    direction="row"
                                    spacing={2}
                                    alignContent="center"
                                    alignItems="center"
                                    width="100%"
                                >
                                    <InputSearch name="Filtrar" setSearch={setSearch} execute={getItems} mb={0} />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => navigate(`/apps/activecampaign/${watch("id")}/new`)}
                                    >
                                        Novo disparo inteligente
                                    </Button>
                                </Stack>

                                <Paper sx={{ width: "100%", overflow: "hidden", my: 2 }}>
                                    <TableContainer>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {items.items.map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                            {columns.map((column) => {
                                                                if (column.id === "actions") {
                                                                    return (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            <Button
                                                                                variant="text"
                                                                                startIcon={<EditIcon />}
                                                                                onClick={() =>
                                                                                    navigate(
                                                                                        `/apps/activecampaign/${watch(
                                                                                            "id"
                                                                                        )}/${row.id}`
                                                                                    )
                                                                                }
                                                                            >
                                                                                Editar
                                                                            </Button>
                                                                            <Button
                                                                                variant="text"
                                                                                color="error"
                                                                                startIcon={<DeleteIcon />}
                                                                                onClick={() => handleChangeDelete(row)}
                                                                            >
                                                                                Excluir
                                                                            </Button>
                                                                        </TableCell>
                                                                    );
                                                                }
                                                                if (column.id === "event") {
                                                                    return (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            {ActiveCampaignFunctions.typeToString(
                                                                                row.event
                                                                            )}
                                                                        </TableCell>
                                                                    );
                                                                }
                                                                if (column.id === "action") {
                                                                    return (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            {row.action ===
                                                                            TActiveCampaignItemAction.Include
                                                                                ? "Incluir"
                                                                                : "Remover"}
                                                                        </TableCell>
                                                                    );
                                                                }
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === "number"
                                                                            ? column.format(value)
                                                                            : value}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePaginationDefault
                                        count={items.total}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </Box>
                        )}
                    </Box>
                </Slide>
            </Box>

            <ConfirmDialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                message="Deseja realmente excluir o item ?"
                onYes={deleteItem}
            />

            <ConfirmDialog
                open={inactive}
                onClose={() => setInactive(false)}
                message="Deseja realmente inativar a conta do ActiveCampaign ? Não será possível reverter essa ação."
                onYes={inactiveCampaign}
            />

            <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
                Concluído com sucesso
            </SuccessSnackbar>

            <SuccessSnackbar open={deleted} onClose={() => setDeleted(false)}>
                Deletado com sucesso
            </SuccessSnackbar>

            <ErrorSnackbar open={error !== ""} onClose={() => setError("")}>
                {error}
            </ErrorSnackbar>
        </Box>
    );
};
