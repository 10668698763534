import {
  Box,
  Button,
  Chip,
  Collapse,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import SwipeableDrawerRightDialog from '../../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { TProductPrice } from '../../../../../core/types/Product'
import { payUrl } from '../../../../../core/functions/hosts'
import { useState } from 'react'
import RowRadioButtonsGroup from '../../../../../components/RadioGroup/RowRadioButtonsGroup'
import UtilFunctions from '../../../../../core/functions/UtilFunctions'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import { DatePickerFilter } from '../../../../../components/DatePickerFilter'
import { TimePickerFilter } from '../../../../../components/DatePickerFilter/TimePickerFilter'
import { red } from '@mui/material/colors'
import {
  getDate,
  getFullYear,
  getHours,
  getMinutes,
  getMonth,
  isValidDateTime,
} from '../../../../../core/functions/dateTime'

export interface IProductPriceDisableProps {
  open: boolean
  sending: boolean
  price: TProductPrice | null
  prices: TProductPrice[]
  setOpen: (value: boolean) => void
  handleClose: () => void
  setPrice: (value: TProductPrice) => void
  onSave: () => void
}

const ProductPriceDisable = ({
  open,
  sending,
  price,
  prices,
  setOpen,
  handleClose,
  setPrice,
  onSave,
}: IProductPriceDisableProps) => {
  const [errorPrice, setErrorPrice] = useState('')

  const handleSave = () => {
    var isError = false

    if (!price?.redirectProductPriceId || price.redirectProductPriceId === '') {
      setErrorPrice('Campo obrigatório')
      isError = true
    }

    if (isError) {
      return
    }

    onSave()

    setErrorPrice('')
  }

  if (price === null) {
    return <></>
  }

  return (
    <SwipeableDrawerRightDialog
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      title="Desabilitar a oferta"
      subtitle="Defina as configurações para desativar a oferta"
      /*actions={
                <Stack direction="row" spacing={1} sx={{ p: 1 }} justifyContent="end">
                    <Button
                        variant="outlined"
                        color="error"
                        disabled={sending}
                        onClick={() => setOpen(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        disabled={sending}
                        onClick={handleSave}
                    >
                        {price.disabled ? "Desabilitar" : "Programar"}
                    </Button>
                </Stack>
            }*/
      buttons={[
        { title: 'Cancelar', onClick: () => setOpen(false), type: 'negative' },
        {
          title: price.disabled ? 'Desabilitar' : 'Programar',
          onClick: handleSave,
          type: 'positive',
        },
      ]}
      buttonsDisabled={sending}
    >
      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
        <Box>
          <Typography variant="h6">
            Quando deseja que a oferta seja desabilitada?
          </Typography>
          <RowRadioButtonsGroup
            value={price.disabled}
            onChange={(value) => {
              if (typeof value === 'string') {
                value = UtilFunctions.booleanify(value)
              }
              setPrice({ ...price, disabled: value })
            }}
            items={[
              { label: 'Agora', value: true },
              { label: 'Escolher uma data e hora', value: false },
            ]}
          />
        </Box>

        <Collapse in={!price.disabled}>
          <Stack direction="row" spacing={2}>
            <DatePickerFilter
              label="Data"
              value={price.disableDate}
              setDate={(e) => {
                const newValue = new Date(e)
                if (isValidDateTime(newValue)) {
                  const dateTime = new Date(
                    newValue.getFullYear(),
                    newValue.getMonth(),
                    newValue.getDate(),
                    getHours(price.disableDate),
                    getMinutes(price.disableDate)
                  )
                  if (isValidDateTime(dateTime)) {
                    setPrice({ ...price, disableDate: dateTime })
                  }
                }
              }}
            />
            <TimePickerFilter
              label="Hora"
              value={price.disableDate}
              setDate={(e) => {
                const newValue = new Date(e)
                const now = new Date()
                if (isValidDateTime(newValue)) {
                  const dateTime = new Date(
                    getFullYear(price.disableDate) ?? now.getFullYear(),
                    getMonth(price.disableDate) ?? now.getMonth(),
                    getDate(price.disableDate) ?? now.getDate(),
                    newValue.getHours(),
                    newValue.getMinutes()
                  )
                  if (isValidDateTime(dateTime)) {
                    setPrice({ ...price, disableDate: dateTime })
                  }
                }
              }}
            />
          </Stack>
        </Collapse>

        <Typography>
          Após a oferta ser desabilitada, o link{' '}
          <Chip
            size="small"
            variant="outlined"
            color="warning"
            label={`${payUrl}/${price.codeId}`}
          />{' '}
          não será mais encontrado no checkout.
        </Typography>

        <Box>
          <Typography>
            Selecione uma outra oferta que seja apresentado quando o usuário
            entrar nesse mesmo link.
          </Typography>
          <Select
            fullWidth
            variant="outlined"
            size="small"
            value={
              price.redirectProductPriceId ? price.redirectProductPriceId : ''
            }
            onChange={(e) =>
              setPrice({ ...price, redirectProductPriceId: e.target.value })
            }
            error={errorPrice !== ''}
          >
            {prices
              .filter(
                (p) => !p.isDisabled && !p.disableDate && p.id !== price.id
              )
              .map((p) => (
                <MenuItem key={p.id} value={p.id}>
                  {NumberFunctions.formatMoneyDefault(p.value)}
                </MenuItem>
              ))}
          </Select>
          {errorPrice !== '' && (
            <Typography variant="overline" color={red[700]}>
              {errorPrice}
            </Typography>
          )}
        </Box>
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}

export default ProductPriceDisable
