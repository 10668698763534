export enum TMemberServiceType {
  Cademi = 1,
  TheMembers = 2,
  AstronMembers = 3,
}

export type TMemberService = {
  id: string
  identifier: string
  domain: string
  token: string
  apiKey: string
  webhookCode: string
  type: TMemberServiceType
  platformDefaultPassword?: string | null
}

export type TMemberServicePagination = {
  items: TMemberService[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
}
