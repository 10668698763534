import { createContext, ReactNode, useState } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { TAuthContext, TAuthUser } from "../types/Auth";
import Cookies from "js-cookie";
import { addDays } from "../functions/dateTime";

type Props = {
    children?: ReactNode;
};

const initialValue = {
    user: null,
    stayConnected: true,
    setUser: (newUser: TAuthUser | null) => {},
    setStayConnected: (newStay: boolean) => {},
};

const AuthContext = createContext<TAuthContext>(initialValue);
const cookieUserName = "__apu";
const cookieStay = "__apus";

const AuthProvider = ({ children }: Props) => {
    const userCookie = Cookies.get(cookieUserName);
    const stayCookie = Cookies.get(cookieStay);
    const [user, setAuthUser] = useState<TAuthUser | null>(
        userCookie && userCookie !== "" ? JSON.parse(userCookie) : null
    );
    const [stayConnected, setAuthStayConnected] = useState<boolean>(stayCookie ? JSON.parse(stayCookie) : true);
    const [userLocal, setUserLocal] = useLocalStorage("user", null);

    const setUser = (newUser: TAuthUser | null) => {
        if (newUser) {
            const expires = !stayConnected ? undefined : newUser.exp ? newUser.exp : addDays(1);
            const newUserJson = JSON.stringify(newUser);
            Cookies.set(cookieUserName, newUserJson, { expires });
            Cookies.set(cookieUserName, newUserJson, { expires, domain: process.env.REACT_APP_COOKIE_HOST });
        } else {
            Cookies.remove(cookieUserName);
            Cookies.remove(cookieUserName, { domain: process.env.REACT_APP_COOKIE_HOST });
        }

        setAuthUser(newUser);
    };

    const setStayConnected = (newStay: boolean) => {
        setAuthStayConnected(newStay);
        const expires = addDays(30);
        Cookies.set(cookieStay, JSON.stringify(newStay), { expires });
    };

    if (userLocal) {
        setUser(userLocal as TAuthUser);
        setUserLocal(null);
    }

    return (
        <AuthContext.Provider value={{ user, stayConnected, setUser, setStayConnected }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
