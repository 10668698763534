import { Box } from '@mui/material'
import {
  TCheckoutOrder,
  TCheckoutOrderDetails,
} from '../../../../../core/types/CheckoutOrder'
import ListItemDetail from '../../../../../components/ListItem/ListItemDetail'
import { maskCpfCnpjNumber, maskPhoneNumber } from '../../../../../masks/masks'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'

export interface IOrderDetailCustomerProp {
  checkoutOrder?: TCheckoutOrder
  details?: TCheckoutOrderDetails
}

const OrderDetailCustomer = ({
  checkoutOrder,
  details,
}: IOrderDetailCustomerProp) => {
  return (
    <Box>
      <ListItemDefault title="Nome" value={checkoutOrder?.name} />
      <ListItemDefault title="E-mail" value={checkoutOrder?.email} />
      <ListItemDefault
        title="Celular"
        value={maskPhoneNumber(details?.customerPhone)}
      />
      <ListItemDefault
        title="CPF"
        value={maskCpfCnpjNumber(details?.customerDocument)}
      />
    </Box>
  )
}

export default OrderDetailCustomer
