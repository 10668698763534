import { useEffect, useState } from 'react'
import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Slide,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CheckoutIcon from '@mui/icons-material/ContentCopy'

import { useCurrentTenant } from '../../../core/hooks/useCurrentTenant'
import CopiedSnackbar from '../../../components/Snackbar/CopiedSnackbar'
import Hosts from '../../../core/functions/hosts'

export const TenantIntegrationApi = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [animationState, setAnimationState] = useState(false)
  const [copied, setCopied] = useState(false)
  const [error, setError] = useState('')

  const { data: tenant } = useCurrentTenant()

  useEffect(() => {
    setAnimationState(true)
    return () => setAnimationState(false)
  }, [])

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack
        component={'form'}
        noValidate
        direction={'column'}
        pt={2}
        spacing={3}
        sx={{ width: '100%' }}
      >
        <Alert severity="info">
          <AlertTitle>Dados da API</AlertTitle>
          <Typography variant="body1">Versão atual da API: v1.0</Typography>
          <br />
          <Typography variant="body1">
            A chaves de API é responsável pela acesso aos dados de integração
            com a nossa API. <br />
            <Link
              href="https://celetus.gitbook.io/affiliatespay-api-docs/"
              underline="hover"
            >
              Acesse nossa documentação para saber mais.
            </Link>
          </Typography>
          <br />
        </Alert>
        <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
          <TextField
            fullWidth
            name="apikey"
            label="Chave de acesso"
            type={showPassword ? 'text' : 'password'}
            id="apikey"
            disabled={true}
            value={tenant?.accessKey}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle apikey visibility"
                    onClick={async () => {
                      Hosts.copyTextToClipboard(`${tenant?.accessKey}`)
                        .then(() => setCopied(true))
                        .catch(() => setError('Erro ao copiar'))
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    <CheckoutIcon sx={{ fontSize: '1.2rem' }} />
                  </IconButton>
                  <IconButton
                    aria-label="toggle apikey visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Slide>
      </Stack>

      {error !== '' && (
        <Box sx={{ m: 2 }}>
          <Alert
            onClose={() => {
              setError('')
            }}
            severity="error"
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        </Box>
      )}

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />
    </Box>
  )
}
