import { useCallback, useEffect, useState } from "react";
import { ITenant } from "../types/Tenant";
import { TenantController } from "../controllers/TenantController";
import { IErrorResponse } from "../types/ErrorResponse";

export const useCurrentTenant = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ITenant | null>(null);
  const [error, setError] = useState('');

  const execute = useCallback(async () => {
    if (!loading) {
      try {
        setLoading(true);

        if (error !== '') {
            setError('');
        }

        if (data !== null) {
            setData(null);
        }

        const response = await TenantController.getByCurrentUser();
        const responseError = response as IErrorResponse;
        const responseData = response as ITenant;

        if (responseError.code) {
            setError(responseError.error);
        } else {
            setData(responseData);
        }
      } catch (e) {
        var message = 'Erro não identificado';
        if (typeof e === "string") {
            message = e.toUpperCase();
        } else if (e instanceof Error) {
            const err = e as Error;
            message = err.message;
        }
        setError(message);
      } finally {
          setLoading(false);
      }
    }
  }, [])

  useEffect(() => {
    execute();
  }, [execute]);

  const clear = () => {
    setError('');
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  };

}