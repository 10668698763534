import { Typography } from "@mui/material";

interface IDataTableCellTypographyProps {
    children: React.ReactNode;
    noWrap?: boolean;
}

const DataTableSubcellTypography = ({ children, noWrap }: IDataTableCellTypographyProps) => {
    return (
        <Typography fontWeight={400} fontSize="12px" lineHeight="16px" color="#1C52BD" noWrap={noWrap}>
            {children}
        </Typography>
    );
};

export default DataTableSubcellTypography;
