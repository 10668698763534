import { TProduct } from "./Product";

export enum TCustomerProductStatus {
    Aprovado = 0,
    Cancelado = 1,
    Disputa = 2,
}

export type TCustomerAddress = {
    id: string;
    street: string;
    number: string;
    complement: string;
    zipCode: string;
    neighborhood: string;
    cityId: number;
    city: string;
    stateId: number;
    state: string;
    countryId: number;
    country: string;
    createdDate: Date;
    updatedDate: Date;
}

export type TCustomer = {
    id: string;
    tenantId: string;
    paymentServiceId: number;
    name: string;
    document: string;
    email: string;
    phone: string;
    createdDate: Date;
    updatedDate: Date;
    address: TCustomerAddress;
}

export type TCustomerPagination = {
    items: TCustomer[];
    page: number;
    rowsPerPage: number;
    totalPages: number;
    total: number;
}

export type TCustomerProduct = {
    id: string;
    tenantId: string;
    serviceOrderId: string;
    status: TCustomerProductStatus;
    value: number;
    product: TProduct;
}