import api, { getResponseError } from "../../services/api";
import { addDays, diffDays } from "../functions/dateTime";
import { TCommissionRule, TCommissionRulePagination } from "../types/CommissionRule";
import { IErrorResponse, IResponse } from "../types/ErrorResponse";

interface IGetCommissionRuleProp {
    page: number;
    rows: number;
    search?: string;
}

const getAll = async ({ page, rows, search }: IGetCommissionRuleProp): Promise<TCommissionRulePagination | IErrorResponse> => {
    try {
        var path = `/CommissionRules?PageNumber=${page}&RowsPerPage=${rows}`;

        if (search && search !== '') {
            path += `&Name=${search}`;
        }

        const { data } = await api.get<TCommissionRulePagination>(path);
        if (data) {
            data.items.map((item) => {
                item.createdDate = new Date(item.createdDate);
                if (!item.closingDate && (item.deadline || 0) > 0) {
                    item.closingDate = addDays(item.deadline || 0, item.createdDate);
                }
            });
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const get = async ({ code }: { code: string }): Promise<TCommissionRule | IErrorResponse> => {
    try {
        const { data } = await api.get<TCommissionRule>(`/CommissionRules/${code}`);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const insert = async ({ data }: { data: TCommissionRule }): Promise<IResponse> => {
    try {
        if (data.closingDate) {
            data.deadline = diffDays(data.closingDate, data.createdDate);
        } else {
            data.deadline = 0;
        }

        const { data: response } = await api.post('/CommissionRules', data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
                data: response,
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error: any) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const update = async ({ data }: { data: TCommissionRule }): Promise<IResponse> => {
    try {
        if (data.closingDate) {
            data.deadline = diffDays(data.closingDate, new Date(data.createdDate));
        } else {
            data.deadline = 0;
        }

        const { data: response } = await api.put('/CommissionRules', data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const insertProducts = async ({ data }: { data: { codeId: string, productAffiliationIds: string[] } }): Promise<IResponse> => {
    try {
        await api.put('/CommissionRules/link/products', data);
        return {
            success: true,
            code: 0,
            error: '',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const CommissionRulesController = {
    getAll,
    get,
    insert,
    update,
    insertProducts,
}

export default CommissionRulesController;