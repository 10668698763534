import { Box, Paper } from "@mui/material";

export interface IInvisibleDashboardCardProps {
    visible: boolean;
    variant?: "rounded" | "chip" | "chart";
    size?: "small" | "normal";
}

const InvisibleDashboardCard = ({ visible, variant = "rounded", size = "normal" }: IInvisibleDashboardCardProps) => {
    if (!visible) {
        return <></>;
    }

    if (variant === "chip") {
        return (
            <Box sx={{ width: "50px", height: "32px", py: 0.5 }}>
                <Paper
                    elevation={0}
                    sx={{ width: "100%", height: "100%", borderRadius: "32px", backgroundColor: "#F2F2FF" }}
                />
            </Box>
        );
    }

    if (variant === "chart") {
        return (
            <Box sx={{ width: "100%", height: "250px", p: 3 }}>
                <Paper
                    elevation={0}
                    sx={{ width: "100%", height: "100%", borderRadius: "8px", backgroundColor: "#F2F2FF" }}
                />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                width: size === "small" ? "40px" : "100px",
                height: size === "small" ? "16px" : "32px",
                py: size === "small" ? undefined : 0.5,
            }}
        >
            <Paper
                elevation={0}
                sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#F2F2FF",
                }}
            />
        </Box>
    );
};

export default InvisibleDashboardCard;
