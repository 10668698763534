import React, { useContext, useState } from 'react'
import {
  Box,
  Backdrop,
  CircularProgress,
  Button,
  Stack,
  Tooltip,
  Chip,
  useMediaQuery,
  Typography,
  Modal,
} from '@mui/material'

import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { Link } from 'react-router-dom'
import { useProducts } from '../../../core/hooks/useProducts'

import DataTable, {
  IDataTableColumn,
} from '../../../components/Tables/DataTable'
import Convertions from '../../../core/functions/convertions'
import {
  TProduct,
  TProductStatus,
  TProductTypePayment,
} from '../../../core/types/Product'

import ProductCard from './productCard'
import TablePaginationDefault from '../../../components/Tables/TablePaginationDefault'
import InputSearch from '../../../components/Inputs/InputSearch'
import DataTableHeaderWeb from '../../../components/Tables/DataTableHeaderWeb'
import DataTableHeaderMobile from '../../../components/Tables/DataTableHeaderMobile'
import { HtmlTooltip } from '../../../components/Tooltip/HtmlTooltip'
import { ProductSituationEnum } from '../../../core/types/Situation'
import { AuthContext } from '../../../core/context/AuthContext'
import { TUserType } from '../../../core/types/Auth'
import ProductSituationBlockModal from './ProductSituationBlockModal'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }
)

const getStatusChipStyles = (status: TProductStatus) => {
  const statusStyles = {
    [TProductStatus.Active]: {
      backgroundColor: 'rgba(28, 189, 63, 0.16)',
      color: 'rgba(0, 157, 35, 1)',
    },
    [TProductStatus.Inactive]: {
      backgroundColor: 'rgba(189, 28, 28, 0.16)',
      color: 'rgba(212, 54, 44, 1)',
    },
  }

  return statusStyles[status]
}

const getTypePaymentChipStyles = (typePayment: TProductTypePayment) => {
  const paymentStyles = {
    [TProductTypePayment.OneTimePayment]: {
      backgroundColor: 'rgba(216, 217, 255, 1)',
      color: 'rgba(28, 82, 189, 1)',
    },
    [TProductTypePayment.RecurringSubscription]: {
      backgroundColor: 'rgba(251, 243, 226, 1)',
      color: 'rgba(136, 113, 0, 1)',
    },
  }

  return paymentStyles[typePayment]
}

interface IMyProductsProps {
  isNotTenantBank: boolean
  showNotTenantBank: () => void
}

export default function MyProducts({
  isNotTenantBank,
  showNotTenantBank,
}: IMyProductsProps) {
  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = useState('')
  const [openNew, setOpenNew] = React.useState(false)
  const [status, setStatus] = React.useState<'active' | 'inactive' | 'deleted'>(
    'active'
  )
  const [situationStatus, setSituationStatus] =
    React.useState<ProductSituationEnum | null>(
      isAdmin ? ProductSituationEnum.Pending : null
    )
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  const [windowSize, setWindowSize] = React.useState([
    window.outerWidth,
    window.outerHeight,
  ])

  const oldSearch = React.useRef('')

  const { loading, data, error, execute, clear } = useProducts({
    page,
    rows: rowsPerPage,
    name: search,
    active: status !== 'deleted',
    status:
      status === 'active' ? TProductStatus.Active : TProductStatus.Inactive,
    productSituation: situationStatus,
  })

  const columns: readonly IDataTableColumn<TProduct>[] = [
    {
      id: 'name',
      label: 'Nome',
      width: '70%',
      render(id, value, row) {
        return (
          <Stack direction="row" alignItems="center">
            <img
              src={
                row.imageProductUrl
                  ? row.imageProductUrl
                  : '/assets/logo/logoCeletus.svg'
              }
              style={{
                width: 40,
                height: 40,
                marginRight: 10,
                borderRadius: 6.67,
              }}
            />
            <Typography>{value}</Typography>
            {Convertions.productTypePaymentToString(row.typePayment) ===
              'Assinatura' && (
              <HtmlTooltip title={<Typography>Recorrência</Typography>}>
                <img
                  src={'/assets/icons/arrow/roundArrow.svg'}
                  alt="icon-eyes"
                  style={{
                    width: '15px',
                    height: '15px',
                    marginLeft: '5px',
                  }}
                />
              </HtmlTooltip>
            )}
          </Stack>
        )
      },
    },
    {
      id: 'status',
      label: 'Situação',
      width: '12%',
      render(id, value, row) {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Chip
              label={Convertions.productStatusToString(value)}
              size="small"
              sx={getStatusChipStyles(value)}
            />
          </Stack>
        )
      },
    },
    {
      id: 'memberServiceId',
      label: 'Referência',
      width: '8%',
    },
    {
      id: 'action',
      label: 'Ações',
      width: '5%',
      render(id, value, row) {
        return (
          <Box>
            {row.situation === ProductSituationEnum.Blocked ? (
              <ProductSituationBlockModal row={row} />
            ) : (
              status !== 'deleted' && (
                <Tooltip title="Editar o produto">
                  <Button
                    component={Link}
                    to={`/product/${row.id}`}
                    variant="text"
                    size="small"
                    sx={{
                      px: 1,
                      py: 0.5,
                      m: 0,
                      minWidth: 0,
                      borderRadius: '8px',
                    }}
                  >
                    <img
                      src="/assets/icons/eyes/eyeButtonBlack.svg"
                      alt="action-eye"
                      style={{ fontSize: '1.2rem', color: 'black' }}
                    />
                  </Button>
                </Tooltip>
              )
            )}
          </Box>
        )
      },
    },
  ]

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    if (rows > 0) {
      setRowsPerPage(rows)
    }
    setPage(0)
  }

  React.useEffect(() => {
    window.addEventListener('resize', (ev) => {
      setWindowSize([window.outerWidth, window.outerHeight])
    })
  }, [])

  React.useEffect(() => {
    if (
      data.rowsPerPage &&
      data.rowsPerPage > 0 &&
      data.rowsPerPage !== rowsPerPage
    ) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  return (
    <Box>
      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <Alert onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Box>
      )}

      {isSmallScreen ? (
        <Box>
          <DataTableHeaderMobile
            totalItems={data.total}
            setSearch={setSearch}
            executeSearch={execute}
            status={status}
            setStatus={setStatus}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Stack spacing={1}>
            {data.items.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                productImage={
                  product.imageProductUrl
                    ? product.imageProductUrl
                    : '/assets/logo/logoCeletus.svg'
                }
              />
            ))}
          </Stack>
          <TablePaginationDefault
            count={data.total}
            rowsPerPage={rowsPerPage}
            page={page}
            hasNewCustomHeader
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      ) : (
        <DataTable
          columns={columns}
          data={data}
          page={page}
          rowsPerPage={rowsPerPage}
          headerComponent={
            <DataTableHeaderWeb
              labelItems="produtos"
              totalItems={data.total}
              setSearch={setSearch}
              executeSearch={execute}
              status={status}
              setStatus={setStatus}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              situationStatus={situationStatus}
              setSituationStatus={setSituationStatus}
            />
          }
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          hasNewCustomHeader
        />
      )}

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}
