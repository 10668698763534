import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { IWithdrawDetail } from '../../../core/types/Tenant'

export interface WithdrawGridProps {
  data: IWithdrawDetail[]
}

interface Column {
  id: 'detail' | 'registerDate'
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'detail', label: 'Registro', minWidth: 100 },
  { id: 'registerDate', label: 'Data', minWidth: 150 },
]

export const WithdrawGridDetails = ({ data }: WithdrawGridProps) => {
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align="center"
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={`keyRow1_${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="left">
                {row.detail}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {row.registerDate !== undefined && row.registerDate !== null
                  ? new Date(row.registerDate).toLocaleString('pt-BR')
                  : ''}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
