import { useContext } from "react";
import { AuthContext } from "../core/context/AuthContext";
import { Navigate } from "react-router-dom";
import SignIn from "../pages/SignIn";

const AutoAuthRoutes = () => {
    const { user } = useContext(AuthContext);

    if (user !== null) {
        return <Navigate to="/dashboard" replace />;
    }

    return <SignIn />;
};

export default AutoAuthRoutes;