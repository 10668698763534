import { ValueOrPercentageGroupButtonsEnum } from "../../components/Buttons/ValueOrPercentageGroupButtons";
import { TChartRange, TChartType, TComissionCard, TDailyCommissions } from "../types/Dashboard";

const chartTypeBrazil = {
    src: "/assets/dashboard/types/brazil.png",
    code: TChartType.Brazil,
    name: "Brasil",
};

const chartTypeWorld = {
    src: "/assets/dashboard/types/world.png",
    code: TChartType.World,
    name: "Mundo",
};

const chartTypes = [chartTypeBrazil, chartTypeWorld];

const chartRanges = [
    {
        code: TChartRange.last180,
        name: "Últimos 180 dias",
    },
    {
        code: TChartRange.last90,
        name: "Últimos 90 dias",
    },
    {
        code: TChartRange.last30,
        name: "Últimos 30 dias",
    },
    {
        code: TChartRange.last7,
        name: "Últimos 7 dias",
    },
    /*{
        code: TChartRange.custom,
        name: "Personalizado",
    },*/
];

const dailyCommissions = ["Daily", "Weekly", "Monthly"];

const getPercentage = (value: number, total: number) => {
    const percentage = value * 100 / total;
    return percentage;
}

const getSelectedValue = (selected: ValueOrPercentageGroupButtonsEnum, value: number, total: number) => {
    if (selected === ValueOrPercentageGroupButtonsEnum.Value) {
        return value;
    }

    const percentage = getPercentage(value, total);
    return percentage;
}

const getDailyCommissions = (value: TDailyCommissions) => {
    return dailyCommissions[value];
}

const DashboardFunctions = {
    chartTypes,
    chartRanges,
    dailyCommissions,
    getPercentage,
    getSelectedValue,
    getDailyCommissions,
}

export default DashboardFunctions;