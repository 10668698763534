import {
  Box,
  Fade,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  TProductLink,
  TProductLinkDuration,
  productLinkCategories,
  productLinkDurations,
} from '../../../../../core/types/Product'
import Convertions from '../../../../../core/functions/convertions'
import { red } from '@mui/material/colors'
import SwitchRound from '../../../../../components/Switch/SwitchRound'
import { TUserType } from '../../../../../core/types/Auth'
import { AuthContext } from '../../../../../core/context/AuthContext'
import { useContext } from 'react'

export interface IProductLinkRegisterProps {
  embedded: boolean
  link: TProductLink
  errorIdentifier?: string
  errorUrl?: string
  setLink: (value: TProductLink) => void
}

const ProductLinkRegister = ({
  embedded,
  link,
  errorIdentifier,
  errorUrl,
  setLink,
}: IProductLinkRegisterProps) => {
  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  return (
    <Stack direction="column" spacing={2} sx={{ p: embedded ? 0 : 2 }}>
      {!embedded && (
        <Box>
          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            alignItems="center"
          >
            <Typography variant="caption" fontWeight={500}>
              Identificação
            </Typography>
          </Stack>
          <TextField
            fullWidth
            required
            size="small"
            type="text"
            disabled={(link.id !== '' && link.codeId === '') || isAdmin}
            inputProps={{ maxLength: 50 }}
            InputLabelProps={{ shrink: true }}
            value={link.identifier}
            onChange={(e) => setLink({ ...link, identifier: e.target.value })}
            error={errorIdentifier !== undefined && errorIdentifier !== ''}
          />
          {errorIdentifier !== '' && (
            <Typography variant="caption" color={red[700]}>
              {errorIdentifier}
            </Typography>
          )}
        </Box>
      )}
      <Box>
        <Stack
          direction="row"
          spacing={1}
          alignContent="center"
          alignItems="center"
        >
          <Typography variant="caption" fontWeight={500}>
            URL {embedded ? ' de página da venda' : ''}
          </Typography>
        </Stack>
        <TextField
          fullWidth
          required
          disabled={isAdmin}
          size="small"
          type="text"
          inputProps={{ maxLength: 500 }}
          InputLabelProps={{ shrink: true }}
          value={link.url}
          onChange={(e) => setLink({ ...link, url: e.target.value })}
          error={errorUrl !== undefined && errorUrl !== ''}
        />
        {errorUrl !== undefined && errorUrl !== '' && (
          <Typography variant="caption" color={red[700]}>
            {errorUrl}
          </Typography>
        )}
      </Box>
      {!embedded && (
        <FormControlLabel
          control={
            <SwitchRound
              disabled={isAdmin}
              sx={{ m: 1 }}
              checked={link.otherProducts}
              onChange={(e) =>
                setLink({ ...link, otherProducts: e.target.checked })
              }
            />
          }
          label="Divulgação com outros produtos"
        />
      )}
      {!embedded && (
        <Fade in={!link.otherProducts} mountOnEnter unmountOnExit>
          <Box>
            <Stack
              direction="row"
              spacing={1}
              alignContent="center"
              alignItems="center"
            >
              <Typography variant="caption" fontWeight={500}>
                Duração
              </Typography>
            </Stack>
            <Select
              fullWidth
              variant="outlined"
              size="small"
              labelId="select-label"
              disabled={(link.id !== '' && link.codeId === '') || isAdmin}
              value={link.duration || TProductLinkDuration.Unknown}
              onChange={(e) =>
                setLink({ ...link, duration: Number(e.target.value) })
              }
            >
              {productLinkDurations.map((duration) => (
                <MenuItem key={Number(duration)} value={Number(duration)}>
                  {Convertions.productLinkDurationToString(duration)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Fade>
      )}
      {!embedded && (
        <Box>
          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            alignItems="center"
          >
            <Typography variant="caption" fontWeight={500}>
              Categoria
            </Typography>
          </Stack>
          <Select
            fullWidth
            variant="outlined"
            size="small"
            labelId="select-label"
            disabled={(link.id !== '' && link.codeId === '') || isAdmin}
            value={link.category}
            onChange={(e) =>
              setLink({ ...link, category: Number(e.target.value) })
            }
          >
            {productLinkCategories.map((category) => (
              <MenuItem key={Number(category)} value={Number(category)}>
                {Convertions.productLinkCategoryToString(category)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
    </Stack>
  )
}

export default ProductLinkRegister
