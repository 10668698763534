import * as React from 'react'
import { Box, Chip, Typography } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import InputSearch from '../../../components/Inputs/InputSearch'
import UserSetting from './UserSetting'
import { TTenantSettings } from '../../../core/types/Tenant'
import { useTenantSettings } from '../../../core/hooks/useTenantSettings'
import { TenantController } from '../../../core/controllers/TenantController'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import DataTable, {
  IDataTableColumn,
} from '../../../components/Tables/DataTable'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }
)

const userSettingsTrueOrFalse: Record<
  string,
  { label: string; backgroundColor: string; color: string }
> = {
  ['true']: {
    label: 'Permite',
    backgroundColor: 'rgba(28, 189, 63, 0.16)',
    color: 'rgba(0, 157, 35, 1)',
  },
  ['false']: {
    label: 'Bloqueado',
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
}

const columns: readonly IDataTableColumn<TTenantSettings>[] = [
  { id: 'tenantCode', label: 'Código', minWidth: 100 },
  { id: 'recipientId', label: 'Código do Recebedor', minWidth: 100 },
  { id: 'tenantName', label: 'Nome', minWidth: 120 },
  { id: 'tenantEmail', label: 'E-mail', minWidth: 120 },
  {
    id: 'immediateWithdrawal',
    label: 'Saque imediato',
    minWidth: 80,
    align: 'right',
    format: (value) => `${value}%`,
  },
  {
    id: 'plataformFee',
    label: 'Taxa específica',
    minWidth: 80,
    align: 'right',
    format: (value) => `${value}%`,
  },
  {
    id: 'salesEnabled',
    label: 'Vendas',
    minWidth: 80,
    align: 'center',
    render(id, value, row) {
      const { label, ...sx } = userSettingsTrueOrFalse[value.toString()]
      return <Chip label={label} size="small" sx={sx} />
    },
  },
  {
    id: 'withdrawlsEnabled',
    label: 'Saques',
    minWidth: 80,
    align: 'center',
    render(id, value, row) {
      const { label, ...sx } = userSettingsTrueOrFalse[value.toString()]
      return <Chip label={label} size="small" sx={sx} />
    },
  },
]

const tenantSettingsDefault: TTenantSettings = {
  id: '',
  tenantId: '',
  immediateWithdrawal: 0.0,
  plataformFee: 0.0,
  enableAnticipationD15: false,
  anticipationD15Fee: 0,
  enableAnticipationD2: false,
  anticipationD2Fee: 0,
}

export default function UserSettingsPage() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [current, setCurrent] = React.useState<TTenantSettings>(
    tenantSettingsDefault
  )
  const [sending, setSending] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [errorSending, setErrorSending] = React.useState('')
  const [message, setMessage] = React.useState('')

  const { loading, data, error, execute, clear } = useTenantSettings({
    page,
    rows: rowsPerPage,
    search,
  })

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleChangeUserSetting = (tenantSettings?: TTenantSettings) => {
    tenantSettings ??= tenantSettingsDefault
    setCurrent({ ...tenantSettings })
    setOpen(true)
  }

  const handleCloseUserSetting = () => {
    setOpen(false)
    setCurrent({ ...tenantSettingsDefault })
  }

  const handleSaveUserSetting = async () => {
    if (current) {
      setSending(true)
      try {
        var response
        if (current.id === '') {
          response = await TenantController.insertSettings({ data: current })
        } else {
          response = await TenantController.updateSettings({ data: current })
        }
        if (!response.success) {
          setErrorSending(response.error)
          return
        }
        setSuccess(true)
        handleCloseUserSetting()
        execute()
      } finally {
        setSending(false)
      }
    }
  }

  return (
    <Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        Configurações do usuário
      </Typography>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <Alert onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Box>
      )}

      <InputSearch
        name="Filtrar por nome"
        setSearch={setSearch}
        execute={execute}
      />

      <DataTable
        columns={columns}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        onItemClick={handleChangeUserSetting}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <UserSetting
        open={open}
        sending={false}
        tenantSettings={current}
        setOpen={setOpen}
        setTenantSettings={setCurrent}
        handleClose={handleCloseUserSetting}
        handleSave={handleSaveUserSetting}
        setSending={setSending}
        setSuccess={setSuccess}
        setError={setErrorSending}
        setMessage={setMessage}
        executeUserSettings={execute}
      />

      <LoadingBackdrop open={loading || sending} />

      <SuccessSnackbar open={message !== ''} onClose={() => setMessage('')}>
        {message}
      </SuccessSnackbar>

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        {current.id !== '' ? 'Alterado ' : 'Inserido '} com sucesso
      </SuccessSnackbar>
    </Box>
  )
}
