import { IDataTableColumn } from '../../../../components/Tables/DataTable'
import { checkoutOrderColumnsDefault } from './CheckoutOrderColumnsDefault'
import { checkoutOrderStatusTimeColumn } from '../CheckoutOrderConsts'
import { HtmlTooltip } from '../../../../components/Tooltip/HtmlTooltip'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import Hosts from '../../../../core/functions/hosts'
import OrderFunctions from '../../../../core/functions/OrderFunctions'
import { TLostSale } from '../../../../core/types/LostSale'
import LostSaleController from '../../../../core/controllers/LostSaleController'
import {
  dayAndMonthString,
  timeString,
} from '../../../../core/functions/dateTime'

export function CheckoutOrderColumnsAbandoned(
  setCopied: (value: boolean) => void,
  execute: () => void
) {
  const defaultColumns = checkoutOrderColumnsDefault.filter(
    (x) => !['utmCampaign'].includes(x.id)
  )
  const columns: IDataTableColumn<TLostSale>[] = [
    {
      id: 'createdDate',
      label: 'Criado em',
      render(id, value, row) {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Box width="24px" height="24px">
              <img
                src={`/assets/icons/check/${!row.reachedOutForAbandonedOrder ? 'un' : ''}checked.svg`}
                alt="check"
              />
            </Box>
            <Stack direction="column">
              <Typography
                fontFamily="Montserrat"
                fontWeight={400}
                fontSize="14px"
                lineHeight="18px"
              >
                {dayAndMonthString(value)}
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontWeight={400}
                fontSize="12px"
                lineHeight="16px"
                color="#707275"
              >
                {timeString(value)}
              </Typography>
            </Stack>
          </Stack>
        )
      },
    },
    ...defaultColumns,
    checkoutOrderStatusTimeColumn,
    {
      id: 'codeId',
      label: 'Checkout',
      minWidth: 60,
      render(id, value, row) {
        const url = OrderFunctions.getUrlCheckoutAbandoned(row)
        return (
          <HtmlTooltip title="Esse link trás todas as informações do usuário no checkout, aumentando a taxa de recuperação.">
            <IconButton
              onClick={async (event) => {
                event.stopPropagation()
                await Hosts.copyTextToClipboard(url)
                setCopied(true)
              }}
            >
              <img src="/assets/icons/actions/link.svg" alt="link" />
            </IconButton>
          </HtmlTooltip>
        )
      },
    },
    {
      id: 'phone',
      label: 'Ação',
      minWidth: 60,
      render(id, value, row) {
        return (
          <IconButton
            onClick={async (event) => {
              event.stopPropagation()
              Hosts.openNewBlank(OrderFunctions.getTextWhatsAppAbandoned(row))

              if (!row.reachedOutForAbandonedOrder) {
                const resp =
                  await LostSaleController.reachedOutForAbandonedOrder({
                    orderId: row.id ?? '',
                  })
                if (resp.success) {
                  execute()
                }
              }
            }}
          >
            <img src="/assets/icons/actions/whatsapp.svg" alt="link" />
          </IconButton>
        )
      },
    },
  ]
  return columns
}
