import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
  Box,
  Backdrop,
  CircularProgress,
  Button,
  Snackbar,
  Chip,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import { TAffiliatedStatus } from '../../core/types/Affiliated';
import { useAffiliatedsBlocked } from '../../core/hooks/useAffiliatedsBlocked';
import AffiliationController from '../../core/controllers/AffiliationController';
import InputSearch from '../../components/Inputs/InputSearch';
import NumberFunctions from '../../core/functions/NumberFunctions';
import SuccessSnackbar from '../../components/Snackbar/SuccessSnackbar';
import ErrorSnackbar from '../../components/Snackbar/ErrorSnackbar';
import LoadingBackdrop from '../../components/Loading/LoadingBackdrop';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface Column {
  id: 'tenantName' | 'tenantCodeId' | 'email' | 'productName' | 'status' | 'action' | 'commissionPercentage';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'tenantCodeId', label: 'Código', minWidth: 100 },
  { id: 'tenantName', label: 'Afiliado', minWidth: 120 },
  { id: 'email', label: 'Email', minWidth: 120 },
  {
    id: 'productName',
    label: 'Produto',
    minWidth: 100,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
  },
  {
    id: 'commissionPercentage',
    label: 'Comissão',
    minWidth: 100,
    align: 'right',
    format: (value) => NumberFunctions.toPercentage(value),
  },
  {
    id: 'action',
    label: 'Ações',
    minWidth: 100,
  },
];

export const BlockedAffiliateds = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [success, setSuccess] = React.useState(false);
  const [errorUpdate, setErrorUpdate] = React.useState('');
  const [sending, setSending] = React.useState(false);

  const oldSearch = React.useRef("");

  const { loading, data, error, execute, clear } = useAffiliatedsBlocked({
    page,
    rows: rowsPerPage,
    search

  });

  useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage);
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search;
      setPage(0);
    }
  }, [data]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleStatusLabel = (status: TAffiliatedStatus) => {
    switch (status) {
      case TAffiliatedStatus.Accepted: return 'Aceito';
      case TAffiliatedStatus.Pending: return 'Pendente';
      case TAffiliatedStatus.Refused: return 'Recusado';
      case TAffiliatedStatus.Blocked: return 'Bloqueado';
      default: return '';
    }
  }

  const handleAcceptedAffiliated = async (id?: string) => {
    if (id === "" || id === undefined) return;
    setSending(true);
    setSuccess(false);
    setErrorUpdate('');

    try {
      var response = await AffiliationController.updateAffiliatedStatus({ id, status: TAffiliatedStatus.Accepted });
      if (!response.success) {
        setErrorUpdate(response.error);
      } else {
        setSuccess(true);
        await execute();
      }
    } finally {
      setSending(false);
    }
  }

  return (
    <Box>
      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <Alert
            onClose={clear}
            severity="error"
            sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Box>
      )}

      <InputSearch
        name='Filtrar por nome, email ou produto'
        setSearch={setSearch}
        execute={execute}
      />

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        if (column.id === 'action') {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Button
                                variant="contained"
                                size='small'
                                startIcon={<CheckIcon />}
                                color="success"
                                onClick={() => handleAcceptedAffiliated(row.id)}
                              >
                                Aprovar/Desbloquear
                              </Button>
                            </TableCell>
                          );
                        }
                        if (column.id === 'status') {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Chip
                                size='small'
                                color={row.status === TAffiliatedStatus.Blocked ? "error" : row.status === TAffiliatedStatus.Refused ? "warning" : "primary"}
                                label={handleStatusLabel(row.status)}
                              />
                            </TableCell>
                          );
                        }
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={data.totalPages * rowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      
      <Box sx={{ height: 80 }} />
      
      <LoadingBackdrop open={loading || sending} />
      
      <ErrorSnackbar
        open={errorUpdate !== ''}
        onClose={() => setErrorUpdate('')}
      >
        {errorUpdate}
      </ErrorSnackbar>
      
      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Alterado com sucesso
      </SuccessSnackbar>
    </Box >
  );
}
