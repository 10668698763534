import * as React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
//import ptBR from 'dayjs/locale/pt-br'
import 'dayjs/locale/pt-br'
import dayjs from 'dayjs'

export interface IBasicDatePickerProps {
  label?: string | undefined
  value?: Date | null
  fullWidth?: boolean
  disabled?: boolean
  setValue: (value?: Date | null) => void
}

export default function BasicDatePicker({
  label,
  value,
  fullWidth = true,
  disabled,
  setValue,
}: IBasicDatePickerProps) {
  return (
    <LocalizationProvider
      /* adapterLocale={ptBR} */
      adapterLocale="pt-br"
      dateAdapter={AdapterDayjs}
    >
      <DatePicker
        label={label}
        views={['year', 'month', 'day']}
        format="DD/MM/YYYY"
        slotProps={{
          textField: {
            size: 'small',
            fullWidth,
            placeholder: 'DD/MM/AAAA',
          },
        }}
        disabled={disabled}
        value={value ? dayjs(value) : null}
        onChange={(v, e) => {
          if (v) {
            const date = new Date(v.toDate())
            setValue(date)
          } else {
            setValue(null)
          }
        }}
      />
    </LocalizationProvider>
  )
}
