import {
    Box,
    Button,
    Fab,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import InputSearch from "../../components/Inputs/InputSearch";
import { useCommissionRules } from "../../core/hooks/useCommissionRules";
import React from "react";
import TablePaginationDefault from "../../components/Tables/TablePaginationDefault";
import { dateString, dateTimeString } from "../../core/functions/dateTime";
import Convertions from "../../core/functions/convertions";
import { TCommissionRule, TCommissionRuleType } from "../../core/types/CommissionRule";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CommissionRuleDetails from "./CommissionRule";
import NumberFunctions from "../../core/functions/NumberFunctions";
import CommissionRulesController from "../../core/controllers/CommissionRuleController";
import LoadingBackdrop from "../../components/Loading/LoadingBackdrop";
import ErrorSnackbar from "../../components/Snackbar/ErrorSnackbar";
import SuccessSnackbar from "../../components/Snackbar/SuccessSnackbar";
import { useProductAffiliations } from "../../core/hooks/useProductAffiliations";

interface Column {
    id:
        | "createdDate"
        | "closingDate"
        | "identifier"
        | "ruleType"
        | "commissionPercentage"
        | "globalCommissionPercentage"
        | "action";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: "createdDate", label: "Data do início", minWidth: 80 },
    { id: "closingDate", label: "Data do fim", minWidth: 80 },
    {
        id: "identifier",
        label: "Identificação",
        minWidth: 100,
    },
    {
        id: "ruleType",
        label: "Tipo",
        minWidth: 80,
    },
    {
        id: "commissionPercentage",
        label: "Comissão",
        minWidth: 70,
        align: "right",
        format: (value) => NumberFunctions.toPercentage(value),
    },
    {
        id: "globalCommissionPercentage",
        label: "Comissão Global",
        minWidth: 70,
        align: "right",
        format: (value) => NumberFunctions.toPercentage(value),
    },
    {
        id: "action",
        label: "Ações",
        minWidth: 100,
    },
];

const commisionRuleDefault: TCommissionRule = {
    codeId: "",
    identifier: "",
    commissionPercentage: 0,
    globalCommissionPercentage: 0,
    deadline: 0,
    ruleMeta: 0,
    ruleType: TCommissionRuleType.Sales,
    createdDate: new Date(),
    closingDate: null,
};

const CommissionRulesPage = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = React.useState("");

    const [open, setOpen] = React.useState(false);
    const [current, setCurrent] = React.useState<TCommissionRule>(commisionRuleDefault);

    const [sending, setSending] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [errorSending, setErrorSending] = React.useState("");

    const oldSearch = React.useRef("");

    const { data: products, loading: loadingProducts } = useProductAffiliations();
    const { loading, data, error, execute, clear } = useCommissionRules({
        page,
        rows: rowsPerPage,
        search,
    });

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (rows: number) => {
        setRowsPerPage(rows);
        setPage(0);
    };

    const handleOpen = (commission: TCommissionRule) => {
        setCurrent({ ...commission });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrent(commisionRuleDefault);
    };

    const saveCommissionRule = async () => {
        if (!current) {
            setErrorSending("Crie ou selecione uma regra de comissão");
            return;
        }

        setSending(true);
        setSuccess(false);
        setErrorSending("");
        try {
            var response;

            if (current.codeId === "") {
                response = await CommissionRulesController.insert({ data: current });
            } else {
                response = await CommissionRulesController.update({ data: current });
            }

            if (!response.success) {
                setErrorSending(response.error);
            } else {
                setSuccess(true);
                handleClose();
                execute();
            }
        } finally {
            setSending(false);
        }
    };

    const saveCommissionRuleProducts = async (productAffiliationIds: string[]) => {
        if (!current) {
            setErrorSending("Crie ou selecione uma regra de comissão");
            return;
        }

        setSending(true);
        setSuccess(false);
        setErrorSending("");
        try {
            const response = await CommissionRulesController.insertProducts({
                data: {
                    codeId: current.codeId,
                    productAffiliationIds,
                },
            });
            if (!response.success) {
                setErrorSending(response.error);
            } else {
                setSuccess(true);
            }
        } finally {
            setSending(false);
        }
    };

    React.useEffect(() => {
        if (data.rowsPerPage !== rowsPerPage) {
            setRowsPerPage(data.rowsPerPage);
        }

        if (oldSearch.current !== search) {
            oldSearch.current = search;
            setPage(0);
        }
    }, [data]);

    return (
        <Box>
            <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
                Regras de Comissões
            </Typography>

            <Stack spacing={2}>
                <InputSearch name="Pesquisar por identificação" setSearch={setSearch} execute={execute} />

                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.items.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={dateTimeString(row.createdDate)}
                                        >
                                            {columns.map((column) => {
                                                if (column.id === "action") {
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            <Button
                                                                variant="text"
                                                                size="small"
                                                                startIcon={<EditIcon />}
                                                                onClick={() => handleOpen(row)}
                                                            >
                                                                Editar
                                                            </Button>
                                                        </TableCell>
                                                    );
                                                }
                                                if (column.id === "createdDate") {
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {dateString(row.createdDate)}
                                                        </TableCell>
                                                    );
                                                }
                                                if (column.id === "closingDate") {
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {!row.closingDate ? "Eterno" : dateString(row.closingDate)}
                                                        </TableCell>
                                                    );
                                                }
                                                if (column.id === "ruleType") {
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {Convertions.commissionRuleTypeToString(row.ruleType)}
                                                        </TableCell>
                                                    );
                                                }
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === "number"
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePaginationDefault
                        count={data.total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <Box sx={{ height: 80 }} />
                <Fab
                    sx={{
                        position: "fixed",
                        bottom: 16,
                        right: 16,
                    }}
                    aria-label={"Nova regra de comissão"}
                    color="primary"
                    onClick={() => handleOpen(commisionRuleDefault)}
                >
                    <AddIcon />
                </Fab>
            </Stack>

            <CommissionRuleDetails
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
                setCommissionRule={setCurrent}
                saveCommissionRule={saveCommissionRule}
                saveCommissionRuleProducts={saveCommissionRuleProducts}
                commission={current}
                products={products}
            />

            <LoadingBackdrop open={loading || sending || loadingProducts} />

            <ErrorSnackbar open={errorSending !== ""} onClose={() => setErrorSending("")}>
                {error}
            </ErrorSnackbar>

            <SuccessSnackbar open={success} autoHideDuration={2000} onClose={() => setSuccess(false)}>
                Concluído com sucesso
            </SuccessSnackbar>
        </Box>
    );
};

export default CommissionRulesPage;
