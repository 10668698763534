import React, { useState, useEffect } from 'react'
import { Box, Button, Stack, TextField } from '@mui/material'
import {
  TCheckoutOrder,
  TCheckoutOrderDetails,
} from '../../../../../core/types/CheckoutOrder'
import ListItemDetail from '../../../../../components/ListItem/ListItemDetail'
import OrderController from '../../../../../core/controllers/OrderController'

export interface IOrderDetailTrackingProp {
  checkoutOrder?: TCheckoutOrder
  details?: TCheckoutOrderDetails
  setSending: (value: boolean) => void
  setSuccess: (value: boolean) => void
  setError: (value: string) => void
}

const OrderDetailTracking = ({
  checkoutOrder,
  details,
  setSending,
  setSuccess,
  setError,
}: IOrderDetailTrackingProp) => {
  const [data, setData] = useState({ ...details })

  const submit = async () => {
    setSending(true)
    setSuccess(false)
    setError('')
    try {
      var response = await OrderController.updateTracking({
        data: {
          checkoutOrderId: checkoutOrder?.id,
          src: data?.src,
          sck: data?.sck,
          utm_campaign: data?.utm_campaign,
          utm_source: data?.utm_source,
          utm_medium: data?.utm_medium,
          utm_content: data?.utm_content,
          utm_term: data?.utm_term,
        },
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
      }
    } finally {
      setSending(false)
    }
  }

  useEffect(() => {
    if (details) {
      setData({ ...details })
    }
  }, [details, setData])

  return (
    <Box sx={{ pt: 1 }}>
      <ListItemDetail
        title="src"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.src}
            onChange={(e) => setData({ ...data, src: e.target.value })}
          />
        }
        viewDivider={false}
        py={1}
      />
      <ListItemDetail
        title="sck"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.sck}
            onChange={(e) => setData({ ...data, sck: e.target.value })}
          />
        }
        viewDivider={false}
        py={1}
      />
      <ListItemDetail
        title="utm_campaign"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.utm_campaign}
            onChange={(e) => setData({ ...data, utm_campaign: e.target.value })}
          />
        }
        viewDivider={false}
        py={1}
      />
      <ListItemDetail
        title="utm_source"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.utm_source}
            onChange={(e) => setData({ ...data, utm_source: e.target.value })}
          />
        }
        viewDivider={false}
        py={1}
      />
      <ListItemDetail
        title="utm_medium"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.utm_medium}
            onChange={(e) => setData({ ...data, utm_medium: e.target.value })}
          />
        }
        viewDivider={false}
        py={1}
      />
      <ListItemDetail
        title="utm_content"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.utm_content}
            onChange={(e) => setData({ ...data, utm_content: e.target.value })}
          />
        }
        viewDivider={false}
        py={1}
      />
      <ListItemDetail
        title="utm_term"
        value={
          <TextField
            fullWidth
            size="small"
            type="text"
            value={data.utm_term}
            onChange={(e) => setData({ ...data, utm_term: e.target.value })}
          />
        }
        viewDivider={false}
        py={1}
      />
      <Stack sx={{ pt: 3, alignItems: 'end', justifyContent: 'end' }}>
        <Button variant="contained" onClick={submit}>
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}

export default OrderDetailTracking
