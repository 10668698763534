import { Stack, InputLabel } from '@mui/material'
import InputSearch from '../Inputs/InputSearch'
import SelectInput from '../SelectInput/SelectInput'

import { statusOptions } from './constans'
import { DataTableHeaderProps } from './DataTableHeader'

const HeaderMobile: React.FC<DataTableHeaderProps> = ({
  labelItems = 'registros',
  totalItems,
  status,
  rowsPerPage,
  setSearch,
  executeSearch,
  setStatus,
  handleChangeRowsPerPage,
}) => {
  return (
    <>
      <Stack direction="column" alignItems="center" spacing={1} sx={{ mb: 2 }}>
        <InputSearch
          id="mobileSearchMyProducts"
          key="mobileSearchMyProducts"
          name="Nome do produto"
          size="small"
          width="100%"
          setSearch={setSearch}
          execute={executeSearch}
          adornmentToStart
          removeLabel
        />
        {status && setStatus && (
          <SelectInput
            width="100%"
            value={status}
            onChange={(e) =>
              setStatus(e.target.value as 'active' | 'inactive' | 'deleted')
            }
            options={statusOptions}
          />
        )}
      </Stack>
      <Stack direction="column" alignItems="center" sx={{ mt: 2, mb: 1 }}>
        <InputLabel id="total-items-label" sx={{ width: '100%' }}>
          {totalItems} {labelItems} encontrados
        </InputLabel>
      </Stack>
    </>
  )
}

export default HeaderMobile
