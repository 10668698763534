import {
  TOccurrenceTypeEnum,
  TOccurrenceEventEnum,
  TOccurrenceStatusEnum,
} from '../types/OccurrenceLog'

const statusType = (value?: TOccurrenceTypeEnum) => {
  switch (value) {
    case TOccurrenceTypeEnum.Info:
      return 'Informação'
    case TOccurrenceTypeEnum.Warnning:
      return 'Aviso'
    case TOccurrenceTypeEnum.Error:
      return 'Erro'
    default:
      return value ?? ''
  }
}

const statusEvent = (value?: TOccurrenceEventEnum) => {
  switch (value) {
    case TOccurrenceEventEnum.Withdraws:
      return 'Saque'
    case TOccurrenceEventEnum.MemberService:
      return 'Área de membros'
    default:
      return value ?? ''
  }
}

const statusString = (value?: TOccurrenceStatusEnum) => {
  switch (value) {
    case TOccurrenceStatusEnum.Active:
      return 'Ativo'
    case TOccurrenceStatusEnum.Inactive:
      return 'Inativo'
    case TOccurrenceStatusEnum.Resolved:
      return 'Resolvido'
    case TOccurrenceStatusEnum.Unresolved:
      return 'Não resolvido'
    default:
      return value ?? ''
  }
}

const OccurrenceLogFunction = {
  statusType,
  statusEvent,
  statusString,
}

export default OccurrenceLogFunction
