import {
  Alert,
  Button,
  ButtonGroup,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useContext, useEffect, useState } from 'react'
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form'
import { TProduct } from '../../../../core/types/Product'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import { TCustomCheckout } from '../../../../core/types/CustomCheckout'
import CustomCheckoutController from '../../../../core/controllers/CustomCheckoutController'
import { useCustomCheckoutsByProduct } from '../../../../core/hooks/useCustomCheckoutsByProduct'
import ProductCustomCheckoutRegister from '../../../../components/Product/CustomCheckout/ProductCustomCheckoutRegister'
import Hosts from '../../../../core/functions/hosts'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import { TUserType } from '../../../../core/types/Auth'
import { AuthContext } from '../../../../core/context/AuthContext'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
export interface IProductCustomCheckoutProp {
  control: Control<TProduct, any>
  watch: UseFormWatch<TProduct>
  setValue: UseFormSetValue<TProduct>
  setError: (error: string) => void
}

const customCheckoutDefault: TCustomCheckout = {
  id: '',
  name: '',
  productId: '',
  productName: '',
  prices: [],
  defaultValue: false,
}

const ProductCustomsCheckout = ({
  control,
  watch,
  setValue,
  setError,
}: IProductCustomCheckoutProp): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState<TCustomCheckout>({
    ...customCheckoutDefault,
  })
  const [openConfirmDelete, setOpenConfirmeDelete] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorName, setErrorName] = useState('')
  const [errorPrices, setErrorPrices] = useState('')
  const [deleteId, setDeleteId] = useState('')

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const { register } = control
  const { fields: prices } = useFieldArray({
    control,
    name: `prices`,
  })

  const productId = watch('id')

  const { loading, data, error, execute, clear } = useCustomCheckoutsByProduct({
    productId,
  })

  const setCurrentDefault = () => {
    setCurrent({ ...customCheckoutDefault })
  }

  const handleChangeEdit = async (productCustomCheckout?: TCustomCheckout) => {
    productCustomCheckout ??= { ...customCheckoutDefault }
    if (productCustomCheckout.id !== '') {
      setSending(true)
      try {
        const response = await CustomCheckoutController.get({
          id: productCustomCheckout.id,
        })
        if (response.data) {
          productCustomCheckout = response.data
        }
      } finally {
        setSending(false)
      }
    }
    setCurrent(productCustomCheckout)
    setOpen(true)
  }

  const handleChangeClose = () => {
    setCurrentDefault()
    setOpen(false)
  }

  const handleChangeSave = async () => {
    if (errorName !== '') {
      setErrorName('')
    }
    if (errorPrices !== '') {
      setErrorPrices('')
    }

    let isErrors = false
    if (!current.name || current.name === '') {
      setErrorName('Campo obrigatório')
      isErrors = true
    }

    if (!current.defaultValue && current.prices.length <= 0) {
      setErrorPrices('Selecione no mínimo uma oferta')
      isErrors = true
    }

    if (isErrors) {
      return
    }

    current.productId = productId

    setSending(true)
    try {
      var response
      if (current.id === '') {
        response = await CustomCheckoutController.insert({ data: current })
        current.id = response.data
      } else {
        response = await CustomCheckoutController.update({ data: current })
      }

      if (!response.success) {
        setError(response.error)
        return
      }
      setSuccess(true)
      handleChangeClose()
      execute()

      Hosts.openNewBlankCustomCheckout(current.id)
    } finally {
      setSending(false)
    }
  }

  const handleChangeDelete = (productCustomCheckout: TCustomCheckout) => {
    //productCustomCheckout ??= { ...customCheckoutDefault };
    //setCurrent(productCustomCheckout);
    setDeleteId(productCustomCheckout.id)
    setOpenConfirmeDelete(true)
  }

  const handleDeleteClose = () => {
    setDeleteId('')
    setCurrentDefault()
    setOpenConfirmeDelete(false)
  }

  const deleteCustomCheckout = async () => {
    if (deleteId === '') {
      return
    }

    setSending(true)
    setSuccess(false)
    setError('')

    try {
      const response = await CustomCheckoutController.remove({ id: deleteId })

      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        handleDeleteClose()
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  useEffect(() => setError(error), [error])

  return (
    <Box>
      <Stack spacing={2}>
        {prices.length <= 0 && (
          <Alert
            variant="filled"
            severity="warning"
            sx={{ width: '100%', mb: 2 }}
          >
            É necessário cadastrar ofertas para poder criar o checkout
            personalizado
          </Alert>
        )}
        {prices.length > 0 && (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              color="#343948"
              fontWeight={700}
              fontSize="16px"
              lineHeight="20px"
            >
              Meus Checkouts Personalizados
            </Typography>
            {!isAdmin && (
              <Button
                variant="outlined"
                size="large"
                sx={{ textTransform: 'none' }}
                onClick={() => handleChangeEdit()}
              >
                + Novo Checkout Personalizado
              </Button>
            )}
          </Stack>
        )}
        <Stack direction="column" spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            alignItems="center"
          >
            <Box sx={{ width: '30%' }}>
              <Typography variant="overline" fontWeight={500}>
                Nome
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography variant="overline" fontWeight={500}>
                Padrão
              </Typography>
            </Box>
            <Box sx={{ width: '20%' }}>
              <Typography variant="overline" fontWeight={500}>
                Ações
              </Typography>
            </Box>
          </Stack>
          <Divider />
          {data.map((customCheckout, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={1}
              alignContent="center"
              alignItems="center"
            >
              <Box sx={{ width: '30%' }}>
                <Typography variant="body2">{customCheckout.name}</Typography>
              </Box>
              <Stack direction="column" sx={{ width: '50%' }}>
                <Typography variant="body2">
                  {customCheckout.defaultValue ? 'Sim' : 'Não'}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ width: '15%' }}>
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                  size="small"
                >
                  {!isAdmin && (
                    <Tooltip title="Editar configurações">
                      <Button onClick={() => handleChangeEdit(customCheckout)}>
                        <EditIcon color="primary" />
                      </Button>
                    </Tooltip>
                  )}

                  {isAdmin && (
                    <Tooltip title="Ver configurações">
                      <Button onClick={() => handleChangeEdit(customCheckout)}>
                        <RemoveRedEyeIcon color="primary" />
                      </Button>
                    </Tooltip>
                  )}

                  <Tooltip title="Editar layout">
                    <Button
                      onClick={() =>
                        Hosts.openNewBlankCustomCheckout(customCheckout.id)
                      }
                    >
                      <ViewQuiltIcon color="primary" />
                    </Button>
                  </Tooltip>
                  {!isAdmin && (
                    <Tooltip title="Excluir checkout">
                      <Button
                        onClick={() => handleChangeDelete(customCheckout)}
                        color="error"
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  )}
                </ButtonGroup>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>

      <ProductCustomCheckoutRegister
        open={open}
        sending={sending}
        customCheckout={current}
        errorName={errorName}
        errorPrices={errorPrices}
        productPrices={prices}
        checkouts={data}
        watch={watch}
        setOpen={setOpen}
        setCustomCheckout={setCurrent}
        handleChangeSave={handleChangeSave}
      />

      <ConfirmDialog
        open={openConfirmDelete}
        title="Confirmar exclusão"
        message="Deseja realmente deletar o checkout personalizado?"
        onClose={handleDeleteClose}
        onYes={deleteCustomCheckout}
      />

      <LoadingBackdrop open={loading || sending} />

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Concluído com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

export default ProductCustomsCheckout
