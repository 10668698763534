import { Chip, Typography } from '@mui/material'
import { TProductPrice } from '../../../../../core/types/Product'

interface IProductPriceItemStatusProps {
  price: TProductPrice
}

const ProductPriceItemStatus = ({ price }: IProductPriceItemStatusProps) => {
  return (
    <Chip
      size="small"
      label={
        <Typography
          color={
            price.isDisabled
              ? '#D4362C'
              : price.disableDate
                ? '#887100'
                : '#009D23'
          }
          fontWeight={500}
          fontSize="12px"
          lineHeight="16px"
        >
          {price.isDisabled
            ? 'Desabilitado'
            : price.disableDate
              ? `Programado`
              : 'Ativa'}
        </Typography>
      }
      sx={{
        bgcolor: price.isDisabled
          ? '#BD1C1C29'
          : price.disableDate
            ? '#FBF3E2'
            : '#1CBD3F29',
      }}
    />
  )
}

export default ProductPriceItemStatus
