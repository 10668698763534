import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback, useState } from 'react'
import { numberOnly } from '../../../masks/masks'
import CepController from '../../../core/controllers/CepController'
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { TTenantRegister } from '../../../core/types/Tenant'
import states from '../../../core/data/states'

interface ITenantBankAccountWidgetProps {
  control: Control<TTenantRegister, any>
  addressName: 'address' | 'mainAddress' | 'managingPartner.address'
  errors: FieldErrors<TTenantRegister>
  setValue: UseFormSetValue<TTenantRegister>
  watch: UseFormWatch<TTenantRegister>
}

const TenantBankAccountAddress = ({
  control,
  addressName,
  errors,
  setValue,
  watch,
}: ITenantBankAccountWidgetProps) => {
  const { register } = control
  const zipCodeValue = watch(`${addressName}.zipCode`)
  const streetValue = watch(`${addressName}.street`)
  const complementValue = watch(`${addressName}.complement`)
  const neighborhoodValue = watch(`${addressName}.neighborhood`)
  const cityValue = watch(`${addressName}.city`)
  const stateValue = watch(`${addressName}.state`)

  const [loadingZipCode, setLoadingZipCode] = useState(false)

  const addressErrors =
    addressName === 'managingPartner.address'
      ? errors.managingPartner?.address
      : errors[addressName]

  const searchZipCode = useCallback(
    async (load: boolean, zipcode?: string) => {
      if (!zipcode || load) {
        return
      }
      const code = numberOnly(zipcode)
      if (code.length < 8) {
        return
      }
      setLoadingZipCode(true)
      try {
        const cep = await CepController.search(code)
        if (cep) {
          setValue(`${addressName}.street`, cep.logradouro)
          setValue(`${addressName}.complement`, cep.complemento)
          setValue(`${addressName}.neighborhood`, cep.bairro)
          setValue(`${addressName}.city`, cep.localidade)
          setValue(`${addressName}.state`, cep.uf)
        } else {
          //setOpenZipCodeInvalid(true)
        }
      } finally {
        setLoadingZipCode(false)
        //setSearchedZipCode(true)
      }
    },
    [numberOnly, setLoadingZipCode, setValue]
  )

  return (
    <Box width="100%">
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        size="small"
        label="CEP"
        type="text"
        placeholder="00000-000"
        inputProps={{
          inputMode: 'numeric',
          maxLength: 9,
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              size="small"
              onClick={() => searchZipCode(loadingZipCode, zipCodeValue)}
            >
              <img alt="Search" src="/assets/icons/search/search.svg" />
            </IconButton>
          ),
        }}
        {...register(`${addressName}.zipCode`)}
        error={
          (addressErrors?.zipCode?.type ?? '') !== '' &&
          (addressErrors?.zipCode?.message ?? '') !== ''
        }
        helperText={
          addressErrors?.zipCode?.type &&
          addressErrors?.zipCode.message && (
            <Typography variant="overline" color={'red'}>
              {addressErrors?.zipCode?.message}
            </Typography>
          )
        }
      />

      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        size="small"
        label="Endereço"
        type="text"
        {...register(`${addressName}.street`)}
        InputLabelProps={{ shrink: (streetValue ?? '') !== '' }}
        error={
          (addressErrors?.street?.type ?? '') !== '' &&
          (addressErrors?.street?.message ?? '') !== ''
        }
        helperText={
          addressErrors?.street?.type &&
          addressErrors?.street.message && (
            <Typography variant="overline" color={'red'}>
              {addressErrors?.street?.message}
            </Typography>
          )
        }
      />

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        sx={{ pt: 2, pb: 1 }}
      >
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          size="small"
          label="Número"
          type="text"
          {...register(`${addressName}.number`)}
          error={
            (addressErrors?.number?.type ?? '') !== '' &&
            (addressErrors?.number?.message ?? '') !== ''
          }
          helperText={
            addressErrors?.number?.type &&
            addressErrors?.number?.message && (
              <Typography variant="overline" color={'red'}>
                {addressErrors?.number?.message}
              </Typography>
            )
          }
        />

        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          size="small"
          label="Complemento"
          type="text"
          {...register(`${addressName}.complement`)}
          InputLabelProps={{ shrink: (complementValue ?? '') !== '' }}
          error={
            (addressErrors?.complement?.type ?? '') !== '' &&
            (addressErrors?.complement?.message ?? '') !== ''
          }
          helperText={
            addressErrors?.complement?.type &&
            addressErrors?.complement?.message && (
              <Typography variant="overline" color={'red'}>
                {addressErrors?.complement?.message}
              </Typography>
            )
          }
        />
      </Stack>

      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        size="small"
        label="Bairro"
        type="text"
        {...register(`${addressName}.neighborhood`)}
        InputLabelProps={{ shrink: (neighborhoodValue ?? '') !== '' }}
        error={
          (addressErrors?.neighborhood?.type ?? '') !== '' &&
          (addressErrors?.neighborhood?.message ?? '') !== ''
        }
        helperText={
          addressErrors?.neighborhood?.type &&
          addressErrors?.neighborhood?.message && (
            <Typography variant="overline" color={'red'}>
              {addressErrors?.neighborhood?.message}
            </Typography>
          )
        }
      />

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        sx={{ pt: 2, pb: 1 }}
      >
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          size="small"
          label="Cidade"
          type="text"
          {...register(`${addressName}.city`)}
          InputLabelProps={{ shrink: (cityValue ?? '') !== '' }}
          error={
            (addressErrors?.city?.type ?? '') !== '' &&
            (addressErrors?.city?.message ?? '') !== ''
          }
          helperText={
            addressErrors?.city?.type &&
            addressErrors?.city?.message && (
              <Typography variant="overline" color={'red'}>
                {addressErrors?.city?.message}
              </Typography>
            )
          }
        />

        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel id="state-label">Estado</InputLabel>
          <Select
            labelId="state-label"
            label="Estado"
            value={watch(`${addressName}.state`) ?? ''}
            {...register(`${addressName}.state`, {
              required: true,
            })}
            error={
              (addressErrors?.state?.type ?? '') !== '' &&
              (addressErrors?.state?.message ?? '') !== ''
            }
          >
            {states.map((state, index) => (
              <MenuItem
                key={`state_${addressName}_${index}`}
                value={state.sigla}
              >
                {`${state.sigla} - ${state.nome}`}
              </MenuItem>
            ))}
          </Select>
          {addressErrors?.state?.type && addressErrors?.state?.message && (
            <Typography variant="overline" color={'red'}>
              {addressErrors?.state?.message}
            </Typography>
          )}
        </FormControl>
      </Stack>

      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        size="small"
        label="Ponto de Referência"
        type="text"
        {...register(`${addressName}.referencePoint`)}
        error={
          (addressErrors?.referencePoint?.type ?? '') !== '' &&
          (addressErrors?.referencePoint?.message ?? '') !== ''
        }
        helperText={
          addressErrors?.referencePoint?.type &&
          addressErrors?.referencePoint?.message && (
            <Typography variant="overline" color={'red'}>
              {addressErrors?.referencePoint?.message}
            </Typography>
          )
        }
      />
    </Box>
  )
}

export default TenantBankAccountAddress
