import { useState } from 'react'
import SimpleCard from '../Cards/SimpleCard'
import { Collapse } from '@mui/material'

interface IAccordionDefaultProps {
  children: React.ReactNode[]
  initialExpanded?: boolean
}

const AccordionDefault = ({
  children,
  initialExpanded = false,
}: IAccordionDefaultProps) => {
  const [expanded, setExpanded] = useState(initialExpanded)

  const summary = children[0] as JSX.Element
  const details = children.filter((c, i) => i > 0)

  const handleToggleExpanded = () => {
    setExpanded((e) => !e)
  }

  return (
    <SimpleCard
      onClick={handleToggleExpanded}
      sx={{
        p: 0,
        border: `1px solid ${expanded ? '#BCC1FF' : '#DCDDDE'}`,
        width: '100%',
      }}
    >
      {summary}
      <Collapse in={expanded}>{details}</Collapse>
    </SimpleCard>
  )
}

export default AccordionDefault
