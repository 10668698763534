import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../../layouts/Theme';
import CopyrightCeletus from '../../../components/Copyright/CopyrightCeletus';
import { TopLogo } from '../../../components/Logo/TopLogo';
import { Card, CircularProgress, Stack } from '@mui/material';
import SuccessIcon from '@mui/icons-material/TaskAltOutlined';
import TextFieldRequired from '../../../components/Inputs/TextFieldRequired';
import Validations from '../../../core/functions/validations';
import { useForm } from 'react-hook-form';
import UserController from '../../../core/controllers/UserController';
import { TUserForgotPassword } from '../../../core/types/User';
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar';
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar';
import { useNavigate } from 'react-router-dom';

export default function ForgotPage() {
    const navigate = useNavigate();

    const [success, setSuccess] = React.useState(false);
    const [sending, setSending] = React.useState(false);

    const [error, setError] = React.useState("");

    const {
        control,
        handleSubmit,
        setFocus,
        clearErrors,
    } = useForm<TUserForgotPassword>({
        defaultValues: {
            email: "",
        },
    });

    const Submit = async (data: TUserForgotPassword) => {
        if (!Validations.email(data.email)) {
            setError("E-mail inválido");
            setFocus("email");
            return;
        }

        setSending(true);
        setSuccess(false);
        setError("");
        try {
            const response = await UserController.forgotPassword({ data });
            if (!response.success) {
                setError(`Não foi possível redefinir a senha. ${response.error}`);
            } else {
                setSuccess(true);
            }
        } finally {
            setSending(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <TopLogo />
                    <Typography component="h1" variant="h5" textAlign="center" fontWeight={900}>
                        Redefinir a senha
                    </Typography>
                    <Typography variant="subtitle1" textAlign="center">
                        Você receberá um e-mail com instruções para redefinir a senha
                    </Typography>
                    <Card sx={{ width: "100%", mt: 3, mb: 2 }}>
                        {!success && (
                            <Box component="form" noValidate onSubmit={handleSubmit(Submit)} sx={{ p: 5 }}>
                                <TextFieldRequired
                                    control={control}
                                    fieldName="email"
                                    label="E-mail"
                                    placeholder="Digite seu e-mail"
                                    clearErrors={clearErrors}
                                    validate={(value) => (!value) || (value == '') ? true : Validations.email(value)}
                                    inputProps={{ style: { textTransform: "lowercase" } }}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3 }}
                                    startIcon={sending ? <CircularProgress size="1rem" /> : undefined}
                                    disabled={sending}
                                >
                                    Redefinir senha
                                </Button>
                            </Box>
                        )}
                        {success && (
                            <Box sx={{ p: 5 }}>
                                <Stack alignContent="center" alignItems="center" sx={{ marginBottom: 3 }}>
                                    <SuccessIcon color='primary' sx={{ fontSize: "4.5rem" }} />
                                </Stack>
                                <Typography variant='h6' textAlign="center">
                                    E-mail enviado. Verifique sua caixa de entrada
                                </Typography>
                            </Box>
                        )}
                    </Card>
                </Box>
                <CopyrightCeletus />
            </Container>

            <ErrorSnackbar
                open={error !== ''}
                onClose={() => setError('')}
            >
                {error}
            </ErrorSnackbar>

            <SuccessSnackbar
                open={success}
                onClose={() => {
                    setSuccess(false);
                    navigate("/");
                }}>
                Solicitação enviada com sucesso
            </SuccessSnackbar>

        </ThemeProvider>
    );
}