import { Routes as Router, Route } from 'react-router-dom'
import AutoAuthRoutes from './AutoAuthRoutes'
import SignUp from '../pages/User/SignUp'
import ForgotPage from '../pages/User/Forgot'
import ChangePasswordPage from '../pages/User/ChangePassword'
import UnauthorizedPage from '../pages/Unauthorized'
import AdminProtected from './ProtectedRoutes/AdminProtected'
import DashboardPage from '../pages/Dashboard'
import CustomersPage from '../pages/Customers'
import CustomerPage from '../pages/Customers/Customer'
import ProductsPage from '../pages/Products'
import ProductPage from '../pages/Products/Product'
import { AffiliatedsPage } from '../pages/Affiliateds'
import CommissionRulesPage from '../pages/CommissionRules'
import AppsPage from '../pages/Apps'
import CademiPage from '../pages/Apps/Cademi/CademiListing'
import WebhooksPage from '../pages/Webhooks/List'
import MarketplacePage from '../pages/Marketplace'
import CheckoutOrdersPage from '../pages/Checkouts/Orders'
import CheckoutOpenPage from '../pages/Checkouts/Open'
import CheckoutAbandonedPage from '../pages/Checkouts/Abandoned'
import CheckoutSubscriptionPage from '../pages/Checkouts/Subscription'
import WebhookSendsPage from '../pages/Webhooks/Sends'
import { SettingsPage } from '../pages/settings'
import SysAdminProtected from './ProtectedRoutes/SysAdminProtected'
import UserSettingsPage from '../pages/Admin/UserSettings'
import SalesFunnelPage from '../pages/Sales/Funnel'
import SalesFunnelNewEditPage from '../pages/Sales/Funnel/SalesFunnelNewEdit'
import SalesFunnelStepsPage from '../pages/Sales/Funnel/SalesFunnelSteps'
import { ActiveCampaignPage } from '../pages/Apps/ActiveCampaign'
import ActiveCampaignSmartPage from '../pages/Apps/ActiveCampaign/ActiveCampaignSmart'
import ProductAffiliateRecruiting from '../pages/Products/AffiliateRecruiting'
import AffiliateRecruitingAffiliationPage from '../pages/Products/AffiliateRecruiting/AffiliateRecruitingAffiliation'
import CheckoutAccessPage from '../pages/Admin/Checkouts/Access'
import CheckoutOrderAnalitycsPage from '../pages/Admin/Checkouts/Analytics'
import TheMembersPage from '../pages/Apps/TheMembers/TheMembersListing'
import AstronMembersPage from '../pages/Apps/AstronMembers/AstronMembersListing'
import CustomCheckoutsPage from '../pages/Customs/Checkout/CustomCheckouts'
import NotazzPage from '../pages/Apps/Notazz'
import CheckoutOrderUsersAnticipationPage from '../pages/Admin/Checkouts/Anticipations'
import IndicatedPage from '../pages/Indicated'
import UtmfyList from '../pages/Apps/Utmfy/UtmfyList'
import OccurrenceLogPage from '../pages/Admin/Checkouts/OccurrenceLog'
import SalesAnalysis from '../pages/Admin/Checkouts/SalesAnalysis'

type Props = {}

const Routes = (props: Props) => {
  return (
    <Router>
      <Route index element={<AutoAuthRoutes />} />
      <Route path="/signup" element={<SignUp />} />

      <Route path="/forgot" element={<ForgotPage />} />
      <Route path="/:codeId/changepassword" element={<ChangePasswordPage />} />

      <Route path="/unauthorized" element={<UnauthorizedPage />} />

      <Route
        path="/affiliate-recruiting/view/:codeId"
        element={<ProductAffiliateRecruiting />}
      />

      <Route element={<AdminProtected />}>
        <Route path="/dashboard" element={<DashboardPage />} />

        <Route path="/customers" element={<CustomersPage />} />
        <Route path="/customer/:id" element={<CustomerPage />} />

        <Route path="/products" element={<ProductsPage />} />
        <Route path="/product/:id" element={<ProductPage />} />
        <Route
          path="/product/affiliation/:id"
          element={<AffiliateRecruitingAffiliationPage />}
        />

        <Route path="/affiliateds" element={<AffiliatedsPage />} />
        <Route path="/commissionrules" element={<CommissionRulesPage />} />

        <Route path="/apps" element={<AppsPage />} />

        <Route path="/apps/cademi" element={<CademiPage />} />
        <Route path="/apps/utmfy" element={<UtmfyList />} />
        <Route path="/apps/themembers" element={<TheMembersPage />} />
        <Route path="/apps/astronmembers" element={<AstronMembersPage />} />
        <Route path="/apps/webhooks" element={<WebhooksPage />} />
        <Route path="/apps/activecampaign" element={<ActiveCampaignPage />} />
        <Route
          path="/apps/activecampaign/:activeCampaignId/:id"
          element={<ActiveCampaignSmartPage />}
        />
        <Route path="/apps/notazz" element={<NotazzPage />} />

        <Route path="/sales/funnel" element={<SalesFunnelPage />} />
        <Route path="/sales/funnel/:id" element={<SalesFunnelNewEditPage />} />
        <Route
          path="/sales/funnel/:id/steps"
          element={<SalesFunnelStepsPage />}
        />

        <Route path="/marketplace" element={<MarketplacePage />} />

        <Route path="/checkout/order" element={<CheckoutOrdersPage />} />
        <Route path="/checkout/open" element={<CheckoutOpenPage />} />
        <Route path="/checkout/abandoned" element={<CheckoutAbandonedPage />} />
        <Route
          path="/checkout/subscription"
          element={<CheckoutSubscriptionPage />}
        />

        <Route path="/checkout/customs" element={<CustomCheckoutsPage />} />

        <Route path="/webhooks/sends" element={<WebhookSendsPage />} />

        <Route path="/settings" element={<SettingsPage />} />

        <Route path="/indicated" element={<IndicatedPage />} />

        <Route element={<SysAdminProtected />}>
          <Route
            path="/checkout/analytics"
            element={<CheckoutOrderAnalitycsPage />}
          />
          <Route path="/checkout/access" element={<CheckoutAccessPage />} />
          <Route
            path="/checkout/anticipations"
            element={<CheckoutOrderUsersAnticipationPage />}
          />
          <Route path="/admin/occurrences" element={<OccurrenceLogPage />} />

          <Route path="/admin/sales/analysis" element={<SalesAnalysis />} />

          <Route path="/settings/users" element={<UserSettingsPage />} />
        </Route>
      </Route>
    </Router>
  )
}

export default Routes
