import { useEffect, useState } from "react"
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Stack, Typography } from "@mui/material";
import Compressor from 'compressorjs';

export interface IImageUploadProp {
    displayButtonMsg: string;
    placeHolder?: string;
    sugestion?: string;
    onChange: (file: File | undefined) => void;
    onError: (error: string) => void;
}

export enum ImageUploadType {
    Cover = 1,
    Product = 2,
}

export const ImageUpload = ({ displayButtonMsg, placeHolder, sugestion, onChange, onError }: IImageUploadProp) => {
    const [selectedFile, setSelectedFile] = useState<File | undefined>()
    const [preview, setPreview] = useState<string | undefined>()

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile]);

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            onChange(undefined);
            return;
        }

        const file = e.target.files[0];

        if (file.size >= 1056895) {
            onError('A imagem precisa ter um tamanho menor que 1 MB');
            return;
        }

        setSelectedFile(file);
        onChange(file);
    }

    return (
        <Stack alignContent={"center"} alignItems={"center"} sx={{ width: "100%", mb: 2 }}>
            <Card elevation={8} sx={{ maxWidth: 365 }}>
                <CardActionArea>
                    {!selectedFile && placeHolder &&
                        <CardMedia
                            component="img"
                            src={placeHolder}
                            alt={displayButtonMsg}
                            sx={{ width: "100%", height: "200px", objectFit: "contain", objectPosition: "center" }}
                        />
                    }
                    {selectedFile &&
                        <CardMedia
                            component="img"
                            src={preview}
                            alt={displayButtonMsg}
                            sx={{ width: "100%", height: "200px", objectFit: "contain", objectPosition: "center" }}
                        />
                    }
                    {!selectedFile && !placeHolder &&
                        <CardMedia
                            component="div"
                            sx={{ width: "100%", height: "200px" }}
                        />
                    }
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {displayButtonMsg}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Tamanho máximo permitido de 999 KB
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {sugestion}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="text"
                            component="label"
                        >
                            Selecione uma imagem
                            <input
                                type="file"
                                onChange={onSelectFile}
                                hidden
                                accept="image/*"
                            />
                        </Button>
                    </CardActions>
                </CardActionArea>
            </Card>
        </Stack>

    );
}