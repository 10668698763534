import React from 'react'
import {
  Autocomplete,
  Box,
  Fade,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import ListItemDetail from '../../../components/ListItem/ListItemDetail'
import SwitchRound from '../../../components/Switch/SwitchRound'
import { TFunnelStep, TFunnelStepError } from '../../../core/types/SalesFunnel'
import { useCallback, useEffect, useRef, useState } from 'react'
import { TProductPrice } from '../../../core/types/Product'
import { useProductsSimple } from '../../../core/hooks/useProductsSimple'
import ProductController from '../../../core/controllers/ProductController'
import { IErrorResponse } from '../../../core/types/ErrorResponse'
import { red } from '@mui/material/colors'
import NumberInput from '../../../components/Inputs/NumberInput'

export enum TFunnelStepPage {
  Initial,
  Buttons,
}

export interface IFunnelStepNewEditProp {
  step: TFunnelStep
  page: TFunnelStepPage
  errors: TFunnelStepError
  setStep: (value: TFunnelStep) => void
  setSending: (value: boolean) => void
  setError: (value: string) => void
}

const FunnelStepNewEdit = ({
  step,
  page,
  errors,
  setStep,
  setSending,
  setError,
}: IFunnelStepNewEditProp) => {
  const [productPrices, setProductPrices] = useState<TProductPrice[]>([])
  const [hasTimer, setHasTimer] = useState(step.hasCountdown || false)
  const [displayTime, setDisplayTime] = useState(
    step.countdownSeconds / 60 || 0
  )

  const { data: products, loading: loadingProducts } = useProductsSimple({
    initialExecute: true,
  })

  const productId = useRef('')

  const handleTimerChange = (event: any) => {
    setHasTimer(event.target.checked)
    setStep({
      ...step,
      hasCountdown: event.target.checked,
    })
  }

  const handleDisplayTimeChange = (value: any) => {
    const totalSeconds = value * 60
    setDisplayTime(value)
    setStep({
      ...step,
      countdownSeconds: totalSeconds,
    })
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStep({
      ...step,
      countdownTitle: e.target.value,
    })
  }

  const getPricesByProduct = useCallback(
    async (productId?: string | null) => {
      if (productId && productId !== null && productId !== '') {
        setSending(true)
        try {
          const response = await ProductController.getPricesByProduct({
            productId,
          })
          const err = response as IErrorResponse
          if (err.code) {
            setError(err.error)
          } else {
            setProductPrices(response as TProductPrice[])
          }
        } finally {
          setSending(false)
        }
      }
    },
    [setError, setSending]
  )

  useEffect(() => {
    if (productId.current !== step.productId) {
      productId.current = step.productId ?? ''
      getPricesByProduct(step.productId)
    }
  }, [step])

  return (
    <Box>
      <Stack direction="column">
        <ListItemDetail
          title="Nome"
          direction="column"
          px={0}
          py={1}
          value={
            <Box width="100%">
              <TextField
                fullWidth
                size="small"
                type="text"
                value={step.name}
                onChange={(e) =>
                  setStep({
                    ...step,
                    name: e.target.value,
                  })
                }
                error={errors.name !== undefined && errors.name !== ''}
              />
              <Typography variant="caption" color={red[700]}>
                {errors.name}
              </Typography>
            </Box>
          }
          viewDivider={false}
        />
        <FormControlLabel
          control={
            <SwitchRound
              sx={{
                m: 1,
              }}
              checked={step.isThankYouPage}
              onChange={(e) =>
                setStep({
                  ...step,
                  isThankYouPage: e.target.checked,
                })
              }
            />
          }
          label="Página de obrigado"
        />
        <Fade in={!step.isThankYouPage} mountOnEnter unmountOnExit>
          <Box>
            <ListItemDetail
              title="Produto"
              direction="column"
              px={0}
              py={1}
              value={
                <Box>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    size="small"
                    options={products}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, option) => {
                      if (option && option !== null) {
                        setStep({
                          ...step,
                          productId: option.id,
                          productName: option.name,
                          productPriceId: '',
                        })
                      }
                    }}
                    value={{
                      id: step.productId ?? '',
                      name: step.productName,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          errors.productId !== undefined &&
                          errors.productId !== ''
                        }
                      />
                    )}
                  />
                  <Typography variant="caption" color={red[700]}>
                    {errors.productId}
                  </Typography>
                </Box>
              }
              viewDivider={false}
            />
            <ListItemDetail
              title="Oferta"
              direction="column"
              px={0}
              py={1}
              value={
                <FormControl
                  fullWidth
                  size="small"
                  disabled={productPrices.length <= 0}
                >
                  <Select
                    variant="outlined"
                    value={step.productPriceId}
                    onChange={(e) =>
                      setStep({
                        ...step,
                        productPriceId: e.target.value,
                      })
                    }
                    error={
                      errors.productPriceId !== undefined &&
                      errors.productPriceId !== ''
                    }
                  >
                    {productPrices.map((price) => (
                      <MenuItem value={price.id}>
                        {price.value?.toFixed(2)}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="caption" color={red[700]}>
                    {errors.productPriceId}
                  </Typography>
                </FormControl>
              }
              viewDivider={false}
            />
          </Box>
        </Fade>
        <ListItemDetail
          title="URL da página"
          direction="column"
          px={0}
          py={1}
          value={
            <Box width="100%">
              <TextField
                fullWidth
                size="small"
                type="text"
                value={step.pageUrl}
                onChange={(e) =>
                  setStep({
                    ...step,
                    pageUrl: e.target.value,
                  })
                }
                error={errors.pageUrl !== undefined && errors.pageUrl !== ''}
              />
              <Typography variant="caption" color={red[700]}>
                {errors.pageUrl}
              </Typography>
            </Box>
          }
          viewDivider={false}
        />

        {!step.isThankYouPage && (
          <ListItemDetail
            title="Texto para ação de efetuar a compra"
            direction="column"
            px={0}
            py={1}
            value={
              <Box width="100%">
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  placeholder="Sim"
                  value={step.positiveButtonLabel}
                  onChange={(e) =>
                    setStep({
                      ...step,
                      positiveButtonLabel: e.target.value,
                    })
                  }
                  error={
                    errors.positiveButtonLabel !== undefined &&
                    errors.positiveButtonLabel !== ''
                  }
                />
                <Typography variant="caption" color={red[700]}>
                  {errors.positiveButtonLabel}
                </Typography>
              </Box>
            }
            viewDivider={false}
          />
        )}
        {!step.isThankYouPage && (
          <ListItemDetail
            title="Texto para opção de recusa"
            direction="column"
            px={0}
            py={1}
            value={
              <Box width="100%">
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  placeholder="Não"
                  value={step.negativeButtonLabel}
                  onChange={(e) =>
                    setStep({
                      ...step,
                      negativeButtonLabel: e.target.value,
                    })
                  }
                  error={
                    errors.negativeButtonLabel !== undefined &&
                    errors.negativeButtonLabel !== ''
                  }
                />
                <Typography variant="caption" color={red[700]}>
                  {errors.negativeButtonLabel}
                </Typography>
              </Box>
            }
            viewDivider={false}
          />
        )}
        {!step.isThankYouPage && (
          <FormControlLabel
            control={
              <SwitchRound
                sx={{
                  m: 2,
                }}
                checked={step.hasCountdown}
                onChange={handleTimerChange}
              />
            }
            label="Habilitar temporizador"
          />
        )}
        {hasTimer && (
          <>
            <ListItemDetail
              title="Temporizador "
              direction="column"
              px={0}
              py={1}
              value={
                <Box width="100%">
                  <NumberInput
                    placeholder="Minutos"
                    value={displayTime}
                    min={1}
                    max={60}
                    onChange={(event, value) => handleDisplayTimeChange(value)}
                  />
                </Box>
              }
              viewDivider={false}
            />
            <ListItemDetail
              title="Título do temporizador"
              direction="column"
              px={0}
              py={1}
              value={
                <Box width="100%">
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    value={step.countdownTitle}
                    onChange={handleTitleChange}
                    error={
                      errors.CountdownTitle !== undefined &&
                      errors.CountdownTitle !== ''
                    }
                  />
                  <Typography variant="caption" color={red[700]}>
                    {errors.CountdownTitle}
                  </Typography>
                </Box>
              }
              viewDivider={false}
            />
          </>
        )}
      </Stack>
    </Box>
  )
}

export default FunnelStepNewEdit
