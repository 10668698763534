import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Stack,
  Chip,
  Tooltip,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { useNavigate } from 'react-router-dom'
import {
  addDays,
  dateDescription,
  diffDays,
} from '../../../../core/functions/dateTime'
import NumberFunctions from '../../../../core/functions/NumberFunctions'
import { TCheckoutOrder } from '../../../../core/types/CheckoutOrder'
import TablePaginationDefault from '../../../../components/Tables/TablePaginationDefault'
import {
  ExportFileTypeEnum,
  ReportDownloadController,
} from '../../../../core/controllers/ReportDownloadController'
import { useDownloadFile } from '../../../../core/hooks/useDownloadFile'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import CopiedSnackbar from '../../../../components/Snackbar/CopiedSnackbar'
import FilterIcon from '@mui/icons-material/Tune'
import 'dayjs/locale/pt-br'
import { useState } from 'react'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import SimpleCard from '../../../../components/Cards/SimpleCard'
import { ExportToFile } from '../../../Checkouts/ExportToFile'
import { AdvancedFilters } from '../../../Checkouts/AdvancedFilters'
import { useCheckoutOrderAnticipations } from '../../../../core/hooks/useCheckoutOrderAnticipations'
import { HtmlTooltip } from '../../../../components/Tooltip/HtmlTooltip'
import InfoIcon from '@mui/icons-material/InfoRounded'
import { grey } from '@mui/material/colors'

interface Column {
  id:
    | 'code'
    | 'name'
    | 'email'
    | 'total'
    | 'ourFee'
    | 'comission'
    | 'fee'
    | 'amountReceive'
    | 'amountPayment'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'code', label: 'Código', minWidth: 80 },
  { id: 'name', label: 'Nome', minWidth: 100 },
  { id: 'email', label: 'E-mail', minWidth: 100 },
  /*{
        id: "total",
        label: "Taxa de Conferência",
        minWidth: 90,
        align: "right",
        format: (value) => NumberFunctions.formatMoneyDefault(value),
    },*/
  {
    id: 'ourFee',
    label: 'Nossa Taxa de Antecipação',
    minWidth: 90,
    align: 'right',
    format: (value) => NumberFunctions.formatMoneyDefault(value),
  },
  {
    id: 'comission',
    label: 'Valor Recebido',
    minWidth: 90,
    align: 'right',
    format: (value) => NumberFunctions.formatMoneyDefault(value),
  },
  {
    id: 'fee',
    label: 'Taxa de Antecipação',
    minWidth: 90,
    align: 'right',
    format: (value) => NumberFunctions.formatMoneyDefault(value),
  },
  {
    id: 'amountPayment',
    label: 'Valor a Pagar',
    minWidth: 90,
    align: 'right',
    format: (value) => NumberFunctions.formatMoneyDefault(value),
  },
  {
    id: 'amountReceive',
    label: 'Valor que Deveria ser Recebido',
    minWidth: 90,
    align: 'right',
    format: (value) => NumberFunctions.formatMoneyDefault(value),
  },
]

export default function CheckoutOrderUsersAnticipationPage() {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [copied, setCopied] = useState(false)
  const [current, setCurrent] = useState<TCheckoutOrder | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorSending, setErrorSending] = useState('')

  const [startDate, setStartdate] = useState<Date | null | undefined>(
    addDays(-29)
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(new Date())
  const [searchType, setSearchType] = useState(0)
  const [prodSearchList, setProdSearchLists] = useState<string[]>([])
  const [affilatedsSearchList, setAffilatedsSearchLists] = useState<string[]>(
    []
  )
  const [paymentSearchList, setpaymentSearchLists] = useState<string[]>([])
  const [statusSearchList, setStatusSearchLists] = useState<string[]>([])
  const [openFilter, setOpenFilter] = useState(false)

  const { loading, data, error, execute, clear } =
    useCheckoutOrderAnticipations({
      page,
      rows: rowsPerPage,
      search,
      startDate,
      endDate,
      status: statusSearchList,
    })

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }
  }, [data, rowsPerPage])

  const navigate = useNavigate()

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleChangeItem = (checkoutOrder: TCheckoutOrder) => {
    setCurrent(checkoutOrder)
    setOpen(true)
  }

  const handleClose = () => {
    setCurrent(undefined)
    setOpen(false)
  }

  const handleDownloadFile = (typeFile: ExportFileTypeEnum) => {
    return ReportDownloadController.getAnticipationsFile({
      fileType: typeFile,
      search,
      type: '',
      report: 'order',
      startDate,
      endDate,
      status: statusSearchList,
    })
  }

  const handlerDownloadingError = (e: string) => setErrorSending(e)
  const getFileName = (type: ExportFileTypeEnum) =>
    `report_${new Date().toISOString().replace(':', '_').replace('.', '_')}.${
      type === ExportFileTypeEnum.XLS ? 'xlsx' : 'csv'
    }`

  const { download, downloading } = useDownloadFile({
    apiDefinition: handleDownloadFile,
    onError: handlerDownloadingError,
    getFileName,
  })

  const downloadCallback = React.useCallback(
    (type: ExportFileTypeEnum) => download(type),
    [download]
  )

  const handleConvertToXLS = async () => {
    if (isValidDownloadReport()) downloadCallback(ExportFileTypeEnum.XLS)
  }

  const handleConvertToCSV = async () => {
    if (isValidDownloadReport()) downloadCallback(ExportFileTypeEnum.CSV)
  }

  const isValidDownloadReport = (): boolean => {
    if (startDate === null || startDate === undefined) {
      setErrorSending('Necessário informar o período inicial.')
      return false
    }

    if (endDate === null || endDate === undefined) {
      setErrorSending('Necessário informar um período final.')
      return false
    }

    return true
  }

  const handleDateFilter = (value: any) => {
    setStartdate(value.startDate)
    setEndDate(value.endDate)
  }

  const handleOpenFilter = () => setOpenFilter(true)

  const handleCloseFilter = () => setOpenFilter(false)

  return (
    <Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        Taxa de Antecipação por Usuário
      </Typography>

      <SimpleCard sx={{ mb: 2 }}>
        <Stack direction="column" spacing={1}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-filter">
              Filtrar por nome e / ou e-mail
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-filter"
              onChange={(e) => setSearch(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  execute()
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Filtros avançados">
                    <IconButton
                      aria-label="toggle search"
                      onClick={handleOpenFilter}
                      edge="end"
                    >
                      <FilterIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              label="Filtrar por nome e / ou e-mail"
            />
          </FormControl>
          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            alignItems="center"
          >
            <Chip
              variant="filled"
              size="small"
              icon={<CalendarMonthIcon />}
              label={`Filtro do período de ${dateDescription(startDate)} à ${dateDescription(endDate)}`}
            />
            <ExportToFile
              convertToCSV={handleConvertToCSV}
              convertToXLS={handleConvertToXLS}
            />
          </Stack>
        </Stack>
      </SimpleCard>

      <SimpleCard sx={{ p: 0 }}>
        <TableContainer
          sx={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
        >
          <Table stickyHeader size="small" aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  if (column.id === 'total') {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          alignContent="center"
                          alignItems="center"
                          justifyContent="end"
                        >
                          {column.label}
                          <HtmlTooltip
                            title={
                              <Typography>
                                Valor calculado de todo o período para a
                                conferência da taxa de antecipação. Calculo:
                                Total de comissão - Saldo disponível - Saldo a
                                receber - Valor dos saques - (Quantidade de
                                saques * 3.67)
                              </Typography>
                            }
                          >
                            <InfoIcon
                              fontSize="small"
                              sx={{ color: grey[600], ml: 1 }}
                            />
                          </HtmlTooltip>
                        </Stack>
                      </TableCell>
                    )
                  }
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    sx={{ cursor: 'pointer' }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id]
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          //onClick={() => handleChangeItem(row)}
                        >
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value?.toString()}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </SimpleCard>
      <Box sx={{ height: 80 }} />

      <AdvancedFilters
        open={openFilter}
        setOpen={setOpenFilter}
        affiliateds={[]}
        products={[]}
        handleCloseFilter={handleCloseFilter}
        handleDateFilter={handleDateFilter}
        typeFilterValue={searchType}
        startDate={startDate}
        endDate={endDate}
        startDateDefault={addDays(-29)}
        endDateDefault={new Date()}
        onlyRangeDate={true}
        handleTypeFilter={(e: number) => setSearchType(e)}
        handleProductFilter={(e: string[]) => setProdSearchLists(e)}
        handleAffiliatedFilter={(e: string[]) => setAffilatedsSearchLists(e)}
        handlePaymentMethodFilter={(e: string[]) => setpaymentSearchLists(e)}
        handlStatusFilter={(e: string[]) => setStatusSearchLists(e)}
      />

      <LoadingBackdrop open={loading || sending || downloading} />

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Concluído com sucesso
      </SuccessSnackbar>

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />
    </Box>
  )
}
