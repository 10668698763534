import * as React from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { ThemeProvider } from '@mui/material/styles'
import { AuthContext } from '../../core/context/AuthContext'
import { useNavigate } from 'react-router-dom'
import UserController from '../../core/controllers/UserController'
import { IErrorResponse } from '../../core/types/ErrorResponse'
import { TAuthUser } from '../../core/types/Auth'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import {
  AppBar,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
} from '@mui/material'
import ErrorSnackbar from '../../components/Snackbar/ErrorSnackbar'
import { themeWithBackgroundWhite } from '../../layouts/Theme'
import { TopLogo } from '../../components/Logo/TopLogo'
import { useQuery } from '../../components/Query/useQuery'
import BpCheckbox from '../../components/Checkout/BpCheckbox'

export default function SignIn() {
  const { stayConnected, setUser, setStayConnected } =
    React.useContext(AuthContext)

  const query = useQuery()

  const [showPassword, setShowPassword] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [shrinkEmail, setShrinkEmail] = React.useState(false)
  const [shrinkPassword, setShrinkPassword] = React.useState(false)

  const [email, setEmail] = React.useState(' ')
  const [password, setPassword] = React.useState(' ')
  const [error, setError] = React.useState('')

  const [userError, setUserError] = React.useState({
    isError: false,
    message: '',
  })
  const [passwordError, setPasswordError] = React.useState({
    isError: false,
    message: '',
  })

  const [windowSize, setWindowSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ])

  const navigate = useNavigate()

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    //const data = new FormData(event.currentTarget);
    //const email = data.get("email")?.toString().toLowerCase() ?? "";
    //const password = data.get("password")?.toString() ?? "";

    if (email.trim() === '') {
      setUserError({
        isError: true,
        message: 'Esse campo é obrigatório',
      })
    }

    if (password.trim() === '') {
      setPasswordError({
        isError: true,
        message: 'Esse campo é obrigatório',
      })
    }

    if (email.trim() === '' || password.trim() === '') {
      setError('Preenchimento obrigatório')
      return
    }

    setLoading(true)
    setError('')
    try {
      const response = await UserController.auth(email, password)
      const error = response as IErrorResponse
      const auth = response as TAuthUser
      if (error.code) {
        setError(error.error)
      } else {
        setUser(auth)
        const redirect = query.get('redirect')
        if (redirect && redirect !== '') {
          navigate(redirect)
        } else {
          navigate('/dashboard')
        }
      }
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    setShrinkEmail(email.length > 0)
    setShrinkPassword(password.length > 0)
  }, [email, password])

  React.useEffect(() => {
    window.addEventListener('resize', (ev) => {
      setWindowSize([window.innerWidth, window.innerHeight])
    })

    // auto preenchimento quando salva a senha no browser
    new Promise((resolve) => setTimeout(resolve, 100)).then(() => {
      setEmail('')
      setPassword('')
    })
  }, [])

  return (
    <ThemeProvider theme={themeWithBackgroundWhite}>
      <CssBaseline />
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: 'white',
          borderBottom: { xs: '1px solid #DBDDDF', md: '0px' },
          width: '100%',
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Box
          width={{ xs: '100%', md: '160px' }}
          height={{ xs: '46px', md: '64px' }}
          sx={{ mt: '10px' }}
        >
          <TopLogo margimBottom="0px" />
        </Box>
      </AppBar>
      <Container
        id="container-main"
        maxWidth="lg"
        sx={{
          px: 0,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            backgroundColor: 'white',
            height: { xs: 'auto', md: '100vh' },
            width: '100%',
            m: 0,
            margin: 0,
          }}
        >
          <Grid
            container
            columns={16}
            sx={{
              height: { xs: 'auto', md: '100vh' },
              maxWidth: '100%',
            }}
          >
            <Grid
              xs={16}
              md={8}
              justifyContent="start"
              textAlign="start"
              sx={{ height: { xs: 'auto', md: '100vh' } }}
            >
              <AppBar
                position="static"
                elevation={0}
                sx={{
                  backgroundColor: 'white',
                  borderBottom: {
                    xs: '1px solid #DBDDDF',
                    md: '0px',
                  },
                  width: '100%',
                  display: { xs: 'none', md: 'block' },
                }}
              >
                <Box
                  width={{ xs: '100%', md: '160px' }}
                  height={{ xs: '46px', md: '64px' }}
                  sx={{ mt: '10px' }}
                >
                  <TopLogo margimBottom="0px" />
                </Box>
              </AppBar>
              <Container
                maxWidth="md"
                sx={{ height: { xs: 'auto', md: '90%' }, px: 3 }}
              >
                <Stack
                  direction="column"
                  alignContent="space-evenly"
                  justifyContent="space-evenly"
                  height={{ xs: 'auto', md: '80%' }}
                  sx={{ pr: { xs: 0, md: 8 } }}
                >
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ p: 0 }}
                  >
                    <Box
                      sx={{
                        mt: 7,
                        maxWidth: windowSize[0] < 600 ? '300px' : '500px',
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="h5"
                        fontWeight={700}
                        fontSize={{
                          xs: '20px',
                          sm: '32px',
                        }}
                        lineHeight={{
                          xs: '24px',
                          sm: '40px',
                        }}
                        sx={{ mb: 1, color: '#3B3838' }}
                      >
                        Boas vindas a melhor plataforma de pagamento do mercado
                        digital.
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontSize={{
                          xs: '14px',
                          sm: '16px',
                        }}
                        lineHeight={{
                          xs: '18px',
                          sm: '20px',
                        }}
                        sx={{ mb: 3, color: '#767171' }}
                      >
                        Tenha o máximo de aprovação em pagamento utilizando
                        nosso checkout de alta conversão
                      </Typography>
                    </Box>
                    <Box>
                      <TextField
                        InputLabelProps={{
                          sx: {
                            ml: 4.5,
                            color: 'black',
                          },
                          shrink: shrinkEmail,
                          required: false,
                        }}
                        sx={(theme) => ({
                          '& .Mui-focused .MuiInputAdornment-root': {
                            color: theme.palette.primary.main,
                          },
                          '& .Mui-error .MuiInputAdornment-root': {
                            color: theme.palette.error.main,
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            px: 5.5,
                          },
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                loading="lazy"
                                src="/assets/icons/mail/mail.svg"
                                alt=""
                              />
                            </InputAdornment>
                          ),
                        }}
                        required
                        fullWidth
                        id="email"
                        label="Digite seu email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        disabled={loading}
                        error={userError.isError}
                        onKeyDown={() => {
                          if (userError.isError) {
                            setUserError({
                              isError: false,
                              message: '',
                            })
                          }
                        }}
                        value={email}
                        onChange={(event) =>
                          setEmail(event.target.value.toLowerCase())
                        }
                        inputProps={{
                          style: {
                            textTransform: 'lowercase',
                          },
                        }}
                      />
                      <Typography
                        variant="caption"
                        color="error"
                        sx={{ ml: 2 }}
                      >
                        {userError.message}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <TextField
                        InputLabelProps={{
                          sx: {
                            ml: 4.5,
                            color: 'black',
                          },
                          shrink: shrinkPassword,
                          required: false,
                        }}
                        sx={(theme) => ({
                          '& .Mui-focused .MuiInputAdornment-root': {
                            color: theme.palette.primary.main,
                          },
                          '& .Mui-error .MuiInputAdornment-root': {
                            color: theme.palette.error.main,
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            px: 5.5,
                          },
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                loading="lazy"
                                src="/assets/icons/password/password.svg"
                                alt=""
                              />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <img
                                    src="/assets/icons/eyes/eyeInvisible.svg"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src="/assets/icons/eyes/eyeVisible.svg"
                                    alt=""
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        required
                        fullWidth
                        id="password"
                        label="Digite sua senha"
                        name="password"
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        disabled={loading}
                        error={passwordError.isError}
                        onKeyDown={() => {
                          if (passwordError.isError) {
                            setPasswordError({
                              isError: false,
                              message: '',
                            })
                          }
                        }}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                      <Typography
                        variant="caption"
                        color="error"
                        sx={{ ml: 2 }}
                      >
                        {passwordError.message}
                      </Typography>
                    </Box>
                    <Stack
                      direction="row"
                      alignContent="center"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <FormControlLabel
                        control={
                          <BpCheckbox
                            checked={stayConnected}
                            onChange={(event) =>
                              setStayConnected(event.target.checked)
                            }
                          />
                        }
                        label={
                          <Typography
                            lineHeight="18px"
                            fontSize={{ xs: '13px', sm: '14px' }}
                            variant="body2"
                            sx={{
                              color: '#5D5E61',
                            }}
                          >
                            Manter conectado
                          </Typography>
                        }
                      />
                      <Link
                        href="/forgot"
                        component={Link}
                        variant="body2"
                        fontSize={{ xs: '13px', sm: '14px' }}
                        lineHeight="18px"
                        textAlign={'end'}
                        sx={{
                          textDecoration: 'none',
                          color: '#1C52BD',
                        }}
                      >
                        Esqueceu sua senha?
                      </Link>
                    </Stack>
                    <Box
                      sx={{
                        position: 'relative',
                        mt: 3,
                        mb: 1,
                      }}
                    >
                      <Button
                        type="submit"
                        fullWidth
                        variant="main"
                        disabled={loading}
                        startIcon={
                          loading ? <CircularProgress size="1rem" /> : undefined
                        }
                        style={{ height: 50 }}
                        sx={{
                          fontWeight: 700,
                          fontSize: '14px',
                          lineHeight: '24px',
                        }}
                      >
                        Entrar
                      </Button>
                      <Typography
                        variant="body2"
                        textAlign="center"
                        fontSize="14px"
                        lineHeight="18px"
                        sx={{
                          mt: 3,
                          mb: 3,
                          color: '#767171',
                        }}
                      >
                        Não possui uma conta?{' '}
                        <Link
                          component={Link}
                          href={`/signup${window.location.search}`}
                          fontWeight={500}
                          sx={{
                            textDecoration: 'none',
                            color: '#1C52BD',
                          }}
                        >
                          Faça seu cadastro
                        </Link>
                      </Typography>
                    </Box>

                    {/*<Box alignContent="space-evenly" justifyContent="space-evenly">
                                            <Typography
                                                variant="body2"
                                                textAlign="center"
                                                sx={{ mt: 1, mb: 3, color: "#767171" }}
                                            >
                                                Ou entre com
                                            </Typography>
                                            <Stack
                                                direction={{ xs: "column", sm: "row" }}
                                                spacing={1}
                                                alignContent="center"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                sx={{
                                                    mt: 1,
                                                    mb: 1,
                                                }}
                                            >
                                                <Button
                                                    component={Link}
                                                    href={`/signup${window.location.search}`}
                                                    variant="outlined"
                                                    size="medium"
                                                    fullWidth
                                                    sx={{ textTransform: "none", borderColor: "#DCDDDE" }}
                                                    style={{ height: 40 }}
                                                >
                                                    <Stack direction="row" width="100%" spacing={1} alignItems="center">
                                                        <FacebookRounded />
                                                        <Typography
                                                            variant="body1"
                                                            fontWeight={500}
                                                            sx={{
                                                                fontSize: 14,
                                                                color: "#767171",
                                                            }}
                                                        >
                                                            Entrar com facebook
                                                        </Typography>
                                                    </Stack>
                                                </Button>
                                                <Button
                                                    component={Link}
                                                    href={`/signup${window.location.search}`}
                                                    variant="outlined"
                                                    size="medium"
                                                    fullWidth
                                                    sx={{ textTransform: "none", borderColor: "#DCDDDE" }}
                                                    style={{ height: 40 }}
                                                >
                                                    <Stack direction="row" width="100%" spacing={1} alignItems="center">
                                                        <img src="/assets/icons/social/logoGoogle.svg" alt="" />
                                                        <Typography
                                                            variant="body1"
                                                            fontWeight={500}
                                                            sx={{
                                                                fontSize: 14,
                                                                color: "#767171",
                                                            }}
                                                        >
                                                            Entrar com Google
                                                        </Typography>
                                                    </Stack>
                                                </Button>
                                            </Stack>
                                                        </Box>*/}
                  </Box>
                </Stack>
              </Container>
            </Grid>
            <Grid
              xs={8}
              justifyContent="start"
              height="100vh"
              width="100%"
              sx={{ display: { xs: 'none', md: 'block' }, py: 2 }}
            >
              <Paper
                elevation={0}
                square={false}
                sx={{
                  m: 0,
                  p: 0,
                  height: '100%',
                  width: '100%',
                  borderRadius: '20px',
                  overflow: 'hidden',
                }}
              >
                <img
                  id="middle"
                  src="/assets/signup/loginImagem.png"
                  alt=""
                  loading="lazy"
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              </Paper>
            </Grid>
            <Box
              sx={{
                position: 'absolute',
                top: '57%',
                left: 'calc(50% - 80px)',
                display: { xs: 'none', md: 'block' },
              }}
            >
              <img
                id="frames"
                src="/assets/signup/FrameApproved.png"
                alt=""
                loading="lazy"
                style={{
                  objectFit: 'contain',
                  height: '80%',
                  width: '80%',
                }}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: '-14px',
                left: '-15px',
                display: { xs: 'block', md: 'none' },
              }}
            >
              <img
                id="top"
                src="/assets/signup/loginGraphicElementMobileTop.png"
                alt="Elemento do topo"
                loading="lazy"
                style={{
                  objectFit: 'contain',
                  height: '121px',
                  width: '79px',
                }}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 'calc(50% - 130px)',
                display: { xs: 'none', md: 'block' },
              }}
            >
              <img
                id="top"
                src="/assets/signup/loginGraphicElementTop.png"
                alt="Elemento do topo"
                loading="lazy"
                style={{
                  objectFit: 'contain',
                  height: '80%',
                  width: '80%',
                }}
              />
            </Box>
          </Grid>
        </Box>
      </Container>
      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>
    </ThemeProvider>
  )
}
