import api, { getResponseError } from '../../services/api'
import { IErrorResponse, IResponse } from '../types/ErrorResponse'
import { TUtmfyPagination, TUtmfyListId, TUtemfyRegister } from '../types/Utmfy'

interface IGetUtmfyProp {
  page: number
  rows: number
}

const getAll = async ({
  page,
  rows,
}: IGetUtmfyProp): Promise<TUtmfyPagination | IErrorResponse> => {
  try {
    var path = `/Apps/utmfy?PageNumber=${page}&RowsPerPage=${rows}`

    const { data } = await api.get<TUtmfyPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const get = async ({
  id,
}: {
  id: string
}): Promise<TUtmfyListId | IErrorResponse> => {
  try {
    const { data } = await api.get<TUtmfyListId>(`/Apps/utmfy/${id}`)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const insert = async ({
  data,
}: {
  data: TUtemfyRegister
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.post('/Apps/utmfy', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const update = async ({
  id,
  data,
}: {
  id: string
  data: TUtemfyRegister
}): Promise<IResponse> => {
  try {
    const { status } = await api.put(`/Apps/utmfy/${id}`, data)
    if (status === 200) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const remove = async ({ id }: { id: string }): Promise<IResponse> => {
  try {
    await api.delete(`/Apps/utmfy/${id}`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}
const disabled = async ({ id }: { id: string }): Promise<IResponse> => {
  try {
    await api.put(`/Apps/utmfy/${id}/disable`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const enabled = async ({ id }: { id: string }): Promise<IResponse> => {
  try {
    await api.put(`/Apps/utmfy/${id}/enable`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const UtmfyController = {
  getAll,
  get,
  insert,
  update,
  remove,
  disabled,
  enabled,
}

export default UtmfyController
