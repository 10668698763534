import {
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Box } from '@mui/system'
import { useContext, useState } from 'react'
import { UseFormWatch } from 'react-hook-form'
import {
  TProduct,
  TProductPixel,
  TProductPixelRegisterPage,
  TProductPixelType,
} from '../../../../core/types/Product'

import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import ProductController from '../../../../core/controllers/ProductController'
import { useProductPixels } from '../../../../core/hooks/useProductPixels'
import ProductPixelRegister from '../../../../components/Product/Pixel/ProductPixelRegister'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import { TUserType } from '../../../../core/types/Auth'
import { AuthContext } from '../../../../core/context/AuthContext'

export interface IProductPixelProps {
  watch: UseFormWatch<TProduct>
  setError: (error: string) => void
}

const productPixelDefault: TProductPixel = {
  id: '',
  productId: '',
  pixel: '',
  pixelToken: '',
  pixelDomain: '',
  type: TProductPixelType.Facebook,
  enabledDomain: false,
  cnameVerified: false,
  selectivePurchase: true,
  selectiveImmediatePurchase: true,
  selectiveNonImmediatePurchase: true,
  visitSalesPage: true,
}

const ProductPixels = ({
  watch,
  setError,
}: IProductPixelProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState<TProductPixel>({
    ...productPixelDefault,
  })
  const [openConfirmDelete, setOpenConfirmeDelete] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorPixel, setErrorPixel] = useState('')
  const [errorDomain, setErrorDomain] = useState('')
  const [errorToken, setErrorToken] = useState('')
  const [registerPageIndex, setRegisterPageIndex] =
    useState<TProductPixelRegisterPage>(TProductPixelRegisterPage.Pixel)
  const [isEditing, setIsEditing] = useState(false)
  const productId = watch('id')

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  const { loading, data, error, execute, clear } = useProductPixels({
    productId,
  })

  const setCurrentDefault = () => {
    clearErrors()
    setCurrent({ ...productPixelDefault })
  }

  const handleChangeEditPixel = (productPixel: TProductPixel) => {
    clearErrors()
    if (!productPixel.type) {
      productPixel.type = TProductPixelType.Facebook
    }
    setCurrent(productPixel)
    setRegisterPageIndex(TProductPixelRegisterPage.Pixel)
    setOpen(true)
    setIsEditing(true)
  }

  const handleChangePixel = () => {
    clearErrors()
    setCurrent(productPixelDefault)
    setRegisterPageIndex(TProductPixelRegisterPage.Pixel)
    setOpen(true)
    setIsEditing(false)
  }

  const handleChangeClose = () => {
    setCurrentDefault()
    setOpen(false)
  }

  const validationPixelErrors = () => {
    let isError = false

    if ((current.pixel ?? '') === '') {
      setErrorPixel('Campo obrigatório')
      isError = true
    }

    if (current.type === TProductPixelType.GoogleAds) {
      if ((current.pixelToken ?? '') === '') {
        setErrorToken('Campo obrigatório')
        isError = true
      }
    }

    return isError
  }

  const validationDomainErrors = () => {
    let isError = false

    if (current.type === TProductPixelType.Facebook) {
      if (current.enabledDomain) {
        if ((current.pixelDomain ?? '') === '') {
          setErrorDomain('Campo obrigatório')
          isError = true
        }
      }
    }

    return isError
  }

  const validationWithErrors = () => {
    let isError = false

    if (validationPixelErrors()) {
      isError = true
    }

    if (validationDomainErrors()) {
      isError = true
    }

    return isError
  }

  const clearErrors = () => {
    if (errorPixel !== '') {
      setErrorPixel('')
    }
    if (errorDomain !== '') {
      setErrorDomain('')
    }
    if (errorToken !== '') {
      setErrorToken('')
    }
  }

  const handleChangeSave = async () => {
    if (validationWithErrors()) {
      return
    }

    clearErrors()

    current.productId = productId

    setSending(true)
    try {
      var response
      if (current.id === '') {
        response = await ProductController.insertPixel({ data: current })
      } else {
        response = await ProductController.updatePixel({ data: current })
      }

      if (!response.success) {
        setError(response.error)
        return
      }

      if (current.enabledDomain) {
        if (!(response.data?.cnameVerified ?? false)) {
          setError('CNAME não verificado, tente novamente mais tarde.')
        }
      }

      setSuccess(true)
      handleChangeClose()
      execute()
    } finally {
      setSending(false)
    }
  }

  const handleChangeVerifyDomain = async () => {
    if (validationWithErrors()) {
      return
    }

    clearErrors()

    current.productId = productId

    setSending(true)
    try {
      var response
      if (current.id === '') {
        response = await ProductController.insertPixel({ data: current })
        if (response.data.cnameVerified) {
          setCurrent({
            ...current,
            id: response.data.id,
            cnameVerified: true,
          })
        } else {
          setCurrent({
            ...current,
            id: response.data.id,
            cnameVerified: false,
            pixelDomain: (current.pixelDomain ?? '').startsWith('pixel.')
              ? (current.pixelDomain ?? '').replace('pixel.', '')
              : current.pixelDomain,
          })
        }
      } else {
        response = await ProductController.updatePixel({ data: current })
        setCurrent({
          ...current,
          cnameVerified: response.data?.cnameVerified ?? false,
          pixelDomain: (current.pixelDomain ?? '').startsWith('pixel.')
            ? (current.pixelDomain ?? '').replace('pixel.', '')
            : current.pixelDomain,
        })
      }

      if (!(response.data?.cnameVerified ?? false)) {
        setError('CNAME não verificado, tente novamente mais tarde.')
        return
      }
    } finally {
      setSending(false)
    }
  }

  const handleChangeDelete = (productPixel: TProductPixel) => {
    productPixel ??= { ...productPixelDefault }
    setCurrent(productPixel)
    setOpenConfirmeDelete(true)
  }

  const handleDeleteClose = () => {
    setCurrentDefault()
    setOpenConfirmeDelete(false)
  }

  const deletePixel = async () => {
    if (!current || current.id === '') {
      return
    }

    setSending(true)
    setSuccess(false)
    setError('')

    try {
      const response = await ProductController.removePixel({
        pixelId: current.id,
      })

      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        handleDeleteClose()
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  return (
    <Box>
      <Stack spacing={2} sx={{ backgroundColor: '#FFFFFF' }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <Stack>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '20px',
                color: '#343948',
              }}
            >
              Meus Pixels
            </Typography>
          </Stack>

          {!isSmallScreen && !isAdmin && (
            <Button
              variant="outlined"
              sx={{
                color: '#1C52BD',
                borderColor: '#1C52BD',
                fontFamily: 'Montserrat',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '24px',
              }}
              onClick={() => handleChangePixel()}
            >
              + Novo Pixel
            </Button>
          )}
        </Stack>

        <Stack direction="column" spacing={1}>
          {!isSmallScreen && (
            <Stack direction="row" sx={{ px: 2, py: 1 }}>
              <Box sx={{ width: '126px', flexShrink: 0 }}>
                <Typography
                  fontWeight={600}
                  fontFamily="Montserrat"
                  fontSize="14px"
                  lineHeight="18px"
                  color="#707275"
                >
                  Pixel
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1, minWidth: 0, pr: 1 }}>
                <Typography
                  fontWeight={600}
                  fontFamily="Montserrat"
                  fontSize="14px"
                  lineHeight="18px"
                  color="#707275"
                >
                  Domínio
                </Typography>
              </Box>
              <Box sx={{ width: '56px', flexShrink: 0 }}>
                <Typography
                  fontWeight={600}
                  fontFamily="Montserrat"
                  fontSize="14px"
                  lineHeight="18px"
                  color="#707275"
                >
                  Ações
                </Typography>
              </Box>
            </Stack>
          )}

          <Stack sx={{ padding: '0 16px' }}>
            {data.map((pixel, index) => (
              <Paper
                elevation={0}
                sx={{
                  mb: 2,
                  borderRadius: '4px',
                  border: '1px solid #DCDDDE',
                  p: 1,
                }}
                key={index}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="body2">
                      {pixel.type === TProductPixelType.Facebook ? (
                        <Stack direction="row" alignItems="center">
                          <img
                            alt="face-icon"
                            src="/assets/icons/social/logoFace.svg"
                            style={{
                              marginRight: 6,
                            }}
                          />
                          Facebook
                        </Stack>
                      ) : (
                        <Stack direction="row" alignItems="center">
                          <img
                            alt="face-icon"
                            src="/assets/icons/social/logoGoogle.svg"
                            style={{
                              marginRight: 6,
                            }}
                          />
                          Google
                        </Stack>
                      )}
                    </Typography>
                  </Box>

                  <Box sx={{ flexGrow: 1, minWidth: 0, ml: '8px' }}>
                    <Typography variant="body2">{pixel.pixelDomain}</Typography>
                  </Box>

                  {!isSmallScreen && (
                    <Box sx={{ width: '25%', flexShrink: 0 }}>
                      {pixel.type === TProductPixelType.Facebook && (
                        <Chip
                          size="small"
                          variant="outlined"
                          color={pixel.cnameVerified ? 'success' : 'warning'}
                          label={
                            pixel.cnameVerified
                              ? 'CNAME verificado'
                              : 'CNAME não validado'
                          }
                        />
                      )}
                    </Box>
                  )}

                  {!isAdmin && (
                    <Stack
                      direction="row"
                      sx={{ width: '10%', justifyContent: 'flex-end' }}
                    >
                      <IconButton onClick={() => handleChangeEditPixel(pixel)}>
                        <img
                          src="/assets/icons/actions/edit.svg"
                          alt="edit-icon"
                          style={{ width: '24px', height: '24px' }}
                        />
                      </IconButton>
                      <IconButton onClick={() => handleChangeDelete(pixel)}>
                        <img
                          src="/assets/icons/actions/delete.svg"
                          alt="delete-icon"
                          style={{ width: '24px', height: '24px' }}
                        />
                      </IconButton>
                    </Stack>
                  )}
                  {isAdmin && (
                    <Stack
                      direction="row"
                      sx={{ width: '10%', justifyContent: 'flex-end' }}
                    >
                      <IconButton onClick={() => handleChangeEditPixel(pixel)}>
                        <img
                          src="/assets/icons/eyes/eyeButtonBlack.svg"
                          alt="edit-icon"
                          style={{ width: '24px', height: '24px' }}
                        />
                      </IconButton>
                    </Stack>
                  )}
                </Stack>
              </Paper>
            ))}

            {isSmallScreen && !isAdmin && (
              <Button
                variant="outlined"
                sx={{
                  color: '#1C52BD',
                  borderColor: '#1C52BD',
                  fontFamily: 'Montserrat',
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '24px',
                }}
                onClick={() => handleChangePixel()}
              >
                + Novo Pixel
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
      <ProductPixelRegister
        open={open}
        sending={sending}
        productPixel={current}
        errorPixel={errorPixel}
        errorDomain={errorDomain}
        errorToken={errorToken}
        pageIndex={registerPageIndex}
        setOpen={setOpen}
        setProductPixel={setCurrent}
        onSave={handleChangeSave}
        onVerifyDomain={handleChangeVerifyDomain}
        onValidationPixelErrors={validationPixelErrors}
        onValidationDomainErrors={validationDomainErrors}
        setPageIndex={setRegisterPageIndex}
        isEditing={isEditing}
      />

      <ConfirmDialog
        open={openConfirmDelete}
        title="Confirmar exclusão"
        message="Deseja realmente deletar o pixel?"
        onClose={handleDeleteClose}
        onYes={deletePixel}
      />

      <LoadingBackdrop open={loading || sending} />

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Concluído com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

export default ProductPixels
