import api, { getResponseError } from "../../services/api";
import { TCustomCheckout, TCustomCheckoutPagination } from "../types/CustomCheckout";
import { IErrorResponse, IResponse, TResponse } from "../types/ErrorResponse";

interface TCustomCheckoutProp {
    page: number;
    rows: number;
    search?: string;
}

const getAll = async ({ page, rows, search }: TCustomCheckoutProp): Promise<TCustomCheckoutPagination | IErrorResponse> => {
    try {
        var path = `/CustomCheckout?PageNumber=${page}&RowsPerPage=${rows}`;

        if (search && search !== '') {
            path += `&Search=${search}`;
        }

        const { data } = await api.get<TCustomCheckoutPagination>(path);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const getByProduct = async ({ productId }: { productId: string }): Promise<TCustomCheckout[] | IErrorResponse> => {
    try {
        var path = `/CustomCheckout/product/${productId}`;
        const { data } = await api.get<TCustomCheckout[]>(path);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const get = async ({ id }: { id: string }): Promise<TResponse<TCustomCheckout>> => {
    try {
        const { data } = await api.get(`/CustomCheckout/${id}`);
        if (data) {
            return {
                code: 0,
                success: true,
                error: "",
                data: {
                    ...data,
                    desktopLayout: JSON.parse(data.desktopLayout),
                    mobileLayout: JSON.parse(data.mobileLayout),
                },
            };
        }

        return {
            code: 1,
            success: false,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            success: false,
            error: e,
        };
    }
}

const insert = async ({ data }: { data: TCustomCheckout }): Promise<IResponse> => {
    try {
        const { data: response } = await api.post('/CustomCheckout', data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
                data: response,
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error: any) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const update = async ({ data }: { data: TCustomCheckout }): Promise<IResponse> => {
    try {
        const json = {
            ...data,
            desktopLayout: JSON.stringify(data.desktopLayout),
            mobileLayout: JSON.stringify(data.mobileLayout),
        }
        const { data: response } = await api.put('/CustomCheckout', json);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const remove = async ({ id }: { id: string }): Promise<IResponse> => {
    try {
        await api.delete(`/CustomCheckout/${id}`);
        return {
            success: true,
            code: 0,
            error: '',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const CustomCheckoutController = {
    getAll,
    get,
    getByProduct,
    insert,
    update,
    remove,
}

export default CustomCheckoutController;