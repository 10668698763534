import MuiAlert, { AlertProps } from '@mui/material/Alert'
import React from 'react'
import { TUtmfyListId } from '../../../../core/types/Utmfy'
import { useUtmfyService } from '../../../../core/hooks/useUtmfyService'
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Fab,
} from '@mui/material'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import TablePaginationDefault from '../../../../components/Tables/TablePaginationDefault'
import UtmfyController from '../../../../core/controllers/UtmfyController'
import UtmfyRegister from '../UtmfyRegister'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import UtmfyEdit from '../UtmfyEdit'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }
)

interface Column {
  id: 'id' | 'name' | 'code' | 'isEnabled' | 'action'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'name', label: 'Nome', minWidth: 150 },
  { id: 'code', label: 'Código', minWidth: 150 },
  { id: 'isEnabled', label: 'Habilitado', minWidth: 150 },
  {
    id: 'action',
    label: 'Ações',
    minWidth: 100,
  },
]

const defaultUtmfyItem: TUtmfyListId = {
  id: '',
  code: '',
  settingsName: '',
  apiToken: '',
  isEnabled: true,
  allProducts: false,
  productIds: [],
}

const UtmfyList: React.FC = () => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [open, setOpen] = React.useState(false)
  const [openEdit, setOpenEdit] = React.useState(false)
  const [utmfyItemToEdit, setutmfyIdToEdit] = React.useState({})
  const [current, setCurrent] = React.useState<TUtmfyListId>({
    ...defaultUtmfyItem,
  })
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openDisabled, setOpenDisabled] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [errorSending, setErrorSending] = React.useState('')
  const [idToDelete, setIdToDelete] = React.useState('')
  const [idToDisabled, setIdToDisabled] = React.useState('')
  const [idToEnabled, setIdToEnabled] = React.useState('')

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const { loading, data, error, execute, clear } = useUtmfyService({
    page,
    rows: rowsPerPage,
  })

  const handleOpen = (utmfy?: TUtmfyListId) => {
    utmfy ??= { ...defaultUtmfyItem }
    if (utmfy.id === '') {
      setCurrent({ ...utmfy })
      setOpen(true)
    } else {
      return
    }
  }

  const handleOpenEdit = async (utmfyId: any) => {
    setOpenEdit(true)
    const response = await UtmfyController.get({ id: utmfyId })
    setutmfyIdToEdit(response)
  }

  const handleDelete = (urmfy?: any) => {
    if (!urmfy) {
      return
    }
    setIdToDelete(urmfy)
    setOpenDelete(true)
  }

  const handleDisabled = (urmfy?: any) => {
    if (!urmfy) {
      return
    }
    setIdToDisabled(urmfy)
    setOpenDisabled(true)
  }

  const deleteUtmfyItem = async () => {
    if (!idToDelete || idToDelete === '') {
      return
    }

    setSending(true)
    setDeleted(false)
    setErrorSending('')

    try {
      const response = await UtmfyController.remove({ id: idToDelete })

      if (!response.success) {
        setErrorSending(response.error)
      } else {
        setDeleted(true)
        setOpenDelete(false)
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  const disabledUtmfyItem = async () => {
    if (!idToDisabled || idToDisabled === '') {
      return
    }

    setSending(true)
    setDisabled(false)
    setErrorSending('')

    try {
      const response = await UtmfyController.disabled({ id: idToDisabled })

      if (!response.success) {
        setErrorSending(response.error)
      } else {
        setDisabled(true)
        setOpenDisabled(false)
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  const enabledUtmfyItem = async (id: any) => {
    if (!id || id === '') {
      return
    }

    setSending(true)

    setErrorSending('')

    try {
      const response = await UtmfyController.enabled({ id: id })

      if (!response.success) {
        setErrorSending(response.error)
      } else {
        setOpenDisabled(false)
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  return (
    <Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        UTMIfy
      </Typography>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <Alert onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Box>
      )}

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      if (column.id === 'action') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Button
                              variant="text"
                              startIcon={<EditIcon />}
                              onClick={() => handleOpenEdit(row.id)}
                            >
                              Editar
                            </Button>
                            <Button
                              variant="text"
                              color="warning"
                              startIcon={
                                row.isEnabled ? (
                                  <CancelIcon />
                                ) : (
                                  <CheckCircleIcon />
                                )
                              }
                              onClick={() =>
                                row.isEnabled
                                  ? handleDisabled(row.id)
                                  : enabledUtmfyItem(row.id)
                              }
                            >
                              {row.isEnabled ? 'Desabilitar' : 'Habilitar'}
                            </Button>
                            <Button
                              variant="text"
                              color="error"
                              startIcon={<DeleteIcon />}
                              onClick={() => handleDelete(row.id)}
                            >
                              Excluir
                            </Button>
                          </TableCell>
                        )
                      }
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'isEnabled'
                            ? value
                              ? 'Ativo'
                              : 'Inativo'
                            : column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Box sx={{ height: 80 }} />
      <Fab
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        aria-label={'Nova Oferta'}
        color="primary"
        onClick={() => handleOpen()}
      >
        <AddIcon />
      </Fab>

      <UtmfyRegister
        open={open}
        setOpen={setOpen}
        utmfy={current}
        execute={execute}
      />

      <UtmfyEdit
        open={openEdit}
        setOpen={setOpenEdit}
        utmfyItem={utmfyItemToEdit}
        execute={execute}
      />

      <ConfirmDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        message="Deseja realmente deletar ?"
        onYes={deleteUtmfyItem}
      />

      <ConfirmDialog
        open={openDisabled}
        onClose={() => setOpenDisabled(false)}
        message="Deseja desabilitar?"
        onYes={disabledUtmfyItem}
      />

      <LoadingBackdrop open={loading || sending} />

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={deleted} onClose={() => setDeleted(false)}>
        Deletado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

export default UtmfyList
