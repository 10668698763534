import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  TApp,
  TAppSideBar,
  TypeButtonAppEnum,
  apps,
} from '../../core/data/apps'
import { useNavigate } from 'react-router-dom'
import { useVoxuySettings } from '../../core/hooks/useVoxuySettings'
import { VoxuySettings } from './VoxuySettings'
import { ChangeEvent, useEffect, useState } from 'react'
import { TVoxuySettings } from '../../core/types/TenantVoxuySettings'
import { GoogleTagManagerSettings } from './GoogleTagManager'
import styled from '@emotion/styled'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import IconButton from '@mui/material/IconButton'

const AppsPage: React.FC = () => {
  const [openVoxuy, setOpenVoxuy] = useState(false)
  const [openGTM, setOpenGTM] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')
  const [filterApps, setFilterApps] = useState<TApp[]>([])

  const { data: voxuyData } = useVoxuySettings()

  const navigate = useNavigate()

  const handleClickButton = (value: TApp) => {
    if (value.typeButton === TypeButtonAppEnum.redirect) navigate(value.to)
    else {
      switch (value.sideBar) {
        case TAppSideBar.voxuy:
          setOpenVoxuy(true)
          break
        case TAppSideBar.gtm:
          setOpenGTM(true)
          break
      }
    }
  }

  const handleClearSearch = () => {
    setSearchTerm('')
    setFilterApps([])
  }

  useEffect(() => {
    if (searchTerm === '') {
      handleClearSearch()
      setFilterApps(apps)
    } else {
      const filter = apps.filter((app) =>
        app.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setFilterApps(filter)
    }
  }, [searchTerm])

  const ArrowIconRight = styled(ArrowForwardIcon)({
    color: '#707275',
  })
  const CustomIconButton = styled(IconButton)({
    p: '0px',
    padding: '0px',
    minWidth: 0,
    fontSize: '0px',
  })
  const CustomIcon = styled('div')({
    width: 32,
    height: 32,
    border: '1px solid #F3F3F4',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    m: 0,
  })

  return (
    <Box sx={{ elevation: 0 }}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        alignContent="space-between"
        alignItems="center"
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h5" fontWeight={700} sx={{ mb: 3 }}>
            Apps
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <FormControl variant="outlined" size="small" sx={{ mb: 3 }}>
            <InputLabel htmlFor="search-label"></InputLabel>
            <OutlinedInput
              id="search-label"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
                if (e.target.value === '') {
                  handleClearSearch()
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <img alt="Pesquisar" src="/assets/icons/search/search.svg" />
                </InputAdornment>
              }
              sx={{
                maxHeight: '40px',
                padding: '8px 16px',
                gap: '16',
                borderRadius: '8px',
                border: ' 1px solid #DCDDDB',
                backgroundColor: '#FFFFFF',
              }}
              placeholder="Pesquisar"
            />
          </FormControl>
        </Box>
      </Stack>

      <Grid container spacing={2} columns={16}>
        {filterApps.map((value, i) => (
          <Grid key={i.toString()} item xs={16} sm={16} md={8} lg={4} xl={4}>
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                height: 80,
                width: '100%',
                borderRadius: '12px',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                ':hover': {
                  boxShadow: 10,
                },
              }}
            >
              <Button
                fullWidth
                sx={{
                  height: 80,
                  color: 'black',
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                  p: '0px',
                }}
                onClick={() => handleClickButton(value)}
              >
                <Stack width="100%" alignSelf="center" sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack spacing={2} direction="row" alignItems="center">
                      <img
                        src={value.image}
                        alt={value.name}
                        style={{ height: 48, objectFit: 'contain' }}
                      />
                      {value.name !== '' && (
                        <Typography
                          width={'100%'}
                          variant="h6"
                          textAlign="center"
                          fontSize={'16px'}
                          fontWeight={600}
                          lineHeight={'20px'}
                        >
                          {value.name}
                        </Typography>
                      )}
                    </Stack>
                    <Stack display="flex" direction="row">
                      <CustomIconButton>
                        <CustomIcon>
                          <ArrowIconRight />
                        </CustomIcon>
                      </CustomIconButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <VoxuySettings
        open={openVoxuy}
        setOpen={setOpenVoxuy}
        voxuySettings={voxuyData as TVoxuySettings}
      />

      <GoogleTagManagerSettings open={openGTM} setOpen={setOpenGTM} />
    </Box>
  )
}

export default AppsPage
