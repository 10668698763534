import { Typography } from '@mui/material'

interface ITextBlackButtonProps {
  children: React.ReactNode
}

const TextBlackButton = ({ children }: ITextBlackButtonProps) => {
  return (
    <Typography
      sx={{
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center',
      }}
    >
      {children}
    </Typography>
  )
}

export default TextBlackButton
