import { TIndicatedComissionLevelEnum } from "../types/IndicatedTenant";

const indicatedComissionLevels: TIndicatedComissionLevelEnum[] = [
    TIndicatedComissionLevelEnum.None,
    TIndicatedComissionLevelEnum.Basic,
    TIndicatedComissionLevelEnum.Gold,
    TIndicatedComissionLevelEnum.Platinum,
];

const getIndicatedComissionLevelString = (value?: TIndicatedComissionLevelEnum) => {
    switch (value) {
        case TIndicatedComissionLevelEnum.Basic: return "Nível 1 - Basic";
        case TIndicatedComissionLevelEnum.Gold: return "Nível 2 - Gold";
        case TIndicatedComissionLevelEnum.Platinum: return "Nível 3 - Platinum";
    }

    return "Nenhum";
}

const IndicatedFunctions = {
    indicatedComissionLevels,
    getIndicatedComissionLevelString,
}

export default IndicatedFunctions;