import {
  Alert,
  Box,
  Fade,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import SwipeableDrawerRightDialog from '../../Dialogs/SwipeableDrawerRightDialog'
import { red } from '@mui/material/colors'
import { TCustomCheckout } from '../../../core/types/CustomCheckout'
import SwitchRound from '../../Switch/SwitchRound'
import { PriceMultiselect } from '../PriceMultiselect'
import { TProduct, TProductPrice } from '../../../core/types/Product'
import { useContext, useEffect, useState } from 'react'
import { UseFormWatch } from 'react-hook-form'
import { AuthContext } from '../../../core/context/AuthContext'
import { TUserType } from '../../../core/types/Auth'

export interface IProductCustomCheckoutRegisterProps {
  open: boolean
  sending: boolean
  customCheckout: TCustomCheckout
  errorName: string
  errorPrices: string
  productPrices: TProductPrice[]
  checkouts: TCustomCheckout[]
  watch: UseFormWatch<TProduct>
  setOpen: (value: boolean) => void
  setCustomCheckout: (value: TCustomCheckout) => void
  handleChangeSave: () => Promise<void>
}

const ProductCustomCheckoutRegister = ({
  open,
  sending,
  customCheckout,
  errorName,
  errorPrices,
  productPrices,
  checkouts,
  watch,
  setOpen,
  setCustomCheckout,
  handleChangeSave,
}: IProductCustomCheckoutRegisterProps) => {
  const [options, setOptions] = useState([...productPrices])

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  useEffect(() => {
    const prices = productPrices.map((pp, index) => {
      pp.id = watch(`prices.${index}.id`)
      return pp
    })

    const ignores: string[] = []
    checkouts.map((c) => {
      if (c.prices && c.prices.length > 0) {
        ignores.push(...c.prices)
      }
    })

    const newPrices: TProductPrice[] = []
    prices.map((pp) => {
      if (!ignores.includes(pp.id)) {
        newPrices.push({ ...pp })
      }
    })

    customCheckout.prices.map((p) => {
      const exists = newPrices.find((np) => np.id === p)
      if (!exists) {
        const add = prices.find((pr) => pr.id === p)
        if (add) {
          newPrices.push({ ...add })
        }
      }
    })

    setOptions(newPrices)
  }, [checkouts, customCheckout])

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={() => setOpen(false)}
      setOpen={(value) => setOpen(value)}
      title="Checkout Personalizado"
      buttons={
        isAdmin
          ? []
          : [
              {
                title: 'Cancelar',
                onClick: () => setOpen(false),
                type: 'negative',
              },
              { title: 'Salvar', onClick: handleChangeSave, type: 'positive' },
            ]
      }
      buttonsDisabled={sending}
    >
      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
        <Box>
          {customCheckout.id === '' && options.length <= 0 && (
            <Alert
              variant="filled"
              severity="warning"
              sx={{ width: '100%', mb: 2 }}
            >
              Cada oferta desse produto já tem um checkout personalizado
            </Alert>
          )}
          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            alignItems="center"
          >
            <Typography variant="subtitle2">Identificação</Typography>
          </Stack>
          <TextField
            fullWidth
            disabled={isAdmin}
            required
            size="small"
            type="text"
            inputProps={{ maxLength: 150 }}
            InputLabelProps={{ shrink: true }}
            value={customCheckout.name}
            onChange={(e) =>
              setCustomCheckout({ ...customCheckout, name: e.target.value })
            }
            error={errorName !== ''}
          />
          {errorName !== '' && (
            <Typography variant="caption" color={red[700]}>
              {errorName}
            </Typography>
          )}
        </Box>
        {(checkouts.find((c) => c.defaultValue) === undefined ||
          customCheckout.defaultValue) && (
          <FormControlLabel
            control={
              <SwitchRound
                disabled={isAdmin}
                sx={{ m: 1 }}
                checked={customCheckout.defaultValue}
                onChange={(e) =>
                  setCustomCheckout({
                    ...customCheckout,
                    defaultValue: e.target.checked,
                  })
                }
              />
            }
            label="Utilizar modelo para todas as ofertas"
          />
        )}
        <Fade in={!customCheckout.defaultValue} mountOnEnter unmountOnExit>
          <Box width="100%">
            <PriceMultiselect
              prices={options}
              selecteds={customCheckout.prices}
              error={errorPrices}
              setSelecteds={(values) =>
                setCustomCheckout({ ...customCheckout, prices: values })
              }
            />
          </Box>
        </Fade>
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}

export default ProductCustomCheckoutRegister
